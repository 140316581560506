//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./FLNHome.module.css";
import { getHMService, getStudentService } from "../../service/service";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import home from "assets/img/FLNSCren.png";
import GridItem from "components/Grid/GridItem";
import {
  ArrowBack,
  ArrowForward,
  DeleteOutline,
  HelpOutline,
} from "@material-ui/icons";
import Popup from "reactjs-popup";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Snackbar from "components/Snackbar/Snackbar";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  list: {
    maxHeight: "80vh",
    overflowY: "auto",
  },
};

const classes = styles;
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    // trueContact: "",
    meetingsForm: [
      {
        teacher_name: "",
        phone: "",
        school: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
        JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
        trueContact: "",
      },
    ],
    emptyMeetings: {
      teacher_name: "",
      phone: "",
      school: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
      JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
      trueContact: "",
    },
    confirmModal: false,
    trucontactArrya: [],
    teacherList: null,
    offset: 0,
    statusLength: null,
    deleteId: null,
    alert: false,
    validateFlag:false,
    messageSnack:null,
    alertEdit:false,
    schoolId: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
    JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
  };

  componentDidMount() {
    this.getTeacher();
  }

  renderMemberTiming = () => {
    let { meetingsForm, smcDetails, isSMCDetailsPresent } = this.state;
    let form = [];

    meetingsForm.map((data, index) => {
      form.push(
        <div>
          <div
            style={{ display: "flex", padding: "5px" }}
            className={classes.committeeContainer}
            key={index}
          >
            <div
              style={{
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                minWidth: "100px",
                // marginTop: "10px",
                color: "#958989",
                textAlign: "center",
              }}
            >
              {" "}
              {index + 1}
            </div>

            <input
              style={{
                color: "#415EB6",
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                marginRight: "20px",
                marginLeft: "30px",
              }}
              placeholder="Name"
              value={data.teacher_name}
              className={classes.committeeAttendanceInput}
              onChange={(e) =>
                this.onValChangeMeetings("teacher_name", e, index)
              }
            />

            <input
              style={{
                color: "#415EB6",
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                marginRight: "20px",
                marginLeft: "30px",
              }}
              type="tel"
              min="0"
              maxLength="10"
              placeholder="Number"
              value={data.phone}
              className={classes.committeeAttendanceInput}
              onChange={(e) => this.onValChangeMeetings("phone", e, index)}
            />

            <input
              style={{
                color: "#415EB6",
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                marginRight: "20px",
                marginLeft: "30px",
              }}
              type="tel"
              min="0"
              maxLength="10"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              placeholder="Number"
              value={data.trueContact}
              className={classes.committeeAttendanceInput}
              onChange={(e) =>
                this.onValChangeMeetings("trueContact", e, index)
              }
            />

            <button
              style={{ border: "0px", backgroundColor: "transparent" }}
              onClick={() => this.removeMeetings(index)}
            >
              <DeleteOutline style={{ color: "red" }} />
            </button>
          </div>
          {console.log(data.trueContact === data.phone, "val")}
          {data.trueContact === data.phone ? null : (
            <div style={{ color: "red", fontSize: "10px" }}>
             मोबाईल नंबर दोन्ही रिकाम्या जागी अचूक लिहावे 
            </div>
          )}
        </div>
      );
    });
    let formWrap = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "20px",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "160px",
              color: "#958989",
            }}
          >
            शिक्षकांचे पूर्ण नाव
          </p>
          <p
            style={{
              textAlign: "center",
              width: "300px",
              color: "#958989",
            }}
          >
            शिक्षकांचा फोन क्रमांक
          </p>
          <p
            style={{
              textAlign: "center",
              width: "200px",
              color: "#958989",
            }}
          >
            फोन क्रमांक परत लिहा
          </p>
        </div>

        {form}

        <div className="d-flex flex-row align-items-center justify-content-center">
          <button
            style={{
              fontSize: "35px",
              border: "0px",
              backgroundColor: "transparent",
              marginRight: "20px",
              backgroundColor: "#c8ed91",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              height: "40px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
            onClick={() => this.addMeetings()}
          >
            +
            <span style={{ fontSize: "15px", marginLeft: "10px" }}>
              अजून एक क्रमांक नोंदवा
            </span>
          </button>
        </div>
      </div>
    );
    return formWrap;
  };

  addMeetings = () => {
    let emptyMeetings = JSON.stringify(this.state.emptyMeetings);
    let meetingsForm = [...this.state.meetingsForm];
    meetingsForm.push(JSON.parse(emptyMeetings));
    this.setState(
      {
        meetingsForm,
      },
      () => {
        console.log(this.state.meetingsForm, "meetingsform");
      }
    );
  };

  removeMeetings = (index) => {
    let meetingsForm = [...this.state.meetingsForm];
    if (meetingsForm.length > 1) {
      meetingsForm.splice(index, 1);
      this.setState({
        meetingsForm,
      });
    }
  };

  onValChangeMeetings = async (flag, event, index) => {
    let meetingsForm = [...this.state.meetingsForm];

    if (flag === "teacher_name") {
      meetingsForm[index].teacher_name = event.target.value;
    } else if (flag === "phone") {
      meetingsForm[index].phone = event.target.value;
    } else if (flag === "trueContact") {
      meetingsForm[index].trueContact = event.target.value;
    }

    this.setState(
      {
        meetingsForm,
      }
    );
  };
  validateNumber = () => {
   let array=[]
   this.state.meetingsForm.map((res)=>{
    console.log(res.phone,"res",res.trueContact)
    if(res.phone!==res.trueContact)
    {
      array.push(res)
    }
   })
   array.length > 0 ? this.setState({ confirmModal: false,}) : this.setState({ confirmModal: true,})
   console.log(array,"array")
  };

  submiTeacherDetails = () => {
    getHMService()
      .createFLNTeacherList(this.state.meetingsForm)
      .then((res) => {
        console.log(res, "res");
        if (res) {
          this.setState(
            {
              confirmModal: false,
              meetingsForm: [
                {
                  teacher_name: "",
                  phone: "",
                  school: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
                  JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
                  trueContact: "",
                },
              ],
            },
            () => {
              this.getTeacher();
            }
          );
        }
      })
      .catch((e)=>{
        console.log(e.response.data[0].phone,"response")
        this.setState({
          messageSnack : e.response?.data[0].phone
        })
        
      })
  };

  getTeacher = async () => {
    let data = await getHMService().getTeacherListDetails(this.state.schoolId,this.state.offset);
    console.log(data, "data");
    if (data.status == 200) {
      this.setState({
        teacherList: data.data.results,
        statusLength: data.data.count,
      });
    }
  };

  nextData = () => {
    let data = this.state.offset + 10;
    console.log(this.state.statusLength, "this.state.statusLength");
    if (data > this.state.statusLength) {
      return;
    } else {
      this.setState(
        {
          offset: this.state.offset + 10,
        },
        () => {
          this.getTeacher();
        }
      );
    }
  };

  deleteTeacher = (index) => {
    console.log(index, "index");
    let TeacherId = this.state.teacherList[this.state.deleteId].teacher_id;
    console.log(this.state.teacherList[this.state.deleteId].teacher_id, "list");
    getHMService()
      .deleteTeacher(TeacherId)
      .then((res) => {
        window.location.reload();
        console.log(res, "res");
      });
  };

  editTeacher =(index) =>{
    let TeacherId = this.state.teacherList[this.state.deleteId].teacher_id;
    console.log(this.state.teacherList[this.state.deleteId], "list");
    this.state.teacherList[this.state.deleteId].is_deleted = false
    console.log(this.state.teacherList[this.state.deleteId], "list12");
    getHMService()
    .editTeacher(this.state.teacherList[this.state.deleteId],TeacherId)
    .then((res) => {
      window.location.reload();
      console.log(res, "res");
    });
  }
  render() {
    let { stdWiseGraph } = this.state;

    return (
      <>
        <AlertDialog
          open={this.state.alert}
          title="Delete?"
          text="Do you want to Delete this Teacher?"
          onCancel={() => this.setState({ alert: false })}
          onConfirm={() => {
            this.setState({ alert: false }, () => {
              this.deleteTeacher();
            });
          }}
        />
        <AlertDialog
          open={this.state.alertEdit}
          title="Activate?"
          text="Do you want to Activate this Teacher?"
          onCancel={() => this.setState({ alertEdit: false })}
          onConfirm={() => {
            this.setState({ alertEdit: false }, () => {
              this.editTeacher();
            });
          }}
        />
        <Snackbar
          autoHideDuration={4000}
          color={"success"}
          close={false}
          open={this.state.messageSnack}
          message={this.state.messageSnack}
          place="br"
          rtlActive={true}
          closeNotification={() => {
            this.setState({
              messageSnack: null,
            });
          }}
        />
        <Modal
          open={this.state.confirmModal}
          onClose={() => {
            this.setState({
              confirmModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 900,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                marginBottom: "50px",
                textAlign: "center",
                color: "#415EB6",
              }}
            >
              तुम्ही भरलेली माहिती तपासून पहा.
            </div>

            <div style={{ marginLeft: "80px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "20px",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    width: "350px",
                    color: "#958989",
                    marginLeft: "70px",
                  }}
                >
                  शिक्षकांचे पूर्ण नाव
                </p>
                <p
                  style={{
                    textAlign: "center",
                    width: "300px",
                    color: "#958989",
                  }}
                >
                  शिक्षकांचा फोन क्रमांक
                </p>
              </div>
              {console.log(this.state.meetingsForm, "this.state.meetingsForm")}
              {this.state.meetingsForm?.map((res, index) => {
                return (
                  <div>
                    <div
                      style={{ display: "flex", padding: "5px" }}
                      className={classes.committeeContainer}
                      key={index}
                    >
                      <div
                        style={{
                          border: "0px",
                          backgroundColor: "#DFE4E8",
                          minWidth: "150px",
                          height: "46px",
                          minWidth: "100px",
                          // marginTop: "10px",
                          color: "#958989",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {index + 1}
                      </div>

                      <div
                        style={{
                          color: "#415EB6",
                          border: "0px",
                          backgroundColor: "#DFE4E8",
                          minWidth: "200px",
                          height: "46px",
                          // marginRight: "20px",
                          marginLeft: "90px",
                          textAlign: "center",
                        }}
                      >
                        {res.teacher_name}
                      </div>

                      <div
                        style={{
                          color: "#415EB6",
                          border: "0px",
                          backgroundColor: "#DFE4E8",
                          minWidth: "200px",
                          height: "46px",
                          // marginRight: "20px",
                          marginLeft: "100px",
                          textAlign: "center",
                        }}
                      >
                        {res.phone}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div style={{ margin: "20px" }}>
                केवळ या क्रमांकावरून व्हीस्कुलवर लॉगिन असलेल्या शिक्षकांना
                अध्ययन स्तराबद्दल माहिती भरता येईल.
              </div>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#DD813E",
                      color: "#fff",
                      padding: "10px",
                      marginTop: "30px",
                      width: "200px",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      this.setState({
                        confirmModal: false,
                      });
                      // this.submiTeacherDetails()
                      // console.log(this.state.meetingsForm,"trueContact")
                    }}
                  >
                    परत तपासा.
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#1ACA2B",
                      color: "#fff",
                      padding: "10px",
                      marginTop: "30px",
                      width: "200px",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      this.submiTeacherDetails();
                    }}
                  >
                    बरोबर आहे, सबमिट करा.
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginTop: "15px",
              // backgroundColor: "#DFE4E8",
              fontWeight: "bold",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "column",
                //   marginLeft: "18%",
              }}
            ></div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  //   backgroundColor: "#DFE4E8",
                  padding: "10px",
                  color: "#4B66B9",
                  //   padding: "10px",
                }}
              >
                महत्वाच्या सूचना:
                <div style={{ padding: "20px" }}>
                  १. आपल्या शाळेतील सर्व विद्यार्थ्यांच्या अध्ययनस्तर निश्चितीची
                  माहिती व्हीस्कुल ॲप वरून अपलोड करण्यासाठी ज्या शिक्षकांची निवड
                  तुम्ही केली आहे त्यांची माहिती येथे भरायची आहे.
                </div>
                <div style={{ padding: "20px" }}>
                  २. या शिक्षकांनी ज्या मोबाईल क्रमांकावरून व्हीस्कुल ॲपमध्ये
                  शिक्षक म्हणून नोंदणी केली असेल तेच क्रमांक इथे द्यावेत.{" "}
                </div>
                <div style={{ padding: "20px" }}>
                  ३. येथील नोंदणीनंतर या यादीतील फोन क्रमांकावरून लॉगिन असलेल्या
                  शिक्षकांना व्हीस्कुल ॲपमध्ये सहजपणे अध्ययनस्तर नोंदणी करता
                  येईल. शिक्षक म्हणून नोंदणी केली असेल तेच क्रमांक इथे द्यावेत.{" "}
                </div>
              </div>
              <div>
                <img width="42%" src={home} style={{ marginBottom: "15px" }} />
              </div>
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {this.renderMemberTiming()}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                marginBottom: "50px",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#7965DA",
                  color: "#fff",
                  padding: "10px",
                  marginTop: "30px",
                }}
                // disabled={ this.validateNumber()}
                onClick={() => {
                  this.validateNumber()
                  // this.setState({
                  //   confirmModal: true,
                  // });
                  // this.submiTeacherDetails()
                  console.log(this.state.validateFlag,"trueContact")
                }}
              >
                तपासा व पुढे जा.
              </Button>
            </div>
            <div>
              <Card>
                <CardHeader color="primary">
                  <h4>यशस्वी नोंद झालेली अंतिम माहिती</h4>
                </CardHeader>
                <CardBody>
                  <div style={(styles.inputWrapper, styles.list)}>
                    <TableContainer>
                      <Table
                        //   className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">पूर्ण नाव</TableCell>
                            <TableCell align="center">मोबाईल क्रमांक</TableCell>
                            <TableCell align="center">स्टेट्स</TableCell>
                            <TableCell align="center">स्टेट्स बदला</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {console.log(this.state.StudentList, "sta")}
                          {this.state.teacherList?.map((res, index) => {
                            return (
                              <TableRow>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  align="left"
                                >
                                  {res.teacher_name}
                                </TableCell>
                                <TableCell align="center">
                                  {res.phone}
                                </TableCell>
                                <TableCell align="center">
                                  <div>
                                    {res.is_deleted ? "INACTIVE" : "ACTIVE"}
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  align="center"
                                  onClick={() => {
                                    this.setState({
                                      deleteId: index,
                                      alert: res.is_deleted ? false:true,
                                      alertEdit: res.is_deleted ? true : false,
                                    });

                                  }}
                                >
                                  {/* <Select
                                  disabled={}
                                    style={{
                                      color: "#415EB6",
                                      border: 0,
                                      backgroundColor: "#DFE4E8",
                                      width: "200px",
                                      height: "46px",
                                      marginRight: "20px",
                                    }}
                                    className={classes.committeeCategoryInput}
                                    value={res.is_deleted ? "No" : "Yes"}
                                    onChange={(e) =>
                                      console.log(e.target.value)
                                    }
                                    placeholder="Category"
                                  >
                                    <MenuItem
                                      value="Yes"
                                      className={classes.committeeCategoryInput}
                                      key="Yes"
                                    >
                                      YES
                                    </MenuItem>
                                    <MenuItem
                                      value="No"
                                      className={classes.committeeCategoryInput}
                                      key="No"
                                    >
                                      NO
                                    </MenuItem>
                                  </Select> */}
                                  Yes/No
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter style={{ display: "flex" }}>
                          <IconButton
                            onClick={() => {
                              this.setState(
                                {
                                  offset:
                                    this.state.offset > 0
                                      ? this.state.offset - 10
                                      : this.state.offset,
                                },
                                () => {
                                  this.getTeacher();
                                }
                              );
                            }}
                          >
                            <ArrowBack />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              this.nextData();
                            }}
                          >
                            <ArrowForward />
                          </IconButton>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}
