import axios from "axios";

import { KOITA_URL } from "./config";

const httpV3 = axios.create({
    baseURL: KOITA_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });
  
  httpV3.interceptors.request.use((config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      config.headers.Authorization = `${user.token}`;
    }
    return config;
  });
  
  httpV3.interceptors.response.use((response) => {
    const { status, message } = response.data;
  
    if (status === 500 && message === "Authentication token invalid") {
      localStorage.clear();
      sessionStorage.setItem(
        "message",
        "Your session has been expired. Please login"
      );
      window.location.href = window.location.origin;
    }
    return response;
  });
  
  export default httpV3;