import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import styles from "./styles";
import { getHMService, getSuperAdminService } from "./../../service/service";
import Snackbar from "./../../components/Snackbar/Snackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import schoolcsv from "../../assets/school.csv";
import Edit from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import { SkipNext, SkipPrevious } from "@material-ui/icons";

const classes = styles;
class Schools extends React.Component {
  state = {
    states: [],
    districts: [],
    blocks: [],
    state: {
      helperText: null,
      value: null,
    },
    district: {
      helperText: null,
      value: null,
    },
    block: {
      helperText: null,
      value: null,
    },
    csv: {
      helperText: null,
      value: null,
    },
    schools: [],
    message: null,
    error: null,
    resetFile: false,
    loading: false,
    openDialog: false,
    dialogType: null,
    addSchoolOpen: false,
    schoolName: {
      helperText: null,
      value: null,
    },
    udiseNum: {
      helperText: null,
      value: null,
    },
    searchText: {
      helperText: null,
      value: null,
    },
    updateSchoolOpen: false,
    updateSchoolId: {
      helperText: null,
      value: null,
    },
    updateSchoolName: {
      helperText: null,
      value: null,
    },
    updateUdiseNum: {
      helperText: null,
      value: null,
    },
    updateBlock: {
      helperText: null,
      value: null,
    },
    updateKendra: {
      helperText: null,
      value: null,
    },
    updateState: {
      helperText: null,
      value: null,
    },
    updateDistrict: {
      helperText: null,
      value: null,
    },
    addState: {
      helperText: null,
      value: null,
    },
    addDistrict: {
      helperText: null,
      value: null,
    },
    addBlock: {
      helperText: null,
      value: null,
    },
    addkendra: {
      helperText: null,
      value: null,
    },
    addStates: [],
    addDistricts: [],
    addBlocks: [],
    singleSchoolFlag: false,
    schoolInfo: false,
    headMasterName: "",
    version: "",
    contact: "",
    kendraList:null,
    addKendraList:null,
    csvKendraName:'',
    PageNum:1,
    PageCount:null
  };

  componentDidMount() {
    getSuperAdminService()
      .getStudentStates()
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          if (this.state.singleSchoolFlag == true) {
            this.setState({
              addStates: response.response,
            });
          } else {
            this.setState(
              {
                states: response.response,
                addStates: response.response,
                state: {
                  value:
                    response.response.length > 0
                      ? response.response[0].student_state_id
                      : null,
                  helperText: null,
                },
              },
              () => {
                this.getStudentDistrict(
                  this.state.states[0].student_state_id,
                  this.state.singleSchoolFlag
                );
              }
            );
          }
        } else {
          this.setState({
            states: [],
            districts: [],
            blocks: [],
            schools: [],
            addStates: [],
            addDistricts: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  getStudentDistrict = async (state_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: state_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          if (singleSchoolFlag == true) {
            this.setState({
              addDistricts: response.response,
            });
          } else {
            this.setState(
              {
                districts: response.response,
                addDistricts: response.response,
                district: {
                  value:
                    response.response.length > 0
                      ? response.response[0].student_district_id
                      : null,
                  helperText: null,
                },
              },
              () => {
                this.getStudentBlock(
                  this.state.districts[0].student_district_id,
                  this.state.singleSchoolFlag
                );
              }
            );
          }
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
            addDistricts: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getStudentBlock = async (district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          if (singleSchoolFlag == true) {
            this.setState({
              addBlocks: response.response,
            });
          } else {
            this.setState(
              {
                blocks: response.response,
                addBlocks: response.response,
                block: {
                  value:
                    response.response.length > 0
                      ? response.response[0].student_block_id
                      : null,
                  helperText: null,
                },
              },
              () => {
                this.getKendra(this.state.block.value)
                this.getSchools(this.state.block.value);
              }
            );
          }
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  onChangeHandle = async (searchContent) => {
    const param = {
      student_block_id: this.state.block.value,
      searchText: searchContent,
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
          });
        } else {
          this.setState({
            schools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
        });
      });
  };

  getSchools = async (block) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: "",
      page_number:this.state.PageNum
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
            PageCount:response.count
          });
        } else {
          this.setState({
            schools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
        });
      });
  };

  onFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type != "text/csv") {
        this.setState({
          csv: {
            value: null,
            helperText: "Please choose valid CSV file",
          },
        });
        return;
      }

      this.setState({
        csv: {
          value: file,
          helperText: null,
        },
      });
    }
  };

  onCreateSingleSchool = async () => {
    const {
      addState,
      addDistrict,
      addBlock,
      schoolName,
      udiseNum,
      block,
      addkendra
    } = this.state;
    if (!addState.value) {
      this.setState({
        addState: {
          value: null,
          helperText: "Please choose valid state",
        },
      });
      return;
    }
    if (!addDistrict.value) {
      this.setState({
        addDistrict: {
          value: null,
          helperText: "Please choose valid district",
        },
      });
      return;
    }
    if (!addBlock.value) {
      this.setState({
        addBlock: {
          value: null,
          helperText: "Please choose valid block",
        },
      });
      return;
    }
    if (!schoolName.value) {
      this.setState({
        schoolName: {
          value: null,
          helperText: "Please fill the school name",
        },
      });
      return;
    }
    if (!udiseNum.value) {
      this.setState({
        udiseNum: {
          value: null,
          helperText: "Please fill the UDise number",
        },
      });
      return;
    }
    this.setState({
      loading: true,
    });

let obj={}

    if(addkendra.value)
    {
      obj = {
        student_block_id: addBlock.value,
        school_name: schoolName.value,
        u_dise: udiseNum.value,
        kendra_id : addkendra.value
      };
    }
    else{
      obj = {
        student_block_id: addBlock.value,
        school_name: schoolName.value,
        u_dise: udiseNum.value,
      };
    }
    

    await getSuperAdminService()
      .AddSingleSchool(obj)
      .then((result) => {
        const response = result.data;

        if (response && response.status == 200) {
          this.setState({
            message: "School added successfully",
            error: null,
            addState: {
              value: null,
              helperText: null,
            },
            addDistrict: {
              value: null,
              helperText: null,
            },
            addBlock: {
              value: null,
              helperText: null,
            },
            schoolName: {
              value: null,
              helperText: null,
            },

            loading: false,
          });

          this.addSchoolModalClose();
          this.getSchools(block.value);
          this.renderSchoolDetails();
        } else if (response.status == 500) {
          this.setState({
            message: "School already exists",
            error: "School already exists",
            loading: false,
          });
          this.addSchoolModalClose();
        } else {
          this.setState({
            message: null,
            error: "Failed to add school",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: null,
          error: "Failed to add schools",
          loading: false,
        });
      });
  };

  onUpdateSchool = async () => {
    const {
      updateDistrict,
      updateBlock,
      updateSchoolId,
      updateSchoolName,
      updateUdiseNum,
      block,
      searchText,
      updateKendra
    } = this.state;
    if (!updateBlock.value) {
      this.setState({
        updateBlock: {
          value: null,
          helperText: "Please choose valid block",
        },
      });
      return;
    }
    if (!updateSchoolName.value) {
      this.setState({
        updateSchoolName: {
          value: null,
          helperText: "Please fill the school name",
        },
      });
      return;
    }
    if (!updateUdiseNum.value) {
      this.setState({
        updateUdiseNum: {
          value: null,
          helperText: "Please fill the UDise number",
        },
      });
      return;
    }
    this.setState({
      loading: true,
    });
    let param ={}
    if(updateKendra.value)
    
    {
      param = {
        student_block_id: updateBlock.value,
        school_name: updateSchoolName.value,
        u_dise: updateUdiseNum.value,
        school_id: updateSchoolId.value,
        kendra_id : updateKendra.value
      }
    }
    else{
      param = {
        student_block_id: updateBlock.value,
        school_name: updateSchoolName.value,
        u_dise: updateUdiseNum.value,
        school_id: updateSchoolId.value,
      }
    }
    
    await getSuperAdminService()
      .UpdateSchool(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            message: "School updated successfully",
            error: null,
            updateState: {
              value: "",
              helperText: null,
            },
            updateDistrict: {
              value: "",
              helperText: null,
            },
            updateBlock: {
              value: "",
              helperText: null,
            },
            updateSchoolName: {
              value: "",
              helperText: null,
            },
            updateUdiseNum: {
              value: "",
              helperText: null,
            },
            searchText: {
              value: "",
              helperText: null,
            },
            loading: false,
          });

          this.updateSchoolModalClose();
          this.getSchools(block.value);
          this.renderSchoolDetails();
        } else {
          this.setState({
            message: null,
            error: "Failed to update school details",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: null,
          error: "Failed to update schools details",
          loading: false,
        });
      });
  };

  onCreateSchools = async () => {
    
    const { state, district, csv, block , csvKendraName} = this.state;
    console.log(csvKendraName,"csvKendraName")
    if (!state.value) {
      this.setState({
        state: {
          value: null,
          helperText: "Please choose valid state",
        },
      });
      return;
    }
    if (!district.value) {
      this.setState({
        district: {
          value: null,
          helperText: "Please choose valid district",
        },
      });
      return;
    }
    if (!block.value) {
      this.setState({
        block: {
          value: null,
          helperText: "Please choose valid block",
        },
      });
      return;
    }
    if (!csv.value) {
      this.setState({
        csv: {
          ...csv,
          value: null,
          helperText: "Please choose CSV file.",
        },
      });
      return;
    }
    this.setState({
      loading: true,
    });

    let fd = new FormData();
    fd.append("block_id", block.value);
    fd.append("filename", csv.value);
  if(csvKendraName)
  {
    fd.append("kendra_id",csvKendraName);
  }
    await getSuperAdminService()
      .schoolbulkupload(fd)
      .then((result) => {
        const response = result.data;

        if (response && response.status == 200) {
          this.setState({
            message: "Schools added successfully",
            error: null,
            csv: {
              value: null,
              helperText: null,
            },
            resetFile: true,
            loading: false,
          });

          this.getSchools(block.value);
          this.renderSchoolDetails();
        } else {
          this.setState({
            message: null,
            error: "Failed to add schools",
            resetFile: false,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: null,
          error: "Failed to add schools",
          resetFile: false,
          loading: false,
        });
      });
  };

  renderSchoolDetails() {
    let { schools } = this.state;

    if (schools.length > 0) {
      return (
        <>
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {schools.map((school) => {
                    return (
                      <TableRow
                        key={school.school_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{school.school_name}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() =>
                              this.updateSchoolModalOpen(
                                school.school_id,
                                school.school_name,
                                school.u_dise,
                                school.student_block_id,
                                school.kendra_id
                              )
                            }
                          >
                            <Edit />
                          </IconButton>

                          <IconButton
                            edge="end"
                            aria-label="details"
                            onClick={() =>
                              this.schoolInfoModalOpen(
                                school.school_id,
                                school.school_name,
                                school.u_dise,
                                school.student_block_id
                              )
                            }
                          >
                            <InfoIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      );
    }
  }

  renderStateMenu(type) {
    let { states, districts, blocks, schools } = this.state;

    let array =
      type == "state"
        ? states
        : type == "district"
        ? districts
        : type == "block"
        ? blocks
        : schools;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.student_state_id
          : type == "district"
          ? state.student_district_id
          : type == "block"
          ? state.student_block_id
          : state.school_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "district"
          ? state.district_name
          : type == "block"
          ? state.block_name
          : type == "school"
          ? state.school_name
          : "";
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }

  addSchoolModalOpen() {
    this.setState({
      addSchoolOpen: true,
      addState: {
        helperText: null,
        value: null,
      },
      addDistrict: {
        helperText: null,
        value: null,
      },
      addBlock: {
        helperText: null,
        value: null,
      },
       kendraList:null,
      singleSchoolFlag: true,
    });
  }

  addSchoolModalClose() {
    this.setState({
      addSchoolOpen: false,
      addState: {
        helperText: null,
        value: null,
      },
      addDistrict: {
        helperText: null,
        value: null,
      },
      addBlock: {
        helperText: null,
        value: null,
      },
      schoolName: {
        helperText: null,
        value: null,
      },
      udiseNum: {
        helperText: null,
        value: null,
      },
      singleSchoolFlag: false,
    });
  }

  updateSchoolModalOpen(school_id, school_name, school_udise, school_block_id,kendra_id) {
    let { districts, blocks } = this.state;

    const districtObj = blocks.find(
      (key) => key.student_block_id === school_block_id
    );
    let districtId = districtObj.student_district_id;

    const stateObj = districts.find(
      (key) => key.student_district_id === districtId
    );
    let stateId = stateObj.student_state_id;

    this.setState({
      updateSchoolOpen: true,
      updateSchoolId: {
        helperText: null,
        value: school_id,
      },
      updateSchoolName: {
        helperText: null,
        value: school_name,
      },
      updateUdiseNum: {
        helperText: null,
        value: school_udise,
      },
      updateBlock: {
        helperText: null,
        value: school_block_id,
      },
      updateState: {
        helperText: null,
        value: stateId,
      },
      updateDistrict: {
        helperText: null,
        value: districtId,
      },
      updateKendra: {
        helperText: null,
        value: kendra_id,
      },
      singleSchoolFlag: true,
    });
  }

  updateSchoolModalClose() {
    this.setState({
      updateSchoolOpen: false,
      updateSchoolId: {
        helperText: null,
        value: null,
      },
      updateSchoolName: {
        helperText: null,
        value: null,
      },
      updateUdiseNum: {
        helperText: null,
        value: null,
      },
      updateBlock: {
        helperText: null,
        value: null,
      },
      updateState: {
        helperText: null,
        value: null,
      },
      updateDistrict: {
        helperText: null,
        value: null,
      },
      singleSchoolFlag: false,
    });
  }
  schoolInfoModalClose = () => {
    this.setState({
      schoolInfo: false,
      updateSchoolId: {
        helperText: null,
        value: null,
      },
      updateSchoolName: {
        helperText: null,
        value: null,
      },
      updateUdiseNum: {
        helperText: null,
        value: null,
      },
      updateBlock: {
        helperText: null,
        value: null,
      },
      updateState: {
        helperText: null,
        value: null,
      },
      updateDistrict: {
        helperText: null,
        value: null,
      },
      singleSchoolFlag: false,
    });
  };

  schoolInfoModalOpen = (
    school_id,
    school_name,
    school_udise,
    school_block_id
  ) => {
    getHMService()
      .getSMCFormDetails(school_id)
      .then((res) => {
        if (res) {
          console.log(res, "result");
          this.setState({
            headMasterName: res?.data?.response?.head_master,
            version: res?.data?.response?.version,
            contact: res?.data?.response?.head_master_mobile,
          });
        }
      });

    let { districts, blocks } = this.state;

    const districtObj = blocks.find(
      (key) => key.student_block_id === school_block_id
    );
    let districtId = districtObj.student_district_id;

    const stateObj = districts.find(
      (key) => key.student_district_id === districtId
    );
    let stateId = stateObj.student_state_id;

    this.setState({
      schoolInfo: true,
      updateSchoolId: {
        helperText: null,
        value: school_id,
      },
      updateSchoolName: {
        helperText: null,
        value: school_name,
      },
      updateUdiseNum: {
        helperText: null,
        value: school_udise,
      },
      updateBlock: {
        helperText: null,
        value: school_block_id,
      },
      updateState: {
        helperText: null,
        value: stateId,
      },
      updateDistrict: {
        helperText: null,
        value: districtId,
      },
      singleSchoolFlag: true,
    });

    this.setState({
      schoolInfo: true,
    });
    // this.props.history.push(`/admin/schools/${id}`);
  };

  getStateName = () => {
    const stateName = this.state.addStates.find(
      (res) => res.student_state_id == this.state.updateState.value
    );
    return stateName?.state_name;
  };

  getDistrictName = () => {
    const districtName = this.state.addDistricts.find(
      (res) => res.student_district_id == this.state.updateDistrict.value
    );

    return districtName?.district_name;
  };

  getBlockName = () => {
    const blockName = this.state.addBlocks.find(
      (res) => res.student_block_id == this.state.updateBlock.value
    );

    return blockName?.block_name;
  };


  getKendra = async (id ) =>{
      let data = await getSuperAdminService().getAllKendra({
        block_id : id
      })
     if(data.data.status == 200)
     {
      this.setState({
        kendraList : data.data.response
      })
     }else{
      this.setState({
        kendraList : []
      })

     }
     

  }

  render() {
    const {
      states,
      districts,
      blocks,
      schools,
      message,
      error,
      state,
      district,
      block,
      udiseNum,
      schoolName,
      csv,
      resetFile,
      loading,
      searchText,
      updateBlock,
      updateSchoolName,
      updateSchoolId,
      updateUdiseNum,
      addBlock,
      addDistrict,
      addState,
      addStates,
      addBlocks,
      addDistricts,
      updateState,
      updateDistrict,
      updateKendra,
      addkendra
    } = this.state;

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-6 text-left">
              <h3>School List</h3>
              <p>List of the schools</p>
            </div>

            <div className="col-6 text-right">
              <div
                style={{ cursor: "pointer", float: "right" }}
                onClick={() => this.addSchoolModalOpen()}
              >
                <AddCircleIcon style={{ fontSize: "50px" }} color="primary" />
              </div>
            </div>
          </div>

          <Card style={{ marginBottom:'50px'}}>
            <CardBody>
              <Snackbar
                autoHideDuration={5000}
                message={message}
                color={error ? "danger" : "success"}
                close={false}
                place="br"
                closeNotification={() => {
                  this.setState({
                    message: null,
                  });
                }}
                rtlActive={true}
                open={message ? true : false}
              ></Snackbar>

              <div className="row">
                <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl fullWidth>
                      <span id="state-label">Select State</span>
                      <Select
                        labelId="state-label"
                        id="state"
                        value={state.value}
                        error={state.helperText}
                        helperText={!state.value && state.helperText}
                        onChange={(e) => {
                          this.setState(
                            {
                              state: {
                                value: e.target.value,
                                helperText: null,
                              },
                              searchText: { value: "", helperText: null },
                              schools: [],
                            },
                            () => {
                              this.getStudentDistrict(
                                this.state.state.value,
                                this.state.singleSchoolFlag
                              );
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("state")}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl fullWidth>
                      <span id="District">Select District</span>
                      <Select
                        labelId="District"
                        id="District_dropdown"
                        value={district.value}
                        error={district.helperText}
                        helperText={!district.value && district.helperText}
                        onChange={(e) => {
                          this.setState(
                            {
                              district: {
                                value: e.target.value,
                                helperText: null,
                              },
                              block: {
                                helperText: null,
                                value: "",
                              },
                              csvKendraName:'',
                              school: "",
                              schools: [],
                              searchText: { value: "", helperText: null },
                            },
                            () => {
                              this.getStudentBlock(
                                this.state.district.value,
                                this.state.singleSchoolFlag
                              );
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("district")}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl fullWidth>
                      <span id="Block">Select Block</span>
                      <Select
                        labelId="Block"
                        id="Block_dropdown"
                        value={block.value}
                        error={block.helperText}
                        helperText={!block.value && block.helperText}
                        onChange={(e) => {
                          this.setState(
                            {
                              block: {
                                value: e.target.value,
                                helperText: null,
                              },
                              updateBlock: {
                                value: e.target.value,
                                helperText: null,
                              },
                              csvKendraName:'',
                              school: "",
                              searchText: { value: "", helperText: null },
                            },
                            () => {
                              this.getKendra(this.state.block.value)
                              this.getSchools(this.state.block.value);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("block")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                 <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl fullWidth>
                      <span id="Block">Select Kendra</span>
                      <Select
                        labelId="Block"
                        id="Block_dropdown"
                        value={this.state.csvKendraName}
                        // error={block.helperText}
                        // helperText={!block.value && block.helperText}
                        onChange={(e) => {
                          this.setState(
                            {
                              csvKendraName : e.target.value
                            }
                          );
                        }}
                      >
                        {this.state.kendraList?.map((kendra) => {
                            return (
                              <MenuItem
                                key={kendra.kendra_id}
                                value={kendra.kendra_id}
                              >
                                {kendra.kendra_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col">
                  <div>
                    <FormControl fullWidth>
                      <span>Choose CSV file</span>
                      <TextField
                        type="file"
                        error={csv.helperText}
                        key={resetFile}
                        helperText={!csv.value && csv.helperText}
                        onChange={(e) => {
                          this.onFileChange(e);
                        }}
                      />
                      <div>
                        Download sample file{" "}
                        <a href={schoolcsv} download="School.csv">
                          here
                        </a>
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col">
                  {loading ? (
                    <div style={classes.loading}>
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => {
                        this.onCreateSchools();
                      }}
                    >
                      Create School
                    </Button>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <TextField
                    type="text"
                    label="Search"
                    value={searchText.value}
                    error={searchText.helperText}
                    helperText={!searchText.value && searchText.helperText}
                    onChange={(ev) => {
                      if (ev.target.value.length == 0) {
                        this.setState({
                          searchText: {
                            value: null,
                            helperText: null,
                          },
                        });
                        this.getSchools(this.state.block.value);
                      } else if (
                        ev.target.value.length < 3 ||
                        ev.target.value == "" ||
                        ev.target.value == null
                      ) {
                        this.setState({
                          searchText: {
                            value: ev.target.value,
                            helperText: "Please enter at least 3 characters",
                          },
                          schools: [],
                        });
                      } else if (ev.target.value.length >= 3) {
                        this.setState({
                          searchText: {
                            value: ev.target.value,
                            helperText: null,
                          },
                        });
                        this.onChangeHandle(ev.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </React.Fragment>
                      ),
                    }}
                  />
                </div>
                <FormHelperText>
                  ( Type minimun 3 letters to search ){" "}
                </FormHelperText>
              </div>

              <div className="row">{this.renderSchoolDetails()}</div>

              <div>
                <SkipPrevious onClick={()=>{
                  this.setState({
                    PageNum : this.state.PageNum >= 2 ? this.state.PageNum -1 : this.state.PageNum
                  },
                  ()=>{
                    this.getSchools(this.state.block.value)
                  })
                }}/>
            
                <SkipNext onClick={()=>{
                  this.setState({
                    PageNum : Math.round(this.state.PageCount/50) >= this.state.PageNum ? this.state.PageNum +1 : this.state.PageNum
                  },
                  ()=>{
                    this.getSchools(this.state.block.value)
                  })
                }}/>
              </div>
            </CardBody>
          </Card>
       
          <Modal
            open={this.state.addSchoolOpen}
            onClose={() => this.addSchoolModalClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <span
                style={{
                  background: "white",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <Card>
                  <CardHeader color="#fff">
                    <h4 className={classes.cardTitleWhite}>Add School</h4>
                    <span>Add new school</span>
                    <CloseIcon
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      color="primary"
                      onClick={() => this.addSchoolModalClose()}
                    />
                  </CardHeader>
                  <CardBody>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={addState.helperText}
                      >
                        <span id="state-label">State</span>
                        <Select
                          labelId="state-label"
                          id="state"
                          value={addState ? addState.value : null}
                          onChange={(e) => {
                            this.setState(
                              {
                                addState: {
                                  value: e.target.value,
                                  helperText: null,
                                },
                              },
                              () => {
                                this.getStudentDistrict(
                                  e.target.value,
                                  this.state.singleSchoolFlag
                                );
                              }
                            );
                          }}
                        >
                          {addStates.map((state) => {
                            return (
                              <MenuItem
                                key={state.student_state_id}
                                value={state.student_state_id}
                              >
                                {state.state_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={addDistrict.helperText}
                      >
                        <span id="demo-simple-select-label">District</span>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={addDistrict ? addDistrict.value : null}
                          onChange={(e) => {
                            this.setState(
                              {
                                addDistrict: {
                                  value: e.target.value,
                                  helperText: null,
                                },
                              },
                              () => {
                                this.getStudentBlock(
                                  e.target.value,
                                  this.state.singleSchoolFlag
                                );
                              }
                            );
                          }}
                        >
                          {addDistricts.map((district) => {
                            return (
                              <MenuItem
                                key={district.student_district_id}
                                value={district.student_district_id}
                              >
                                {district.district_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={addBlock.helperText}
                      >
                        <span>Taluka/Block</span>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={addBlock ? addBlock.value : null}
                          onChange={(e) => {
                            this.setState({
                              addBlock: {
                                value: e.target.value,
                                helperText: null,
                              },
                            },
                            ()=>{
                              this.getKendra(e.target.value)
                            });
                          }}
                        >
                          {addBlocks.map((block) => {
                            return (
                              <MenuItem
                                key={block.student_block_id}
                                value={block.student_block_id}
                              >
                                {block.block_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{block.helperText}</FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={addkendra.helperText}
                      >
                        <span>Kendra</span>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={addkendra ? addkendra.value : null}
                          onChange={(e) => {
                            this.setState({
                              addkendra: {
                                value: e.target.value,
                                helperText: null,
                              },
                            });
                          }}
                        >
                          {console.log(this.state.kendraList,"this.state.kendraList")}
                         {this.state.kendraList?.map((kendra) => {
                            return (
                              <MenuItem
                                key={kendra.kendra_id}
                                value={kendra.kendra_id}
                              >
                                {kendra.kendra_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{addkendra.helperText}</FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={schoolName.helperText}
                      >
                        <span>School Name</span>
                        <TextField
                          type="text"
                          id="schoolName"
                          name="schoolName"
                          value={schoolName.value}
                          onChange={(e) => {
                            this.setState({
                              schoolName: {
                                value: e.target.value,
                                helperText: null,
                              },
                            });
                          }}
                        />
                        <FormHelperText>{schoolName.helperText}</FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={udiseNum.helperText}
                      >
                        <span>UDise Number</span>
                        <TextField
                          type="text"
                          id="udiseNum"
                          name="udiseNum"
                          value={udiseNum.value}
                          onChange={(e) => {
                            this.setState({
                              udiseNum: {
                                value: e.target.value,
                                helperText: null,
                              },
                            });
                          }}
                        />
                        <FormHelperText>{udiseNum.helperText}</FormHelperText>
                      </FormControl>
                    </div>
                  </CardBody>
                  <CardFooter style={classes.cardFooter}>
                    {loading ? (
                      <div style={classes.loading}>
                        <CircularProgress color="secondary" />
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.onCreateSingleSchool();
                        }}
                      >
                        Create School
                      </Button>
                    )}
                  </CardFooter>
                </Card>
              </span>
            </div>
          </Modal>

          <Modal
            open={this.state.updateSchoolOpen}
            onClose={() => this.updateSchoolModalClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <span
                style={{
                  background: "white",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <Card>
                  <CardHeader color="#fff">
                    <h4 className={classes.cardTitleWhite}>
                      Update School Details
                    </h4>
                    <CloseIcon
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      color="primary"
                      onClick={() => this.updateSchoolModalClose()}
                    />
                  </CardHeader>

                  <CardBody>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateState.helperText}
                      >
                        <span id="state-label">State</span>
                        <Select
                          labelId="state-label"
                          id="state"
                          value={updateState ? updateState.value : null}
                          onChange={(e) => {
                            this.setState(
                              {
                                updateState: {
                                  value: e.target.value,
                                  helperText: null,
                                },
                              },
                              () => {
                                this.getStudentDistrict(
                                  e.target.value,
                                  this.state.singleSchoolFlag
                                );
                              }
                            );
                          }}
                        >
                          {addStates.map((state) => {
                            return (
                              <MenuItem
                                key={state.student_state_id}
                                value={state.student_state_id}
                              >
                                {state.state_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateDistrict.helperText}
                      >
                        <span id="demo-simple-select-label">District</span>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={updateDistrict ? updateDistrict.value : null}
                          onChange={(e) => {
                            this.setState(
                              {
                                updateDistrict: {
                                  value: e.target.value,
                                  helperText: null,
                                },
                              },
                              () => {
                                this.getStudentBlock(
                                  e.target.value,
                                  this.state.singleSchoolFlag
                                );
                              }
                            );
                          }}
                        >
                          {addDistricts.map((district) => {
                            return (
                              <MenuItem
                                key={district.student_district_id}
                                value={district.student_district_id}
                              >
                                {district.district_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateBlock.helperText}
                      >
                        <span>Taluka/Block</span>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={updateBlock ? updateBlock.value : null}
                          onChange={(e) => {
                            this.setState({
                              updateBlock: {
                                value: e.target.value,
                                helperText: null,
                              },
                            },
                            ()=>{
                              this.getKendra( e.target.value)
                            });
                          }}
                        >
                          {addBlocks.map((block) => {
                            return (
                              <MenuItem
                                key={block.student_block_id}
                                value={block.student_block_id}
                              >
                                {block.block_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {updateBlock.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateSchoolName.helperText}
                      >
                        <span>School Name</span>
                        <TextField
                          type="text"
                          id="schoolName"
                          name="schoolName"
                          value={updateSchoolName.value}
                          onChange={(e) => {
                            this.setState({
                              updateSchoolName: {
                                value: e.target.value,
                                helperText: null,
                              },
                            });
                          }}
                        />
                        <FormHelperText>
                          {updateSchoolName.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateUdiseNum.helperText}
                      >
                        <span>UDise Number</span>
                        <TextField
                          type="text"
                          id="udiseNum"
                          name="udiseNum"
                          value={updateUdiseNum.value}
                          onChange={(e) => {
                            this.setState({
                              updateUdiseNum: {
                                value: e.target.value,
                                helperText: null,
                              },
                            });
                          }}
                        />
                        <FormHelperText>
                          {updateUdiseNum.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>


                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateBlock.helperText}
                      >
                        <span>Kendra Name</span>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={updateKendra ? updateKendra.value : null}
                          onChange={(e) => {
                            this.setState({
                              updateKendra: {
                                value: e.target.value,
                                helperText: null,
                              },
                            });
                          }}
                        >
                          {this.state.kendraList?.map((kendra) => {
                            return (
                              <MenuItem
                                key={kendra.kendra_id}
                                value={kendra.kendra_id}
                              >
                                {kendra.kendra_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {updateKendra.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <input type="hidden" value={updateSchoolId.value} />
                  </CardBody>
                  <CardFooter style={classes.cardFooter}>
                    {loading ? (
                      <div style={classes.loading}>
                        <CircularProgress color="secondary" />
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.onUpdateSchool();
                        }}
                      >
                        Update School
                      </Button>
                    )}
                  </CardFooter>
                </Card>
              </span>
            </div>
          </Modal>
          <Modal
            open={this.state.schoolInfo}
            onClose={() => this.schoolInfoModalClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <span
                style={{
                  background: "white",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <Card>
                  <CardHeader color="#fff">
                    <h4 className={classes.cardTitleWhite}>School Details</h4>
                    <CloseIcon
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      color="primary"
                      onClick={() => this.schoolInfoModalClose()}
                    />
                  </CardHeader>

                  <CardBody>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateState.helperText}
                      >
                        <span id="state-label">State</span>
                        <p>{this.getStateName()}</p>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateDistrict.helperText}
                      >
                        <span id="demo-simple-select-label">District</span>
                        <p>{this.getDistrictName()}</p>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateBlock.helperText}
                      >
                        <span>Taluka/Block</span>
                        <p>{this.getBlockName()}</p>

                        <FormHelperText>
                          {updateBlock.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateSchoolName.helperText}
                      >
                        <span>School Name</span>
                        <p>{this.state.updateSchoolName.value}</p>
                        <FormHelperText>
                          {updateSchoolName.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateUdiseNum.helperText}
                      >
                        <span>UDise Number</span>
                        <p>{this.state.updateUdiseNum.value}</p>

                        <FormHelperText>
                          {updateUdiseNum.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateUdiseNum.helperText}
                      >
                        <span>Headmaster Name</span>
                        {this.state.headMasterName !== "" ? (
                          <p>{this.state.headMasterName}</p>
                        ) : (
                          <p>N/A</p>
                        )}

                        <FormHelperText>
                          {updateUdiseNum.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateUdiseNum.helperText}
                      >
                        <span>Contact No</span>

                        {this.state.contact !== "" ? (
                          <p>{this.state.contact}</p>
                        ) : (
                          <p>N/A</p>
                        )}
                        <FormHelperText>
                          {updateUdiseNum.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div style={styles.inputWrapper}>
                      <FormControl
                        style={classes.dropdown}
                        error={updateUdiseNum.helperText}
                      >
                        <span>Version</span>
                        {this.state.version !== "" ? (
                          <p>{this.state.version}</p>
                        ) : (
                          <p>N/A</p>
                        )}

                        <FormHelperText>
                          {updateUdiseNum.helperText}
                        </FormHelperText>
                      </FormControl>
                    </div>

                    <input type="hidden" value={updateSchoolId.value} />
                  </CardBody>
                </Card>
              </span>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Schools;
