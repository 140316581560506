import React from "react";
import Modal from "@material-ui/core/Modal";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import classes from "./SchoolDetail.module.css";
import { getHMService } from "service/service";
import Snackbar from "./../../components/Snackbar/Snackbar";
import {
  FormControlLabel,
  Switch,
  Checkbox,
  Icon,
  InputLabel,
  Button,
} from "@material-ui/core";
import {
  Add,
  Call,
  CloudDownloadOutlined,
  Delete,
  Save,
} from "@material-ui/icons";
import Activity from "views/Activity/Activity";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span>{children}</span>}
    </div>
  );
}
let schoolInfo = JSON.parse(localStorage.getItem("HMSchool"));
// let checkSchoolDetails = schoolInfo.school_detail != undefined;

class SchoolDetail extends React.Component {
  state = {
    isAddMember: false,
    isAddDetail: false,
    isEditDetail: false,
    memberForm: [
      {
        name: "",
        phone: "",
      },
    ],
    emptyForm: {
      name: "",
      phone: "",
    },
    error: false,
    message: "",
    HMDetails: {},
    // HMSchool: JSON.parse(localStorage.getItem("HMSchool")),
    HMSchool: JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool"))
      : JSON.parse(localStorage.getItem("AMdetails"))
      ? JSON.parse(localStorage.getItem("AMdetails"))
      : null,
    isHMDetailsPresent: false,
    isSMCDetailsPresent: false,
    smcDetails: {},
    WHOLE_BUILDING: null,
    LABS: null,
    GROUND: null,
    COMPOUND: null,
    CLASSROOMS: null,
    whole_building: "",
    labs: "",
    ground: "",
    compound: "",
    classrooms: "",
    tabIndex: 0,
    classCheck: false,
    groundCheck: false,
    washroomCheck: false,
    internetCheck: false,
    computerCheck: false,
    electricityCheck: false,
    wallCheck: false,
    libraryCheck: false,
    primaryInfo: true,
    schoolInfo: false,
    specialInfo: false,
    activity: false,
    meetArray: [],
    emptyMeet: { meetings_date: "", meetings_attendance: "", reports: "" },
    currentImage: 0,
    schoolNewDetails: [],
    managementDetails: [],
    memberArray: [],
    futureMeet: [],
    allVersion: [],
    selectedVersion: "",
    extraDetails: [],
    extraInfo: false,
    pdfURL: null,
    yearList: null,
    YearValue: null,
    quaterList: null,
    quaterValue: null,
    NoForm: false,
  };

  componentDidMount() {
    this.setFlag();
    this.getYears();

    // this.getVersion()
  }

  getYears = () => {
    getHMService()
      .getYearHm()
      .then((res) => {
        console.log(res, "res");
        if (res.data) {
          this.setState({
            yearList: res.data.years,
          });
        }
      });
  };

  getHalfarray = () => {
    getHMService()
      .getHalfValues(this.state.YearValue)
      .then((res) => {
        console.log(res, "res");
        if (res.data) {
          this.setState(
            {
              quaterList: res.data.quaters,
              disableStatus: false,
            },
            () => {}
          );
        }
      });
  };

  getSchoolDetailsForHm = () => {
    let param = {
      quarter: this.state.quaterValue,
      year: this.state.YearValue,
      school: this.state.HMSchool?.school_id,
    };
    console.log(param, "param");

    getHMService()
      .getSchoolDetailsHm(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.response) {
          this.setState({
            isTeacherDetails: true,
            NoForm: false,
            schoolNewDetails: res.data.response,
            futureMeet: res.data.response.smc_future_meetings,
            meetArray: res.data.response.smc_meetings,
            memberArray: res.data.response.members,
            pdfURL: res.data.response.pdf_url,
          });
        } else {
          this.setState({
            isTeacherDetails: false,
            NoForm: true,
          });
        }
      });
  };
  getVersion = () => {
    const url = JSON.parse(localStorage.getItem("HMSchool"))
      ? "get_all_version"
      : `get_all_version/?school_id=${this.state.HMSchool.school_id}`;

    getHMService()
      .getVersionName(url)
      .then((res) => {
        console.log(res.data.response.length, "res");
        if (res.data.response.length > 0) {
          this.setState(
            {
              allVersion: res.data.response,
              selectedVersion: res.data.response[0].id,
            },
            () => {
              this.getSMCformDetails();
            }
          );
        }
      });
  };

  handleChange(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  displaySchoolDetails = () => {
    let { school_detail: schoolDetails } = this.state.HMSchool;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-6">
            <Card>
              <CardHeader>
                <div className={classes.formHead}>Headmaster Details</div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Name : {schoolDetails.head_master}
                  </div>
                  <div className="col-6">
                    {" "}
                    Phone : {schoolDetails.head_master_mobile}
                  </div>
                  <div className="col-6">
                    {" "}
                    Email : {schoolDetails.head_master_email}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-6">
            <Card>
              <CardHeader style={{ padding: "10px" }}>
                <div style={{ padding: "1px" }} className={classes.formHead}>
                  Additional Teacher Details
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Name : {schoolDetails.additional_teacher}
                  </div>
                  <div className="col-6">
                    {" "}
                    Phone : {schoolDetails.additional_teacher_mobile}
                  </div>
                  <div className="col-6"> &nbsp;</div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-6">
            <Card>
              <CardHeader>
                <div style={{ padding: "1px" }} className={classes.formHead}>
                  School Teacher Details
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Total Count : {schoolDetails.total_teacher}
                  </div>
                  <div className="col-6"></div>
                  <div className="col-6">
                    {" "}
                    Male : {schoolDetails.male_teacher}
                  </div>
                  <div className="col-6">
                    {" "}
                    Female : {schoolDetails.female_teacher}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-6">
            <Card>
              <CardHeader>
                <div className={classes.formHead}>Student Details</div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Total Count : {schoolDetails.total_student}
                  </div>
                  <div className="col-6"></div>
                  <div className="col-6"> Boys : {schoolDetails.boys}</div>
                  <div className="col-6"> Girls : {schoolDetails.girls}</div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-6">
            <Card>
              <CardHeader>
                <div className={classes.formHead}>School Things</div>
              </CardHeader>
              {console.log(schoolDetails, "schoolDetails")}
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    Classrooms : {schoolDetails.is_class_rooms ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    washroom : {schoolDetails.is_washroom ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    computer room :{" "}
                    {schoolDetails.is_computer_room ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Internet : {schoolDetails.is_internet ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Compound Wall : {schoolDetails.is_compound ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Library : {schoolDetails.is_library ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Ground : {schoolDetails.is_ground ? "✔️" : "❌"}
                  </div>
                  <div className="col-6">
                    {" "}
                    Electricity : {schoolDetails.is_electricity ? "✔️" : "❌"}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="row">
            <div className="col-3">
              {schoolDetails.whole_building &&
                this.previewImage(schoolDetails.whole_building, "Building")}
            </div>

            <div className="col-3">
              {schoolDetails.labs &&
                this.previewImage(schoolDetails.labs, "Labs")}
            </div>

            <div className="col-3">
              {schoolDetails.ground &&
                this.previewImage(schoolDetails.ground, "Ground")}
            </div>

            <div className="col-3">
              {schoolDetails.compound &&
                this.previewImage(schoolDetails.compound, "Compound")}
            </div>

            <div className="col-3">
              {schoolDetails.classrooms &&
                this.previewImage(schoolDetails.classrooms, "Classroom")}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  previewImage = (url, title) => {
    return (
      <Card>
        <CardHeader>
          <div className={classes.formHead}>{title}</div>
        </CardHeader>
        <CardBody>
          <img src={url} id="imgPrev" className={classes.imgPreview} />
        </CardBody>
      </Card>
    );
  };

  getSchoolDetails = () => {
    getHMService()
      .getMySchool()
      .then((res) => {
        let data = res.data;
        if (data.status == 200) {
          this.setState(
            {
              HMSchool: data.response,
            },
            () => {
              localStorage.setItem(
                "HMSchool",
                JSON.stringify(this.state.HMSchool)
              );
              this.setFlag();
            }
          );
        }
      });
  };

  setFlag = () => {
    let HMflag = false;
    let SMCFlag = false;
    console.log(this.state.HMSchool.smc, "this.state.HMSchool.smc");
    if (this.state.HMSchool.school_detail) {
      HMflag = true;
    }
    if (this.state.HMSchool.smc) {
      SMCFlag = true;
    }
    this.setState({
      isHMDetailsPresent: HMflag,
      isSMCDetailsPresent: SMCFlag,
    });
  };

  onSMCFieldChange = (e, key) => {
    let smcDetailsObj = { ...this.state.smcDetails };
    this.setState({
      smcDetails: { ...smcDetailsObj, [key]: e.target.value },
    });
  };

  renderMemberForm = () => {
    let { memberForm, smcDetails, isSMCDetailsPresent } = this.state;
    let form = [];
    memberForm.map((data, index) => {
      form.push(
        <div
          className={["row", classes.formFields]
            .toString()
            .split(",")
            .join(" ")}
          key={index}
        >
          <div className="col-5">
            <span>Name</span>
            <span>
              <input
                type="text"
                className="form-control"
                value={data.name}
                onChange={(e) => this.onValChange(true, e, index)}
              />
            </span>
          </div>
          <div className="col-5">
            <span>Phone</span>
            <span>
              <input
                type="number"
                className="form-control"
                value={data.phone}
                onChange={(e) => this.onValChange(false, e, index)}
              />
            </span>
          </div>
          <div className="col-2">
            <span>
              <br></br>
            </span>
            <span>
              <button
                className="btn btn-danger"
                onClick={() => this.removeUser(index)}
              >
                <ClearIcon />
              </button>
            </span>
          </div>
        </div>
      );
    });
    let formWrap = (
      <div className="row">
        <div className="col-6">
          <span>Budget</span>
          <span>
            <input
              type="text"
              className="form-control"
              value={smcDetails.budget}
              onChange={(e) => this.onSMCFieldChange(e, "budget")}
            />
          </span>
        </div>
        <div className="col-6">
          <span>Acheivement</span>
          <span>
            <input
              type="text"
              className="form-control"
              value={smcDetails.achievement}
              onChange={(e) => this.onSMCFieldChange(e, "achievement")}
            />
          </span>
        </div>
        <div className="col-6">
          <span>Challenges</span>
          <span>
            <input
              type="text"
              className="form-control"
              value={smcDetails.challenges}
              onChange={(e) => this.onSMCFieldChange(e, "challenges")}
            />
          </span>
        </div>
        <div className="col-6">
          <span>Meetings</span>
          <span>
            <input
              type="text"
              className="form-control"
              value={smcDetails.meetings}
              onChange={(e) => this.onSMCFieldChange(e, "meetings")}
            />
          </span>
        </div>
        <div className="col-6">
          <span>Roles and responsibility</span>
          <span>
            <input
              type="text"
              className="form-control"
              value={smcDetails.roles_and_responsibility}
              onChange={(e) =>
                this.onSMCFieldChange(e, "roles_and_responsibility")
              }
            />
          </span>
        </div>
        <div className="col-6">
          <span>School grant received</span>
          <span>
            <input
              type="text"
              className="form-control"
              value={smcDetails.school_grant_received}
              onChange={(e) =>
                this.onSMCFieldChange(e, "school_grant_received")
              }
            />
          </span>
        </div>
        <div className="row"></div>
        <br />
        <hr />
        <div
          className={["row", classes.center].toString().split(",").join(" ")}
        >
          Members
        </div>
        {form}
        <div
          className={["m-2", classes.headSection]
            .toString()
            .split(",")
            .join(" ")}
        >
          <div>
            <button className="btn btn-primary" onClick={() => this.addUser()}>
              Add Member
            </button>
          </div>
          {!isSMCDetailsPresent ? (
            <div>
              <button
                className="btn btn-primary"
                onClick={() => this.onSubmitUserForm(false)}
              >
                Submit
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn btn-primary"
                onClick={() => this.onSubmitUserForm(true)}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </div>
    );
    return formWrap;
  };

  onSubmitUserForm = (flag) => {
    let { school_id } = this.state.HMSchool;
    let { smcDetails } = this.state;
    let reqBody = {
      members: [...this.state.memberForm],
      school: school_id,
      ...smcDetails,
    };

    console.log(reqBody, "reqBody onSubmitUserForm", this.state.smcDetails);
    if (!flag) {
      getHMService()
        .addSMCDetails(reqBody)
        .then(
          (res) => {
            this.setState(
              {
                message: "SMC data added Successfully",
                error: false,
                isAddMember: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            this.setState({
              message: err,
              error: true,
              isAddMember: false,
            });
          }
        );
    } else {
      getHMService()
        .updateSMCDetails(reqBody, smcDetails.id)
        .then(
          (res) => {
            this.setState(
              {
                message: "SMC data updated Successfully",
                error: false,
                isAddMember: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            this.setState({
              message: err,
              error: true,
              isAddMember: false,
            });
          }
        );
    }
  };

  addUser = () => {
    let emptyForm = JSON.stringify(this.state.emptyForm);
    let memberForm = [...this.state.memberForm];
    memberForm.push(JSON.parse(emptyForm));
    this.setState(
      {
        memberForm,
      },
      () => {
        console.log(this.state.memberForm, "memberform");
      }
    );
  };

  removeUser = (index) => {
    let memberForm = [...this.state.memberForm];
    if (index > 0) {
      memberForm.splice(index, 1);
      this.setState({
        memberForm,
      });
    }
  };

  onValChange = (flag, event, index) => {
    let memberForm = [...this.state.memberForm];
    if (flag) {
      memberForm[index].name = event.target.value;
    } else {
      memberForm[index].phone = event.target.value;
    }
    this.setState({
      memberForm,
    });
  };

  renderAddMemberDialog = () => {
    return (
      <Modal
        open={this.state.isAddMember}
        onClose={() => {
          this.setState({
            isAddMember: false,
          });
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <span
            style={{
              background: "white",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "50%",
            }}
          >
            <div className={classes.modalHead}>
              <div>&nbsp;</div>
              <div>SMC Details</div>
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    this.setState({
                      isAddMember: false,
                      memberForm: [
                        {
                          name: "",
                          phone: "",
                        },
                      ],
                      smcDetails: {},
                    });
                  }}
                >
                  <ClearIcon />
                </button>
              </div>
            </div>
            <div>{this.renderMemberForm()}</div>
          </span>
        </div>
      </Modal>
    );
  };

  onSMCDetailFieldChange = (e, key) => {
    console.log("onSMCDetailFieldChange onchange", e.target.value);
    let smcDetilsObj = { ...this.state.HMDetails };
    this.setState({
      HMDetails: { ...smcDetilsObj, [key]: e.target.value },
    });
  };

  onSMCDetailFileUpload = (e, field) => {
    this.setState({
      [field]: e.target.files[0],
    });
    let elem = document.getElementById(field);
    elem.src = URL.createObjectURL(e.target.files[0]);
  };

  onSelectedFileUpload = (key) => {
    let { school_id } = this.state.HMSchool;
    let file = this.state[key];
    let fd = new FormData();
    fd.append("image", file);
    fd.append("school_id", school_id);
    getHMService()
      .uploadSchoolImages(fd)
      .then(
        (res) => {
          console.log(res, "res after img upload");
          let field = key.toString().toLowerCase();
          this.setState({
            message: `${key.toString().toLowerCase()} uploaded Successfully`,
            error: false,
            [field]: res.data.file,
          });
        },
        (err) => {
          console.log("err", err);
          this.setState({
            message: "Something went wrong",
            error: true,
          });
        }
      );
  };

  previewFile = (key) => {
    console.log(this.state[key], "key from previewFile");
    let url = window.URL.createObjectURL(this.state[key]);
    return (
      <div>
        <img src={url} />
      </div>
    );
  };

  // addNewMeet = () => {
  //   let emptyMeet = { meetings_date: "", meetings_attendance: "", reports: "" };
  //   console.log(this.state.meetArray, "memberform", emptyMeet);
  //   let meetArray = [...this.state.meetArray];
  //   meetArray.push(emptyMeet);
  //   this.setState(
  //     {
  //       meetArray,
  //     },
  //     () => {
  //       console.log(this.state.meetArray, "memberform");
  //     }
  //   );
  // };

  // addNewMember = () => {
  //   let emptyMeet = { name: "", phone: "", gender: "", caste: "" };
  //   console.log(this.state.meetArray, "memberform", emptyMeet);
  //   let memberArray = [...this.state.memberArray];
  //   memberArray.push(emptyMeet);
  //   this.setState(
  //     {
  //       memberArray,
  //     },
  //     () => {
  //       console.log(this.state.memberArray, "memberform");
  //     }
  //   );
  // };

  addFutureMeet = () => {
    let emptyMeet = { meetings_date: "" };
    console.log(this.state.futureMeet, "memberform", emptyMeet);
    let futureMeet = [...this.state.futureMeet];
    futureMeet.push(emptyMeet);
    this.setState(
      {
        futureMeet,
      },
      () => {
        console.log(this.state.futureMeet, "memberform");
      }
    );
  };

  deleteMeet = (id) => {
    console.log(id, "id");
    getHMService()
      .deleteSMCMeeting(id)
      .then((res) => {
        console.log(res, "rse");
      });
    // let meetArray = [...this.state.meetArray];
    // meetArray.splice(id,1);
    // this.setState(
    //   {
    //     meetArray,
    //   },
    //   () => {
    //     console.log(this.state.meetArray, "memberform");
    //   }
  };

  deleteMember = (id) => {
    console.log(id, "id");
    getHMService()
      .deleteSMCMember(id)
      .then((res) => {
        console.log(res, "rse");
      });
  };
  renderHMDform = () => {
    let {
      HMDetails,
      isEditDetail,
      whole_building,
      labs,
      ground,
      compound,
      classrooms,
      tabIndex,
    } = this.state;
    return (
      <>
        <div className="row" style={{ padding: "10px" }}>
          <div className="col-6">
            <span>Class Type</span>
            <span>
              <input
                type="text"
                className="form-control"
                value={HMDetails.class_type}
                onChange={(e) => this.onSMCDetailFieldChange(e, "class_type")}
              />
            </span>
          </div>
        </div>
        <hr />

        <Tabs
          value={tabIndex}
          onChange={this.handleChange.bind(this)}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="HM Details"
            {...a11yProps(0)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="Additinal Teacher Details"
            {...a11yProps(1)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="Teacher Count"
            {...a11yProps(2)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="Student Count"
            {...a11yProps(3)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="School Gallery"
            {...a11yProps(4)}
          ></Tab>
          <Tab
            style={{ fontSize: "15px", fontWeight: "bold" }}
            label="School things"
            {...a11yProps(5)}
          ></Tab>
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <div className="row" style={{ padding: "10px" }}>
            <div className={classes.formHead} style={{ padding: "10px" }}>
              Headmaster Details
            </div>
            <div className="col-6">
              <span>Name</span>
              <span>
                <input
                  type="text"
                  className="form-control"
                  value={HMDetails.head_master}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "head_master")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Phone</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.head_master_mobile}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "head_master_mobile")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Email</span>
              <span>
                <input
                  type="email"
                  className="form-control"
                  value={HMDetails.head_master_email}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "head_master_email")
                  }
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <div className="row">
            <div className={classes.formHead}>Additinal Teacher Details</div>
            <div className="col-6">
              <span>Phone</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.additional_teacher_mobile}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "additional_teacher_mobile")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Email</span>
              <span>
                <input
                  type="email"
                  className="form-control"
                  value={HMDetails.additional_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "additional_teacher")
                  }
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <div className="row">
            <div className={classes.formHead}>Teacher Count</div>
            <div className="col-6">
              <span>Total</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.total_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "total_teacher")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Male</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.male_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "male_teacher")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Female</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.female_teacher}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "female_teacher")
                  }
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          <div className="row">
            <div className={classes.formHead}>Student Count</div>
            <div className="col-6">
              <span>Total</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.total_student}
                  onChange={(e) =>
                    this.onSMCDetailFieldChange(e, "total_student")
                  }
                />
              </span>
            </div>
            <div className="col-6">
              <span>Boys</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.boys}
                  onChange={(e) => this.onSMCDetailFieldChange(e, "boys")}
                />
              </span>
            </div>
            <div className="col-6">
              <span>Girls</span>
              <span>
                <input
                  type="number"
                  className="form-control"
                  value={HMDetails.girls}
                  onChange={(e) => this.onSMCDetailFieldChange(e, "girls")}
                />
              </span>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={4}>
          <div
            style={{
              height: "500px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div style={{ padding: "10px" }} className={classes.formHead}>
              School Gallery
            </div>
            <div className="row">
              <div>Building</div>
              {whole_building ? (
                <>
                  <div className="col-6">
                    <img src={whole_building} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          whole_building: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) =>
                        this.onSMCDetailFileUpload(e, "WHOLE_BUILDING")
                      }
                    />
                  </div>
                  <div className="col-4">
                    <img
                      id="WHOLE_BUILDING"
                      src=""
                      width="100px"
                      height="100px"
                    />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        this.onSelectedFileUpload("WHOLE_BUILDING")
                      }
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Labs</div>
              {labs ? (
                <>
                  <div className="col-6">
                    <img src={labs} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          labs: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) => this.onSMCDetailFileUpload(e, "LABS")}
                    />
                  </div>
                  <div className="col-4">
                    <img id="LABS" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("LABS")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Ground</div>
              {ground ? (
                <>
                  <div className="col-6">
                    <img src={ground} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          ground: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) => this.onSMCDetailFileUpload(e, "GROUND")}
                    />
                  </div>
                  <div className="col-4">
                    <img id="GROUND" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("GROUND")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Compound</div>
              {compound ? (
                <>
                  <div className="col-6">
                    <img src={compound} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          compound: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) =>
                        this.onSMCDetailFileUpload(e, "COMPOUND")
                      }
                    />
                  </div>
                  <div className="col-4">
                    <img id="COMPOUND" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("COMPOUND")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div>Class room</div>
              {classrooms ? (
                <>
                  <div className="col-6">
                    <img src={classrooms} width="100px" height="100px" />
                  </div>
                  <div className="col-6">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          classrooms: null,
                        });
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={(e) =>
                        this.onSMCDetailFileUpload(e, "CLASSROOMS")
                      }
                    />
                  </div>
                  <div className="col-4">
                    <img id="CLASSROOMS" src="" width="100px" height="100px" />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onSelectedFileUpload("CLASSROOMS")}
                    >
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel value={tabIndex} index={5}>
          <div>School Things</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.classCheck}
                      onChange={(e) => {
                        this.setState({
                          classCheck: !this.state.classCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Classroom"
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.groundCheck}
                      onChange={(e) => {
                        this.setState({
                          groundCheck: !this.state.groundCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Ground"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.wallCheck}
                      onChange={(e) => {
                        this.setState({
                          wallCheck: !this.state.wallCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="Compund Wall"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.internetCheck}
                      onChange={(e) => {
                        this.setState({
                          internetCheck: !this.state.internetCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="Internet"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10",
              }}
            >
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.libraryCheck}
                      onChange={(e) => {
                        this.setState({
                          libraryCheck: !this.state.libraryCheck,
                        });
                      }}
                      name="Phone"
                      color="primary"
                    />
                  }
                  label="Library"
                />
              </div>
              <div style={{ marginHorizontal: "10" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.washroomCheck}
                      onChange={(e) => {
                        this.setState({
                          washroomCheck: !this.state.washroomCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Washroom"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.computerCheck}
                      onChange={(e) => {
                        this.setState({
                          computerCheck: !this.state.computerCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Computer room"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.electricityCheck}
                      onChange={(e) => {
                        this.setState({
                          electricityCheck: !this.state.electricityCheck,
                        });
                      }}
                      //   name="Phone"
                      color="primary"
                    />
                  }
                  label="Electricity"
                />
              </div>
            </div>
          </div>
        </TabPanel>

        <div
          className={["m-2", classes.headSection]
            .toString()
            .split(",")
            .join(" ")}
        >
          <div>
            <button
              style={{ backgroundColor: "#FFF", borderColor: "#18BD5B" }}
              // className="btn btn-primary"
              onClick={() => {
                this.setState({
                  isAddDetail: false,
                  isEditDetail: false,
                });
              }}
            >
              Cancel
            </button>
          </div>

          <div>
            <button
              // className="btn btn-primary"
              style={{
                backgroundColor: "#18BD5B",
                color: "#fff",
                borderColor: "#18BD5B",
              }}
              onClick={() => this.onSubmitSMCDetails(isEditDetail)}
            >
              {!isEditDetail ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </>
    );
  };

  getVal = (data) => {
    let obj = {};
    for (let key in data) {
      obj[key] = data[key] ? data[key] : "";
    }
    return obj;
  };

  onEditDetails = () => {
    let { school_detail } = this.state.HMSchool;
    this.setState({
      isEditDetail: true,
      isAddDetail: true,
      HMDetails: this.getVal(school_detail),
      whole_building: school_detail.whole_building,
      labs: school_detail.labs,
      ground: school_detail.ground,
      compound: school_detail.compound,
      classrooms: school_detail.classrooms,
    });
  };

  onSubmitSMCDetails = (flag) => {
    let formVal = { ...this.state.HMDetails };

    let { school_id } = this.state.HMSchool;
    let { whole_building, labs, ground, compound, classrooms } = this.state;
    let req_body = {
      school: school_id,
      ...formVal,
      whole_building,
      labs,
      ground,
      compound,
      classrooms,
      is_class_rooms: this.state.classCheck,
      is_library: this.state.libraryCheck,
      is_ground: this.state.groundCheck,
      is_washroom: this.state.washroomCheck,
      is_compound: this.state.wallCheck,
      is_computer_room: this.state.computerCheck,
      is_internet: this.state.internetCheck,
      is_electricity: this.state.electricityCheck,
    };
    console.log(req_body, "req_body --- onSubmitSMCDetails");
    if (!flag) {
      getHMService()
        .createSchoolDetails(req_body)
        .then(
          (req) => {
            this.setState(
              {
                message: "School details created",
                error: false,
                HMDetails: {},
                isAddDetail: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            console.log(err, "err while adding details.");
            this.setState({
              message: "Something went wrong",
              error: true,
              isAddDetail: false,
            });
          }
        );
    } else {
      let { id: details_id } = this.state.HMSchool.school_detail;
      getHMService()
        .editSchoolDetail(req_body, details_id)
        .then(
          (req) => {
            this.setState(
              {
                message: "School details Updated",
                error: false,
                HMDetails: {},
                isAddDetail: false,
              },
              () => {
                this.getSchoolDetails();
              }
            );
          },
          (err) => {
            console.log(err, "err while adding details.");
            this.setState({
              message: "Something went wrong",
              error: true,
              isAddDetail: false,
            });
          }
        );
    }
  };

  renderAddDetailDialog = () => {
    return (
      <Modal
        open={this.state.isAddDetail}
        onClose={() => {
          this.setState({
            isAddDetail: false,
          });
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "95%",
          }}
        >
          <span
            style={{
              background: "white",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "50%",
              backgroundColor: "#DCE6E3",
            }}
          >
            <div
              className={classes.modalHead}
              style={{ backgroundColor: "#335559" }}
            >
              <div>&nbsp;</div>
              <div style={{ color: "#fff", fontSize: "35px" }}>
                School Details
              </div>
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    this.setState({
                      isAddDetail: false,
                    });
                  }}
                >
                  <ClearIcon />
                </button>
              </div>
            </div>
            <div>{this.renderHMDform()}</div>
          </span>
        </div>
      </Modal>
    );
  };

  renderMembers = (memData) => {
    let members = [];
    if (memData && memData.length) {
      memData.map((data, index) => {
        members.push(
          <tr key={index}>
            <td> {data.name}</td>
            <td> {data.phone}</td>
          </tr>
        );
      });
    }
    return members;
  };

  onEditSMCDetails = (flag) => {
    console.log(flag, "flag from onEditSMCDetails");
    this.setState(
      {
        isAddMember: true,
      },
      () => {
        if (flag) {
          this.setSMCDetails();
        }
      }
    );
  };

  setSMCDetails = () => {
    let { smc } = this.state.HMSchool;
    this.setState({
      smcDetails: smc,
      memberForm: smc.members,
    });
  };

  // saveMeet = () => {
  //   console.log(this.state.meetArray);
  //   let array = [];
  //   let meetArray = [...this.state.meetArray];
  //   meetArray.map((res) => {
  //     console.log(res.hasOwnProperty("id"), "id");
  //     if (!res.hasOwnProperty("id")) {
  //       array.push(res);
  //     }
  //   });
  //   console.log(this.state.HMSchool.school_id, "sc", array);
  //   let param = {
  //     smc_meetings: array,
  //     school: this.state.HMSchool.school_id,
  //     school_details: this.state.schoolNewDetails.id,
  //   };

  //   getHMService()
  //     .updateSMCMember(param)
  //     .then((res) => {
  //       console.log(res, "res");
  //     });
  // };

  saveMember = () => {
    let array = [];
    let memberArray = [...this.state.memberArray];
    console.log(this.state.HMSchool.school_id, "sc");
    memberArray.map((res) => {
      console.log(res.hasOwnProperty("id"), "id");
      if (!res.hasOwnProperty("id")) {
        array.push(res);
      }
    });
    let param = {
      members: array,
      school: this.state.HMSchool.school_id,
      school_details: this.state.schoolNewDetails.id,
    };

    getHMService()
      .updateSMCMember(param)
      .then((res) => {
        console.log(res, "res");
      });
  };

  saveFutureMeet = () => {
    let array = [];
    console.log(this.state.futureMeet, "this.state.futureMeet");
    let futureMeet = [...this.state.futureMeet];
    console.log(this.state.HMSchool.school_id, "sc");

    futureMeet.map((res) => {
      console.log(res.hasOwnProperty("id"), "id");
      if (!res.hasOwnProperty("id")) {
        array.push(res);
      }
    });
    let param = {
      smc_future_meetings: array,
      school: this.state.HMSchool.school_id,
      school_details: this.state.schoolNewDetails.id,
    };

    getHMService()
      .updateSMCMember(param)
      .then((res) => {
        console.log(res, "res");
        if (res) {
          this.getSMCformDetails();
        }
      });
  };
  formsVal = (value, id, type) => {
    console.log(value, id, type, "valid", this.state.meetArray);
    console.log(this.state.meetArray[id]);
    let meetArray = [...this.state.meetArray];

    if (type == "1") {
      meetArray[id].meetings_date = value;
    } else if (type == "2") {
      meetArray[id].meetings_attendance = value;
    } else if (type == "3") {
      meetArray[id].reports = value;
    }

    console.log(meetArray, "meetarralast");
  };

  MemberAdd = (value, id, type) => {
    console.log(value, id, type, "valid", this.state.memberArray);
    console.log(this.state.memberArray[id]);
    let memberArray = [...this.state.memberArray];

    if (type == "1") {
      memberArray[id].name = value;
    } else if (type == "2") {
      memberArray[id].gender = value;
    } else if (type == "3") {
      memberArray[id].caste = value;
    } else if (type == "4") {
      memberArray[id].phone = value;
    }

    console.log(memberArray, "memberArray");
  };

  addFutureMeetDetails = (value, id, type) => {
    console.log(value, id, type, "valid", this.state.futureMeet);
    console.log(this.state.futureMeet[id]);
    let futureMeet = [...this.state.futureMeet];

    if (type == "1") {
      futureMeet[id].meetings_date = value;
    }

    console.log(futureMeet, "memberArray");
  };

  sliderContainer = () => {
    console.log(schoolInfo, "schoolInfo", this.state.schoolNewDetails);

    const imageArr = [
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.whole_building !== undefined
            ? this.state.schoolNewDetails.whole_building
            : null,
        id: 4,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.office !== undefined
            ? this.state.schoolNewDetails.office
            : null,
        id: 1,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.ground !== undefined
            ? this.state.schoolNewDetails.ground
            : null,
        id: 2,
      },

      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.classrooms !== undefined
            ? this.state.schoolNewDetails.classrooms
            : null,
        id: 3,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.compound !== undefined &&
          this.state.schoolNewDetails.compound !== ""
            ? this.state.schoolNewDetails.compound
            : null,
        id: 0,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.games_sports_material !== undefined &&
          this.state.schoolNewDetails.games_sports_material !== ""
            ? this.state.schoolNewDetails.games_sports_material
            : null,
        id: 5,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.washrooms !== undefined &&
          this.state.schoolNewDetails.washrooms !== ""
            ? this.state.schoolNewDetails.washrooms
            : null,
        id: 6,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.drinking_water !== undefined &&
          this.state.schoolNewDetails.drinking_water !== ""
            ? this.state.schoolNewDetails.drinking_water
            : null,
        id: 7,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.ramp !== undefined &&
          this.state.schoolNewDetails.ramp !== ""
            ? this.state.schoolNewDetails.ramp
            : null,
        id: 8,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.kitchen_shed !== undefined &&
          this.state.schoolNewDetails.kitchen_shed !== ""
            ? this.state.schoolNewDetails.kitchen_shed
            : null,
        id: 9,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.study_teaching_materials !== undefined &&
          this.state.schoolNewDetails.study_teaching_materials !== ""
            ? this.state.schoolNewDetails.study_teaching_materials
            : null,
        id: 10,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.library_reading_materials !== undefined &&
          this.state.schoolNewDetails.library_reading_materials !== ""
            ? this.state.schoolNewDetails.library_reading_materials
            : null,
        id: 11,
      },
      {
        link:
          this.state.schoolNewDetails &&
          this.state.schoolNewDetails.other_facilities !== undefined &&
          this.state.schoolNewDetails.other_facilities !== ""
            ? this.state.schoolNewDetails.other_facilities
            : null,
        id: 12,
      },
    ];

    console.log(imageArr, "imageArr");
    return (
      <div className={classes.PhotoContainer}>
        <h1 className={classes.sliderText}>शाळेचे फोटो</h1>
        <div className={classes.PhotoSubContainer}>
          <button onClick={this.moveBackward} className={classes.sliderButton}>
            {"<"}
          </button>
          {imageArr[this.state.currentImage].link !== null ? (
            <div
              style={{
                width: 500,
                flexDirection: "row",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={imageArr[this.state.currentImage].link}
                className={classes.sliderImage}
              />
            </div>
          ) : (
            <p className={{ width: "85%", textAlign: "center" }}>
              Not Available
            </p>
          )}
          <button onClick={this.moveForward} className={classes.sliderButton}>
            {">"}
          </button>
        </div>
        <div className={classes.typeOfImage}>
          {console.log(
            this.state.currentImage,
            " this.state.currentImage",
            imageArr
          )}
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 0
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            मुख्य इमारत
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 1
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            कार्यालय
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 2
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            मैदान
          </p>
          {/* <p className={classes.imageTypeText}>|</p>
          <p
            className={
              this.state.currentImage == 3
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            वर्गखोली
          </p> */}
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 3
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            वर्गखोली
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 4
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            सुरक्षा भिंत
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 5
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            खेळ व क्रीडाविषयक सामग्री
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 6
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            स्वच्छतागृहे
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 7
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            पेयजल
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 8
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            रॅम्प
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 9
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            किचन शेड
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 10
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            अध्ययन – अध्यापन साहित्य
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 11
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            ग्रंथालय/वाचन साहित्य
          </p>
          <p className={classes.imageTypeText}>|</p>
          <p
            style={{ fontSize: "15px" }}
            className={
              this.state.currentImage == 12
                ? `${classes.imageTypeText} ${classes.imageTypeTextActive}`
                : `${classes.imageTypeText}`
            }
          >
            इतर सुविधा
          </p>
        </div>
      </div>
    );
  };

  headMasterData = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>मुख्याध्यापकांचे नाव</p>
          <div className={classes.headMasterData}>
            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.head_master
                  ? this.state.schoolNewDetails.head_master
                  : "NA"}
              </p>
            </div>
            <img src="https://res.cloudinary.com/darfyr0di/image/upload/v1659691113/new%20app/project/image_1call_paljga.png" />
            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.head_master_mobile
                  ? this.state.schoolNewDetails.head_master_mobile
                  : "NA"}
              </p>
            </div>

            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.head_master_caste
                  ? this.state.schoolNewDetails.head_master_caste
                  : "NA"}
              </p>
            </div>
          </div>
        </div>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>जेष्ठ पुरुष शिक्षकाचे नाव</p>
          <div className={classes.headMasterData}>
            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.senior_teacher_male
                  ? this.state.schoolNewDetails.senior_teacher_male
                  : "NA"}
              </p>
            </div>
            <img src="https://res.cloudinary.com/darfyr0di/image/upload/v1659691113/new%20app/project/image_1call_paljga.png" />
            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.senior_teacher_male_mobile
                  ? this.state.schoolNewDetails.senior_teacher_male_mobile
                  : "NA"}
              </p>
            </div>

            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.senior_teacher_male_caste
                  ? this.state.schoolNewDetails.senior_teacher_male_caste
                  : "NA"}
              </p>
            </div>
          </div>
        </div>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>जेष्ठ महिला शिक्षकाचे नाव</p>
          <div className={classes.headMasterData}>
            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {console.log(
                  this.state.schoolNewDetails.senior_teacher_female,
                  "female"
                )}
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.senior_teacher_female
                  ? this.state.schoolNewDetails.senior_teacher_female
                  : "NA"}
              </p>
            </div>
            <img src="https://res.cloudinary.com/darfyr0di/image/upload/v1659691113/new%20app/project/image_1call_paljga.png" />
            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.senior_teacher_female_mobile
                  ? this.state.schoolNewDetails.senior_teacher_female_mobile
                  : "NA"}
              </p>
            </div>

            <div className={classes.nameTextContainer}>
              <p className={classes.nameText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.senior_teacher_female_caste
                  ? this.state.schoolNewDetails.senior_teacher_female_caste
                  : "NA"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  schoolData = () => {
    return (
      <div>
        <div className={classes.PhotoContainer}>
          <div className={classes.headMasteContainer}>
            <p className={classes.headMasterText}>शाळेचा पत्ता</p>
            <div className={classes.headMasterData}>
              <div className={classes.nameTextContainer}>
                <p className={classes.nameText}>
                  {this.state.schoolNewDetails?.school_address}
                </p>
              </div>
              <p style={{ width: 39 }}> </p>
              <div className={classes.nameTextContainer}>
                <p className={classes.nameText}>
                  {this.state.schoolNewDetails?.school_address_gmaps}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.PhotoContainer}>
          <div className={classes.headMasteContainer}>
            <p className={classes.headMasterText}>शाळेचा प्रकार</p>
            <div className={classes.headMasterData}>
              <div className={classes.nameTextContainer}>
                <p className={classes.nameText}>
                  {this.state.schoolNewDetails?.school_type}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  schoolStudents = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>विद्यार्थी संख्या</p>
          <div className={classes.headMasterData}>
            <div className={classes.schoolStudentsTotal}>
              <p className={classes.schoolStudentsTotalText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.total_student
                  ? this.state.schoolNewDetails.total_student
                  : "N/A"}
              </p>
            </div>
            <p style={{ width: 39 }}> </p>
            <div>
              <div className={classes.schoolStudentsTotaBoy}>
                <p className={classes.nameText}>
                  {this.state.schoolNewDetails &&
                  this.state.schoolNewDetails.boys
                    ? this.state.schoolNewDetails.boys
                    : "N/A"}
                </p>
                <img
                  width={50}
                  src="https://cdn-icons-png.flaticon.com/512/145/145867.png"
                />
              </div>
              <div className={classes.schoolStudentsTotaBoy}>
                <p className={classes.nameText}>
                  {this.state.schoolNewDetails &&
                  this.state.schoolNewDetails.girls
                    ? this.state.schoolNewDetails.girls
                    : "N/A"}
                </p>
                <img
                  src="https://cdn.iconscout.com/icon/premium/png-256-thumb/schoolgirl-26-1128990.png"
                  width={50}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  vidaythiSankhaya = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>वर्गनिहाय विद्यार्थी संख्या</p>
          <div style={{ padding: "10px" }}>
            {this.state.schoolNewDetails?.gender_wise
              ? this.state.schoolNewDetails.gender_wise.map((res) => {
                  return (
                    <div
                      className={classes.headMasterData}
                      style={{ padding: "10px" }}
                    >
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>
                          {res.class_name} class
                        </p>
                      </div>
                      <p style={{ width: 39 }}> </p>
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>{res.total_students}</p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  };

  castwiseCount = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>
            जात प्रवर्गनिहाय विद्यार्थी संख्या
          </p>
          <div style={{ padding: "10px" }}>
            <div className={classes.headMasterData} style={{ padding: "10px" }}>
              <div className={classes.nameTextContainer2}>
                <p className={classes.nameText}>जात प्रवर्ग</p>
              </div>
              <p style={{ width: 39 }}> </p>
              <div className={classes.nameTextContainer2}>
                <p className={classes.nameText}>मुले</p>
              </div>
              <div className={classes.nameTextContainer2}>
                <p style={{ marginLeft: "-90px" }} className={classes.nameText}>
                  मुली
                </p>
              </div>
            </div>
            {this.state.schoolNewDetails?.caste_wise?.map((res) => {
              return (
                <div
                  className={classes.headMasterData}
                  style={{ padding: "10px" }}
                >
                  <div className={classes.nameTextContainer}>
                    <p className={classes.nameText}>{res.caste_name}</p>
                  </div>
                  <p style={{ width: 39 }}> </p>
                  <div className={classes.nameTextContainer1}>
                    <p className={classes.nameText}>{res.boys}</p>
                  </div>
                  <div className={classes.nameTextContainer1}>
                    <p className={classes.nameText}>{res.girls}</p>
                  </div>
                </div>
              );
            })}
            {/* <div
                      className={classes.headMasterData}
                      style={{ padding: "10px" }}
                    >
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>
                          SC
                        </p>
                      </div>
                      <p style={{ width: 39 }}> </p>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                    </div>
                    <div
                      className={classes.headMasterData}
                      style={{ padding: "10px" }}
                    >
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>
                           ST
                        </p>
                      </div>
                      <p style={{ width: 39 }}> </p>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                    </div>
                    <div
                      className={classes.headMasterData}
                      style={{ padding: "10px" }}
                    >
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>
                          OBC
                        </p>
                      </div>
                      <p style={{ width: 39 }}> </p>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                    </div>
                    <div
                      className={classes.headMasterData}
                      style={{ padding: "10px" }}
                    >
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>
                           NT
                        </p>
                      </div>
                      <p style={{ width: 39 }}> </p>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                    </div>
                    <div
                      className={classes.headMasterData}
                      style={{ padding: "10px" }}
                    >
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>
                           VJNT
                        </p>
                      </div>
                      <p style={{ width: 39 }}> </p>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                    </div>
                    <div
                      className={classes.headMasterData}
                      style={{ padding: "10px" }}
                    >
                      <div className={classes.nameTextContainer}>
                        <p className={classes.nameText}>
                           MINORITY
                        </p>
                      </div>
                      <p style={{ width: 39 }}> </p>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                      <div className={classes.nameTextContainer1}>
                        <p className={classes.nameText}>5</p>
                      </div>
                    </div> */}
          </div>
        </div>
      </div>
    );
  };

  teacherCount = () => {
    return (
      <div className={classes.PhotoContainer}>
        <div className={classes.headMasteContainer}>
          <p className={classes.headMasterText}>शिक्षक संख्या</p>
          <div className={classes.headMasterData}>
            <div className={classes.schoolStudentsTotal}>
              <p className={classes.schoolStudentsTotalText}>
                {this.state.schoolNewDetails &&
                this.state.schoolNewDetails.total_teacher
                  ? this.state.schoolNewDetails.total_teacher
                  : "N/A"}
              </p>
            </div>
            <p style={{ width: 39 }}> </p>
            <div>
              <div className={classes.schoolStudentsTotaBoy}>
                <p className={classes.nameText}>
                  {this.state.schoolNewDetails &&
                  this.state.schoolNewDetails.male_teacher
                    ? this.state.schoolNewDetails.male_teacher
                    : "N/A"}
                </p>
                <p className={classes.nameText}>
                  <img
                    width={50}
                    src="https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/3_avatar-128.png"
                  />
                </p>
              </div>
              <div className={classes.schoolStudentsTotaBoy}>
                <p className={classes.nameText}>
                  {this.state.schoolNewDetails &&
                  this.state.schoolNewDetails.female_teacher
                    ? this.state.schoolNewDetails.female_teacher
                    : "N/A"}
                </p>
                <p className={classes.nameText}>
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/11_avatar-512.png"
                    width={50}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  moveBackward = () => {
    if (this.state.currentImage === 0) {
      this.setState({
        currentImage: 12,
      });
    } else {
      this.setState((i) => ({
        currentImage: i.currentImage - 1,
      }));
    }
  };

  moveForward = () => {
    if (this.state.currentImage === 12) {
      this.setState({
        currentImage: 0,
      });
    } else {
      this.setState((i) => ({
        currentImage: i.currentImage + 1,
      }));
    }
  };

  getSMCformDetails = () => {
    console.log("version", this.state.selectedVersion);
    getHMService()
      .getVersionWiseDetails(
        this.state.HMSchool.school_id,
        this.state.selectedVersion
      )
      .then((Res) => {
        if (Res) {
          this.setState({
            schoolNewDetails: Res.data.response,
            futureMeet: Res.data.response.smc_future_meetings,
            meetArray: Res.data.response.smc_meetings,
            memberArray: Res.data.response.members,
            pdfURL: Res.data.response.pdf_url,
          });
        }
        console.log(
          Res,
          "res",
          this.state.managementDetails,
          this.state.meetArray
        );
      });
  };

  downloadPDF = () => {
    console.log(this.state.selectedVersion, "HM");
    getHMService()
      .getPDFUrl(this.state.selectedVersion)
      .then((res) => {
        console.log(res, "res");
        if (res.data.status == 200) {
          window.open(res.data?.response?.pdf_url);
        }
      });
  };

  render() {
    let {
      error,
      message,
      isHMDetailsPresent,
      isSMCDetailsPresent,
    } = this.state;
    let { school_detail, smc } = this.state.HMSchool;
    return (
      <GridItem xs={12} sm={12} md={12} style={{ backgroundColor: "#fff" }}>
        <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() => {
            this.setState({
              message: null,
            });
          }}
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        {this.renderAddMemberDialog()}
        {this.renderAddDetailDialog()}
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <FormControl>
              {console.log(this.state.yearList, "yearList")}
              <Select
                style={{ width: "120px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.YearValue}
                onChange={(e) => {
                  console.log(e, "values");
                  this.setState(
                    {
                      YearValue: e.target.value,
                      quaterValue: null,
                    },
                    () => {
                      this.getHalfarray();
                    }
                  );
                }}
                label="Select Year"
              >
                {this.state.yearList?.map((res, id) => {
                  return (
                    <MenuItem key={id} value={res}>
                      {res}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                style={{ width: "120px", marginLeft: "10px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.quaterValue}
                onChange={(e) => {
                  console.log(e, "values");
                  this.setState(
                    {
                      quaterValue: e.target.value,
                    },
                    () => {
                      this.getSchoolDetailsForHm();
                    }
                  );
                }}
                label="Select Quater"
              >
                {this.state.quaterList?.map((res, id) => {
                  return (
                    <MenuItem key={id} value={res}>
                      {res}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* 
         <Button style={{ backgroundColor:'#4B68BA',padding:'5px',color:'#fff',borderRadius:'10px'}} onClick={()=>
          
        {this.getSchoolDetailsForHm()}
          
          }> Show form</Button> */}
          </div>
        </div>
        {console.log(this.state.schoolNewDetails, "schoolNewDetails")}
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",

              // justifyContent:'space-around'
            }}
          >
            <Card
              style={{
                backgroundColor: this.state.primaryInfo ? "#0DB41E" : "#EEF7FE",
                border: "1px solid #588FB9",
                textAlign: "center",
                width: "15%",
                fontSize: "15px",
                padding: "20px",
                marginLeft: "10px",
                color: this.state.primaryInfo ? "#fff" : "#4B68BA",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({
                  primaryInfo: true,
                  schoolInfo: false,
                  specialInfo: false,
                  extraInfo: false,
                });
              }}
            >
              प्राथमिक माहिती
            </Card>
            <Card
              style={{
                backgroundColor: this.state.schoolInfo ? "#0DB41E" : "#EEF7FE",
                border: "1px solid #588FB9",
                textAlign: "center",
                width: "15%",
                fontSize: "15px",
                padding: "20px",
                marginLeft: "10px",
                color: this.state.schoolInfo ? "#fff" : "#4B68BA",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  primaryInfo: false,
                  schoolInfo: true,
                  specialInfo: false,
                  extraInfo: false,
                })
              }
            >
              शाळा व्यवस्थापन समिती
            </Card>
            <Card
              style={{
                backgroundColor: this.state.specialInfo ? "#0DB41E" : "#EEF7FE",
                border: "1px solid #588FB9",
                textAlign: "center",
                width: "15%",
                fontSize: "15px",
                padding: "20px",
                marginLeft: "10px",
                color: this.state.specialInfo ? "#fff" : "#4B68BA",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  primaryInfo: false,
                  schoolInfo: false,
                  specialInfo: true,
                  extraInfo: false,
                })
              }
            >
              विशेष माहिती
            </Card>
            <Card
              style={{
                backgroundColor: this.state.extraInfo ? "#0DB41E" : "#EEF7FE",
                border: "1px solid #588FB9",
                textAlign: "center",
                width: "15%",
                fontSize: "15px",
                padding: "20px",
                marginLeft: "10px",
                color: this.state.extraInfo ? "#fff" : "#4B68BA",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  primaryInfo: false,
                  schoolInfo: false,
                  specialInfo: false,
                  extraInfo: true,
                })
              }
            >
              इतर माहिती
            </Card>
            {this.state.selectedVersion ? (
              <Card
                style={{
                  backgroundColor: "#EEF7FE",
                  border: "1px solid #588FB9",
                  textAlign: "center",
                  width: "15%",
                  fontSize: "15px",
                  padding: "20px",
                  marginLeft: "10px",
                  color: "#4B68BA",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.downloadPDF();
                }}
              >
                Download
              </Card>
            ) : null}
          </div>
          <div style={{ marginBottom: "50px" }}>
            {this.state.primaryInfo ? (
              <div>
                {this.sliderContainer()}
                {this.schoolData()}
                {this.schoolStudents()}
                {this.vidaythiSankhaya()}
                {this.castwiseCount()}
                {this.headMasterData()}

                {this.teacherCount()}
              </div>
            ) : null}
          </div>
          {this.state.schoolInfo ? (
            <div>
              {/* <Card>
            <CardHeader color="primary">
               
                <div className={classes.headSection}>
                <h4 className={''}>SMC Details</h4>
                <div>
                  {isSMCDetailsPresent ? (
                    <button
                    style={{ backgroundColor:'#8827AA',borderColor:'#8827AA',elevation:'10'}}
                      className="btn btn-primary"
                      title="Edit SMC details"
                      onClick={() => this.onEditSMCDetails(true)}
                    >
                      <EditIcon />
                    </button>
                  ) : (
                    <button
                    style={{ backgroundColor:'#8827AA',borderColor:'#8827AA',elevation:'10'}}
                      className="btn btn-primary"
                      onClick={() => this.onEditSMCDetails(false)}
                    >
                      <AddIcon />
                    </button>
                  )}
                </div>
              </div>
              
            </CardHeader>
            <CardBody>
          
            <CardHeader>
             
            </CardHeader>
            <CardBody>
              {isSMCDetailsPresent ? (
                <>
                  <div>{school_detail.school_name}</div>
                  <div className="row">
                    <Card>
                      <div  className="row" style={{ padding:'10px',fontSize:'16px'}}>
                    <div className="col-6" >Budget : {smc.budget}</div>
                    <div className="col-6">
                      Achievements : {smc.achievement}
                    </div>
                    <div className="col-6">Challenges : {smc.challenges}</div>
                    <div className="col-6">Meetings : {smc.meetings}</div>
                    <div className="col-6">
                      Roles and responsibility : {smc.roles_and_responsibility}
                    </div>
                    <div className="col-6">
                      School grant received : {smc.school_grant_received}
                    </div>
                    </div>
                    </Card>
                    <Card>
                      <CardHeader>
                        <div className={classes.formHead}>Members</div>
                      </CardHeader>
                      <CardBody>
                        {smc.members && smc.members.length ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Phone</th>
                              </tr>
                            </thead>
                            <tbody>{this.renderMembers(smc.members)}</tbody>
                          </table>
                        ) : (
                          <div>No Members Yet</div>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </>
              ) : (
                <div>No SMC Details Found. Please Add Details</div>
              )}
            </CardBody>
          
          </CardBody>
          </Card> */}

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "10px",
                }}
              >
                शाळा व्यवस्थापन समितीचे सदस्य- -
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: "-20px",
                    padding: "10px",
                  }}
                >
                  {/* <div
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    this.addNewMember();
                  }}
                >
                  <Add />
                </div>
                <div onClick={() => this.saveMember()}>
                  <Save />
                </div> */}
                </div>
                {console.log(this.state.memberArray, "memb")}
                {this.state.memberArray
                  ? this.state.memberArray?.map((res, id) => {
                      return (
                        <div
                          style={{
                            marginTop: "15px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div style={{ padding: "10px" }}>
                            सदस्य क्र {id + 1}
                          </div>
                          {res.name ? (
                            <div
                              style={{
                                backgroundColor: "#DFE4E8",
                                width: "15%",
                                padding: "10px",
                              }}
                            >
                              {res.name}
                            </div>
                          ) : (
                            <input
                              style={{ width: "15%", padding: "10px" }}
                              type="text"
                              className="form-control"
                              // value={data.name}
                              onChange={(e) =>
                                this.MemberAdd(e.target.value, id, "1")
                              }
                            />
                          )}

                          {res.gender ? (
                            <div
                              style={{
                                backgroundColor: "#DFE4E8",
                                width: "15%",
                                padding: "10px",
                              }}
                            >
                              {res.gender}
                            </div>
                          ) : (
                            <input
                              style={{ width: "15%", padding: "10px" }}
                              type="text"
                              className="form-control"
                              // value={data.name}
                              onChange={(e) =>
                                this.MemberAdd(e.target.value, id, "2")
                              }
                            />
                          )}
                          {res.caste ? (
                            <div
                              style={{
                                backgroundColor: "#DFE4E8",
                                width: "15%",
                                padding: "10px",
                              }}
                            >
                              {res.caste}
                            </div>
                          ) : (
                            <input
                              style={{ width: "15%", padding: "10px" }}
                              type="text"
                              className="form-control"
                              // value={data.name}
                              onChange={(e) =>
                                this.MemberAdd(e.target.value, id, "3")
                              }
                            />
                          )}

                          <div
                            style={{
                              backgroundColor: "#DFE4E8",
                              width: "15%",
                              padding: "10px",
                            }}
                          >
                            {res?.patent}
                          </div>
                          {res.phone ? (
                            <div
                              style={{
                                backgroundColor: "#DFE4E8",
                                width: "15%",
                                padding: "10px",
                              }}
                            >
                              <Call /> {res.phone}
                            </div>
                          ) : (
                            <input
                              style={{ width: "15%", padding: "10px" }}
                              type="text"
                              className="form-control"
                              // value={data.name}
                              onChange={(e) =>
                                this.MemberAdd(e.target.value, id, "4")
                              }
                            />
                          )}

                          {/* <div
                          // className="btn btn-primary"
                          onClick={() => {
                            this.deleteMember(res.id);
                            console.log(res.id, "id");
                          }}
                          style={{}}
                        >
                          <Delete />
                        </div> */}
                        </div>
                      );
                    })
                  : null}
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "10px",
                  flexDirection: "row",
                }}
              >
                <div style={{ flexDirection: "row" }}>
                  शाळा व्यवस्थापन समिती बैठक वृतांत
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: "-20px",
                      padding: "10px",
                    }}
                  >
                    {/* <div
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      this.addNewMeet();
                    }}
                  >
                    <Add />
                  </div>
                  <div onClick={() => this.saveMeet()}>
                    <Save />
                  </div> */}
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: "20px",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      color: "#958989",
                      marginRight: "10px",
                      marginTop: "20px",
                    }}
                  >
                    या वर्षात झालेल्या सभांची संख्या
                  </p>

                  <div
                    style={{
                      backgroundColor: "#DFE4E8",
                      width: "15%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    {
                      this.state.schoolNewDetails?.special_information
                        ?.number_of_meetings
                    }
                  </div>
                </div>
                {console.log(
                  this.state.emptyForm,
                  this.state.memberForm,
                  "forms",
                  this.state.meetArray
                )}
                {this.state.meetArray?.map((res, id) => {
                  console.log(res, "res");
                  return (
                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ padding: "10px" }}>बैठक क्र {id + 1}</div>

                      {res.meetings_date ? (
                        <div
                          style={{
                            width: "15%",
                            padding: "10px",
                            backgroundColor: "#DFE4E8",
                          }}
                        >
                          {res.meetings_date}
                        </div>
                      ) : (
                        <input
                          style={{ width: "15%", padding: "10px" }}
                          type="text"
                          className="form-control"
                          // value={data.name}
                          onChange={(e) =>
                            this.formsVal(e.target.value, id, "1")
                          }
                        />
                      )}
                      {
                        res.meetings_attendance ? (
                          <div
                            style={{
                              width: "15%",
                              padding: "10px",
                              backgroundColor: "#DFE4E8",
                            }}
                          >
                            {res.meetings_attendance}
                          </div>
                        ) : null
                        // (
                        //   <input
                        //     style={{ width: "15%", padding: "10px" }}
                        //     type="text"
                        //     className="form-control"
                        //     // value={data.name}
                        //     onChange={(e) => this.formsVal(e.target.value, id, "2")}
                        //   />
                        // )
                      }
                      {res.reports ? (
                        <div
                          onClick={() => window.open(res.reports)}
                          style={{
                            width: "15%",
                            padding: "10px",
                            backgroundColor: "#DFE4E8",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          वृतांत
                          <CloudDownloadOutlined />
                        </div>
                      ) : (
                        <input
                          style={{ width: "15%", padding: "10px" }}
                          type="text"
                          className="form-control"
                          // value={data.name}
                          onChange={(e) =>
                            this.formsVal(e.target.value, id, "3")
                          }
                        />
                      )}
                      {/* <div
                      // className="btn btn-primary"
                      onClick={() => {
                        this.deleteMeet(res.id);
                        console.log(res.id, "id");
                      }}
                      style={{}}
                    >
                      <Delete />
                    </div> */}
                      <div
                        onClick={() => window.open(res.photo)}
                        style={{
                          width: "15%",
                          padding: "10px",
                          backgroundColor: "#DFE4E8",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        वृतांत
                        <CloudDownloadOutlined />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "10px",
                  flexDirection: "row",
                }}
              >
                <div style={{ marginTop: "25px" }}>
                  पुढील नियोजित बैठका
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: "-20px",
                      padding: "10px",
                    }}
                  >
                    {/* <div
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      this.addFutureMeet();
                    }}
                  >
                    <Add />
                  </div>
                  <div onClick={() => this.saveFutureMeet()}>
                    <Save />
                  </div> */}
                  </div>
                  {console.log(this.state.managementDetails, "rest map")}
                  {this.state.futureMeet?.map((res, id) => {
                    console.log(res, "res map");
                    return (
                      <div
                        style={{
                          marginTop: "15px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ padding: "10px" }}>बैठक क्र {id + 1}</div>

                        {res.meetings_date ? (
                          <div
                            style={{
                              backgroundColor: "#DFE4E8",
                              width: "15%",
                              padding: "10px",
                              marginLeft: "20px",
                            }}
                          >
                            {res.meetings_date}
                          </div>
                        ) : (
                          <input
                            style={{ width: "15%", padding: "10px" }}
                            type="text"
                            className="form-control"
                            // value={data.name}
                            onChange={(e) =>
                              this.addFutureMeetDetails(e.target.value, id, "1")
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                शाळा व्यवस्थापन समितीच्या माध्यमातून झालेला शाळेचा विकास
                {/* {this.state.schoolNewDetails?.other_school_details
                ?.head_master_photo ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={
                      this.state.schoolNewDetails?.other_school_details
                        ?.school_strengths_photo
                    }
                    style={{ width: "10%" }}
                  />
                </div>
              ) : null} */}
                {this.state.schoolNewDetails?.other_school_details
                  ?.school_development_by_smc_photo ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={
                        this.state.schoolNewDetails?.other_school_details
                          ?.school_development_by_smc_photo
                      }
                      style={{ width: "10%" }}
                    />
                  </div>
                ) : null}
                <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
                  {this.state.schoolNewDetails
                    ? this.state.schoolNewDetails?.other_school_details
                        ?.school_development_by_smc
                    : "NA"}
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "58%",
                    borderWidth: "1px",
                    borderColor: "black",
                    borderRightStyle: "solid",
                    paddingRight: "60px",
                  }}
                >
                  <p style={{ fontSize: "26px" }}>
                    शाळेस या शैक्षणिक वर्षात प्राप्त लोकसहभाग (२०२२-२३)
                  </p>

                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#958989",
                        marginTop: "20px",
                        marginRight: "10px",
                      }}
                    >
                      एकूण प्राप्त लोकसहभाग अंदाजे किंमत (रु.)
                    </p>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.public_donation
                          ?.total_donation
                      }
                    </div>
                  </div>

                  <div>
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#958989",
                        marginTop: "20px",
                        marginRight: "10px",
                      }}
                    >
                      लोकसहभागातून मिळालेली सुविधा -
                    </p>
                    <div
                      style={{
                        marginTop: "15px",
                        backgroundColor: "#DFE4E8",
                        padding: "20px",
                        border: 0,
                        height: "253px",
                        width: "100%",
                        resize: "none",
                        color: "#000",
                        fontSize: "22px",
                        fontWeight: "800",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.public_donation
                          ?.facilities_obtained
                      }
                    </div>
                  </div>
                </div>
                <div style={{ width: "38%" }}>
                  <p
                    style={{
                      fontSize: "20px",
                      color: "#958989",
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                  >
                    देणगीदारांची नावे -
                  </p>

                  {/* {this.state.donorsForm.map((data, index) => {
            return (
              <input
                type="text"
                value={data.donorsName}
                style={{
                  marginTop: "20px",
                  paddingTop: "7px",
                  width: "100%",
                  height: "46px",
                  backgroundColor: "#DFE4E8",
                  border: 0,
                  paddingLeft: "10px",
                }}
                onChange={(e) => this.donorValChange(e, index)}
              />
            );
          })} */}

                  {this.state.schoolNewDetails?.donors?.map((res) => {
                    if (res.donor_name) {
                      return (
                        <div
                          style={{
                            padding: "10px",
                            backgroundColor: "#DFE4E8",
                            marginTop: "15px",
                          }}
                        >
                          {res.donor_name}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>

              <div
                style={{
                  marginTop: "25px",
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginBottom: "50px",
                }}
              >
                आपल्या शाळेत काय उपलब्ध आहे त्यानुसार योग्य ते सर्व चेकबॉक्स
                निवडा.
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>इंटरनेट</div>

                    {console.log(
                      this.state.schoolNewDetails?.school_facility?.internet,
                      "interant"
                    )}
                    <div style={{ marginRight: "180px" }}>
                      {this.state.schoolNewDetails?.school_facility?.internet
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>गणित पेटी</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.maths_box
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>मैदान</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.ground
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>पक्क्या वर्ग खोल्या</div>

                    <div style={{ marginRight: "180px" }}>
                      {this.state.schoolNewDetails?.school_facility
                        ?.solid_class_rooms
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>स्मार्ट टीव्ही</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.smart_tv
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>मुलींचे स्वच्छतागृह</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility
                        ?.girls_washroom
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>ग्रंथालय कक्ष</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.library
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>कच्च्या वर्गखोल्या</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility
                        ?.weak_class_rooms
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>वीज जोडणी</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility
                        ?.power_connection
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>सफाई कर्मचारी</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.sweeper
                        ? "🟩"
                        : "🟥"}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>प्रयोगशाळा</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.lab
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>प्रोजेक्टर</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.projector
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "40%" }}>मुलांचे स्वच्छतागृह</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility
                        ?.boys_washroom
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>सुरक्षा भिंत</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility
                        ?.boundary_wall
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>BALA रचना</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility?.bala
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>खेळाचे साहित्य</div>

                    <div style={{ marginRight: "180px" }}>
                      {" "}
                      {this.state.schoolNewDetails?.school_facility
                        ?.play_material
                        ? "🟩"
                        : "🟥"}{" "}
                    </div>
                  </div>
                </div>
                {/* <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>संगणक कक्ष</div>

                  <div style={{ marginRight: "180px" }}>
                    {" "}
                    {this.state.schoolNewDetails?.school_facility?.computer_room
                      ? "🟩"
                      : "🟥"}{" "}
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>परसबाग</div>

                  <div style={{ marginRight: "180px" }}>
                    {" "}
                    {this.state.schoolNewDetails?.school_facility?.backyard
                      ? "🟩"
                      : "🟥"}{" "}
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>इन्व्हर्टर</div>

                  <div style={{ marginRight: "180px" }}>
                    {" "}
                    {this.state.schoolNewDetails?.school_facility?.inverter
                      ? "🟩"
                      : "🟥"}{" "}
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    width: "25%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>ABL</div>

                  <div style={{ marginRight: "180px" }}>
                    {" "}
                    {this.state.schoolNewDetails?.school_facility?.abl
                      ? "🟩"
                      : "🟥"}{" "}
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          ) : null}
          {this.state.specialInfo ? (
            <div>
              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "20px",
                }}
              >
                आपल्या शाळेतील विद्यार्थ्यांनी मागील वर्षी (२०२१-२२) कोणत्या
                स्पर्धा परीक्षा दिल्या त्यानुसार खालील बाबी लिहा
                <div style={{ marginTop: "5em" }}>
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "195px" }}>
                      इ ५ वी शिष्यवृत्ती
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship
                        ?.scholarship_5th == 0
                        ? "नाही"
                        : "होय"}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.scholarship
                          ?.scholarship_5th
                      }
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "180px" }}>
                      इ ५ वी नवोदय परीक्षा
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "35px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship
                        ?.scholarship_navodaya == 0
                        ? "नाही"
                        : "होय"}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.scholarship
                          ?.scholarship_navodaya
                      }
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "195px" }}>
                      इ ८ वी शिष्यवृत्ती
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship
                        ?.scholarship_8th == 0
                        ? "नाही"
                        : "होय"}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.scholarship
                          ?.scholarship_8th
                      }
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "180px" }}>
                      इ. ८ वी एन. एम. एम. एस.
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "35px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship
                        ?.scholarship_8th_nmms == 0
                        ? "नाही"
                        : "होय"}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.scholarship
                          ?.scholarship_8th_nmms
                      }
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "180px" }}>
                      इ. १० वी शिष्यवृत्ती
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "35px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship
                        ?.scholarship_10th == 0
                        ? "नाही"
                        : "होय"}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.scholarship
                          ?.scholarship_10th
                      }
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "180px" }}>
                      एलिमेंटरी परीक्षा
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "35px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship?.elementary == 0
                        ? "नाही"
                        : "होय"}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship?.elementary}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "8%" }}>
                      इंटरमिजीएट परीक्षा
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "15px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship?.intermediate ==
                      0
                        ? "नाही"
                        : "होय"}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.scholarship?.intermediate}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2em",
                  }}
                >
                  <div
                    style={{
                      width: "60%",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderRightStyle: "solid",
                    }}
                  >
                    <p style={{ fontSize: "26px" }}>
                      प्रधानमंत्री पोषण शक्ती निर्माण (PM Poshan) योजना (२०२२
                      -२३ )<span className={classes.redStar}>*</span>
                    </p>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          width: "40%",
                          color: "#958989",
                        }}
                      >
                        पोषण आहार घेणाऱ्या विद्यार्थ्यांची संख्या
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "25%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.mid_day_meal
                            ?.total_students
                        }
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          width: "40%",
                          color: "#958989",
                        }}
                      >
                        स्वयंपाकी/मदतनीस संख्या
                      </div>
                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "25%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.mid_day_meal
                            ?.number_of_cook
                        }
                      </div>
                    </div>
                    <hr style={{ width: "99%" }} color="black" size="4" />
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        color: "#958989",
                      }}
                    >
                      पोषण आहार तयार करणाऱ्या स्वयंपाकी / मदतनीस यांची माहिती
                    </p>
                    <div style={{ marginLeft: "15%" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <span
                          style={{
                            width: "40%",
                            textAlign: "center",
                            color: "#958989",
                          }}
                        >
                          नाव
                        </span>
                        <span
                          style={{
                            marginLeft: "40px",
                            width: "40%",
                            textAlign: "center",
                            color: "#958989",
                          }}
                        >
                          मासिक भत्ता
                        </span>
                      </div>
                      {this.state.schoolNewDetails?.cook_details?.map(
                        (data, index) => {
                          console.log(data, "data");
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                margin: "10px",
                              }}
                            >
                              <div
                                // type="text"
                                // value={data.cookName}
                                style={{
                                  // marginTop: "5px",
                                  // paddingTop: "7px",
                                  width: "40%",
                                  // height: "46px",
                                  backgroundColor: "#DFE4E8",
                                  // border: 0,
                                  // paddingLeft: "10px",
                                  padding: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {data.cook_name}
                              </div>
                              <div
                                // type="text"
                                // value={data.cookName}
                                style={{
                                  // marginTop: "5px",
                                  // paddingTop: "7px",
                                  width: "40%",
                                  // height: "46px",
                                  backgroundColor: "#DFE4E8",
                                  padding: "10px",
                                  textAlign: "center",
                                  // border: 0,
                                  // paddingLeft: "10px",
                                  // marginLeft: "40px",
                                }}
                              >
                                {data.cook_salary}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div
                      // className={["m-2", classes.headSection]
                      //   .toString()
                      //   .split(",")
                      //   .join(" ")}
                      className="d-flex flex-row align-items-center justify-content-center"
                    ></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginRight: "120px",
                      marginLeft: "30px",
                      width: "38%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "-40px",
                        padding: "40px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ color: "#958989" }}>इतर माहिती</p>
                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          // width: "15%",
                          padding: "10px",
                          // marginLeft: "20px",
                          color: "grey",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.mid_day_meal
                            ?.other_details
                        }
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{
                          padding: "10px",
                          minWidth: "150px",
                          color: "#958989",
                        }}
                      >
                        पोषण आहार घेताना विद्यार्थ्यांच्या रचनेचा फोटो
                      </p>

                      <div
                        style={{
                          // backgroundColor: "#DFE4E8",
                          width: "25%",
                          // padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {this.state.schoolNewDetails?.mid_day_meal
                          ?.student_photo ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                this.state.schoolNewDetails?.mid_day_meal
                                  ?.student_photo
                              }
                              style={{ width: "90%" }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                <p style={{ fontSize: "26px" }}>
                  शाळेत दिल्या जाणाऱ्या विविध शासकीय योजनांची नावे : (FY २०२१
                  -२२ )
                </p>
                {/* <div style={{display:"flex",flexDirection:"row"}} >
<div style={{width:"50%",display:"flex",flexDirection:"column",alignItems:"center"}} >
 <p style={{textAlign:"center",color:"#958989"}} >योजनेचे नाव </p>
   </div>
   <div style={{width:"50%",display:"flex",flexDirection:"column",alignItems:"center"}} >
 <p style={{textAlign:"center",color:"#958989"}} >लाभार्थ्यांची संख्या  </p>
   </div>
   <div style={{ display:'flex' ,justifyContent:'space-around',margin:'10px'}}>
                      <div
                        style={{
                          width: "40%",
                          backgroundColor: "#DFE4E8",
                          padding:'10px',
                          textAlign:'center'
                        }}
                       
                      >szcz</div>
                       <div
                        style={{
                          width: "40%",
                          backgroundColor: "#DFE4E8",
                          padding:'10px',
                          textAlign:'center'
                          // border: 0,
                          // paddingLeft: "10px",
                          // marginLeft: "40px",
                        }}
                       
                      >scs</div>
                    </div>
</div> */}

                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span
                      style={{
                        width: "40%",
                        textAlign: "center",
                        color: "#958989",
                      }}
                    >
                      योजनेचे नाव{" "}
                    </span>
                    <span
                      style={{
                        marginLeft: "40px",
                        width: "40%",
                        textAlign: "center",
                        color: "#958989",
                      }}
                    >
                      लाभार्थ्यांची संख्या
                    </span>
                  </div>
                  {this.state.schoolNewDetails?.government_schemes?.map(
                    (data, index) => {
                      console.log(data, "data");
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            margin: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              backgroundColor: "#DFE4E8",
                              padding: "10px",
                              textAlign: "center",
                            }}
                          >
                            {data.name_of_scheme}
                          </div>
                          <div
                            style={{
                              width: "40%",
                              backgroundColor: "#DFE4E8",
                              padding: "10px",
                              textAlign: "center",
                            }}
                          >
                            {data.number_of_beneficiaries}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              {/* <div
              style={{
                backgroundColor: "#ECFBFF",
                fontWeight: "bold",
                marginTop: "15px",
                justifyContent: "center",
                padding: "20px",
                marginBottom: "20px",
              }}
            >
              मध्यान्न पोषण आहार (चालू वर्ष २०२२-२३)
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2em",
                }}
              >
                <div style={{ width: "50%" }}>
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "40%" }}>
                      पोषण आहार घेणाऱ्या विद्यार्थ्यांची संख्या
                    </div>

                    {console.log(
                      this.state.schoolNewDetails?.mid_day_meal,
                      "meal"
                    )}
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "25%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.mid_day_meal
                        ?.total_students !== ""
                        ? this.state.schoolNewDetails?.mid_day_meal
                            ?.total_students
                        : "NA"}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "40%" }}>
                      पोषण आहार तयार करणाऱ्या स्वयंपाक्याचेे पूर्ण नाव
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "25%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.mid_day_meal?.cook_name}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "40%" }}>
                      पोषण आहार तयार करणाऱ्या स्वयंपाक्याचा मासिक भत्ता
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "25%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.mid_day_meal?.cook_salary}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "10px", width: "40%" }}>
                      पोषण आहार घेताना विद्यार्थ्यांच्या रचनेचा फोटो
                    </div>

                    <div
                      style={{
                        // backgroundColor: "#DFE4E8",
                        width: "25%",
                        // padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {this.state.schoolNewDetails?.mid_day_meal
                        ?.student_photo ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={
                              this.state.schoolNewDetails?.mid_day_meal
                                ?.student_photo
                            }
                            style={{ width: "90%" }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      marginLeft: "20px",
                      marginTop: "-60px",
                      color: "grey",
                    }}
                  >
                    इतर माहिती
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        // width: "15%",
                        padding: "10px",
                        // marginLeft: "20px",
                        color: "grey",
                      }}
                    >
                      {this.state.schoolNewDetails?.mid_day_meal?.other_details}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                विद्यार्थ्यांचे गणवेश (चालू वर्ष २०२२-२३)
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2em",
                  }}
                >
                  <div style={{ width: "200%" }}>
                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        एकूण किती विद्यार्थ्यांना गणवेश वाटप केले
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {this.state.schoolNewDetails?.uniform?.total_students}
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        एकूण किती मुलींना गणवेश वाटप केले
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "45px",
                        }}
                      >
                        {this.state.schoolNewDetails?.uniform?.girls}
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        एकूण किती मुलांना गणवेश वाटप केले
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "45px",
                        }}
                      >
                        {this.state.schoolNewDetails?.uniform?.boys}
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        किती विद्यार्थ्यांना अजून गणवेशाची गरज आहे?
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          // marginLeft: "20px",
                        }}
                      >
                        {this.state.schoolNewDetails?.uniform?.remaining}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                विद्यार्थ्यांसाठी वर्गात बसण्याची सुविधा (चालू वर्ष २०२२-२३)
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2em",
                  }}
                >
                  <div style={{ width: "200%" }}>
                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        कच्चे बांधकाम असलेल्या वर्ग खोल्यांची संख्या :
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "95px",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.special_information
                            ?.crude_classrooms
                        }
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        पक्के बांधकाम असलेल्या वर्ग खोल्यांची संख्या :
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "90px",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.special_information
                            ?.concrete_classrooms
                        }
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        किती विद्यार्थ्यांना बसण्यासाठी अधिक बाकांची गरज आहे? :
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.special_information
                            ?.required_more_sits
                        }
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        शाळेतील एकूण वर्गखोल्यांची संख्या :
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "160px",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.special_information
                            ?.total_classrooms
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                विद्यार्थ्यांसाठी पिण्याचे पाणी खालील स्त्रोत मार्फत उपलब्ध आहे
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2em",
                  }}
                >
                  <div style={{ width: "200%" }}>
                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {this.state.schoolNewDetails?.special_information
                          ?.water_source_common
                          ? "🟩"
                          : "⬜"}{" "}
                        सामाइक नळाद्वारे
                      </div>

                      <div
                        style={{
                          // backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {this.state.schoolNewDetails?.special_information
                          ?.tube_hand_pump
                          ? "🟩"
                          : "⬜"}{" "}
                        कुपनलिका/हातपंप
                      </div>

                      <div
                        style={{
                          // backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        other :{" "}
                        {this.state.schoolNewDetails?.special_information
                          ?.water_other
                          ? this.state.schoolNewDetails?.special_information
                              ?.water_other
                          : "NA"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                {/* ग्रंथालयात असणाऱ्या एकूण पुस्तकांची संख्या लिहा  */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "2em",
                  }}
                >
                  <div style={{ width: "200%" }}>
                    <div
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        ग्रंथालयात असणाऱ्या एकूण पुस्तकांची संख्या लिहा{" "}
                      </div>

                      <div
                        style={{
                          backgroundColor: "#DFE4E8",
                          width: "15%",
                          padding: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        {
                          this.state.schoolNewDetails?.special_information
                            ?.total_books
                        }
                      </div>
                    </div>
                  </div>

                  {/* <div style={{ width: "200%" }}>
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ padding: "7px", width: "14%" }}>
                      मोफत पाठ्यपुस्तक योजने अंतर्गत किती विध्यार्थ्यांना
                      पाठ्यपुस्तक मिळाले{" "}
                    </div>

                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        width: "15%",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {
                        this.state.schoolNewDetails?.special_information
                          ?.free_text_books
                      }
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          ) : null}

          {this.state.extraInfo ? (
            <div>
              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                मुख्याध्यापकांचा आपल्या शाळेतील विद्यार्थ्यांना संदेश
                {this.state.schoolNewDetails?.other_school_details
                  ?.head_master_photo ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={
                        this.state.schoolNewDetails?.other_school_details
                          ?.head_master_photo
                      }
                      style={{ width: "10%" }}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#DFE4E8",
                    padding: "10px",
                  }}
                >
                  {console.log(
                    this.state.schoolNewDetails.other_school_details,
                    "schoolDetails"
                  )}
                  {this.state.schoolNewDetails
                    ? this.state.schoolNewDetails?.other_school_details
                        ?.head_master_msg
                    : "NA"}
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  marginTop: "15px",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                शाळेचा इतिहास
                {this.state.schoolNewDetails?.other_school_details
                  ?.school_history_photo ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={
                        this.state.schoolNewDetails?.other_school_details
                          ?.school_history_photo
                      }
                      style={{ width: "10%" }}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#DFE4E8",
                    padding: "10px",
                  }}
                >
                  {console.log(
                    this.state.schoolNewDetails.other_school_details,
                    "schoolDetails"
                  )}
                  {this.state.schoolNewDetails
                    ? this.state.schoolNewDetails?.other_school_details
                        ?.school_history
                    : "NA"}
                </div>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                शाळेत राबविल्या जाणाऱ्या वैशिष्ठ्यपूर्ण बाबी/ सकारात्मक उपक्रम
                {this.state.schoolNewDetails?.other_school_details
                  ?.head_master_photo ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={
                        this.state.schoolNewDetails?.other_school_details
                          ?.school_strengths_photo
                      }
                      style={{ width: "10%" }}
                    />
                  </div>
                ) : null}
                <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
                  {this.state.schoolNewDetails
                    ? this.state.schoolNewDetails?.other_school_details
                        ?.school_strengths
                    : "NA"}
                </div>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                शाळेसमोरील आव्हाने व समस्या / भौतिक सुविधांबाबत शाळेची गरज
                {this.state.schoolNewDetails?.other_school_details
                  ?.school_challenges_photo ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={
                        this.state.schoolNewDetails?.other_school_details
                          ?.school_challenges_photo
                      }
                      style={{ width: "10%" }}
                    />
                  </div>
                ) : null}
                <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
                  {this.state.schoolNewDetails
                    ? this.state.schoolNewDetails?.other_school_details
                        ?.school_challenges
                    : "NA"}
                </div>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                शाळेचे भविष्यकालीन नियोजन / आराखडा
                {this.state.schoolNewDetails?.other_school_details
                  ?.future_plan_of_school_photo ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={
                        this.state.schoolNewDetails?.other_school_details
                          ?.future_plan_of_school_photo
                      }
                      style={{ width: "10%" }}
                    />
                  </div>
                ) : null}
                <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
                  {this.state.schoolNewDetails
                    ? this.state.schoolNewDetails?.other_school_details
                        ?.future_plan_of_school
                    : "NA"}
                </div>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  backgroundColor: "#ECFBFF",
                  fontWeight: "bold",
                  justifyContent: "center",
                  padding: "20px",
                  marginBottom: "50px",
                }}
              >
                पालकांसाठी सूचना
                {/* {this.state.schoolNewDetails?.other_school_details
                ?.instructions_for_parents ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={
                      this.state.schoolNewDetails?.other_school_details
                        ?.instructions_for_parents
                    }
                    style={{ width: "10%" }}
                  />
                </div>
              ) : null} */}
                <div style={{ backgroundColor: "#DFE4E8", padding: "10px" }}>
                  {this.state.schoolNewDetails
                    ? this.state.schoolNewDetails?.other_school_details
                        ?.instructions_for_parents
                    : "NA"}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </GridItem>
    );
  }
}

export default SchoolDetail;
