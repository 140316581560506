import React from "react";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getSuperAdminService } from "service/service";
import { FormHelperText, MenuItem } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import http from "common/axios";
import { getContentWriterService } from "service/service";
import MaterialTable from "material-table";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader";
import { createLearningElement } from "service/WebSeparateService";
import { getLearningElement } from "service/WebSeparateService";
import httpV2 from "common/axiosV2";
import Snackbar from "components/Snackbar/Snackbar";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { ListItemIcon } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import { updateLearningElement } from "service/WebSeparateService";
import { SkipNext, SkipPrevious } from "@material-ui/icons";

const styles = {
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
    padding: "8px 8px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

export default class ChapterAnyalytics extends React.Component {
  state = {
    offset: 0,
    // limit:0,
    count: "",
    baseAnalytics: {},
    studentList: [],
    column: [],
    queryObject: { searchText: "" },
    checked: false,
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    mediumList: [],
    subjectList: [],
    userList: [],
    state: {
      helperText: null,
      value: "",
    },
    board: "",
    district: {
      helperText: null,
      value: "",
    },
    class: "",
    medium: "",
    subject: "",
    teacher: "",
    user: "",
    currentTabIndex: 0,
    stdWiseGraph: null,
    openDetailDialog: false,
    studentDetail: {},
    subjectWiseGraph: null,
    loading: false,
    districts: [],
    blocks: [],
    block: {
      helperText: null,
      value: "",
    },
    schools: [],
    school: "",
    classStandrd: "",
    AllmediumList: [],
    selectedMedium: null,
    selectedStandard: "",
    classList: [],
    selectSubject: "",
    subList: [],
    openDetailDialog: false,
    MediumList: [],
    mediumSelected: "",
    rows: [],
    rowsClick: [],
    tableShow: false,
    elementText: "",
    message: "",
    tableRows: [],
    toggle: false,
    expandId: "",
    editID: "",
    editLE: false,
    editedText: "",
  };
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  componentDidMount() {
    this.getData();
    this.getList();
  }

  async getData() {
    let { chapter } = this.state;
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      chapter = { ...chapter, state_id: stateRes.response[0].state_id };
      const stateList = stateRes.response;
      this.setState(
        {
          stateList: stateList,
          chapter,
          // state: {
          //   value:
          //     stateList.length > 0
          //       ? stateList[0].state_id
          //       : null,
          //   helperText: null,
          // },
          stateForGraph: stateList.length > 0 ? stateList[0].state_id : null,
        },
        () => {
          // this.getBoardByState();
          if (this.state.currentTabIndex == 0) {
            this.getDistrictsByState(this.state.stateForGraph);
          }
        }
      );
    } else {
      this.setState({
        stateList: [],
        state: {
          helperText: null,
          value: null,
        },
      });
    }
  }

  // getChapterListByChapter = async (chapter) => {
  //   let reqBody = {
  //     subject_id: chapter.subject_id,
  //   };
  //   let chapterListData = await httpV2.get(`chapters/`, {
  //     params: {
  //       limit: 100,
  //       offset: 0,
  //       subject_id: chapter.subject_id,
  //     },
  //   });
  //   console.log(chapterListData, "chapterListData");
  //   chapterListData = chapterListData.data;
  //   if (chapterListData) {
  //     console.log(chapterListData, "chapterListData");
  //     this.setState({
  //       chapterList: chapterListData.results,
  //     });
  //   }
  // };

  // getList = () =>{

  //   getLearningElement().
  //   then((res)=>{
  //     console.log(res.data.results,"res")
  //     this.setState({
  //       tableRows : res.data.results
  //     })
  //   })
  // }

  //   getList = async (chapter) => {
  //   httpV2
  //     .get(`learning_element/`, {
  //       params: {
  //         offset: 0,
  //       },
  //     })
  //     .then((data) => {
  //       console.log(data.data.results, "data");
  //       const list = data.data.results;

  //       if (list) {
  //          this.setState({
  //       tableRows : list.results
  //     })
  //       }
  //       console.log(list, "mediaList");
  //     });
  // };
  getList = async (chapter) => {
    let ListData = await httpV2.get(`learning_element/`, {
      params: {
        // limit: this.state.limit,
        offset: this.state.offset,
      },
    });
    console.log(ListData, "ListData");
    ListData = ListData.data;
    if (ListData) {
      console.log(ListData, "ListData");
      this.setState({
        tableRows: ListData.results,
        count: ListData.count,
      });
    }
  };

  renderStateMenu(type) {
    let { stateList, districts, blocks, schools, classList } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "district"
        ? districts
        : type == "class"
        ? classList
        : type == "block"
        ? blocks
        : schools;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "district"
          ? state.student_district_id
          : type == "class"
          ? classList
          : type == "block"
          ? state.student_block_id
          : state.school_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "district"
          ? state.district_name
          : type == "block"
          ? state.block_name
          : type == "class"
          ? classList
          : type == "school"
          ? state.school_name
          : "";
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }

  getDistrictsByState = (state_id) => {
    getSuperAdminService()
      .getStudentDistrict({
        student_state_id: state_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              districts: response.response,
              // district: {
              //   value:
              //     response.response.length > 0
              //       ? response.response[0].student_district_id
              //       : null,
              //   helperText: null,
              // },
              // districtForGraph: response.response.length > 0
              //   ? response.response[0].student_district_id
              //   : null,
            },
            () => {
              if (this.state.currentTabIndex == 0) {
                if (this.state.districtForGraph) {
                  //   this.getStudentDataForChart();
                }
                //this.getStudentBlock(this.state.districtForGraph)
              }
            }
          );
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
          });
        }
      });
  };

  getAllstandards = async (value) => {
    let apidata3 = {
      // board_id: this.state.selectedMedium,
      district_id: this.state.selectedMedium,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };

  getAllSubject = () => {
    let param = {
      class_id: this.state.selectedStandard,
      medium_id: this.state.mediumSelected,
    };

    getContentWriterService()
      .getSubjectByClassMed(param)
      .then((res) => {
        this.setState({
          subList: res.data.response,
        });
      });
  };

  getMedium = async () => {
    let apidata = {
      board_id: 2,
    };
    let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
      apidata
    );
    console.log(CWDistrictRes.data.response);
    this.setState({
      AllmediumList: CWDistrictRes.data.response,
    });
  };
  createElement = (id) => {
    console.log(
      this.state.selectSubject,
      this.state.selectedStandard,
      this.state.elementText
    );
    let param = {
      classes: this.state.selectedStandard,
      subject: this.state.selectSubject,
      learning_element: this.state.elementText,
    };
    createLearningElement(param).then((res) => {
      console.log(res, "res");
      if (res) {
        this.setState({
          message: "Added successfully",
          selectSubject: "",
          selectedStandard: "",
          elementText: "",
        });
      }
    });

    // httpV2.get(`learning_element/`,{
    //   params: {
    //     classes:44,
    //     subject: 229
    //   }
    // }).then((res)=>{
    //   console.log(res)
    // })
  };

  updateLearningnElement = (id) => {
    let data = {
      learning_element: this.state.editedText,
    };
    updateLearningElement(data, id).then((res) => {
      console.log(res, "res");
      if (res) {
        this.setState(
          {
            editLE: false,
          },
          () => {
            this.getList();
          }
        );
      }
    });
  };
  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    this.setState({
      MediumList: mediumRes.data.response,
    });
  };

  render() {
    return (
      <>
        <Snackbar
          autoHideDuration={5000}
          message={this.state.message}
          color="success"
          close={false}
          place="br"
          closeNotification={() =>
            this.setState({
              message: "",
            })
          }
          rtlActive={true}
          open={this.state.message ? true : false}
        ></Snackbar>
        <Card>
          <CardHeader color="primary">
            <h4 className={styles.cardTitleWhite}>Learning Outcome</h4>
          </CardHeader>
          <CardContent>
            <div className="row">
              <div className="col-6">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select State
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={this.state.state.value}
                      style={{ width: "9em" }}
                      onChange={(e) => {
                        this.setState(
                          {
                            state: { value: e.target.value, helperText: null },
                          },
                          () => {
                            this.getMedium();
                            this.getDistrictsByState(this.state.state.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("state")}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-6">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Syllabus Set*
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectedMedium}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState(
                          {
                            selectedMedium: e.target.value,
                            mediumSelected: null,
                            classList: [],
                          },
                          () => {
                            this.getAllMedium();
                          }
                        );
                      }}
                    >
                      {this.state.AllmediumList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.district_id}
                            key={stdClass.district_id}
                          >
                            {stdClass.district_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Medium*
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.mediumSelected}
                      // disabled={this.state.bulkSend}
                      onChange={(e) => {
                        this.setState(
                          {
                            mediumSelected: e.target.value,
                          },
                          () => {
                            this.getAllstandards();
                          }
                        );
                      }}
                    >
                      {this.state.MediumList.map((med) => {
                        return (
                          <MenuItem value={med.medium_id} key={med.medium_id}>
                            {med.medium_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-6">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Medium*
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.mediumSelected}
                      // disabled={this.state.bulkSend}
                      onChange={(e) => {
                        this.setState(
                          {
                            mediumSelected: e.target.value,
                          },
                          () => {
                            this.getAllstandards();
                          }
                        );
                      }}
                    >
                      {this.state.MediumList.map((med) => {
                        return (
                          <MenuItem value={med.medium_id} key={med.medium_id}>
                            {med.medium_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-6">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Standard*
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectedStandard}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState(
                          {
                            selectedStandard: e.target.value,
                          },
                          () => {
                            this.getAllSubject();
                          }
                        );
                      }}
                    >
                      {this.state.classList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.class_id}
                            key={stdClass.class_id}
                          >
                            {stdClass.class_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-6"> */}
              {/* <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Standard*
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectedStandard}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState(
                          {
                            selectedStandard: e.target.value,
                          },
                          () => {
                            this.getAllSubject();
                          }
                        );
                      }}
                    >
                      {this.state.classList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.class_id}
                            key={stdClass.class_id}
                          >
                            {stdClass.class_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div> */}
              <div className="col-6">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Subject*
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectSubject}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState({
                          selectSubject: e.target.value,
                        });
                      }}
                    >
                      {this.state.subList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.subject_id}
                            key={stdClass.subject_id}
                          >
                            {stdClass.subject_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-6">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <TextField
                      id="standard-basic"
                      label="Title"
                      style={{ width: "35em" }}
                      name="heading"
                      value={this.state.elementText}
                      onChange={(e) => {
                        this.setState({
                          elementText: e.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            {/* <div className="row justify-content-center">
             <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                   <TextField
                   id="standard-basic"
                   label="Title"
                   name="heading" 
                   style={{width:"35em"}}
                   value={this.state.elementText}
                   onChange={(e) => {
                       this.setState({
                        elementText : e.target.value
                       })
                   }}/>
                  </FormControl>
                </div>
              </div>
              </div> */}
          </CardContent>
          <CardFooter>
            <Button
              disabled={
                this.state.selectedStandard && this.state.selectSubject
                  ? false
                  : true
              }
              style={{
                color: "#fff",
                backgroundImage: "linear-gradient(60deg, #3f50b5, #8e24aa)",
              }}
              onClick={() => {
                this.createElement();
              }}
            >
              Create Learning Outcome
            </Button>
            <FormHelperText>* fields mandatory</FormHelperText>
          </CardFooter>
        </Card>
        <Card>
          <Typography variant="h6" style={{ marginLeft: "2%" }}>
            List
          </Typography>
          {this.state.tableRows
            ? this.state.tableRows.map((res) => {
                return (
                  <>
                    <ListItem
                      // style={{ padding:"2px"}}
                      alignItemsFlexStart
                      divider={true}
                      button
                      key={res.element_id}
                      onClick={() => {
                        this.setState({
                          toggle: !this.state.toggle,
                        });
                      }}
                    >
                      {this.state.editID &&
                      this.state.editID == res.element_id &&
                      this.state.editLE ? (
                        <TextField
                          //id="standard-basic"
                          id="outlined-basic"
                          label="text"
                          style={{ width: "23em" }}
                          type="text"
                          onChange={(e) => {
                            this.setState({
                              editedText: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        <ListItemText
                          primary={res.learning_element}
                          style={{ maxWidth: "300px" }}
                        />
                      )}
                      <ListItemText
                        primary={res.subject_name}
                        inset
                        style={{ maxWidth: "300px" }}
                      />
                      <ListItemText primary={res.class_name} />
                      {this.state.editLE &&
                      this.state.editID == res.element_id ? (
                        <>
                          <ListItemIcon
                            style={{ cursor: "pointer" }}
                            button
                            edge="end"
                            onClick={() => {
                              this.updateLearningnElement(res.element_id);
                              console.log("edit");
                              // this.setState({

                              // })
                            }}
                          >
                            <SaveIcon />
                          </ListItemIcon>
                          <ListItemIcon
                            style={{ cursor: "pointer" }}
                            button
                            edge="end"
                            onClick={() => {
                              console.log("edit");
                              this.setState({
                                //editID : res.element_id,
                                editLE: false,
                              });
                            }}
                          >
                            <Cancel />
                          </ListItemIcon>
                        </>
                      ) : (
                        <ListItemIcon
                          style={{ cursor: "pointer" }}
                          button
                          edge="end"
                          onClick={() => {
                            console.log("edit");
                            this.setState({
                              editID: res.element_id,
                              editLE: true,
                            });
                          }}
                        >
                          <EditIcon />
                        </ListItemIcon>
                      )}
                      {/* {this.state.toggle == true && this.state.expandId == res.element_id? (
                          <ExpandLess />
                           ) : (
                          <ExpandMore 
                          onClick={()=>{
                            this.setState({
                              expandId : res.element_id
                            })
                            
                          }}/>
                          )} */}
                    </ListItem>
                    {this.state.expandId == res.element_id ? (
                      <Collapse
                        in={this.state.toggle == true}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem>
                            <ListItemText primary="Sub :" />
                            <ListItemText primary={res.subject_name} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Class :" />
                            <ListItemText primary={res.class_name} />
                          </ListItem>
                        </List>
                      </Collapse>
                    ) : (
                      ""
                    )}
                  </>
                );
              })
            : null}
          {
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState(
                    {
                      offset: this.state.offset - 10,
                      // limit:this.state.limit-10
                    },
                    () => {
                      this.getList();
                    }
                  );
                }}
                disabled={this.state.offset == 0 ? true : false}
              >
                <SkipPrevious />
              </Button>
              <Button
                onClick={() => {
                  this.setState(
                    {
                      offset: this.state.offset + 10,
                      // limit: this.state.limit + 10
                    },
                    () => {
                      this.getList();
                    }
                  );
                }}
                disabled={
                  this.state.offset >= this.state.count - 10 ? true : false
                }
              >
                <SkipNext />
              </Button>
            </div>
          }
        </Card>
      </>
    );
  }
}
