import http from "./../common/axios";
import httpV3 from "./../common/axiosV3";

export default class SuperAdminService {
  loginUser(data) {
    return http.post(`/adminLogin/`, data);
  }

  loginVerifyToken(data) {
    return http.post(`/verfiyOTP/`, data);
  }

  addAdminContentWriter(data) {
    return http.post(`/addAdminContentWriter/`, data);
  }

  getAllAdminContentWriterList(data) {
    return http.post(`/adminCWdetailList/`, data);
  }

  getAllChapterList(data) {
    return http.post(`/chapterDetailsList/`, data);
  }

  getAllState() {
    return http.get(`/getStates/`);
  }

  getAllBoardFromState(data) {
    return http.post(`/getBoardsByState/`, data);
  }

  getAllDistrictFromBoard(data) {
    return http.post(`/getDistrictByBoard/`, data);
  }

  getAllClassesFromDistrict(data) {
    return http.post(`/getClassesByDistrict/`, data);
  }

  getAllAdminList() {
    return http.get(`/adminList/`);
  }

  assignClassesToUser(data) {
    return http.post(`/addClasses/`, data);
  }

  updateAdminCWStatus(data) {
    return http.put(`/updateAdminCWStatus/`, data);
  }

  getAdminCWDetail(data) {
    return http.post(`/adminContentWriterDetails/`, data);
  }

  UpdateAdminCWDetail(data) {
    return http.put(`/updateProfileDetails/`, data);
  }

  updateChapterStatus(data) {
    return http.put(`/updateChapterStatus/`, data);
  }

  addChapterPart(data) {
    return http.post(`/addChapterParts/`, data);
  }

  getTotalNumberOfChapters() {
    return http.get("/totalChapterNumbers/");
  }

  getTotalNumberOfChapterByClass() {
    return http.get("/analyticsChaptersByClass/");
  }

  getTeacherWiseChapterData() {
    return http.get("/chaptersByContentcreators/");
  }

  updateChapterCredit(data) {
    return http.put("/updateChapterCredits/", data);
  }

  getStdWiseAnalytics(data) {
    return http.post("/analyticsBySubject/", data);
  }

  getStudentStates = () => {
    return http.get(`/getstudentstates/`);
  };

  getStudentDistrict = (data) => {
    return http.post(`/getstudentdistrict/`, data);
  };

  getStudentBlock = (data) => {
    return http.post(`/getstudentblock/`, data);
  };

  schoolbulkupload = (data) => {
    return http.post(`/schoolbulkupload/`, data);
  };

  getSchoolsForBlock = (data) => {
    return http.post(`/getSchoolsStudentApplication/`, data);
  };

  addStudentState = (data) => {
    return http.post(`/createstudentstate/`, data);
  };

  addStudentDistrict = (data) => {
    return http.post(`/createstudentdistrict/`, data);
  };

  addStudentBlock = (data) => {
    return http.post(`/createstudentblock/`, data);
  };

  deleteSubject = (data) => {
    return http.post(`/deletesubject/`, data);
  };

  getAdminId = () => {
    return http.get(`/getadminid/`);
  }
  sendNotificationAll = (data) => {
    return http.post(`/sendnotificationtoall/`, data);
  };

  getActivity = () => {
    return http.get(`/getdailyactivity/`);
  }

  createActivity = (data) => {
    return http.post(`/createdailyactivity/`, data);
  }

  uploadActivityMedia = (data, cb) => {
    return http.post(`/uploadactivitymedia/`, data, {
      onUploadProgress: (progressEvent) => {
        cb(progressEvent);
      },
    });
  }

  bulkUploadUsers = (data) => {
    return http.post(`/contentwriterbulkupload/`, data);
  }
  getCWBySearch = (data) => {
    return http.post(`/getAdminTeachers_by_search/`, data);
  }
  getSummaryList = (data) => {
    return http.post(`/student_summary/`, data);
  }

  AddSingleSchool= (data) => {
    return http.post(`/creat_School_new/`, data);
  }
  getAllNotification = (data) => {
    return http.post(`/getallnotifications/`, data);
  };
  deleteNotification = (data) => {
    return http.post(`/deleteallnotification/`, data);
  };
  UpdateSchool = (data) => {
    return http.put(`/updateschool/`, data);
  }
  scheduleEmail = (data) => {
    return http.post(`/schedule_report/`, data);
  }
  getScheduleEmailList = () => {
    return http.get(`/schedule_report_list/`);
  }
  deleteScheduleEmail = (data) => {
    return http.delete(`/delete_schedule_report/${data}/`);
  }
  updateScheduleEmail = (data) => {
    return http.patch(`/update_schedule_report/${data.id}/`, data);
  }
  getBackground = () => {
    return http.get(`/get_app_background/`);
  }
  updateBackground = (data) => {
    return http.post(`/add_app_background/`, data);
  }
  updateSubject = (data) => {
     return http.patch(`/update_subject/`, data);
  }
  getTopperList = (data) => {
    return http.post(`/getstudenttopperboard/`, data);
 }
 sendFCMNotification = (data) => {
  return http.post(`/send_notification/`, data);
}
getAllSchools = (data) => {
  return http.get(`getschoollist/?search=${data.searchText}&limit=50`)
}

getAllKendra(data) {
  return http.post(`/school_kendra/`, data);
}

createAMUser(data) {
  return http.post(`/register_administrative_user/`, data);
}

vsidataBlockwiseAppTime(data){
  return http.post(`/user_time_analytics/`,data)
}
vsidataBlockwiseWebTime(data){
  return http.post(`/user_web_time_analytics/`,data)
}

vsidataBlockwiseAppLifeTime(data){
  return http.post(`/app_user_lifetime_block_aggregate/`,data)
}

vsidataBlockwiseWebLifeTime(data){
  return http.post(`/user_web_time_analytics/`,data)
}

getVSIdataUserwise(id){
  return http.get(id)
}
sendNotificationByFCM = (data) => {
  return http.post(`/send_notification_by_fcm/`, data);
}
CreateUserLevelExcel = (url) => {
  return http.get(url);
}
getScheduledNotification = () => {
  return http.get(`/get_scheduled_notification/`);
}
getTopperBackground = () => {
  return http.get(`/get_app_background/?service_name=topper_background`);
}
getDailyActivityBackground = () => {
  return http.get(`/get_app_background/?service_name=daily_activity_background`);
}

getDashboardSliderOneBackground = () => {
  return http.get(`/get_app_background/?service_name=dashboard_slider_1`);
}

getDashboardSliderTwoBackground = () => {
  return http.get(`/get_app_background/?service_name=dashboard_slider_2`);
}

getDashboardSliderThreeBackground = () => {
  return http.get(`/get_app_background/?service_name=dashboard_slider_3`);
}

getSPecialCourseBackground = () => {
  return http.get(`/get_app_background/?service_name=special_course_background`);
}

getLivesessionFirst = () => {
  return http.get(`/get_app_background/?service_name=live_session_1`);
}

getLivesessionSecond = () => {
  return http.get(`/get_app_background/?service_name=live_session_2`);
}

getLiveSessionNotice = () => {
  return http.get(`/get_app_background/?service_name=live_session_notice`);
}

updateSpecialCourseClass = (param) => {
  return http.put(`/updateClass/`,param);
}

creatSpecialCourse = (param) => {
  return http.post(`/createClass/`,param);
}

creatLOSubject = (param) => {
  return http.post(`/lo_subject/`,param);
}

getLOSubject = (param) => {
  return http.get(`/lo_subject/?student_class=${param.class}&student_district=${param.medium}&medium=${param.district}`);
}

updateLOSubject = (id,param) => {
  return http.patch(`/lo_subject/${id}/`,param);
}

getLOMonths = (param) => {
  return http.get(`/lo_months/?limit=12`,);

}
getPubishedLOs = (url) => {
  return http.get(url);

}

publishLos= (param) => {
  return http.post(`/learning_outcome/`,param);

}

getLOSubject= (param) => {
  return http.get(`/lo_subject/?student_class=${param?.class}&student_district=${param?.district}&medium=${param?.medium}`);

}

updatePublishedLos =(paramID,data)=>{
  return http.patch(`/learning_outcome/${paramID}/`, data);
  
}

getFAQLoginTypes = () => {
  return http.get(`/faqs_login_type/list/`);
}

getFAQCategoryTypes = (param) => {
  return http.get(`/faqs_categories/list/?login_type=${param}`);
}

addFAQCategoryTypes = (param) => {
  return http.post(`/faqs_categories/create/`,param);
}

editFAQCategoryTypes =(param,id)=>{
  return http.patch(`faqs_categories/${id}/`,param);
}

deleteFAQCategoryTypes =(id)=>{
  return http.delete(`faqs_categories/${id}/`);
}


editFAQQuestionTypes =(param,id)=>{
  return http.patch(`faqs/${id}/`,param);
}

deleteFAQQuestionTypes =(id)=>{
  return http.delete(`faqs/${id}/`);
}

getAllFAQsList = (res)=>{
  return http.get(res)
}

addFAQList =(param)=>{
  return http.post(`/faqs/create/`,param);
}
getChallengeList= () => {
  return httpV3.get(`/challenge_config/?required=challenges`);
}

getSubChallengeListByChallenge= (data) => {
  return httpV3.get(`/challenge_config/?challenges=${data}&required=sub_challenges`);
}

getChallengeConfig= (challengeName, subChallengeName) => {
  return httpV3.get(`/challenge_config/?sub_challenges=${subChallengeName}&challenges=${challengeName}`);
}

getImageURLs3 = (payload) => {
  return httpV3.post(`/challenge_data/upload_challenge_images/`, payload);
}

createSavedChallenge = (payload) => {
  return httpV3.post(`/challenge_data/`, payload);
}

getAllCreatedChallengeData = (filter_str) => {
  return httpV3.get(
    `/challenge_data/?${filter_str}`
    );
}

deleteChallenge = (challenge_id) => {
  return httpV3.delete(`/challenge_data/${challenge_id}/`);
}

updateSavedChallenge = (challenge_id, payload) => {
  return httpV3.put(`/challenge_data/${challenge_id}/`, payload);
}

getSingleChallengeData = (challenge_id) => {
  return httpV3.get(`/challenge_data/${challenge_id}/`);
}

publishChallenge = (challenge_id) => {
  return httpV3.post(`/challenge_data/${challenge_id}/publish_challenge/`);
}

getAdvanceFilterData = () => {
  return httpV3.get(`/advancefilter_config/?required=user_type`);
}

getAdvanceFilterTimeframeData = (user_type) => {
  return httpV3.get(`/advancefilter_config/?user_type=${user_type}&required=timeframe`);
}

getAdvanceFilterTimeframeValueData = (user_type, timeframe) => {
  return httpV3.get(`/advancefilter_config/?required=value&user_type=${user_type}&timeframe=${timeframe}`);
}

getAdvanceFilterComparisonData = (user_type, timeframe, timeframeValue) => {
  return httpV3.get(`/advancefilter_config/?required=comparison_operator&user_type=${user_type}&timeframe=${timeframe}&value=${timeframeValue}`);
}

getAllCreatedMilestoneData = (filter_str) => {
  return httpV3.get(
    `/milestone/?${filter_str}`
    );
}

createMilestone = (payload) => {
  return httpV3.post(`/milestone/`, payload);
}

deleteMilestone = (milestone_id) => {
  return httpV3.delete(`/milestone/${milestone_id}/`);
}

updateMilestone = (milestone_id, payload) => {
  return httpV3.put(`/milestone/${milestone_id}/`, payload);
}

updateMilestoneStatus = (milestone_id, payload) => {
  return httpV3.patch(`/milestone/${milestone_id}/`, payload);
}

readSingleMilestone = (milestone_id) => {
  return httpV3.get(`/milestone/${milestone_id}/`);
}


getNudgeList= () => {
  return httpV3.get(`/nudges_config/?nudges_type_list=True`);
}

getWhatsappTemplates= (selected_nudge, platform) => {
  return httpV3.get(`/templates/?nudges_type=${selected_nudge}&active=True&platform=${platform}`);
}

getNudgeConfig= (selected_nudge) => {
  return httpV3.get(`nudges_config/?nudges_type=${selected_nudge}`);
}

getAllCreatedNudgeData = (filter_str) => {
  return httpV3.get(
    `/nudges/?${filter_str}`
    );
}

createNudge = (payload) => {
  return httpV3.post(`/nudges/`, payload);
}

updateNudge = (nudge_id, payload) => {
  return httpV3.put(`/nudges/${nudge_id}/`, payload);
}

deleteNudge = (nudge_id) => {
  return httpV3.delete(`/nudges/${nudge_id}/`);
}

updateNudgeStatus = (nudge_id, payload) => {
  return httpV3.patch(`/nudges/${nudge_id}/`, payload);
}

readSingleNudge = (nudge_id) => {
  return httpV3.get(`/nudges/${nudge_id}/`);
}

createPremiumResource = (data) => {
  return http.post(`/premium/resource-details/`, data);
}
getPremiumResource = (url) => {
  return http.get(url);
}
editPremiumResource(id, data){
  return http.put(`/premium/resource-details/${id}/`,data);
}

deactivateResource(id, data){
  return http.put(`/premium/resource-details/${id}/`,data);
}
deleteResource(id, data){
  return http.put(`/premium/resource-details/${id}/`,data);
}

async addFilesOnS3(data, name){
  try{
    const formData = new FormData();
    formData.append("functionality_name", name);
    formData.append("file", data);
  
    const response = await http.post(`/add_file_on_s3_bucket/`, formData, {
      headers: {
          "content-type": "multipart/form-data",
      },
    });
    return response;

  }catch(err){
  console.log(err);
  }
}

  async getFLNLearningSubjects() {
    return http.get(`/fln_learning_tool_subject/`);
  }

  async getFLNTags() {
  return http.get(`/fln_learning_tool_tags/`);
  }

  async getFLNLearningItems(subject_id) {
    return http.get(`/fln_learning_tool_learning_item/?subject=${subject_id}`);
  }

  async getFLNLevels(learning_id) {
    return http.get(`/fln_learning_tool_level/?learning_item=${learning_id}`);
  }

  async getFLNLearningObjects(params) {
    return http.get(`/fln_learning_tool/?subject=${params?.subject_id}&reading_level=${params?.reading_level_id}&difficulty_level=${params?.difficulty_level_id}`);
  }

  async getFLNObjectsUsingURL(params) {
    return http.get(`/fln_learning_tool/?difficulty_level=${params?.difficulty_level_id}&limit=${params?.limit}&offset=${params?.offset}&reading_level=${params?.reading_level_id}&subject=${params?.subject_id}`);
  }

  async submitFLNform (data){
  return http.post(`/fln_learning_tool/`, data);
  }

  async submitFLNUpdateform (id, data){
  return http.patch(`/fln_learning_tool/${id}/`, data);
  }

  async deleteFLNLearningRecord (id){
  return http.delete(`/fln_learning_tool/${id}/`);
  }

  async downloadFLNData (params){
  return http.get(`/fln_learning_tool/download_excel_sheet?subject=${params?.subject_id}&reading_level=${params?.reading_level_id}&difficulty_level=${params?.difficulty_level_id}`);
  }

}
