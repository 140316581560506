//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./FLNHome.module.css";
import {
  getAdministrativeService,
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import { Autocomplete } from "@material-ui/lab";
import highcharts3d from "highcharts/highcharts-3d";
import AlertDialog from "components/AlertDialog/AlertDialog";
highcharts3d(Highcharts);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dropdown: {
    width: "9em",
    marginTop: "35px",
  },
  inputWrapper: {
    display: "flex",
    width: "70%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "5px",
  },
  cardFooter: {
    justifyContent: "flex-end",
    width: "68%",
    paddingBottom: "15px",
  },
  uploadFile: {
    display: "flex",
    width: "50%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  uploadButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
};

export default class HMDashboardPage extends React.Component {
  state = {
    district: {
      helperText: null,
      value: null,
    },
    block: {
      value: null,
      helperText: null,
    },
    school: {
      value: null,
      helperText: null,
    },
    state: {
      helperText: null,
      value: null,
    },
    districtList: [],
    blocks: [],
    mediumSelected: null,
    classList: [],
    selectedMedium: null,
    yearList: null,
    monthList: null,
    ChaluSelectedMonth: null,
    selectedYear: null,
    ChaluSelectedYear: null,
    modalOpen: false,
    // monthDataType: JSON.parse(localStorage.getItem("HMSchool")).fln_data_type,
    modalValues: [],
    yearList: null,
    selectedMonth: [],
    allYearSelected: null,
    MonthsList: null,
    subjectList: null,
    selectedStage: null,
    allYearSelected: null,
    arrayForLine: null,
    allCount: null,
    ViewTrend: false,
    ViewSummary: true,
    NextFLNSub: null,
    levelComparsionArr: [],
    firstTime: false,
    isSetAuto: false,
    loading: false,
    selectedStandard: null,
    selectedHalf: "FIRST HALF",
    districtComparision: false,
    schoolComparision: false,
    showGraph: false,
    compareBlockData: null,
    showSchoolGraph: false,
    compareSchoolData: null,
    compareKendra: false,
    showKendraGraph: false,
    compareKendraData: null,
    kendraBlockwise: [],
    blockModal: false,
    BlockValidation: false,
    subValidation: false,
    block1: {
      value: null,
      helperText: null,
    },
    block2: {
      value: null,
      helperText: null,
    },
    block3: {
      value: null,
      helperText: null,
    },
    block4: {
      value: null,
      helperText: null,
    },
    block5: {
      value: null,
      helperText: null,
    },
    isSetAuto1: false,
    isSetAuto2: false,
    isSetAuto3: false,
    isSetAuto4: false,
    isSetAuto5: false,
    school1: {
      value: null,
      helperText: null,
    },
    school2: {
      value: null,
      helperText: null,
    },
    school3: {
      value: null,
      helperText: null,
    },
    school4: {
      value: null,
      helperText: null,
    },
    school5: {
      value: null,
      helperText: null,
    },
    schoolListArray: [],
    arrayForCount: null,
    schoolSelected:true,
    schoolListArray:null,
    selectedKendra:null
  };

  componentDidMount() {
    this.getData();
    this.getAllMedium();
    this.getYears();
    this.getSubjectList();
  }

  async getData() {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: 1,
      })
      .then((res) => {
        console.log(res, "Res");
        if (res.status == 200) {
          console.log(
            localStorage.getItem("AMdetails"),
            "localStorage.getItem"
          );
          this.setState({
            districtList: res.data.response,
          });
          if (localStorage.getItem("AMdetails")) {
            let Val = JSON.parse(localStorage.getItem("AMdetails"));
            console.log(Val.student_district_id, "VAL");
            this.setState(
              {
                district: {
                  helperText: null,
                  value: Val.student_district_id,
                },
              },
              () => {
                this.getStudentBlock(Val.student_district_id);
              }
            );
          }
        }
      });
  }

  getYears = () => {
    getHMService()
      .getYear()
      .then((res) => {
        console.log(res, "res");
        this.setState({
          yearList: res.data.years,
        });
        if (res.data.years.length > 0) {
          this.setState(
            {
              selectedYear: res.data.years[0],
              ChaluSelectedYear: res.data.years[0],
              TulanaSelectedYear: res.data.years[0],
            },
            this.getMonthsList(res.data.years[0])
          );
        }
      });
  };

  setAllYears = () => {
    let array = [];
    this.state.yearList.map((res) => {
      array.push(res);
    });
    this.setState({ allYearSelected: array, selectedYear: null });
  };
  getMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.months)
          this.setState(
            {
              monthList: res.data.months,
              ChaluSelectedMonth: res.data.months[0],
              TulanaSelectedMonth: res.data.months[0],
              MonthsList: res.data.months[0],
            },
            () => {
              if (this.state.tab2) {
                this.getDataForCompare();
              }
            }
          );
      });
  };

  getSubjectList = (data) => {
    //  arrayData.push(data)
    //  this.setState({
    //   arrayAnyltics : arrayData
    //  })
    // console.log(data,"fat",arrayData)
    let districtID = JSON.parse(localStorage.getItem('AMdetails')) ? JSON.parse(localStorage.getItem('AMdetails')).student_district_id : null
    getHMService()
      .getSubjectList({
        student_district_id:districtID
      })
      .then((res) => {
        console.log(res, "res");
        if (res.data.results) {
          this.setState(
            {
              subjectList: res.data.results,
              selectedStage: res.data.results[0].subject_id,
            },
            () => {
              // this.getFirstAnyltics();
            }
          );
        }
      });
  };
  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    this.setState({
      MediumList: mediumRes.data.response,
    });
  };

  getAllstandards = async (value) => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };
  getStudentBlock = async (district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
            },
            () => {
              this.getSchools(this.state.block.value);
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  };

  getClassName = () => {
    var result = this.state.classList.find((obj) => {
      return obj.class_id === this.state.selectedStandard;
    });
    console.log(result, "classList");
    return result?.class_name;
  };

  getMediumName = () => {
    var result = this.state.MediumList?.find((obj) => {
      return obj.medium_id === this.state.mediumSelected;
    });
    console.log(result, "classList");
    return result?.medium_name;
  };

  getDataReports = () => {
    console.log(this.state.school, "schools");
    let param = {
      // student_class: this.state.selectedStandard,

      year_list: this.state.allYearSelected
        ? this.state.allYearSelected
        : [this.state.ChaluSelectedYear],
      month_list: this.state.selectedMonth,
      month_half_list: this.state.lineSelectedHalf
        ? [this.state.lineSelectedHalf]
        : ["FIRST HALF", "SECOND HALF"],
      student_district_id: this.state.district.value,
      subject: this.state.selectedStage,
    };

    if (this.state.block.value) {
      param["student_block_id"] = this.state.block.value;
    }
    if (this.state.school) {
      param["school"] = this.state.school.school_id;
    }
    if (this.state.mediumSelected) {
      param["medium"] = this.state.mediumSelected;
    }
    if (this.state.selectedStandard) {
      param["student_class_id"] = this.state.selectedStandard;
    }

    console.log(param, "param");

    getAdministrativeService()
      .getTrendLineDataReport(param)
      .then((res) => {
        console.log(res, "Res");
        const entries = Object.entries(
          res.data.analytics_level_wise_percentage
        );
        const entriesForCount = Object.entries(res.data.analytics_level_wise);
        console.log(entries, "entries");
        if (entries.length > 0) {
          if (entries[0][0] == "प्रारंभिक") {
            console.log("entries1");
            this.setState({
              arrayForLine: entries,
              allCount: res.data.all_counts,
              arrayForCount: res.data.analytics_level_wise,
            });
          } else {
            console.log("entries2");
            this.setState({
              arrayForLine: entries,
              allCount: res.data.all_counts,
              arrayForCount: res.data.analytics_level_wise,
            });
          }
        } else {
          this.setState({
            arrayForLine: entries,
            allCount: res.data.all_counts,
            arrayForCount: res.data.analytics_level_wise,
          });
        }
      });
  };

  getCSVDataReports = () => {
    let param = {};
    if (this.state.district.value == 0) {
      param = {
        student_class: this.state.selectedStandard,
        medium: this.state.mediumSelected,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        month_half: this.state.selectedHalf
          ? this.state.selectedHalf
          : "FIRST HALF",
        student_district_id: this.state.district.value,
      };
    } else {
      param = {
        student_class: this.state.selectedStandard,
        // medium: this.state.mediumSelected,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        student_district_id: this.state.district.value,
      };
    }

    getAdministrativeService()
      .getCSVDataEntryReport(param)
      .then((res) => {
        console.log(res.data, "res");
        if (res.data) {
          this.convertJsonToCsv(res.data, "excelFile");
        }
      });
  };

  convertJsonToCsv(items, fileTitle) {
    console.log(typeof items, "items");
    var jsonObject = JSON.stringify(items);
    var exportedFilenmae = fileTitle || "export.csv";

    var blob = new Blob([items], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  getCatogeriesForSpline = () => {
    let array = [];
    let array1 = [];
    this.state.arrayForLine.map((res) => {
      res.map((resp) => {
        console.log(resp, ":resp");
        if (typeof resp == "object") {
          array.push(Object.keys(resp));
        }
      });
    });

    console.log(array, "array45454545", array1);
    array = array?.length > 1 ? array[0] : array;
    console.log(array, "array");
    return array;
  };

  getLevels = (id) => {
    let array1 = [];
    this.state.arrayForLine.map((res) => {
      res.map((resp) => {
        console.log(resp, "4545resp");
        if (typeof resp !== "object") {
          array1.push(resp);
        }
      });
    });

    console.log(array1, "array45454545", array1);

    return array1[id];
  };

  ViewTrendLine = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>
                {/* <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="School"
                  id="School_dropdown"
                  value={school.value}
                  error={school.helperText}
                  helperText={!school.value && school.helperText}
                  onChange={(e) => {
                    this.setState({
                      school: {
                        value: e.target.value,
                        helperText: null,
                      },
                      updateschool: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.schools?.map((res) => {
                    return (
                      <MenuItem value={res.school_id}>
                        {res.school_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                    });
                    this.onChangeHandle(value);
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                      Medium*
                    </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              marginTop: "35px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div>वर्ष</div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    this.setAllYears();
                  }}
                  style={{
                    backgroundColor:
                      this.state.allYearSelected?.length > 0
                        ? "#DB83E2"
                        : "#C2B0BB",
                  }}
                >
                  सर्व
                </Button>
                {this.state.yearList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            selectedYear: res,
                            allYearSelected: null,
                          },
                          () => {
                            this.getMonthsList(res);
                          }
                        );
                      }}
                      style={{
                        backgroundColor:
                          this.state.selectedYear === res
                            ? "#DB83E2"
                            : "#C2B0BB",
                        marginLeft: "10px",
                      }}
                    >
                      {res}
                    </Button>
                  );
                })}
              </div>
            </div>

            <div>
              <div>अध्ययनस्तर विषय निवडा.</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.subjectList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        this.setState({
                          selectedStage: res.subject_id,
                        });
                      }}
                      style={{
                        width: "15%",
                        marginTop: "5px",
                        backgroundColor:
                          this.state.selectedStage === res.subject_id
                            ? "#DB83E2"
                            : "#C2B0BB",
                        marginLeft: "10px",
                      }}
                    >
                      {res.subject}
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flexDirection: "row-reverse",
            marginTop: "35px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <div>
            {this.state.district.value == 0 ? (
              <div style={{ marginRight: "38em", width: "40%" }}>
                <div>Part</div>
                <div
                  style={{ display: "flex", flexWrap: "wrap", width: "150%" }}
                >
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: null,
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == null
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    ALL
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "FIRST HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "FIRST HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    I
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "SECOND HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "SECOND HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    II
                  </Button>
                </div>
              </div>
            ) : null}
          </div>

          <div style={{ width: "40%" }}>
            <div>महिना</div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {this.state.monthList?.map((res) => {
                return (
                  <Button
                    onClick={() => {
                      if (
                        this.state.selectedMonth?.length > 0 &&
                        this.state.selectedMonth.includes(res)
                      ) {
                        this.state.selectedMonth.splice(
                          this.state.selectedMonth.indexOf(res),
                          1
                        );
                        this.setState({
                          selectedMonth: [...this.state.selectedMonth],
                        });
                      } else {
                        this.setState({
                          selectedMonth: [...this.state.selectedMonth, res],
                        });
                      }
                    }}
                    style={{
                      backgroundColor: this.state.selectedMonth.includes(res)
                        ? "#DB83E2"
                        : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    {res}
                  </Button>
                );
              })}
              {console.log(this.state.selectedMonth, "select mnth")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() =>
              this.setState(
                {
                  firstTime: false,
                },
                () => {
                  if (this.state.selectedMonth.length > 0) {
                    this.getDataReports();
                  }
                }
              )
            }
          >
            View
          </Button>

          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],

                ViewSummary: false,

                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,

                selectedSub: null,
                showKendraGraph: false,
              });
            }}
          >
            reset
          </Button>
        </div>

        {this.state.allCount ? (
          <div
            style={{
              color: "#415EB6",
              fontSize: "20px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            {console.log(this.state.allCount, "allCount")}
            <div>
              विद्यार्थी संख्या: {this.state.allCount?.total_student_in_school}
            </div>
            <div style={{ marginLeft: "20px" }}>
              {"  "} उपलब्ध नोंदी:{" "}
              {this.state.allCount?.total_available_entries}
            </div>
            {"  "}{" "}
            <div style={{ marginLeft: "20px" }}>
              अनुपलब्ध नोंदी: {this.state.allCount?.total_unavailable_entries}
            </div>
          </div>
        ) : null}

        <div class="row">
          {this.showTrendLineGraph(this.state.arrayForCount)}{" "}
          {console.log(this.state.arrayForLine, "arrayForLine")}
          {/* {this.state.arrayForLine?.map((res, index) => {
            let array = [];
            let array1 = [];
            console.log(res, "res");
            res.map((resp) => {
              if (typeof resp !== "object") {
                array.push(resp);
              } else {
                array.push(Object.values(resp));
                // console.log(Object.keys(resp),"keys pair");
                // this.setState({
                //   monthListAxis :[...this.state.monthListAxis,res]
                // })
                // this.state.monthListAxis.push(Object.keys(resp))
              }

              console.log(resp, "resp", array);
            });
            console.log("resp", array);

            Object.values(array).map((res) => {
              console.log(typeof res, "res aray");
              if (typeof res == "object") {
                const iterator = res.values();

                for (const value of iterator) {
                  console.log(value);
                  array1.push(value);
                }
              }
            });
            console.log(Object.values(array), array1);

            return (
              <div
                class="col"
                style={{
                  padding: "5px",
                  marginTop: "25px",
                  marginBottom: "40px",
                }}
              >
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={{
                      marginLeft: "48%",
                      padding: "10px",
                      backgroundColor: "#2BBC6E",
                      borderRadius: "10px",
                    }}
                  >
                    स्तर : {this.getLevels(index)}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories: this.getCatogeriesForSpline(),
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "percentage",
                        },
                      },
                    //   tooltip: {
                    //     formatter: function() {
                    //       var currentPoint = this;
                    //  console.log(currentPoint,"currentPoint.series",this.y)
                    //  let count
                    //  console.log(this.state.allCount,"this.state.allCount)")
                    //  if(this.state.allCount)
                    //  { count = (this.y * this.state.allCount?.total_available_entries)/100}
                    //  console.log(count,"count4545")     
                    //  return currentPoint.series
                    //     }
                    //   },
                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "percentage",
                          data: array1,
                        },
                      ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth - 260 - 120) / 1.05,
                        type: "spline",
                      },
                    }}
                  ></HighchartsReact>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    );
  };

  getSummaryGraph = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>
                {/* <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="School"
                  id="School_dropdown"
                  value={school.value}
                  error={school.helperText}
                  helperText={!school.value && school.helperText}
                  onChange={(e) => {
                    this.setState({
                      school: {
                        value: e.target.value,
                        helperText: null,
                      },
                      updateschool: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.schools?.map((res) => {
                    return (
                      <MenuItem value={res.school_id}>
                        {res.school_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                    });
                    this.onChangeHandle(value);
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                Medium*
              </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTime: false,
                  },
                  () => {
                    this.getMonthsList(e.target.value);
                    // this.getFirstAnyltics()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTime: false,
                  },
                  () => {
                    // this.getFirstAnyltics()
                    // this.getSummaryForGraph()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          {this.state.district.value == 0 ? (
            <div style={{ marginLeft: "10px" }}>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.selectedHalf}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      selectedHalf: e.target.value,
                      firstTime: false,
                    },
                    () => {
                      // this.getFirstAnyltics()
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                <MenuItem value="FIRST HALF">part-I</MenuItem>
                <MenuItem value="SECOND HALF">part- II</MenuItem>
              </Select>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() =>
              this.setState(
                {
                  firstTime: false,
                },
                () => this.getSummaryForGraph()
              )
            }
          >
            search
          </Button>

          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
            }}
            onClick={() => window.location.reload()}
          >
            Reset
          </Button>
        </div>

        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() =>
              window.location.reload()
            }
          >
            Reset
          </Button>
        </div> */}

        <div class="row">
          {this.state.levelComparsionArr?.map((res, i) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      margin: "10px",
                      borderRadius: "10px",
                      color: "#fff",
                      backgroundColor: "#2BBC6E",
                      padding: "10px",
                      marginLeft:
                        i + 1 == this.state.levelComparsionArr?.length
                          ? "15%"
                          : "35%",
                    }}
                  >
                    {res.subject_name}
                  </div>
                </div>
                {this.getCharts(res.analytics_level_wise, res)}
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: "60px" }}></div>
      </div>
    );
  };

  getSummaryForGraph = () => {
    console.log(
      this.state.ChaluSelectedMonth,
      "chalu",
      this.state.ChaluSelectedYear
    );
    let param = {
      student_district_id: this.state.district.value,
      year: this.state.ChaluSelectedYear,
      month: this.state.ChaluSelectedMonth,
      month_half: this.state.selectedHalf,
    };
    if (this.state.block.value) {
      param["student_block_id"] = this.state.block.value;
    }
    if (this.state.school) {
      param["school"] = this.state.school.school_id;
    }
    if (this.state.mediumSelected) {
      param["medium"] = this.state.mediumSelected;
    }
    if (this.state.selectedStandard) {
      param["student_class_id"] = this.state.selectedStandard;
    }
    if (this.state.NextFLNSub) {
      param["subject"] = this.state.NextFLNSub;
    }

    console.log(param, "param");
    let array = [];
    if (this.state.NextFLNSub || !this.state.firstTime) {
      getAdministrativeService()
        .getSummaryGraphData(param)
        .then((res) => {
          // arrayData.push(res.data)
          console.log(res.data.analytics_level_wise, "level1", res.data);

          if (res) {
            array =
              this.state.levelComparsionArr.length ==
              this.state.subjectList.length
                ? []
                : this.state.levelComparsionArr;
            array.push(res.data);
            this.setState(
              {
                anylyticsList: res.data,
                NextFLNSub: res.data.next_subject,
                firstTime: true,
                levelComparsionArr: array,
              },
              () => {
                this.getSummaryForGraph();
              }
            );
          }
        });
    }
  };

  getCharts = (data, res) => {
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: {
              text: "",
            },
            xAxis: {
              categories: this.getCatergories(data),
              crosshair: true,
            },
            yAxis: {
              stackLabels: {
                enabled: true,
                align: "center",
              },
              visible: true,
              title: {
                enabled: true,
                text: "percentage",
              },
            },

            labels: {
              items: [
                {
                  html: "",
                  style: {
                    left: "50px",
                    top: "18px",
                  },
                },
              ],
            },
            series: this.getSerisForGraph(data),

            tooltip: {
              formatter: function () {
                var result = data.find((item) => item.level === this.x);

                let pcnt = result?.count;

                return "Count=" + pcnt;
              },
            },

            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              width: window.innerWidth / 2.8,
              type: "column",
              backgroundColor: "#fff",
            },
          }}
        ></HighchartsReact>
        {console.log(data, "data")}
        <div style={{ padding: "10px", display: "flex" }}>
          <div>विद्यार्थी संख्या: {res.total_student_count}</div>
          <div style={{ marginLeft: "25px" }}>
            उपलब्ध नोंदी: {res.total_available_entries}
          </div>
          <div style={{ marginLeft: "25px" }}>
            अनुपलब्ध नोंदी: {res.total_unavailable_entries}
          </div>
          <div style={{ marginLeft: "25px" }}>
            नोंदणी टक्केवारी : {res.available_entries_percentage}%
          </div>
        </div>
      </>
    );
  };

  getCatergories = (data) => {
    let array = [];
    data.map((res) => {
      array.push(res.level);
    });
    return array;
  };

  getSerisForGraph = (data) => {
    let array = [];
    let obj = {
      name: "percentage",
      data: null,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    data.map((res) => {
      array.push(res.percentage);
    });

    obj = {
      name: "percentage",
      data: array,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    return [obj];
  };

  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };

  onChangeHandle = (val, value) => {
    console.log(val, value, "val from onChangeHandle");
    this.setState({
      school: val,
    });
  };

  ViewDistrictComparision = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Subject*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={this.state.selectedSub}
                  onChange={(e) => {
                    this.setState({
                      selectedSub: e.target.value,
                      showGraph: false,
                      blockModal: false,
                    });
                  }}
                >
                  {this.state.subjectList?.map((res) => {
                    return (
                      <MenuItem value={res.subject_id}>{res.subject}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.blockModal ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                Medium*
              </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                        showGraph: false,
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                        showGraph: false,
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTime: false,
                    showGraph: false,
                  },
                  () => {
                    this.getMonthsList(e.target.value);
                    // this.getFirstAnyltics()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTime: false,
                    showGraph: false,
                  },
                  () => {
                    // this.getFirstAnyltics()
                    // this.getSummaryForGraph()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          {this.state.district.value == 0 ? (
            <div style={{ marginLeft: "10px" }}>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.selectedHalf}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      selectedHalf: e.target.value,
                      firstTime: false,
                      showGraph: false,
                    },
                    () => {
                      // this.getFirstAnyltics()
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                <MenuItem value="FIRST HALF">part-I</MenuItem>
                <MenuItem value="SECOND HALF">part- II</MenuItem>
              </Select>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() => {
              if (
                this.state.district.value &&
                this.state.selectedSub &&
                this.state.ChaluSelectedMonth &&
                this.state.ChaluSelectedYear
              ) {
                this.setState(
                  {
                    firstTime: false,
                  },
                  () => this.getComparisionForDistrict()
                );
              } else {
                this.setState({
                  blockModal: true,
                });
              }
            }}
          >
            search
          </Button>
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],

                ViewSummary: false,

                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,

                selectedSub: null,
                showKendraGraph: false,
              });
            }}
          >
            reset
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.showBlockComparision(this.state.compareBlockData)}
          {/* {this.state.showGraph ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: "",
              },
              xAxis: {
                categories: this.getCatergoriesForBlock(),
                crosshair: true,
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                stackLabels: {
                  enabled: true,
                  align: "center",
                },
                visible: true,
                title: {
                  enabled: true,
                  text: "percentage",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },

              labels: {
                items: [
                  {
                    html: "",
                    style: {
                      left: "50px",
                      top: "18px",
                    },
                  },
                ],
              },
              series: this.getStackedBarData(this.state.compareBlockData),
              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: true,
                  },
                  with: 15,
                  depth: 40,
                },
              },

              tooltip: {
                formatter: function() {
                  var currentPoint = this,
                    currentSeries = currentPoint.series,
                    chart = currentSeries.chart,
                    stackName = this.series.userOptions.stack,
                    stackValues = '';
                 
                    console.log(currentPoint.series.name,"hell",this.state.compareBlockData,"vvv",chart,"kkkk",stackName,"stackName")
                  chart.series.forEach(function(series) {
                    series.points.forEach(function(point) {
                      if (currentSeries.userOptions.stack === series.userOptions.stack && currentPoint.key === point.category) {
                        stackValues += series.name + ': ' + point.y + '<br/>'
                      }
                    })
                  });
            
                  return currentPoint.series.name
                }
              },

              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                width: (window.innerWidth - 260 - 120) / 1.05,
                type: "column",
                backgroundColor: "#fff",
                options3d: {
                  enabled: true,
                  alpha: 1,
                  beta: 15,
                  // viewDistance: 25,
                  depth: 100,
                },
              },
            }}
          ></HighchartsReact>
        ) : null} */}
        </div>
      </div>
    );
  };

  ViewschoolComparision = () => {
    let {
      district,
      block,
      school,
      state,
      block1,
      block2,
      block3,
      block4,
      block5,
      school1,
      school2,
      school3,
      school4,
      school5,
    } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Subject*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={this.state.selectedSub}
                  onChange={(e) => {
                    this.setState({
                      selectedSub: e.target.value,
                      showSchoolGraph: false,
                      subValidation: false,
                    });
                  }}
                >
                  {this.state.subjectList?.map((res) => {
                    return (
                      <MenuItem value={res.subject_id}>{res.subject}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.subValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                Medium*
              </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                        showSchoolGraph: false,
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                        showSchoolGraph: false,
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
        >
          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block1.value}
                    error={block1.helperText}
                    helperText={!block1.value && block1.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block1: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                        },
                        () => {
                          this.getSchools(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto1}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto1: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto1: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school1: value,
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school1.value}
                        error={school1.helperText}
                        helperText={!school1.value && school1.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block1.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school1, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block2.value}
                    error={block2.helperText}
                    helperText={!block2.value && block2.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block2: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                        },
                        () => {
                          this.getSchools(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto2}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto2: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto2: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school2: value,
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school2.value}
                        error={school2.helperText}
                        helperText={!school2.value && school2.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block2.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block3.value}
                    error={block3.helperText}
                    helperText={!block3.value && block3.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block3: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                        },
                        () => {
                          this.getSchools(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto3}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto3: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto3: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school3: value,
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school3.value}
                        error={school3.helperText}
                        helperText={!school3.value && school3.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block3.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block4.value}
                    error={block4.helperText}
                    helperText={!block4.value && block4.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block4: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                        },
                        () => {
                          this.getSchools(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto4}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto4: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto4: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school4: value,
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school4.value}
                        error={school4.helperText}
                        helperText={!school4.value && school4.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block4.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school4, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block5.value}
                    error={block5.helperText}
                    helperText={!block5.value && block5.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block5: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                        },
                        () => {
                          this.getSchools(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto5}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto5: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto5: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school5: value,
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school5.value}
                        error={school5.helperText}
                        helperText={!school5.value && school5.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block5.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school, "school")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTime: false,
                    showSchoolGraph: false,
                  },
                  () => {
                    this.getMonthsList(e.target.value);
                    // this.getFirstAnyltics()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTime: false,
                    showSchoolGraph: false,
                  },
                  () => {
                    // this.getFirstAnyltics()
                    // this.getSummaryForGraph()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          {this.state.district.value == 0 ? (
            <div style={{ marginLeft: "10px" }}>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.selectedHalf}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      selectedHalf: e.target.value,
                      firstTime: false,
                      showSchoolGraph: false,
                    },
                    () => {
                      // this.getFirstAnyltics()
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                <MenuItem value="FIRST HALF">part-I</MenuItem>
                <MenuItem value="SECOND HALF">part- II</MenuItem>
              </Select>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() => {
              if (
                this.state.district.value &&
                this.state.selectedSub &&
                this.state.ChaluSelectedMonth &&
                this.state.ChaluSelectedYear
              ) {
                this.setState(
                  {
                    firstTime: false,
                  },
                  () => this.getComparisionForSchool()
                );
              } else {
                if (!this.state.block.value) {
                  this.setState({ BlockValidation: true });
                }
                if (!this.state.selectedSub) {
                  this.setState({ subValidation: true });
                }
              }
            }}
          >
            search
          </Button>
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],

                ViewSummary: false,

                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,

                selectedSub: null,
                showKendraGraph: false,
                block1: {
                  value: null,
                  helperText: null,
                },
                block2: {
                  value: null,
                  helperText: null,
                },
                block3: {
                  value: null,
                  helperText: null,
                },
                block4: {
                  value: null,
                  helperText: null,
                },
                block5: {
                  value: null,
                  helperText: null,
                },
                school1: {
                  value: null,
                  helperText: null,
                },
                school2: {
                  value: null,
                  helperText: null,
                },
                school3: {
                  value: null,
                  helperText: null,
                },
                school4: {
                  value: null,
                  helperText: null,
                },
                school5: {
                  value: null,
                  helperText: null,
                },
              });
            }}
          >
            reset
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.showSchoolComparison(this.state.compareSchoolData)}

          {/* {this.state.showSchoolGraph ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: "",
              },
              xAxis: {
                categories: this.getCatergoriesScho0lCompare(),
                crosshair: true,
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                stackLabels: {
                  enabled: true,
                  align: "center",
                },
                visible: true,
                title: {
                  enabled: true,
                  text: "Count",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },

              labels: {
                items: [
                  {
                    html: "",
                    style: {
                      left: "50px",
                      top: "18px",
                    },
                  },
                ],
              },
              series: this.getStackedSchoolBarData(
                this.state.compareSchoolData
              ),
              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: true,
                  },
                  with: 15,
                  depth: 40,
                },
              },

              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                width: (window.innerWidth - 260 - 120) / 1.05,
                type: "column",
                backgroundColor: "#fff",
                options3d: {
                  enabled: true,
                  alpha: 1,
                  beta: 15,
                  // viewDistance: 25,
                  depth: 100,
                },
              },
            }}
          ></HighchartsReact>
        ) : null} */}
        </div>
      </div>
    );
  };

  getComparisionForDistrict = () => {
    let param = {
      student_district_id: this.state.district.value,
      year: this.state.ChaluSelectedYear,
      month: this.state.ChaluSelectedMonth,
      month_half: this.state.selectedHalf,
    };
    console.log(param, "subject", this.state.selectedSub);
    let param1 = {
      student_district_id: this.state.district.value,
      subject: this.state.selectedSub,
      month: this.state.ChaluSelectedMonth,
      year: this.state.ChaluSelectedYear,
      compare_region: "block",
      // student_class_id:51,
    };

    if (this.state.selectedStandard) {
      param1["student_class"] = this.state.selectedStandard;
    }
    if (this.state.mediumSelected) {
      param1["medium"] = this.state.mediumSelected;
    }

    if (this.state.district.value == 0) {
      param1["month_half"] = this.state.selectedHalf;
    }
    getAdministrativeService()
      .getSummaryForComapreBlockGraph(param1)
      .then((res) => {
        this.setState({
          // showGraph:true
        });
        console.log(res, "res");
        if (res) {
          this.setState({
            compareBlockData: res.data,
            showGraph: true,
          });
        }
      });
  };

  getComparisionForSchool = () => {
    let schoolArray = [];
    console.log(this.state.school1.school_id, "this.state.school1.value");
    if (this.state.school1.school_id) {
      schoolArray.push(this.state.school1.school_id);
      console.log(this.state.school1.school_id, "this.state.school1.value");
    }
    if (this.state.school2.school_id) {
      schoolArray.push(this.state.school2.school_id);
      console.log(this.state.school2.school_id, "this.state.school1.value");
    }
    if (this.state.school3.school_id) {
      schoolArray.push(this.state.school3.school_id);
      console.log(this.state.school3.school_id, "this.state.school1.value");
    }
    if (this.state.school4.school_id) {
      schoolArray.push(this.state.school4.school_id);
      console.log(this.state.school4.school_id, "this.state.school1.value");
    }
    if (this.state.school5.school_id) {
      schoolArray.push(this.state.school5.school_id);
      console.log(this.state.school5.school_id, "this.state.school1.value");
    }
    console.log(schoolArray, "schoolArray");
    if (schoolArray.length > 0) {
      let param = {
        student_district_id: this.state.district.value,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        month_half: this.state.selectedHalf,
      };
      console.log(param, "subject", this.state.selectedSub);
      let arraySchool = [];
      // this.state.school.map((res) => {
      //   arraySchool.push(res.school_id);
      // });
      let param1 = {
        student_district_id: this.state.district.value,
        student_block_id: this.state.block.value,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        schools_list: schoolArray,
        compare_region: "school",
        subject: this.state.selectedSub,
      };
      if (this.state.selectedStandard) {
        param1["student_class"] = this.state.selectedStandard;
      }
      if (this.state.mediumSelected) {
        param1["medium"] = this.state.mediumSelected;
      }
      if (this.state.district.value == 0) {
        param1["month_half"] = this.state.selectedHalf;
      }

      getAdministrativeService()
        .getSummaryForComapreSchoolGraph(param1)
        .then((res) => {
          this.setState({
            // showGraph:true
          });
          console.log(res, "res");
          if (res) {
            this.setState({
              compareSchoolData: res.data,
              showSchoolGraph: true,
            });
          }
        });
    }
  };
  getStackedBarData = (response) => {
    console.log(response, "res type", this.state.blocks);
    let level1 = [];
    let level2 = [];
    let level3 = [];
    let level4 = [];
    let level5 = [];
    let level6 = [];
    let level7 = [];
    let level8 = [];
    this.state.blocks.map((data) => {
      if (response) {
        response[data.block_name].map((res) => {
          console.log(
            "res",
            res,
            this.state.selectedSub,
            res.level_name === "प्रारंभिक"
          );
          if (this.state.selectedSub === 3) {
            if (res.level_name === "प्रारंभिक") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "अंक ओळख") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "संख्याज्ञान") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "बेरीज") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "वजाबाकी") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "गुणाकार") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "भागाकार") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            } else if (res.level_name === "अनुपलब्ध") {
              if (res.percentage != 0) {
                level8.push(res.percentage);
              } else {
                level8.push(null);
              }
            }
          } else {
            if (res.level_name === "प्रारंभिक") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "अक्षर") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "शब्द") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "वाक्य") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "परिच्छेद") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "गोष्ट") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "अनुपलब्ध") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            }
          }
        });
      }
    });

    console.log(
      level2,
      level1,
      level3,
      level4,
      level5,
      level6,
      level7,
      "level1"
    );
    let data = [];

    let param1 = {};
    let param2 = {};
    let param3 = {};
    let param4 = {};
    let param5 = {};
    let param6 = {};
    let param7 = {};
    let param8 = {};

    if (this.state.selectedSub === 3) {
      param1 = {
        name: "प्रारंभिक",
        data: level1,
      };
      param2 = {
        name: "अंक ओळख",
        data: level2,
      };
      param3 = {
        name: "संख्याज्ञान",
        data: level3,
      };
      param4 = {
        name: "बेरीज",
        data: level4,
      };
      param5 = {
        name: "वजाबाकी",
        data: level5,
      };
      param6 = {
        name: "गुणाकार",
        data: level6,
      };
      param7 = {
        name: "भागाकार",
        data: level7,
      };
      param8 = {
        name: "अनुपलब्ध",
        data: level8,
      };
    } else {
      param1 = {
        name: "प्रारंभिक",
        data: level1,
      };
      param2 = {
        name: "अक्षर",
        data: level2,
      };
      param3 = {
        name: "शब्द",
        data: level3,
      };
      param4 = {
        name: "वाक्य",
        data: level4,
      };
      param5 = {
        name: "परिच्छेद",
        data: level5,
      };
      param6 = {
        name: "गोष्ट",
        data: level6,
      };
      param7 = {
        name: "अनुपलब्ध",
        data: level7,
      };
    }

    if (this.state.selectedSub === 3) {
      data = [param1, param2, param3, param4, param5, param6, param7, param8];
    } else {
      data = [param1, param2, param3, param4, param5, param6, param7];
    }
    console.log(data, "data");
    return data;
  };

  getStackedSchoolBarData = (response) => {
    console.log(response, "res type", this.state.school);
    let level1 = [];
    let level2 = [];
    let level3 = [];
    let level4 = [];
    let level5 = [];
    let level6 = [];
    let level7 = [];
    let level8 = [];
    let dummarArr = [];
    let schoolArray = [];
    console.log(this.state.school1.school_id, "this.state.school1.value");
    if (this.state.school1.school_id) {
      dummarArr.push(this.state.school1.school_name);
      console.log(this.state.school1.school_name, "this.state.school1.value");
    }
    if (this.state.school2.school_id) {
      dummarArr.push(this.state.school2.school_name);
      console.log(this.state.school2.school_name, "this.state.school1.value");
    }
    if (this.state.school3.school_id) {
      dummarArr.push(this.state.school3.school_name);
      console.log(this.state.school3.school_name, "this.state.school1.value");
    }
    if (this.state.school4.school_id) {
      dummarArr.push(this.state.school4.school_name);
      console.log(this.state.school4.school_name, "this.state.school1.value");
    }
    if (this.state.school5.school_id) {
      dummarArr.push(this.state.school5.school_name);
      console.log(this.state.school5.school_name, "this.state.school1.value");
    }
    console.log(dummarArr, "dumm");
    dummarArr.map((data) => {
      if (response) {
        response[data].map((res) => {
          console.log(
            "res",
            res,
            this.state.selectedSub,
            res.level_name === "प्रारंभिक"
          );
          if (this.state.selectedSub === 3) {
            if (res.level_name === "प्रारंभिक") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "अंक ओळख") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "संख्याज्ञान") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "बेरीज") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "वजाबाकी") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "गुणाकार") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "भागाकार") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            } else if (res.level_name === "अनुपलब्ध") {
              if (res.percentage != 0) {
                level8.push(res.percentage);
              } else {
                level8.push(null);
              }
            }
          } else {
            if (res.level_name === "प्रारंभिक") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "अक्षर") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "शब्द") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "वाक्य") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "परिच्छेद") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "गोष्ट") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "अनुपलब्ध") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            }
          }
        });
      }
    });

    console.log(
      level2,
      level1,
      level3,
      level4,
      level5,
      level6,
      level7,
      "level1"
    );
    let data = [];

    let param1 = {};
    let param2 = {};
    let param3 = {};
    let param4 = {};
    let param5 = {};
    let param6 = {};
    let param7 = {};
    let param8 = {};

    if (this.state.selectedSub === 3) {
      param1 = {
        name: "प्रारंभिक",
        data: level1,
      };
      param2 = {
        name: "अंक ओळख",
        data: level2,
      };
      param3 = {
        name: "संख्याज्ञान",
        data: level3,
      };
      param4 = {
        name: "बेरीज",
        data: level4,
      };
      param5 = {
        name: "वजाबाकी",
        data: level5,
      };
      param6 = {
        name: "गुणाकार",
        data: level6,
      };
      param7 = {
        name: "भागाकार",
        data: level7,
      };
      param8 = {
        name: "अनुपलब्ध",
        data: level8,
      };
    } else {
      param1 = {
        name: "प्रारंभिक",
        data: level1,
      };
      param2 = {
        name: "अक्षर",
        data: level2,
      };
      param3 = {
        name: "शब्द",
        data: level3,
      };
      param4 = {
        name: "वाक्य",
        data: level4,
      };
      param5 = {
        name: "परिच्छेद",
        data: level5,
      };
      param6 = {
        name: "गोष्ट",
        data: level6,
      };
      param7 = {
        name: "अनुपलब्ध",
        data: level7,
      };
    }

    if (this.state.selectedSub === 3) {
      data = [param1, param2, param3, param4, param5, param6, param7, param8];
    } else {
      data = [param1, param2, param3, param4, param5, param6, param7];
    }
    console.log(data, "data");
    return data;
  };

  getCatergoriesScho0lCompare = () => {
    let dummarArr = [];
    if (this.state.school1.school_id) {
      dummarArr.push(this.state.school1.school_name);
      console.log(this.state.school1.school_name, "this.state.school1.value");
    }
    if (this.state.school2.school_id) {
      dummarArr.push(this.state.school2.school_name);
      console.log(this.state.school2.school_name, "this.state.school1.value");
    }
    if (this.state.school3.school_id) {
      dummarArr.push(this.state.school3.school_name);
      console.log(this.state.school3.school_name, "this.state.school1.value");
    }
    if (this.state.school4.school_id) {
      dummarArr.push(this.state.school4.school_name);
      console.log(this.state.school4.school_name, "this.state.school1.value");
    }
    if (this.state.school5.school_id) {
      dummarArr.push(this.state.school5.school_name);
      console.log(this.state.school5.school_name, "this.state.school1.value");
    }

    return dummarArr;
  };
  getCatergoriesForBlock = () => {
    let array = [];

    this.state.blocks.map((res) => {
      array.push(res.block_name);
    });
    return array;
  };

  getStackedBarDataKendra = (response) => {
    const district = JSON.parse(localStorage.getItem("AMdetails"))
    console.log(response, "res type", this.state.kendraBlockwise);
    let level1 = [];
    let level2 = [];
    let level3 = [];
    let level4 = [];
    let level5 = [];
    let level6 = [];
    let level7 = [];
    let level8 = [];
    let level9 = [];
    let level10 = [];
    let level11= [];
    let level12 = [];

    console.log(this.state.kendraBlockwise, "kendra wise block");

    if(this.state.schoolSelected)
    {
      console.log(Object.keys(this.state.compareKendraData),"compareKendraData")
    
      this.state.schoolListArray.map((data) => {
        console.log(response,"response45445")
        if (response) {
          response[data].map((res) => {
            console.log(
              response[data],"123456"
            );
          console.log(JSON.parse(localStorage.getItem("AMdetails")),"AM")
         
        console.log(district,"distrcit45")
  
        if(district.student_district_id === 1)
        {
          console.log("okkkkkkkk")
          if (this.state.selectedSub === 9) {
            if (res.level_name === "मुळाक्षरे") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "स्वरचिन्ह विरहीत शब्द") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "स्वरचिन्ह युक्त शब्द") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "साधे वाक्य") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "जोडाक्षर युक्त वाक्य") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "उतारा") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "समजपूर्वक वाचन") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            }
          } else if(this.state.selectedSub === 10) {
            if (res.level_name === "संख्याज्ञान अंकी") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "संख्याज्ञान अक्षरी") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "बेरीज बिनहातच्याची") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "बेरीज हातच्याची") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "बेरीज शाब्दिक") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "वजाबाकी बिनहातच्याची") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "गुणाकार अंकी") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            } else if (res.level_name === "गुणाकार शाब्दिक") {
              if (res.percentage != 0) {
                level8.push(res.percentage);
              } else {
                level8.push(null);
              }
            }else if (res.level_name === "भागाकार अंकी") {
              if (res.percentage != 0) {
                level9.push(res.percentage);
              } else {
                level9.push(null);
              }
            }else if (res.level_name === "भागाकार शाब्दिक") {
              if (res.percentage != 0) {
                level10.push(res.percentage);
              } else {
                level10.push(null);
              }
            }else if (res.level_name === "वजाबाकी हातच्याची") {
              if (res.percentage != 0) {
                level11.push(res.percentage);
              } else {
                level11.push(null);
              }
            }else if (res.level_name === "वजाबाकी शाब्दिक") {
              if (res.percentage != 0) {
                level12.push(res.percentage);
              } else {
                level12.push(null);
              }
            }
          }
          else if(this.state.selectedSub === 11) {
            if (res.level_name === "मुळाक्षरे") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "स्वरचिन्ह विरहीत शब्द") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "स्वरचिन्ह युक्त शब्द") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "साधे वाक्य") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "जोडाक्षर युक्त वाक्य") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } 
           
          }
  
        }
        else{
  
            if (this.state.selectedSub === 3) {
              if (res.level_name === "प्रारंभिक") {
                if (res.percentage != 0) {
                  level1.push(res.percentage);
                } else {
                  level1.push(null);
                }
              } else if (res.level_name === "अंक ओळख") {
                if (res.percentage != 0) {
                  level2.push(res.percentage);
                } else {
                  level2.push(null);
                }
              } else if (res.level_name === "संख्याज्ञान") {
                if (res.percentage != 0) {
                  level3.push(res.percentage);
                } else {
                  level3.push(null);
                }
              } else if (res.level_name === "बेरीज") {
                if (res.percentage != 0) {
                  level4.push(res.percentage);
                } else {
                  level4.push(null);
                }
              } else if (res.level_name === "वजाबाकी") {
                if (res.percentage != 0) {
                  level5.push(res.percentage);
                } else {
                  level5.push(null);
                }
              } else if (res.level_name === "गुणाकार") {
                if (res.percentage != 0) {
                  level6.push(res.percentage);
                } else {
                  level6.push(null);
                }
              } else if (res.level_name === "भागाकार") {
                if (res.percentage != 0) {
                  level7.push(res.percentage);
                } else {
                  level7.push(null);
                }
              } else if (res.level_name === "अनुपलब्ध") {
                if (res.percentage != 0) {
                  level8.push(res.percentage);
                } else {
                  level8.push(null);
                }
              }
            } else {
              if (res.level_name === "प्रारंभिक") {
                if (res.percentage != 0) {
                  level1.push(res.percentage);
                } else {
                  level1.push(null);
                }
              } else if (res.level_name === "अक्षर") {
                if (res.percentage != 0) {
                  level2.push(res.percentage);
                } else {
                  level2.push(null);
                }
              } else if (res.level_name === "शब्द") {
                if (res.percentage != 0) {
                  level3.push(res.percentage);
                } else {
                  level3.push(null);
                }
              } else if (res.level_name === "वाक्य") {
                if (res.percentage != 0) {
                  level4.push(res.percentage);
                } else {
                  level4.push(null);
                }
              } else if (res.level_name === "परिच्छेद") {
                if (res.percentage != 0) {
                  level5.push(res.percentage);
                } else {
                  level5.push(null);
                }
              } else if (res.level_name === "गोष्ट") {
                if (res.percentage != 0) {
                  level6.push(res.percentage);
                } else {
                  level6.push(null);
                }
              } else if (res.level_name === "अनुपलब्ध") {
                if (res.percentage != 0) {
                  level7.push(res.percentage);
                } else {
                  level7.push(null);
                }
              }
            }
          }
          });
        }
      });
    }
    else{
    this.state.kendraBlockwise.map((data) => {
      if (response) {
        response[data.kendra_name].map((res) => {
          console.log(
            "res print",
            res,
            this.state.selectedSub,
            res.level_name === "प्रारंभिक"
          );
        console.log(JSON.parse(localStorage.getItem("AMdetails")),"AM")
       
      console.log(district,"distrcit45")

      if(district.student_district_id === 1)
      {
        if (this.state.selectedSub === 9) {
          if (res.level_name === "मुळाक्षरे") {
            if (res.percentage != 0) {
              level1.push(res.percentage);
            } else {
              level1.push(null);
            }
          } else if (res.level_name === "स्वरचिन्ह विरहीत शब्द") {
            if (res.percentage != 0) {
              level2.push(res.percentage);
            } else {
              level2.push(null);
            }
          } else if (res.level_name === "स्वरचिन्ह युक्त शब्द") {
            if (res.percentage != 0) {
              level3.push(res.percentage);
            } else {
              level3.push(null);
            }
          } else if (res.level_name === "साधे वाक्य") {
            if (res.percentage != 0) {
              level4.push(res.percentage);
            } else {
              level4.push(null);
            }
          } else if (res.level_name === "जोडाक्षर युक्त वाक्य") {
            if (res.percentage != 0) {
              level5.push(res.percentage);
            } else {
              level5.push(null);
            }
          } else if (res.level_name === "उतारा") {
            if (res.percentage != 0) {
              level6.push(res.percentage);
            } else {
              level6.push(null);
            }
          } else if (res.level_name === "समजपूर्वक वाचन") {
            if (res.percentage != 0) {
              level7.push(res.percentage);
            } else {
              level7.push(null);
            }
          }
        } else if(this.state.selectedSub === 10) {
          if (res.level_name === "संख्याज्ञान अंकी") {
            if (res.percentage != 0) {
              level1.push(res.percentage);
            } else {
              level1.push(null);
            }
          } else if (res.level_name === "संख्याज्ञान अक्षरी") {
            if (res.percentage != 0) {
              level2.push(res.percentage);
            } else {
              level2.push(null);
            }
          } else if (res.level_name === "बेरीज बिनहातच्याची") {
            if (res.percentage != 0) {
              level3.push(res.percentage);
            } else {
              level3.push(null);
            }
          } else if (res.level_name === "बेरीज हातच्याची") {
            if (res.percentage != 0) {
              level4.push(res.percentage);
            } else {
              level4.push(null);
            }
          } else if (res.level_name === "बेरीज शाब्दिक") {
            if (res.percentage != 0) {
              level5.push(res.percentage);
            } else {
              level5.push(null);
            }
          } else if (res.level_name === "वजाबाकी बिनहातच्याची") {
            if (res.percentage != 0) {
              level6.push(res.percentage);
            } else {
              level6.push(null);
            }
          } else if (res.level_name === "गुणाकार अंकी") {
            if (res.percentage != 0) {
              level7.push(res.percentage);
            } else {
              level7.push(null);
            }
          } else if (res.level_name === "गुणाकार शाब्दिक") {
            if (res.percentage != 0) {
              level8.push(res.percentage);
            } else {
              level8.push(null);
            }
          }else if (res.level_name === "भागाकार अंकी") {
            if (res.percentage != 0) {
              level9.push(res.percentage);
            } else {
              level9.push(null);
            }
          }else if (res.level_name === "भागाकार शाब्दिक") {
            if (res.percentage != 0) {
              level10.push(res.percentage);
            } else {
              level10.push(null);
            }
          }else if (res.level_name === "वजाबाकी हातच्याची") {
            if (res.percentage != 0) {
              level11.push(res.percentage);
            } else {
              level11.push(null);
            }
          }else if (res.level_name === "वजाबाकी शाब्दिक") {
            if (res.percentage != 0) {
              level12.push(res.percentage);
            } else {
              level12.push(null);
            }
          }
        }
        else if(this.state.selectedSub === 11) {
          if (res.level_name === "मुळाक्षरे") {
            if (res.percentage != 0) {
              level1.push(res.percentage);
            } else {
              level1.push(null);
            }
          } else if (res.level_name === "स्वरचिन्ह विरहीत शब्द") {
            if (res.percentage != 0) {
              level2.push(res.percentage);
            } else {
              level2.push(null);
            }
          } else if (res.level_name === "स्वरचिन्ह युक्त शब्द") {
            if (res.percentage != 0) {
              level3.push(res.percentage);
            } else {
              level3.push(null);
            }
          } else if (res.level_name === "साधे वाक्य") {
            if (res.percentage != 0) {
              level4.push(res.percentage);
            } else {
              level4.push(null);
            }
          } else if (res.level_name === "जोडाक्षर युक्त वाक्य") {
            if (res.percentage != 0) {
              level5.push(res.percentage);
            } else {
              level5.push(null);
            }
          } 
         
        }

      }
      else{

          if (this.state.selectedSub === 3) {
            if (res.level_name === "प्रारंभिक") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "अंक ओळख") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "संख्याज्ञान") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "बेरीज") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "वजाबाकी") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "गुणाकार") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "भागाकार") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            } else if (res.level_name === "अनुपलब्ध") {
              if (res.percentage != 0) {
                level8.push(res.percentage);
              } else {
                level8.push(null);
              }
            }
          } else {
            if (res.level_name === "प्रारंभिक") {
              if (res.percentage != 0) {
                level1.push(res.percentage);
              } else {
                level1.push(null);
              }
            } else if (res.level_name === "अक्षर") {
              if (res.percentage != 0) {
                level2.push(res.percentage);
              } else {
                level2.push(null);
              }
            } else if (res.level_name === "शब्द") {
              if (res.percentage != 0) {
                level3.push(res.percentage);
              } else {
                level3.push(null);
              }
            } else if (res.level_name === "वाक्य") {
              if (res.percentage != 0) {
                level4.push(res.percentage);
              } else {
                level4.push(null);
              }
            } else if (res.level_name === "परिच्छेद") {
              if (res.percentage != 0) {
                level5.push(res.percentage);
              } else {
                level5.push(null);
              }
            } else if (res.level_name === "गोष्ट") {
              if (res.percentage != 0) {
                level6.push(res.percentage);
              } else {
                level6.push(null);
              }
            } else if (res.level_name === "अनुपलब्ध") {
              if (res.percentage != 0) {
                level7.push(res.percentage);
              } else {
                level7.push(null);
              }
            }
          }
        }
        });
      }
    });
  }

    console.log(
      level2,
      level1,
      level3,
      level4,
      level5,
      level6,
      level7,
      "level1"
    );
    let data = [];

    let param1 = {};
    let param2 = {};
    let param3 = {};
    let param4 = {};
    let param5 = {};
    let param6 = {};
    let param7 = {};
    let param8 = {};
    let param9 = {};
    let param10 = {};
    let param11 = {};
    let param12 = {};

     if(district.student_district_id === 1)
     {

      if (this.state.selectedSub === 9) {
      param1 = {
        name: "मुळाक्षरे",
        data: level1,
      };
      param2 = {
        name: "स्वरचिन्ह विरहीत शब्द",
        data: level2,
      };
      param3 = {
        name: "स्वरचिन्ह युक्त शब्द",
        data: level3,
      };
      param4 = {
        name: "साधे वाक्य",
        data: level4,
      };
      param5 = {
        name: "जोडाक्षर युक्त वाक्य",
        data: level5,
      };
      param6 = {
        name: "उतारा",
        data: level6,
      };
      param7 = {
        name: "समजपूर्वक वाचन",
        data: level7,
      };
    } else if(this.state.selectedSub === 10) {
      param1 = {
        name: "संख्याज्ञान अंकी",
        data: level1,
      };
      param2 = {
        name: "संख्याज्ञान अक्षरी",
        data: level2,
      };
      param3 = {
        name: "बेरीज बिनहातच्याची",
        data: level3,
      };
      param4 = {
        name: "बेरीज हातच्याची",
        data: level4,
      };
      param5 = {
        name: "बेरीज शाब्दिक",
        data: level5,
      };
      param6 = {
        name: "वजाबाकी बिनहातच्याची",
        data: level6,
      };
      param7 = {
        name: "गुणाकार अंकी",
        data: level7,
      };
      param8 = {
        name: "गुणाकार शाब्दिक",
        data: level8,
      };
      param9 = {
        name: "भागाकार अंकी",
        data: level9,
      };
      param10 = {
        name: "भागाकार शाब्दिक",
        data: level10,
      };
      param11 = {
        name: "वजाबाकी हातच्याची",
        data: level11,
      };
      param12 = {
        name: "वजाबाकी शाब्दिक",
        data: level12,
      };
    }  else if(this.state.selectedSub === 11) {
      param1 = {
        name: "मुळाक्षरे",
        data: level1,
      };
      param2 = {
        name: "स्वरचिन्ह विरहीत शब्द",
        data: level2,
      };
      param3 = {
        name: "स्वरचिन्ह युक्त शब्द",
        data: level3,
      };
      param4 = {
        name: "साधे वाक्य",
        data: level4,
      };
      param5 = {
        name: "जोडाक्षर युक्त वाक्य",
        data: level5,
      };
      
    }

     }
     else{

     
    if (this.state.selectedSub === 3) {
      param1 = {
        name: "प्रारंभिक",
        data: level1,
      };
      param2 = {
        name: "अंक ओळख",
        data: level2,
      };
      param3 = {
        name: "संख्याज्ञान",
        data: level3,
      };
      param4 = {
        name: "बेरीज",
        data: level4,
      };
      param5 = {
        name: "वजाबाकी",
        data: level5,
      };
      param6 = {
        name: "गुणाकार",
        data: level6,
      };
      param7 = {
        name: "भागाकार",
        data: level7,
      };
      param8 = {
        name: "अनुपलब्ध",
        data: level8,
      };
    } else {
      param1 = {
        name: "प्रारंभिक",
        data: level1,
      };
      param2 = {
        name: "अक्षर",
        data: level2,
      };
      param3 = {
        name: "शब्द",
        data: level3,
      };
      param4 = {
        name: "वाक्य",
        data: level4,
      };
      param5 = {
        name: "परिच्छेद",
        data: level5,
      };
      param6 = {
        name: "गोष्ट",
        data: level6,
      };
      param7 = {
        name: "अनुपलब्ध",
        data: level7,
      };
    }
  }

  if(district.student_district_id === 1)
  {
    if (this.state.selectedSub === 9)
    {
      data = [param1, param2, param3, param4, param5, param6, param7];
    } else  if (this.state.selectedSub === 10)
    {
      data = [param1, param2, param3, param4, param5, param6, param7, param8,param9,param10,param11,param12];
    } else  if (this.state.selectedSub === 11){
      data = [param1, param2, param3, param4, param5];
    }

  }
  else{

  
    if (this.state.selectedSub === 3) {
      data = [param1, param2, param3, param4, param5, param6, param7, param8];
    } else {
      data = [param1, param2, param3, param4, param5, param6, param7];
    }
  }
    console.log(data, "data");
    return data;
  };

  getCatergoriesForKendra = () => {
    let array = [];

    this.state.schoolListArray.map((res) => {
      array.push(res);
    });
    return array;
  };

  getComparisionForKendra = () => {
    let param = {
      student_district_id: this.state.district.value,
      student_block_id: this.state.block.value,
      subject: this.state.selectedSub,
      month: this.state.ChaluSelectedMonth,
      year: this.state.ChaluSelectedYear,
      compare_region: "kendra",
      //  kendra_id:52
    };
    if (this.state.selectedStandard) {
      param["student_class_id"] = this.state.selectedStandard;
    }
    if (this.state.selectedKendra) {
      param["kendra_id"] = this.state.selectedKendra;
    }
    if (this.state.mediumSelected) {
      param["medium"] = this.state.mediumSelected;
    }
    if (this.state.district.value == 0) {
      param["month_half"] = this.state.selectedHalf;
    }

    getAdministrativeService()
      .getSummaryForComapreKendraGraph(param)
      .then((res) => {
        if (res) {
          this.setState({
            compareKendraData: res.data,
            showKendraGraph: true,
            schoolListArray:Object.keys(res.data)
          });
        }
      });
  };

  getKendra = async (id) => {
    let data = await getSuperAdminService().getAllKendra({
      block_id: id,
    });

    if (data.data.status == 200) {
      this.setState({
        kendraBlockwise: data.data.response,
      });
    }
  };

  ViewKendraComparision = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },
                        showKendraGraph: false,
                        BlockValidation: false,
                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value);
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.BlockValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>


          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Kendra">
                  Kendra
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Kendra"
                  value={this.state.selectedKendra}
                  onChange={(e) => {
                    this.setState({
                      selectedKendra: e.target.value,
                      showKendraGraph: false,
                      subValidation: false,
                    });
                  }}
                >
                  {this.state.kendraBlockwise?.map((res) => {
                    return (
                      <MenuItem value={res.kendra_id}>{res.kendra_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
           
          </div>


          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Subject*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={this.state.selectedSub}
                  onChange={(e) => {
                    this.setState({
                      selectedSub: e.target.value,
                      showKendraGraph: false,
                      subValidation: false,
                    });
                  }}
                >
                  {this.state.subjectList?.map((res) => {
                    return (
                      <MenuItem value={res.subject_id}>{res.subject}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.subValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                Medium*
              </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                        showKendraGraph: false,
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                        showKendraGraph: false,
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTime: false,
                    showKendraGraph: false,
                  },
                  () => {
                    this.getMonthsList(e.target.value);
                    // this.getFirstAnyltics()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTime: false,
                    showKendraGraph: false,
                  },
                  () => {
                    // this.getFirstAnyltics()
                    // this.getSummaryForGraph()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          {this.state.district.value == 0 ? (
            <div style={{ marginLeft: "10px" }}>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.selectedHalf}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      selectedHalf: e.target.value,
                      firstTime: false,
                      showKendraGraph: false,
                    },
                    () => {
                      // this.getFirstAnyltics()
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                <MenuItem value="FIRST HALF">part-I</MenuItem>
                <MenuItem value="SECOND HALF">part- II</MenuItem>
              </Select>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() => {
              if (
                this.state.district.value &&
                this.state.selectedSub &&
                this.state.ChaluSelectedMonth &&
                this.state.ChaluSelectedYear
              ) {
                this.setState(
                  {
                    firstTime: false,
                  },
                  () => this.getComparisionForKendra()
                );
              } else {
                if (!this.state.block.value) {
                  this.setState({ BlockValidation: true });
                }
                if (!this.state.selectedSub) {
                  this.setState({ subValidation: true });
                }
              }
            }}
          >
            search
          </Button>
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],

                ViewSummary: false,

                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,

                selectedSub: null,
                showKendraGraph: false,
                selectedKendra:null
              });
            }}
          >
            reset
          </Button>
        </div>

        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.showKendraComparision(this.state.compareKendraData)}
            {/* {this.state.showKendraGraph ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: "",
              },
              xAxis: {
                categories: this.getCatergoriesForKendra(),
                crosshair: true,
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                stackLabels: {
                  enabled: true,
                  align: "center",
                },
                visible: true,
                title: {
                  enabled: true,
                  text: "Count",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },

              labels: {
                items: [
                  {
                    html: "",
                    style: {
                      left: "50px",
                      top: "18px",
                    },
                  },
                ],
              },
              series: this.getStackedBarDataKendra(this.state.compareKendraData),
              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: true,
                  },
                  with: 15,
                  depth: 40,
                },
              },

              // tooltip: {
              //   formatter: function () {
              //     var result = data.find((item) => item.level === this.x);

              //     let pcnt = result?.percentage;

              //     return Highcharts.numberFormat(pcnt) + "%";
              //   },
              // },

              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                width: (window.innerWidth - 260 - 120) / 1.05,
                type: "column",
                backgroundColor: "#fff",
                options3d: {
                  enabled: true,
                  alpha: 1,
                  beta: 15,
                  // viewDistance: 25,
                  depth: 100,
                },
              },
            }}
          ></HighchartsReact>
        ) : null} */}
          </div>
        </div>
      </div>
    );
  };

  showTrendLineGraph = (data) => {
    console.log(data, "arrayForCount");
    return this.state.arrayForLine?.map((res, index) => {
      let array = [];
      let array1 = [];
      console.log(res, "res");
      res.map((resp) => {
        if (typeof resp !== "object") {
          array.push(resp);
        } else {
          array.push(Object.values(resp));
        }

        console.log(resp, "resp", array);
      });
      console.log("resp", array);

      Object.values(array).map((res) => {
        console.log(typeof res, "res aray");
        if (typeof res == "object") {
          const iterator = res.values();

          for (const value of iterator) {
            console.log(value);
            array1.push(value);
          }
        }
      });
      console.log(Object.values(array), array1);

      return (
        <div
          class="col"
          style={{
            padding: "5px",
            marginTop: "25px",
            marginBottom: "40px",
          }}
        >
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <div
              style={{
                marginLeft: "48%",
                padding: "10px",
                backgroundColor: "#2BBC6E",
                borderRadius: "10px",
              }}
            >
              स्तर : {this.getLevels(index)}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                title: {
                  text: "",
                },
                xAxis: {
                  categories: this.getCatogeriesForSpline(),
                  crosshair: true,
                },
                yAxis: {
                  stackLabels: {
                    enabled: true,
                    align: "center",
                  },
                  visible: true,
                  title: {
                    enabled: true,
                    text: "percentage",
                  },
                },
                tooltip: {
                  formatter: function () {
                    var currentPoint = this,
                      currentSeries = currentPoint.series,
                      chart = currentSeries.chart,
                      stackName = this.series.userOptions.stack,
                      stackValues = "";
                    let array1 = [];
                    const entriesForCount = Object.entries(data);
                    entriesForCount.map((res) => {
                      res.map((resp) => {
                        console.log(resp, "4545resp");
                        if (typeof resp !== "object") {
                          array1.push(resp);
                        }
                      });
                    });
                    let stage = array1[index];
                    console.log(array1[index], "index11", data[stage], stage);
                    let xValue = this.x;
                    xValue = xValue.replace("_percentage", "");
                    let count = data[stage];
                    let val = "2023_february_first_half";

                    console.log(count[xValue], "count3434", xValue);
                    return "Count:" + count[xValue];
                  },
                },
                labels: {
                  items: [
                    {
                      html: "",
                      style: {
                        left: "50px",
                        top: "18px",
                      },
                    },
                  ],
                },
                series: [
                  {
                    name: "percentage",
                    data: array1,
                  },
                ],
                chart: {
                  plotBackgroundColor: null,
                  plotBorderWidth: null,
                  plotShadow: false,
                  width: (window.innerWidth - 260 - 120) / 1.05,
                  type: "spline",
                },
              }}
            ></HighchartsReact>
          </div>
        </div>
      );
    });
  };

  showBlockComparision = (response) => {
    console.log(response, "resp1212");
    return this.state.showGraph ? (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: "",
          },
          xAxis: {
            categories: this.getCatergoriesForBlock(),
            crosshair: true,
            labels: {
              skew3d: true,
              style: {
                fontSize: "16px",
              },
            },
          },
          yAxis: {
            allowDecimals: false,
            min: 0,
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text: "percentage",
            },
            labels: {
              skew3d: true,
              style: {
                fontSize: "16px",
              },
            },
          },

          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          series: this.getStackedBarData(this.state.compareBlockData),
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
              with: 15,
              depth: 40,
            },
          },

          tooltip: {
            formatter: function () {
              var currentPoint = this,
                currentSeries = currentPoint.series,
                chart = currentSeries.chart,
                stackName = this.series.userOptions.stack,
                stackValues = "";

              console.log(
                currentPoint,
                "hell",
                response[this.x],
                "vvv",
                chart,
                "kkkk",
                stackName,
                "stackName"
              );
              let val = this.x;
              var result = response[this.x].find(
                (item) => item.level_name === currentPoint.series.name
              );

              console.log(result, "result12");
              let data = result.counts;
              return "Count:" + data;
            },
          },

          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "column",
            backgroundColor: "#fff",
            options3d: {
              enabled: true,
              alpha: 1,
              beta: 15,
              // viewDistance: 25,
              depth: 100,
            },
          },
        }}
      ></HighchartsReact>
    ) : null;
  };

  showKendraComparision = (datas) => {
    console.log(datas, "datas");

    return this.state.showKendraGraph ? (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: "",
          },
          xAxis: {
            categories: this.getCatergoriesForKendra(),
            crosshair: true,
            labels: {
              skew3d: true,
              style: {
                fontSize: "16px",
              },
            },
          },
          yAxis: {
            allowDecimals: false,
            min: 0,
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text: "Count",
            },
            labels: {
              skew3d: true,
              style: {
                fontSize: "16px",
              },
            },
          },

          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          series: this.getStackedBarDataKendra(this.state.compareKendraData),
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
              with: 15,
              depth: 40,
            },
          },

          tooltip: {
            formatter: function () {
              var currentPoint = this,
                currentSeries = currentPoint.series,
                chart = currentSeries.chart,
                stackName = this.series.userOptions.stack,
                stackValues = "";

              console.log(
                currentPoint,
                "hell",
                datas[this.x],
                "vvv",
                chart,
                "kkkk",
                stackName,
                "stackName"
              );
              let val = this.x;
              var result = datas[this.x].find(
                (item) => item.level_name === currentPoint.series.name
              );

              console.log(result, "result12");
              let data = result.counts;
              return "Count:" + data;
            },
          },

          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "column",
            backgroundColor: "#fff",
            options3d: {
              enabled: true,
              alpha: 1,
              beta: 15,
              // viewDistance: 25,
              depth: 100,
            },
          },
        }}
      ></HighchartsReact>
    ) : null;
  };

  showSchoolComparison = (response) => {
    console.log(response, "response");

    return this.state.showSchoolGraph ? (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: "",
          },
          xAxis: {
            categories: this.getCatergoriesScho0lCompare(),
            crosshair: true,
            labels: {
              skew3d: true,
              style: {
                fontSize: "16px",
              },
            },
          },
          yAxis: {
            allowDecimals: false,
            min: 0,
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text: "Count",
            },
            labels: {
              skew3d: true,
              style: {
                fontSize: "16px",
              },
            },
          },

          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          tooltip: {
            formatter: function () {
              var currentPoint = this,
                currentSeries = currentPoint.series,
                chart = currentSeries.chart,
                stackName = this.series.userOptions.stack,
                stackValues = "";

              console.log(
                currentPoint,
                "hell",
                response[this.x],
                "vvv",
                chart,
                "kkkk",
                stackName,
                "stackName"
              );
              let val = this.x;
              var result = response[this.x].find(
                (item) => item.level_name === currentPoint.series.name
              );

              console.log(result, "result12");
              let data = result.counts;
              return "Count:" + data;
            },
          },
          series: this.getStackedSchoolBarData(this.state.compareSchoolData),
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
              with: 15,
              depth: 40,
            },
          },

          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "column",
            backgroundColor: "#fff",
            options3d: {
              enabled: true,
              alpha: 1,
              beta: 15,
              // viewDistance: 25,
              depth: 100,
            },
          },
        }}
      ></HighchartsReact>
    ) : null;
  };
  render() {
    let { district, block, school, state, block1, block2, block3 } = this.state;

    return (
      <>
        <div
          style={{ display: "flex", textAlign: "center", flexDirection: "row" }}
        >
          <div
            style={{
              backgroundColor: this.state.ViewSummary ? "#6872DD" : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                ViewTrend: false,
                ViewSummary: true,
                districtComparision: false,
                schoolComparision: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                levelComparsionArr: [],
                showSchoolGraph: false,
                showGraph: false,
                compareKendra: false,
                showKendraGraph: false,
              });
            }}
          >
            View Summary Graph
          </div>

          <div
            style={{
              marginLeft: "20px",
              backgroundColor: this.state.ViewTrend ? "#6872DD" : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],
                ViewTrend: true,
                ViewSummary: false,
                districtComparision: false,
                schoolComparision: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,
                compareKendra: false,
                showKendraGraph: false,
              });
            }}
          >
            View Trend Line
          </div>
          <div
            style={{
              marginLeft: "20px",
              backgroundColor: this.state.districtComparision
                ? "#6872DD"
                : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],
                districtComparision: true,
                ViewSummary: false,
                ViewTrend: false,
                schoolComparision: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,
                compareKendra: false,
                selectedSub: null,
                showKendraGraph: false,
              });
            }}
          >
            Compare Blocks
          </div>
          <div
            style={{
              marginLeft: "20px",
              backgroundColor: this.state.compareKendra ? "#6872DD" : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],
                districtComparision: false,
                ViewSummary: false,
                ViewTrend: false,
                schoolComparision: false,
                compareKendra: true,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,
                selectedSub: null,
                showKendraGraph: false,
                BlockValidation: false,
                subValidation: false,
              });
            }}
          >
            Compare Kendra
          </div>
          <div
            style={{
              marginLeft: "20px",
              backgroundColor: this.state.schoolComparision
                ? "#6872DD"
                : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],
                districtComparision: false,
                ViewSummary: false,
                ViewTrend: false,
                schoolComparision: true,
                compareKendra: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,
                selectedSub: null,
                showKendraGraph: false,
              });
            }}
          >
            Compare Schools
          </div>
        </div>
        {this.state.ViewTrend
          ? this.ViewTrendLine()
          : this.state.schoolComparision
          ? this.ViewschoolComparision()
          : this.state.districtComparision
          ? this.ViewDistrictComparision()
          : this.state.compareKendra
          ? this.ViewKendraComparision()
          : this.getSummaryGraph()}
      </>
    );
  }
}
