import React from "react";
import TextField from "@material-ui/core/TextField";
import logo from "./../../../assets/img/vopa_logo.png";
import classes from "./form.module.css";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import * as constants from "./../../../common/constants";
import { getSuperAdminService } from "./../../../service/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "./../../../components/Snackbar/Snackbar";
import Alert from "./../../../components/Alert/Alert";
const dropdownStyles = {
  dropdown: { width: 300 } ,
};
const stackTokens = { childrenGap: 20 };
const OPTIONS = [
  { key: "1", text: "Super Admin" },
  { key: "2", text: "Content Writer" },
];

class Form extends React.Component {
  state = {
    userType: constants.USER_TYPE.SUPER_ADMIN,
    mobileNumber: "",
    message: "",
    isError: false,
    isLoading: false,
    validations: {
      mobile: {
        valid: false,
        touched: false,
      },
    },
  };

  componentDidMount() {
    sessionStorage.clear();
  }

  async onSubmit() {
    this.setState({ isLoading: true });
    const payload = {
      mobile: this.state.mobileNumber,
      user_type: this.state.userType,
    };

    try {
      let result = await getSuperAdminService().loginUser(payload);
      const { status, response } = result.data;
      this.setState({ isLoading: false });
      if (status === 200) {
        if (!response.is_existed) {
          return this.setState({
            isError: true,
            message: "No user with provided number found",
          });
        }
        this.props.history.push("/verify", {
          mobile: response.mobile,
          otp: response.otp,
          user_type: this.state.userType,
        });
      } else {
        this.setState({
          isError: true,
          message: "No user with provided number found",
        });
      }
    } catch (error) {
      this.setState({
        isError: true,
        isLoading: false,
        message: "Something has went wrong",
      });
    }
  }

  async onWhatsappSubmit() {
    this.setState({ isLoading: true });
    const payload = {
      mobile: this.state.mobileNumber,
      user_type: this.state.userType,
      is_whats_up : true
    };
      console.log(payload,"param")
    try {
      let result = await getSuperAdminService().loginUser(payload);
      const { status, response } = result.data;
      this.setState({ isLoading: false });
      if (status === 200) {
        if (!response.is_existed) {
          return this.setState({
            isError: true,
            message: "No user with provided number found",
          });
        }
        this.props.history.push("/verify", {
          mobile: response.mobile,
          otp: response.otp,
          user_type: this.state.userType,
        });
      } else {
        this.setState({
          isError: true,
          message: "No user with provided number found",
        });
      }
    } catch (error) {
      this.setState({
        isError: true,
        isLoading: false,
        message: "Something has went wrong",
      });
    }
  }

  handleMobileNoClick = (e) => {
    let updatedValidations = { ...this.state.validations };
    updatedValidations.mobile.touched = true;
    this.setState({
      validations: updatedValidations,
    });
  };

  handleMobileNoChange = (e) => {
    let updatedValidations = { ...this.state.validations };
    updatedValidations.mobile.touched = true;
    updatedValidations.mobile.valid =
      !isNaN(e.target.value) && e.target.value.length === 10 ? true : false;
    this.setState({
      mobileNumber: e.target.value,
      validations: updatedValidations,
    });
  };

  render() {
    const {
      isError,
      message,
      isLoading,
      validations,
      sliderImages,
    } = this.state;
    return (
      <div className={classes.container}>
        <Snackbar
          autoHideDuration={3000}
          message={message}
          color={isError ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() => this.setState({ message: "" })}
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} />
          <span className={classes.logoText}>V - School</span>
          <span className={classes.logoTagLine}>
            Ensuring right to education
          </span>
        </div>
        <div className={classes.form}>
          {sessionStorage.getItem("message") ? (
            <Alert
              message="Session Expired. Please Login"
              type="danger"
            ></Alert>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className={classes.label}>Mobile number</label>
            <TextField
            type="number"
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter mobile number"
              className={classes.textInput}
              error={validations.mobile.touched && !validations.mobile.valid}
              onChange={(e) => this.handleMobileNoChange(e)}
              onClick={(e) => this.handleMobileNoClick(e)}
              helperText={
                validations.mobile.touched && !validations.mobile.valid
                  ? "Please enter valid mobile number"
                  : null
              }
            />
          </div>
          <FormControl className={classes.formControl}>
            <label className={classes.label}>Login as</label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.userType}
              onChange={(e) => {
                this.setState({ userType: e.target.value });
              }}
              className={classes.dropdown}
              label="Select role"
            >
              <MenuItem key={1} value={constants.USER_TYPE.SUPER_ADMIN}>
                {"SUPER ADMIN"}
              </MenuItem>
              {/* <MenuItem key={2} value={constants.USER_TYPE.HEADMASTER}>
                {"HEADMASTER"}
              </MenuItem> */}
              <MenuItem key={3} value={constants.USER_TYPE.ADMIN}>
                {"ADMIN"}
              </MenuItem>
              <MenuItem key={4} value={constants.USER_TYPE.CONTENT_WRITER}>
                {"CONTENT-WRITER"}
              </MenuItem>
              {/* <MenuItem key={5} value={constants.USER_TYPE.ADMINISTRATIVE}>
                {"ADMINISTRATIVE"}
              </MenuItem> */}
            </Select>
          </FormControl>
          <div className={classes.btContainer}>
            {isLoading && <CircularProgress color="primary" />}
            {!isLoading && (
              <>
              <Button
                className={classes.signBtn}
                onClick={this.onSubmit.bind(this)}
                variant="contained"
                color="primary"
                style={{ color:'#000'}}
                disabled={!this.state.validations.mobile.valid}
              >
                Generate SMS OTP
              </Button>
              <Button
              className={classes.signBtn1}
              onClick={this.onWhatsappSubmit.bind(this)}
              variant="contained"
               color="primary"
              style={{ marginLeft:'10px',color:'#000',backgroundColor:'red'}}
              disabled={!this.state.validations.mobile.valid}
            >
             Generate Whatsapp OTP
            </Button>
            </>
            )}
            
          </div>
          <div className={classes.btContainer}>
          <Button
              className={classes.signBtn2}
              onClick={this.onSubmit.bind(this)}
              variant="contained"
               color="primary"
              style={{ color:'#000'}}
              disabled={!this.state.validations.mobile.valid}
            >
             Report error
            </Button>
            </div>
        </div>
      </div>
    );
  }
}

export default Form;
