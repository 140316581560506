import React, { useEffect, useState } from "react";
import "./PremiumResources.css";
import {
  Breadcrumbs,
  Button,
  Link,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import ShowPremiumResources from "./ShowPremiumResources";
import { getSuperAdminService } from "service/service";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
  },
}));

const PremiumResources = () => {
  const classes = useStyles();
  const location = useLocation();
  const [uploadedimg, setUploadedImg] = useState();
  const [showTable, setShowTable] = useState(false);
  const [resourceName, setResourceName] = useState(null);
  const [webUrl, setWebUrl] = useState(null);
  const [demoUrl, setDemoUrl] = useState(null);
  const [validityDate, setValidityDate] = useState(null);
  const [resourceTitle, setResourceTitle] = useState(null);
  const [cost, setCost] = useState();
  const [description, setDiscription] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [resourceId, setResourceId] = useState(null);
  const [resourceNameValidation, setResourceNameValidation] = useState(false);
  const [editUploadImg, setEditUploadImg] = useState(false);
  const handleChange = (e) => {
    if (isEdit) {
      const upoaded = e.target.files?.[0];
      setUploadedImg(upoaded);
      setEditUploadImg(true);
    } else {
      const upoaded = e.target.files?.[0];
      setUploadedImg(upoaded);
    }
  };

  const handleContent = (e) => {
    e.preventDefault();
    console.info("You clicked a breadcrumb.");
    setShowTable(false);
  };

  const handleTableContent = (e) => {
    e.preventDefault();
    console.info("You clicked.");
    setShowTable(true);
  };

  const resetAllfield = () => {
    setResourceId("");
    setResourceName("");
    setWebUrl("");
    setDemoUrl("");
    setValidityDate("");
    setResourceTitle("");
    setCost("");
    setUploadedImg("");
    setDiscription("");
    setErrorMessage("");
  };
  const createResource = async () => {
    console.log(
      resourceName,
      webUrl,
      demoUrl,
      validityDate,
      resourceTitle,
      cost,
      uploadedimg,
      description
    );
    if (isEdit) {
      let result;
      if (editUploadImg) {
        try {
          const name = "PREMIUM";
          const response = await getSuperAdminService().addFilesOnS3(
            uploadedimg,
            name
          );

          if (response) {
            console.log("Result", response);
            result = response;
          }
        } catch (err) {
          console.log(err);
        }
      }
      const payload = {
        name: resourceName,
        logo_url: editUploadImg ? result?.data?.file : uploadedimg,
        website: webUrl,
        demo: demoUrl,
        validity: validityDate,
        title: resourceTitle,
        description: description,
        cost: cost,
        error_message: errorMessage,
      };

      getSuperAdminService()
        .editPremiumResource(resourceId, payload)
        .then((res) => {
          console.log("Result", res);
          if (res?.status === 200) {
            setEditUploadImg(false);
            setResourceId("");
            setResourceName("");
            setWebUrl("");
            setDemoUrl("");
            setValidityDate("");
            setResourceTitle("");
            setCost("");
            setUploadedImg("");
            setDiscription("");
            setErrorMessage("");
            setIsEdit(false);
          }
        });
    } else {
      let result;
      if (uploadedimg) {
        try {
          const name = "PREMIUM";
          const response = await getSuperAdminService().addFilesOnS3(
            uploadedimg,
            name
          );

          if (response) {
            result = response;
          }
        } catch (err) {
          console.log(err);
        }
      }
      const payload = {
        name: resourceName,
        logo_url: result?.data?.file,
        website: webUrl,
        demo: demoUrl,
        validity: validityDate,
        title: resourceTitle,
        description: description,
        cost: cost,
        error_message: errorMessage,
      };

      await getSuperAdminService()
        .createPremiumResource(payload)
        .then((res) => {
          if (res?.status === 200) {
            setResourceId("");
            setResourceName("");
            setWebUrl("");
            setDemoUrl("");
            setValidityDate("");
            setResourceTitle("");
            setCost("");
            setUploadedImg("");
            setDiscription("");
            setErrorMessage("");
          }
        });
    }
  };

  useEffect(() => {
    if (isEdit) {
      console.log("location", location?.state);
      // const data = location?.state?.rowData;
      setResourceId(location?.state?.rowData?.id);
      setResourceName(location?.state?.rowData?.name);
      setWebUrl(location?.state?.rowData?.website);
      setDemoUrl(location?.state?.rowData.demo);
      setValidityDate(location?.state?.rowData?.validity);
      setResourceTitle(location?.state?.rowData?.title);
      setCost(location?.state?.rowData?.cost);
      setUploadedImg(location?.state?.rowData?.logo_url);
      setDiscription(location?.state?.rowData?.description);
      setErrorMessage(location?.state?.rowData?.error_message);
    }
  }, [isEdit]);

  console.log("Edit", isEdit, editUploadImg);
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "2rem" }}>
        <Link
          color="inherit"
          onClick={handleContent}
          style={{
            color: showTable === false ? "blue" : "black",
            cursor: "pointer",
          }}
        >
          Create New Resource
        </Link>
        <Link
          onClick={handleTableContent}
          style={{
            color: showTable === true ? "blue" : "black",
            cursor: "pointer",
          }}
        >
          View Resources
        </Link>
      </Breadcrumbs>
      {showTable ? (
        <ShowPremiumResources
          setShowTable={setShowTable}
          setIsEdit={setIsEdit}
        />
      ) : (
        <div className="premiumWrapper">
          <h5>Create New Resource</h5>

          <div className="premiumContainer">
            <div className="premiumContainer1">
              <TextField
                id="standard-basic"
                label="Resource Name*"
                variant="filled"
                type="text"
                value={resourceName}
                onChange={(e) => {
                  if (e.target.value.length <= 5) {
                    setResourceNameValidation(false);
                  } else {
                    setResourceNameValidation(true);
                  }
                  setResourceName(e.target.value);
                }}
                helperText={
                  resourceNameValidation ? (
                    <span style={{ color: "red" }}>
                      Resource name should not be more than 5 characters
                    </span>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                id="standard-basic"
                label="Resource Website URL*"
                multiline
                maxRows={4}
                variant="filled"
                value={webUrl}
                onChange={(e) => {
                  setWebUrl(e.target.value);
                }}
              />
              <TextField
                id="standard-basic"
                label="Resource Demo URL*"
                multiline
                maxRows={4}
                variant="filled"
                value={demoUrl}
                onChange={(e) => {
                  setDemoUrl(e.target.value);
                }}
              />
              <form className={classes.container} noValidate>
                <TextField
                  id="date"
                  label="Resource Validity Date*"
                  variant="filled"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={validityDate}
                  onChange={(e) => {
                    setValidityDate(e.target.value);
                  }}
                />
              </form>
              <TextField
                id="standard-basic"
                label="Title for Resource*"
                variant="filled"
                type="text"
                multiline
                maxRows={4}
                value={resourceTitle}
                onChange={(e) => {
                  setResourceTitle(e.target.value);
                }}
              />
              <TextField
                type="number"
                id="standard-basic"
                label="Cost of Resource in Diamonds*"
                variant="filled"
                value={cost}
                onChange={(e) => {
                  setCost(e.target.value);
                }}
              />
            </div>
            <div className="premiumContainer2">
              <div
                className={classes.root}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={handleChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudDownload />}
                  >
                    Upload Resources Logo*
                  </Button>
                </label>
              </div>
              {uploadedimg && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isEdit && (
                      <img
                        src={
                          editUploadImg === true
                            ? URL.createObjectURL(uploadedimg)
                            : uploadedimg
                        }
                        height="150px"
                        width="150px"
                      />
                    )}

                    {!isEdit && uploadedimg && (
                      <img
                        src={URL.createObjectURL(uploadedimg)}
                        height="150px"
                        width="150px"
                      />
                    )}
                  </div>
                  <span style={{ textAlign: "center" }}>Uploaded Image</span>
                </>
              )}

              <div className="description">
                <TextField
                  id="standard-multiline-static"
                  label="Description for Resource*..."
                  type="text"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "90%" }}
                  InputProps={{
                    style: { height: "8rem" },
                  }}
                  value={description}
                  onChange={(e) => {
                    setDiscription(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="message">
            <TextField
              multiline
              maxRows={4}
              label=" Descriptive message for users who don't have enough wealth ( diamond ) for buying the Resource*"
              style={{ width: "100%" }}
              variant="filled"
              value={errorMessage}
              onChange={(e) => {
                setErrorMessage(e.target.value);
              }}
            />
          </div>
          <div className="buttons">
            <Button
              variant="contained"
              color="primary"
              disabled={
                !resourceName ||
                !resourceTitle ||
                !cost ||
                !webUrl ||
                !demoUrl ||
                !description ||
                !errorMessage ||
                !uploadedimg ||
                !validityDate
              }
              onClick={() => {
                createResource();
              }}
            >
              {isEdit ? "Edit" : "Submit"}
            </Button>
            <Button
              style={{ border: "1px solid black" }}
              onClick={() => {
                resetAllfield();
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PremiumResources;
