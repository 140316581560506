import React from 'react';
import moment from 'moment';
import Pagination from "material-ui-flat-pagination";
import ReactPaginate from 'react-paginate';
import AlertDialog from "components/AlertDialog/AlertDialog";

import { Avatar, CardHeader, CardContent, Card, IconButton } from '@material-ui/core';

import classes from './ChapterComments.module.css';
import DeleteIcon from "@material-ui/icons/Delete";

import * as constants from "./../../../common/constants";
import CardFooter from 'components/Card/CardFooter';
import { getSharedService } from 'service/service';

class ChapterComments extends React.Component {
    state = {
        comments: [],
        commentAdded: '',
        chapterId: null,
        recordPerPage: 5,
        currentPageNumber: 0,
        pageCount: 0,
        adminData: {},
        user_type:"",
        showDeleteAlert:false,
        commentToDelete:null,
        message:""
    }

    componentDidMount() {
        let pageCount = Math.ceil(this.props.comments.total_count / this.state.recordPerPage)
        const user = JSON.parse(localStorage.getItem("user"));
        this.setState({
            comments: this.props.comments.chapterDetails,
            chapterId: this.props.chapterId,
            pageCount: pageCount,
            user_type: user.user_type,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.comments.chapterDetails !== this.props.comments.chapterDetails) {
            this.setState({
                comments: this.props.comments.chapterDetails,
            })
        }
    }

    render() {
        let { comments, recordPerPage, pageCount, currentPageNumber,showDeleteAlert } = this.state;
        let displayClass = this.props.comments.total_count == null ? 'none' : 'flex';
        return (<>
        <div>
        <AlertDialog
          open={showDeleteAlert}
          title="Delete?"
          text="Do you want to delete this comment?"
          onCancel={() => {
            this.setState({
              showDeleteAlert: false,
            });
          }}
          onConfirm={() => {
            this.setState(
              {
                showDeleteAlert: false,
              },
              () => {
               this.deleteComment(comments);
              }
            );
          }}
        />
        </div>

            <div>
                <CardContent>
                    {comments && comments.length ?
                        this.renderComments(comments) : <div style={{ justifyContent: 'center', alignItems: 'center' }}>No comments yet !!</div>}
                </CardContent>
                <div style={{ display: `${displayClass}`, alignItems: 'center' }}>
                    <span style={{ marginTop: '-15px'}} >
                        { /*<label htmlFor="record">Records per page : </label>*/ }
                        <select 
                        id="record" 
                        style={{ 
                            width: '100px',
                            position: 'relative',
                            display: 'block',
                            color: '#0d6efd',
                            textDecoration: 'none',
                            backgroundColor: '#fff',
                            border: '1px solid #dee2e6',
                            padding: '6px 10px',
                            marginLeft: '15px',
                            borderRadius: '5px',
                        }} 
                        onChange={(event) => this.onRecordChange(event)} 
                        value={recordPerPage}>
                            <option value={5}>5 rows</option>
                            <option value={10}>10 rows</option>
                            <option value={15}>15 rows</option>
                        </select>
                    </span>
                    <ReactPaginate
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        onPageChange={(event) => this.onPageChangeHandle(event)}
                    />
                </div>
            </div>
            </>
        )
    }

    onRecordChange = (event) => {
        let page_count;
        if(this.props.comments.total_count < event.target.value)
        {
            page_count = 1; 
        }
        else
        {        
            page_count = Math.ceil(this.props.comments.total_count / event.target.value);
        }
        this.setState({
            recordPerPage: event.target.value,
            pageCount : page_count
        },() => {
            this.props.onPageChange(this.state.currentPageNumber, this.state.recordPerPage)
        })
    }

    onPageChangeHandle (pageNO) {
       console.log("ok");
        this.setState({
            currentPageNumber: pageNO
        },
        () => {
            this.props.onPageChange(this.state.currentPageNumber, this.state.recordPerPage)
        })
    }

    isVopaMember = (comment) => {
        let userType = comment.user_type.toUpperCase()
        return (userType == constants.USER_TYPE.SUPER_ADMIN) || (userType == constants.USER_TYPE.CONTENT_WRITER)
    }

    renderComments(commentsData) {
        return commentsData.map((comment, index) => {
            return (
                <div key={index}>
                    <Card className={classes.root} variant="outlined">
                        
                        <CardHeader
                            title={`${comment.full_name} ${this.isVopaMember(comment) ? ' - vopa member' : ''}`}
                            subheader={comment.updated_at ? moment(comment.updated_at).format("LLL") : moment(comment.created_at).format("MMM Do YY")}
                            subheader={this.renderDelIcon(comment)}
                            avatar={
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    {this.getFirstLetter(comment.full_name)}
                                </Avatar>
                                
                            } />
                        <CardContent>
                            {comment.comment.text}
                        </CardContent>
                        
                    </Card>
                    <br />
                </div>
            )
        })
    }

    renderDelIcon=(commentData)=>{
        console.log("comment",commentData);
         if(this.state.user_type === constants.USER_TYPE.SUPER_ADMIN)
        {
            return(
                <IconButton
                 onClick={() => {
                    this.setState({
                        showDeleteAlert:true,
                        commentToDelete:commentData.comment_id
                    })
                }}>
                      {!this.props.removeDelete ? <DeleteIcon style={{marginLeft:"50em"}}/> : null}


                       </IconButton>
                
            )
        }
        else{
            return("error")
        }

    }

    deleteComment=(commentData)=>{
        console.log(this.state.commentToDelete);
        const param = {
            comment_id: this.state.commentToDelete,
          };
          console.log(param);
          getSharedService ()
            .deleteComment(param)
            .then((result) => {
              result = result.data;
              console.log("comments" , commentData);
              console.log(result.status==200);
              if (result && result.status == 200) {
                const filteredComments = commentData.filter((comment) => {
                    return comment.comment_id != this.state.commentToDelete;              
                });
                console.log("cm",this.state.comments);
                console.log("filter",filteredComments);
                this.setState({
                  message: " comment  delete successfully",
                  error: true,   
                  comments:filteredComments
                });
              } else {
                this.setState({
                  message: "Failed to comment delete",
                  error: false,
                });
              }
            })
            .catch((err) => {
              this.setState({
                message: "Something has went wrong",
                error: true,
              });
            });
    }
    getFirstLetter(str) {
        if (str.trim().length) {
            return str.substr(0, 1).toUpperCase()
        }
        return '';
    }
}

export default ChapterComments;