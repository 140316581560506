import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const Navbar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userData, setUserData] = React.useState({});
    let history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let userObj = JSON.parse(localStorage.getItem("user"));
        if (userObj) {
            setUserData(userObj)
        }
    }, [])

    const getLoggedInUser = () => {
        if (userData && userData.user_details) {
            return userData.user_details.first_name + " " + userData.user_details.last_name;
        }
        return "";
    }

    const onLogout = () => {
        localStorage.clear();
        history.replace("/");
    }

    return (
        <AppBar position="static" style={{ background: '#FFFFFF', display: `${!userData.token ? 'none' : ''}` }}>
            <Toolbar style={{ justifyContent: 'end', display: 'flex', flexDirection: 'row-reverse' }}>
                <Avatar src="/broken-image.jpg" />
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    {getLoggedInUser()} <div className="arrow"></div>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem> */}
                    <MenuItem onClick={onLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar;