import { Button, Card, CircularProgress, FormHelperText, Input, Modal } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { getSuperAdminService } from "service/service";
import { getAIQuestion } from "service/WebSeparateService";


export default class MCQAIQuestion extends React.Component {
  state = {
    paragraph:"",
    openmodal:false,
    isloading:false,
    aiQuestion:[],
    showErr:false
  };

  
  getQuestionsFromAi =async ()=>{

    
    let param = {
      "passage":this.state.paragraph
    }


      await getAIQuestion(param)
            .then((res)=>{
              res = res.data
              if(res && res?.status == 200){
                console.log("Res",res)
                this.setState({
                  aiQuestion:res?.question_list,
                  isloading:false
                })
              }
              else{
                  this.setState({
                    isloading:false,
                    aiQuestion:[]
                  })
              }
            })
            .catch((err)=>{
              console.log("err",err)
              this.setState({
                isloading:false,
                aiQuestion:[]
              })
            })

  }

  render() {

    let apply = ""
    let evaluate = ""
    let create = ""
    let analyze = ""


    if(this.state.aiQuestion != 0){
      
      apply = this.state.aiQuestion.find(obj => obj?.level == "Apply" );
      evaluate = this.state.aiQuestion.find(obj => obj?.level == "Evaluate" );
      create = this.state.aiQuestion.find(obj => obj?.level == "Create" );
      analyze = this.state.aiQuestion.find(obj => obj?.level == "Analyze" );
    }


    return (
      <>
       <Modal
        open={this.state.openmodal}
        onClose={() => {
          this.setState({
            openmodal: false,
            isloading:false,
          });
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
  {
    this.state.isloading == true ? (
      <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
            <div
            style={{
              background: "white",
            //   padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "50%",
              height:"50vh",
              justifyContent: "center",
            alignItems: "center",
            }}
          >
            <CircularProgress 
                       color="inherit"
                          size={20}
                       />
          </div>
       
        </div>
    ) : (

      <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
            <div
            style={{
              background: "white",
            //   padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "50%",
            }}
          >
            <div style={{ backgroundColor:'#58B9FF',textAlign:'center',padding:'10px',color:'#fff',display:'flex',justifyContent:'space-between'}}>
               <div>
                </div>
                AI Question
                <div>
                   <CancelRounded style={{ color:'red'}} onClick={()=>{
                    this.setState({
                        openmodal:false,
                        isloading:false,  
                    })
                   }}/>
                </div>
            </div>

            <div style={{ padding:'10px',color:'#58B9FF'}}>
            Apply (Applying understanding of the passage): {apply?.question}
            </div>

            <div style={{ padding:'10px',color:'#58B9FF'}}>
            Evaluate (Evaluating concepts and making judgments): {evaluate?.question}
            </div>

            <div style={{ padding:'10px',color:'#58B9FF'}}>
            Create (Generating new ideas and perspectives): {create?.question}
            </div>
            <div style={{ padding:'10px',color:'#58B9FF'}}>
            Analyze : {analyze?.question}
            </div>
          </div>
       
        </div>
    ) 
  }

      </Modal>
        <Card>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> AI questions</h4>
          </CardHeader>
          <CardBody style={{}}>
          <textarea
            onChange={(e)=>{
              this.setState({
                
                paragraph:e.target.value
              })
            }}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "253px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
              }}
              placeholder="Enter minimum 100 words"
              value={this.state.paragraph}
            >
              {this.state.paragraph}
            </textarea>
            {
              (this.state.showErr == true && (
                this.state.paragraph?.split(" ").filter(word => word != "")?.length < 100
              ) ) ? (
                <p style={{
              color:"red"
            }} >
              Minimum 100 words required
            </p>
              ) : null
            }
             
             <div style={{ display:'flex',justifyContent:'center'}}>

            <Button
            style={{ backgroundColor:'#4E47B2',padding:'5px',borderRadius:'10px',marginTop:'20px',color:'#fff'}}
            onClick={()=>{
              let myArray = this.state.paragraph?.split(" ")?.filter(word => word !== "");
              if(myArray.length >= 100){
                this.setState({
                    openmodal:true,
                    isloading:true,
                    showErr:false
                },()=>{
                  this.getQuestionsFromAi()
                })
              }
              else{
                this.setState({
                  showErr:true
                })
              }

            }}>
                Generate
            </Button>
            </div>
          </CardBody>
        </Card>

       

      </>
    );
  }

  
}