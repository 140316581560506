import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import classes from "./CreditCourtesy.module.css";
import { withRouter } from "react-router-dom";
import { getSuperAdminService } from "./../../../service/service";
import { chapterDetailsUpdate } from "service/WebSeparateService";

const CreditCourtesy = (props) => {
  const credits = props.credits;
  const [editing, setEditing] = useState(false);
  const [lessonCreated, setLessonCreated] = useState("");
  const [videoCourtsey, setVideoCourtsey] = useState("");
  const [references, setReferences] = useState("");
  const [license, setLicense] = useState("");
  const [chapter, setChapter] = useState("");

  const saveChanges = async () => {
    let param = {
      
      credits: {
        lesson_created_by: lessonCreated ? lessonCreated : null,
        video_courtesy: videoCourtsey ? videoCourtsey : null,
        other_reference: references ? references : null,
        licence: license ? license : null,
      },
    };
    
    console.log(param,"ok");
    let updatedCreditRes = await chapterDetailsUpdate(chapter.chapter_id, param);
    updatedCreditRes = updatedCreditRes.data;
    console.log(updatedCreditRes,"updatedCreditRes");
    if (updatedCreditRes) {
      setEditing(false);
    }
  };

  useEffect(() => {
    setChapter(props.location.state.chapter);
    setPropsValues();
  }, []);

  const setPropsValues = () => {
    const {
      lesson_created_by,
      video_courtesy,
      other_reference,
      licence,
    } = credits;
    setLessonCreated(lesson_created_by);
    setVideoCourtsey(video_courtesy);
    setReferences(other_reference);
    setLicense(licence);
  };

  return (
    <div>
      {/* <Card className={classes.root} variant="outlined"> */}
      <CardContent>
      <div className="row">
        <div className="col-10">
          <div className={classes.text}>
            <span className={classes.label}>Lesson created by: &nbsp;</span>
            {editing ? (
              <TextField
                size="small"
                id="standard-basic"
                variant="outlined"
                value={lessonCreated ? lessonCreated : ""}
                onChange={(e) => setLessonCreated(e.target.value)}
              />
            ) : (
                <span>{lessonCreated ? lessonCreated : "N/A"}</span>
              )}
          </div>
        
          <div className={classes.text}>
            <span className={classes.label}>Video Courtesy: &nbsp;</span>
            {editing ? (
              <TextField
                size="small"
                id="standard-basic"
                variant="outlined"
                value={videoCourtsey ? videoCourtsey : ""}
                onChange={(e) => setVideoCourtsey(e.target.value)}
              />
            ) : (
                <span>{videoCourtsey ? videoCourtsey : "N/A"}</span>
              )}
          </div>

          <div className={classes.text}>
            <span className={classes.label}>Other references: &nbsp;</span>
            {editing ? (
              <TextField
                size="small"
                id="standard-basic"
                variant="outlined"
                value={references ? references : ""}
                onChange={(e) => setReferences(e.target.value)}
              />
            ) : (
                <span>{references ? references : "N/A"}</span>
              )}
          </div>

          <div className={classes.text}>
            <span className={classes.label}>License: &nbsp;</span>
            {editing ? (
              <TextField
                size="small"
                id="standard-basic"
                variant="outlined"
                value={license ? license : ""}
                onChange={(e) => setLicense(e.target.value)}
              />
            ) : (
                <span>{license ? license : "N/A"}</span>
              )}
          </div>
        </div>    
        
        <div className="col-2">
          <div className={classes.editContainer}>
            {!editing ? (
              <IconButton
                aria-label="Edit"
                onClick={() => setEditing(!editing)}
                title="Edit"
              >
                <EditIcon />
              </IconButton>
            ) : (
                <div>
                  <IconButton
                    aria-label="cancel"
                    onClick={() => {
                      setEditing(false);
                      setPropsValues();
                    }}
                    title="Cancel changes"
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    aria-label="save"
                    onClick={() => saveChanges()}
                    title="Save"
                  >
                    <SaveIcon />
                  </IconButton>
                </div>
              )}
          </div>
        </div>
      </div>
      </CardContent>
      {/* </Card> */}
    </div>
  );
};

export default withRouter(CreditCourtesy);
