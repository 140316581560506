import http from "./../common/axios";

export default class StudentService {
    getStudentBaseAnalytics() {
        return http.get(`/studentbaseanalytics/`);
    }

    generateStudentCsv(data) {
        return http.post(`/genratestudentcsv/`, data);
    }

    getStudentCountGraph(data) {
        return http.post(`/studentcountgraph/`, data);
    }

    getStudentDetailedList(data) {
        return http.post(`/studentdetailedlist/`, data);
    }

    getStudentCompleteProfile(data) {
        return http.post(`/studentprofiledetails/`, data);
    }
    generateBlockCsv(data) {
        return http.post(`/studentsummaryblockwisecsv/`, data);
    }
    getBlockSummaryTable(data) {
        return http.post(`/studentsummaryblockwise/`, data);
    }
    getIdwiseCount (data) {
        return http.post(`/getcountbyid/`, data);
      }
    getCastIncomeGendergraph (data) {
        return http.get(`/castincomegendergraph/`, data);
      }
}