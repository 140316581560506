import React from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import MaterialTable from "material-table";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  getStudentService,
  getSuperAdminService,
  getContentWriterService,
} from "service/service";

import classes from "./student.module.css";

import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PeopleIcon from "@material-ui/icons/People";
import FaceIcon from "@material-ui/icons/Face";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import * as constants from "./../../common/constants";
import { getAdminService } from "service/service";
import ReactTooltip from "react-tooltip";
import http from "../../common/axios";
import Snackbar from "components/Snackbar/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { EditAttributesOutlined } from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import CardFooter from "components/Card/CardFooter";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import { FormControlLabel, Switch } from "@material-ui/core";
import { httpV2 } from "service/WebSeparateService";
import Cancel from "@material-ui/icons/Cancel";
import DatePicker from "react-date-picker";
import Delete from "@material-ui/icons/Delete";
const styles = {
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
    padding: "8px 8px",
  },
  summary: {
    // alignItems:"center",
    width: "25em",
    height: "9em",
    align: "center",
    marginLeft: "13em",
    boxShadow: "3px 3px #e9eaf7",
    //border:"1px solid black",
    padding: "8px",
  },
};

export default class Students extends React.Component {
  tableRef = React.createRef();
  state = {
    baseAnalytics: {},
    studentList: [],
    column: [],
    queryObject: { searchText: "" },
    checked: false,
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    mediumList: [],
    subjectList: [],
    userList: [],
    state: {
      helperText: null,
      value: "",
    },
    board: "",
    district: {
      helperText: null,
      value: "",
    },
    class: "",
    medium: "",
    subject: "",
    teacher: "",
    user: "",
    currentTabIndex: 0,
    stdWiseGraph: null,
    openDetailDialog: false,
    studentDetail: {},
    subjectWiseGraph: null,
    loading: false,
    districts: [],
    blocks: [],
    block: {
      helperText: null,
      value: "",
    },
    schools: [],
    school: "",
    stateForGraph: "",
    districtForGraph: "",
    blockForGraph: "",
    searchText: "",
    isApply: false,
    sumOfStudents: "",
    isSetAuto: false,
    schoolName: "",
    filteredSchools: [],
    AutoSchool_id: "",
    openStudentReport: false,
    studentSummary: [],
    showMaterialtable: false,
    showButton: false,
    schoolForsummary: "",
    udiseForsumary: "",
    BlockForSummary: "",
    districtorSumaary: "",
    CountForsummary: "",
    uniqueContact: "",
    activeCount: "",
    openBlockreport: false,
    BlockCsv: false,
    openSchoolData: false,
    showMaterialtable2: false,
    blockDataForSchool: [],
    columnForBlock: [],
    showLoader: false,
    ShowSchoolSummary: false,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    hide1: true,
    moreFilter: false,
    moreCount: false,
    femaleCount: false,
    messageMail: "",
    dialogEmail: false,
    userEmail: "",
    stateReport: "",
    districtReport: "",
    blockReport: [],
    frequency: "",
    selectedBlockReports: [],
    EmailReport: "",
    person: [],
    mailData: [],
    blockForCards: [],
    showEmailModal: false,
    editSchedule: false,
    editID: "",
    openAlert: false,
    scheduledID: "",
    scheduledStatus: "",
    updateStatus: false,
    updateStatusEmail: "",
    updateStatusID: "",
    status: "",
    avgTimeGraph: false,
    incomeFilter: false,
    filterName: "",
    hrValue: "",
    FilterAvg: "",
    FilterActiveCount: "",
    NameForReport: "",
    ContactForReport: "",
    designationForReport: "",
    showDownloadDialog: false,
    blockDownload: [],
    objForDownload: [],
    date: "",
    dateForEmail: "",
    reportName: "",
    deleteSchedule: "",
    toppers: [],
    toperState: "",
    topperDist: "",
    topperBlock: "",
    showTopper: false,
    dialogTopper: false,
    topperName: "",
  };

  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  componentDidMount() {
    this.getBaseAnalytics();
    this.renderScheduleForMail();
    //this.getStudentDataForChart();
    this.getData();
    this.setState({
      columnForBlock: [
        {
          field: "u_dise",
          title: "U_dise",
          editable: "never",
        },
        {
          field: "school_name",
          title: "School_name",
          editable: "never",
        },
        {
          field: "count_of_student_profile",
          title: "Count",
          editable: "never",
        },
      ],
      column: [
        {
          field: "first_name",
          title: "First name",
          editable: "never",
        },
        {
          field: "last_name",
          title: "Last name",
          editable: "never",
        },
        {
          field: "class_name",
          title: "Class",
          editable: "never",
        },
        {
          field: "medium_name",
          title: "Medium",
          editable: "never",
        },
        {
          field: "student_state_name",
          title: "State",
          editable: "never",
        },
        {
          field: "student_district_name",
          title: "District",
          editable: "never",
        },
        {
          field: "student_block_name",
          title: "Block",
          editable: "never",
        },
        {
          field: "school_name",
          title: "School",
          editable: "never",
        },
        // {
        //   field: "app_total_time_use",
        //   title: "App time usage",
        //   editable: "never",
        //   render: (rowData) => {
        //     return <div>{rowData.app_total_time_use ? this.millisecondToMinute(rowData.app_total_time_use) : ""}</div>;
        //   }
        // }
      ],
    });
  }
  getManualActivCount = (e) => {
    this.setState({
      hrValue: e,
    });
    http(`get_analysis_data/`, {
      params: {
        hours: e,
      },
    }).then((res) => {
      console.log(res, "res");
      this.setState({
        FilterAvg: res.data.response.avg_time,
        FilterActiveCount: res.data.response.count,
      });
    });
  };
  convertNumber(value) {
    var val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000) {
      val = (val / 1000).toFixed(2) + " K";
    }
    return val;
  }

  getBlockName(id) {
    let result = "";
    this.state.blocks.map((data) => {
      if (data.student_block_id == id) {
        result = data.block_name;
      }
    });
    return result;
  }

  getStudentsData = (query) => {
    let { district, block, school } = this.state;
    return new Promise(async (resolve, reject) => {
      let param = {
        student_district_id: district.value ? district.value : "",
        student_block_id: block.value ? block.value : "",
        school_id: school || "",
        class_id: "",
        medium_id: "",
        search_text: query.searchText || this.state.searchText,
        record_per_page: query.pageSize,
        page_number: query.page + 1,
      };
      if (this.state.isApply || this.state.searchText.trim() !== "") {
        try {
          console.log(param, "param");
          let studentList;
          if (this.state.openSchoolData) {
            studentList = await getStudentService().getStudentDetailedList(
              param
            );
            studentList = studentList.data;
          } else {
            studentList = await getStudentService().getBlockSummaryTable(param);
            studentList = studentList.data;
          }
          console.log(studentList, "student");
          this.setState({
            studentSummary: studentList,
          });

          if (studentList && studentList.status === 200) {
            let data = [];
            if (this.state.openSchoolData) {
              if (query.filters.length > 0) {
                data = studentList.response.chapterDetails.filter((c) => {
                  const filter = query.filters[0];
                  if (
                    c[filter.column.field]
                      .toString()
                      .toLowerCase()
                      .includes(filter.value.toString().toLowerCase())
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                });
              } else {
                data = studentList.response.chapterDetails;
              }
            } else {
              if (query.filters.length > 0) {
                data = studentList.response.filter((c) => {
                  const filter = query.filters[0];
                  if (
                    c[filter.this.state.columnForBlock.field]
                      .toString()
                      .toLowerCase()
                      .includes(filter.value.toString().toLowerCase())
                  ) {
                    console.log(
                      c[filter.this.state.columnForBlock.field]
                        .toString()
                        .toLowerCase()
                        .includes(filter.value.toString().toLowerCase()),
                      "x"
                    );
                    return true;
                  } else {
                    return false;
                  }
                });
              } else {
                data = studentList.response;
              }
            }
            resolve({
              data: data,
              page: query.page,
              totalCount: studentList.response.length
                ? studentList.response.length
                : studentList.response.total_count,
            });
          } else {
            resolve({
              data: [],
              page: 0,
              totalCount: 0,
            });
          }
          this.setState({
            isApply: false,
            studentList:
              this.state.showMaterialtable2 == false
                ? studentList.response.chapterDetails
                : studentList.response,
            openSchoolData: false,
          });
        } catch (error) {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          this.setState({
            isApply: false,
            openSchoolData: false,
          });
        }
      } else {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
      }
    });
  };

  isAllFilterSelected(val) {
    let { state, district, block, school } = this.state;
    if (val === "VIEW") {
      if (
        state.value !== "" &&
        district.value !== "" &&
        block.value !== "" &&
        school !== ""
      ) {
        return false;
      }
    } else {
      if (state.value !== "" && district.value !== "" && block.value !== "") {
        return false;
      }
    }
    return true;
  }

  getSummary = async () => {
    let { district, block, AutoSchool_id, ShowSchoolSummary } = this.state;
    console.log(this.state.AutoSchool_id, "district");

    let param = {
      student_district_id: district.value,
      student_block_id: block.value,
      school_id: AutoSchool_id ? AutoSchool_id : "",
    };
    let summaryData = await getSuperAdminService().getSummaryList(param);
    if (summaryData.status) {
      this.setState({ showLoader: false });
    }
    summaryData = summaryData.data.response;
    console.log(summaryData, "summary");
    if (ShowSchoolSummary == true) {
      this.setState({
        schoolForsummary: summaryData.school_name,
        udiseForsumary: summaryData.u_dise,
        BlockForSummary: summaryData.student_block_name,
        districtorSumaary: summaryData.student_district_name,
        CountForsummary: summaryData.total_count,
        uniqueContact: summaryData.Unique_phone_no,
        activeCount: summaryData.active_students_count,
        openStudentReport: true,
        AutoSchool_id: "",
        ShowSchoolSummary: false,
        total_parents_count: "",
        total_student_profile_count: "",
        total_app_time: "",
        average_time_on_app: " ",
        total_active_student: "",
        showParents: false,
        showStudent: false,
        showTime: false,
        showActiveStudent: false,
        showAvgTime: false,
      });
    } else {
      console.log("block details");
      this.setState({
        openBlockreport: true,
        TotalBlockCount: summaryData.total_count,
        uniqueContact: summaryData.Unique_phone_no,
        activeCount: summaryData.active_students_count,
        actualSchool: summaryData.count_of_actual_school,
        actualVschool: summaryData.count_of_v_school,
        BlockForSummary: summaryData.student_block_name,
        districtorSumaary: summaryData.student_district_name,
      });
    }
    this.setState({ showLoader: false });
  };
  getIDwiseCount = async () => {
    //console.log(this.state.blockForGraph,this.state.districtForGraph,this.state.stateForGraph,"ssb");
    let { stateForGraph, districtForGraph, blockForGraph } = this.state;
    console.log(stateForGraph);
    let param = {
      student_state_id: stateForGraph,
      student_district_id: districtForGraph,
      student_block_id: blockForGraph,
    };
    console.log(param);
    let data = await getStudentService().getIdwiseCount(param);
    console.log(data.data.response, "data");
  };
  renderStateMenu(type) {
    let { stateList, districts, blocks, schools } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "district"
        ? districts
        : type == "block"
        ? blocks
        : schools;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "district"
          ? state.student_district_id
          : type == "block"
          ? state.student_block_id
          : state.school_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "district"
          ? state.district_name
          : type == "block"
          ? state.block_name
          : type == "school"
          ? state.school_name
          : "";
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }

  clearFilter = () => {
    this.setState(
      {
        state: {
          helperText: null,
          value: "",
        },
        district: {
          helperText: null,
          value: "",
        },
        block: {
          helperText: null,
          value: "",
        },
        class: "",
        school: "",
      },
      () => {
        this.tableRef.current.onQueryChange();
      }
    );
  };

  multipleBLockEmail = (e) => {
    console.log(e.target.value, "block vals");
    let index = e.target.value.length;
    let blockArray = e.target.value;
    console.log(index, "blockArray", blockArray[index - 1]);
    const {
      target: { value },
    } = e;
    console.log(e, "e ", value);
    this.setState({
      person: typeof value === "string" ? value.split(",") : value,
    });
    console.log(e, "block val");
    let data = this.renderStateMenu("block");
    console.log(data, "block data");
    let blockname = "";
    data.map((res) => {
      if (res.props.value == blockArray[index - 1].toString()) {
        blockname = res.props.children;
      } else {
        return;
      }
    });
    console.log(blockname, "blockname");
    let array = [];
    let report = {
      student_block_id: blockArray[index - 1].toString(),
    };
    this.state.selectedBlockReports.push(report);
    {
      console.log(this.state.selectedBlockReports, "selectedBlockReports");
    }
  };
  onStudentDataExport = () => {
    let { district, block, school, AutoSchool_id } = this.state;
    //let userEmail = JSON.parse(localStorage.getItem("user")).user_details.email
    console.log(this.state.userEmail, "email");
    let param = {
      student_district_id: district.value ? district.value : "",
      student_block_id: block.value ? block.value : "",
      school_id: school || "",
      class_id: "",
      medium_id: "",
      to_email: this.state.userEmail,
    };
    getStudentService()
      .generateStudentCsv(param)
      .then((res) => {
        console.log(res.config.adapter.data, "res.headers");
        console.log(res.data.status, "school");
        if (res.data.status == "200") {
          this.setState({
            messageMail: "Excel sheet sent to your mail",
            dialogEmail: false,
            to_email: "",
          });
          // let contentDisposition = res.headers['content-disposition'];
          // console.log(contentDisposition, "CSV");
          // let date = new Date().toLocaleString();
          // let fileName = (this.state.schoolName) ? `${AutoSchool_id}_${date}` : contentDisposition.substr(contentDisposition.indexOf("=") + 1, contentDisposition.length)

          // this.convertJsonToCsv(res.data, fileName)
        }
      });
  };
  onDownloadList = () => {
    let { district, block } = this.state;
    let report = {
      student_block_id: block.value,
    };
    this.state.objForDownload.push(report);
    let arr = this.state.objForDownload;
    console.log(arr.length, "arr.length");
    const arrayToObject = (arr) => {
      const res = {};
      res[0] = report;
      console.log(res, "res");

      return res;
    };
    console.log(arrayToObject(arr));
    console.log("dist");
    let param = {
      student_district_id: district.value ? district.value : "",
      student_block_id: arrayToObject(arr),
      school_id: "",
      class_id: "",
      medium_id: "",
    };
    console.log(param, "param");
    getStudentService()
      .generateBlockCsv(param)
      .then((res) => {
        if (res.data) {
          this.setState({
            messageMail: "Excel sheet Downloading",
            showDownloadDialog: false,
          });
          let contentDisposition = res.headers["content-disposition"];
          console.log(
            contentDisposition.substr(
              contentDisposition.indexOf("=") + 1,
              contentDisposition.length
            ),
            "CSV"
          );
          let date = new Date().toLocaleString();
          let fileName = `${contentDisposition.substr(
            contentDisposition.indexOf("=") + 1,
            contentDisposition.length
          )}`;

          this.convertJsonToCsv(res.data, fileName);
        }
      });
  };
  onSchoolDataExport = () => {
    console.log("block");
    let { district, block } = this.state;
    let arr = this.state.selectedBlockReports;
    console.log(arr.length, "arr.length");
    const arrayToObject = (arr) => {
      const res = {};
      for (let i = 0; i < arr.length; i++) {
        const key = i;
        res[key] = arr[i];
        delete res[key]["id"];
      }
      return res;
    };
    console.log(arrayToObject(arr));
    console.log(block.value, "dist");
    let param = {
      student_district_id: district.value ? district.value : "",
      student_block_id: arrayToObject(arr),
      school_id: "",
      class_id: "",
      medium_id: "",
      to_email: this.state.userEmail,
    };
    getStudentService()
      .generateBlockCsv(param)
      .then((res) => {
        if (res.data) {
          this.setState({
            messageMail: "Excel sheet sent to your mail",
            showEmailModal: false,
            to_email: "",
          });
          // let contentDisposition = res.headers['content-disposition'];
          // console.log(contentDisposition.substr(contentDisposition.indexOf("=") + 1, contentDisposition.length), "CSV");
          // let date = new Date().toLocaleString();
          // let fileName = `${contentDisposition.substr(contentDisposition.indexOf("=") + 1, contentDisposition.length)}`

          // this.convertJsonToCsv(res.data, fileName)
        }
      });
  };
  // convertToCSV(objArray) {
  //   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  //   var str = '';

  //   for (var i = 0; i < array.length; i++) {
  //     var line = '';
  //     for (var index in array[i]) {
  //       if (line != '') line += ','

  //       line += array[i][index];
  //     }

  //     str += line + '\r\n';
  //   }

  //   return str;
  // }

  convertJsonToCsv(items, fileTitle) {
    // Convert Object to JSON
    // var jsonObject = JSON.stringify(items);

    // var csv = this.convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle || "export.csv";

    var blob = new Blob([items], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  getBaseAnalytics = () => {
    getStudentService()
      .getStudentBaseAnalytics()
      .then((res) => {
        if (res.data && res.data.status == 200) {
          this.setState({
            baseAnalytics: res.data.response,
          });
        }
      });
  };

  millisecondToMinute(val) {
    let time = 0;
    if (val) {
      time = val / 60000; //millisecond to mins conversion
      if (time > 60) {
        // Hour conversoin
        if (time / 60 > 1000) {
          time = time / 60;
          time = `${(time / 1000).toFixed(2)}K+ Hrs`;
        } else {
          time = `${(time / 60).toFixed(2)} Hr`;
        }
      } else {
        time = `${time.toFixed(2)} Min`;
      }
    }
    return time;
  }

  millisecondToMinuteOnlyNum(val) {
    let time = 0;
    if (val) {
      time = val / 60000; //millisecond to mins conversion
      // if (time > 60) {
      //   time = (time / 60).toFixed(2);   // minutes conversoin
      // } else {
      //   time = (time).toFixed(2);
      // }
    }
    return +time.toFixed(2);
  }
  anaylticsOfStudent = async (id) => {
    console.log(id, "id");
    let data = {};
    if (id == "1") {
      data = {
        user_count: true,
      };
    } else if (id == "2") {
      data = {
        student_count: true,
      };
    } else if (id == "3") {
      data = {
        app_time: true,
      };
    } else if (id == "4") {
      data = {
        active_user_count: true,
      };
    } else {
      data = {
        user_average_time: true,
      };
    }
    let studentCount = await http.get(`/studentbaseanalytics/`, {
      params: data,
    });
    console.log(studentCount, "studentCount");
    studentCount = studentCount.data.response;
    console.log(
      studentCount.hasOwnProperty("total_parents_count"),
      "studentCount"
    );
    if (studentCount.hasOwnProperty("total_user_count")) {
      let parantsCount = this.convertNumber(studentCount.total_user_count);
      console.log(parantsCount, "parantsCount");
      this.setState({
        total_parents_count: parantsCount,
        showParents: true,
      });
    } else if (studentCount.hasOwnProperty("total_student_profile_count")) {
      let profileCount = this.convertNumber(
        studentCount.total_student_profile_count
      );

      this.setState({
        total_student_profile_count: profileCount,
        showStudent: true,
      });
    } else if (studentCount.hasOwnProperty("total_app_time")) {
      let appTime = this.millisecondToMinute(studentCount.total_app_time);

      this.setState({
        total_app_time: appTime,
        showTime: true,
      });
    } else if (studentCount.hasOwnProperty("total_active_user_count")) {
      let activeStudent = this.convertNumber(studentCount.total_active_user_count);

      this.setState({
        total_active_student: activeStudent,
        showActiveStudent: true,
      });
    } else if (studentCount.hasOwnProperty("user_average_time")) {
      let avgTime = this.millisecondToMinute(studentCount.user_average_time);

      this.setState({
        average_time_on_app: avgTime,
        showAvgTime: true,
      });
    }
  };
  getStatusText = (key, value) => {
    let statObj = {
      text: "",
      data: 0,
      icon: "",
    };
    switch (key) {
      case "total_parents_count":
        statObj.text = "Total Users";
        statObj.data =
          this.state.total_parents_count == true
            ? this.convertNumber(value)
            : null;
        statObj.icon =
          this.state.total_parents_count == true ? (
            <PeopleIcon className={classes.copySvg} />
          ) : null;

        break;

      case "total_student_profile_count":
        statObj.text = "Total students";
        statObj.data =
          this.state.total_student_profile_count == true
            ? this.convertNumber(value)
            : null;
        statObj.icon =
          this.state.total_student_profile_count == true ? (
            <FaceIcon className={classes.copySvg} />
          ) : null;
        break;

      case "total_app_time":
        statObj.text = "Total time spent on app (Approx.)";
        statObj.data =
          this.state.total_app_time == true
            ? this.millisecondToMinute(value)
            : null;
        statObj.icon =
          this.state.total_app_time == true ? (
            <AccessTimeIcon className={classes.copySvg} />
          ) : null;
        break;

      case "average_time_on_app":
        statObj.text = "Average time spent";
        statObj.data =
          this.state.average_time_on_app == true
            ? this.millisecondToMinute(value)
            : null;
        statObj.icon =
          this.state.average_time_on_app == true ? (
            <AccessTimeIcon className={classes.copySvg} />
          ) : null;
        break;

      case "total_active_student":
        statObj.text = "Total active student (1 hr usage)";
        statObj.data =
          this.state.total_active_student == true
            ? this.convertNumber(value)
            : null;
        statObj.icon =
          this.state.total_active_student == true ? (
            <PeopleIcon className={classes.copySvg} />
          ) : null;
        break;
      // case "total_active_student":
      //   statObj.text = "Total active student";
      //   statObj.data = this.state.total_active_student == true ? this.convertNumber(value);
      //   statObj.icon = <PeopleIcon className={classes.copySvg} />
      //   break
    }
    return statObj;
  };

  renderStats = (baseAnalytics) => {
    let statsResult = [];
    if (baseAnalytics && Object.entries(baseAnalytics).length) {
      for (const [key, value] of Object.entries(baseAnalytics)) {
        let statData = this.getStatusText(key, value);
        // statsResult.push(
        //   <div
        //   style={{ cursor:"pointer"}}
        //   data-tip data-for="registerTip"
        //   onClick={(e)=>{
        //     console.log(e,"e");
        //       if(key== "total_parents_count")
        //       {
        //         this.setState({total_parents_count : true,
        //         hide1 : true})
        //       }
        //       else if(key== "total_student_profile_count")
        //       {
        //         this.setState({total_student_profile_count : true})
        //       }
        //       else if(key=="total_app_time")
        //       {
        //         this.setState({total_app_time : true})
        //       }
        //       else if(key== "average_time_on_app")
        //       {
        //         this.setState({average_time_on_app : true})
        //       }
        //       else {
        //         this.setState({total_active_student : true})
        //       }

        //     }}
        //      className="col-2" key={key}>
        //     <div className={`${classes.chpterStatBox} row`}>
        //       <div className="row">
        //         <div style={{ display: "flex", justifyContent: "center", alignSelf: "center" , }}>
        //         <ReactTooltip id="registerTip" place="bottom" effect="solid" style={{background:"blue"}}>
        //         Click to see details
        //          </ReactTooltip>
        //           <Button
        //         data-tip data-for="registerTip"
        //           // onClick={(e)=>{
        //           //   console.log(e,"e");
        //           //     if(key== "total_parents_count")
        //           //     {
        //           //       this.setState({total_parents_count : true,
        //           //       hide1 : true})
        //           //     }
        //           //     else if(key== "total_student_profile_count")
        //           //     {
        //           //       this.setState({total_student_profile_count : true})
        //           //     }
        //           //     else if(key=="total_app_time")
        //           //     {
        //           //       this.setState({total_app_time : true})
        //           //     }
        //           //     else if(key== "average_time_on_app")
        //           //     {
        //           //       this.setState({average_time_on_app : true})
        //           //     }
        //           //     else {
        //           //       this.setState({total_active_student : true})
        //           //     }

        //           //   }}
        //           >{statData.text}</Button>
        //         </div>
        //       </div>
        //       <div className="row" style={{ alignItems: "center" }}>
        //         <div className={`col-6 ${classes.statText}`} title={(key == "total_parents_count" || key == "total_student_profile_count") ? value : ""}>
        //           {statData.data}
        //         </div>
        //         <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
        //           <IconButton>
        //             {statData.icon}
        //           </IconButton>
        //            {/* <Button
        //           id={`${key}`}
        //           style={{ background:"#3f50b5", color:"white", widht:"20px", height:"40px"  }}
        //           onClick={(e)=>{
        //           console.log(e,"e");
        //             if(key== "total_parents_count")
        //             {
        //               this.setState({total_parents_count : true,
        //               hide1 : true})
        //             }
        //             else if(key== "total_student_profile_count")
        //             {
        //               this.setState({total_student_profile_count : true})
        //             }
        //             else if(key=="total_app_time")
        //             {
        //               this.setState({total_app_time : true})
        //             }
        //             else if(key== "average_time_on_app")
        //             {
        //               this.setState({average_time_on_app : true})
        //             }
        //             else {
        //               this.setState({total_active_student : true})
        //             }

        //           }}>Click</Button>  */}
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // )
      }
    }
    return statsResult;
  };

  onSearchStudent = (data) => {
    // let { current: { state: { query } } } = this.tableRef
    // let queryObj = {
    //   pageSize: query.pageSize,
    //   page: query.page,
    //   searchText: data.target.value
    // }
    // this.setState({ queryObject: queryObj }, () => {
    //   this.getStudentsData(this.state.queryObject)
    // });
    var searchText = data.target.value;
    this.setState({
      openSchoolData : true,
      searchText: searchText,
    });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.tableRef.current.onQueryChange();
    }, 1000);
  };

  handleChange = () => {
    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        // this.getData();
        // this.getTeacherList();
      }
    );
  };

  async getData() {
    let { chapter } = this.state;
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      chapter = { ...chapter, state_id: stateRes.response[0].state_id };
      const stateList = stateRes.response;
      this.setState(
        {
          stateList: stateList,
          chapter,
          // state: {
          //   value:
          //     stateList.length > 0
          //       ? stateList[0].state_id
          //       : null,
          //   helperText: null,
          // },
          stateForGraph: stateList.length > 0 ? stateList[0].state_id : null,
        },
        () => {
          // this.getBoardByState();
          if (this.state.currentTabIndex == 0) {
            this.getStudentDataForChart();
            this.getDistrictsByState(this.state.stateForGraph);
          }
        }
      );
    } else {
      this.setState({
        stateList: [],
        state: {
          helperText: null,
          value: null,
        },
      });
    }
  }

  // getBoardByState = async () => {
  //   let data = { state_id: this.state.state.value };
  //   let res = await getSuperAdminService().getAllBoardFromState(data);
  //   res = res.data;
  //   if (res && res.status == 200) {
  //     const boardList = res.response;
  //     this.setState(
  //       {
  //         boardList: boardList,
  //         board: boardList.length > 0 ? boardList[0].board_id : "",
  //       },
  //       () => {
  //         this.getDistrictByBoard();
  //       }
  //     );
  //   } else {
  //     this.setState({
  //       boardList: [],
  //       board: "",
  //     });
  //   }

  //   let param = { state_id: this.state.state };
  //   let mediumRes = await getContentWriterService().getMediumbyState(param);
  //   mediumRes = mediumRes.data;
  //   if (mediumRes && mediumRes.status == 200) {
  //     const mediumList = mediumRes.response;
  //     this.setState({
  //       mediumList: mediumList,
  //       medium: mediumList.length > 0 ? mediumList[0].medium_id : "",
  //     });
  //   } else {
  //     this.setState({
  //       mediumList: [],
  //       medium: "",
  //     });
  //   }
  // };

  // getDistrictByBoard = async () => {
  //   let data = { board_id: this.state.board };
  //   let res = await getSuperAdminService().getAllDistrictFromBoard(data);
  //   res = res.data;
  //   if (res && res.status == 200) {
  //     const districtList = res.response;
  //     this.setState(
  //       {
  //         districtList: districtList,
  //         district: districtList.length > 0 ? districtList[0].district_id : "",
  //       },
  //       () => {
  //         this.getClassesByDistrict();
  //       }
  //     );
  //   } else {
  //     this.setState({
  //       districtList: [],
  //       district: "",
  //     });
  //   }
  // };

  // getClassesByDistrict = async () => {
  //   let data = { district_id: this.state.district };
  //   let res = await getSuperAdminService().getAllClassesFromDistrict(data);
  //   res = res.data;
  //   if (res && res.status == 200) {
  //     const classList = res.response;
  //     this.setState(
  //       {
  //         classList: classList,
  //         class: classList.length > 0 ? classList[0].class_id : "",
  //       },
  //       () => {
  //         this.getSubjectByClass();
  //       }
  //     );
  //   } else {
  //     this.setState({
  //       classList: [],
  //       class: "",
  //     });
  //   }
  // };

  // getSubjectByClass = () => {
  //   let apidata2 = {
  //     class_id: this.state.class,
  //     medium_id: this.state.medium,
  //   };
  //   getContentWriterService()
  //     .getSubjectByClassMed(apidata2)
  //     .then((res) => {
  //       res = res.data;
  //       if (res && res.status == 200) {
  //         const subjectList = res.response;
  //         this.setState({
  //           subjectList,
  //           //subject: subjectList.length > 0 ? subjectList[0].subject_id : "",
  //         });
  //       } else {
  //         this.setState({
  //           subjectList: [],
  //         });
  //       }
  //     });
  // };

  // getTeacherList = async () => {
  //   if (this.state.userList.length > 0) return;
  //   let data = {
  //     page_number: 1,
  //     record_per_page: 50,
  //     order_column: "created_at",
  //     order_type: 1,
  //     user_type: "",
  //     searchText: "",
  //   };
  //   let allUserRes = await getSuperAdminService().getAllAdminContentWriterList(
  //     data
  //   );
  //   allUserRes = allUserRes.data;
  //   if (allUserRes && allUserRes.status == 200) {
  //     this.setState({
  //       userList: allUserRes.response.adminTeacherList,
  //     });
  //   } else {
  //     this.setState({
  //       userList: [],
  //     });
  //   }
  // };

  getDistrictsByState = (state_id) => {
    getSuperAdminService()
      .getStudentDistrict({
        student_state_id: state_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              districts: response.response,
              // district: {
              //   value:
              //     response.response.length > 0
              //       ? response.response[0].student_district_id
              //       : null,
              //   helperText: null,
              // },
              // districtForGraph: response.response.length > 0
              //   ? response.response[0].student_district_id
              //   : null,
            },
            () => {
              if (this.state.currentTabIndex == 0) {
                if (this.state.districtForGraph) {
                  this.getStudentDataForChart();
                }
                //this.getStudentBlock(this.state.districtForGraph)
              }
            }
          );
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
          });
        }
      });
  };

  getStudentBlock = (district_id) => {
    getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id ? district_id : 1,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
              // block: {
              //   value:
              //     response.response.length > 0
              //       ? response.response[0].student_block_id
              //       : null,
              //   helperText: null,
              // },
              schools: [],
              // blockForGraph: response.response.length > 0
              //   ? response.response[0].student_block_id
              //   : null,
            },
            () => {
              if (this.state.currentTabIndex == 0) {
                this.getStudentDataForChart();
              }
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = (block, searchtext) => {
    const param = {
      student_block_id: block,
      searchText: searchtext ? searchtext : "",
    };
    getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        result = result.data;
        console.log(result, "school");
        if (result && result.status == 200) {
          this.setState({
            schools: result.response,
            filteredSchools: result.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  };

  handleTabChange = (event, newValue) => {
    this.handleChange();
    this.setState(
      {
        currentTabIndex: newValue,
      },
      () => {
        if (newValue == 0) {
          this.getStudentDataForChart();
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
          });
        }
      }
    );
  };

  getStudentDataForChart = async () => {
    let { districtForGraph, blockForGraph } = this.state;
    if (this.state.filterDropdownId === "1") {
      this.setState({ ActiveStudentKey: true });
    } else if (this.state.filterDropdownId === "2") {
      this.setState({ uniqueContactKey: true });
    } else if (this.state.filterDropdownId === "4") {
      this.setState({ avgTimeGraph: true });
    }
    let param = {
      district_id: districtForGraph,
      block_id: blockForGraph,
      medium_id: "",
      active_student: this.state.ActiveStudentKey,
      unique_user: this.state.uniqueContactKey,
      average_time: this.state.avgTimeGraph,
    };
    let studentCount;
    if (this.state.moreFilter == false) {
      studentCount = await getStudentService().getStudentCountGraph(param);
      studentCount = studentCount.data;
      console.log(studentCount, "studentCount");
    } else {
      // let data={
      //   student_district_id: districtForGraph,
      //   student_block_id: blockForGraph,
      //   gender : true

      // }
      console.log(this.state.filterName, "data");
      if (this.state.filterName == "income") {
        studentCount = await http.get(`/castincomegendergraph/`, {
          params: {
            student_district_id: blockForGraph ? null : districtForGraph,
            student_block_id: blockForGraph,
            income: true,
          },
        });
        studentCount = studentCount.data.response;
        console.log(studentCount, "studentCount");
      } else if (this.state.filterName == "Caste") {
        studentCount = await http.get(`/castincomegendergraph/`, {
          params: {
            student_district_id: blockForGraph ? null : districtForGraph,
            student_block_id: blockForGraph,
            caste: true,
          },
        });
        studentCount = studentCount.data.response;
        console.log(studentCount, "studentCount");
      } else {
        studentCount = await http.get(`/castincomegendergraph/`, {
          params: {
            student_district_id: blockForGraph ? null : districtForGraph,
            student_block_id: blockForGraph,
            gender: true,
          },
        });
        studentCount = studentCount.data.response;
        console.log(studentCount, "studentCount");
      }
    }

    console.log(studentCount, "count");
    let labels = [];
    let series = [];
    let count = [];
    let Extracount = [];
    let otherCount = [];
    let income1 = [];
    let income2 = [];
    let income3 = [];
    let income4 = [];
    let income0 = [];
    let OpenNum = [];
    let OBCNum = [];
    let NTNum = [];
    let OtherNum = [];
    let SBCNum = [];
    let STNum = [];
    let SCNum = [];
    let VJNTNum = [];
    let isClass = false;
    let sum = 0;

    if (studentCount) {
      console.log("ok");
      let filteredData;
      if (this.state.moreFilter == true) {
        console.log("ok");
        this.setState({ showLoader: false });
        if (studentCount[0].block_name) {
          filteredData = studentCount.filter((x) =>
            x.hasOwnProperty("block_name")
          );
          console.log(filteredData, "filter");
        } else {
          filteredData = studentCount.filter((x) =>
            x.hasOwnProperty("class_name")
          );
          isClass = true;
          console.log(filteredData);
        }
      } else {
        console.log("enter");
        this.setState({ showLoader: false });

        console.log(studentCount.response[0], "0");
        if (studentCount.response[0].district_name) {
          filteredData = studentCount.response.filter((x) =>
            x.hasOwnProperty("district_name")
          );
          console.log(filteredData, "filter");
        } else if (
          studentCount.response[0].student_district_id ||
          studentCount.response[0].block_name
        ) {
          console.log("ok");
          if (this.state.filterDropdownId === "1") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("active_students")
            );
            console.log(filteredData, "activ");
          } else if (this.state.filterDropdownId === "2") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("unique_user")
            );
            console.log(filteredData, "activ");
          } else if (this.state.filterDropdownId === "4") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("avg_time")
            );
            console.log(filteredData, "activ");
          } else if (this.state.filterDropdownId === "0") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("block_name")
            );
            console.log(filteredData, "filter");
          } else {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("block_name")
            );
            console.log(filteredData, "filter");
          }
        } else {
          filteredData = studentCount.response.filter((x) =>
            x.hasOwnProperty("class_name")
          );
          isClass = true;
        }
      }
      filteredData.sort((a, b) => a.class_id - b.class_id);
      console.log(filteredData, "data");
      filteredData.map((x) => {
        if (x.hasOwnProperty("district_name")) {
          labels.push(x.district_name);
        } else if (x.hasOwnProperty("class_name")) {
          labels.push(x.class_name);
        } else {
          labels.push(x.block_name);
          console.log(labels, "labels");
        }

        if (
          this.state.filterDropdownId == "1" ||
          ("2" && this.state.moreFilter == false)
        ) {
          console.log(this.state.filterDropdownId, "drop");
          if (this.state.filterDropdownId == "4") {
            console.log(x.avg_time, "x");
            var ms = x.avg_time,
              min = Math.floor((ms / 1000 / 60) << 0);
            //sec = Math.floor((ms/1000) % 60);
            console.log(min);
            count.push(min);
            sum = sum + min;
          } else {
            count.push(x.count);
            sum = sum + x.count;
          }
        } else if (this.state.moreFilter == true) {
          console.log(x, "x");
          if (x.male_count) {
            console.log(x.male_count, "male");
            count.push(x.male_count);
            Extracount.push(x.female_count);
            otherCount.push(x.other_count);
            sum = sum + x.female_count + x.male_count + x.other_count;
            console.log(sum, "sum");
          }
          if (x.open_caste_count) {
            OpenNum.push(x.open_caste_count);
            OBCNum.push(x.OBC_caste_count);
            NTNum.push(x.NT_caste_count);
            OtherNum.push(x.OTHER_caste_count);
            SBCNum.push(x.SBC_caste_count);
            STNum.push(x.ST_caste_count);
            SCNum.push(x.SC_caste_count);
            VJNTNum.push(x.VJNT_caste_count);
            sum =
              sum +
              x.open_caste_count +
              x.OBC_caste_count +
              x.NT_caste_count +
              x.OTHER_caste_count +
              x.SBC_caste_count +
              x.ST_caste_count +
              x.SC_caste_count +
              x.VJNT_caste_count;
            console.log(sum, "sum");
          } else {
            console.log(x, "male");
            income1.push(x.fifty_to_one_lakh_income_count);
            income2.push(x.one_to_five_lakh_income_count);
            income3.push(x.five_to_ten_lakh_income_count);
            income4.push(x.above_10lakh_income_count);
            income0.push(x.belowfifty_thousand_income_count);
            sum =
              sum +
              x.fifty_to_one_lakh_income_count +
              x.one_to_five_lakh_income_count +
              x.five_to_ten_lakh_income_count +
              x.above_10lakh_income_count +
              x.belowfifty_thousand_income_count;
          }
        } else {
          count.push(x.count);
          sum = sum + x.count;
        }
        // if(this.state.maleCount == true)
        // {
        //  console.log(x.male_count,"male");
        //  count.push(x.male_count)
        //   sum = sum + x.male_count
        // }
        // else if(this.state.maleCount == true) {
        //   console.log(x.female_count,"female");
        //   count.push(x.female_count)
        //   sum = sum + x.female_count
        // }
      });
      this.setState({
        sumOfStudents: sum,
      });

      series =
        this.state.moreFilter == false
          ? [
              {
                name: isClass ? "class" : "Count",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ]
          : this.state.incomeFilter == true
          ? [
              {
                name: "",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " below 50k",
                data: income0,
                color: "orange",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " 50k-1L",
                data: income1,
                color: "blue",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " 1-5L",
                data: income2,
                color: "voilet",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "5-10L",
                data: income3,
                color: "green",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " above 10L",
                data: income4,
                color: "yellow",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ]
          : this.state.filterName == "Caste"
          ? [
              {
                name: "",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "Open",
                data: OpenNum,
                color: "orange",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " OBC",
                data: OBCNum,
                color: "blue",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " NT",
                data: NTNum,
                color: "voilet",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "Other",
                data: OtherNum,
                color: "green",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " SBC",
                data: SBCNum,
                color: "yellow",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "ST",
                data: STNum,
                color: "#8f32a8",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "SC",
                data: SCNum,
                color: "#32a897",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "VJNT",
                data: VJNTNum,
                color: "#32a852",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ]
          : [
              {
                name: " Male Count",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " Female Count",
                data: Extracount,
                color: "red",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " other Count",
                data: otherCount,
                color: "blue",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ];

      this.setState({
        stdWiseGraph: {
          title: {
            text: "",
          },
          xAxis: {
            categories: labels,
            crosshair: true,
          },
          yAxis: {
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text:
                this.state.filterDropdownId == "1"
                  ? "Active student count "
                  : this.state.filterDropdownId == "2"
                  ? "Unique Contacts"
                  : this.state.filterDropdownId == "4"
                  ? "avg time(in min)"
                  : "Student count",
            },
          },

          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          series: series,
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "column",
          },
        },
      });
      this.setState({
        ActiveStudentKey: false,
        uniqueContactKey: false,
        avgTimeGraph: false,
        filterName: "",
      });
    }
  };

  handleRowClick = (event, rowData) => {
    this.setState(
      {
        openDetailDialog: true,
      },
      () => {
        this.getStudentDetail(rowData.student_id);
      }
    );
  };

  getStudentDetail = (studentId) => {
    let param = {
      student_id: studentId,
    };
    this.setState({ loading: true });
    getStudentService()
      .getStudentCompleteProfile(param)
      .then((res) => {
        if (res.data && res.status == 200) {
          this.setState({ studentDetail: res.data.response }, () => {
            if (
              this.state.studentDetail &&
              this.state.studentDetail.subject_wise_data
            ) {
              let labels = [];
              let series = [];
              let count = [];

              this.state.studentDetail.subject_wise_data.map((x) => {
                labels.push(x.subject_name);
                count.push(this.millisecondToMinuteOnlyNum(x.timespend__sum));
              });

              series = [
                {
                  name: "Subjects",
                  data: count,
                  color: "#f7ac32",
                },
              ];

              this.setState({
                subjectWiseGraph: {
                  title: {
                    text: "",
                  },
                  xAxis: {
                    categories: labels,
                    crosshair: true,
                  },
                  yAxis: {
                    stackLabels: {
                      enabled: true,
                      align: "center",
                    },
                    visible: true,
                    title: { enabled: true, text: "Minutes" },
                  },
                  labels: {
                    items: [
                      {
                        html: "",
                        style: {
                          left: "50px",
                          top: "18px",
                        },
                      },
                    ],
                  },
                  series: series,
                  chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    width: (window.innerWidth - 260 - 120) / 2,
                    type: "column",
                  },
                },
                loading: false,
              });
            }
          });
        } else {
          this.setState({
            studentDetail: {},
            subjectWiseGraph: null,
            loading: false,
          });
        }
      });
  };

  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };

  onChangeHandle = (val, value) => {
    console.log(val, value, "val from onChangeHandle");
    this.setState({
      school: val,
    });
  };

  deleteReport = (id) => {
    getSuperAdminService()
      .deleteScheduleEmail(id)
      .then((res) => {
        console.log(res, "res");
        if (res) {
          this.setState(
            {
              messageMail: "Updated successfully",
            },
            () => {
              this.renderScheduleForMail();
            }
          );
        }
      });
  };
  scheduleReports = () => {
    let userEmail = JSON.parse(localStorage.getItem("user")).user_details.email;
    let userName = JSON.parse(localStorage.getItem("user")).user_details
      .full_name;
    console.log(this.state.selectedBlockReports, "jhb");
    let arr = this.state.selectedBlockReports;
    console.log(arr.length, "arr.length");
    const arrayToObject = (arr) => {
      const res = {};
      for (let i = 0; i < arr.length; i++) {
        const key = i;
        res[key] = arr[i];
        delete res[key]["id"];
      }
      return res;
    };
    console.log(arrayToObject(arr));
    let param = {
      email: this.state.EmailReport,
      name: this.state.NameForReport,
      mobile: this.state.ContactForReport,
      reports: arrayToObject(arr),
      designation: this.state.designationForReport,
      next_time: this.state.dateForEmail ? this.state.dateForEmail : "",
      frequency:
        this.state.frequency == 1
          ? "WEEKLY"
          : this.state.frequency == 2
          ? "MONTHLY"
          : "QUARTERLY",
      school_summary: this.state.reportName == 1 ? true : false,
      active: true,
      //student_list :this.state.reportName == 2 ? true : false,
    };
    console.log(param, "param");

    getSuperAdminService()
      .scheduleEmail(param)
      .then((data) => {
        console.log(data, "data");
        if (data) {
          this.setState(
            {
              messageMail: "Email scheduled successfully",
            },
            () => {
              this.renderScheduleForMail();
            }
          );
        }
      });
  };
  handleClassChange = (e) => {
    console.log(e.target.value, "block vals");
    let index = e.target.value.length;
    let blockArray = e.target.value;
    console.log(index, "blockArray", blockArray[index - 1]);
    const {
      target: { value },
    } = e;
    console.log(e, "e ", value);
    this.setState({
      person: typeof value === "string" ? value.split(",") : value,
    });
    console.log(e, "block val");
    let data = this.renderStateMenu("block");
    console.log(data, "block data");
    let blockname = "";
    data.map((res) => {
      if (res.props.value == blockArray[index - 1].toString()) {
        blockname = res.props.children;
      } else {
        return;
      }
    });
    console.log(blockname, "blockname");
    let array = [];
    let report = {
      student_district_id: this.state.districtReport,
      student_block_id: blockArray[index - 1].toString(),
      student_block_name: blockname,
    };
    this.state.selectedBlockReports.push(report);
    {
      console.log(this.state.selectedBlockReports, "selectedBlockReports");
    }
  };

  renderScheduleForMail = () => {
    getSuperAdminService()
      .getScheduleEmailList()
      .then((data) => {
        console.log(data, "Data");
        let objArr = data.data.response.map((d) => {
          console.log(d.reports, "report");
          let val = Object.values(d.reports);
          val.map((d) => {
            console.log(d.student_block_id, "ddd444");
            this.state.blockForCards.push(d.student_block_id);
          });
        });
        console.log(this.state.blockForCards, "obj");

        this.setState({
          mailData: data.data.response,
        });
      });
  };
  updateEmailSchedule = () => {
    let param = {};
    if (this.state.updateStatus == false) {
      console.log(
        this.state.frequency,
        this.state.EmailReport,
        this.state.selectedBlockReports,
        "data param"
      );
      let arr = this.state.selectedBlockReports;
      console.log(arr.length, "arr.length");
      const arrayToObject = (arr) => {
        const res = {};
        for (let i = 0; i < arr.length; i++) {
          const key = i;
          res[key] = arr[i];
          delete res[key]["id"];
        }
        return res;
      };
      console.log(arrayToObject(arr));
      let userID = JSON.parse(localStorage.getItem("user")).user_details
        .user_id;
      param = {
        email: this.state.EmailReport,
        reports: arrayToObject(arr),
        frequency:
          this.state.frequency == 1
            ? "WEEKLY"
            : this.state.frequency == 2
            ? "MONTHLY"
            : "QUARTERLY",
        active: this.state.scheduledStatus == "1" ? false : true,
        id: this.state.editID,
      };
      console.log(param, "param");
    } else {
      param = {
        reports: "",
        frequency:
          this.state.frequency == 1
            ? "WEEKLY"
            : this.state.frequency == 2
            ? "MONTHLY"
            : "QUARTERLY",
        email: this.state.updateStatusEmail,
        active: this.state.status == true ? false : true,
        id: this.state.updateStatusID,
      };
    }
    getSuperAdminService()
      .updateScheduleEmail(param)
      .then((res) => {
        console.log(res, "res");
        if (res) {
          this.setState(
            {
              messageMail: "Updated successfully",
              updateStatus: false,
            },
            () => {
              this.renderScheduleForMail();
            }
          );
        }
      });
  };

  topperList = () => {
    const { topperBlock, topperDist, toperState } = this.state;
    const param = {
      student_state_id: 1,
      class_id: "",
      student_district_id: topperDist || "",
      student_block_id: topperBlock || "",
    };
    getSuperAdminService()
      .getTopperList(param)
      .then((res) => {
        console.log(res.data.response, "data");
        let data = res.data.response;
        let array = [];

        data.map((res) => {
          console.log(res, "Res");
          let time1 = res.total_time / 1000;
          let roundOFF = Number(time1.toFixed());
          console.log(roundOFF, "time");
          let time = roundOFF / 60;
          console.log(time, "min");
          if (time > 60) {
            // Hour conversoin
            if (time / 60 > 1000) {
              time = time / 60;
              time = `${(time / 1000).toFixed(2)}K+ Hrs`;
            } else {
              time = `${(time / 60).toFixed(2)} Hr`;
            }

            console.log(time, "extSec");
          }
          let obj = {
            first_name: res.first_name,
            last_name: res.last_name,
            school_name: res.school_name,
            total_time: time,
            mobile: res.mobile,
          };
          array.push(obj);
        });
        console.log(array, "array");
        this.setState({
          toppers: array,
          showTopper: true,
        });
      });
  };
  handleRowClick = (event, rowData) => {
    console.log(rowData, "rowData");
    this.setState({
      topperName: rowData.first_name + rowData.last_name,
    });

    this.setState({
      dialogTopper: true,
    });
  };
  render() {
    let {
      baseAnalytics,
      currentTabIndex,
      stdWiseGraph,
      openDetailDialog,
      studentDetail,
      subjectWiseGraph,
      loading,
      openStudentReport,
    } = this.state;
    {
      console.log(this.state.selectedBlockReports, "selectedBlockReports");
    }
    return (
      <>
        <AlertDialog
          open={this.state.openAlert}
          title="Update?"
          text="Do you want to Update this Schedule?"
          onCancel={() => this.setState({ openAlert: false })}
          onConfirm={() => {
            this.updateEmailSchedule();
            this.setState({ openAlert: false });
          }}
        />
        <Dialog
          open={this.state.dialogEmail}
          onClose={() => {
            this.setState({
              dialogEmail: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {" Please write your email to send CSV report"}
          </DialogTitle>
          <DialogContent>
            <TextField
              type="text"
              label="Email "
              name="Email"
              onChange={(e) => {
                this.setState({
                  userEmail: e.target.value,
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.onStudentDataExport();
              }}
              color="primary"
            >
              Send
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  dialogEmail: false,
                });
              }}
              // color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showEmailModal}
          onClose={() => {
            this.setState({
              showEmailModal: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {" Please write your email to send CSV report"}
          </DialogTitle>
          <DialogContent>
            <TextField
              type="text"
              label="Email "
              name="Email"
              onChange={(e) => {
                this.setState({
                  userEmail: e.target.value,
                });
              }}
            />
            <div
              style={{
                marginTop: "2em",
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Select block
              </InputLabel>
              <Select
                style={{ width: "12em" }}
                multiple
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.person}
                onChange={(e) => this.multipleBLockEmail(e)}
              >
                {this.renderStateMenu("block")}
                {console.log(this.state.blockReport)}
              </Select>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.onSchoolDataExport();
              }}
              color="primary"
            >
              Send
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  showEmailModal: false,
                });
              }}
              // color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          style={{
            marginLeft: "40%",
            marginTop: "15%",
            width: "30%",
          }}
          open={this.state.dialogTopper}
          onClose={() => {
            this.setState({
              dialogTopper: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
          {" Toppers Details"}
        </DialogTitle> */}
          <Card>
            <CardHeader>Toppers Details</CardHeader>
            <CardBody>
              <div>Name : {this.state.topperName}</div>
              <div>Contact :</div>
              <div>parent name :</div>
            </CardBody>
          </Card>
        </Modal>
        <Snackbar
          autoHideDuration={5000}
          color={"success"}
          message={this.state.messageMail}
          close={false}
          place="br"
          closeNotification={() =>
            this.setState({
              messageMail: "",
            })
          }
          rtlActive={true}
          open={this.state.messageMail ? true : false}
        ></Snackbar>

        <GridItem xs={12} sm={12} md={12}>
          <div className={`row ${classes.headerText}`}>
            Student Analytics Data
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "initial",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <Card
              style={{ color: "#4d4f5c", marginRight: "2em", height: "11em" }}
              id="1"
              onClick={(e) => {
                this.anaylticsOfStudent(e.target.id);
              }}
            >
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                }}
              >
                Total user
              </h4>
              <h1>
                {this.state.showParents ? this.state.total_parents_count : ""}
              </h1>

              <PeopleIcon
                className={classes.copySvg}
                style={{ display: "flex", justifyContent: "center" }}
              />
            </Card>
            {/* <Card
              style={{ color: "#4d4f5c", marginRight: "2em" }}
              id="2"
              onClick={(e) => {
                this.anaylticsOfStudent(e.target.id);
                console.log(e.target.id);
              }}
            >
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                }}
              >
                Total Students
              </h4>
              <h1>
                {this.state.showStudent
                  ? this.state.total_student_profile_count
                  : ""}
              </h1>
              <FaceIcon className={classes.copySvg} />
            </Card> */}
            <Card
              id="3"
              style={{ color: "#4d4f5c", marginRight: "2em" }}
              onClick={(e) => {
                this.anaylticsOfStudent(e.target.id);

                console.log(e.target.id);
              }}
            >
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                }}
              >
                Total time spent
              </h4>
              <h1 style={{ fontSize: "2.5em" }}>
                {this.state.showTime ? this.state.total_app_time : ""}
              </h1>
              <AccessTimeIcon className={classes.copySvg} />
            </Card>
            <Card
              id="4"
              style={{
                color: "#4d4f5c",
                marginRight: "2em",
                fontFamily: "Poppins",
              }}
              onClick={(e) => {
                this.anaylticsOfStudent(e.target.id);
                console.log(e.target.id);
              }}
            >
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                }}
              >
                {" "}
                Total active user
              </h4>
              <h1>
                {this.state.showActiveStudent
                  ? this.state.total_active_student
                  : ""}
              </h1>
              <PeopleIcon className={classes.copySvg} />
            </Card>
            <Card
              id="5"
              style={{ color: "#4d4f5c", marginRight: "2em" }}
              onClick={(e) => {
                console.log(e.target.id);
                this.anaylticsOfStudent(e.target.id);
              }}
            >
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                }}
              >
                Avg time spent
              </h4>
              <h1 style={{ color: "#4d4f5c", marginLeft: "3px" }}>
                {this.state.showAvgTime ? this.state.average_time_on_app : ""}
              </h1>
              <AccessTimeIcon className={classes.copySvg} />
            </Card>
            <Card id="6" style={{ color: "#4d4f5c", marginRight: "2em" }}>
              <TextField
                style={{
                  width: "7em",
                }}
                type="text"
                label="Time(hr)"
                name="number"
                onChange={(e) => {
                  this.getManualActivCount(e.target.value);

                  console.log(e.target.value, "val");
                }}
              />
              <div
                style={{
                  fontFamily: "Poppins",
                  padding: "3px",
                }}
              >
                Active student :{" "}
                {this.state.FilterActiveCount
                  ? this.state.FilterActiveCount
                  : ""}
                <br />
                Avg time(H:M:S):{" "}
                {this.state.FilterAvg ? this.state.FilterAvg : ""}
              </div>
              {/* <h4 style={{ color:"#4d4f5c" , marginLeft:"3px"}}>Active student : {this.state.showAvgTime ? this.state.average_time_on_app:"" }</h4>
        <h4 style={{ color:"#4d4f5c" , marginLeft:"3px"}}>Avg time :{this.state.showAvgTime ? this.state.average_time_on_app:"" }</h4>
         */}
            </Card>
          </div>
          <hr />
          <Paper>
            <Tabs
              value={currentTabIndex}
              onChange={this.handleTabChange}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Student Chart " />
              <Tab label="Download  Report" />
              <Tab label="Schedule Report" />
              <Tab label="Topper List" />
            </Tabs>
          </Paper>

          <TabPanel
            value={currentTabIndex}
            index={0}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Card>
              <CardBody>
                <div>
                  <div className="row">
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl style={styles.dropdown}>
                          <InputLabel id="demo-simple-select-label">
                            Select State
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={this.state.stateForGraph}
                            onChange={(e) => {
                              this.setState(
                                {
                                  stateForGraph: e.target.value,
                                  districtForGraph: "",
                                  blockForGraph: "",
                                },
                                () => {
                                  this.getStudentDataForChart();
                                  this.getDistrictsByState(
                                    this.state.stateForGraph
                                  );
                                }
                              );
                            }}
                          >
                            {this.renderStateMenu("state")}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl style={styles.dropdown}>
                          <InputLabel id="demo-simple-select-label">
                            Select District
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label2"
                            id="demo-controlled-open-select2"
                            value={this.state.districtForGraph}
                            onChange={(e) => {
                              this.setState(
                                {
                                  districtForGraph: e.target.value,
                                  blockForGraph: "",
                                  filterDropdownId: "",
                                  moreCount: "",
                                  showLoader: true,
                                  moreFilter: false,
                                },
                                () => {
                                  //this.getStudentDataForChart()
                                  this.getStudentBlock(
                                    this.state.districtForGraph
                                  );
                                }
                              );
                            }}
                          >
                            {this.renderStateMenu("district")}
                            {this.state.showLoader ? (
                              <CircularProgress
                                style={{ marginLeft: " 40em" }}
                              />
                            ) : (
                              ""
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-4">
                      <div style={styles.inputWrapper}>
                        <FormControl style={styles.dropdown}>
                          <InputLabel id="demo-simple-select-label">
                            Select Block
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label2"
                            id="demo-controlled-open-select2"
                            value={this.state.blockForGraph}
                            onChange={(e) => {
                              this.setState(
                                {
                                  blockForGraph: e.target.value,
                                  filterDropdownId: "",
                                  showLoader: true,
                                  moreFilter: false,
                                  moreCount: "",
                                },
                                () => {
                                  this.getStudentDataForChart();
                                }
                              );
                            }}
                          >
                            {this.renderStateMenu("block")}
                            {this.state.showLoader ? (
                              <CircularProgress
                                style={{ marginLeft: " 40em" }}
                              />
                            ) : (
                              ""
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div>
                      {this.state.districtForGraph ||
                      this.state.blockForGraph ? (
                        <div>
                          <div className="row">
                            <div className="col-4">
                              <FormControl>
                                <InputLabel id="demo-simple-select-label">
                                  filter
                                </InputLabel>
                                <Select
                                  labelId="demo-controlled-open-select-label2"
                                  id="demo-controlled-open-select2"
                                  style={{ width: "7em" }}
                                  label="filter"
                                  value={this.state.filterDropdownId}
                                  onChange={(e) => {
                                    console.log(e.target.value, "E");
                                    this.setState(
                                      {
                                        filterDropdownId: e.target.value,
                                        showLoader: true,
                                        filterName:
                                          e.target.value == 3
                                            ? "gender"
                                            : e.target.value == 6
                                            ? "Caste"
                                            : "income",
                                        ActiveStudentKey:
                                          e.target.value == 1 ? true : false,
                                        uniqueContactKey:
                                          e.target.value == 2 ? true : false,
                                        moreFilter:
                                          e.target.value == 3 ||
                                          e.target.value == 5 ||
                                          e.target.value == 6
                                            ? true
                                            : false,
                                        avgTimeGraph:
                                          e.target.value == 4 ? true : false,
                                        incomeFilter:
                                          e.target.value == 5 ? true : false,
                                      },
                                      () => {
                                        this.getStudentDataForChart();
                                      }
                                    );
                                    // this.state.ondropdownchange()
                                  }}
                                >
                                  <MenuItem value={1}>Active student</MenuItem>
                                  <MenuItem value={2}>Unique Contacts</MenuItem>
                                  <MenuItem value={3}>Gender</MenuItem>
                                  <MenuItem value={4}>Avg Time</MenuItem>
                                  <MenuItem value={5}>Income</MenuItem>
                                  <MenuItem value={6}>Caste</MenuItem>
                                  <MenuItem value={0}>student count</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-4">
                              {/* <FormControl >
                     <InputLabel id="demo-simple-select-label">Select gender</InputLabel>
                       <Select
                         labelId="demo-controlled-open-select-label2"
                         id="demo-controlled-open-select2"
                         style={{width:"7em"}}
                         label="Gender"
                          value={this.state.moreCount}
                         onChange={(e)=>{
                           this.setState({
                             moreFilter : true,
                             moreCount: e.target.value 
                            
                           },
                           ()=>{
                             this.getStudentDataForChart()
                           })
                         }}
                      >
                            <MenuItem value={10}>Male</MenuItem>
                            <MenuItem value={20}>Female</MenuItem>
                            <MenuItem value={30}>Remove filter</MenuItem>
                       </Select>
                       
                   </FormControl> */}
                            </div>
                            {/* <div className="col-4">
                   <FormControl >
                     <InputLabel id="demo-simple-select-label">Cast</InputLabel>
                       <Select
                         labelId="demo-controlled-open-select-label2"
                         id="demo-controlled-open-select2"
                         style={{width:"7em"}}
                         label="Cast"
                        //  value={this.state.filterDropdownId}
                        onChange={()=>{
                          this.setState({
                            moreFilter : true
                          })
                        }}
                         
                      >
                            <MenuItem value={1}>Open</MenuItem>
                            <MenuItem value={2}>Reserved</MenuItem>
                            <MenuItem value={2}>Remove filter</MenuItem>
                       </Select>
                       
                   </FormControl>
                   </div> */}
                            {this.state.showLoader ? (
                              <CircularProgress
                                style={{ marginLeft: " 40em" }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {this.getBlockName(this.state.blockForGraph) !== "" ? (
                      <>
                        {/* <Button
                      color="primary"
                      variant="contained"
                      style={{
                        marginTop: "1em",
                        height: "2.5em",
                        width: "2.5em",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        // this.getIDwiseCount()
                      }}>Filter</Button> */}
                        <div
                          style={{
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "20px",
                          }}
                        >
                          Showing data for :{" "}
                          {this.getBlockName(this.state.blockForGraph)}
                        </div>
                        <div
                          style={{
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "20px",
                          }}
                        >
                          {this.state.filterDropdownId == "4"
                            ? "Total Time :"
                            : "Total Student : "}{" "}
                          {this.state.sumOfStudents}
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          marginLeft: "330px",
                          marginTop: "5px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        Total Student :{this.state.sumOfStudents}
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={stdWiseGraph}
                    ></HighchartsReact>
                  </div>
                </div>
              </CardBody>
            </Card>
          </TabPanel>

          <TabPanel
            value={currentTabIndex}
            index={1}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div className="col-4">
                <div className={classes.wrap}>
                  <div className={classes.search}>
                    <input
                      type="text"
                      className={classes.searchTerm}
                      placeholder="Search by student name"
                      value={this.state.searchText}
                      onChange={(e) => this.onSearchStudent(e)}
                    />
                    <button type="submit" className={classes.searchButton}>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-2">
                {/* <IconButton
              variant={this.state.checked ? "contained" : "outlined"}
              //onClick={() => this.handleChange()}
              aria-label="filter"
              title="filter"
              color={this.state.checked ? "primary" : "secondary"}
              style={{ background: this.state.checked ? "#3B86FF 0% 0% no-repeat padding-box" : "", float: "right" }}
            > */}
                {/* <FilterListIcon /> */}
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" className="bi bi-funnel" viewBox="0 0 16 16" stroke="black" strokeWidth="0">
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" className={classes.filterIcon} />
              </svg> */}
                {/* {!this.state.checked ? (<div className={[classes.filterIcon, classes.filterOriginal].join(" ")}></div>) : (<div className={[classes.filterIcon, classes.filterOpened].join(" ")}></div>)}
            </IconButton> */}
              </div>
            </div>
            <div xs={12} sm={12} md={12} style={classes.filterContainer}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <div style={styles.inputWrapper}>
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select State
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.state.value}
                        onChange={(e) => {
                          this.setState(
                            {
                              state: {
                                value: e.target.value,
                                helperText: null,
                              },
                            },
                            () => {
                              this.getDistrictsByState(this.state.state.value);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("state")}
                      </Select>
                    </FormControl>
                    {/* <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">Select Board</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={this.state.board}
                      onChange={(e) => {
                        this.setState(
                          {
                            board: e.target.value,
                          },
                          () => {
                            this.getDistrictByBoard();
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("board")}
                    </Select>
                  </FormControl> */}
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select District
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.district.value}
                        onChange={(e) => {
                          this.setState(
                            {
                              district: {
                                value: e.target.value,
                                helperText: null,
                              },
                              block: {
                                helperText: null,
                                value: "",
                              },
                              school: "",
                            },
                            () => {
                              this.getStudentBlock(this.state.district.value);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("district")}
                      </Select>
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select block
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.block.value}
                        onChange={(e) => {
                          this.setState(
                            {
                              BlockCsv: true,
                              showButton: true,
                              block: {
                                value: e.target.value,
                                helperText: null,
                              },
                            },
                            () => {
                              this.getSchools(this.state.block.value);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("block")}
                        {console.log(this.state.block)}
                      </Select>
                    </FormControl>

                    <FormControl style={classes.formControl}>
                      <Autocomplete
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        style={{ width: 300 }}
                        open={this.state.isSetAuto}
                        disableClearable
                        onOpen={() => {
                          this.setOpen(true);
                        }}
                        onClose={() => {
                          this.setOpen(false);
                        }}
                        onChange={(event, value) => {
                          this.setState({
                            schoolName: event.target.value,
                            AutoSchool_id: value.school_id,
                            showButton: true,
                            BlockCsv: false,
                            ShowSchoolSummary: true,
                          });
                          console.log("e val", event.target.value);
                          this.onChangeHandle(value.school_id, value);
                        }}
                        getOptionLabel={(option) => option.school_name}
                        options={this.state.filteredSchools}
                        loading={loading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search School "
                            style={{ marginTop: "1em", width: "24em" }}
                            onChange={(ev) => {
                              console.log(ev.target.value, "ev");

                              this.getSchools(
                                this.state.block.value,
                                ev.target.value
                              );
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                      {console.log(this.state.filteredSchools)}
                      {console.log(this.state.AutoSchool_id, "id")}
                    </FormControl>

                    {/* <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">Select School</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      //value={this.state.school}
                      onChange={(e) => {
                        this.setState(
                          {
                            school: e.target.value,
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("school")}
                    </Select>
                    {console.log(this.state.school,"id")}
                  </FormControl> */}
                    {/* <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Select Subject
                    </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.subject}
                    onChange={(e) => {
                      this.setState({
                        subject: e.target.value,
                      });
                    }}
                  >
                    {this.renderStateMenu("subject")}
                  </Select>
                </FormControl> */}
                    {/* <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Teacher
                      </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.teacher}
                    onChange={(e) => {
                      this.setState({
                        teacher: e.target.value,
                      });
                    }}
                  >
                    {this.renderStateMenu("user")}
                  </Select>
                </FormControl> */}
                  </div>
                </CardContent>
                <CardActions className={classes.filterActions}>
                  {/* <Button
                  variant="outlined"
                  onClick={() =>
                    this.setState({ checked: false }, () => {
                      this.clearFilter();
                    })
                  }
                >
                  Cancel
                </Button> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={this.isAllFilterSelected("CSV")}
                    onClick={() =>
                      // this.state.BlockCsv ?  this.onSchoolDataExport() : this.onStudentDataExport()
                      // this.onStudentDataExport()
                      this.setState({
                        dialogEmail: true,
                      })
                    }
                  >
                    Export list of all students
                  </Button>
                  &nbsp; OR &nbsp;
                  {/* <Button
                  variant="contained"
                  color="primary"
                  disabled={this.isAllFilterSelected("VIEW")}
                  onClick={() => {
                    this.setState(
                      {
                        page: 1,
                        isApply: true
                      },
                      () => {
                        this.tableRef.current.onQueryChange();
                      }
                    );
                  }}
                >
                  View
                </Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.showButton == false ? true : false}
                    onClick={() => {
                      this.setState(
                        {
                          page: 1,
                          isApply: true,
                          showLoader: true,
                        },
                        () => {
                          this.getSummary();
                          // this.tableRef.current.onQueryChange();
                        }
                      );
                    }}
                  >
                    {" "}
                    View Summary
                  </Button>
                  {this.state.showLoader ? <CircularProgress /> : ""}
                </CardActions>
              </Card>
            </div>

            <div className="row">
              {this.state.showMaterialtable == true ? (
                <MaterialTable
                  onRowClick={this.handleRowClick}
                  title={""}
                  tableRef={this.tableRef}
                  columns={this.state.column}
                  data={(query) => {
                    console.log("QUERY---", query, this.tableRef);
                    return this.getStudentsData(query);
                  }}
                  // actions={[
                  //   {
                  //     icon: "remove_red_eye",
                  //     tooltip: "Preview Chapter",c
                  //     onClick: (event, rowData) => {
                  //         this.props.history.push(
                  //           `chapter/preview/${rowData.chapter_id}`,
                  //           { chapter: rowData }
                  //         );
                  //     },
                  //   },
                  // ]}
                  options={{
                    sorting: true,
                    search: false,
                    actionsColumnIndex: -1,
                    filtering: false,
                    pageSize: 10,
                  }}
                  localization={{
                    toolbar: { searchPlaceholder: "Search student" },
                    body: {
                      emptyDataSourceMessage:
                        "Please select filters to view records",
                    },
                  }}
                />
              ) : (
                ""
              )}
              {/* {
             this.state.showMaterialtable2 == true ? <MaterialTable

          onRowClick={this.handleRowClick}
          title={""}
          tableRef={this.tableRef}
          columns={this.state.columnForBlock}
          data={(query) => {
            console.log("QUERY---", query, this.tableRef);
            return this.getStudentsData(query);
          }}

          options={{
            sorting: true,
            search: false,
            actionsColumnIndex: -1,
            filtering: false,
            pageSize: 10,
          }}
          localization={{
            toolbar: { searchPlaceholder: "Search student" },
            body: { emptyDataSourceMessage: 'Please select filters to view records' }
          }}
        /> : ""} */}
            </div>
            {/* {this.setState({ showMaterialtable : false})} */}
          </TabPanel>
          <TabPanel
            value={currentTabIndex}
            index={2}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div className="col-4">
                <div className={classes.wrap}>
                  <div className={classes.search}>
                    {/* <input type="text" className={classes.searchTerm} placeholder="Search by student name"
                  value={this.state.searchText} onChange={(e) => this.onSearchStudent(e)} /> */}
                    {/* <button type="submit" className={classes.searchButton}>
                  <i className="fa fa-search"></i>
                </button> */}
                  </div>
                </div>
              </div>

              <div className="col-2">
                {/* <IconButton
              variant={this.state.checked ? "contained" : "outlined"}
              //onClick={() => this.handleChange()}
              aria-label="filter"
              title="filter"
              color={this.state.checked ? "primary" : "secondary"}
              style={{ background: this.state.checked ? "#3B86FF 0% 0% no-repeat padding-box" : "", float: "right" }}
            > */}
                {/* <FilterListIcon /> */}
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" className="bi bi-funnel" viewBox="0 0 16 16" stroke="black" strokeWidth="0">
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" className={classes.filterIcon} />
              </svg> */}
                {/* {!this.state.checked ? (<div className={[classes.filterIcon, classes.filterOriginal].join(" ")}></div>) : (<div className={[classes.filterIcon, classes.filterOpened].join(" ")}></div>)}
            </IconButton> */}
              </div>
            </div>
            <div xs={12} sm={12} md={12} style={classes.filterContainer}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <div style={styles.inputWrapper}>
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select State
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.stateReport}
                        onChange={(e) => {
                          this.setState(
                            {
                              stateReport: e.target.value,
                            },
                            () => {
                              this.getDistrictsByState(this.state.stateReport);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("state")}
                      </Select>
                    </FormControl>
                    {/* <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">Select Board</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={this.state.board}
                      onChange={(e) => {
                        this.setState(
                          {
                            board: e.target.value,
                          },
                          () => {
                            this.getDistrictByBoard();
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("board")}
                    </Select>
                  </FormControl> */}
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select District
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.districtReport}
                        onChange={(e) => {
                          console.log(e.target, "iddd");
                          this.setState(
                            {
                              districtReport: e.target.value,
                              blockReport: [],
                            },
                            () => {
                              this.getStudentBlock(this.state.districtReport);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("district")}
                        {console.log(this.renderStateMenu("district"), "dis")}
                      </Select>
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select block
                      </InputLabel>
                      <Select
                        multiple
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.person}
                        onChange={(e) => this.handleClassChange(e)}
                      >
                        {this.renderStateMenu("block")}
                        {console.log(this.state.blockReport)}
                      </Select>
                    </FormControl>

                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        frequency
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        style={{ width: "70%" }}
                        label="frequency"
                        value={this.state.frequency}
                        onChange={(e) => {
                          console.log(e.target.value, "E");
                          this.setState({
                            frequency: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={1}>weekly Report</MenuItem>
                        <MenuItem value={2}>Monthly Report</MenuItem>
                        <MenuItem value={3}>Quaterly Report</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <TextField
                        style={{ width: "50%" }}
                        type="email"
                        label="Email"
                        name="Email"
                        value={this.state.EmailReport}
                        onChange={(e) => {
                          this.setState({
                            EmailReport: e.target.value,
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <TextField
                        style={{ width: "50%" }}
                        type="text"
                        label="Name"
                        name="Name"
                        value={this.state.NameForReport}
                        onChange={(e) => {
                          this.setState({
                            NameForReport: e.target.value,
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <TextField
                        style={{ width: "50%" }}
                        type="text"
                        label="designation"
                        name="designation"
                        value={this.state.designationForReport}
                        onChange={(e) => {
                          this.setState({
                            designationForReport: e.target.value,
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <div>
                        <DatePicker
                          format="y-MM-dd"
                          value={this.state.date}
                          onChange={(e) => {
                            let Stringdate = new Date(e).toISOString();
                            let filterDate = Stringdate.replace("T", " ");
                            let data = filterDate.replace("Z", "0");
                            console.log(data);
                            this.setState({
                              date: e,
                              dateForEmail: data,
                            });
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select Report
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        onChange={(e) => {
                          this.setState({
                            reportName: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={1}>SChool List</MenuItem>
                        <MenuItem value={2}>Student List</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl style={styles.dropdown}>
                      <TextField
                        style={{ width: "50%" }}
                        type="number"
                        label="Contact No"
                        name="Contact No"
                        value={this.state.ContactForReport}
                        onChange={(e) => {
                          this.setState({
                            ContactForReport: e.target.value,
                          });
                        }}
                      />
                    </FormControl>
                    {/* <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">Select School</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      //value={this.state.school}
                      onChange={(e) => {
                        this.setState(
                          {
                            school: e.target.value,
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("school")}
                    </Select>
                    {console.log(this.state.school,"id")}
                  </FormControl> */}
                    {/* <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Select Subject
                    </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.subject}
                    onChange={(e) => {
                      this.setState({
                        subject: e.target.value,
                      });
                    }}
                  >
                    {this.renderStateMenu("subject")}
                  </Select>
                </FormControl> */}
                    {/* <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Teacher
                      </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.teacher}
                    onChange={(e) => {
                      this.setState({
                        teacher: e.target.value,
                      });
                    }}
                  >
                    {this.renderStateMenu("user")}
                  </Select>
                </FormControl> */}
                  </div>
                </CardContent>
                <CardActions className={classes.filterActions}>
                  {/* <Button
                  variant="outlined"
                  onClick={() =>
                    this.setState({ checked: false }, () => {
                      this.clearFilter();
                    })
                  }
                >
                  Cancel
                </Button> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    //  disabled={this.isAllFilterSelected("CSV")}
                    onClick={() => this.scheduleReports()}
                  >
                    Send Reports
                  </Button>
                  {/* <Button
                  variant="contained"
                  color="primary"
                  disabled={this.isAllFilterSelected("VIEW")}
                  onClick={() => {
                    this.setState(
                      {
                        page: 1,
                        isApply: true
                      },
                      () => {
                        this.tableRef.current.onQueryChange();
                      }
                    );
                  }}
                >
                  View
                </Button> */}
                </CardActions>
              </Card>
            </div>
            <div>
              <div>
                <h3>Schedule List</h3>
                {this.state.mailData
                  ? this.state.mailData.map((res) => {
                      return (
                        <>
                          <Card style={{ width: "100%" }}>
                            <CardHeader>
                              <Delete
                                style={{
                                  marginLeft: "95%",
                                }}
                                onClick={(e) => {
                                  console.log(res.id, "e");
                                  this.deleteReport(res.id);
                                }}
                              />
                            </CardHeader>
                            <CardContent>
                              <dl id={res.id}>
                                <dt>
                                  Name :{console.log(res.id, "id")}
                                  {res.name}
                                </dt>
                                <dd>
                                  Designation :{console.log(res.id, "id")}
                                  {res.designation}
                                </dd>
                                <dd>
                                  Mobile No :{console.log(res.id, "id")}
                                  {res.mobile}
                                </dd>
                                <dd>
                                  Email -{" "}
                                  {this.state.editSchedule &&
                                  res.id == this.state.editID ? (
                                    <FormControl
                                      className={classes.formControl}
                                      style={{ paddingLeft: "20px" }}
                                    >
                                      <TextField
                                        //id="standard-basic"
                                        id="outlined-basic"
                                        //label={res.email}
                                        placeholder={res.email}
                                        type="text"
                                        onChange={(e) => {
                                          this.setState({
                                            EmailReport: e.target.value,
                                          });
                                        }}
                                      />
                                    </FormControl>
                                  ) : (
                                    res.email
                                  )}
                                </dd>
                                <dd>
                                  status -{" "}
                                  {this.state.editSchedule &&
                                  res.id == this.state.editID ? (
                                    <FormControl
                                      className={classes.formControl}
                                      style={{ paddingLeft: "20px" }}
                                    >
                                      <Select
                                        labelId="demo-controlled-open-select-label2"
                                        id="demo-controlled-open-select2"
                                        //label={res.email}
                                        label={
                                          res.active == true
                                            ? "Active"
                                            : "Inactive"
                                        }
                                        type="text"
                                        style={{ width: "15em" }}
                                        onChange={(e) => {
                                          this.setState({
                                            scheduledStatus: e.target.value,
                                          });
                                        }}
                                      >
                                        <MenuItem value={1}>
                                          Deactivate
                                        </MenuItem>
                                        <MenuItem value={2}>activate</MenuItem>
                                      </Select>
                                      <helperText>
                                        (To update the status fill email address
                                        only)
                                      </helperText>
                                    </FormControl>
                                  ) : res.active == true ? (
                                    "Active"
                                  ) : (
                                    "Inactive"
                                  )}
                                </dd>
                                <dd>
                                  Freuqency-{" "}
                                  {this.state.editSchedule &&
                                  res.id == this.state.editID ? (
                                    <FormControl
                                      className={classes.formControl}
                                    >
                                      {/* <InputLabel id="demo-simple-select-label">filter</InputLabel> */}
                                      <Select
                                        labelId="demo-controlled-open-select-label2"
                                        id="demo-controlled-open-select2"
                                        style={{ width: "15em" }}
                                        label={res.frequency}
                                        onChange={(e) => {
                                          console.log(e.target.value, "E");
                                          this.setState({
                                            frequency: e.target.value,
                                          });
                                        }}
                                      >
                                        <MenuItem value={1}>
                                          weekly Report
                                        </MenuItem>
                                        <MenuItem value={2}>
                                          Monthly Report
                                        </MenuItem>
                                        <MenuItem value={3}>
                                          Quaterly Report
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    res.frequency
                                  )}
                                </dd>
                                <dd>
                                  Blocks-{" "}
                                  {this.state.editSchedule &&
                                  res.id == this.state.editID ? (
                                    <>
                                      <FormControl style={styles.dropdown}>
                                        <InputLabel id="demo-simple-select-label">
                                          Select State
                                        </InputLabel>
                                        <Select
                                          labelId="demo-controlled-open-select-label"
                                          id="demo-controlled-open-select"
                                          value={this.state.stateReport}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                stateReport: e.target.value,
                                              },
                                              () => {
                                                this.getDistrictsByState(
                                                  this.state.stateReport
                                                );
                                              }
                                            );
                                          }}
                                        >
                                          {this.renderStateMenu("state")}
                                        </Select>
                                      </FormControl>
                                      <FormControl style={styles.dropdown}>
                                        <InputLabel id="demo-simple-select-label">
                                          Select District
                                        </InputLabel>
                                        <Select
                                          labelId="demo-controlled-open-select-label2"
                                          id="demo-controlled-open-select2"
                                          value={this.state.districtReport}
                                          onChange={(e) => {
                                            console.log(e.target, "iddd");
                                            this.setState(
                                              {
                                                districtReport: e.target.value,
                                                blockReport: [],
                                              },
                                              () => {
                                                this.getStudentBlock(
                                                  this.state.districtReport
                                                );
                                              }
                                            );
                                          }}
                                        >
                                          {this.renderStateMenu("district")}
                                          {console.log(
                                            this.renderStateMenu("district"),
                                            "dis"
                                          )}
                                        </Select>
                                      </FormControl>
                                      <FormControl style={styles.dropdown}>
                                        <InputLabel id="demo-simple-select-label">
                                          Select block
                                        </InputLabel>
                                        <Select
                                          multiple
                                          labelId="demo-controlled-open-select-label2"
                                          id="demo-controlled-open-select2"
                                          value={this.state.blockReport}
                                          onChange={(e) =>
                                            this.handleClassChange(e)
                                          }
                                        >
                                          {this.renderStateMenu("block")}
                                          {console.log(this.state.blockReport)}
                                        </Select>
                                      </FormControl>
                                    </>
                                  ) : (
                                    Object.values(res.reports).map((d) => {
                                      console.log(d.student_block_id, "ddd444");
                                      return (
                                        <span> {d.student_block_name} , </span>
                                      );
                                    })
                                  )}
                                </dd>
                              </dl>
                            </CardContent>
                            <CardFooter>
                              {this.state.editSchedule == false ? (
                                <>
                                  {" "}
                                  <Edit
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      console.log(res.id, "e");
                                      this.setState({
                                        editSchedule: true,
                                        editID: res.id,
                                        updateStatus: false,
                                      });
                                    }}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          res.active == true ? true : false
                                        }
                                        color="primary"
                                        onClick={(e) => {
                                          console.log(e, "e");
                                          this.setState({
                                            updateStatus: true,
                                            updateStatusEmail: res.email,
                                            openAlert: true,
                                            updateStatusID: res.id,
                                            status: res.active,
                                          });
                                        }}
                                        name={
                                          res.active == true
                                            ? "Active"
                                            : "Inactive"
                                        }
                                      />
                                    }
                                    label={
                                      res.active == true ? "Active" : "Inactive"
                                    }
                                  />
                                </>
                              ) : (
                                ""
                              )}
                              {this.state.editSchedule &&
                              res.id == this.state.editID ? (
                                <>
                                  <div>
                                    <Check
                                      color="primary"
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "3em",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          editSchedule: false,
                                          openAlert: true,
                                        });
                                      }}
                                    />
                                    <Clear
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "3em",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          editSchedule: false,
                                        });
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </CardFooter>
                          </Card>
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value={currentTabIndex}
            index={3}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select State
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={this.state.toperState}
                      onChange={(e) => {
                        this.setState(
                          {
                            toperState: e.target.value,
                          },
                          () => {
                            this.getDistrictsByState(e.target.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("state")}
                    </Select>
                  </FormControl>

                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select District
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.topperDist}
                      onChange={(e) => {
                        this.setState(
                          {
                            topperDist: e.target.value,
                          },
                          () => {
                            this.getStudentBlock(this.state.topperDist);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("district")}
                    </Select>
                  </FormControl>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select block
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.topperBlock}
                      onChange={(e) => {
                        this.setState(
                          {
                            topperBlock: e.target.value,
                          },
                          () => {
                            this.getSchools(this.state.topperBlock);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("block")}
                      {console.log(this.state.block)}
                    </Select>
                  </FormControl>
                </div>
              </CardContent>
              <CardActions className={classes.filterActions}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.topperList();
                  }}
                >
                  Get List
                </Button>
              </CardActions>
            </Card>
            {this.state.showTopper ? (
              <MaterialTable
                columns={[
                  { title: "first_name", field: "first_name" },
                  { title: "last_name ", field: "last_name" },
                  { title: "total_time", field: "total_time" },
                  {
                    title: "school_name",
                    field: "school_name",
                  },
                  {
                    title: "Contact No",
                    field: "mobile",
                  },
                ]}
                data={this.state.toppers}
                //onRowClick={this.handleRowClick}
                title="Chapter Analysis"
              ></MaterialTable>
            ) : null}
          </TabPanel>
          <Modal
            open={this.state.openBlockreport}
            onClose={() => this.setState({ openBlockreport: false })}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {this.state.openBlockreport == true ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Card
                  style={{
                    height: "48em",
                    width: "53em",
                    border: "1px",
                    boxShadow: "1px 3px 1px #93a2ed",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div
                      style={{ marginLeft: "90%" }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          openBlockreport: false,
                          openSchoolData: false,
                        });
                      }}
                    >
                      <Cancel />
                    </div>
                    <h3
                      style={{
                        marginTop: "8px",
                        borderBottom: "3px solid blue",
                        marginTop: "1em",
                      }}
                    >
                      Schools Detail
                    </h3>
                  </div>

                  <div className="row">
                    <CardBody>
                      <Card style={styles.summary}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          District name :{this.state.districtorSumaary}
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Block name : {this.state.BlockForSummary}
                        </div>
                      </Card>
                      <Card style={styles.summary}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Total Schools in Block : {this.state.actualSchool}
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          V-School App user schools : {this.state.actualVschool}
                        </div>
                      </Card>
                      <Card style={styles.summary}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Total student profile in block :{" "}
                          {this.state.TotalBlockCount}
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Total Active Student :{this.state.activeCount}
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Total Unique Mobile Numbers :{" "}
                          {this.state.uniqueContact}
                        </div>
                      </Card>
                    </CardBody>
                    <div style={{ marginTop: "8px" }}>
                      <Button
                        style={{ marginLeft: "15em" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.setState(
                            {
                              openBlockreport: false,
                              showMaterialtable2: true,
                              showEmailModal: true,
                            },
                            () => {
                              // this.tableRef.current.onQueryChange();
                              //this.onSchoolDataExport()
                            }
                          );
                        }}
                      >
                        Email active school list
                      </Button>
                      <Button
                        style={{ marginLeft: "4em" }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          this.onDownloadList();
                        }}
                      >
                        Download List
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            ) : (
              ""
            )}
          </Modal>
          <Modal
            open={openStudentReport}
            onClose={() => this.setState({ openStudentReport: false })}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {this.state.openStudentReport == true ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Card
                  style={{
                    height: "35em",
                    width: "53em",
                    border: "1px",
                    boxShadow: "1px 3px 1px #93a2ed",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h3
                      style={{
                        marginTop: "8px",
                        borderBottom: "3px solid blue",
                        marginTop: "1em",
                      }}
                    >
                      Student Detail
                    </h3>
                  </div>

                  <div className="row">
                    <CardBody>
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          marginLeft: "7px",
                        }}
                      >
                        School name : {this.state.schoolForsummary}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          marginLeft: "7px",
                        }}
                      >
                        Udise No : {this.state.udiseForsumary}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          marginLeft: "7px",
                        }}
                      >
                        Total Count : {this.state.CountForsummary}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          marginLeft: "7px",
                        }}
                      >
                        Total Active students : {this.state.activeCount}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          marginLeft: "7px",
                        }}
                      >
                        Unique Contacts : {this.state.uniqueContact}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          marginLeft: "7px",
                        }}
                      >
                        District : {this.state.districtorSumaary}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          marginLeft: "7px",
                        }}
                      >
                        Block : {this.state.BlockForSummary}
                      </div>
                    </CardBody>
                    <div style={{ marginTop: "8px" }}>
                      <Button
                        style={{ marginLeft: "15em" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.setState(
                            {
                              openStudentReport: false,
                              showMaterialtable: true,
                              openSchoolData: true,
                              showMaterialtable2: false,
                            },
                            () => {
                              this.tableRef.current.onQueryChange();
                            }
                          );
                        }}
                      >
                        More Details
                      </Button>
                      <Button
                        style={{ marginLeft: "4em" }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          this.setState({
                            openStudentReport: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            ) : (
              ""
            )}
          </Modal>
          <Modal
            open={openDetailDialog}
            onClose={() => this.setState({ openDetailDialog: false })}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <span
                style={{
                  background: "white",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <h4>Student Detail</h4>
                </div>
                <hr />
                {studentDetail && Object.keys(studentDetail).length ? (
                  <>
                    <Card>
                      <div className="row">
                        <div className="col-6">
                          <label>Student name : </label>
                          {studentDetail?.student_details?.first_name}{" "}
                          {studentDetail?.student_details?.last_name}
                        </div>
                        <div className="col-6">
                          <label>Class : </label>
                          {studentDetail?.student_details?.class_name}
                        </div>
                        <div className="col-6">
                          <label>Medium : </label>
                          {studentDetail?.student_details?.medium_name}
                        </div>
                        <div className="col-6">
                          <label>State : </label>
                          {studentDetail?.student_details?.student_state_name}
                        </div>
                        <div className="col-6">
                          <label>District : </label>
                          {
                            studentDetail?.student_details
                              ?.student_district_name
                          }
                        </div>
                        <div className="col-6">
                          <label>Block : </label>
                          {studentDetail?.student_details?.student_block_name}
                        </div>
                        <div className="col-6">
                          <label>School : </label>
                          {studentDetail?.student_details?.school_name}
                        </div>
                        <div className="col-6">
                          <label>Parent's Name : </label>
                          {studentDetail?.parents_details?.full_name}
                        </div>
                        <div className="col-6">
                          <label>Parent's Contact : </label>
                          {studentDetail?.parents_details?.mobile}
                        </div>
                        <div className="col-6">
                          <label>Caste : </label>
                          {studentDetail?.parents_details?.caste}
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={subjectWiseGraph}
                      ></HighchartsReact>
                    </Card>
                  </>
                ) : (
                  <div
                    style={{
                      height: "80px",
                      width: "20vw",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Data Available
                  </div>
                )}
                <hr />
                <div>
                  <Button
                    style={{ float: "right" }}
                    variant="outlined"
                    onClick={() =>
                      this.setState({
                        openDetailDialog: false,
                        studentDetail: {},
                        subjectWiseGraph: null,
                      })
                    }
                  >
                    close{" "}
                  </Button>
                </div>
              </span>
            </div>
          </Modal>
        </GridItem>
      </>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
