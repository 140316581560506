export const USER_TYPE = {
    ADMIN: "ADMIN",
    SUPER_ADMIN: "SUPERADMIN",
    CONTENT_WRITER: "CONTENT-WRITER",
    HEADMASTER : "HEADMASTER",
    ADMINISTRATIVE : "ADMINISTRATIVE"
}

export const CHAPTER_STATUS = {
    IN_PROGRESS: "IN-PROGRESS",
    IN_REVIEW: "IN-REVIEW",
    PUBLISHED: "PUBLISHED",
    CHANGES_REQUIRED: "CHANGES-REQUIRED"
}