"use client";
import React, { useEffect, useState } from "react";
import "../Milestone.css";
import Pencil from "../icons/Pencil.png";
import Dot from "../icons/Dot.png";
import Sort from "../icons/Sort.png";
import { Link, useHistory } from "react-router-dom";
import { GrUp } from "react-icons/gr";
import { GrDown } from "react-icons/gr";
import { CircularProgress } from "@material-ui/core";
import { getSuperAdminService } from "service/service";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { RxCross2 } from "react-icons/rx";
import { IoCheckmarkSharp } from "react-icons/io5";

function MilestoneList({
  page,
  setMessage,
  setError,
  limit,
  offset,
  setOffset,
  count,
  setCount,
}) {
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(true);
  const [milestoneLoader, setMilestoneLoader] = useState(false);
  const [filterStringGlobal, setFilterStringGlobal] = useState("");
  const [allCreatedMilestoneList, setAllCreatedMilestoneList] = useState([]);
  // search
  const [searchByMilestone, setSearchByMilestone] = useState("");
  const [searchByTitle, setSearchByTitle] = useState("");
  const [searchByDate, setSearchByDate] = useState(null);
  const [searchByDateStr, setSearchByDateStr] = useState("");
  // sort
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("-");
  const [showSort, setShowSort] = useState(false);

  const handleDateChange = (date) => {
    setSearchByDate(date);

    if (date !== null) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      setSearchByDateStr(formattedDate);
    } else if (date === null) {
      setSearchByDateStr("");
    }
  };

  const showSortOptionsToggler = () => {
    setShowSort((prevState) => !prevState);
  };

  const handleSetSortOrder = (value) => {
    setSortOrder(value);
  };

  const handleSetSortBy = (value) => {
    setSortBy(value);
  };

  const handleShowFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  const getAllCreatedMilestone = async (filterString) => {
    try {
      setMilestoneLoader(true);
      const response = await getSuperAdminService().getAllCreatedMilestoneData(
        filterString
      );
      setAllCreatedMilestoneList(response.data.results);
      setCount(response.data.count);
      setMilestoneLoader(false);
    } catch (error) {
      setMilestoneLoader(false);
      console.error("Error fetching Milestone list", error);
    }
  };

  const disableMilestone = async (milestone_id) => {
    try {
      let response = await getSuperAdminService().deleteMilestone(milestone_id);
      setError(false);
      setMessage("Milestone disabled successfully");
      await getAllCreatedMilestone(filterStringGlobal);
    } catch (error) {
      setError(true);
      setMessage("Error disable milestone");
      console.error("Error disable milestone", error);
    }
  };

  const enableMilestone = async (milestone_id, payload) => {
    try {
      let response = await getSuperAdminService().updateMilestoneStatus(
        milestone_id,
        payload
      );
      setError(false);
      setMessage("Milestone enabled successfully");
      await getAllCreatedMilestone(filterStringGlobal);
    } catch (error) {
      setError(true);
      setMessage("Error enable milestone");
      console.error("Error enable milestone", error);
    }
  };

  useEffect(() => {
    const data = history.location.state?.coming;
    console.log(data, "12345");
  }, []);

  useEffect(() => {
    console.log(page, "1234qwe");
    let filterString = "";
    if (page !== "") {
      if (page === "ongoing") {
        filterString += `is_deleted=false&`;
      }
      if (page === "disabled") {
        filterString += `is_deleted=true&`;
      }
      if (searchByMilestone) {
        filterString += `milestone=${searchByMilestone}&`;
      }
      if (searchByTitle) {
        filterString += `search=${searchByTitle}&`;
      }
      if (searchByDateStr !== "") {
        filterString += `created_at=${searchByDateStr}&`;
      }
      let orderingParam = sortOrder + sortBy;
      setFilterStringGlobal(
        `${filterString}${
          "ordering=" + orderingParam + "&"
        }limit=${limit}&offset=${offset}`
      );
      getAllCreatedMilestone(
        `${filterString}${
          "ordering=" + orderingParam + "&"
        }limit=${limit}&offset=${offset}`
      );
    }
  }, [
    sortOrder,
    sortBy,
    offset,
    limit,
    page,
    searchByMilestone,
    searchByTitle,
    searchByDateStr,
  ]);

  useEffect(() => {
    setOffset(0);
  }, [page, limit, searchByMilestone, searchByTitle, searchByDateStr]);

  return (
    <>
      <div
        className="pb-4"
        onClick={() => {
          if (showSort == true) {
            setShowSort(false);
          }
        }}
      >
        <div className="primarLight p-3 milestoneFilter primaryPurple mb-3">
          <div
            className="d-flex justify-content-between"
            onClick={handleShowFilter}
          >
            <span className="title2 colorBlack">Milestone Filter</span>
            <div className="pe-2 pointerArrow">
              {showFilter ? <GrUp /> : <GrDown />}
            </div>
          </div>
          <div
            className={`${
              showFilter ? "" : "d-none"
            } d-flex justify-content-between pt-3`}
          >
            <div className="col-3">
              <select
                className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect "
                aria-label=".form-select-lg example"
                value={searchByMilestone}
                onChange={(e) => {
                  setSearchByMilestone(e.target.value);
                }}
              >
                <option value="" selected>
                  Search by Milestone Type
                </option>
                <option value="Total Chapter">Total Chapter</option>
                <option value="Total Reels Attempts">
                  Total Reels Attempts
                </option>
                {/* <option value="Total Points Earn">Total Points Earn</option>
                <option value="Daily Open">Daily Open</option> */}
              </select>
            </div>
            <div className="col-3">
              <input
                className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect "
                type="text"
                placeholder="Search by Milestone Title"
                value={searchByTitle}
                onChange={(e) => {
                  setSearchByTitle(e.target.value);
                }}
              />
            </div>
            <div className="col-3">
              <div className="whiteBackground inputSelect mb-3">
                <div className="ms-4 selectHeight">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="width100"
                      placeholder="Select Created Date"
                      margin="normal"
                      id="date-picker-dialog"
                      label=""
                      InputProps={{
                        disableUnderline: true,
                      }}
                      format="dd/MM/yyyy"
                      value={searchByDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="col-1">
              <button
                type="button"
                class="btn btn-secondary paddingButton"
                onClick={() => {
                  setSearchByMilestone("");
                  setSearchByTitle("");
                  setSearchByDate(null);
                  setSearchByDateStr("");
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div
            className="px-3 py-2 primarLight pointerArrow sortBtn"
            onClick={showSortOptionsToggler}
          >
            <img
              className="mt-1 pointerArrow"
              src={Sort}
              alt="Sort"
              width={30}
              height={30}
            />
          </div>

          <div className={`${showSort ? "" : "d-none"} challengeSort rounded`}>
            <div className="pb-3 bg-light rounded">
              <div className="crossBtn" onClick={showSortOptionsToggler}>
                <RxCross2 />
              </div>
              <div
                className="px-4 py-2 rounded sortElement d-flex"
                onClick={() => {
                  handleSetSortBy("created_at");
                }}
              >
                <div>
                  <span>Created date</span>
                </div>
                <div
                  className={`${sortBy === "created_at" ? "" : "d-none"} ps-2`}
                >
                  <IoCheckmarkSharp />
                </div>
              </div>
              <hr className=""></hr>
              <div
                className="px-4 py-2 rounded sortElement d-flex"
                onClick={() => {
                  handleSetSortOrder("");
                }}
              >
                <div>
                  <span>Ascending</span>
                </div>
                <div className={`${sortOrder === "" ? "" : "d-none"} ps-2`}>
                  <IoCheckmarkSharp />
                </div>
              </div>
              <div
                className="px-4 py-2 rounded sortElement d-flex"
                onClick={() => {
                  handleSetSortOrder("-");
                }}
              >
                <div>
                  <span>Descending</span>
                </div>
                <div className={`${sortOrder === "-" ? "" : "d-none"} ps-2`}>
                  <IoCheckmarkSharp />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4 pb-5 whiteBackground">
          <div className="d-flex">
            <div className="Regular12 colorGray col-1 ps-3">
              <span>Sr.No.</span>
            </div>
            <div className="Regular12 colorGray col-3">
              <span>Milestone Title</span>
            </div>
            <div className="Regular12 colorGray col-4">
              <span>Description</span>
            </div>
            <div className="Regular12 colorGray col-1">
              <span className="me-2">Created date</span>
            </div>
            <div className="d-flex justify-content-around Regular12 colorGray col-1 px-1">
              <span>Goal Achieved</span>
            </div>
            {page === "disabled" ? (
              <div className="Regular12 colorGray col-1 ps-4">
                <span>Status</span>
              </div>
            ) : (
              <div className="Regular12 colorGray col-1 ps-4"></div>
            )}
            <div className="Regular12 colorGray col-1 px-3">
              <span>Action</span>
            </div>
          </div>
          <hr className=""></hr>
          <div
            className={`${
              count === 0 && milestoneLoader === false ? "" : "d-none"
            } Regular14 colorPrimaryBlack d-flex justify-content-center align-items-center pt-5 mt-5`}
          >
            <span className="pt-5 mt-4">No available data.</span>
          </div>
          {milestoneLoader === true ? (
            <div
              style={{
                marginLeft: "50%",
                marginTop: "10%",
              }}
            >
              <CircularProgress color="primary" size={40} />
            </div>
          ) : (
            <>
              {allCreatedMilestoneList.map((createdMilestone, index) => {
                return (
                  <div key={index + "milestone"}>
                    <div className="d-flex py-2">
                      <div className="Regular14 colorPrimaryBlack col-1 ps-4">
                        <span>{createdMilestone.sn}</span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-3 pe-3">
                        <span>{createdMilestone.title}</span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-4 pe-3">
                        <span>{createdMilestone.description}</span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-1">
                        <span>
                          {
                            createdMilestone.created_at.substring(8, 10) +
                              "-" +
                              createdMilestone.created_at.substring(5, 7) +
                              "-" +
                              createdMilestone.created_at.substring(0, 4)
                            // + " " +
                            // createdMilestone.created_at.substring(11, 16)
                          }
                        </span>
                      </div>
                      <div className="d-flex justify-content-around Regular14 colorPrimaryBlack col-1 px-1">
                        <span>{createdMilestone.achieved}</span>
                      </div>
                      {page === "disabled" ? (
                        <div className="Regular14 colorPrimaryBlack col-1 px-2">
                          <img
                            className=""
                            src={Dot}
                            alt="dot"
                            width={16}
                            height={16}
                          />
                          <span>Disabled</span>
                        </div>
                      ) : (
                        <div className="Regular14 colorPrimaryBlack col-1 px-2"></div>
                      )}
                      <div className="d-flex Regular14 colorPrimaryBlack col-1 px-3">
                        <Link
                          to={`/admin/milestones/edit-milestone/${createdMilestone._id}`}
                        >
                          <img
                            className="mt-1"
                            src={Pencil}
                            alt="Pencil"
                            width={20}
                            height={20}
                          />
                        </Link>
                        <div className="form-check form-switch ms-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            style={{ height: "22px", width: "40px" }}
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={!createdMilestone.is_deleted}
                            onClick={() => {
                              if (!createdMilestone.is_deleted) {
                                disableMilestone(createdMilestone._id);
                              } else if (createdMilestone.is_deleted) {
                                enableMilestone(createdMilestone._id, {
                                  is_deleted: false,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="primarLightColor m-0"></hr>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MilestoneList;
