import {
  Button,
  Card,
  FormHelperText,
  Input,
  CircularProgress,
  Box,
  Modal,
  Switch,
} from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import {
  getSuperAdminService,
  getContentWriterService,
  getHMService,
} from "./../../service/service";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change,
} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

import {
  AddBoxOutlined,
  ArrowBack,
  ArrowForward,
  Cancel,
  DeleteOutline,
  Edit,
  Forward,
  Save,
} from "@material-ui/icons";
import {
  getMcqAiGenerated,
  addAIquestionsToDB,
} from "service/WebSeparateService";
import Snackbar from "components/Snackbar/Snackbar";
import classes from "./LearningOutcome.module.css";
import { CancelRounded } from "@material-ui/icons";
import AlertDialog from "components/AlertDialog/AlertDialog";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dropdown: {
    width: "9em",
    marginTop: "35px",
  },
  inputWrapper: {
    display: "flex",
    width: "70%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "5px",
  },
  cardFooter: {
    justifyContent: "flex-end",
    width: "68%",
    paddingBottom: "15px",
  },
  uploadFile: {
    display: "flex",
    width: "50%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  uploadButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
};

export default class LearningOutcome extends React.Component {
  state = {
    tab1: true,
    tab2: false,
    district: {
      helperText: null,
      value: null,
    },
    mediumSelected: null,
    classList: [],
    districtList: [],
    MediumList: [],
    selectedStandard: null,
    subjectName: "",
    districtSecond: {
      helperText: null,
      value: null,
    },
    mediumSelectedSecond: null,
    selectedStandardSecond: null,
    checkSubject: false,
    districtLO: {
      helperText: null,
      value: null,
    },
    selectedStandardLO: null,
    mediumSelectedLO: null,
    subjectSelectedLO: null,
    subList: [],
    loTitle: "",
    loDesc: "",
    loFile: "",
    loOrder: "",
    selectedMonth: [],
    yearList: null,
    selectedYear: null,
    checkLO: false,
    LoSubList: null,
    ediSubject: null,
    editsubjectId: null,
    editSubClass: null,
    editSubjectModal: false,
    checkLO: false,
    monthList: [],
    editLO: false,
    loDetails: {},
    updatedMonth: [],
    updatedDesc: "",
    updatedLoTitle:"",
    status: "ACTIVE",
    imageModal: false,
    imageURL: "",
    message: "",
    error: false,
    learningOutcomeArray: [],
    totalArrayCount: null,
    offset: 0,
    updateLoId: null,
    alertBox: false,
    deletetype: null,
    subjectForm: [{ subjectName: "" }],
    emptySubForm: { subjectName: "" },
    updatedLoImage:"",
    allSelected:false
  };

  inputRef = React.createRef();

  componentDidMount() {
    this.getData();
    this.getAllMedium();
    // this.getYears();
    this.getMonthsList();
    this.getAllLO();
  }

  getallLOsubject = () => {
    let param = {
      district: this.state.districtSecond.value,
      medium: this.state.mediumSelectedSecond,
      class: this.state.selectedStandardSecond,
    };

    getSuperAdminService()
      .getLOSubject(param)
      .then((res) => {
        console.log(res, "res");
        if (res.status === 200) {
          this.setState({
            LoSubList: res.data.results,
          });
        }
      });
  };
  getAllLO = () => {
    let param = {
      offset: this.state.offset,
    };

    let url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10`;
    if (this.state.districtLO.value) {
      url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_district=${this.state.districtLO.value}`;
    }
    if (this.state.selectedStandardLO) {
      url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}`;
    }
    if (this.state.subjectSelectedLO) {
      url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}&lo_subject=${this.state.subjectSelectedLO.id}`;
    }
    getSuperAdminService()
      .getPubishedLOs(url)
      .then((res) => {
        if (res?.data?.results) {
          this.setState({
            learningOutcomeArray: res?.data?.results,
            totalArrayCount: res?.data?.count,
          });
        }
      });
  };

  // getYears = () => {
  //   getHMService()
  //     .getYear()
  //     .then((res) => {
  //       this.setState({
  //         yearList: res.data.years,
  //       });
  //       if (res.data.years.length > 0) {
  //         this.setState(
  //           {
  //             selectedYear: res.data.years[0],

  //           },
  //           this.getMonthsList(res.data.years[0])
  //         );
  //       }
  //     });
  // };

  getMonthsList = () => {
    getSuperAdminService()
      .getLOMonths()
      .then((res) => {
        console.log(res, "res");
        if (res.data.results)
          this.setState({
            monthList: res.data?.results,
          });
      });
  };

  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    this.setState({
      MediumList: mediumRes.data.response,
    });
  };
  async getData() {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: 1,
      })
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            districtList: res.data.response,
          });
        }
      });
  }

  getAllstandards = async (value) => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };

  saveForSubject = () => {
    console.log(
      this.state.subjectForm,
      "sub form",
      this.state.subjectForm.map((a) => a.subjectName)
    );

    let flagSubject = true;
    this.state.subjectForm.forEach((i) => {
      if (i.subjectName === "") {
        flagSubject = false;
      }
    });

    const {
      subjectName,
      selectedStandard,
      mediumSelected,
      district,
    } = this.state;


    if (
      this.state.district.value != null &&
      this.state.mediumSelected != null &&
      this.state.selectedStandard != null &&
      flagSubject != false
    ) {

      let subjectNameArr = []
      this.state.subjectForm.forEach((i) => {
        subjectNameArr.push(i.subjectName)
      });


      let param = {
        lo_subject: subjectNameArr,
        student_class: selectedStandard,
        student_state: 1,
        student_district: district.value,
        medium: mediumSelected,
      };
      getSuperAdminService()
        .creatLOSubject(param)
        .then((res) => {
          if (res.status === 201) {
            this.setState({
              subjectName: "",
              selectedStandard: null,
              mediumSelected: null,
              district: {
                helperText: null,
                value: null,
              },
              message: "The Subject has been created",
              error: false,
              checkSubject: false,
              subjectForm: [{ subjectName: "" }],
            });
          }
        });
    } else {
      this.setState({
        checkSubject: true,
      });
    }

  
  
  };

  getAllSubject = () => {
    let param = {
      class: this.state.selectedStandardLO,
      medium: this.state.mediumSelectedLO,
      district: this.state.districtLO?.value,
    };
    // getContentWriterService()
    //   .getSubjectByClassMed(param)
    //   .then((res) => {
    //     this.setState({
    //       subList: res.data.response,
    //     });
    //   });
    getSuperAdminService()
      .getLOSubject(param)
      .then((res) => {
        if (res?.data?.results) {
          console.log("Tell me the res", res);
          this.setState({
            subList: res.data.results,
          });
        }
      });
  };

  classSubject = () => {
    let { district, districtSecond } = this.state;


  
    let flagSubject = true;
    this.state.subjectForm.forEach((i) => {
      if (i.subjectName === "") {
        flagSubject = false;
      }
    });


    return (
      <div>
        <p
          style={{
            fontSize: "20px",
            marginTop: "30px",
          }}
        >
          Add Class and Subjects
        </p>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState({
                      district: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                        Medium*
                      </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: null,
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div>
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
            marginLeft: "5px",
          }}
        >
          <p>Add Subjects</p>
          {this.state.subjectForm.map((data, index) => {
            return (
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  value={data.subjectName}
                  style={{
                    marginTop: "20px",
                    paddingTop: "7px",
                    width: "20%",
                    height: "46px",
                    backgroundColor: "#DFE4E8",
                    border: 0,
                    paddingLeft: "10px",
                  }}
                  onChange={(e) => this.SubValChange(e, index)}
                />
                <button
                  style={{ border: "0px", backgroundColor: "transparent" }}
                  onClick={() => this.removeSub(index)}
                >
                  <DeleteOutline style={{ color: "red" }} />
                </button>
                <button
                  style={{ border: "0px", backgroundColor: "transparent" }}
                  onClick={() => this.addSub()}
                >
                  <AddBoxOutlined style={{ color: "black" }} />
                </button>
              </div>
            );
          })}
        </div>

        <div>
          {this.state.checkSubject == true &&
          (this.state.district.value == null ||
            this.state.mediumSelected == null ||
            this.state.selectedStandard == null ||
            flagSubject == false) ? (
            <p
              style={{
                color: "red",
              }}
            >
              Please Fill form carefully
            </p>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#71C45B",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={this.saveForSubject}
          >
            सेव्ह करा
          </div>
        </div>
        <hr />
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            List of classes and subjects
          </p>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            className="row"
          >
            <div className="col-2">
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="District">
                    District
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="District"
                    id="District_dropdown"
                    value={districtSecond.value}
                    error={districtSecond.helperText}
                    helperText={
                      !districtSecond.value && districtSecond.helperText
                    }
                    onChange={(e) => {
                      console.log(e.target.value, "ee");
                      this.setState({
                        districtSecond: {
                          value: e.target.value,
                          helperText: null,
                        },
                      });
                    }}
                  >
                    {this.state.districtList?.map((res) => {
                      return (
                        <MenuItem value={res.student_district_id}>
                          {res.district_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-2">
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">
                      Medium*
                    </InputLabel> */}
                  <span style={{ color: "#415EB6" }} id="School">
                    Medium
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.mediumSelectedSecond}
                    // disabled={this.state.bulkSend}
                    onChange={(e) => {
                      this.setState(
                        {
                          mediumSelectedSecond: e.target.value,
                          selectedStandardSecond: null,
                        },
                        () => {
                          this.getAllstandards();
                        }
                      );
                    }}
                  >
                    {this.state.MediumList?.map((med) => {
                      return (
                        <MenuItem value={med.medium_id} key={med.medium_id}>
                          {med.medium_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#ECFBFF",
              height: "19em",
              marginBottom: "50px",
              padding: "10px",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              {this.state.classList.map((clss) => {
                return (
                  <button
                    onClick={() => {
                      this.setState(
                        {
                          selectedStandardSecond: clss.class_id,
                        },
                        () => {
                          this.getallLOsubject();
                        }
                      );
                    }}
                    style={{
                      backgroundColor:
                        clss?.class_id == this.state.selectedStandardSecond
                          ? "#855AD8"
                          : "#DFE4E8",
                      borderRadius: "10px",
                      color:
                        clss?.class_id == this.state.selectedStandardSecond
                          ? "white"
                          : "#958989",
                      width: "100px",
                      border: "none",
                      height: "40px",
                      margin: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {clss.class_name}
                  </button>
                );
              })}
            </div>
            <div style={{ borderBottom: "1px solid black" }}></div>
            <div>
              {this.state.LoSubList?.map((res) => {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        backgroundColor: "#DFE4E8",
                        borderRadius: "10px",
                        color: "#855AD8",
                        width: "100px",
                        border: "none",
                        height: "40px",
                        margin: "10px",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {res.lo_subject}
                    </div>
                    <Edit
                      onClick={() => {
                        this.setState({
                          ediSubject: res.lo_subject,
                          editsubjectId: res.id,
                          editSubClass: res.student_class,
                          editSubjectModal: true,
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  pubishLearingOutcome = () => {
    if (
      this.state.loTitle != "" &&
      this.state.loOrder != "" &&
      this.state.loDesc != "" &&
      // this.state.loFile != "" &&
      this.state.subjectSelectedLO != null &&
      this.state.selectedMonth?.length != 0 &&
      this.state.selectedStandardLO != null &&
      this.state.mediumSelectedLO != null || 
      this.state.allSelected == true
    ) {
      let param = {
        level: this.state.loTitle,
        level_code: this.state.loTitle,
        description: this.state.loDesc,
        level_order: parseInt(this.state.loOrder),
        images: {
          img1: this.state.loFile,
        },
        lo_subject: this.state.subjectSelectedLO?.id,
        student_class: this.state.selectedStandardLO,
        month: this.state.allSelected == true ? [1,2,3,4,5,6,7,8,9,10,11,12] : this.state.selectedMonth,
        student_district: this.state.districtLO?.value,
        student_state: 1,
        default_for_all: false,
      };
      getSuperAdminService()
        .publishLos(param)
        .then((res) => {
          if (res?.status == 201) {
            this.setState(
              {
                checkLO: false,
                message: "The LO has been added",
                error: false,
                loTitle: "",
                loDesc: "",
                loFile: "",
                loOrder: "",
                selectedMonth: [],
                allSelected:false
              },
              () => {
                this.inputRef.current.value = null;
                let url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10`;
                if (this.state.districtLO.value) {
                  url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_district=${this.state.districtLO.value}`;
                }
                if (this.state.selectedStandardLO) {
                  url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}`;
                }
                if (this.state.subjectSelectedLO) {
                  url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}&lo_subject=${this.state.subjectSelectedLO.id}`;
                }
                getSuperAdminService()
                  .getPubishedLOs(url)
                  .then((res) => {
                    if (res?.data?.results) {
                      this.setState({
                        learningOutcomeArray: res?.data?.results,
                        totalArrayCount: res?.data?.count,
                      });
                    }
                  });
              }
            );
          }
        });
    } else {
      this.setState({
        checkLO: true,
      });
    }
  };

  BackPage = () => {
    let data =
      this.state.offset > 0 ? this.state.offset - 10 : this.state.offset;

    this.setState(
      {
        offset: data,
      },
      () => {
        this.getAllLO();
      }
    );
  };

  nextPage = () => {
    let data = this.state.offset + 10;
    if (data > this.state.totalArrayCount) {
      return;
    } else {
      this.setState(
        {
          offset: data,
        },
        () => {
          this.getAllLO();
        }
      );
    }
  };
  LOSection = () => {
    let { districtLO } = this.state;
    return (
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={districtLO.value}
                  error={districtLO.helperText}
                  helperText={!districtLO.value && districtLO.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        districtLO: {
                          value: e.target.value,
                          helperText: null,
                        },
                        selectedStandardLO: null,
                        mediumSelectedLO: null,
                        subjectSelectedLO: null,
                      },
                      () => {
                        this.getAllLO();
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                        Medium*
                      </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelectedLO}
                  disabled={
                    this.state?.districtLO?.value == null ? true : false
                  }
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelectedLO: e.target.value,
                        selectedStandardLO: null,
                        subjectSelectedLO: null,
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandardLO}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandardLO: e.target.value,
                        subjectSelectedLO: null,
                        offset: 0,
                      },
                      () => {
                        this.getAllSubject();
                        this.getAllLO();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Subject
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.subjectSelectedLO}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        subjectSelectedLO: e.target.value,
                        offset: 0,
                      },
                      () => {
                        this.getAllLO();
                      }
                    );
                  }}
                >
                  {this.state.subList.map((stdClass) => {
                    return (
                      <MenuItem value={stdClass} key={stdClass.id}>
                        {stdClass.lo_subject}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <p
          style={{
            fontSize: "20px",
            marginTop: "30px",
            color: "black",
            padding: "10px",
          }}
        >
          Add LO
        </p>
        <div
          style={{
            backgroundColor: "#ECFBFF",
            padding: "10px",
            color: "#958989",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              color: "#958989",
            }}
          >
            LO Title
            <input
              style={{
                borderWidth: "0px",
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "20px",
              }}
              type="text"
              placeholder="LO Title"
              value={this.state.loTitle} // If using state to control the input value
              onChange={(e) => {
                this.setState({
                  loTitle: e.target.value,
                });
              }} // If you want to handle input changes
            />
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              color: "#958989",
              marginTop: "10px",
            }}
          >
            LO Order
            <input
              type="number"
              style={{
                borderWidth: "0px",
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "20px",
              }}
              placeholder="LO Order"
              value={this.state.loOrder} // If using state to control the input value
              onChange={(e) => {
                this.setState({
                  loOrder: e.target.value,
                });
              }} // If you want to handle input changes
            />
          </div>
          Description :
          <textarea
            onChange={(e) => {
              this.setState({
                loDesc: e.target.value,
              });
            }}
            style={{
              marginTop: "15px",
              backgroundColor: "#DFE4E8",
              padding: "20px",
              border: 0,
              height: "253px",
              width: "100%",
              resize: "none",
              color: "#415EB6",
              fontSize: "22px",
              fontWeight: "800",
              marginTop: "10px",
            }}
            placeholder="Enter Text"
            value={this.state.loDesc}
          >
            {this.state.loDesc}
          </textarea>
          <input
            ref={this.inputRef}
            type="file"
            accept=".pdf, image/jpeg, image/jpg, image/png"
            style={{ marginTop: "5px", paddingTop: "7px" }}
            className={classes.committeeDetailsInput}
            onChange={(e) => {
              this.onChangeFile(e);
            }}
          />
          {/* <p style={{
      color:"#958989",
      fontSize:"20px",
      marginTop:"20px"
    }} >Select year:</p>
  
  {this.state.yearList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState(
                            {
                              selectedYear: res,
                              allYearSelected: null,
                            },
                            () => {
                              this.getMonthsList(res);
                            }
                          );
                        }}
                        style={{
                          backgroundColor:
                            this.state.selectedYear === res
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                        }}
                      >
                        {res}
                      </Button>
                    );
                  })} */}
          <p
            style={{
              color: "#958989",
              fontSize: "20px",
              marginTop: "20px",
            }}
          >
            Select Months:
          </p>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.state.monthList?.map((res) => {
              return (
                <Button
                  onClick={() => {
                    if (
                      this.state.selectedMonth?.length > 0 &&
                      this.state.selectedMonth.includes(res?.id)
                    ) {
                      this.state.selectedMonth.splice(
                        this.state.selectedMonth.indexOf(res?.id),
                        1
                      );
                      this.setState({
                        selectedMonth: [...this.state.selectedMonth],
                        allSelected:false
                      });
                    } else {
                      this.setState({
                        selectedMonth: [...this.state.selectedMonth, res?.id],
                        allSelected:false
                      });
                    }
                  }}
                  style={{
                    backgroundColor: this.state.selectedMonth.includes(res?.id)
                      ? "#DB83E2"
                      : "#C2B0BB",
                    marginLeft: "10px",
                    marginTop: "5px",
                    padding: "5px",
                    width: "100px",
                  }}
                >
                  {res?.months}
                </Button>
              );
            })}
            <Button
                  onClick={() => {
                    if(this.state.allSelected == true){
                      this.setState({
                        allSelected:false,
                        selectedMonth:[]
                      })
                    }
                    else{
                      this.setState({
                        allSelected:true,
                        selectedMonth:[]
                      })
                    }
                    
                  }}
                  style={{
                    backgroundColor: this.state.allSelected == false
                      ? "#C2B0BB"
                      : "#EA1E1E",
                    marginLeft: "10px",
                    marginTop: "5px",
                    padding: "5px",
                    width: "100px",
                  }}
                >
                All Months
                </Button>
            {console.log(this.state.selectedMonth, "select mnth")}
          </div>
          {this.state.checkLO == true &&
          (this.state.loTitle == "" ||
            this.state.loOrder == "" ||
            this.state.loDesc == "" ||
            // this.state.loFile == "" ||
            this.state.subjectSelectedLO == null ||
            (this.state.selectedMonth?.length == 0 && this.state.allSelected == false) ||
            this.state.selectedStandardLO == null ||
            this.state.mediumSelectedLO == null 
             ) ? (
            <p
              style={{
                color: "red",
              }}
            >
              Please Fill form carefully
            </p>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                textAlign: "center",
                backgroundColor: "#C6C6C6",
                padding: "10px",
                width: "7%",
                borderRadius: "10px",
                color: "#fff",
                cursor: "pointer",
                borderWidth: "0px",
                marginRight: "20px",
                marginTop: "10px",
              }}
              onClick={() => {
                this.setState({
                  districtLO: {
                    helperText: null,
                    value: null,
                  },
                  selectedStandardLO: null,
                  mediumSelectedLO: null,
                  subjectSelectedLO: null,
                  loTitle: "",
                  loDesc: "",
                  loFile: "",
                  loOrder: "",
                  selectedMonth: [],
                },()=>{
                  this.inputRef.current.value = null;
                });
              }}
            >
              Close
            </button>
            <button
              style={{
                textAlign: "center",
                backgroundColor: "#71C45B",
                padding: "10px",
                width: "7%",
                borderRadius: "10px",
                color: "#fff",
                cursor: "pointer",
                borderWidth: "0px",
                marginRight: "20px",
                marginTop: "10px",
              }}
              onClick={this.pubishLearingOutcome}
            >
              Publish LO
            </button>
          </div>
        </div>
        <hr />
        <div
          style={{
            marginBottom: "100px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              marginTop: "30px",
              color: "black",
            }}
          >
            List of published LOs
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                width: "10%",
                textAlign: "right",
                marginLeft: "5%",
              }}
            >
              LO title
            </div>
            <div
              style={{
                width: "50%",
                textAlign: "center",
              }}
            >
              LO Description
            </div>
            <div
              style={{
                width: "15%",
                textAlign: "center",
              }}
            >
              e.g
            </div>
            <div
              style={{
                width: "15%",
                textAlign: "center",
              }}
            >
              period
            </div>
            <div
              style={{
                width: "10%",
                textAlign: "center",
                marginLeft: "5%",
              }}
            >
              Active
            </div>
          </div>
          {this.state.learningOutcomeArray?.map((res, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    width: "5%",
                    backgroundColor: "#DFE4E8",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    width: "10%",
                    backgroundColor: "#DFE4E8",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  {res?.level_code}
                </div>
                <div
                  style={{
                    width: "40%",
                    backgroundColor: "#DFE4E8",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  {res?.description}
                </div>
                <div
                  onClick={() => {
                    this.setState({
                      imageURL: res?.images?.img1,
                      imageModal: true,
                    });
                  }}
                  style={{
                    width: "10%",
                    backgroundColor: "#DFE4E8",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  image
                </div>
                <div
                  style={{
                    width: "15%",
                    backgroundColor: "#DFE4E8",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {res?.month?.map((mon) => {
                    return (
                      <span style={{ marginLeft: "5px" }}>{mon?.months}</span>
                    );
                  })}
                </div>
                <IconButton
                  onClick={() => {
                    console.log("Tell me the res", res);
                    let monthArray = res?.month?.map((item) => item.id);
                    this.setState({
                      loDetails: {
                        level_code: res.level_code,
                      },
                      updatedMonth: monthArray,
                      updatedDesc: res?.description,
                      updateLoId: res?.id,
                      editLO: true,
                      updatedLoTitle:res?.level_code,
                      updatedLoImage:res?.images?.img1
                    });
                  }}
                  style={{
                    width: "5%",
                  }}
                >
                  <EditIcon />
                </IconButton>
                <div
                  style={{
                    width: "5%",
                  }}
                >
                  <Switch
                    checked={res?.is_deleted == false ? true : false}
                    color="primary"
                    onChange={(e) => {
                      this.setState({
                        alertBox: true,
                        updateLoId: res?.id,
                        deletetype: !res?.is_deleted,
                      });
                    }}
                    name={this.state.status}
                  />
                </div>
              </div>
            );
          })}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                this.BackPage();
              }}
            >
              <ArrowBack />
            </Button>
            <Button
              onClick={() => {
                this.nextPage();
              }}
            >
              <ArrowForward />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  onChangeFile = async (event) => {

    const file = event.target.files[0];
    if(file){
      if (
        file.type === 'application/pdf' ||
        (file.type.startsWith('image/') && /\.(jpeg|jpg|png)$/.test(file.name))
      ){
        const fd = new FormData();
        fd.append(
          "image",
          event.currentTarget ? event.currentTarget.files[0] : event
        );
        fd.append("school_id", 1);
        console.log(fd, "fd");
        let mediaRes = await getHMService().uploadSchoolImages(fd);
        console.log(mediaRes.data.file, "media");
        this.setState({
          loFile: mediaRes.data.file,
          message: "Correct File",
          error: false,
        });
      }
      else{
          this.setState({
            message: "Pick Correct File Type",
            error: false,
          })
      }
    }


  };



  onChangeUpadateFile = async (event) => {

    const file = event.target.files[0];
    if(file){
      if (
        file.type === 'application/pdf' ||
        (file.type.startsWith('image/') && /\.(jpeg|jpg|png)$/.test(file.name))
      ){
        const fd = new FormData();
        fd.append(
          "image",
          event.currentTarget ? event.currentTarget.files[0] : event
        );
        fd.append("school_id", 1);
        console.log(fd, "fd");
        let mediaRes = await getHMService().uploadSchoolImages(fd);
        console.log(mediaRes.data.file, "media");
        this.setState({
          updatedLoImage: mediaRes.data.file,
          message: "Correct File",
          error: false,
        });
      }
      else{
          this.setState({
            message: "Pick Correct File Type",
            error: false,
          })
      }
    }


  };
  editSubject = () => {
    let param = {
      lo_subject: this.state.ediSubject,
      student_class: this.state.editSubClass,
    };

    getSuperAdminService()
      .updateLOSubject(this.state.editsubjectId, param)
      .then((res) => {
        console.log(res, "res");
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };
  updateTheLos = () => {
    let param = {
      description: this.state.updatedDesc,
      month: this.state.updatedMonth,
      level_code:this.state.updatedLoTitle,
      level:this.state.updatedLoTitle,
      images:{
        img1:this.state.updatedLoImage
      }
    };
    let paramID = this.state.updateLoId;

    getSuperAdminService()
      .updatePublishedLos(paramID, param)
      .then((res) => {
        if (res?.status == 200) {
          this.setState(
            {
              message: "The LO has been updated",
              error: false,
              editLO:false
            },
            () => {
              let url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10`;
              if (this.state.districtLO.value) {
                url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_district=${this.state.districtLO.value}`;
              }
              if (this.state.selectedStandardLO) {
                url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}`;
              }
              if (this.state.subjectSelectedLO) {
                url = `/learning_outcome/?ordering=+level_order&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}&lo_subject=${this.state.subjectSelectedLO.id}`;
              }
              getSuperAdminService()
                .getPubishedLOs(url)
                .then((res) => {
                  if (res?.data?.results) {
                    this.setState({
                      learningOutcomeArray: res?.data?.results,
                      totalArrayCount: res?.data?.count,
                    });
                  }
                }); 
            }
          );
        }
      });
  };

  urlToimageName = (url) => {
    let index = url.lastIndexOf("/") + 1;
    let filename = url.substr(index);
    let dotIndex = filename.lastIndexOf(".");
    let name = filename.substr(0, dotIndex);
    
    const underscoreIndex = name.indexOf('_');
    
    if (underscoreIndex !== -1) {
      name = name.substr(underscoreIndex + 1);
    }
    return name;
  };


  onSubmitHandler = () => {
    let param = {
      is_deleted: this.state.deletetype,
    };
    let paramID = this.state.updateLoId;

    getSuperAdminService()
      .updatePublishedLos(paramID, param)
      .then((res) => {
        if (res?.status == 200) {
          this.setState(
            {
              message: "The LO has been updated",
              error: false,
              alertBox: false,
            },
            () => { 
              let url = `/learning_outcome/?ordering=-id&offset=${this.state.offset}&limit=10`;
              if (this.state.districtLO.value) {
                url = `/learning_outcome/?ordering=-id&offset=${this.state.offset}&limit=10&student_district=${this.state.districtLO.value}`;
              }
              if (this.state.selectedStandardLO) {
                url = `/learning_outcome/?ordering=-id&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}`;
              }
              if (this.state.subjectSelectedLO) {
                url = `/learning_outcome/?ordering=-id&offset=${this.state.offset}&limit=10&student_class=${this.state.selectedStandardLO}&student_district=${this.state.districtLO.value}&lo_subject=${this.state.subjectSelectedLO.id}`;
              }
              getSuperAdminService()
                .getPubishedLOs(url)
                .then((res) => {
                  if (res?.data?.results) {
                    this.setState({
                      learningOutcomeArray: res?.data?.results,
                      totalArrayCount: res?.data?.count,
                    });
                  }
                }); 
            }
          );
        }
      });
  };

  SubValChange = (event, index) => {
    let subjectForm = [...this.state.subjectForm];
    subjectForm[index].subjectName = event.target.value;

    this.setState({
      subjectForm: subjectForm,
    });
  };
  addSub = () => {
    let emptySubForm = JSON.stringify(this.state.emptySubForm);
    let subjectForm = [...this.state.subjectForm];
    subjectForm.push(JSON.parse(emptySubForm));
    this.setState(
      {
        subjectForm,
      },
      () => {
        console.log(this.state.subjectForm, "donors Dorm");
      }
    );
  };

  removeSub = (index) => {
    let subjectForm = [...this.state.subjectForm];
    if (subjectForm.length > 1) {
      subjectForm.splice(index, 1);
      this.setState({
        subjectForm,
      });
    }
  };
  render() {
    let { message, error } = this.state;

    return (
      <>
        <AlertDialog
          open={this.state.alertBox}
          title="Change Status?"
          text="Do you want to Change Status?"
          onCancel={() =>
            this.setState({
              alertBox: false,
            })
          }
          onConfirm={() => {
            this.onSubmitHandler();
          }}
        />
        <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() => {
            this.setState({
              message: null,
            });
          }}
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        <Modal
          open={this.state.editLO}
          onClose={() => {
            this.setState({
              editLO: false,
              loDetails: {},
              updatedMonth: [],
              updatedDesc: "",
              updateLoId: null,
              updatedLoTitle:"",
              updatedLoImage:""
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 900,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                marginBottom: "50px",
                textAlign: "center",
                color: "#415EB6",
              }}
            >
              Edit LO
            </div>
            <div
              style={{
                color: "#415EB6",
                marginTop: "6px",
                display:"flex",
                flexDirection:"row",
                alignItems:"center"
              }}
            >
              <p>
              Lo title :
              </p>
               <input
              style={{
                borderWidth: "0px",
                borderColor: "black",
                borderStyle: "solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "20px",
              }}
              type="text"
              placeholder="LO Title"
              value={this.state.updatedLoTitle} // If using state to control the input value
              onChange={(e) => {
                this.setState({
                  updatedLoTitle: e.target.value,
                });
              }} // If you want to handle input changes
            />
            </div>
            <div style={{ }}>
                <div style={{
                color: "#415EB6",
                marginTop: "6px",
                  }}>
                  LO File
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.updatedLoImage ? (
                    <>
                      {
                        this.state.updatedLoImage?.split('.')?.pop() == "pdf" ? (
                          <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "10%",
                  borderRadius: "10px",
                  color: "#fff",
                  // marginLeft: "15px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  window.open(this.state.imageURL);
                }}
              >
                PDF File
              </Button>
                        ) : (
                          <img
                      src={this.state.updatedLoImage}
                      width="100px"
                      height="100px"
                      alt="Your Image"
                    />
                        )
                      }
                    </>
                  ) : <p>No File</p>}
                </div>
                <div>
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    style={{ marginTop: "5px", paddingTop: "7px" }}
                    className={classes.committeeDetailsInput}
                    onChange={(e) => {
                      this.onChangeUpadateFile(e);
                    }}
                  />
                </div>
              </div>
           

            <div
              style={{
                color: "#415EB6",
                marginTop: "6px",
              }}
            >
              Lo Description :
            </div>
            <textarea
              onChange={(e) => {
                this.setState({
                  updatedDesc: e.target.value,
                });
              }}
              style={{
                marginTop: "15px",
                backgroundColor: "#DFE4E8",
                padding: "20px",
                border: 0,
                height: "153px",
                width: "100%",
                resize: "none",
                color: "#415EB6",
                fontSize: "22px",
                fontWeight: "800",
                marginTop: "10px",
              }}
              placeholder="Enter Text"
              value={this.state.updatedDesc}
            >
              {this.state.updatedDesc}
            </textarea>

            <div
              style={{
                color: "#415EB6",
                marginTop: "6px",
              }}
            >
              Select Months
            </div>
            <div>
              {this.state.monthList?.map((res) => {
                return (
                  <Button
                    onClick={() => {
                      if (
                        this.state.updatedMonth?.length > 0 &&
                        this.state.updatedMonth.includes(res?.id)
                      ) {
                        this.state.updatedMonth.splice(
                          this.state.updatedMonth.indexOf(res?.id),
                          1
                        );
                        this.setState({
                          updatedMonth: [...this.state.updatedMonth],
                        });
                      } else {
                        this.setState({
                          updatedMonth: [...this.state.updatedMonth, res?.id],
                        });
                      }
                    }}
                    style={{
                      backgroundColor: this.state.updatedMonth.includes(res?.id)
                        ? "#DB83E2"
                        : "#C2B0BB",
                      marginLeft: "10px",
                      marginTop: "5px",
                      padding: "5px",
                      width: "100px",
                    }}
                  >
                    {res?.months}
                  </Button>
                );
              })}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "15px",
                  marginTop: "30px",
                }}
                onClick={this.updateTheLos}
              >
                Submit
              </Button>

              <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "red",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "15px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  this.setState({
                    editLO: false,
                  });
                }}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.imageModal}
          onClose={() => {
            this.setState({
              imageModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "300px",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
               {this.state.imageURL ? (
                    <>
                      {
                        this.state.imageURL?.split('.')?.pop() == "pdf" ? (
                         
                          <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "50%",
                  borderRadius: "10px",
                  color: "#fff",
                  // marginLeft: "15px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  window.open(this.state.imageURL);
                }}
              >
                PDF File
              </Button>
                        ) : (
                          <img
                          src={this.state.imageURL}
                          style={{ maxWidth: "90%", maxHeight: "90%" }}
                          alt="Your Image"
                        />
                        )
                      }
                    </>
                  ) : <p>No File</p>}
            
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  // marginLeft: "15px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  this.setState({
                    imageModal: false,
                  });
                }}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.editSubjectModal}
          onClose={() => {
            this.setState({
              editSubjectModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                background: "white",
                //   padding: 20,
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#58B9FF",
                  textAlign: "center",
                  padding: "10px",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div></div>
                Edit Subject
                <div>
                  <CancelRounded
                    style={{ color: "red" }}
                    onClick={() => {
                      this.setState({
                        editSubjectModal: false,
                      });
                    }}
                  />
                </div>
              </div>

              <div style={{ margin: "30px" }}>
                <div>Name of Course:</div>
                <div>
                  <input
                    type="text"
                    value={this.state.ediSubject}
                    placeholder="Name"
                    onChange={(e) =>
                      this.setState({ ediSubject: e.target.value })
                    }
                    className={classes.inputField}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    console.log(this.state, "state");
                    this.editSubject();
                  }}
                  style={{ backgroundColor: "#7965DA" }}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <button
          onClick={() => {
            this.setState({
              tab1: true,
              tab2: false,
            });
          }}
          className={
            this.state.tab1 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          Class and Subjects
        </button>
        <button
          onClick={() => {
            this.setState({
              tab1: false,
              tab2: true,
              tab3: false,
            });
          }}
          className={
            this.state.tab2 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          Learing Outcome
        </button>
        {this.state.tab1 ? this.classSubject() : this.LOSection()}
      </>
    );
  }
}
