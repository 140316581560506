//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./FLNHome.module.css";
import {
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import { Box, Button, Input, Modal } from "@material-ui/core";
import home from "assets/img/SMCHome.jpg";
import {
  ArrowBack,
  ArrowForward,
  Cancel,
  DeleteOutline,
  Edit,
  Forward,
  Save,
} from "@material-ui/icons";
import HMService from "service/HMService";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Snackbar from "components/Snackbar/Snackbar";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  list: {
    maxHeight: "80vh",
    overflowY: "auto",
  },
};

const classes = styles;
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    studnetList: [],
    flag: false,
    school: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
        JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
    selectedClass: null,
    selectedMedium: null,
    editView: false,
    editIndex: null,
    deleteActivity: false,
    deleteID: null,
    studnetName: "",
    studnetSRLId: "",
    singleMedium: null,
    singleStandard: null,
    singleName: "",
    singleId: null,
    gender: null,
    offsetPage: 0,
    totalStudent: null,
    EditStudnet: false,
    firstName: null,
    MiddleName: null,
    LastName: null,
    SingleMiddleName: "",
    SingleLastName: "",
    flags: false,
    snackBarFlag: false,
    message: null,
  };

  componentDidMount() {
    this.getStudent();
    this.getMedium();
  }

  getStudent = () => {
    let url = this.state.searchFlag
      ? `/fln_student/?school=${this.state.school}&&offset=${this.state.offsetPage}&&medium=${this.state.selectedMedium}&&student_class=${this.state.selectedClass}`
      : `/fln_student/?school=${this.state.school}&&offset=${this.state.offsetPage}`;
    getHMService()
      .getStudentListDetails(url)
      .then((res) => {
        console.log(res, "res");
        this.setState({
          studnetList: res.data.results,
          flag: true,
          totalStudent: res.data.count,
          snackBarFlag: false,
        });
      });
  };
  nextPage = () => {
    let data = this.state.offsetPage + 10;
    if (data > this.state.totalStudent) {
      return;
    } else {
      this.setState(
        {
          offsetPage: data,
        },
        () => {
          this.getStudent();
        }
      );
    }
  };

  BackPage = () => {
    let data =
      this.state.offsetPage > 0
        ? this.state.offsetPage - 10
        : this.state.offsetPage;

    this.setState(
      {
        offsetPage: data,
      },
      () => {
        this.getStudent();
      }
    );
  };

  getMedium = () => {
    getContentWriterService()
      .getMediumbyState({
        state_id: 1,
      })
      .then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          this.setState({
            mediumList: res.data.response,
          });
        }
      });
  };

  getAllstandards = async () => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };

  deleteStudent = () => {
    getHMService()
      .deleteStudent(this.state.school, this.state.deleteID)
      .then((res) => {
        console.log(res, "res");
        this.getStudent();
      });
  };
  updateStudentList = () => {
    let data = [...this.state.studnetList];
    console.log(this.state.studnetName, "studnetName");
    if (this.state.firstName.length > 0) {
      data[this.state.editIndex].first_name = this.state.firstName;
    }
    if (this.state.MiddleName.length > 0) {
      data[this.state.editIndex].middle_name = this.state.MiddleName;
    }
    if (this.state.LastName.length > 0) {
      data[this.state.editIndex].last_name = this.state.LastName;
    }
    if (this.state.studnetSRLId > 0) {
      data[this.state.editIndex].saral_id = this.state.studnetSRLId;
    }
    this.setState({
      studnetSRLId: "",
      studnetName: "",
    });
    console.log(data[this.state.editIndex], "data[this.state.editIndex]");
    getHMService()
      .editStudent(
        data[this.state.editIndex].student_id,
        data[this.state.editIndex].school,
        data[this.state.editIndex]
      )
      .then((res) => {
        console.log(res, "Rs");
        if (res.status == 200) {
          this.setState(
            {
              editView: false,
              EditStudnet: false,
            },
            () => {
              this.getStudent();
            }
          );
        }
      });
    console.log(data, "data");
  };

  createSingleStudent = () => {
    console.log(
      this.state.singleMedium === null &&
        this.state.studnetSRLId === "" &&
        this.state.singleName === "" &&
        this.state.SingleLastName === "" &&
        this.state.singleStandard === null &&
        this.state.gender === null &&
        this.state.SingleMiddleName === "",
      "validate"
    );
    {
    }
    console.log(this.state.singleName == null, "single");
    if (
      this.state.singleMedium === null &&
      this.state.studnetSRLId === "" &&
      this.state.singleName === "" &&
      this.state.SingleLastName === "" &&
      this.state.singleStandard === null &&
      this.state.gender === null &&
      this.state.SingleMiddleName === ""
    ) {
      this.setState({ flags: true });
    } else {
      let obj = {
        medium: this.state.singleMedium,
        school: this.state.school,
        saral_id: this.state.studnetSRLId,
        first_name: this.state.singleName,
        middle_name: this.state.SingleMiddleName,
        last_name: this.state.SingleLastName,
        student_class: this.state.singleStandard,
        gender: this.state.gender,
      };
      console.log(obj, "obj");
      // for (var key in obj) {
      //   if (obj[key] === "") {
      //     console.log(key + " is blank. Deleting it");
      //     this.setState({ flags: true });
      //     return;
      //   } else {
      //     this.setState({ flags: false });
      //   }
      // }
      // console.log(this.state.flags, "flags");
      // if (!this.state.flags) {
        getHMService()
          .createFLNStudnet(obj)
          .then((res) => {
            console.log(res, "res");
            if (res) {
              this.setState(
                {
                  singleName: "",
                  studnetSRLId: "",
                  snackModal: false,
                  flags: true,
                  snackBarFlag: true,
                  message: "Successfully Done",
                },
                () => {
                  this.getStudent();
                }
              );
            }
          })
          .catch((e) => {
            {
              console.log(e.response.data,"response")
              let data = e.response.data.saral_id ? e.response.data.saral_id :
              e.response.data.gender ? e.response.data.gender :
              e.response.data.last_name ? e.response.data.last_name :
              e.response.data.middle_name ? e.response.data.middle_name :
              e.response.data.student_class ? e.response.data.student_class : 
              e.response.data.first_name ? e.response.data.first_name : 
              e.response.data.middle_name ? e.response.data.middle_name : "Error"
              
              this.setState({
                snackBarFlag: true,
                message: data
              });
              console.log(e, "e");
            }
          });
      
    }
  };

  provideName = (firstName,middleName,lastName) =>{
    let data = `${firstName} ${middleName} ${lastName}`
    console.log(data,"data")
    const data1 = data.length > 18 ? `${data.substr(0, 18)}..` : data
    return data1
  }
  render() {
    let { stdWiseGraph } = this.state;

    return (
      <>
        <Snackbar
          autoHideDuration={4000}
          color={"success"}
          close={false}
          open={this.state.message}
          message={this.state.message}
          place="br"
          rtlActive={true}
          closeNotification={() => {
            this.setState({
              message: null,
            });
          }}
        />
        <AlertDialog
          open={this.state.deleteActivity}
          title="Delete?"
          text="Do you want to delete this field?"
          onCancel={() => this.setState({ deleteActivity: false })}
          onConfirm={() => {
            this.deleteStudent();
            this.setState({ deleteActivity: false });
          }}
        />
        <Modal
          open={this.state.snackModal}
          onClose={() => {
            this.setState({
              snackModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            style={{ maxHeight: 400, overflow: "auto", borderRadius: "20px" }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                marginBottom: "50px",
                textAlign: "center",
                color: "#415EB6",
              }}
            >
              Add Single Student
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <input
                  style={{
                    // background: "#DFE4E8",
                    color: "#415EB6",
                    maxWidth: "200px",
                    height: "46px",
                    marginLeft: "10px",
                    paddingTop: "7px",
                    // border: 0,
                    padding: "10px",
                  }}
                  type="text"
                  placeholder="first Name"
                  className={classes.committeeCategoryInput}
                  onChange={(e) =>
                    this.setState({
                      singleName: e.target.value,
                    })
                  }
                />

                <input
                  style={{
                    // background: "#DFE4E8",
                    color: "#415EB6",
                    maxWidth: "200px",
                    height: "46px",
                    marginLeft: "10px",
                    paddingTop: "7px",
                    // border: 0,
                    padding: "10px",
                  }}
                  type="text"
                  placeholder="middle Name"
                  className={classes.committeeCategoryInput}
                  onChange={(e) =>
                    this.setState({
                      SingleMiddleName: e.target.value,
                    })
                  }
                />

                <input
                  style={{
                    // background: "#DFE4E8",
                    color: "#415EB6",
                    maxWidth: "200px",
                    height: "46px",
                    marginLeft: "10px",
                    paddingTop: "7px",
                    // border: 0,
                    padding: "10px",
                  }}
                  type="text"
                  placeholder="last Name"
                  className={classes.committeeCategoryInput}
                  onChange={(e) =>
                    this.setState({
                      SingleLastName: e.target.value,
                    })
                  }
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "10px",
                }}
              >
                <div>
                  <InputLabel id="demo-simple-select-label">Medium</InputLabel>
                  <Select
                    style={{
                      color: "#415EB6",
                      border: 0,
                      // backgroundColor: "#DFE4E8",
                      width: "200px",
                      height: "46px",
                      marginRight: "20px",
                    }}
                    label="Medium"
                    className={classes.committeeCategoryInput}
                    value={this.state.singleMedium}
                    onChange={(e) =>
                      this.setState(
                        {
                          singleMedium: e.target.value,
                        },
                        () => {
                          this.getAllstandards();
                        }
                      )
                    }
                    placeholder="Category"
                  >
                    {this.state.mediumList?.map((res) => {
                      return (
                        <MenuItem
                          value={res.medium_id}
                          className={classes.committeeCategoryInput}
                          key={res.medium_name}
                        >
                          {res.medium_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <div>
                  <InputLabel id="demo-simple-select-label">Class</InputLabel>
                  <Select
                    style={{
                      color: "#415EB6",
                      border: 0,
                      // backgroundColor: "#DFE4E8",
                      width: "200px",
                      height: "46px",
                      marginRight: "20px",
                    }}
                    className={classes.committeeCategoryInput}
                    value={this.state.singleStandard}
                    onChange={(e) =>
                      this.setState({
                        singleStandard: e.target.value,
                      })
                    }
                    placeholder="Category"
                  >
                    {this.state.classList?.map((res) => {
                      return (
                        <MenuItem
                          value={res.class_id}
                          className={classes.committeeCategoryInput}
                          key={res.class_name}
                        >
                          {res.class_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    style={{
                      color: "#415EB6",
                      border: 0,
                      // backgroundColor: "#DFE4E8",
                      width: "200px",
                      height: "46px",
                      marginRight: "20px",
                    }}
                    className={classes.committeeCategoryInput}
                    value={this.state.gender}
                    onChange={(e) =>
                      this.setState({
                        gender: e.target.value,
                      })
                    }
                    placeholder="Category"
                  >
                    <MenuItem
                      value="MALE"
                      className={classes.committeeCategoryInput}
                      key="MALE"
                    >
                      MALE
                    </MenuItem>
                    <MenuItem
                      value="FEMALE"
                      className={classes.committeeCategoryInput}
                      key="FEMALE"
                    >
                      FEMALE
                    </MenuItem>
                  </Select>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <input
                  style={{
                    // background: "#DFE4E8",
                    color: "#415EB6",
                    maxWidth: "200px",
                    height: "46px",
                    marginLeft: "10px",
                    paddingTop: "7px",
                    // borderBottom: "1px solid black",
                    padding: "10px",
                  }}
                  type="number"
                  placeholder="Saral ID"
                  className={classes.committeeCategoryInput}
                  onChange={(e) =>
                    this.setState({
                      studnetSRLId: e.target.value,
                    })
                  }
                />
              </div>

              {this.state.flags ? (
                <div style={{ color: "red", marginTop: "5px" }}>
                  *Fill all details
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",

                  marginTop: "20px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#0d6efd",
                    padding: "15px",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.createSingleStudent();
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.EditStudnet}
          onClose={() => {
            this.setState({
              EditStudnet: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            style={{ maxHeight: 400, overflow: "auto", borderRadius: "20px" }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                marginBottom: "50px",
                textAlign: "center",
                color: "#415EB6",
              }}
            >
              Edit Single Student
            </div>
            <div>
              <input
                style={{
                  color: "#415EB6",
                  // border: "0px",
                  // backgroundColor: "#DFE4E8",
                  minWidth: "17%",
                  height: "46px",
                  // marginRight: "20px",
                  marginLeft: "90px",
                  textAlign: "center",
                }}
                placeholder="first Name"
                value={this.state.firstName}
                // className={classes.committeeAttendanceInput}
                onChange={(e) =>
                  this.setState({
                    firstName: e.target.value,
                  })
                }
              />
              <input
                style={{
                  color: "#415EB6",
                  // border: "0px",
                  // backgroundColor: "#DFE4E8",
                  minWidth: "17%",
                  height: "46px",
                  // marginRight: "20px",
                  marginLeft: "90px",
                  textAlign: "center",
                }}
                placeholder=" Middle Name"
                value={this.state.MiddleName}
                // className={classes.committeeAttendanceInput}
                onChange={(e) =>
                  this.setState({
                    MiddleName: e.target.value,
                  })
                }
              />
              <input
                style={{
                  color: "#415EB6",
                  // border: "0px",
                  // backgroundColor: "#DFE4E8",
                  minWidth: "17%",
                  height: "46px",
                  // marginRight: "20px",
                  marginLeft: "90px",
                  textAlign: "center",
                  marginTop: "15px",
                }}
                placeholder="Last Name"
                value={this.state.LastName}
                // className={classes.committeeAttendanceInput}
                onChange={(e) =>
                  this.setState({
                    LastName: e.target.value,
                  })
                }
              />
              <input
                style={{
                  color: "#415EB6",
                  // border: "0px",
                  // backgroundColor: "#DFE4E8",
                  minWidth: "17%",
                  height: "46px",
                  // marginRight: "20px",
                  marginLeft: "90px",
                  textAlign: "center",
                }}
                type="number"
                placeholder="Name"
                value={this.state.studnetSRLId}
                className={classes.committeeAttendanceInput}
                onChange={(e) =>
                  this.setState({
                    studnetSRLId: e.target.value,
                  })
                }
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",

                  marginTop: "20px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#0d6efd",
                    padding: "15px",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.updateStudentList();
                  }}
                >
                  Edit Student
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        <div
          style={{
            marginTop: "15px",
            // backgroundColor: "#DFE4E8",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
            color: "rgb(65, 94, 182)",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ padding: "15px", backgroundColor: "#D9D7DA" }}
              onClick={() => {
                this.setState({ snackModal: true });
              }}
            >
              add single student
            </Button>
          </div>
          <div style={{ fontSize: "20px", padding: "10px" }}>
            महत्वाच्या सूचना:{""}
          </div>
          <div>
            १. तुम्ही अपलोड केलेली विद्यार्थ्यांची यादी इथे पाहता येईल व त्यात
            बदल करता येतील.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "40px",
              flexDirection: "row",
            }}
          >
            <div>
              <InputLabel id="demo-simple-select-label">Medium</InputLabel>
              <Select
                style={{
                  color: "#415EB6",
                  border: 0,
                  backgroundColor: "#DFE4E8",
                  width: "200px",
                  height: "46px",
                  marginRight: "20px",
                }}
                className={classes.committeeCategoryInput}
                value={this.state.selectedMedium}
                onChange={(e) =>
                  this.setState(
                    {
                      selectedMedium: e.target.value,
                    },
                    () => {
                      this.getAllstandards();
                    }
                  )
                }
                placeholder="Category"
              >
                {this.state.mediumList?.map((res) => {
                  return (
                    <MenuItem
                      value={res.medium_id}
                      className={classes.committeeCategoryInput}
                      key={res.medium_name}
                    >
                      {res.medium_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div>
              <InputLabel id="demo-simple-select-label">Class</InputLabel>
              <Select
                style={{
                  color: "#415EB6",
                  border: 0,
                  backgroundColor: "#DFE4E8",
                  width: "200px",
                  height: "46px",
                  marginRight: "20px",
                }}
                label="Medium"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={classes.committeeCategoryInput}
                value={this.state.selectedClass}
                onChange={(e) =>
                  this.setState({
                    selectedClass: e.target.value,
                  })
                }
                placeholder="Category"
              >
                {this.state.classList?.map((res) => {
                  return (
                    <MenuItem
                      value={res.class_id}
                      className={classes.committeeCategoryInput}
                      key={res.class_name}
                    >
                      {res.class_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Button
              style={{
                backgroundColor: "#7965DA",
                padding: "10px",
                color: "#fff",
              }}
              onClick={() => {
                this.setState(
                  {
                    searchFlag: true,
                  },
                  () => {
                    this.getStudent();
                  }
                );
              }}
            >
              search
            </Button>
          </div>
          {/* {this.state.editView ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Save
                onClick={() => this.updateStudentList()}
                style={{ fontSize: "35px" }}
              />

              <Cancel
                style={{ fontSize: "35px", marginLeft: "15px" }}
                onClick={() =>
                  this.setState({
                    editView: false,
                    // editIndex: index,
                  })
                }
              />
            </div>
          ) : null} */}
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "20px",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  width: "350px",
                  color: "#958989",
                  marginLeft: "70px",
                }}
              >
                विद्यार्थ्याचेे पूर्ण नाव
              </p>
              <p
                style={{
                  textAlign: "center",
                  width: "300px",
                  color: "#958989",
                }}
              >
                Unique ID
              </p>
              <p
                style={{
                  textAlign: "center",
                  width: "300px",
                  color: "#958989",
                }}
              >
                माध्यम
              </p>
              <p
                style={{
                  textAlign: "center",
                  width: "300px",
                  color: "#958989",
                }}
              >
                इयत्ता
              </p>
            </div>
            <div style={{ marginLeft: "-10px" }}>
              {console.log(this.state.studnetList)}
              {this.state.flag &&
                this.state.studnetList?.map((res, index) => {
                  return (
                    <div>
                      <div
                        style={{ display: "flex", padding: "5px" }}
                        className={classes.committeeContainer}
                        key={index}
                      >
                        <div
                          style={{
                            border: "0px",
                            backgroundColor: "#DFE4E8",
                            minWidth: "5%",
                            height: "46px",
                            minWidth: "100px",
                            // marginTop: "10px",
                            color: "#958989",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {index + 1}
                        </div>

                        {this.state.editView &&
                        this.state.editIndex === index ? (
                          <input
                            style={{
                              color: "#415EB6",
                              border: "0px",
                              backgroundColor: "#DFE4E8",
                              minWidth: "17%",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "70px",
                              textAlign: "center",
                            }}
                            placeholder="Name"
                            value={this.state.studnetName || res.first_name}
                            // className={classes.committeeAttendanceInput}
                            onChange={(e) =>
                              this.setState({
                                studnetName: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <div
                            style={{
                              color: "#415EB6",
                              border: "0px",
                              backgroundColor: "#DFE4E8",
                              minWidth: "17%",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "70px",
                              textAlign: "center",
                            }}
                          >
                            {/* {res.first_name}
                            {""} {res.middle_name} {""}
                            {res.last_name} */}
                            {this.provideName(res.first_name,res.middle_name,res.last_name)}
                          </div>
                        )}

                        {this.state.editView &&
                        this.state.editIndex === index ? (
                          <input
                            style={{
                              color: "#415EB6",
                              border: "0px",
                              backgroundColor: "#DFE4E8",
                              minWidth: "7%",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "90px",
                              textAlign: "center",
                            }}
                            type="number"
                            placeholder="Name"
                            value={this.state.studnetSRLId || res.saral_id}
                            className={classes.committeeAttendanceInput}
                            onChange={(e) =>
                              this.setState({
                                studnetSRLId: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <div
                            style={{
                              color: "#415EB6",
                              border: "0px",
                              backgroundColor: "#DFE4E8",
                              minWidth: "20%",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "90px",
                              textAlign: "center",
                              padding: "10px",
                            }}
                          >
                            {res.saral_id}
                          </div>
                        )}

                        {/* {this.state.editView &&
                        this.state.editIndex === index ? (
                          <input
                            style={{
                              color: "#415EB6",
                              border: "0px",
                              backgroundColor: "#DFE4E8",
                              minWidth: "200px",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "90px",
                              textAlign: "center",
                            }}
                            placeholder="Name"
                            // value={data.teacher_name}
                            // className={classes.committeeAttendanceInput}
                            // onChange={(e) =>
                            //   this.onValChangeMeetings("teacher_name", e, index)
                            // }
                          />
                        ) : ( */}
                        <div
                          style={{
                            color: "#415EB6",
                            border: "0px",
                            backgroundColor: "#DFE4E8",
                            minWidth: "17%",
                            height: "46px",
                            // marginRight: "20px",
                            marginLeft: "50px",
                            textAlign: "center",
                          }}
                        >
                          {res.medium_name}
                        </div>
                        {/* // )} */}

                        {/* {this.state.editView &&
                        this.state.editIndex === index ? (
                          <input
                            style={{
                              color: "#415EB6",
                              border: "0px",
                              backgroundColor: "#DFE4E8",
                              minWidth: "200px",
                              height: "46px",
                              // marginRight: "20px",
                              marginLeft: "90px",
                              textAlign: "center",
                            }}
                            placeholder="Name"
                            // value={data.teacher_name}
                            // className={classes.committeeAttendanceInput}
                            // onChange={(e) =>
                            //   this.onValChangeMeetings("teacher_name", e, index)
                            // }
                          />
                        ) : ( */}
                        <div
                          style={{
                            color: "#415EB6",
                            border: "0px",
                            backgroundColor: "#DFE4E8",
                            minWidth: "7%",
                            height: "46px",
                            // marginRight: "20px",
                            marginLeft: "70px",
                            textAlign: "center",
                          }}
                        >
                          {res.class_name}
                        </div>
                        {/* )} */}

                        <div style={{ padding: "10px" }}>
                          <DeleteOutline
                            onClick={() => {
                              this.setState({
                                deleteID: res.student_id,
                                deleteActivity: true,
                              });
                            }}
                          />
                          {
                            !this.state.editView ? (
                              <Edit
                                style={{ marginLeft: "15px" }}
                                onClick={() =>
                                  this.setState({
                                    // editView: true,
                                    editIndex: index,
                                    EditStudnet: true,
                                    firstName: res.first_name,
                                    MiddleName: res.middle_name,
                                    LastName: res.last_name,
                                    studnetSRLId: res.saral_id,
                                  })
                                }
                              />
                            ) : null
                            //  (
                            //   <>
                            //     <Save />
                            //     <Cancel onClick={() =>
                            //       this.setState({
                            //         editView: false,
                            //         // editIndex: index,
                            //       })
                            //     }/>
                            //   </>
                            // )
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              this.BackPage();
            }}
          >
            <ArrowBack />
          </Button>
          <Button
            onClick={() => {
              this.nextPage();
            }}
          >
            <ArrowForward />
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "50px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#1ACA2B",
              color: "#fff",
              padding: "10px",
            }}
          >
            माहिती डाउनलोड करा
          </Button>
        </div>
      </>
    );
  }
}
