//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//custom imports
import {
  getAdministrativeService,
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import { Autocomplete } from "@material-ui/lab";
import highcharts3d from "highcharts/highcharts-3d";
import AlertDialog from "components/AlertDialog/AlertDialog";
highcharts3d(Highcharts);


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dropdown: {
    width: "9em",
    marginTop: "35px",
  },
  inputWrapper: {
    display: "flex",
    width: "70%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "5px",
  },
  cardFooter: {
    justifyContent: "flex-end",
    width: "68%",
    paddingBottom: "15px",
  },
  uploadFile: {
    display: "flex",
    width: "50%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  uploadButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
};

export default class LOAdminAnalysis extends React.Component {
  state = {
    district: {
      helperText: null,
      value: null,
    },
    block: {
      value: null,
      helperText: null,
    },
    school: {
      value: null,
      helperText: null,
    },
    state: {
      helperText: null,
      value: null,
    },
    districtList: [],
    blocks: [],
    mediumSelected: null,
    classList: [],
    selectedMedium: null,
    yearList: null,
    monthList: null,
    ChaluSelectedMonth: null,
    selectedYear: null,
    ChaluSelectedYear: null,
    modalOpen: false,
    // monthDataType: JSON.parse(localStorage.getItem("HMSchool")).fln_data_type,
    modalValues: [],
    yearList: null,
    selectedMonth: [],
    allYearSelected: null,
    MonthsList: null,
    subjectList: null,
    selectedStage: null,
    allYearSelected: null,
    arrayForLine: null,
    allCount: null,
    NextFLNSub: null,
    levelComparsionArr: [],
    firstTime: false,
    isSetAuto: false,
    loading: false,
    selectedStandard: null,
    selectedHalf: "FIRST HALF",
    showGraph: false,
    compareBlockData: [],
    showSchoolGraph: false,
    compareSchoolData: [],
    viewLineGraph:false,
    ViewTrend: false,
    ViewSummary: true,
    compareKendra: false,
    showKendraGraph: false,
    districtComparision: false,
    schoolComparision: false,
    viewDataEntry: false,
    compareKendraData: [],
    kendraBlockwise: [],
    blockModal: false,
    BlockValidation: false,
    subValidation: false,
    block1: {
      value: null,
      helperText: null,
    },
    block2: {
      value: null,
      helperText: null,
    },
    block3: {
      value: null,
      helperText: null,
    },
    block4: {
      value: null,
      helperText: null,
    },
    block5: {
      value: null,
      helperText: null,
    },
    isSetAuto1: false,
    isSetAuto2: false,
    isSetAuto3: false,
    isSetAuto4: false,
    isSetAuto5: false,
    school1: {
      value: null,
      helperText: null,
    },
    school2: {
      value: null,
      helperText: null,
    },
    school3: {
      value: null,
      helperText: null,
    },
    school4: {
      value: null,
      helperText: null,
    },
    school5: {
      value: null,
      helperText: null,
    },
    schoolListArray: [],
    arrayForCount: null,
    schoolSelected:true,
    schoolListArray:null,
    selectedKendra:null,
    levelComparsionArr: [],
    ChaluSelectedYear: null,
    ChaluSelectedMonth: null,
    loSubjectList:[],
    ChaluSelectedMedium:null,
    ChaluSelectedClass:null,
    selectedLoSubject:null,
    currentPos:0,
    firstTimeSummary: false,
    tab3table:[],
    viewtab3Table:false,
    learningOutcomeArray:[],
    currentLO:null,
    dataSummary:{},
    csvDownloading:false
  };

  componentDidMount() {
    this.getData();
    this.getMedium()
    this.getYears();
  }

  getMedium = () => {
    getContentWriterService()
      .getMediumbyState({
        state_id: 1,
      })
      .then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          this.setState({
            mediumList: res.data.response,
            selectedMedium: res.data.response[0]?.medium_id,
            selectedMediumName:res.data.response[0]?.medium_name,
          },()=>{
            this.getAllstandards();
          });
        }
      });
  };

  getLoSubjectListMore = () =>{
    if(this.state?.selectedClass != null  && this.state.selectedMedium != null){
      let url = `lo_subject/?student_class=${this.state?.selectedClass}&student_district=${this.state.district?.value}&medium=${this.state.selectedMedium}`
      getHMService()
        .getLoSubject(url)
        .then((res) => {
          console.log(res, "res lo subjectr s fa f");
          if (res.data.results) {
            this.setState(
              {
                subjectList: res.data.results, 
                selectedStage : res.data.results?.length > 0 ? res.data.results[0] : null,
              },
            );
          }
        });
  
    }
  }

  async getData() {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: 1,
      })
      .then((res) => {
        console.log(res, "Res");
        if (res.status == 200) {
          console.log(
            localStorage.getItem("AMdetails"),
            "localStorage.getItem"
          );
          this.setState({
            districtList: res.data.response,
          });
          if (localStorage.getItem("AMdetails")) {
            let Val = JSON.parse(localStorage.getItem("AMdetails"));
            console.log(Val.student_district_id, "VAL");
            this.setState(
              {
                district: {
                  helperText: null,
                  value: Val.student_district_id,
                },
              },
              () => {
                this.getStudentBlock(Val.student_district_id);
              }
            );
          }
        }
      });
  }


  getLoSubjectList =()=>{

    if(this.state?.ChaluSelectedClass != null && this.state.ChaluSelectedMedium != null ){

        let url = `lo_subject/?student_class=${this.state?.ChaluSelectedClass?.class_id}&student_district=${this.state.district?.value}&medium=${this.state.ChaluSelectedMedium?.medium_id}`
        getHMService()
          .getLoSubject(url)
          .then((res) => {
            console.log(res, "res lo subjectr");
            if (res.data.results) {
              this.setState(
                {
                  loSubjectList: res.data.results, 
                  selectedLoSubject : res.data.results?.length > 0 ? res.data.results[0]?.id : null,
                  currentPos:res.data.results?.length > 0 ? 1 : 0,
                  levelComparsionArr:[]
                },
              );
            }
          });

  
    }

   
  }

  getYears = () => {
    getHMService()
      .getYear()
      .then((res) => {
        console.log(res, "res");
        this.setState({
          yearList: res.data.years,
        });
        if (res.data.years.length > 0) {
          this.setState(
            {
              selectedYear: res.data.years[0],
              ChaluSelectedYear: res.data.years[0],
              TulanaSelectedYear: res.data.years[0],
            },
            this.getLoMonthsList(res.data.years[0])
          );
        }
      });
  };

  getLoMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getLoMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.results)
          this.setState(
            {
              monthList: res.data.results,
              ChaluSelectedMonth: res.data.results[res.data.results?.length - 1],
              TulanaSelectedMonth:res.data.results[res.data.results?.length - 1]
            },
            () => {

            }
          );
      });
  };


  getFirstAnyltics = () => {

  if(this.state.ChaluSelectedMedium != null && this.state.ChaluSelectedClass != null ){
      let param = {
        school: this.state.school.school_id,
        subject: this.state.NextFLNSub,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth, 
      };
      let array = [];
  
      console.log("tell me the statesau fhaf",this.state)
    
      if ( this.state.selectedLoSubject != null ||  !this.state.firstTimeSummary   ) {
        let url=`/lo_data_analytics/get_lo_analytics_for_school/?year=${this.state.ChaluSelectedYear}&month=${this.state.ChaluSelectedMonth?.id}&student_class=${this.state.ChaluSelectedClass?.class_id}&medium=${this.state.ChaluSelectedMedium?.medium_id}&district=${this.state.district?.value}&lo_subject=${this.state.selectedLoSubject}`
        
        if(this.state?.block?.value != null ){
             url= url + `&block=${this.state.block.value}`
        }

        if(this.state.school?.school_id != null ){
           url= url + `&school=${this.state.school?.school_id}`
        }
          
        if(this.state.selectedKendra != null){
          url= url + `&kendra=${this.state.selectedKendra}`
        }
        getHMService()
          .getLoAnalysisChalu(url)
          .then((res) => {
            // arrayData.push(res.data)
            console.log( "level1", res.data);

  
            if (!res?.data?.message) {
              array =
                this.state.levelComparsionArr.length ==
                this.state.loSubjectList?.length
                  ? []
                  : this.state.levelComparsionArr;
              array.push(res?.data);
              this.setState((i)=>({
                anylyticsList: res.data,
                // NextFLNSub: res.data.next_subject,
                firstTimeSummary: true,
                levelComparsionArr: array,
                loading: true,
                currentPos:i.currentPos + 1,
                selectedLoSubject :i.loSubjectList?.length > i.currentPos  ? i?.loSubjectList[i?.currentPos]?.id : null
              }),()=>{
                this.getFirstAnyltics();
              })
             
            }
          });
      } else {
        this.setState({
          loading: false,
        });
      }
    }

  };

  getSerisForGraph = (data) => {
    let array = [];
    let obj = {
      name: "count",
      data: null,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    data.map((res) => {
      array.push(res.percentage);
    });

    obj = {
      name: "",
      data: array,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    return [obj];
  };

  getCatergories = (data) => {
    let array = [];
    data.map((res) => {
      array.push(res?.lo_name);
    });
    return array;
  };

  getCharts = (data, res) => {
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: {
              text: "",
            },
            xAxis: {
              categories: this.getCatergories(data),
              crosshair: true,
              title: {
                enabled: true,
                text: "L.O",
              },
            },
            yAxis: {
              stackLabels: {
                enabled: true,
                align: "center",
              },
              visible: true,
              title: {
                enabled: true,
                text: "अध्ययन निष्पत्ती प्रमाण",
              },
            },

            labels: {
              items: [
                {
                  html: "",
                  style: {
                    left: "50px",
                    top: "18px",
                  },
                },
              ],
            },
            series: this.getSerisForGraph(data),

            tooltip: {
              formatter: function () {
                var result = data.find((item) => item?.lo_name === this.x);

                let pcnt = result?.yes_count;
                console.log("Tell me the precer",pcnt)
                console.log("Tell me the precer",pcnt)

                return pcnt;
              },
            },

            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              width: (window.innerWidth - 260 - 120) / 1.05,
              type: "column",
              backgroundColor: "#fff",
            },
          }}
        ></HighchartsReact>
        {console.log(data, "data")}
        <div style={{ padding: "10px", display: "flex" }}>
          <div>विद्यार्थी संख्या: {res.total_student}</div>
          <div style={{ marginLeft: "25px" }}>
            उपलब्ध नोंदी: {res.total_student_uploaded}
          </div>
          <div style={{ marginLeft: "25px" }}>
            अनुपलब्ध नोंदी: {res.total_student_not_uploaded}
          </div>
        </div>
      </>
    );
  };

  setAllYears = () => {
    let array = [];
    this.state.yearList.map((res) => {
      array.push(res);
    });
    this.setState({ allYearSelected: array, selectedYear: null,tab3table:[] ,arrayForLine:[]});
  };
  getMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.months)
          this.setState(
            {
              monthList: res.data.months,
              ChaluSelectedMonth: res.data.months[0],
              TulanaSelectedMonth: res.data.months[0],
              MonthsList: res.data.months[0],
            },
            () => {
              if (this.state.tab2) {
                this.getDataForCompare();
              }
            }
          );
      });
  };

  
  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    this.setState({
      MediumList: mediumRes.data.response,
    });
  };

  getAllstandards = async () => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
          // selectedClass: res.data.response[0]?.class_id,
          selectedClassName: res.data.response[0]?.class_name
        },()=>{
          // this.getLoSubjectListMore()
        });
      });
  };


  getStudentBlock = async (district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
            },
            () => {
              this.getSchools(this.state.block.value);
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  };

  getSchoolsTwo = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schoolsTwo: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schoolsTwo: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schoolsTwo: [],
          filteredSchools: [],
        });
      });
  };

  getSchoolsThree = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schoolsThree: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schoolsThree: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schoolsThree: [],
          filteredSchools: [],
        });
      });
  };

  getSchoolsFour = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schoolsFour: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schoolsFour: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schoolsFour: [],
          filteredSchools: [],
        });
      });
  };

  getSchoolsFive = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schoolsFive: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schoolsFive: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schoolsFive: [],
          filteredSchools: [],
        });
      });
  };

  getClassName = () => {
    var result = this.state.classList.find((obj) => {
      return obj.class_id === this.state.selectedStandard;
    });
    console.log(result, "classList");
    return result?.class_name;
  };

  getMediumName = () => {
    var result = this.state.MediumList?.find((obj) => {
      return obj.medium_id === this.state.mediumSelected;
    });
    console.log(result, "classList");
    return result?.medium_name;
  };


  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };

  onChangeHandle = (val, value) => {
    console.log(val, value, "val from onChangeHandle");
    this.setState({
      school: val,
    });
  };


  getListOfLos =()=>{

    let url = `/learning_outcome/?ordering=+level_order&offset=0&limit=100&student_class=${this.state.selectedClass}&student_district=${this.state?.district?.value}&lo_subject=${this.state.selectedSub?.id}&month=${this.state.ChaluSelectedMonth?.id}`;

    getSuperAdminService()
    .getPubishedLOs(url)
    .then((res) => {
      if (res?.data?.results) {
        this.setState({
          learningOutcomeArray: res?.data?.results,
          currentLO: null
        });
      }
    });
  }
  

  getCatergoriesForBlock = () => {
    let array = [];

    this.state.blocks.map((res) => {
      array.push(res.block_name);
    });
    return array;
  };


  getCatergoriesForKendra = () => {
    let array = [];

    this.state.blocks.map((res) => {
      array.push(res.kendra_name);
    });
    return array;
  };

  ViewDistrictComparision = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>


          <div className="col-2" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Year">
                  Year*
                </span>
                <Select
              id="demo-controlled-open-select2"
              labelId="Year"
              style={{ backgroundColor: "#DFE4E8" }}
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                
                this.setState((i)=>(
                  {
                    ChaluSelectedYear: e.target.value,
                    compareBlockData:[]
                    
                }),()=>{
                  this.getLoMonthsList(e.target.value);
                })
              }}
            >
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
              </FormControl>
          </div>
      
            
          </div>

          <div className="col-2">

          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Month">
                  Month*
                </span>
                <Select 
                style={{ backgroundColor: "#DFE4E8" }}
              id="demo-controlled-open-select2"
              labelId="Month"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState((i)=>(
                  {
                    ChaluSelectedMonth: e.target.value,
                    compareBlockData:[],
                    selectedSub:"",
                    learningOutcomeArray:null
                }))
              
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res?.months}</MenuItem>;
              })}
            </Select>
              </FormControl>
          </div>
         
          
          </div>


          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                Medium*
              </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedClass: "",
                        showGraph: false,
                        compareBlockData:[]
                      },
                      () => { 
                      }
                    );
                  }}
                >
                  {this.state.mediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-1" style={{
            marginRight:"20px"
          }} >
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedClass}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedClass: e.target.value,
                        selectSubject: "",
                        showGraph: false,
                        learningOutcomeArray:[],
                        currentLO:null,
                        compareBlockData:[]
                      },
                      () => {
                        this.getLoSubjectListMore();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Subject*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={this.state.selectedSub}
                  onChange={(e) => {
                    this.setState({
                      selectedSub: e.target.value,
                      compareBlockData:[]
                    },()=>{
                      if(this.state.selectedSub  != null ){
                        this.getListOfLos()
                      }
                    });
                  }}
                >
                  {this.state.subjectList?.map((res) => {
                    return (
                      <MenuItem value={res}>{res.lo_subject}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.blockModal ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>
          <div className="col-1">
            <div style={styles.inputWrapper}>
            <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "10px",
                  marginTop:"20px"
                }}
                onClick={() => {
                  this.getCompareBlocks()
                }}
              >
                search
              </Button> 
            </div>
         
          </div>
        </div>
        <div style={{ marginTop: "30px" }} className="row" >
        <div className="col-8">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  Select LO*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={this.state.currentLO?.lo}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                     {
                      currentLO:e.target.value,
                      compareBlockData:[]
                     }
                    );
                  }}
                >
                  {this.state.learningOutcomeArray?.map((res) => {
                    return (
                      <MenuItem value={res}>
                       {res?.level_code} : {res.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTime: false,
                    showGraph: false,
                  },
                  () => {
                    this.getMonthsList(e.target.value);
                    // this.getFirstAnyltics()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTime: false,
                    showGraph: false,
                  },
                  () => {
                    // this.getFirstAnyltics()
                    // this.getSummaryForGraph()
                    // this.getStudentBlock(this.state.selectedDistrict);
                  }
                );
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          {this.state.district.value == 0 ? (
            <div style={{ marginLeft: "10px" }}>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.selectedHalf}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      selectedHalf: e.target.value,
                      firstTime: false,
                      showGraph: false,
                    },
                    () => {
                      // this.getFirstAnyltics()
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                <MenuItem value="FIRST HALF">part-I</MenuItem>
                <MenuItem value="SECOND HALF">part- II</MenuItem>
              </Select>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() => {
              if (
                this.state.district.value &&
                this.state.selectedSub &&
                this.state.ChaluSelectedMonth &&
                this.state.ChaluSelectedYear
              ) {
                this.setState(
                  {
                    firstTime: false,
                  },
                  () => this.getComparisionForDistrict()
                );
              } else {
                this.setState({
                  blockModal: true,
                });
              }
            }}
          >
            search
          </Button>
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],

                ViewSummary: false,

                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,

                selectedSub: null,
                showKendraGraph: false,
              });
            }}
          >
            reset
          </Button>
        </div> */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* {this.showBlockComparision(this.state.compareBlockData)} */}

          {
            this.state.compareBlockData?.length > 0 ? (
              <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: "",
              },
              xAxis: {
                categories: this.state.compareBlockData.map(item => item.block_name),
                crosshair: true,
                title: {
                  text: "Blocks",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                stackLabels: {
                  enabled: true,
                  align: "center",
                },
                title: {
          text: 'अध्ययन निष्पत्ती प्रमाण ', // Set the X-axis label to 'ayush'
        },
                visible: true,
                title: {
                  text: "Percentage",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },

              labels: {
                items: [
                  {
                    html: "",
                    style: {
                      left: "50px",
                      top: "18px",
                    },
                  },
                ],
              },
              series: [{
        name: 'Percentage',
        data: this.state.compareBlockData.map(item => ({
    count: item.yes_count,
    y: item.percentage,
  }))
      }],

              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: true,
                  },
                  with: 15,
                  depth: 40,
                },
              },

              tooltip: {
  formatter: function() {
    var currentPoint = this,
      currentSeries = currentPoint.series,
      chart = currentSeries.chart,
      stackName = currentSeries.userOptions.stack,
      stackValues = '';

    chart.series.forEach(function(series) {
      series.points.forEach(function(point) {
        if (currentSeries.userOptions.stack === series.userOptions.stack && currentPoint.key === point.category) {
          stackValues += "Count" + ': ' + point.count + '<br/>';
        }
      });
    });

    return currentPoint.key + '<br/>' + stackValues;
  }
},

              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                width: (window.innerWidth - 260 - 120) / 1.05,
                type: "column",
                backgroundColor: "#fff",
                options3d: {
                  enabled: true,
                  alpha: 1,
                  beta: 15,
                  // viewDistance: 25,
                  depth: 100,
                },
              },
            }}
          ></HighchartsReact>
            ) : null
          }
        
        </div>
      </div>
    );
  };

  ViewschoolComparision = () => {
    let {
      district,
      block,
      school,
      state,
      block1,
      block2,
      block3,
      block4,
      block5,
      school1,
      school2,
      school3,
      school4,
      school5,
    } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Year">
                  Year*
                </span>
                <Select
              id="demo-controlled-open-select2"
              labelId="Year"
              style={{ backgroundColor: "#DFE4E8" }}
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                
                this.setState((i)=>(
                  {
                    ChaluSelectedYear: e.target.value,
                    compareSchoolData: [],
                }),()=>{
                  this.getLoMonthsList(e.target.value);
                })
              }}
            >
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
              </FormControl>
          </div>
      
            
          </div>

          <div className="col-2">

          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Month">
                  Month*
                </span>
                <Select 
                style={{ backgroundColor: "#DFE4E8" }}
              id="demo-controlled-open-select2"
              labelId="Month"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState((i)=>(
                  {
                    ChaluSelectedMonth: e.target.value,
                    compareSchoolData: [],
                    selectedSub:"",
                    learningOutcomeArray:null
                }))
              
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res?.months}</MenuItem>;
              })}
            </Select>
              </FormControl>
          </div>
         
          
          </div>
         

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                Medium*
              </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                        showSchoolGraph: false,
                        compareSchoolData: [],
                      },
                      () => {


                      }
                    );
                  }}
                >
                  {this.state.mediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedClass}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedClass: e.target.value,
                        selectSubject: "",
                        showSchoolGraph: false,
                        learningOutcomeArray:[],
                        currentLO:null,
                        compareSchoolData: [],
                      },
                      () => {
                        this.getLoSubjectListMore();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Subject*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  disabled={this.state.selectedClass != null ? false : true}
                  value={this.state.selectedSub}
                  onChange={(e) => {
                    this.setState({
                      selectedSub: e.target.value,
                      compareSchoolData: [],
                    },()=>{
                      if(this.state.selectedSub  != null ){
                        this.getListOfLos()
                      }
                    });
                  }}
                >
                  {this.state.subjectList?.map((res) => {
                    return (
                      <MenuItem value={res}>{res.lo_subject}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
        >
          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block*
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block1.value}
                    error={block1.helperText}
                    helperText={!block1.value && block1.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block1: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                          compareSchoolData: [],
                        },
                        () => {
                          this.getSchools(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School*
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto1}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto1: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto1: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school1: value,
                        compareSchoolData: [],
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school1.value}
                        error={school1.helperText}
                        helperText={!school1.value && school1.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block1.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school1, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block2.value}
                    error={block2.helperText}
                    helperText={!block2.value && block2.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block2: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                          compareSchoolData: [],
                        },
                        () => {
                          this.getSchoolsTwo(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto2}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto2: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto2: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school2: value,
                        compareSchoolData: [],
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schoolsTwo == undefined ? [] : this.state.schoolsTwo
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school2.value}
                        error={school2.helperText}
                        helperText={!school2.value && school2.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchoolsTwo(
                            this.state.block2.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block3.value}
                    error={block3.helperText}
                    helperText={!block3.value && block3.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block3: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                          compareSchoolData: [],
                        },
                        () => {
                          this.getSchoolsThree(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto3}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto3: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto3: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school3: value,
                        compareSchoolData: [],
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schoolsThree == undefined ? [] : this.state.schoolsThree
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school3.value}
                        error={school3.helperText}
                        helperText={!school3.value && school3.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchoolsThree(
                            this.state.block3.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block4.value}
                    error={block4.helperText}
                    helperText={!block4.value && block4.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block4: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                          compareSchoolData: [],
                        },
                        () => {
                          this.getSchoolsFour(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto4}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto4: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto4: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school4: value,
                        compareSchoolData: [],
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schoolsFour == undefined ? [] : this.state.schoolsFour
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school4.value}
                        error={school4.helperText}
                        helperText={!school4.value && school4.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchoolsFour(
                            this.state.block4.value,
                            ev.target.value,
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school4, "school")}
            </div>
          </div>

          <div>
            <div>
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block5.value}
                    error={block5.helperText}
                    helperText={!block5.value && block5.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block5: {
                            value: e.target.value,
                            helperText: null,
                          },
                          BlockValidation: false,
                          showSchoolGraph: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                          compareSchoolData: [],
                        },
                        () => {
                          this.getSchoolsFive(e.target.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* {
              this.state.BlockValidation ? <div style={{ color:'red'}}>Required</div>:null
            } */}
            </div>

            <div>
              <div style={styles.inputWrapper}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto5}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto5: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto5: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        showSchoolGraph: false,
                        school5: value,
                        compareSchoolData: [],
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schoolsFive == undefined ? [] : this.state.schoolsFive
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school5.value}
                        error={school5.helperText}
                        helperText={!school5.value && school5.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchoolsFive(
                            this.state.block5.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              {console.log(this.state.school, "school")}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "30px" }} className="row" >
        <div className="col-8">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  Select LO*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={this.state.currentLO?.lo}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                     {
                      currentLO:e.target.value,
                      compareSchoolData: [],
                     }
                    );
                  }}
                >
                  {this.state.learningOutcomeArray?.map((res) => {
                    return (
                      <MenuItem value={res}>
                       {res?.level_code} : {res.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() => {
              this.getCompareSchool()
            }}
          >
            search
          </Button>

        </div>

        
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* {this.showSchoolComparison(this.state.compareSchoolData)} */}

          {
          this.state.compareSchoolData?.length > 0 ? (
            <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: "",
              },
              xAxis: {
                categories: this.state.compareSchoolData.map(item => item.school_name),
                crosshair: true,
                title: {
                  text: "School",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                stackLabels: {
                  enabled: true,
                  align: "center",
                },
                title: {
          text: 'अध्ययन निष्पत्ती प्रमाण ', // Set the X-axis label to 'ayush'
        },
                visible: true,
                title: {
                  text: "Percentage",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },

              labels: {
                items: [
                  {
                    html: "",
                    style: {
                      left: "50px",
                      top: "18px",
                    },
                  },
                ],
              },
      series: [{
        name: 'Percentage',
        data: this.state.compareSchoolData.map(item => ({
    count: item.yes_count,
    y: item.percentage,
  }))
      }],

              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: true,
                  },
                  with: 15,
                  depth: 40,
                },
              },

              tooltip: {
  formatter: function() {
    var currentPoint = this,
      currentSeries = currentPoint.series,
      chart = currentSeries.chart,
      stackName = currentSeries.userOptions.stack,
      stackValues = '';

    chart.series.forEach(function(series) {
      series.points.forEach(function(point) {
        if (currentSeries.userOptions.stack === series.userOptions.stack && currentPoint.key === point.category) {
          stackValues += "Count" + ': ' + point.count + '<br/>';
        }
      });
    });

    return currentPoint.key + '<br/>' + stackValues;
  }
},

              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                width: (window.innerWidth - 260 - 120) / 1.05,
                type: "column",
                backgroundColor: "#fff",
                options3d: {
                  enabled: true,
                  alpha: 1,
                  beta: 15,
                  // viewDistance: 25,
                  depth: 100,
                },
              },
            }}
          ></HighchartsReact>
          ) : null
        }
        </div>
      </div>
    );
  };


  getKendra = async (id) => {
    let data = await getSuperAdminService().getAllKendra({
      block_id: id,
    });

    if (data.data.status == 200) {
      this.setState({
        kendraBlockwise: data.data.response,
      });
    }
  };

  ViewKendraComparision = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block*"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                        compareKendraData:[]
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value); 
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-1" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Year">
                  Year*
                </span>
                <Select
              id="demo-controlled-open-select2"
              labelId="Year"
              style={{ backgroundColor: "#DFE4E8" }}
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                
                this.setState((i)=>(
                  {
                    ChaluSelectedYear: e.target.value,
                    compareKendraData:[]
                }),()=>{
                  this.getLoMonthsList(e.target.value);
                })
              }}
            >
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
              </FormControl>
          </div>
      
            
          </div>

            <div className="col-1">

          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Month">
                  Month*
                </span>
                <Select 
                style={{ backgroundColor: "#DFE4E8" }}
              id="demo-controlled-open-select2"
              labelId="Month"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState((i)=>(
                  {
                    ChaluSelectedMonth: e.target.value,
                    compareKendraData:[],
                    selectedSub:"",
                    learningOutcomeArray:null
                }))
              
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res?.months}</MenuItem>;
              })}
            </Select>
              </FormControl>
          </div>
         
          
          </div>


          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                Medium*
              </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedMedium}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        selectedMedium: e.target.value,
                        selectedClass: "",
                        showGraph: false,
                        compareKendraData:[]
                      },
                      () => { 
                      }
                    );
                  }}
                >
                  {this.state.mediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-1" style={{
            marginRight:"10px"
          }} >
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedClass}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedClass: e.target.value,
                        selectSubject: "",
                        showGraph: false,
                        compareKendraData:[]
                      },
                      () => {
                        this.getLoSubjectListMore();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>


          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Subject*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={this.state.selectedSub}
                  onChange={(e) => {
                    this.setState({
                      selectedSub: e.target.value,
                      compareKendraData:[]
                      
                    },()=>{
                      if(this.state.selectedSub  != null ){
                        this.getListOfLos()
                      }
                    });
                  }}
                >
                  {this.state.subjectList?.map((res) => {
                    return (
                      <MenuItem value={res}>{res.lo_subject}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.blockModal ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>
          <div className="col-1">
            <div style={styles.inputWrapper}>
            <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "10px",
                  marginTop:"20px"
                }}
                onClick={() => {
                  this.getCompareKendra()
                }}
              >
                search
              </Button> 
            </div>
         
          </div>
        </div>
        <div style={{ marginTop: "30px" }} className="row" >
        <div className="col-8">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  Select LO*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={this.state.currentLO?.lo}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                     {
                      currentLO:e.target.value,
                      compareKendraData:[]
                     }
                    );
                  }}
                >
                  {this.state.learningOutcomeArray?.map((res) => {
                    return (
                      <MenuItem value={res}>
                       {res?.level_code} : {res.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* {this.showKendraComparision(this.state.compareKendraData)} */}
           

        {
          this.state.compareKendraData?.length > 0 ? (
            <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {
                text: "",
              },
              xAxis: {
                categories: this.state.compareKendraData.map(item => item.kendra_name),
                crosshair: true,
                title: {
                  text: "Kendra",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                stackLabels: {
                  enabled: true,
                  align: "center",
                },
                title: {
          text: 'अध्ययन निष्पत्ती प्रमाण ', // Set the X-axis label to 'ayush'
        },
                visible: true,
                title: {
                  text: "Percentage",
                },
                labels: {
                  skew3d: true,
                  style: {
                    fontSize: "16px",
                  },
                },
              },

              labels: {
                items: [
                  {
                    html: "",
                    style: {
                      left: "50px",
                      top: "18px",
                    },
                  },
                ],
              },
      series: [{
        name: 'Percentage',
        data: this.state.compareKendraData.map(item => ({
    count: item.yes_count,
    y: item.percentage,
  }))
      }],

              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: true,
                  },
                  with: 15,
                  depth: 40,
                },
              },

              tooltip: {
  formatter: function() {
    var currentPoint = this,
      currentSeries = currentPoint.series,
      chart = currentSeries.chart,
      stackName = currentSeries.userOptions.stack,
      stackValues = '';

    chart.series.forEach(function(series) {
      series.points.forEach(function(point) {
        if (currentSeries.userOptions.stack === series.userOptions.stack && currentPoint.key === point.category) {
          stackValues += "Count" + ': ' + point.count + '<br/>';
        }
      });
    });

    return currentPoint.key + '<br/>' + stackValues;
  }
},

              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                width: (window.innerWidth - 260 - 120) / 1.05,
                type: "column",
                backgroundColor: "#fff",
                options3d: {
                  enabled: true,
                  alpha: 1,
                  beta: 15,
                  // viewDistance: 25,
                  depth: 100,
                },
              },
            }}
          ></HighchartsReact>
          ) : null
        }
          </div>
        </div>
      </div>
    );
  };

  getLoMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getLoMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.results)
          this.setState(
            {
              monthList: res.data.results,
              ChaluSelectedMonth: res.data.results[res.data.results?.length - 1],
              TulanaSelectedMonth:res.data.results[res.data.results?.length - 1]
            },
            () => {

            }
          );
      });
  };

  getSummaryGraph = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-1"> 
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block*"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                        levelComparsionArr:[],
                    ChaluSelectedClass:null,
                    currentPos:1,
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value); 
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-1" style={{marginRight:"40px"}} >
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>
                {/* <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="School"
                  id="School_dropdown"
                  value={school.value}
                  error={school.helperText}
                  helperText={!school.value && school.helperText}
                  onChange={(e) => {
                    this.setState({
                      school: {
                        value: e.target.value,
                        helperText: null,
                      },
                      updateschool: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.schools?.map((res) => {
                    return (
                      <MenuItem value={res.school_id}>
                        {res.school_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                      levelComparsionArr:[],
                    ChaluSelectedClass:null,
                    currentPos:1,
                    });
                    this.onChangeHandle(value);
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Kendra">
                  Kendra
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Kendra"
                  value={this.state.selectedKendra}
                  onChange={(e) => {
                    this.setState({
                      selectedKendra: e.target.value,
                      levelComparsionArr:[],
                    ChaluSelectedClass:null,
                    currentPos:1,
                    });
                  }}
                >
                  {this.state.kendraBlockwise?.map((res) => {
                    return (
                      <MenuItem value={res.kendra_id}>{res.kendra_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
           
          </div>
          <div className="col-1" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Year">
                  Year*
                </span>
                <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              style={{ backgroundColor: "#DFE4E8" }}
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                
                this.setState((i)=>(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTimeSummary: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[]  
                }),()=>{
                  this.getLoMonthsList(e.target.value);
                })
              }}
            >
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
          <div className="col-1" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Kendra">
                  Month*
                </span>
                <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              style={{ backgroundColor: "#DFE4E8" }}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState((i)=>(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTimeSummary: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[]
                  
                }))
              
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res?.months}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
          <div className="col-1" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Kendra">
                  Medium*
                </span>
                <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMedium}
              style={{ backgroundColor: "#DFE4E8" }}
              onChange={(e) => {
                console.log(e.target.value, "eee");

                this.setState((i)=>(
                  {
                    ChaluSelectedMedium: e.target.value,
                    firstTimeSummary: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[],
                    ChaluSelectedClass:null,
                }),()=>{
                  // this.getLoSubjectList()
                })
              }}
            >
              {this.state.mediumList?.map((res) => {
                return <MenuItem value={res}>{res?.medium_name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
          <div className="col-1" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Kendra">
                  Class*
                </span>
                <Select
                                  style={{ backgroundColor: "#DFE4E8" }}
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedClass}
              disabled={(this.state.ChaluSelectedMedium == null  ) ? true : false }
              onChange={(e) => {

                this.setState((i)=>({
                  ChaluSelectedClass: e.target.value,
                  firstTimeSummary: false, 
                }),()=>{
                  this.getLoSubjectList()
                })

              }}
            >
            {this.state.classList?.map((res) => {
                return <MenuItem value={res}>{res?.class_name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
          <div className="col-1" >

            {!this.state.loading ? (
              <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "10px",
                  marginTop:"20px"
                }}
                onClick={() => this.getFirstAnyltics()}
              >
                search
              </Button> 
            ) : (
              <CircularProgress />
            )}

          </div>

        </div>

        <div
        style={{
          fontSize: "20px",
          padding: "20px",
          color: "#415EB6",
          fontFamily: "Mukta",
        }}
      >
     
    
        <div >
          {this.state.levelComparsionArr?.map((res, i) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
                <div style={{ display: "flex" ,justifyContent:'center'}}>
                  <div
                    style={{
                      margin: "10px",
                      borderRadius: "10px",
                      color: "#fff",
                      backgroundColor: "#2BBC6E",
                      padding: "10px",
                      // marginLeft:
                      //   i + 1 == this.state.levelComparsionArr?.length
                      //     ? "15%"
                      //     : "35%",
                    }}
                  >
                    {this.state?.loSubjectList?.[i]?.lo_subject}
                  </div>
                </div>
                {this.getCharts(res?.analytics_data, res)}
              </div>
            );
          })}
        </div>
      </div>
      </div>
    );
  };

  getCompareBlocks =()=>{
 
      if(this.state.selectedClass != null && this.state?.selectedMedium != null && this.state.selectedSub != null && this.state.currentLO != null){
        let param = { 
            "subject":this.state.selectedSub?.id,
            "year":this.state.ChaluSelectedYear,
            "month": this.state.ChaluSelectedMonth?.id,
            "student_district_id":this.state?.district?.value,
            "month_name": this.state.ChaluSelectedMonth?.months,
            "medium": this.state.selectedMedium,
            "compare_region":"block",
            "student_class":this.state.selectedClass,
            "lo":this.state.currentLO?.id
        }

        getAdministrativeService()
          .compareBlockKendraSchoolAdmin(param)
          .then((res) => {
            if(res?.data){
              this.setState({
                compareBlockData:res?.data    
              })
            }
          });
       
    
      }
   
  }

  getCompareKendra =()=>{

    if(this.state.selectedClass != null && this.state?.selectedMedium != null && this.state.selectedSub != null && this.state.currentLO != null &&  this.state.currentLO != null && this.state.block?.value != null ){
      let param = { 
          "subject":this.state.selectedSub?.id,
          "year":this.state.ChaluSelectedYear,
          "month": this.state.ChaluSelectedMonth?.id,
          "student_district_id":this.state?.district?.value,
          "student_block_id":this.state.block?.value,
          "month_name": this.state.ChaluSelectedMonth?.months,
          "medium": this.state.selectedMedium,
          "compare_region":"kendra",
          "lo":this.state.currentLO?.id,
          "student_class":this.state.selectedClass,
      }



      getAdministrativeService()
        .compareBlockKendraSchoolAdmin(param)
        .then((res) => {
          if(res?.data){
            this.setState({
              compareKendraData:res?.data
            
            })
          }
        });
  
    }
  }

  getCompareSchool =()=>{
   
    let schoolArray = [];
    console.log(this.state.school1.school_id, "this.state.school1.value");
    if (this.state.school1.school_id) {
      schoolArray.push(this.state.school1.school_id);
      console.log(this.state.school1.school_id, "this.state.school1.value");
    }
    if (this.state.school2.school_id) {
      schoolArray.push(this.state.school2.school_id);
      console.log(this.state.school2.school_id, "this.state.school1.value");
    }
    if (this.state.school3.school_id) {
      schoolArray.push(this.state.school3.school_id);
      console.log(this.state.school3.school_id, "this.state.school1.value");
    }
    if (this.state.school4.school_id) {
      schoolArray.push(this.state.school4.school_id);
      console.log(this.state.school4.school_id, "this.state.school1.value");
    }
    if (this.state.school5.school_id) {
      schoolArray.push(this.state.school5.school_id);
      console.log(this.state.school5.school_id, "this.state.school1.value");
    }
    if(this.state.selectedClass != null && this.state?.selectedMedium != null && this.state.selectedSub != null && this.state.currentLO != null &&  this.state.currentLO != null && schoolArray?.length > 0 ){
      let param = { 
          "subject":this.state.selectedSub?.id,
          "year":this.state.ChaluSelectedYear,
          "month": this.state.ChaluSelectedMonth?.id,
          "student_district_id":this.state?.district?.value,
          "month_name": this.state.ChaluSelectedMonth?.months,
          "medium": this.state.selectedMedium,
          "compare_region":"school",
          "schools_list":schoolArray,
          "student_class":this.state.selectedClass,
          "lo":this.state.currentLO?.id,
      }



      getAdministrativeService()
        .compareBlockKendraSchoolAdmin(param)
        .then((res) => {
          if(res?.data){
            this.setState({
              compareSchoolData:res?.data
            })
          }
        });
  
    }

  }

  ViewTrendLine = () => {
    let { district, block, school, state } = this.state;
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>
                {/* <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="School"
                  id="School_dropdown"
                  value={school.value}
                  error={school.helperText}
                  helperText={!school.value && school.helperText}
                  onChange={(e) => {
                    this.setState({
                      school: {
                        value: e.target.value,
                        helperText: null,
                      },
                      updateschool: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.schools?.map((res) => {
                    return (
                      <MenuItem value={res.school_id}>
                        {res.school_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                    });
                    this.onChangeHandle(value);
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                      Medium*
                    </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              marginTop: "35px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div>वर्ष</div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    this.setAllYears();
                  }}
                  style={{
                    backgroundColor:
                      this.state.allYearSelected?.length > 0
                        ? "#DB83E2"
                        : "#C2B0BB",
                  }}
                >
                  सर्व
                </Button>
                {this.state.yearList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            selectedYear: res,
                            allYearSelected: null,
                          },
                          () => {
                            this.getMonthsList(res);
                          }
                        );
                      }}
                      style={{
                        backgroundColor:
                          this.state.selectedYear === res
                            ? "#DB83E2"
                            : "#C2B0BB",
                        marginLeft: "10px",
                      }}
                    >
                      {res}
                    </Button>
                  );
                })}
              </div>
            </div>

            <div>
              
            </div>
          </div>
        </div>

        <div
          style={{
            flexDirection: "row-reverse",
            marginTop: "35px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <div>
            {this.state.district.value == 0 ? (
              <div style={{ marginRight: "38em", width: "40%" }}>
                <div>Part</div>
                <div
                  style={{ display: "flex", flexWrap: "wrap", width: "150%" }}
                >
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: null,
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == null
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    ALL
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "FIRST HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "FIRST HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    I
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "SECOND HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "SECOND HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    II
                  </Button>
                </div>
              </div>
            ) : null}
          </div>

          <div style={{ width: "40%" }}>
            <div>महिना</div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {this.state.monthList?.map((res) => {
                return (
                  <Button
                    onClick={() => {
                      if (
                        this.state.selectedMonth?.length > 0 &&
                        this.state.selectedMonth.includes(res)
                      ) {
                        this.state.selectedMonth.splice(
                          this.state.selectedMonth.indexOf(res),
                          1
                        );
                        this.setState({
                          selectedMonth: [...this.state.selectedMonth],
                        });
                      } else {
                        this.setState({
                          selectedMonth: [...this.state.selectedMonth, res],
                        });
                      }
                    }}
                    style={{
                      backgroundColor: this.state.selectedMonth.includes(res)
                        ? "#DB83E2"
                        : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    {res}
                  </Button>
                );
              })}
              {console.log(this.state.selectedMonth, "select mnth")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
            }}
            onClick={() =>
              this.setState(
                {
                  firstTime: false,
                },
                () => {
                  if (this.state.selectedMonth.length > 0) {
                    this.getDataReports();
                  }
                }
              )
            }
          >
            View
          </Button>

          <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],

                ViewSummary: false,

                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,

                selectedSub: null,
                showKendraGraph: false,
              });
            }}
          >
            reset
          </Button>
        </div>

        {this.state.allCount ? (
          <div
            style={{
              color: "#415EB6",
              fontSize: "20px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            {console.log(this.state.allCount, "allCount")}
            <div>
              विद्यार्थी संख्या: {this.state.allCount?.total_student_in_school}
            </div>
            <div style={{ marginLeft: "20px" }}>
              {"  "} उपलब्ध नोंदी:{" "}
              {this.state.allCount?.total_available_entries}
            </div>
            {"  "}{" "}
            <div style={{ marginLeft: "20px" }}>
              अनुपलब्ध नोंदी: {this.state.allCount?.total_unavailable_entries}
            </div>
          </div>
        ) : null}

        <div class="row">
          {/* {this.showTrendLineGraph(this.state.arrayForCount)}{" "} */}
          {console.log(this.state.arrayForLine, "arrayForLine")}
          {/* {this.state.arrayForLine?.map((res, index) => {
            let array = [];
            let array1 = [];
            console.log(res, "res");
            res.map((resp) => {
              if (typeof resp !== "object") {
                array.push(resp);
              } else {
                array.push(Object.values(resp));
                // console.log(Object.keys(resp),"keys pair");
                // this.setState({
                //   monthListAxis :[...this.state.monthListAxis,res]
                // })
                // this.state.monthListAxis.push(Object.keys(resp))
              }

              console.log(resp, "resp", array);
            });
            console.log("resp", array);

            Object.values(array).map((res) => {
              console.log(typeof res, "res aray");
              if (typeof res == "object") {
                const iterator = res.values();

                for (const value of iterator) {
                  console.log(value);
                  array1.push(value);
                }
              }
            });
            console.log(Object.values(array), array1);

            return (
              <div
                class="col"
                style={{
                  padding: "5px",
                  marginTop: "25px",
                  marginBottom: "40px",
                }}
              >
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={{
                      marginLeft: "48%",
                      padding: "10px",
                      backgroundColor: "#2BBC6E",
                      borderRadius: "10px",
                    }}
                  >
                    स्तर : {this.getLevels(index)}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories: this.getCatogeriesForSpline(),
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "percentage",
                        },
                      },
                    //   tooltip: {
                    //     formatter: function() {
                    //       var currentPoint = this;
                    //  console.log(currentPoint,"currentPoint.series",this.y)
                    //  let count
                    //  console.log(this.state.allCount,"this.state.allCount)")
                    //  if(this.state.allCount)
                    //  { count = (this.y * this.state.allCount?.total_available_entries)/100}
                    //  console.log(count,"count4545")     
                    //  return currentPoint.series
                    //     }
                    //   },
                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "percentage",
                          data: array1,
                        },
                      ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth - 260 - 120) / 1.05,
                        type: "spline",
                      },
                    }}
                  ></HighchartsReact>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    );
  };


  getLineGraph = () => {
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
      lineSelectedHalf,
      selectedMediumName,
      selectedClassName,
    } = this.state;


    let monthArray = selectedMonth?.map(item => item.id) 
   let  param = {
    school: school.school_id,
    subject: selectedStage,
    month_list: monthArray,
    year_list: allYearSelected ? allYearSelected : [selectedYear],
    class_list: allClassSelected ? allClassSelected : selectedClass,
    medium_list: selectedMedium,
    medium_name: selectedMediumName,
    class_name: selectedClassName,
  };

  console.log("tell me the state",this.state)

  let url =`lo_data_analytics/get_lo_analytics_for_months/?year=[${param?.year_list}]&month=[${param?.month_list}]&student_class=${param?.class_list}&medium=${param?.medium_list}&lo_subject=${param?.subject?.id}&medium_name=${param?.medium_name}&class_name=${param?.class_name}&subject_name=${param?.subject?.lo_subject}&district=${this.state.district?.value}`
  if(this.state?.block?.value != null ){
    url= url + `&block=${this.state.block.value}`
}

if(this.state.school?.school_id != null ){
  url= url + `&school=${this.state.school?.school_id}`
}
 
if(this.state.selectedKendra != null){
 url= url + `&kendra=${this.state.selectedKendra}`
}

    getHMService()
      .getLoTrendlineAnalytics(url)
      .then((res) => {

        if(res?.data?.analytics_data){
          const entries = res?.data.analytics_data;

          if (entries.length > 0) {
            this.setState({
              arrayForLine: entries,
              allCount: res.data,
            });
          } else {
            this.setState({
              arrayForLine: entries,
              allCount: res.data,
            });
          }
        }
        else{
         this.setState({
          arrayForLine: [],
          allCount: null,
         })
        }
        
      
        // this.setState({ arrayForLine: entries, allCount: res.data.all_counts });
      });

  };

  getLineGraphTable =()=>{
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
      lineSelectedHalf,
      selectedMediumName,
      selectedClassName,
    } = this.state;
      console.log(allMediumSelected, "allMediumSelected", allClassSelected);


    let monthArray = selectedMonth?.map(item => item.id) 
   let  param = {
    school: school.school_id,
    subject: selectedStage,
    month_list: monthArray,
    year_list: allYearSelected ? allYearSelected : [selectedYear],
    class_list: allClassSelected ? allClassSelected : selectedClass,
    medium_list: selectedMedium,
    medium_name: selectedMediumName,
    class_name: selectedClassName,
  };


  let url =`lo_data_analytics/get_lo_analytics_for_months/?school=${school.school_id}&year=[${param?.year_list}]&month=[${param?.month_list}]&student_class=${param?.class_list}&medium=${param?.medium_list}&lo_subject=${param?.subject?.id}&medium_name=${param?.medium_name}&class_name=${param?.class_name}&subject_name=${param?.subject?.lo_subject}&district=${this.state.school.student_district_id}&block=${this.state.school.student_block_id}&table_view=True`
  if(this.state.school?.kendra_id != null){
    url = url + `&kendra=${this.state.school?.kendra_id}`
  }


    getHMService()
      .getLoTrendlineAnalytics(url)
      .then((res) => {
        console.log("Tell me thre sresr",res?.data)
        if(res?.data){
          this.setState({
            tab3table:res?.data?.analytics_data
          })
    
        }
      });
  }

  downloadSaraniExcel = () => {
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
      lineSelectedHalf,
      selectedMediumName,
      selectedClassName,
    } = this.state;
      console.log(allMediumSelected, "allMediumSelected", allClassSelected);


    let monthArray = selectedMonth?.map(item => item.id) 
   let  param = {
    school: school.school_id,
    subject: selectedStage,
    month_list: monthArray,
    year_list: allYearSelected ? allYearSelected : [selectedYear],
    class_list: allClassSelected ? allClassSelected : selectedClass,
    medium_list: selectedMedium,
    medium_name: selectedMediumName,
    class_name: selectedClassName,
  };


  let url =`lo_data_analytics/get_lo_analytics_for_months/?school=${school.school_id}&year=[${param?.year_list}]&month=[${param?.month_list}]&student_class=${param?.class_list}&medium=${param?.medium_list}&lo_subject=${param?.subject?.id}&medium_name=${param?.medium_name}&class_name=${param?.class_name}&subject_name=${param?.subject?.lo_subject}&district=${this.state.school.student_district_id}&block=${this.state.school.student_block_id}&table_view=True&need_export=True`
  if(this.state.school?.kendra_id != null){
    url = url + `&kendra=${this.state.school?.kendra_id}`
  }

    getHMService()
      .getLoTrendlineAnalytics(url)
      .then((res) => {
        if(res?.data?.url){
          window.open(res?.data?.url);
    
        }
      });
  };

  showLineGraph =()=>{

    let { district, block, school, state } = this.state;
    let tableArr = []
    if (this.state.tab3table?.length > 0){
      let { lo, lo__level,lo__description,year, total, ...tableObject } = this.state.tab3table[0];
      tableArr = Object.keys(tableObject);

    }

    return (
      <>
           <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block*"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                        arrayForLine:[]
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value); 
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>
                {/* <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="School"
                  id="School_dropdown"
                  value={school.value}
                  error={school.helperText}
                  helperText={!school.value && school.helperText}
                  onChange={(e) => {
                    this.setState({
                      school: {
                        value: e.target.value,
                        helperText: null,
                      },
                      updateschool: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.schools?.map((res) => {
                    return (
                      <MenuItem value={res.school_id}>
                        {res.school_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                      arrayForLine:[]
                    });
                    this.onChangeHandle(value);
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Kendra">
                  Kendra
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Kendra"
                  value={this.state.selectedKendra}
                  onChange={(e) => {
                    this.setState({
                      selectedKendra: e.target.value,
                      arrayForLine:[]
                    });
                  }}
                >
                  {this.state.kendraBlockwise?.map((res) => {
                    return (
                      <MenuItem value={res.kendra_id}>{res.kendra_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
           
          </div>
        </div>
        <div
          style={{
            borderBottomWidth: "4px",
            borderBottomColor: "black",
            marginTop: "35px",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <div>वर्ष*</div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    this.setAllYears();
                  }}
                  style={{
                    backgroundColor:
                      this.state.allYearSelected?.length > 0
                        ? "#DB83E2"
                        : "#C2B0BB",
                  }}
                >
                  सर्व
                </Button>
                {this.state.yearList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            selectedYear: res,
                            allYearSelected: null,
                            tab3table:[],
                            arrayForLine:[]
                          },
                          () => {
                            this.getLoMonthsList(res);
                          }
                        );
                      }}
                      style={{
                        backgroundColor:
                          this.state.selectedYear === res
                            ? "#DB83E2"
                            : "#C2B0BB",
                        marginLeft: "10px",
                      }}
                    >
                      {res}
                    </Button>
                  );
                })}
              </div>
            </div>

            <div style={{ marginTop: "35px" }}>
              <div>महिना*</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.monthList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        // this.createMonthArray(res)
                        if (
                          this.state.selectedMonth?.length > 0 &&
                          this.state.selectedMonth.includes(res)
                        ) {
                          this.state.selectedMonth.splice(
                            this.state.selectedMonth.indexOf(res),
                            1
                          );
                          this.setState({
                            selectedMonth: [...this.state.selectedMonth],
                            tab3table:[],
                            arrayForLine:[]
                          });
                        } else {
                          this.setState({
                            selectedMonth: [...this.state.selectedMonth, res],
                            tab3table:[],
                            arrayForLine:[]
                          });
                        }
                      }}
                      style={{
                        backgroundColor: this.state.selectedMonth.includes(res)
                          ? "#DB83E2"
                          : "#C2B0BB",
                        marginLeft: "10px",
                        minWidth: "15%",
                        marginTop: "5px",
                        padding: "5px",
                      }}
                    >
                      {res?.months}
                    </Button>
                  );
                })}
                {console.log(this.state.selectedMonth, "select mnth")}
                {/* <Button style={{ backgroundColor: "#C2B0BB" }}>Oct</Button> */}
              </div>
            </div>

            {this.state.monthDataType == "MONTH_HALF" ? (
              <div style={{ marginTop: "35px" }}>
                <div>Part</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: null,
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == null
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    ALL
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "FIRST HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "FIRST HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    I
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "SECOND HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "SECOND HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    II
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div style={{}}>
            <div>
              <div>
                <div>अध्ययनस्तर विषय निवडा.*</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.subjectList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedStage: res,
                            tab3table:[],
                            arrayForLine:[]
                          });
                        }}
                        style={{
                          width: "15%",
                          marginTop: "5px",
                          backgroundColor:
                            this.state.selectedStage?.id === res.id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                        }}
                      >
                        {res.lo_subject}
                      </Button>
                    );
                  })}
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>माध्यम निवडा*</div>
                <div style={{ display: "flex" }}>
                  {/* <Button
                    onClick={() => {
                      this.setAllMedium();
                    }}
                    style={{
                      backgroundColor:
                        this.state.allMediumSelected?.length > 0
                          ? "#DB83E2"
                          : "#C2B0BB",
                    }}
                  >
                    सर्व
                  </Button> */}
                  {this.state.mediumList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedMedium: res.medium_id,
                            allMediumSelected: null,
                            selectedMediumName:res?.medium_name,
                            tab3table:[],
                            arrayForLine:[]
                          },()=>{
                            this.getLoSubjectListMore()
                          });
                        }}
                        style={{
                          backgroundColor:
                            this.state.selectedMedium === res.medium_id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                        }}
                      >
                        {res.medium_name}
                      </Button>
                    );
                  })}

                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>सेमी</Button> */}
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>आपली इयत्ता निवडा*</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {/* <Button
                    onClick={() => {
                      this.setAllStandrs();
                    }}
                    style={{
                      backgroundColor:
                        this.state.allClassSelected?.length > 0
                          ? "#DB83E2"
                          : "#C2B0BB",
                      width: "15%",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    सर्व
                  </Button> */}
                  {this.state.classList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedClass: res.class_id,
                            allClassSelected: null,
                            selectedClassName: res?.class_name,
                            tab3table:[],
                            arrayForLine:[]
                          },()=>{
                            this.getLoSubjectListMore()
                          });
                        }}
                        style={{
                          backgroundColor:
                            this.state.selectedClass === res.class_id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                          width: "15%",
                          marginTop: "5px",
                        }}
                      >
                        {res.class_name}
                      </Button>
                    );
                  })}

                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>10 वी</Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "25px",
            display: "flex",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <Button
            onClick={() => {
              console.log(this.state.selectedMonth, "selectedMonth");
              if (this.state.selectedMonth.length > 0 && this.state.selectedStage != null ) {
                
                this.setState({
                  viewtab3Table:false
                },()=>{
                  this.getLineGraph();
                })
              }
            }}
            style={{ backgroundColor: this.state.viewtab3Table == false ? "#7965DA" : "#C2B0BB", color: "#fff" }}
          >
            View
          </Button>
          {/* <Button
            style={{
              backgroundColor: this.state.viewtab3Table == true ? "#7965DA" : "#C2B0BB",
              color: "#fff",
              marginLeft: "10px",
            }}
            onClick={() => {

              if (this.state.selectedMonth.length > 0 && this.state.selectedStage != null ) {
                this.setState({
                  viewtab3Table:true
                },()=>{
                  this.getLineGraphTable();
                })
              }
              
            }}
          >
            सारणी पहा
          </Button> */}
        </div>

        {
          this.state.viewtab3Table == false ? (
            <>

            {
              this.state.arrayForLine?.length > 0  ? (
                  <>
                  {this.state.allCount ? (
          <div
            style={{
              color: "#415EB6",
              fontSize: "20px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            {console.log(this.state.allCount, "allCount")}
            <div>
              विद्यार्थी संख्या: {this.state.allCount?.total_student}
            </div>
            <div style={{ marginLeft: "20px" }}>
              {"  "} उपलब्ध नोंदी:{" "}
              {this.state.allCount?.total_student_uploaded}
            </div>
            {"  "}{" "}
            <div style={{ marginLeft: "20px" }}>
              अनुपलब्ध नोंदी: {this.state.allCount?.total_student_not_uploaded}
            </div>
          </div>
        ) : null}
                  </>
              ) : null
            }

      

        <div class="row">
          {console.log(this.state.arrayForLine, "arrayForLine")}

          {this.state.arrayForLine?.map((res, index) => {

            let { lo, lo__level,lo__description, ...newObject } = res;

            const categories = Object.keys(newObject);
            const values = Object.values(newObject);
            console.log("what is res",res)

          let array1=[]

            return (
              <div
                class="col"
                style={{
                  padding: "5px",
                  marginTop: "25px",
                  marginBottom: "40px",
                }}
              >
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={{
                      marginLeft: "48%",
                      padding: "10px",
                      backgroundColor: "#2BBC6E",
                      borderRadius: "10px",
                    }}
                  >
                    स्तर : {res?.lo__level}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        // categories: this.getCatogeriesForSpline(),
                        categories: categories,
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "Count",
                          data: values,
                        },
                      ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth - 260 - 120) / 1.05,
                        type: "spline",
                      },
                    }}
                  ></HighchartsReact>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      marginLeft: "5%",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                     {res?.lo__level} : 
                  </div>
                  <div
                    style={{
                      marginLeft: "5%",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                  {res?.lo__description}
                  </div>
                </div>
              </div>
            );
          })}
          {/* {data1.map((res) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    title: {
                      text: "",
                    },
                    xAxis: {
                        categories: [this.state.selectedMonth],
                      crosshair: true,
                    },
                    yAxis: {
                      stackLabels: {
                        enabled: true,
                        align: "center",
                      },
                      visible: true,
                      title: {
                        enabled: true,
                        text:
                           "count",
                      },
                    },

                    labels: {
                      items: [
                        {
                          html: "",
                          style: {
                            left: "50px",
                            top: "18px",
                          },
                        },
                      ],
                    },
                    series: [
                      {
                        name: "Population",
                        data: [
                          ["Shanghai", 3.7],
                          // ["Lagos", 16.1],
                          // ["Istanbul", 14.2],
                          // ["Karachi", 14.0],
                          // ["Karachi", 14.0],
                        ],
                      },
                    ],
                    chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      width: window.innerWidth / 2.8,
                      type: "spline",
                    },
                  }}
                ></HighchartsReact>
                <div>
                  विद्यार्थी संख्या: १२५ उपलब्ध नोंदी: १२५ अनुपलब्ध नोंदी: १२
                </div>
              </div>
            );
          })} */}
        </div>

     
            </>
          ) : (
              <>
              {
                this.state.tab3table?.length > 0 ? (
                  <div style={{
                    marginBottom:"100px"
                  }}>
                  <div style={{
              display:"flex",
              justifyContent:"center",
              fontSize:"20px",
              color:"#415EB6",
              marginTop:"30px",
              marginBottom:"30px"
            }} >
           <span style={{
            marginRight:"10px"
           }} >
           वर्ग: {this.state.selectedClassName} 
           </span> 
           <span
           style={{
            marginRight:"10px",
            marginLeft:"10px"
           }} 
           >
           माध्यम: {this.state.selectedMediumName}
           </span>
            <span>
            विषय:{this.state.selectedStage?.lo_subject}
            </span>
            </div>
            <TableContainer component={Paper}>
                    <Table
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                        <TableCell align="left"></TableCell>
                          <TableCell align="center">Subject : {this.state.selectedStage?.lo_subject} </TableCell>
                          <TableCell align="right">Year : {
                            this.state.allYearSelected ? (this.state.allYearSelected?.map((res)=>{
                              return (`${res} `)
                            })) : this.state.selectedYear
                          } </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">LO Code</TableCell>
                          <TableCell align="center">Description / Meaning</TableCell>
                            {
                              tableArr?.map((res)=>{
                                return(
                                  <TableCell align="center">{res}</TableCell>
                                )
                              })
                            }
                          <TableCell align="center">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.tab3table.map((row, index) => {

let { lo, lo__level,lo__description,year, total, ...tableObject } = row;
const objectEntries = Object.values(tableObject);

return(
  <TableRow key={index}>
                            <TableCell
                              
                              align="left"
                            >
                              {row?.lo__level}
                            </TableCell>
                            <TableCell align="center">
                              {row?.lo__description}
                            </TableCell>
                            {
                              objectEntries?.map((res)=>{
                                return(
                                  <TableCell align="center">
                              {res}
                            </TableCell> 
                                )
                              })
                            }
                            <TableCell align="center">
                              {row?.total}
                            </TableCell>
                          </TableRow>
)
                        }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
          style={{
            marginTop: "25px",
            display: "flex",
            justifyContent: "flex-end",
            color: "#fff",
          }}  
        >
          <Button
            style={{
              backgroundColor: "#C2B0BB",
              color: "#000",
              marginLeft: "10px",
              marginBottom:"200px",
              marginRight:"50px"
            }}
            onClick={() => {
              if(this.state.selectedMonth.length > 0 && this.state.selectedStage != null){
                this.downloadSaraniExcel()
              }
            }}
          >
        डाउनलोड करा
          </Button>
        </div>
        

                  </div>
                ) : null
               }
              </>
          ) 
        }

      </>
    );
  }

  showDataEntry =()=>{
    let { district, block, school, state, block1, block2, block3 } = this.state;

    return(
      <>
        {
          this.state.csvDownloading == true ? (
            <div style={{
                marginLeft:"50%",
                marginTop:"50px"
              }} >
                <CircularProgress 
                       color="inherit"
                          size={30}
                       />
                  </div>
          ) : (
            <>
  <div style={{ marginTop: "30px" }} className="row">
          <div className="col-1">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  disabled
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2"> 
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block*"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value); 
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-1" style={{marginRight:"40px"}} >
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>
                {/* <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="School"
                  id="School_dropdown"
                  value={school.value}
                  error={school.helperText}
                  helperText={!school.value && school.helperText}
                  onChange={(e) => {
                    this.setState({
                      school: {
                        value: e.target.value,
                        helperText: null,
                      },
                      updateschool: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.schools?.map((res) => {
                    return (
                      <MenuItem value={res.school_id}>
                        {res.school_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                    });
                    this.onChangeHandle(value);
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Kendra">
                  Kendra
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Kendra"
                  value={this.state.selectedKendra}
                  onChange={(e) => {
                    this.setState({
                      selectedKendra: e.target.value,
                    });
                  }}
                >
                  {this.state.kendraBlockwise?.map((res) => {
                    return (
                      <MenuItem value={res.kendra_id}>{res.kendra_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
           
          </div>
          <div className="col-2" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Kendra">
                  Medium*
                </span>
                <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMedium}
              style={{ backgroundColor: "#DFE4E8" }}
              onChange={(e) => {
                console.log(e.target.value, "eee");

                this.setState((i)=>(
                  {
                    ChaluSelectedMedium: e.target.value,
                    firstTimeSummary: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[],
                    ChaluSelectedClass:null,
                }),()=>{
                  // this.getLoSubjectList()
                })
              }}
            >
              {this.state.mediumList?.map((res) => {
                return <MenuItem value={res}>{res?.medium_name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
          <div className="col-2" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Kendra">
                  Class*
                </span>
                <Select
                                  style={{ backgroundColor: "#DFE4E8" }}
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedClass}
              disabled={(this.state.ChaluSelectedMedium == null  ) ? true : false }
              onChange={(e) => {

                this.setState((i)=>({
                  ChaluSelectedClass: e.target.value,
                  firstTimeSummary: false, 
                }),()=>{
                  this.getLoSubjectList()
                })

              }}
            >
            {this.state.classList?.map((res) => {
                return <MenuItem value={res}>{res?.class_name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
        </div>
        <div className="row" style={{
          display:"flex",
          flexDirection:"row",
          justifyContent:"center",
          marginTop:"30px"
        }} >
        <div className="col-2" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Year">
                  Year*
                </span>
                <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              style={{ backgroundColor: "#DFE4E8" }}
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                
                this.setState((i)=>(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTimeSummary: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[]  
                }),()=>{
                  this.getLoMonthsList(e.target.value);
                })
              }}
            >
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
          <div className="col-2" >
          <div style={styles.inputWrapper}>
          <FormControl fullWidth>
          <span style={{ color: "#415EB6" }} id="Kendra">
                  Month*
                </span>
                <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              style={{ backgroundColor: "#DFE4E8" }}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState((i)=>(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTimeSummary: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[]
                  
                }))
              
              }}
            >
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res?.months}</MenuItem>;
              })}
            </Select>
          </FormControl>
          </div>
          </div>
        </div>
                  <div style={{
                    justifyContent:"center",
                    display:"flex"
                  }} >

            {!this.state.loading ? (
              <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "10px",
                  marginTop:"20px"
                }}
                onClick={() => this.getLODataSummary()}
              >
                search
              </Button> 
            ) : (
              <CircularProgress />
            )}

          </div>

      </>
          )
        }
      </>
    )
  }

  getLODataSummary =()=>{

    if(this.state.ChaluSelectedYear != null && this.state.ChaluSelectedMonth != null){
      let param = {
        school: this.state.school.school_id,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth, 
        block:this.state.block.value,
        kendra:this.state.selectedKendra,
        medium:this.state.ChaluSelectedMedium,
        class:this.state.ChaluSelectedClass,
        district:this.state.district?.value
      }

      const foundObject = this.state.districtList.find(dis => dis?.student_district_id == param?.block  );


      let url = `/lo_data_analytics/data_entry_report/?year=${param?.year}&month=${param?.month?.id}&district_id=${param.district}`
      if(this.state.school?.school_id){
        url = url + `&school_id=${param?.school}`
      }
      if(this.state.ChaluSelectedMedium){
        url = url + `&medium_id=${param?.medium?.medium_id}`
      }
      if(this.state.ChaluSelectedClass){
        url = url + `&class_id=${param?.class?.class_id}`
      }
      if(this.state.selectedKendra){
        url = url + `&kendra_id=${param?.kendra}`
      }
      if(this.state.block.value){
        url = url + `&block_id=${param?.block}`
      }

      console.log("Tell me the url",url)

      getAdministrativeService()
      .getLODataEntryReport(url)
      .then((res) => {
        console.log(res,"res")
        if (res) {
          this.setState({
            dataSummary:res?.data,
            modalOpen:true
          })

        
        }
      });

     
    }


  }

  getCSVDataReports = () => {


    if(this.state.ChaluSelectedYear != null && this.state.ChaluSelectedMonth != null){
      this.setState({ modalOpen: false,csvDownloading:true });

      const disObject = this.state.districtList.find(dis => dis?.student_district_id == this.state.district?.value);

      let param = {
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth?.id,
        month_name:this.state.ChaluSelectedMonth?.months, 
        student_district_id:this.state.district?.value,
        district_name: disObject?.district_name,
        report_type : "district"
      }


      if(this.state.school?.school_id){
        param.school = this.state.school.school_id
      }
      if(this.state.ChaluSelectedMedium){
        param.medium = this.state.ChaluSelectedMedium?.medium_id
        param.medium_name = this.state.ChaluSelectedMedium?.medium_name
      }
      if(this.state.ChaluSelectedClass){
        param.student_class = this.state.ChaluSelectedClass?.class_id
        param.class_name = this.state.ChaluSelectedClass?.class_name
      }
      if(this.state.selectedKendra){
        param.kendra_id = this.state.selectedKendra
      }
      if(this.state.block.value){
        const blockObject = this.state.blocks.find(dis => dis?.student_block_id == this.state.block.value);
        param.student_block_id = this.state.block.value
        param.block_name = blockObject?.block_name
        param.report_type = "block"
      }

      getAdministrativeService()
      .getLOCSVDataEntryReport(param)
      .then((res) => {
      
        if (res) {

          this.setState({
            csvDownloading:false
          })

          window.open(res.data.pdf_url);
        
        }
      });
    }

  };

  render() {
    let { district, block, school, state, block1, block2, block3 } = this.state;

    return (
      <>
       <Dialog
          style={{
            borderRadius: "10px",
            fontWeight: "bold",
            borderRadius: "20px",
          }}
          fullWidth
          maxWidth="sm"
          //fullWidth={true}
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({
              modalOpen: false,
            });
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            style={{
              backgroundColor: "#7965DA",
              color: "#fff",
              textAlign: "center",
            }}
            id="form-dialog-title"
          >
            Data Entry Summary
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#D9D9D9" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>वर्ष : {this.state.ChaluSelectedYear}</div>
              <div>वर्ग: {this.state.ChaluSelectedClass?.class_name}</div>
            </div>

            <div
              style={{
                marginLeft: "-10px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>महिना: {this.state.ChaluSelectedMonth?.months}</div>
              {this.state.district.value == 0 ? (
                <div>भाग: {this.state.selectedHalf ? this.state.selectedHalf:'सर्व'}</div>
              ) : null}
              <div>माध्यम: {this.state.ChaluSelectedMedium?.medium_name}</div>
            </div>

            <div
              style={{ borderBottom: "2px solid #7965DA", marginTop: "5px" }}
            ></div>

            <div
              style={{
                marginTop: "40px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>
              Total Outcome Achieved: {this.state.dataSummary?.total_lo_assessed}
              </div>
              <div>
                Total Schools found: {this.state.dataSummary?.total_schools_found}
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>
                Total Students found: {this.state.dataSummary?.total_students_found}
              </div>
              <div>
                Schools with Zero entries:{" "}
                {this.state.dataSummary?.schools_with_no_entry}
              </div>
            </div>

            <div
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div
                style={{
                  backgroundColor: "#7965DA",
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                  cursor:'pointer'
                }}
                onClick={() => this.getCSVDataReports()}
              >
                Download CSV
              </div>
              <div
                style={{
                  backgroundColor: "#C2B0BB",
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                }}
                onClick={() => this.setState({ modalOpen: false })}
              >
                रद्द करा
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <div
          style={{ display: "flex", textAlign: "center", flexDirection: "row" }}
        >
          <div
            style={{
              backgroundColor: this.state.ViewSummary ? "#6872DD" : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                ViewTrend: false,
                ViewSummary: true,
                districtComparision: false,
                viewDataEntry:false,
                schoolComparision: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                levelComparsionArr: [],
                showSchoolGraph: false,
                showGraph: false,
                compareKendra: false,
                showKendraGraph: false,
                viewLineGraph:false,
                schoolListArray: [],
                arrayForCount: null,
                schoolSelected:true,
                schoolListArray:null,
                selectedKendra:null,
                levelComparsionArr: [],
                loSubjectList:[],
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                selectedLoSubject:null,
                currentPos:0,
                firstTimeSummary: false,
                tab3table:[],
                viewtab3Table:false,
                learningOutcomeArray:[],
                currentLO:null,
                selectedClass:null,
                mediumSelected:null,
                compareKendraData:[],
                compareSchoolData: [],
                subjectList:null,
                arrayForLine:[]
              });
            }}
          >
            View Summary Graph
          </div>

         
          <div
            style={{
              marginLeft: "20px",
              backgroundColor: this.state.districtComparision
                ? "#6872DD"
                : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],
                districtComparision: true,
                ViewSummary: false,
                ViewTrend: false,
                viewDataEntry:false,
                schoolComparision: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,
                compareKendra: false,
                selectedSub: null,
                showKendraGraph: false,
                viewLineGraph:false,
                arrayForCount: null,
                schoolSelected:true,
                schoolListArray:null,
                selectedKendra:null,
                levelComparsionArr: [],
                loSubjectList:[],
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                selectedLoSubject:null,
                currentPos:0,
                firstTimeSummary: false,
                tab3table:[],
                viewtab3Table:false,
                learningOutcomeArray:[],
                currentLO:null,
                selectedClass:null,
                mediumSelected:null,
                compareBlockData: [],
                compareKendraData:[],
                compareSchoolData: [],
                subjectList:null,
                arrayForLine:[]
              });
            }}
          >
            Compare Blocks
          </div>
          <div
            style={{
              marginLeft: "20px",
              backgroundColor: this.state.compareKendra ? "#6872DD" : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],
                districtComparision: false,
                ViewSummary: false,
                ViewTrend: false,
                viewDataEntry:false,
                schoolComparision: false,
                compareKendra: true,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,
                selectedSub: null,
                showKendraGraph: false,
                BlockValidation: false,
                subValidation: false,
                viewLineGraph:false,
                arrayForCount: null,
                schoolSelected:true,
                schoolListArray:null,
                selectedKendra:null,
                levelComparsionArr: [],
                loSubjectList:[],
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                selectedLoSubject:null,
                currentPos:0,
                firstTimeSummary: false,
                tab3table:[],
                viewtab3Table:false,
                learningOutcomeArray:[],
                currentLO:null,
                selectedClass:null,
                mediumSelected:null,
                compareBlockData: [],
                compareKendraData:[],
                compareSchoolData: [],
                subjectList:null,
                arrayForLine:[]
              });
            }}
          >
            Compare Kendra
          </div>
          <div
            style={{
              marginLeft: "20px",
              backgroundColor: this.state.schoolComparision
                ? "#6872DD"
                : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({
                levelComparsionArr: [],
                districtComparision: false,
                ViewSummary: false,
                ViewTrend: false,
                viewDataEntry:false,
                schoolComparision: true,
                compareKendra: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                showSchoolGraph: false,
                showGraph: false,
                selectedSub: null,
                showKendraGraph: false,
                viewLineGraph:false,
                arrayForCount: null,
                schoolSelected:true,
                schoolListArray:null,
                selectedKendra:null,
                levelComparsionArr: [],
                loSubjectList:[],
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                selectedLoSubject:null,
                currentPos:0,
                firstTimeSummary: false,
                tab3table:[],
                viewtab3Table:false,
                learningOutcomeArray:[],
                currentLO:null,
                selectedClass:null,
                mediumSelected:null,
                compareBlockData: [],
                compareKendraData:[],
                compareSchoolData: [],
                subjectList:null,
                arrayForLine:[]
              });
            }}
          >
            Compare Schools
          </div>
          <div
            style={{
              backgroundColor: this.state.viewLineGraph ? "#6872DD" : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
              marginLeft:"20px"
            }}
            onClick={() => {
              this.setState({
                ViewTrend: false,
                ViewSummary: false,
                viewDataEntry:false,
                districtComparision: false,
                schoolComparision: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                levelComparsionArr: [],
                showSchoolGraph: false,
                showGraph: false,
                compareKendra: false,
                showKendraGraph: false,
                viewLineGraph:true,
                arrayForCount: null,
                schoolSelected:true,
                schoolListArray:null,
                selectedKendra:null,
                levelComparsionArr: [],
                loSubjectList:[],
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                selectedLoSubject:null,
                currentPos:0,
                firstTimeSummary: false,
                tab3table:[],
                viewtab3Table:false,
                learningOutcomeArray:[],
                currentLO:null,
                selectedClass:null,
                mediumSelected:null,
                compareBlockData: [],
                compareKendraData:[],
                compareSchoolData: [],
                subjectList:null,
                arrayForLine:[]
              });
            }}
          >
            View Trend
          </div>
          <div
            style={{
              backgroundColor: this.state.viewDataEntry ? "#6872DD" : "#B6B0CF",
              padding: "10px",
              width: "15%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
              marginLeft:"20px"
            }}
            onClick={() => {
              this.setState({
                ViewTrend: false,
                ViewSummary: false,
                viewDataEntry:true,
                districtComparision: false,
                schoolComparision: false,
                block: {
                  value: null,
                  helperText: null,
                },
                school: {
                  value: null,
                  helperText: null,
                },
                mediumSelected: null,
                selectedStandard: null,
                levelComparsionArr: [],
                showSchoolGraph: false,
                showGraph: false,
                compareKendra: false,
                showKendraGraph: false,
                viewLineGraph:false,
                arrayForCount: null,
                schoolSelected:true,
                schoolListArray:null,
                selectedKendra:null,
                levelComparsionArr: [],
                loSubjectList:[],
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                selectedLoSubject:null,
                currentPos:0,
                firstTimeSummary: false,
                tab3table:[],
                viewtab3Table:false,
                learningOutcomeArray:[],
                currentLO:null,
                selectedClass:null,
                mediumSelected:null,
                compareBlockData: [],
                compareKendraData:[],
                compareSchoolData: [],
                subjectList:null,
                arrayForLine:[]
              });
            }}
          >
            Data Entry Reports
          </div>

        </div>
        { this.state.schoolComparision
          ? this.ViewschoolComparision()
          : this.state.districtComparision
          ? this.ViewDistrictComparision()
          : this.state.compareKendra
          ? this.ViewKendraComparision()
          : this.state.viewLineGraph
          ? this.showLineGraph()
          : this.state.viewDataEntry 
          ? this.showDataEntry()
          : this.getSummaryGraph()}
      </>
    );
  }
}