import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Admin from "layouts/Admin";
import Login from "layouts/Login/Login";
import OTP from "./containers/OTP/OTP";
import ContentWriter from "layouts/ContentWriter";
import { createBrowserHistory } from "history";
import * as constants from "./common/constants";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online';
import Snackbar from "components/Snackbar/Snackbar";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
  },
});

const App = () => {
  const hist = createBrowserHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const [status, setStatus] = useState(true);

  useEffect(() => {
    listeners();
  }, []);

  const listeners = useCallback(() => {
    window.addEventListener("online", () => setStatus(true));
    window.addEventListener("offline", () => setStatus(false));
    return () => {
      window.removeEventListener("online", () => setStatus(true));
      window.removeEventListener("offline", () => setStatus(false));
    };
  }, []);
  return (
    <>
    <Snackbar
        autoHideDuration={2000}
        message={  "No internet. Please check your connection" }
        color={"danger" }
        close={false}
        place="br"
         closeNotification={() => setStatus(true)}
         rtlActive={true}
        open={status ? false : true }
      ></Snackbar>
     <ThemeProvider theme={theme}>
      <Router history={hist}>
        <ErrorBoundary>
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/user" component={ContentWriter} />
            {user && user.user_type === constants.USER_TYPE.SUPER_ADMIN && (
              <Redirect to="/admin/dashboard" />
            )}
            {user && user.user_type === constants.USER_TYPE.CONTENT_WRITER && (
              <Redirect to="/user/create-lesson" />
            )}
            <Route path="/login" component={Login} />
            <Route path="/verify" component={OTP} />
            <Redirect from="/" to="/login" />
            {!user && <Redirect to="/login" />}
          </Switch>
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
    </>
  );
};

export default App;
