import React from "react";
import MaterialTable from "material-table";
import * as moment from "moment";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import { getSuperAdminService } from "../../service/service";
import { AddAlertRounded, AddCircleOutlineOutlined, AddCircleOutlineRounded, Cancel } from "@material-ui/icons";
import UserProfile from "views/UserProfile/UserProfile";
import { Button, FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import AlertDialog from "components/AlertDialog/AlertDialog";

const inputChanged = false;

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

export default class AdminCWTableList extends React.Component {
  state = {
    column: [],
    data: [],
    query: null,
    page: 0,
    modalOpen:false,
    alertBox : false,
    rowDataCheck : ""
  };

  componentDidMount() {
    this.setState({
      column: [
        {
          field: "admin_id",
          title: "Id",
          editable: "never",
        },
        {
          field: "email",
          title: "Email",
          editable: "never",
        },
        {
          field: "full_name",
          title: "Name",
          editable: "never",
        },
        {
          field: "user_type",
          title: "User Type",
          editable: "never",
        },
        {
          field: "mobile",
          title: "Mobile",
          editable: "never",
        },
        {
          field: "status",
          title: "Status",
          render: (rowData) => {            
            return <FormControlLabel
            control={
              <Switch checked={rowData.status == "ACTIVE" ? true : false} 
              color="primary"
              onChange={(e)=>{
                console.log(rowData.status,"Status")
                this.setState({
                  alertBox : true,
                  rowData :rowData
                })
              }
               } 
               name={rowData.status} />
            }
            label={rowData.status}
          />
          },
          editComponent: (props) => {
            return (
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={props.value}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
              >
                <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
              </Select>
            );
          },
        },
        {
          field: "last_login",
          title: "Last Login",
          editable: "never",
          render: (rowData) => {
            var now = moment(new Date());
            var date = moment(rowData.last_login);
            const weeks = now.diff(date, "weeks");
            const days = now.diff(date, "days");
            const hours = now.diff(date, "hours");
            const mins = now.diff(date, "minutes");

            if (weeks) {
              return `${weeks} weeks ago`;
            } else if (days) {
              return `${days} days ago`;
            } else if (hours) {
              return `${hours} hours ago`;
            } else if (mins) {
              return `${mins} mins ago`;
            }
          },
        },
      ],
    });
  }

  debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  getData = (query) => {
    return new Promise(async (resolve, reject) => {
      let data = {
        page_number: query ? query.page + 1 : 1,
        record_per_page: query ? query.pageSize : 1,
        order_column: "created_at",
        order_type: 1,
        user_type: "",
        searchText: query ? query.search : "",
      };
      this.setState({
        query: query ? query.search : "",
        page: query ? query.page : 1,
      });
      let res = await getSuperAdminService().getAllAdminContentWriterList(data);
      res = res.data;
      if (res && res.status == 200) {
        resolve({
          data: res.response.adminTeacherList,
          page: query ? query.page : 1,
          totalCount: res.response.total_count,
        });
      } else {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
      }
    });
  };

  onSubmitHandler = async (rowData, classId) => {
    let statusAdmin
    console.log(this.state.rowData,"rowData");
    if(this.state.rowData.status == "ACTIVE")
    {
      statusAdmin = "INACTIVE"
    }
    else  if(this.state.rowData.status == "INACTIVE"){
      statusAdmin ="ACTIVE"
    }
    console.log(statusAdmin,"final Status")
    let data = {
      user_id: this.state.rowData.user_id,
      status: statusAdmin,
    };
    let res = await getSuperAdminService().updateAdminCWStatus(data);
    res = res.data;
    if (res && res.status == 200) {
      //this.getData();
      window.location.reload();
      this.setState({
        alertBox : false,
        rowDataCheck : statusAdmin
        
      })
    }
  };

  goToUserList = () =>{
    this.props.history.push("/admin/all-users");
  }

  render() {
    return (
      <>
       <AlertDialog
        open={this.state.alertBox}
        title="Change Status?"
        text="Do you want to Change Status?"
        onCancel={() => this.setState({
          alertBox : false
        })}
        onConfirm={() =>{
          this.onSubmitHandler()
        }}
      />
      <Dialog
    fullWidth
    maxWidth="md"
            open={this.state.modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
            aria-labelledby="form-dialog-title"
          >
        <DialogContent  >
        <Button
        onClick={()=>{
          this.setState({
            modalOpen: false,
          });
        }} style={{ display:"flex"  , float:"right"}}>
          
          <Cancel/></Button>
         <UserProfile onRedirect = {()=>this.goToUserList()}/>
        
        </DialogContent>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={styles.cardTitleWhite}>User List</h4>
              <p className={styles.cardCategoryWhite}></p>
              <span>Manage your users</span>
              <Tooltip title="Add user">
              <Button
              onClick={()=>{ this.setState({ modalOpen: true }) }}
              style={{background :"white" , marginLeft:"75%" ,}}>
                Add user
                </Button>
              </Tooltip>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title={""}
                columns={this.state.column}
                data={(query) => {
                  if (query.search != this.state.query) {
                    return this.getData(query);
                  } else if (query.page != this.state.page) {
                    return this.getData(query);
                  } else {
                    return this.getData(query);
                  }
                }}
                actions={[
                  {
                    icon: "remove_red_eye",
                    tooltip: "View/edit User",
                    onClick: (event, rowData) => {
                      this.props.history.push({
                        pathname: `/admin/all-user/${rowData.user_id}`,
                        state: { data: rowData },
                      });
                    },
                  },
                ]}
                // editable={{
                //   onRowUpdate: (newData) => this.onSubmitHandler(newData),
                // }}
                options={{
                  sorting: true,
                  search: true,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </>
    );
  }
}
