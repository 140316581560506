"use client";
import React, { useEffect, useState } from "react";
import "./Milestone.css";
import MilestoneList from "./components/MilestoneList";
import MilestoneNavbar from "./components/MilestoneNavbar";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { Link, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar";

function Milestone() {
  const history = useHistory();
  const [page, setPage] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState("");

  useEffect(() => {
    const data = history.location.state?.coming;
    if (!data) {
      setPage("ongoing");
    } else {
      setPage(data);
    }
  });

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        mess
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => {
          setMessage("");
        }}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>
      <div className="whiteBackground p-4 milestones vh100">
        <MilestoneNavbar
          page={page}
          pageLink={"Create Milestone"}
          path={"milestone"}
          setPage={setPage}
        />
        <div>
          <MilestoneList
            page={page}
            setMessage={setMessage}
            setError={setError}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            count={count}
            setCount={setCount}
          />
        </div>
        <div className="pagination py-4 px-3 d-flex justify-content-end">
          <div
            className={`${
              offset / limit + 1 > 1 ? "d-flex" : "d-none"
            } paginationNumber`}
            onClick={() => {
              setOffset(offset - limit);
            }}
          >
            <GrPrevious />
          </div>
          <div
            className={`${
              offset / limit + 1 > 1 ? "d-flex" : "d-none"
            } paginationNumber Regular12`}
            onClick={() => {
              setOffset(0);
            }}
          >
            1
          </div>
          <div
            className={`${
              offset / limit + 1 > 3 ? "d-flex" : "d-none"
            } Regular12`}
          >
            ...
          </div>
          <div
            className={`${
              offset / limit + 1 > 2 ? "d-flex" : "d-none"
            } paginationNumber Regular12`}
          >
            {offset / limit}
          </div>

          <div className="paginationNumber Regular12 primaryPurple">
            {offset / limit + 1}
          </div>

          <div
            className={`${
              Math.ceil(count / limit) > offset / limit + 2
                ? "d-flex"
                : "d-none"
            } paginationNumber Regular12`}
          >
            {offset / limit + 1 + 1}
          </div>
          <div
            className={`${
              Math.ceil(count / limit) > offset / limit + 3
                ? "d-flex"
                : "d-none"
            } Regular12`}
          >
            ...
          </div>
          <div
            className={`${
              Math.ceil(count / limit) > offset / limit + 1
                ? "d-flex"
                : "d-none"
            } paginationNumber Regular12`}
            onClick={() => {
              setOffset((Math.ceil(count / limit) - 1) * limit);
            }}
          >
            {Math.ceil(count / limit)}
          </div>

          <div
            className={`${
              Math.ceil(count / limit) > offset / limit + 1
                ? "d-flex"
                : "d-none"
            } paginationNumber`}
            onClick={() => {
              setOffset(offset + limit);
            }}
          >
            <GrNext />
          </div>
        </div>
      </div>
    </>
  );
}

export default Milestone;
