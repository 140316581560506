//default imports
import { Card, CircularProgress, FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { getSuperAdminService } from "service/service";
import classes from "./SMCAnalytics.module.css";
import Highcharts from "highcharts";
import { getAdminService } from "service/service";
import { getAdministrativeService } from "service/service";
import highcharts3d from "highcharts/highcharts-3d";
import { array } from "prop-types";
highcharts3d(Highcharts);
export default class HMDashboardPage extends React.Component {
  state = {
    Overview : true,
    Facilities:false,
    Students:false,
    SMC:false,
    Schoolership:false,
    blocks:[],
    schools: [],
    districtID: JSON.parse(localStorage.getItem("AMdetails")),
    isSetAuto: false ,
    loading:false,
    block: {
      value: null,
      helperText: null,
    },
    school: {
      value: null,
      helperText: null,
    },
    smcArry:[],
    smcStudentArrya:[],
    showStudentGraph:false,
    smcMembersData:[],
    showSMCGraph:false,
    smcScholershipData:[],
    showScholershipGraph:false,
    showFacilitiesGraph:false,
    smcFacilties:[],
    showExtraFacilities:false,
    smcExtraFacilties:[]
  };

componentDidMount(){
this.getSMCDataPoints()
  this.getStudentBlock()
}

getSMCDataPoints = (id) =>{

  let param={
    student_district_id :  this.state.districtID.student_district_id
  }

  if(this.state.block.value)
  {
    param['student_block_id'] = this.state.block.value
  }
  console.log(this.state.school,"this.state.school")
  if(id)
  {
    param['school_id'] = id
  }
  getAdministrativeService().getSMCDataPoints(param).
  then((res)=>{
    console.log(res,"res")
  if(res)
  {
    this.setState({
      smcArry: res.data
    })
  }
  })
}
  getStudentBlock = async (district_id, singleSchoolFlag) => {
    console.log(this.state.districtID.student_district_id,"districtID")
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: this.state.districtID.student_district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
            },
            () => {
              this.getSchools(this.state.block.value);
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

 

  getKendra = async (id) => {
    let data = await getSuperAdminService().getAllKendra({
      block_id: id,
    });

    if (data.data.status == 200) {
      this.setState({
        kendraBlockwise: data.data.response,
      });
    }
  };


  getSchools = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
           
          });
        } else {
          this.setState({
            schools: [],
        
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
    
        });
      });
  };

  showOverview = ( ) =>{
    const { block,school,smcArry} = this.state
    console.log(smcArry,"smcArry")
    return(
      <>
      <div style={{ display:'flex', justifyContent:'center',marginTop:'20px'}}>
          <div style={{ width:'9%'}}>
            <div >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },
                        showKendraGraph: false,
                        BlockValidation: false,
                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value);
                        this.getSMCDataPoints()
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.BlockValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>
            

            <div style={{ width:'15%',marginLeft:'20px'}}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        school: value,
                      },
                      ()=>{
                        this.getSMCDataPoints(value.school_id)
                      });
                     
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school.value}
                        error={school.helperText}
                        helperText={!school.value && school.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

        </div>

        <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row',justifyContent:'space-evenly'}}>

            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total Schools</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_schools}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total Teachers</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_teachers}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total Students</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_students}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total SMC Members</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_committee_member}</div>
            </Card>


        </div>

        <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row',justifyContent:'space-evenly'}}>

<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}> Total Male Teachers</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_male_teachers}</div>
</Card>
<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}> Total Female Teachers</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_female_teachers}</div>
</Card>
<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}> Schools to student ratio</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.school_to_student}</div>
</Card>
<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}>  Teacher to student ratio</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.teacher_to_student}</div>
</Card>


        </div>
        
        <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row',justifyContent:'space-evenly'}}>

<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}>  Schools to book ratio</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.school_to_books}</div>
</Card>
<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}> Total Books</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_books}</div>
</Card>
<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}> Total Boys</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_boys}</div>
</Card>
<Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
  <div  style={{ fontSize:'14px'}}> Total Girls</div>

   <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_girls}</div>
</Card>


        </div>
              </>
    )

  }

  showStudenst= ( ) =>{
    const { block,school,smcStudentArrya,smcArry} = this.state
     
    return(
      <>
      <div style={{ display:'flex', justifyContent:'center',marginTop:'20px'}}>
          <div style={{ width:'9%'}}>
            <div >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },
                        showKendraGraph: false,
                        BlockValidation: false,
                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value);
                        this.getSMCDataPoints()
                        this.getStudentData()
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.BlockValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>
            

            <div style={{ width:'15%',marginLeft:'20px'}}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        school: value,
                      },
                      ()=>{this.getSMCDataPoints(value.school_id)
                        this.getStudentData()});
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school.value}
                        error={school.helperText}
                        helperText={!school.value && school.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

        </div>

        <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row'}}>

            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total Students</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_students}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> Total Teachers to student ratio</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.teacher_to_student}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> School to Students Ratio</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.school_to_student}</div>
            </Card>
           

        </div>
        { this.state.showStudentGraph ?
        <div style={{ display: "flex", flexDirection:'row' ,marginTop:'20px' ,justifyContent:'space-around'}}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories:[1,2,3,4,5,6,7,8,9,10],
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "Class",
                          data:this.getdata(),
                          color: "#34A853",
                          dataLabels: {
                            enabled: true,
                            align: "center",
                            style: {
                              fontSize: "10px",
                              fontFamily: "helvetica, arial, sans-serif",
                            },
                          },
                        }
                      ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth) /2.5,
                        type: "column",
                        options3d: {
                          enabled: true,
                          alpha: 1,
                          beta: 15,
                          // viewDistance: 25,
                          depth: 100,
                        },
                      },
                    }}
                  ></HighchartsReact>

                   <HighchartsReact

                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories: this.getCatogeries(),
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                      series : this.getcompareArray(),
                    //   series: [{
                    //     name: 'Year 1990',
                    //     data: [631, 727, 3202, 721, 26]
                    // }, {
                    //     name: 'Year 2000',
                    //     data: [814, 841, 3714, 726, 31]
                    // }, ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth) /2.5,
                        type: "column",
                        options3d: {
                          enabled: true,
                          alpha: 1,
                          beta: 15,
                          // viewDistance: 25,
                          depth: 100,
                        },
                      },
                    }}
                  ></HighchartsReact>
                </div> :null}
       
              </>
    )

  }

  showSMC= ( ) =>{
    const { block,school,smcStudentArrya,smcArry,smcMembersData} = this.state
     
    return(
      <>
      <div style={{ display:'flex', justifyContent:'center',marginTop:'20px'}}>
          <div style={{ width:'9%'}}>
            <div >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },
                        showKendraGraph: false,
                        BlockValidation: false,
                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value);
                        this.getSMCMemberDataPoints()
                        this.getStudentData()
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.BlockValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>
            

            <div style={{ width:'15%',marginLeft:'20px'}}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        school: value,
                      },
                      ()=>{this.getSMCMemberDataPoints(value.school_id)
                        this.getSMCDataPoints(value.school_id)
                        this.getStudentData(value.school_id)});
                      // this.onChangeHandle(value);
                    
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school.value}
                        error={school.helperText}
                        helperText={!school.value && school.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

        </div>

        <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row'}}>

            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total Schools</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_schools}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> Total Members</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcMembersData.total_members}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> School to Member Ratio</div>
           
               {this.state.showSMCGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcMembersData.school_to_member_ratio}</div>:null}
            </Card>
           
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> Male Female to Member Ratio</div>
           
               {this.state.showSMCGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcMembersData.male_to_female_member_ratio}</div>:null}
            </Card>
        </div>


       
       


       {this.state.showSMCGraph ? <div style={{ display: "flex", flexDirection:'row' ,marginTop:'20px' ,justifyContent:'space-around'}}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories:this.getSMCcategeroies(),
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "Category count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                    
                    
                    series: [
                      {
                        name: "Category Name",
                        data:this.SMCseries(),
                        color: "#5B0F00",
                        dataLabels: {
                          enabled: true,
                          align: "center",
                          style: {
                            fontSize: "10px",
                            fontFamily: "helvetica, arial, sans-serif",
                          },
                        },
                      }
                    ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth) /2.5,
                        type: "column",
                        options3d: {
                          enabled: true,
                          alpha: 1,
                          beta: 15,
                          // viewDistance: 25,
                          depth: 100,
                        },
                      },
                    }}
                  ></HighchartsReact>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories:this.getCatogeriesForMonth(),
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "Meeting count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                    
                    
                    series: [
                      {
                        name: "Month",
                        data:this.getSMCMemberSeries(),
                        color: "#134F5C",
                        dataLabels: {
                          enabled: true,
                          align: "center",
                          style: {
                            fontSize: "10px",
                            fontFamily: "helvetica, arial, sans-serif",
                          },
                        },
                      }
                    ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth) /2.5,
                        type: "column",
                        options3d: {
                          enabled: true,
                          alpha: 1,
                          beta: 15,
                          // viewDistance: 25,
                          depth: 100,
                        },
                      },
                    }}
                  ></HighchartsReact>

                </div> : null}
              </>
    )

  }

  showScholership = ( ) =>{
    const { block,school,smcStudentArrya,smcArry,smcMembersData,smcScholershipData} = this.state
     
    return(
      <>
      <div style={{ display:'flex', justifyContent:'center',marginTop:'20px'}}>
          <div style={{ width:'9%'}}>
            <div >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },
                        showKendraGraph: false,
                        BlockValidation: false,
                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value);
                        this.getScholershipDataPoints()
                        this.getStudentData()
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.BlockValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>
            

            <div style={{ width:'15%',marginLeft:'20px'}}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        school: value,
                      },
                      ()=>{this.getScholershipDataPoints(value.school_id)
                        this.getSMCDataPoints(value.school_id)
                        this.getStudentData(value.school_id)});
                      // this.onChangeHandle(value);
                    
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school.value}
                        error={school.helperText}
                        helperText={!school.value && school.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

        </div>
  

          {console.log(smcArry,"smcArry4544")}
        {this.state.showScholershipGraph?  <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row'}}>

            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total Schools in district</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_schools}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> Total Appering school</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcScholershipData.school_details.schools_appearing}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> % school Appering</div>
           
               {this.state.showScholershipGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcScholershipData.schools_appearing_percentage}</div>:null}
            </Card>
           
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> Total students Appering</div>
           
               {this.state.showScholershipGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcScholershipData.total_students_appearing}</div>:null}
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> % students Appering</div>
           
               {this.state.showScholershipGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcScholershipData.students_appearing_percentage}</div>:null}
            </Card>
        </div> : <CircularProgress/>}


       
       


       {this.state.showScholershipGraph ? <div style={{ display: "flex", flexDirection:'row' ,marginTop:'20px' ,justifyContent:'space-around'}}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories:Object.keys(this.state.smcScholershipData.scholarship),
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "Category count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                    
                    
                    series: [
                      {
                        name: "Exam ",
                        data:this.getScholershipSeries(),
                        color: "#3D85C6",
                        dataLabels: {
                          enabled: true,
                          align: "center",
                          style: {
                            fontSize: "10px",
                            fontFamily: "helvetica, arial, sans-serif",
                          },
                        },
                      }
                    ],
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth) /1.2,
                        type: "column",
                        options3d: {
                          enabled: true,
                          alpha: 1,
                          beta: 15,
                          // viewDistance: 25,
                          depth: 100,
                        },
                      },
                    }}
                  ></HighchartsReact>

                </div> : null}
              </>
    )

  }

  showFacilities =( ) =>{
    const { block,school,smcStudentArrya,smcArry,smcFacilties} = this.state
     
    return(
      <>
      <div style={{ display:'flex', justifyContent:'center',marginTop:'20px'}}>
          <div style={{ width:'9%'}}>
            <div >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },
                        showKendraGraph: false,
                        BlockValidation: false,
                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                        this.getKendra(this.state.block.value);
                        this.getFacilitiesDataPoints()
                        this.getStudentData()
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.BlockValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
          </div>
            

            <div style={{ width:'15%',marginLeft:'20px'}}>
                <FormControl>
                  <span style={{ color: "#415EB6" }} id="School">
                    School
                  </span>
                  {console.log(this.state.schools, "schoolws")}
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{}}
                    open={this.state.isSetAuto}
                    disableClearable
                    onOpen={() => {
                      this.setState({ isSetAuto: true });
                    }}
                    onClose={() => {
                      this.setState({ isSetAuto: false });
                    }}
                    onChange={(event, value) => {
                      this.setState({
                        school: value,
                      },
                      ()=>{this.getFacilitiesDataPoints(value.school_id)
                        this.getSMCDataPoints(value.school_id)
                        this.getStudentData(value.school_id)});
                      // this.onChangeHandle(value);
                    
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    // options={this.state.schools}
                    loading={this.state.loading}
                    options={
                      this.state.schools == undefined ? [] : this.state.schools
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        value={school.value}
                        error={school.helperText}
                        helperText={!school.value && school.helperText}
                        style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.block.value,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>

        </div>

        <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row'}}>

            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
              <div  style={{ fontSize:'14px'}}> Total Schools </div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcFacilties.total_schools}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> Internet</div>
           
               <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcFacilties.internet}</div>
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}>Electricity</div>
           
               {this.state.showFacilitiesGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcFacilties.electricity}</div>:null}
            </Card>
           
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> BALA</div>
           
               {this.state.showFacilitiesGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcFacilties.electricity}</div>:null}
            </Card>
            <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'30px'}}>
              <div  style={{ fontSize:'14px'}}> Girls washroom</div>
           
               {this.state.showFacilitiesGraph ?<div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcFacilties.electricity}</div>:null}
            </Card>
        </div>


       
       


       {this.state.showFacilitiesGraph ? <div style={{ display: "flex", flexDirection:'row' ,marginTop:'20px' ,justifyContent:'space-around'}}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories:['BALA','Boundry wall','Boys washroom','Electricity','Girls washroom','ground','internet','Lab','Library','Maths Box','Play material','Projector','Smart Tv','Sweeper'],
                        crosshair: true,
                      },
                      yAxis: {
                        stackLabels: {
                          enabled: true,
                          align: "center",
                        },
                        visible: true,
                        title: {
                          enabled: true,
                          text: "Category count",
                        },
                      },

                      labels: {
                        items: [
                          {
                            html: "",
                            style: {
                              left: "50px",
                              top: "18px",
                            },
                          },
                        ],
                      },
                    
                    
                      series: this.getFacilitiesSeries(),
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        width: (window.innerWidth) /1.2,
                        type: "column",
                        options3d: {
                          enabled: true,
                          alpha: 1,
                          beta: 15,
                          // viewDistance: 25,
                          depth: 100,
                        },
                      },
                    }}
                  ></HighchartsReact>

                </div> : null}
              </>
    )

  }


  getFacilitiesSeries =() =>{

    const {smcFacilties }=this.state
    console.log(this.state.smcFacilties,'smcFacilties',array)
    let obj1={
      name:'available',
      data: [smcFacilties.facilities.bala_available, 
          smcFacilties.facilities.boundary_wall_available,
        smcFacilties.facilities.boys_washroom_available,
        smcFacilties.facilities.electricity_available,
        smcFacilties.facilities.girls_washroom_available, 
        smcFacilties.facilities.ground_available,
        smcFacilties.facilities.internet_available,
        smcFacilties.facilities.lab_available,
        smcFacilties.facilities.library_available, 
        smcFacilties.facilities.maths_box_available,
        smcFacilties.facilities.play_material_available,
        smcFacilties.facilities.projector_available,
        smcFacilties.facilities.smart_tv_available,
        smcFacilties.facilities.sweeper_available,
      ],
      color:'#4472C4'
    }
    let  obj2={
      name:'unavailable',
      data: [
        smcFacilties.facilities.bala_unavailable,
        smcFacilties.facilities.boundary_wall_available,
        smcFacilties.facilities.boys_washroom_unavailable ,
        smcFacilties.facilities.electricity_unavailable,
        smcFacilties.facilities.girls_washroom_unavailable,
        smcFacilties.facilities.ground_unavailable,
        smcFacilties.facilities.internet_unavailable,
        smcFacilties.facilities.lab_unavailable,
        smcFacilties.facilities.library_unavailable,
        smcFacilties.facilities.maths_box_unavailable,
        smcFacilties.facilities.play_material_unavailable,
        smcFacilties.facilities.projector_unavailable,
        smcFacilties.facilities.smart_tv_unavailable,
        smcFacilties.facilities.sweeper_unavailable
      ],
      color:'#ED7D31'
    }
    return [obj1,obj2]
  }
  getStudentData = (id) =>{

    let param={
      student_district_id :  this.state.districtID.student_district_id
    }
  
    if(this.state.block.value)
    {
      param['student_block_id'] = this.state.block.value
    }
    console.log(this.state.school,"this.state.school")
    if(id)
    {
      param['school_id'] = id
    }
    getAdministrativeService().getSMCStudentDataPoints(param).
    then((res)=>{
      console.log(res,"res")
    if(res)
    {
      this.setState({
        smcStudentArrya: res.data,
        showStudentGraph:true
      })
    }
    })
  }

  getdata = () =>{
    let array=[]

    console.log("smcArry")
    this.state.smcStudentArrya.gender_count.map((res)=>
    array.push(res.sum)
    ) 
    
    return array
  }

  getcompareArray = () =>{
    let array=[]
    let data1=[]
    let data2=[]
    let obj1={
      name:'boys',
      data: null
    }
    let obj2={
      name:'Girls',
      data: null
    }
    console.log("smcArry")
    this.state.smcStudentArrya.cast_wise_count.map((res)=>
   {
    data1.push(res.boys)
    data2.push(res.girls)
   }
    ) 
     obj1={
      name:'boys',
      data: data1,
      color:'#4285F4'
    }
     obj2={
      name:'Girls',
      data: data2,
      color:'#FF00FF'
    }
    return [obj1,obj2]
  }

  getCatogeries =() =>{
    let array =[]

    this.state.smcStudentArrya.cast_wise_count.map((res)=>
   {
    array.push(res.school_detail_id__caste_wise__caste_name)
   }
    )

    return array
  }


   SMCseries =() =>{

    let array=[]

      console.log(this.state.smcMembersData,"members")
      console.log("smcArry")
      this.state.smcMembersData.caste_wise_member.map((res)=>
      array.push(res.count)
      ) 
      console.log(array,"arary")
      return array
   }

   getSMCMemberDataPoints= (id) =>{
    let param={
      student_district_id :  this.state.districtID.student_district_id
    }
  
    if(this.state.block.value)
    {
      param['student_block_id'] = this.state.block.value
    }
    console.log(this.state.school,"this.state.school")
    if(id)
    {
      param['school_id'] = id
    }
    getAdministrativeService().getSMCMembersDataPoints(param).
    then((res)=>{
      console.log(res,"res")
    if(res)
    {
      this.setState({
        smcMembersData: res.data,
        showSMCGraph:true
      })
    }
    })
   }

   getScholershipDataPoints= (id) =>{
    let param={
      student_district_id :  this.state.districtID.student_district_id
    }
  
    if(this.state.block.value)
    {
      param['student_block_id'] = this.state.block.value
    }
    console.log(this.state.school,"this.state.school")
    if(id)
    {
      param['school_id'] = id
    }
    getAdministrativeService().getScholershipPoints(param).
    then((res)=>{
      console.log(res,"res")
    if(res)
    {
      this.setState({
        smcScholershipData: res.data,
        showScholershipGraph:true
      })
    }
    })
   }

   getFacilitiesDataPoints= (id) =>{
    let param={
      student_district_id :  this.state.districtID.student_district_id
    }
  
    if(this.state.block.value)
    {
      param['student_block_id'] = this.state.block.value
    }
    console.log(this.state.school,"this.state.school")
    if(id)
    {
      param['school_id'] = id
    }
    getAdministrativeService().getFaciltiesPoints(param).
    then((res)=>{
      console.log(res,"res")
    if(res)
    {
      this.setState({
        smcFacilties: res.data,
        showFacilitiesGraph:true
      })
    }
    })
   }
   getSMCcategeroies = () =>{

    let array=[]

      console.log(this.state.smcMembersData,"members")
      console.log("smcArry")
      this.state.smcMembersData.caste_wise_member.map((res)=>
      array.push(res.school_detail_id__members__caste)
      ) 
      console.log(array,"arary")
      return array
   }

   getSMCMemberSeries =() =>{
    let array=[]

      console.log(this.state.smcMembersData.month_wise_meets        ,"members")
      console.log("smcArry")
      this.state.smcMembersData.month_wise_meets.map((res)=>
      array.push(res.counts)
      ) 
      console.log(array,"arary")
      return array
   }

   getCatogeriesForMonth =() =>{
    let array=[]
    let data = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec']
      console.log(this.state.smcMembersData.month_wise_meets        ,"members")
      console.log("smcArry")
      this.state.smcMembersData.month_wise_meets.map((res)=>
      array.push(data[res.month-1])
      ) 
      console.log(array,"arary")
      return array
   }

   getMemberRatio = (data) =>{
      
        let count =0
    console.log(this.state.smcMembersData.gender.map((res)=>console.log(res,'res')),"smcMembersData")
    data.gender.map((res)=>{
      count = count + res.count
    })
    console.log(count,"count")
  
    return (this.state.smcArry.total_schools/count).toFixed(2)
   }

   getGemderMemberRatio =(data) =>{
      
    let count1,count2

       data.gender.map((res)=>{
           if(res.school_detail_id__members__gender === 'MALE')
           {
            count1 = res.count
           }
           if(res.school_detail_id__members__gender === "FEMALE")
           {
            count2 = res.count
           }
        })
      console.log(count1,"counts",count2)
   
       return (count1/count2).toFixed(2)
}

    getScholershipSeries =() =>{

      let array=[]

      console.log(this.state.smcScholershipData,"smcScholershipData")
       array = Object.values(this.state.smcScholershipData.scholarship)
      return array
    }

    showextraFacilities =()=>{
      const { block,school,smcExtraFacilties,smcArry,showExtraFacilities} = this.state
      console.log(smcArry,"smcArry",smcExtraFacilties)
      return(
        <>
        <div style={{ display:'flex', justifyContent:'center',marginTop:'20px'}}>
            <div style={{ width:'9%'}}>
              <div >
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block*
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block.value}
                    error={block.helperText}
                    helperText={!block.value && block.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block: {
                            value: e.target.value,
                            helperText: null,
                          },
                          showKendraGraph: false,
                          BlockValidation: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                        },
                        () => {
                          this.getSchools(this.state.block.value);
                          this.getKendra(this.state.block.value);
                          this.getSMCDataPoints()
                          this.getExtraFacilitiesDataPoints()
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {this.state.BlockValidation ? (
                <div style={{ color: "red" }}>Required</div>
              ) : null}
            </div>
              
  
              <div style={{ width:'15%',marginLeft:'20px'}}>
                  <FormControl>
                    <span style={{ color: "#415EB6" }} id="School">
                      School
                    </span>
                    {console.log(this.state.schools, "schoolws")}
                    <Autocomplete
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      style={{}}
                      open={this.state.isSetAuto}
                      disableClearable
                      onOpen={() => {
                        this.setState({ isSetAuto: true });
                      }}
                      onClose={() => {
                        this.setState({ isSetAuto: false });
                      }}
                      onChange={(event, value) => {
                        this.setState({
                          school: value,
                        },
                        ()=>{
                          this.getSMCDataPoints(value.school_id)
                          this.getExtraFacilitiesDataPoints(value.school_id)
                        });
                       
                        // this.onChangeHandle(value);
                        console.log("admin ID", value.school_id);
                      }}
                      getOptionLabel={(option) =>
                        `${option.school_name} ${option.u_dise}`
                      }
                      // options={this.state.schools}
                      loading={this.state.loading}
                      options={
                        this.state.schools == undefined ? [] : this.state.schools
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          labelId="School"
                          id="School_dropdown"
                          value={school.value}
                          error={school.helperText}
                          helperText={!school.value && school.helperText}
                          style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                          onChange={(ev) => {
                            this.getSchools(
                              this.state.block.value,
                              ev.target.value
                            );
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
  
          </div>
          <div style={{ marginLeft:'10%',fontSize:'30px',fontWeight:'bold'}}>Uniform</div>
          {showExtraFacilities ?
          <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row',justifyContent:'space-evenly'}}>
       
              <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
                <div  style={{ fontSize:'14px'}}> Total Students</div>
             
                 <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties?.uniform.total_students}</div>
              </Card>
              <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
                <div  style={{ fontSize:'14px'}}>Uniform Provided</div>
             
                 <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.uniform.total_uniforms_provided}</div>
              </Card>
              <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
                <div  style={{ fontSize:'14px'}}> Girls Uniform Provided</div>
             
                 <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.uniform.girls_uniforms_provided}</div>
              </Card>
              <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
                <div  style={{ fontSize:'14px'}}> Boys Uniform Provided</div>
             
                 <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.uniform.boys_uniforms_provided}</div>
              </Card>

              <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
                <div  style={{ fontSize:'14px'}}> Requirement</div>
             
                 <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.uniform.remaining_students_for_uniform}</div>
              </Card>
  
  
          </div>:null}
  
          <div style={{ marginTop:'15px',marginLeft:'10%',fontSize:'30px',fontWeight:'bold'}}>Mid-Day Meal</div>
          {showExtraFacilities ? <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row',marginLeft:'6%'}}>
       
       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Total Students</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.mid_day_meal.mid_day_meal_num_of_cook}</div>
       </Card>
       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'7%'}}>
         <div  style={{ fontSize:'14px'}}>Total Cook</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.mid_day_meal.mid_day_meal_num_of_cook}</div>
       </Card>
       {/* <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Girls Uniform Provided</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_students}</div>
       </Card>
       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Boys Uniform Provided</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_committee_member}</div>
       </Card>

       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Requirement</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_committee_member}</div>
       </Card> */}


           </div> : null}
          
          <div style={{ marginTop:'25px',marginLeft:'10%',fontSize:'30px',fontWeight:'bold'}}>Drinking Water</div>
          {showExtraFacilities ? <div style={{ marginTop:'15px',width:'100%' ,display:'flex' , flexDirection:'row',marginLeft:'6%'}}>
       
       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Total Tap</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.water.water_common_tap}</div>
       </Card>
       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px',marginLeft:'7%'}}>
         <div  style={{ fontSize:'14px'}}>Total Hand Pump</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcExtraFacilties.water.water_hand_pump}</div>
       </Card>
       {/* <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Girls Uniform Provided</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_students}</div>
       </Card>
       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Boys Uniform Provided</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_committee_member}</div>
       </Card>

       <Card style={{ width:'12%',padding:'42px',backgroundColor:'#D9D7DA',borderRadius:'10px'}}>
         <div  style={{ fontSize:'14px'}}> Requirement</div>
      
          <div style={{ fontSize:'40px' , marginTop:'18px'}}>{smcArry.total_committee_member}</div>
       </Card> */}


          </div> : null}
                </>
      )
  
    }

    getExtraFacilitiesDataPoints = (id) =>{

      let param={
        student_district_id :  this.state.districtID.student_district_id
      }
    
      if(this.state.block.value)
      {
        param['student_block_id'] = this.state.block.value
      }
      console.log(this.state.school,"this.state.school")
      if(id)
      {
        param['school_id'] = id
      }
      getAdministrativeService().getExtraFaciltiesPoints(param).
      then((res)=>{
        console.log(res,"res")
      if(res)
      {
        this.setState({
          smcExtraFacilties: res.data,
          showExtraFacilities:true
        })
      }
      })
    }
  render() {
 
      const { block,school} = this.state
    return (
      <>
       
       <button
          onClick={() => {
            this.setState({
              Overview : true,
              Facilities:false,
              Students:false,
              SMC:false,
              Schoolership:false,
              ExtraFacilities:false,
              block: {
                value: null,
                helperText: null,
              },
              school: {
                value: null,
                helperText: null,
              },
            });
          }}
          className={
            this.state.Overview
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          Overview
        </button>
        <button
          onClick={() => {
            this.setState({
              Overview : false,
              Facilities:false,
              Students:true,
              SMC:false,
              Schoolership:false,
              ExtraFacilities:false,
              block: {
                value: null,
                helperText: null,
              },
              school: {
                value: null,
                helperText: null,
              },
            },
            ()=>{
              this.getStudentData()
              this.getSMCDataPoints()
            });
          }}
          className={
            this.state.Students
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          Students
        </button>
        <button
          onClick={() => {
            this.setState({
              Overview : false,
              Facilities:false,
              Students:false,
              SMC:true,
              Schoolership:false,
              ExtraFacilities:false,
              block: {
                value: null,
                helperText: null,
              },
              school: {
                value: null,
                helperText: null,
              },
            },
            ()=>{
              this.getStudentData()
              this.getSMCMemberDataPoints()
            });
          }}
          className={
            this.state.SMC
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          SMC
        </button>
        <button
          onClick={() => {
            this.setState({
              Overview : false,
              Facilities:false,
              Students:false,
              SMC:false,
              Schoolership:true,
              ExtraFacilities:false,
              block: {
                value: null,
                helperText: null,
              },
              school: {
                value: null,
                helperText: null,
              },
            }
            ,
          ()=>{
            this.getStudentData()
            this.getScholershipDataPoints()
          });
          }}
          className={
            this.state.Schoolership
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          Schoolership
        </button>

        <button
          onClick={() => {
            this.setState({
              Overview : false,
              Facilities:true,
              Students:false,
              SMC:false,
              Schoolership:false,
              ExtraFacilities:false,
              block: {
                value: null,
                helperText: null,
              },
              school: {
                value: null,
                helperText: null,
              },
            }
            ,
          ()=>{
            this.getStudentData()
            this.getFacilitiesDataPoints()
          });
          }}
          className={
            this.state.Facilities
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
         Facilities
        </button>

        <button
          onClick={() => {
            this.setState({
              Overview : false,
              Facilities:false,
              Students:false,
              SMC:false,
              Schoolership:false,
              ExtraFacilities:true,
              block: {
                value: null,
                helperText: null,
              },
              school: {
                value: null,
                helperText: null,
              },
            },
            ()=>{
             
              this.getExtraFacilitiesDataPoints()
            });
          }}
          className={
            this.state.ExtraFacilities
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
        Extra Facilities
        </button>

        {
          this.state.Overview ? this.showOverview():
          this.state.Students ? this.showStudenst():
          this.state.SMC ? this.showSMC():
          this.state.Schoolership ? this.showScholership():
          this.state.Facilities? this.showFacilities():
          this.state.ExtraFacilities ? this.showextraFacilities():

          null
        }
      </>
    );
  }
}
