import React from "react";
import classes from "./Alert.module.css";

const Alert = ({ message, type }) => {
  let alertClass = [];
  alertClass.push(classes.alert);
  if (type == "success") {
    alertClass.push(classes.success);
  } else if (type == "danger") {
    alertClass.push(classes.danger);
  }

  return (
    <div className={alertClass.toString().split(",").join(" ")}>{message}</div>
  );
};

export default Alert;






