/*eslint-disable*/
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import cls from "./Sidebar.module.css";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import Card from "components/Card/Card.js";

const useStyles = makeStyles(styles);
const user = JSON.parse(localStorage.getItem("user"));
let type = user ? user.user_type : null;
export default function Sidebar(props) {
  const [userData, setUserData] = React.useState({});
  const [school, setSchool] = React.useState({});
  const [routeFlag, setRouteFlag] = useState(false);
  const [routeWelcomeFlag, setWelcomeFlag] = useState(false);
  const [routeSMCFlag, setSMCFlag] = useState(false);
  const [hrefRoutes, setHrefRoute] = React.useState({});
  const [adminWelcomeFlag, setAdminWelcomeFlag] = useState(false);
  const [adminRouteFlag, setAdminRouteFlag] = useState(false);
  const [adminRouteSMCFlag, setAdminRouteSMCFlag] = useState(false);
  const [loFlag, setLoFlag] = useState(false);
  const [hmFLNFlag, setHmFLNFlag] = useState(false);
  useEffect(() => {
    let userObj = JSON.parse(localStorage.getItem("user"));

    if (userObj) {
      setUserData(userObj);
    }
    // if (schoolInfo) {
    //   setSchool(schoolInfo);
    // }
  }, []);

  useEffect(() => {
    let schoolInfo = JSON.parse(localStorage.getItem("HMSchool"))
      ? JSON.parse(localStorage.getItem("HMSchool"))
      : JSON.parse(localStorage.getItem("AMdetails"));
    if (schoolInfo) {
      setSchool(schoolInfo);
    }
    // if (schoolInfo) {
    //   setSchool(schoolInfo);
    // }
  }, []);
  const getLoggedInUser = () => {
    if (userData && userData.user_details) {
      return (
        userData.user_details.first_name +
        " " +
        userData.user_details.last_name +
        " - \n" +
        userData.user_details.mobile
      );
    }
    return "";
  };

  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes, history } = props;

  // var links = (
  //       {renderMenuItems()}

  // );
  var brand = (
    <>
      <div style={{ paddingBottom: "35px" }} className={classes.logo}>
        <a
          href="https://vopa.in"
          className={classNames(classes.logoLink, {
            [classes.logoLinkRTL]: props.rtlActive,
          })}
          target="_blank"
        >
          <div className={[classes.logoImage, cls.logoWrapper].join(" ")}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
          {/* {logoText} */}
        </a>
      </div>
      <div className={[classes.itemText, cls.loginContainer].join(" ")}>
        <Card style={{ width: "70%", padding: "10px", borderRadius: "15px" }}>
          {getLoggedInUser()}
        </Card>
      </div>
    </>
  );
  const UrlFlag = (id) => {
    console.log(id, "id");
    let url = window.location.href;
    let index = url.lastIndexOf("/user/") + 6;
    let filename = url.substr(index);
    console.log(filename, "filename");
    if (
      filename === "SMCHome" ||
      filename === "createform" ||
      filename === "schoolDetail"
    ) {
      return true;
    }
  };


  const URLFln =()=>{
    let url = window.location.href;
    let index = url.lastIndexOf("/user/") + 6;
    let filename = url.substr(index);
    console.log(filename, "filename");
    if(filename === "FLNAnylsis" ){
      return true
    }
    return false
  }

  const URLLo =()=>{
    let url = window.location.href;
    let index = url.lastIndexOf("/user/") + 6;
    let filename = url.substr(index);
    console.log(filename, "filename");
    if(filename === "LOAnylsis" ){
      return true
    }
    return false
  }

  const UrlSMCFlag = () => {
    let url = window.location.href;
    let index = url.lastIndexOf("/user/") + 6;
    let filename = url.substr(index);
    console.log(filename, "filename");
    if (
      filename === "FLNHome" ||
      filename === "FLNStudentList" ||
      filename === "FLNTeacherList" ||
      filename === "FLNTransferTeacher" ||
      filename === "StudentView " 
    ) {
      return true;
    }
  };

  const renderMenuItems = () => {
    if (user?.user_type === "HEADMASTER") {
      // setWelcomeFlag(true)
      console.log(window.location.href, "RoutePath", routeWelcomeFlag);
      if (routeFlag || UrlSMCFlag()) {
        console.log("ihnhere 1")
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" || res.name === "School Setup",
            "okok45"
          );
          if (
            res.name === "Welcome" ||
            res.name === "Log Out" ||
            res.name === "School Setup" ||
            res.name === "Upload Student List" ||
            res.name === "Assign Uploader Teacher" ||
            res.name === "Add/Remove Student" ||
            res.name === "Transfer Teacher"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array");
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/FLNStudentList" ||
                        prop.path == "/FLNTeacherList" ||
                        prop.path == "/FLNTransferTeacher" ||
                        prop.path == "/StudentView" 
                      ) {
                        setRouteFlag(true);
                        setWelcomeFlag(false);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path === "/dashboard") {
                        setRouteFlag(false);
                        setWelcomeFlag(true);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/SMCHome") {
                        setSMCFlag(true);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } 
                      else if (prop.path == "/FLNAnylsis"){
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(false)
                        setHmFLNFlag(true)
                      }
                      else if (prop.path == "/LOAnylsis"){
                        setWelcomeFlag(false);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(true)
                        setHmFLNFlag(false)
                      }else {
                        setRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      } else if (UrlFlag()) {
        console.log("ihnhere 2")
        console.log(routeSMCFlag, "routeSMCFlag");
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" || res.name === "School Setup",
            "okok45"
          );
          if (
            res.name === "Welcome" ||
            res.name === "Log Out" ||
            res.name === "Update Information" ||
            res.name === "View/Download" ||
            res.name === "SMC"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array");
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/FLNStudentList" ||
                        prop.path == "/FLNTeacherList" ||
                        prop.path == "/FLNTransferTeacher" ||
                        prop.path == "/StudentView" 

                      ) {
                        setRouteFlag(true);
                        setWelcomeFlag(false);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path === "/dashboard") {
                        setRouteFlag(false);
                        setWelcomeFlag(true);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/SMCHome") {
                        setSMCFlag(true);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } 
                      else if (prop.path == "/LOAnylsis"){
                        setWelcomeFlag(false);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(true)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/FLNAnylsis"){
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(false)
                        setHmFLNFlag(true)
                      }else {
                        setRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      }  else if (routeSMCFlag) {
        console.log("ihnhere 4")
        console.log(routeSMCFlag, "routeSMCFlag");
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" || res.name === "School Setup",
            "okok45"
          );
          if (
            res.name === "Welcome" ||
            res.name === "Log Out" ||
            res.name === "Update Information" ||
            res.name === "View/Download" ||
            res.name === "SMC"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array");
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/FLNStudentList" ||
                        prop.path == "/FLNTeacherList" ||
                        prop.path == "/FLNTransferTeacher" ||
                        prop.path == "/StudentView"
                      ) {
                        setRouteFlag(true);
                        setWelcomeFlag(false);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path === "/dashboard") {
                        setRouteFlag(false);
                        setWelcomeFlag(true);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/SMCHome") {
                        setSMCFlag(true);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      }
                      else if (prop.path == "/FLNAnylsis"){
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(false)
                        setHmFLNFlag(true)
                      }
                      else if (prop.path == "/LOAnylsis"){
                        setWelcomeFlag(false);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(true)
                        setHmFLNFlag(false)
                      } else {
                        setRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      } else if (loFlag || URLLo() ) {
        console.log("ihnhere 5")
        console.log(routeSMCFlag, "routeSMCFlag");
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" || res.name === "School Setup",
            "okok45"
          );
          if (
            res.name === "Welcome" ||
            res.name === "Log Out" ||
            res.name === "LO Analysis" 
          ) {
            array.push(res);
          }
        });

        console.log(array, "array");
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/FLNStudentList" ||
                        prop.path == "/FLNTeacherList" ||
                        prop.path == "/FLNTransferTeacher" ||
                        prop.path == "/StudentView"
                      ) {
                        setRouteFlag(true);
                        setWelcomeFlag(false);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path === "/dashboard") {
                        setRouteFlag(false);
                        setWelcomeFlag(true);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/SMCHome") {
                        setSMCFlag(true);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } 
                      else if (prop.path == "/FLNAnylsis"){
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(false)
                        setHmFLNFlag(true)
                      }
                      else if (prop.path == "/LOAnylsis"){
                        setWelcomeFlag(false);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(true)
                        setHmFLNFlag(false)
                      }else {
                        setRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      }
      else if (hmFLNFlag || URLFln() ) {
        console.log("ihnhere 6")
        console.log(routeSMCFlag, "routeSMCFlag");
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" || res.name === "School Setup",
            "okok45"
          );
          if (
            res.name === "Welcome" ||
            res.name === "Log Out" ||
            res.name === "FLN Analysis"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array");
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/FLNStudentList" ||
                        prop.path == "/FLNTeacherList" ||
                        prop.path == "/FLNTransferTeacher" ||
                        prop.path == "/StudentView"
                      ) {
                        setRouteFlag(true);
                        setWelcomeFlag(false);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path === "/dashboard") {
                        setRouteFlag(false);
                        setWelcomeFlag(true);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/SMCHome") {
                        setSMCFlag(true);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } 
                      else if (prop.path == "/FLNAnylsis"){
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(false)
                        setHmFLNFlag(true)
                      }
                      else if (prop.path == "/LOAnylsis"){
                        setWelcomeFlag(false);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(true)
                        setHmFLNFlag(false)
                      }
                      else {
                        setRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      }
      else if (routeWelcomeFlag) {
        console.log("ihnhere 3")
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" ||
              res.name === "Log Out" ||
              res.name === "School Setup",
            "okok45"
          );
          if (
            res.name === "Welcome" ||
            res.name === "Log Out" ||
            res.name === "School Setup" ||
            res.name === "FLN Analysis" ||
            res.name === "LO Analysis" ||
            res.name === "SMC"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array");
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/FLNStudentList" ||
                        prop.path == "/FLNTeacherList" ||
                        prop.path == "/FLNTransferTeacher" ||
                        prop.path == "/StudentView" 
                      ) {
                        setRouteFlag(true);
                        setWelcomeFlag(false);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path === "/dashboard") {
                        setRouteFlag(false);
                        setWelcomeFlag(true);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/SMCHome") {
                        setSMCFlag(true);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/FLNAnylsis"){
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(false)
                        setHmFLNFlag(true)
                      }
                      else if (prop.path == "/LOAnylsis"){
                        setWelcomeFlag(false);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(true)
                        setHmFLNFlag(false)
                      }else {
                        setRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      } 
      else {
        console.log("ihnhere 7")
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" ||
              res.name === "Log Out" ||
              res.name === "School Setup",
            "okok45"
          );
          if (
            res.name === "Welcome" ||
            res.name === "Log Out" ||
            res.name === "School Setup" ||
            res.name === "FLN Analysis" ||
            res.name === "LO Analysis" ||
            res.name === "SMC"
          ) {
            array.push(res);
          }
        });
        console.log(JSON.parse(localStorage.getItem("user")), "user");
        let data =
          JSON.parse(localStorage.getItem("user"))?.user_type === "SUPERADMIN"
            ? routes
            : array;
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {data.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      console.log("tell me prop",prop.path)
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/FLNStudentList" ||
                        prop.path == "/FLNTeacherList" ||
                        prop.path == "/FLNTransferTeacher" ||
                        prop.path == "/StudentView"
                      ) {
                        setRouteFlag(true);
                        setWelcomeFlag(false);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path === "/dashboard") {
                        setRouteFlag(false);
                        setWelcomeFlag(true);
                        setSMCFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      } else if (prop.path == "/SMCHome") {
                        setSMCFlag(true);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setLoFlag(false)
                        setHmFLNFlag(false)
                      }  else if (prop.path == "/FLNAnylsis"){
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(false)
                        setHmFLNFlag(true)
                      }
                      else if (prop.path == "/LOAnylsis"){
                        setWelcomeFlag(false);
                        setRouteFlag(false);
                        setWelcomeFlag(false);
                        setSMCFlag(false)
                        setLoFlag(true)
                        setHmFLNFlag(false)
                      }
                      else {
                        setRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      }
    } else if (user?.user_type === "ADMINISTRATIVE"){
      // return (
      //   <List className={[classes.list, cls.sideBarContainer].join(" ")}>
      //     {console.log(routes, "routes")}
      //     {routes.map((prop, key) => {
      //       var activePro = " ";
      //       var listItemClasses;
      //       if (prop.path === "/upgrade-to-pro") {
      //         activePro = classes.activePro + " ";
      //         listItemClasses = classNames({
      //           [" " + classes[color]]: true,
      //         });
      //       } else {
      //         listItemClasses = classNames({
      //           [" " + classes[color]]: activeRoute(prop.layout + prop.path),
      //         });
      //       }
      //       const whiteFontClasses = classNames({
      //         [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
      //       });
      //       return (
      //         <div
      //           key={key}
      //           className={
      //             activeRoute(prop.layout + prop.path) ? cls.navItem : null
      //           }
      //         >
      //           {console.log(routeFlag, "routeFlag")}
      //           <div className={cls.top}></div>

      //           <ListItem
      //             disabled={
      //               getDisbaledStatus(prop.path)
      //             }
      //             key={key}
      //             onClick={() => {
      //               if (
      //                 prop.path == "/FLNHome" ||
      //                 prop.path == "/FLNStudentList" ||
      //                 prop.path == "/FLNTeacherList" ||
      //                 prop.path == "/StudentView" ||
      //                 prop.path == "/FLNAnylsis"
      //               ) {
      //                 setRouteFlag(true);
      //                 setWelcomeFlag(false);
      //                 setSMCFlag(false);
      //               } else if (prop.path === "/dashboard") {
      //                 setRouteFlag(false);
      //                 setWelcomeFlag(true);
      //                 setSMCFlag(false);
      //               } else if (prop.path == "/SMCHome") {
      //                 setSMCFlag(true);
      //                 setRouteFlag(false);
      //                 setWelcomeFlag(false);
      //               } else {
      //                 setRouteFlag(false);
      //               }
      //               console.log(prop.path, "path");
      //               if (prop.path == "/logout") {
      //                 localStorage.clear();
      //                 history.replace("/");
      //                 return;
      //               }
      //               history.push(prop.layout + prop.path);
      //               return;
      //             }}
      //             button
      //             // className={classes.itemLink + listItemClasses + cls.navItem}
      //             className={[classes.itemLink].join(" ")}
      //           >
      //             {typeof prop.icon === "string" ? (
      //               <Icon
      //                 className={classNames(
      //                   classes.itemIcon,
      //                   activeRoute(prop.layout + prop.path)
      //                     ? cls.activeItem
      //                     : null,
      //                   whiteFontClasses,
      //                   {
      //                     [classes.itemIconRTL]: props.rtlActive,
      //                   }
      //                 )}
      //               >
      //                 {prop.icon}
      //               </Icon>
      //             ) : (
      //               <prop.icon
      //                 className={classNames(
      //                   classes.itemIcon,
      //                   activeRoute(prop.layout + prop.path)
      //                     ? cls.activeItem
      //                     : null,
      //                   whiteFontClasses,
      //                   {
      //                     [classes.itemIconRTL]: props.rtlActive,
      //                   }
      //                 )}
      //               />
      //             )}

      //             <ListItemText
      //               primary={props.rtlActive ? prop.rtlName : prop.name}
      //               className={classNames(
      //                 classes.itemText,
      //                 activeRoute(prop.layout + prop.path)
      //                   ? cls.activeItem
      //                   : null,
      //                 whiteFontClasses,
      //                 {
      //                   [classes.itemTextRTL]: props.rtlActive,
      //                 }
      //               )}
      //               disableTypography={true}
      //             />
      //           </ListItem>
      //           <div className={cls.bottom}></div>
      //         </div>
      //       );
      //     })}
      //   </List>
      // );

      //

      if (adminRouteFlag || adminFLNFlag()) {
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" || res.name === "FLN Home",
            "okok45"
          );
          if (
            res.name === "AM Dashboard" ||
            res.name === "Log Out" ||
            res.name === "FLN Home" ||
            res.name === "FLN Nipun Bharat" ||
            res.name === "LO analysis" ||
            res.name === "Data Collection" ||
            res.name === "FLN  For AM"
          ) {
            array.push(res);
          }
        });
        console.log(array, "array adminFlag");
        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/NipunBharat" ||
                        prop.path == "/LOAdminAnalysis" ||
                        prop.path == "/FLN" ||
                        prop.path == "/AMFLN"
                      ) {
                        console.log("heeeey array coming inside");
                        setAdminRouteFlag(true);
                        setAdminWelcomeFlag(false);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path === "/AMHome") {
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(true);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path == "/HomeSMC") {
                        setAdminRouteSMCFlag(true);
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(false);
                      } else {
                        setAdminRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      } else if (adminUrlFlag()) {
        let array = [];
        routes.map((res) => {
          console.log(
            res.name === "Welcome" || res.name === "FLN Home",
            "okok45"
          );
          if (
            res.name === "AM Dashboard" ||
            res.name === "Log Out" ||
            res.name === "SMC Home" ||
            res.name === "SMC  For AM"||
            res.name === "SMC Overview"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array SMC");

        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/NipunBharat" ||
                        prop.path == "/LOAdminAnalysis" ||
                        prop.path == "/FLN" ||
                        prop.path == "/AMFLN"
                      ) {
                        setAdminRouteFlag(true);
                        setAdminWelcomeFlag(false);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path === "/AMHome") {
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(true);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path == "/HomeSMC") {
                        setAdminRouteSMCFlag(true);
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(false);
                      } else {
                        setAdminRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      } else if (adminWelcomeFlag) {
        let array = [];
        routes.map((res) => {
          if (
            res.name === "AM Dashboard" ||
            res.name === "Log Out" ||
            res.name === "SMC Home" ||
            res.name === "FLN Home"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array ALL");

        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/NipunBharat" ||
                        prop.path == "/LOAdminAnalysis" ||
                        prop.path == "/FLN" ||
                        prop.path == "/AMFLN"
                        // ||
                        // prop.path == "StudentView"
                      ) {
                        setAdminRouteFlag(true);
                        setAdminWelcomeFlag(false);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path === "/AMHome") {
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(true);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path == "/HomeSMC") {
                        setAdminRouteSMCFlag(true);
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(false);
                      } else {
                        setAdminRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      } else if (adminRouteSMCFlag) {
        let array = [];
        routes.map((res) => {
          if (
            res.name === "AM Dashboard" ||
            res.name === "Log Out" ||
            res.name === "SMC Home" ||
            res.name === "SMC  For AM"||
            res.name === "SMC Overview"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array SMC1");

        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/NipunBharat" ||
                        prop.path == "/LOAdminAnalysis" ||
                        prop.path == "/FLN" ||
                        prop.path == "/AMFLN"
                      ) {
                        setAdminRouteFlag(true);
                        setAdminWelcomeFlag(false);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path === "/AMHome") {
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(true);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path == "/HomeSMC") {
                        setAdminRouteSMCFlag(true);
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(false);
                      } else {
                        setAdminRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      } else {
        let array = [];
        routes.map((res) => {
          if (
            res.name === "AM Dashboard" ||
            res.name === "Log Out" ||
            res.name === "FLN Home" ||
            res.name === "SMC Home"
          ) {
            array.push(res);
          }
        });

        console.log(array, "array ALL");

        return (
          <List className={[classes.list, cls.sideBarContainer].join(" ")}>
            {console.log(routes, "routes")}
            {array.map((prop, key) => {
              var activePro = " ";
              var listItemClasses;
              if (prop.path === "/upgrade-to-pro") {
                activePro = classes.activePro + " ";
                listItemClasses = classNames({
                  [" " + classes[color]]: true,
                });
              } else {
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
              }
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
              });
              return (
                <div
                  key={key}
                  className={
                    activeRoute(prop.layout + prop.path) ? cls.navItem : null
                  }
                >
                  {console.log(routeFlag, "routeFlag")}
                  <div className={cls.top}></div>

                  <ListItem
                    key={key}
                    onClick={() => {
                      if (
                        prop.path == "/FLNHome" ||
                        prop.path == "/NipunBharat" ||
                        prop.path == "/LOAdminAnalysis" ||
                        prop.path == "/FLN" ||
                        prop.path == "/AMFLN"
                      ) {
                        setAdminRouteFlag(true);
                        setAdminWelcomeFlag(false);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path === "/AMHome") {
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(true);
                        setAdminRouteSMCFlag(false);
                      } else if (prop.path == "/HomeSMC") {
                        setAdminRouteSMCFlag(true);
                        setAdminRouteFlag(false);
                        setAdminWelcomeFlag(false);
                      } else {
                        setAdminRouteFlag(false);
                      }
                      console.log(prop.path, "path");
                      if (prop.path == "/logout") {
                        localStorage.clear();
                        history.replace("/");
                        return;
                      }
                      history.push(prop.layout + prop.path);
                      return;
                    }}
                    button
                    // className={classes.itemLink + listItemClasses + cls.navItem}
                    className={[classes.itemLink].join(" ")}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          activeRoute(prop.layout + prop.path)
                            ? cls.activeItem
                            : null,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                  <div className={cls.bottom}></div>
                </div>
              );
            })}
          </List>
        );
      }
    } else{
      return (
        <List className={[classes.list, cls.sideBarContainer].join(" ")}>
          {console.log(routes, "routes")}
          {routes.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            if (prop.path === "/upgrade-to-pro") {
              activePro = classes.activePro + " ";
              listItemClasses = classNames({
                [" " + classes[color]]: true,
              });
            } else {
              listItemClasses = classNames({
                [" " + classes[color]]: activeRoute(prop.layout + prop.path),
              });
            }
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
            });
            return (
              <div
                key={key}
                className={
                  activeRoute(prop.layout + prop.path) ? cls.navItem : null
                }
              >
                {console.log(routeFlag, "routeFlag")}
                <div className={cls.top}></div>

                <ListItem
                  disabled={
                    getDisbaledStatus(prop.path)
                  }
                  key={key}
                  onClick={() => {
                    if (
                      prop.path == "/FLNHome" ||
                      prop.path == "/FLNStudentList" ||
                      prop.path == "/FLNTeacherList" ||
                      prop.path == "/FLNTransferTeacher" ||
                      prop.path == "/StudentView" ||
                      prop.path == "/FLNAnylsis"||
                      prop.path == "/LOAnylsis"
                    ) {
                      setRouteFlag(true);
                      setWelcomeFlag(false);
                      setSMCFlag(false);
                    } else if (prop.path === "/dashboard") {
                      setRouteFlag(false);
                      setWelcomeFlag(true);
                      setSMCFlag(false);
                    } else if (prop.path == "/SMCHome") {
                      setSMCFlag(true);
                      setRouteFlag(false);
                      setWelcomeFlag(false);
                    } else {
                      setRouteFlag(false);
                    }
                    console.log(prop.path, "path");
                    if (prop.path == "/logout") {
                      localStorage.clear();
                      history.replace("/");
                      return;
                    }
                    history.push(prop.layout + prop.path);
                    return;
                  }}
                  button
                  // className={classes.itemLink + listItemClasses + cls.navItem}
                  className={[classes.itemLink].join(" ")}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        activeRoute(prop.layout + prop.path)
                          ? cls.activeItem
                          : null,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}

                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    className={classNames(
                      classes.itemText,
                      activeRoute(prop.layout + prop.path)
                        ? cls.activeItem
                        : null,
                      whiteFontClasses,
                      {
                        [classes.itemTextRTL]: props.rtlActive,
                      }
                    )}
                    disableTypography={true}
                  />
                </ListItem>
                <div className={cls.bottom}></div>
              </div>
            );
          })}
        </List>
      );
    }
  };

  const getDisbaledStatus = (data) => {
    let flag;
    if (
      data == "/FLNStudentList" ||
      data == "/FLNTeacherList" ||
      data == "/FLNTransferTeacher" ||
      data == "/StudentView"
    ) {
      if (school.hasOwnProperty("school_name")) {
        flag = false;
      } else {
        flag = true;
      }
    } else {
      flag = false;
    }
    console.log(school, "dataschool");
    return flag;
  };
  const adminFLNFlag = () => {
    let url = window.location.href;

    let index = url.lastIndexOf("/") + 1;
    let filename = url.substr(index);
    console.log(filename, "filename");
    if (
      filename === "FLNHome" ||
      filename === "NipunBharat" ||
      filename === "LOAdminAnalysis" ||
      filename === "FLN" ||
      filename === "FLNStudentList" ||
      filename === "FLNTeacherList" ||
      filename === "StudentView"
    ) {
      return true;
    }
  };

  const adminUrlFlag = () => {
    let url = window.location.href;
    let index = url.lastIndexOf("/") + 1;
    let filename = url.substr(index);
    console.log(filename, "filename");
    if (filename === "AMSMC" || filename === "HomeSMC" ||filename === "SMCAnalytics" ) {
      return true;
    }
  };
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div
            className={[classes.sidebarWrapper, cls.sideBarContainer].join(" ")}
          >
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {renderMenuItems()}
          </div>
          {image !== undefined ? (
            <div
              style={{
                backgroundImage: "url(" + image + ")",
              }}
              className={classes.background}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <div>
          <Drawer
            anchor={props.rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: classNames(classes.drawerPaper, {
                [classes.drawerPaperRTL]: props.rtlActive,
              }),
            }}
          >
            {brand}
            <div style={{ width:'265px'}} className={classes.sidebarWrapper}>{renderMenuItems()}</div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
          {console.log(school, "school454")}
          {userData.user_type == "ADMINISTRATIVE" ? (
            <div style={{ backgroundColor: "#414FB5", height: "60px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "15%",
                    borderRadius: "15px",
                    textAlign: "center",
                    marginLeft: "15%",
                    marginTop: "15px",
                  }}
                >
                  {school?.student_district}
                </div>

                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "20%",
                    borderRadius: "15px",
                    textAlign: "center",
                    marginLeft: "15%",
                    marginTop: "15px",
                  }}
                >
                  {school?.school_name?.length > 35
                    ? `${school?.school_name.substr(0, 35)}..`
                    : school?.school_name}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ backgroundColor: "#414FB5", height: "60px" }}>
              <div
                style={{
                  display: "flex",
                  marginLeft: "17em",
                  justifyContent: "space-around",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "25%",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  {school?.school_name?.length > 35
                    ? `${school?.school_name.substr(0, 35)}..`
                    : school?.school_name}
                </div>
                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "15%",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  {school?.district}
                </div>
                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "15%",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  {school?.block}
                </div>
                <div
                  style={{
                    backgroundColor: "#D9D7DA",
                    width: "15%",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  {school?.u_dise}
                </div>
              </div>
            </div>
          )}
        </div>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};