import React, { Component } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Hidden from "@material-ui/core/Hidden";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

export default class CheckingChapterPart extends Component {
  state = {
    numberOfPart: 4,
    typeOfComponent: ["Text", "Image", "Video"],
    value: 0,
    tab1data: [],
    tab2data: [],
    tab3data: [],
  };

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  addData(type) {
    let { value, tab1data, tab2data, tab3data } = this.state;
    if (value == 0) {
      tab1data.push(type);
    } else if (value == 1) {
      tab2data.push(type);
    } else if (value == 2) {
      tab3data.push(type);
    }
    this.setState({ tab1data, tab2data, tab3data });
  }

  renderdataforTab1() {
    let { tab1data, tab2data, tab3data, value } = this.state;
    let array = [];
    if (value == 0) {
      array = tab1data;
    } else if (value == 1) {
      array = tab2data;
    } else {
      array = tab3data;
    }
    return array.map((type, index) => {
      if (type == "text") {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="text"
              id="text"
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
        );
      } else if (type == "image") {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <input type="file" accept="image/jpeg" />
          </GridItem>
        );
      } else if (type == "video") {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <input type="file" accept="video/" />
          </GridItem>
        );
      } else if (type == "pdf") {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <input type="file" accept=".pdf" />
          </GridItem>
        );
      }else if(type == "textarea"){
        return (
          <GridItem xs={12} sm={12} md={12}>
            <input type="file" accept=".pdf" />
          </GridItem>
        );
      }
    });
  }

  render() {
    let { value } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="primary">
                <h4>Checking Lesson Parts</h4>
              </CardHeader>
              <CardBody>
                <AppBar position="static">
                  <Hidden only="xs">
                    <Tabs
                      value={value}
                      onChange={this.handleTabChange}
                      variant="scrollable"
                    >
                      <Tab label="Part 1" />
                      <Tab label="Part 2" />
                      <Tab label="Part 3" />
                    </Tabs>
                  </Hidden>
                  <Hidden smUp>
                    <Tabs
                      value={value}
                      onChange={this.handleTabChange}
                      variant="fullWidth"
                      orientation="vertical"
                    >
                      <Tab label="Part 1" />
                      <Tab label="Part 2" />
                      <Tab label="Part 3" />
                    </Tabs>
                  </Hidden>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <GridContainer>
                    {this.renderdataforTab1()}
                    
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Additional Knowledge"
                        id="Additional Knowledge"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Tag"
                        id="Tag"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer> 
                  <Button color="primary">Save</Button>{" "}
                  <Button color="primary">Next</Button>
                  <Button color="primary">Submit</Button>
                  <Button color="primary">Suggest Improvment and Publish</Button>
                  <Button color="primary">Start Rating</Button>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <GridContainer>
                    {this.renderdataforTab1()}
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Additional Knowledge"
                        id="Additional Knowledge"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Tag"
                        id="Tag"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <Button color="primary">Save</Button>{" "}
                  <Button color="primary">Next</Button>
                  <Button color="primary">Submit</Button>
                  <Button color="primary">Suggest Improvment and Publish</Button>
                  <Button color="primary">Start Rating</Button>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <GridContainer> {this.renderdataforTab1()}</GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Additional Knowledge"
                        id="Additional Knowledge"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Tag"
                        id="Tag"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  <Button color="primary">Save</Button>{" "}
                  <Button color="primary">Next</Button>
                  <Button color="primary">Submit</Button>
                  <Button color="primary">Suggest Improvment and Publish</Button>
                  <Button color="primary">Start Rating</Button>
                </TabPanel>
              </CardBody>
              <CardFooter>
                
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
