//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
import classes from "./LOAnalysis.module.css";
import {
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import { Button, CircularProgress } from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import { array } from "prop-types";
import { Loader } from "react-bootstrap-typeahead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { GetAppOutlined } from "@material-ui/icons";


const data = [
  "मराठी वाचन",
  "इंग्रजी वाचन",
  "गणित",
  "मराठी श्रुतलेखन",
  "इंग्रजी श्रुतलेखन",
];
const data1 = ["मराठी वाचन", "इंग्रजी वाचन", "गणित", "मराठी श्रुतलेखन"];
const monthData = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const arrayData = [];
const styles = {
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
    padding: "8px 8px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
export default class LOAnylsis extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    tab1: true,
    tab2: false,
    tab3: false,
    school: JSON.parse(localStorage.getItem("HMSchool")),
    subjectList: null,
    anylyticsList: null,
    mediumList: null,
    yearList: null,
    monthList: null,
    classList: null,
    selectedYear: null,
    selectedMonth: [],
    selectedStage: null,
    selectedMedium: null,
    selectedClass: null,
    selectedMediumName: null,
    selectedClassName: null,
    allMediumSelected: null,
    allYearSelected: null,
    allStandardSelected: null,
    NextFLNSub: null,
    firstTime: false,
    arrayAnyltics: null,
    arrayForLine:null,
    allClassSelected: null,
    monthListAxis: [],
    allCount: null,
    selectedSub: null,
    levelWiseCount: null,
    avaialbleCount: null,
    comapareArray: null,
    monthDataType: JSON.parse(localStorage.getItem("HMSchool")).fln_data_type,
    levelComparsionArr: [],
    ChaluSelectedYear: null,
    ChaluSelectedMonth: null,
    selectedHalf: "FIRST HALF",
    TulanaSelectedYear: null,
    TulanaSelectedMonth: null,
    TulanaselectedHalf: "FIRST HALF",
    lineSelectedHalf: null,
    loading: false,
    ChaluSelectedMedium:null,
    ChaluSelectedClass:null,
    loSubjectList:[],
    selectedLoSubject:null,
    currentPos:0,
    viewtab3Table:false,
    tab3table:[],
    comparisionClass:null,
    comparisionMedium:null,
    comparisionSubjectList:[],
    selectedLoSubjectComparsion:null,
    viewLineGraph:false
  };

  componentDidMount() {
    this.getSubjectList();
    this.getMedium();
    this.getYears();
  }

  currentInfo = () => {
    return (
      <div
        style={{
          fontSize: "20px",
          padding: "20px",
          color: "#415EB6",
          fontFamily: "Mukta",
        }}
      >
        <div>शाळेचे नाव: {this.state.school?.school_name}</div>
        <div>
       नुकत्याच भरलेल्या माहितीनुसार विद्यार्थ्यांचा अध्ययन निष्पत्ती खालीलप्रमाणे आहे.
        </div>
        {console.log(arrayData, "arrayAnyltics")}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            color: "#fff",
          }}
        >
          <div>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedYear}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                
                this.setState((i)=>(
                  {
                    ChaluSelectedYear: e.target.value,
                    firstTime: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[]  
                }),()=>{
                  this.getLoMonthsList(e.target.value);
                })
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.yearList?.map((res) => {
                return <MenuItem value={res}>{res}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMonth}
              onChange={(e) => {
                console.log(e.target.value, "eee");
                this.setState((i)=>(
                  {
                    ChaluSelectedMonth: e.target.value,
                    firstTime: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[]
                  
                }))
              
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.monthList?.map((res) => {
                return <MenuItem value={res}>{res?.months}</MenuItem>;
              })}
            </Select>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedMedium}
              onChange={(e) => {
                console.log(e.target.value, "eee");

                this.setState((i)=>(
                  {
                    ChaluSelectedMedium: e.target.value,
                    firstTime: false,
                    currentPos:1,
                    selectedLoSubject : i?.loSubjectList?.length > 0 ? i?.loSubjectList?.[0]?.id : null ,
                    levelComparsionArr:[],
                    ChaluSelectedClass:null,
                }),()=>{
                  // this.getLoSubjectList()
                })
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.mediumList?.map((res) => {
                return <MenuItem value={res}>{res?.medium_name}</MenuItem>;
              })}
            </Select>
          </div>

          
          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.ChaluSelectedClass}
              disabled={this.state.ChaluSelectedMedium == null ? true : false }
              onChange={(e) => {
                console.log(e.target.value, "eee");

                this.setState((i)=>({
                  ChaluSelectedClass: e.target.value,
                    firstTime: false, 
                }),()=>{
                  this.getLoSubjectList()
                })

              }}
            >
            {this.state.classList?.map((res) => {
                return <MenuItem value={res}>{res?.class_name}</MenuItem>;
              })}
            </Select>
          </div>
          

          <div>
            {!this.state.loading ? (
              <Button
                style={{
                  textAlign: "center",
                  backgroundColor: "#7965DA",
                  padding: "10px",
                  width: "7%",
                  borderRadius: "10px",
                  color: "#fff",
                  marginLeft: "10px",
                }}
                onClick={() => this.getFirstAnyltics()}
              >
                search
              </Button>
            ) : (
              <CircularProgress />
            )}
          </div>
          {console.log(
            this.state.levelComparsionArr,
            "list values",
            this.state.monthList
          )}
        </div>
        <div >
          {this.state.levelComparsionArr?.map((res, i) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
              {console.log("sdfasfdsafafaf",res)}
                <div style={{ display: "flex" ,justifyContent:'center'}}>
                  <div
                    style={{
                      margin: "10px",
                      borderRadius: "10px",
                      color: "#fff",
                      backgroundColor: "#2BBC6E",
                      padding: "10px",
                      // marginLeft:
                      //   i + 1 == this.state.levelComparsionArr?.length
                      //     ? "15%"
                      //     : "35%",
                    }}
                  >
                    {this.state?.loSubjectList?.[i]?.lo_subject}
                  </div>
                </div>
                {this.getCharts(res?.analytics_data, res)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  getMonthValue = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!

    console.log(dd, mm, "ddmm");
    let half = dd > 15 ? "II" : "I";
    let value = `${monthData[mm - 1]}-${half}`;
    return (
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#6872DD",
        }}
      >
        {" "}
        महिना :{value}
      </div>
    );
  };
  comparision = () => {
    return (
      <>
        <div
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          <div>शाळेचे नाव: {this.state.school?.school_name}</div>
          <div>
        आपल्या शाळेची जिल्हा व तालुका स्तराशी तुलना केली असता खालील प्रमाणे माहिती मिळाली.
          </div>


          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              color: "#fff",
            }}
          >
            <div>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.TulanaSelectedYear}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      TulanaSelectedYear: e.target.value,
                      TulanaSelectedMonth: null,
                      levelWiseCount: null,
                      avaialbleCount: null,
                      comapareArray: null,
                    },
                    () => {
                      this.getLoMonthsList(e.target.value);
                      this.getDataForCompare();
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                {this.state.yearList?.map((res) => {
                  return <MenuItem value={res}>{res}</MenuItem>;
                })}
              </Select>
            </div>

            <div style={{ marginLeft: "10px" }}>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.TulanaSelectedMonth}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState(
                    {
                      TulanaSelectedMonth: e.target.value,  
                        levelWiseCount: null,
                          avaialbleCount: null,
                          comapareArray: null,

                    },
                    () => {
                      this.getDataForCompare();
                      // this.getDataForCompare();
                      // this.getStudentBlock(this.state.selectedDistrict);
                    }
                  );
                }}
              >
                {console.log(this.state.districts, "district")}
                {this.state.monthList?.map((res) => {
                  return <MenuItem value={res}>{res?.months}</MenuItem>;
                })}
              </Select>
            </div>


            <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.comparisionMedium}
              onChange={(e) => {
                console.log(e.target.value, "eee");

                this.setState((i)=>(
                  {
                    comparisionMedium: e.target.value,
                    comparisionClass:null ,
                    levelWiseCount: null,
                          avaialbleCount: null,
                          comapareArray: null,
                          selectedLoSubjectComparsion:null,
                          comparisionSubjectList:[]
                   
                }),()=>{
                  this.getDataForCompare();
                  // this.getLoSubjectList()
                })
              }}
            >
              {console.log(this.state.districts, "district")}
              {this.state.mediumList?.map((res) => {
                return <MenuItem value={res}>{res?.medium_name}</MenuItem>;
              })}
            </Select>
          </div>

          
          <div style={{ marginLeft: "10px" }}>
            <Select
              labelId="demo-controlled-open-select-label2"
              id="demo-controlled-open-select2"
              value={this.state.comparisionClass}
              disabled={this.state.comparisionMedium == null ? true : false }
              onChange={(e) => {
                console.log(e.target.value, "eee");

                this.setState((i)=>({
                  comparisionClass: e.target.value, 
                  levelWiseCount: null,
                    avaialbleCount: null,
                    comapareArray: null,
                }),()=>{
                  this.getLoSubjectListComparsion()
                })

              }}
            >
            {this.state.classList?.map((res) => {
                return <MenuItem value={res}>{res?.class_name}</MenuItem>;
              })}
            </Select>
          </div>

            {this.state.monthDataType === "MONTH_HALF" ? (
              <div style={{ marginLeft: "10px" }}>
                <Select
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.TulanaselectedHalf}
                  onChange={(e) => {
                    console.log(e.target.value, "eee");
                    this.setState(
                      {
                        TulanaselectedHalf: e.target.value,
                      },
                      () => {
                        this.getDataForCompare();
                        // this.getStudentBlock(this.state.selectedDistrict);
                      }
                    );
                  }}
                >
                  {console.log(this.state.districts, "district")}
                  <MenuItem value="FIRST HALF">part-I</MenuItem>
                  <MenuItem value="SECOND HALF">part-II</MenuItem>
                </Select>
              </div>
            ) : null}

            {console.log(
              this.state.monthDataType,
              "list values",
              this.state.monthList
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {console.log(this.state.selectedSub, "sub")}
            {this.state.comparisionSubjectList?.map((res) => {
              return (
                <div
                  onClick={() => {
                    this.setState({
                      selectedLoSubjectComparsion:res 
                    },()=>{
                      this.CompareInfo(res);
                    })
                  }}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                    color: "#fff",
                    backgroundColor:
                      this.state.selectedLoSubjectComparsion?.id == res?.id
                        ? "#2BBC6E"
                        : "#8D9A93",
                    padding: "15px",
                    width: "18%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {res.lo_subject}
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.getComaparision()}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          <div>
            <div>जिल्हा स्तर</div>
            <div>
              विद्यार्थी संख्या: {this.state.levelWiseCount?.student_district_id_count}{" "}
              उपलब्ध नोंदी: {this.state.avaialbleCount?.school__student_district_id}
            </div>
          </div>

          <div>
            <div>केंद्र स्तर</div>
            <div>
              विद्यार्थी संख्या:{" "}
              {this.state.levelWiseCount?.kendra_id_count
                ? this.state.levelWiseCount?.kendra_id_count
                : "NA"}{" "}
              उपलब्ध नोंदी:{" "}
              {this.state.avaialbleCount?.school__kendra_id
                ? this.state.avaialbleCount?.school__kendra_id
                : "NA"}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            color: "#415EB6",
            fontFamily: "Mukta",
            marginBottom: "40px",
          }}
        >
          <div>
            <div>तालुका स्तर</div>
            <div>
              विद्यार्थी संख्या: {this.state.levelWiseCount?.student_block_id_count}{" "}
              उपलब्ध नोंदी: {this.state.avaialbleCount?.school__student_block_id}
            </div>
          </div>

          <div>
            <div>शाळा स्तर</div>
            <div>
              विद्यार्थी संख्या: {this.state.levelWiseCount?.school_id_count} उपलब्ध
              नोंदी: {this.state.avaialbleCount?.school}
            </div>
          </div>
        </div>
      </>
    );
  };

  moreInfoTab = () => {

    let tableArr = []
    if (this.state.tab3table?.length > 0){
      let { lo, lo__level,lo__description,year, total, ...tableObject } = this.state.tab3table[0];
      tableArr = Object.keys(tableObject);

    }

    return (
      <>
        <div
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          <div>शाळेचे नाव: {this.state.school?.school_name}</div>
          <div>
         शाळेची इतर महिन्याची प्रगती पाहण्यासाठी खालील बाबी निवडा.
          </div>
        </div>
        <div style={{ borderBottom: "4px solid #8F50D6" }}></div>
        <div
          style={{
            borderBottomWidth: "4px",
            borderBottomColor: "black",
            marginTop: "35px",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <div>वर्ष</div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    this.setAllYears();
                  }}
                  style={{
                    backgroundColor:
                      this.state.allYearSelected?.length > 0
                        ? "#DB83E2"
                        : "#C2B0BB",
                  }}
                >
                  सर्व
                </Button>
                {this.state.yearList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            selectedYear: res,
                            allYearSelected: null,
                            tab3table:[],
                            arrayForLine:null,
                            viewLineGraph:false
                          },
                          () => {
                            this.getLoMonthsList(res);
                          }
                        );
                      }}
                      style={{
                        backgroundColor:
                          this.state.selectedYear === res
                            ? "#DB83E2"
                            : "#C2B0BB",
                        marginLeft: "10px",
                      }}
                    >
                      {res}
                    </Button>
                  );
                })}
              </div>
            </div>

            <div style={{ marginTop: "35px" }}>
              <div>महिना</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.monthList?.map((res) => {
                  return (
                    <Button
                      onClick={() => {
                        // this.createMonthArray(res)
                        if (
                          this.state.selectedMonth?.length > 0 &&
                          this.state.selectedMonth.includes(res)
                        ) {
                          this.state.selectedMonth.splice(
                            this.state.selectedMonth.indexOf(res),
                            1
                          );
                          this.setState({
                            selectedMonth: [...this.state.selectedMonth],
                            tab3table:[],
                            arrayForLine:null,
                            viewLineGraph:false
                          });
                        } else {
                          this.setState({
                            selectedMonth: [...this.state.selectedMonth, res],
                            tab3table:[],
                            arrayForLine:null,
                            viewLineGraph:false
                          });
                        }
                      }}
                      style={{
                        backgroundColor: this.state.selectedMonth.includes(res)
                          ? "#DB83E2"
                          : "#C2B0BB",
                        marginLeft: "10px",
                        minWidth: "15%",
                        marginTop: "5px",
                        padding: "5px",
                      }}
                    >
                      {res?.months}
                    </Button>
                  );
                })}
                {console.log(this.state.selectedMonth, "select mnth")}
                {/* <Button style={{ backgroundColor: "#C2B0BB" }}>Oct</Button> */}
              </div>
            </div>

            {this.state.monthDataType == "MONTH_HALF" ? (
              <div style={{ marginTop: "35px" }}>
                <div>Part</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: null,
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == null
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    ALL
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "FIRST HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "FIRST HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    I
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        lineSelectedHalf: "SECOND HALF",
                      });
                    }}
                    style={{
                      backgroundColor:
                        this.state.lineSelectedHalf == "SECOND HALF"
                          ? "#DB83E2"
                          : "#C2B0BB",
                      marginLeft: "10px",
                      minWidth: "15%",
                      marginTop: "5px",
                      padding: "5px",
                    }}
                  >
                    II
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div style={{}}>
            <div>
              <div>
                <div>अध्ययनस्तर विषय निवडा.</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.subjectList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedStage: res,
                            tab3table:[],
                            arrayForLine:null,
                            viewLineGraph:false
                          });
                        }}
                        style={{
                          width: "15%",
                          marginTop: "5px",
                          backgroundColor:
                            this.state.selectedStage?.id === res.id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                        }}
                      >
                        {res.lo_subject}
                      </Button>
                    );
                  })}
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>माध्यम निवडा</div>
                <div style={{ display: "flex" }}>
                  {/* <Button
                    onClick={() => {
                      this.setAllMedium();
                    }}
                    style={{
                      backgroundColor:
                        this.state.allMediumSelected?.length > 0
                          ? "#DB83E2"
                          : "#C2B0BB",
                    }}
                  >
                    सर्व
                  </Button> */}
                  
                  {this.state.mediumList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedMedium: res.medium_id,
                            allMediumSelected: null,
                            selectedMediumName:res?.medium_name,
                            tab3table:[],
                            arrayForLine:null,
                            viewLineGraph:false
                          },()=>{
                            this.getLoSubjectListMore()
                          });
                        }}
                        style={{
                          backgroundColor:
                            this.state.selectedMedium === res.medium_id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                        }}
                      >
                        {res.medium_name}
                      </Button>
                    );
                  })}

                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>सेमी</Button> */}
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>आपली इयत्ता निवडा</div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {/* <Button
                    onClick={() => {
                      this.setAllStandrs();
                    }}
                    style={{
                      backgroundColor:
                        this.state.allClassSelected?.length > 0
                          ? "#DB83E2"
                          : "#C2B0BB",
                      width: "15%",
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    सर्व
                  </Button> */}
                  {this.state.classList?.map((res) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            selectedClass: res.class_id,
                            allClassSelected: null,
                            selectedClassName: res?.class_name,
                            tab3table:[],
                            arrayForLine:null,
                            viewLineGraph:false
                          },()=>{
                            this.getLoSubjectListMore()
                          });
                        }}
                        style={{
                          backgroundColor:
                            this.state.selectedClass === res.class_id
                              ? "#DB83E2"
                              : "#C2B0BB",
                          marginLeft: "10px",
                          width: "15%",
                          marginTop: "5px",
                        }}
                      >
                        {res.class_name}
                      </Button>
                    );
                  })}

                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>10 वी</Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "25px",
            display: "flex",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <Button
            onClick={() => {
              console.log(this.state.selectedMonth, "selectedMonth");
              if (this.state.selectedMonth.length > 0 && this.state.selectedStage != null ) {
                
                this.setState({
                  viewtab3Table:false
                },()=>{
                  this.getLineGraph();
                })
              }
            }}
            style={{ backgroundColor: this.state.viewtab3Table == false ? "#7965DA" : "#C2B0BB", color: "#fff" }}
          >
            आलेख पहा
          </Button>
          <Button
            style={{
              backgroundColor: this.state.viewtab3Table == true ? "#7965DA" : "#C2B0BB",
              color: "#fff",
              marginLeft: "10px",
            }}
            onClick={() => {

              if (this.state.selectedMonth.length > 0 && this.state.selectedStage != null ) {
                this.setState({
                  viewtab3Table:true
                },()=>{
                  this.getLineGraphTable();
                })
              }
              
            }}
          >
            सारणी पहा
          </Button>
         <div>
        
         <Button
            style={{
              backgroundColor: this.state.viewtab3Table == true ? "#7965DA" : "#C2B0BB",
              color: "#fff",
              marginLeft: "10px",
            }}
            onClick={() => {

              window.open("https://docs.google.com/spreadsheets/d/1TrkPwA1BP7CLbBehodxzOPK7emvCXGilchsiaKwwQgU/edit?usp=sharing", '_blank');
              
            }}
          >
           <IconButton
                  aria-label="delete"
                 style={{
                  width:"10px",
                  height:"10px",
                  margin:"2px"
                 }}
                >
                  <GetAppOutlined style={{
                    color:"white"
                  }}  />
                </IconButton>
            विद्यार्थी यादी 
          </Button>
         </div>
        </div>

        {
          this.state.viewtab3Table == false ? (
            <>

{
  this.state.arrayForLine ? (
     <>
     {this.state.allCount ? (
          <div
            style={{
              color: "#415EB6",
              fontSize: "20px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            {console.log(this.state.allCount, "allCount")}
            <div>
              विद्यार्थी संख्या: {this.state.allCount?.total_student}
            </div>
            <div style={{ marginLeft: "20px" }}>
              {"  "} उपलब्ध नोंदी:{" "}
              {this.state.allCount?.total_student_uploaded}
            </div>
            {"  "}{" "}
            <div style={{ marginLeft: "20px" }}>
              अनुपलब्ध नोंदी: {this.state.allCount?.total_student_not_uploaded}
            </div>
          </div>
        ) : null}
     </>
  ) : (
    null
  )
}

        

        <div class="row">
          {console.log(this.state.arrayForLine, "arrayForLine")}

          {this.state.arrayForLine?.length > 0 ?   (
            <>
            {this.state.arrayForLine?.map((res, index) => {

let { lo, lo__level,lo__description, ...newObject } = res;

const categories = Object.keys(newObject);
const values = Object.values(newObject);

let array1=[]

return (
  <div
    class="col"
    style={{
      padding: "5px",
      marginTop: "25px",
      marginBottom: "40px",
    }}
  >
    <div style={{ display: "flex", marginBottom: "10px" }}>
      <div
        style={{
          marginLeft: "48%",
          padding: "10px",
          backgroundColor: "#2BBC6E",
          borderRadius: "10px",
        }}
      >
        स्तर : {res?.lo__level}
      </div>
    </div>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: "",
          },
          xAxis: {
            // categories: this.getCatogeriesForSpline(),
            categories: categories,
            crosshair: true,
          },
          yAxis: {
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text: "count",
            },
          },

          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          series: [
            {
              name: "Count",
              data: values,
            },
          ],
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "spline",
          },
        }}
      ></HighchartsReact>
    </div>
    <div style={{ marginBottom: "10px" }}>
      <div
        style={{
          marginLeft: "5%",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
         {res?.lo__level} : 
      </div>
      <div
        style={{
          marginLeft: "5%",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
      {res?.lo__description}
      </div>
    </div>
  </div>
);
})}
            </>
          ): <>
            {
              this.state.viewLineGraph == true ? (
                <p style={{
            textAlign:"center",
            marginTop:20
          }} >
          Graph is not availbale
          </p>
              ): null
            }
          </> }

          {/* {data1.map((res) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    title: {
                      text: "",
                    },
                    xAxis: {
                        categories: [this.state.selectedMonth],
                      crosshair: true,
                    },
                    yAxis: {
                      stackLabels: {
                        enabled: true,
                        align: "center",
                      },
                      visible: true,
                      title: {
                        enabled: true,
                        text:
                           "count",
                      },
                    },

                    labels: {
                      items: [
                        {
                          html: "",
                          style: {
                            left: "50px",
                            top: "18px",
                          },
                        },
                      ],
                    },
                    series: [
                      {
                        name: "Population",
                        data: [
                          ["Shanghai", 3.7],
                          // ["Lagos", 16.1],
                          // ["Istanbul", 14.2],
                          // ["Karachi", 14.0],
                          // ["Karachi", 14.0],
                        ],
                      },
                    ],
                    chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      width: window.innerWidth / 2.8,
                      type: "spline",
                    },
                  }}
                ></HighchartsReact>
                <div>
                  विद्यार्थी संख्या: १२५ उपलब्ध नोंदी: १२५ अनुपलब्ध नोंदी: १२
                </div>
              </div>
            );
          })} */}
        </div>

     
            </>
          ) : (
              <>
              {
                this.state.tab3table?.length > 0 ? (
                  <div style={{
                    marginBottom:"100px"
                  }}>
                  <div style={{
              display:"flex",
              justifyContent:"center",
              fontSize:"20px",
              color:"#415EB6",
              marginTop:"30px",
              marginBottom:"30px"
            }} >
           <span style={{
            marginRight:"10px"
           }} >
           वर्ग: {this.state.selectedClassName} 
           </span> 
           <span
           style={{
            marginRight:"10px",
            marginLeft:"10px"
           }} 
           >
           माध्यम: {this.state.selectedMediumName}
           </span>
            <span>
            विषय:{this.state.selectedStage?.lo_subject}
            </span>
            </div>
            <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead  style={{
                          backgroundColor:"#F8E6D0",
                        }}  >
                        <TableRow>
                        <TableCell align="left"></TableCell>
                          <TableCell align="center">Subject : {this.state.selectedStage?.lo_subject} </TableCell>
                          <TableCell align="right">Year : {
                            this.state.allYearSelected ? (this.state.allYearSelected?.map((res)=>{
                              return (`${res} `)
                            })) : this.state.selectedYear
                          } </TableCell>
                          {
                              tableArr?.map((res)=>{
                                return(
                                  <TableCell align="center">{''}</TableCell>
                                )
                              })
                            }
                        </TableRow>
                      </TableHead>
                      <TableHead  style={{
                          backgroundColor:"#F8E6D0"
                        }}  >
                        <TableRow>
                          <TableCell align="left">LO Code</TableCell>
                          <TableCell align="center">Description / Meaning</TableCell>
                            {
                              tableArr?.map((res)=>{
                                return(
                                  <TableCell align="center">{res}</TableCell>
                                )
                              })
                            }
                          <TableCell align="center">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.tab3table.map((row, index) => {

let { lo, lo__level,lo__description,year, total, ...tableObject } = row;
const objectEntries = Object.values(tableObject);

return(
  <TableRow key={index}>
                            <TableCell
                              
                              align="left"
                            >
                              {row?.lo__level}
                            </TableCell>
                            <TableCell align="center">
                              {row?.lo__description}
                            </TableCell>
                            {
                              objectEntries?.map((res)=>{
                                return(
                                  <TableCell align="center">
                              {res}
                            </TableCell> 
                                )
                              })
                            }
                            <TableCell align="center">
                              {row?.total}
                            </TableCell>
                          </TableRow>
)
                        }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
          style={{
            marginTop: "25px",
            display: "flex",
            justifyContent: "flex-end",
            color: "#fff",
          }}  
        >
          <Button
            style={{
              backgroundColor: "#C2B0BB",
              color: "#000",
              marginLeft: "10px",
              marginBottom:"200px",
              marginRight:"50px"
            }}
            onClick={() => {
              if(this.state.selectedMonth.length > 0 && this.state.selectedStage != null){
                this.downloadSaraniExcel()
              }
            }}
          >
        डाउनलोड करा
          </Button>
        </div>
        

                  </div>
                ) : null
               }
              </>
          ) 
        }

      </>
    );
  };

  getSubjectList = (data) => {
    //  arrayData.push(data)
    //  this.setState({
    //   arrayAnyltics : arrayData
    //  })
    // console.log(data,"fat",arrayData)
    let districtID = JSON.parse(localStorage.getItem('HMSchool')) ? JSON.parse(localStorage.getItem('HMSchool')).student_district_id : null 
    getHMService()
      .getSubjectList({
        student_district_id:districtID
      })
      .then((res) => {
        console.log(res, "res");
        if (res.data.results) {
          this.setState(
            {
              // subjectList: res.data.results,
              // selectedStage: res.data.results[0]?.subject_id,
              selectedSub: res.data.results[0]?.subject_id,
            },
            () => {
              // this.getFirstAnyltics();
            }
          );
        }
      });
  };


  getLoSubjectListComparsion =()=>{

    if(this.state?.comparisionClass != null && this.state.comparisionMedium != null){
      let url = `lo_subject/?student_class=${this.state?.comparisionClass?.class_id}&student_district=${this.state.school.student_district_id}&medium=${this.state.comparisionMedium?.medium_id}`
      getHMService()
        .getLoSubject(url)
        .then((res) => {
          console.log(res, "res lo subjectr");
          if (res.data.results) {
            this.setState(
              {
                comparisionSubjectList: res.data.results, 
                selectedLoSubjectComparsion : res.data.results?.length > 0 ? res.data.results[0] : null,
                levelWiseCount: null,
                avaialbleCount: null,
                comapareArray: null,
              },()=>{
                this.getDataForCompare()
              }
            );
          }
        });
  
    }
  }

  getLoSubjectList =()=>{


    if(this.state?.ChaluSelectedClass != null && this.state.ChaluSelectedMedium != null){
      let url = `lo_subject/?student_class=${this.state?.ChaluSelectedClass?.class_id}&student_district=${this.state.school.student_district_id}&medium=${this.state.ChaluSelectedMedium?.medium_id}`
      getHMService()
        .getLoSubject(url)
        .then((res) => {
          console.log(res, "res lo subjectr");
          if (res.data.results) {
            this.setState(
              {
                loSubjectList: res.data.results, 
                selectedLoSubject : res.data.results?.length > 0 ? res.data.results[0]?.id : null,
                currentPos:res.data.results?.length > 0 ? 1 : 0,
                levelComparsionArr:[]
              },
            );
          }
        });
  
    }

   
  }

  getFirstAnyltics = () => {

    if(this.state.ChaluSelectedMedium != null && this.state.ChaluSelectedClass != null  ){
      let param = {
        school: this.state.school.school_id,
        subject: this.state.NextFLNSub,
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth, 
      };
      let array = [];
  
    
      if ( this.state.selectedLoSubject != null ||  !this.state.firstTime  ) {
        let url=`/lo_data_analytics/get_lo_analytics_for_school/?school=${this.state.school?.school_id}&year=${this.state.ChaluSelectedYear}&month=${this.state.ChaluSelectedMonth?.id}&student_class=${this.state.ChaluSelectedClass?.class_id}&medium=${this.state.ChaluSelectedMedium?.medium_id}&district=${this.state.school.student_district_id}&block=${this.state.school.student_block_id}&lo_subject=${this.state.selectedLoSubject}`
  
        getHMService()
          .getLoAnalysisChalu(url)
          .then((res) => {
            // arrayData.push(res.data)
            console.log( "level1", res.data);

  
            if (!res?.data?.message) {
              array =
                this.state.levelComparsionArr.length ==
                this.state.loSubjectList?.length
                  ? []
                  : this.state.levelComparsionArr;
              array.push(res?.data);
              this.setState((i)=>({
                anylyticsList: res.data,
                // NextFLNSub: res.data.next_subject,
                firstTime: true,
                levelComparsionArr: array,
                loading: true,
                currentPos:i.currentPos + 1,
                selectedLoSubject :i.loSubjectList?.length > i.currentPos  ? i?.loSubjectList[i?.currentPos]?.id : null
              }),()=>{
                this.getFirstAnyltics();
              })
             
            }
          });
      } else {
        this.setState({
          loading: false,
        });
      }
    }

  };

  getMedium = () => {
    getContentWriterService()
      .getMediumbyState({
        state_id: 1,
      })
      .then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          this.setState({
            mediumList: res.data.response,
            selectedMedium: res.data.response[0]?.medium_id,
            selectedMediumName:res.data.response[0]?.medium_name,
          },()=>{
            this.getAllstandards();
          });
        }
      });
  };

  getAllstandards = async () => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
          selectedClass: res.data.response[0]?.class_id,
          selectedClassName: res.data.response[0]?.class_name
        },()=>{
          this.getLoSubjectListMore()
        });
      });
  };

  getCharts = (data, res) => {
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: {
              text: "",
            },
            xAxis: {
              categories: this.getCatergories(data),
              crosshair: true,
              title: {
                enabled: true,
                text: "L.O",
              },
            },
            yAxis: {
              stackLabels: {
                enabled: true,
                align: "center",
              },
              visible: true,
              title: {
                enabled: true,
                text: "अध्ययन निष्पत्ती प्रमाण",
              },
            },

            labels: {
              items: [
                {
                  html: "",
                  style: {
                    left: "50px",
                    top: "18px",
                  },
                },
              ],
            },
            series: this.getSerisForGraph(data),

            tooltip: {
              formatter: function () {
                var result = data.find((item) => item?.lo_name === this.x);

                let pcnt = result?.yes_count;

                return pcnt;
              },
            },

            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              width: (window.innerWidth - 260 - 120) / 1.05,
              type: "column",
              backgroundColor: "#fff",
            },
          }}
        ></HighchartsReact>
        {console.log(data, "data")}
        <div style={{ padding: "10px", display: "flex" }}>
          <div>विद्यार्थी संख्या: {res.total_student}</div>
          <div style={{ marginLeft: "25px" }}>
            उपलब्ध नोंदी: {res.total_student_uploaded}
          </div>
          <div style={{ marginLeft: "25px" }}>
            अनुपलब्ध नोंदी: {res.total_student_not_uploaded}
          </div>
        </div>
      </>
    );
  };

  getSerisForGraph = (data) => {
    let array = [];
    let obj = {
      name: "count",
      data: null,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    data.map((res) => {
      array.push(res.percentage);
    });

    obj = {
      name: "",
      data: array,
      color: "#7775E3",
      dataLabels: {
        enabled: true,
        align: "center",
        style: {
          fontSize: "10px",
          fontFamily: "helvetica, arial, sans-serif",
        },
      },
    };
    return [obj];
  };

  getCatergories = (data) => {
    let array = [];
    data.map((res) => {
      array.push(res?.lo_name);
    });
    return array;
  };

  getYears = () => {
    getHMService()
      .getYear()
      .then((res) => {
        console.log(res, "res");
        this.setState({
          yearList: res.data.years,
        });
        if (res.data.years.length > 0) {
          this.setState(
            {
              selectedYear: res.data.years[0],
              ChaluSelectedYear: res.data.years[0],
              TulanaSelectedYear: res.data.years[0],
            },
            this.getLoMonthsList(res.data.years[0])
          );
        }
      });
  };

  getMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.months)
          this.setState(
            {
              monthList: res.data.months,
              ChaluSelectedMonth: res.data.months[0],
              TulanaSelectedMonth: res.data.months[0],
              // selectedMonth: res.data.months[0],
            },
            () => {
              if (this.state.tab2) {
                this.getDataForCompare();
              } else if (this.state.tab1) {
                // this.getFirstAnyltics()
              }
            }
          );
      });
  };

  getLoMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getLoMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.results)
          this.setState(
            {
              monthList: res.data.results,
              ChaluSelectedMonth: res.data.results[res.data.results?.length - 1],
              TulanaSelectedMonth:res.data.results[res.data.results?.length - 1]
            },
            () => {

            }
          );
      });
  };

  getLoSubjectListMore = () =>{
    if(this.state?.selectedClass != null  && this.state.selectedMedium != null){
      let url = `lo_subject/?student_class=${this.state?.selectedClass}&student_district=${this.state.school.student_district_id}&medium=${this.state.selectedMedium}`
      getHMService()
        .getLoSubject(url)
        .then((res) => {
          console.log(res, "res lo subjectr s fa f");
          if (res.data.results) {
            this.setState(
              {
                subjectList: res.data.results, 
                selectedStage : res.data.results?.length > 0 ? res.data.results[0] : null,
              },
            );
          }
        });
  
    }
  }

  setAllMedium = () => {
    let array = [];
    this.state.mediumList.map((res) => {
      array.push(res.medium_id);
    });
    this.setState({ allMediumSelected: array, selectedMedium: null, selectedMediumName:null});
  };

  setAllYears = () => {
    let array = [];
    this.state.yearList.map((res) => {
      array.push(res);
    });
    this.setState({ allYearSelected: array, selectedYear: null , tab3table:[]});
  };

  setAllStandrs = () => {
    let array = [];
    this.state.classList.map((res) => {
      array.push(res.class_id);
    });
    this.setState({ allClassSelected: array, selectedClass: null,selectedMediumName: null });
  };

  getLineGraph = () => {
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
      lineSelectedHalf,
      selectedMediumName,
      selectedClassName,
    } = this.state;
      console.log(allMediumSelected, "allMediumSelected", allClassSelected);


    let monthArray = selectedMonth?.map(item => item.id) 
   let  param = {
    school: school.school_id,
    subject: selectedStage,
    month_list: monthArray,
    year_list: allYearSelected ? allYearSelected : [selectedYear],
    class_list: allClassSelected ? allClassSelected : selectedClass,
    medium_list: selectedMedium,
    medium_name: selectedMediumName,
    class_name: selectedClassName,
  };



  let url =`lo_data_analytics/get_lo_analytics_for_months/?school=${school.school_id}&year=[${param?.year_list}]&month=[${param?.month_list}]&student_class=${param?.class_list}&medium=${param?.medium_list}&lo_subject=${param?.subject?.id}&medium_name=${param?.medium_name}&class_name=${param?.class_name}&subject_name=${param?.subject?.lo_subject}&district=${this.state.school.student_district_id}&block=${this.state.school.student_block_id}`

    getHMService()
      .getLoTrendlineAnalytics(url)
      .then((res) => {
        const entries = res?.data.analytics_data;

        if (entries.length > 0) {
          this.setState({
            arrayForLine: entries,
            allCount: res.data,
            viewLineGraph:true
          });
        } else {
          this.setState({
            arrayForLine: entries,
            allCount: res.data,
            viewLineGraph:true
          });
        }
        // this.setState({ arrayForLine: entries, allCount: res.data.all_counts });
      });

  };

  getLineGraphTable =()=>{
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
      lineSelectedHalf,
      selectedMediumName,
      selectedClassName,
    } = this.state;
      console.log(allMediumSelected, "allMediumSelected", allClassSelected);


    let monthArray = selectedMonth?.map(item => item.id) 
   let  param = {
    school: school.school_id,
    subject: selectedStage,
    month_list: monthArray,
    year_list: allYearSelected ? allYearSelected : [selectedYear],
    class_list: allClassSelected ? allClassSelected : selectedClass,
    medium_list: selectedMedium,
    medium_name: selectedMediumName,
    class_name: selectedClassName,
  };


  let url =`lo_data_analytics/get_lo_analytics_for_months/?school=${school.school_id}&year=[${param?.year_list}]&month=[${param?.month_list}]&student_class=${param?.class_list}&medium=${param?.medium_list}&lo_subject=${param?.subject?.id}&medium_name=${param?.medium_name}&class_name=${param?.class_name}&subject_name=${param?.subject?.lo_subject}&district=${this.state.school.student_district_id}&block=${this.state.school.student_block_id}&table_view=True`
  if(this.state.school?.kendra_id != null){
    url = url + `&kendra=${this.state.school?.kendra_id}`
  }


    getHMService()
      .getLoTrendlineAnalytics(url)
      .then((res) => {
        console.log("Tell me thre sresr",res?.data)
        if(res?.data){
          this.setState({
            tab3table:res?.data?.analytics_data
          })
    
        }
      });
  }

  downloadSaraniExcel = () => {
    const {
      school,
      selectedYear,
      selectedStage,
      selectedMedium,
      selectedMonth,
      selectedClass,
      allMediumSelected,
      allClassSelected,
      allYearSelected,
      lineSelectedHalf,
      selectedMediumName,
      selectedClassName,
    } = this.state;
      console.log(allMediumSelected, "allMediumSelected", allClassSelected);


    let monthArray = selectedMonth?.map(item => item.id) 
   let  param = {
    school: school.school_id,
    subject: selectedStage,
    month_list: monthArray,
    year_list: allYearSelected ? allYearSelected : [selectedYear],
    class_list: allClassSelected ? allClassSelected : selectedClass,
    medium_list: selectedMedium,
    medium_name: selectedMediumName,
    class_name: selectedClassName,
  };


  let url =`lo_data_analytics/get_lo_analytics_for_months/?school=${school.school_id}&year=[${param?.year_list}]&month=[${param?.month_list}]&student_class=${param?.class_list}&medium=${param?.medium_list}&lo_subject=${param?.subject?.id}&medium_name=${param?.medium_name}&class_name=${param?.class_name}&subject_name=${param?.subject?.lo_subject}&district=${this.state.school.student_district_id}&block=${this.state.school.student_block_id}&table_view=True&need_export=True`
  if(this.state.school?.kendra_id != null){
    url = url + `&kendra=${this.state.school?.kendra_id}`
  }

  console.log("tell me thsdfaf",this.state.school)

    getHMService()
      .getLoTrendlineAnalytics(url)
      .then((res) => {
        if(res?.data?.url){
          window.open(res?.data?.url);
    
        }
      });
  };

  convertJsonToCsv(items, fileTitle) {
    console.log(typeof items, "items");
    var exportedFilenmae = fileTitle || "export.csv";

    var blob = new Blob([items], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  createMonthArray = (res) => {
    if (
      this.state.selectedMonth?.length > 0 &&
      this.state.selectedMonth.includes(res)
    ) {
      this.state.selectedMonth.splice(this.state.selectedMonth.indexOf(res), 1);
    } else {
      this.state.selectedMonth.push(res);
    }
  };

  getCatogeriesForSpline = () => {
    let array = [];
    let array1 = [];
    this.state.arrayForLine.map((res) => {
      res.map((resp) => {
        console.log(resp, ":resp");
        if (typeof resp == "object") {
          array.push(Object.keys(resp));
        }
      });
    });

    console.log(array, "array45454545", array1);
    array = array?.length > 1 ? array[0] : array;
    console.log(array, "array");
    return array;
  };

  getLevels = (id) => {
    let array1 = [];
    this.state.arrayForLine.map((res) => {
      res.map((resp) => {
        console.log(resp, "4545resp");
        if (typeof resp !== "object") {
          array1.push(resp);
        }
      });
    });

    console.log(array1, "array45454545", array1);

    return array1[id];
  };
  getComaparision = () => {
    let array = [];
    let categoriesLabels = [];
    this.state.comapareArray &&
      this.state.comapareArray.map((res) => {
        console.log(res, "{res");
        let obj = {
          type: "column",
          name: res.level,
          data: [
            res.school__student_district_id_percentage,
            res.school__student_block_id_percentage,
            res.school_percentage,
            res.school__kendra_id_percentage,
          ],
        };
        array.push(obj);
        categoriesLabels.push(res.level);
      });

    console.log(array, "array");
    return array.length > 0 ? (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: "",
          },
          xAxis: {
            categories: categoriesLabels,
            crosshair: true,
          },
          yAxis: {
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text: "Percentage",
            },
          },
          tooltip: {
            formatter: function () {
              return this.y + "%";
            },
          },
          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },

          series: this.getSerisForCompareGraph(),

          chart: {
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "column",
          },
        }}
      ></HighchartsReact>
    ) : null;
  };

  getSerisForCompareGraph = () => {
    let arrayData = [];
    let obj1 = {};
    let obj2 = {};
    let obj3 = {};
    let obj4 = {};
    let array1 = [];
    let array2 = [];
    let array3 = [];
    let array4 = [];

    this.state.comapareArray &&
      this.state.comapareArray.map((res, index) => {
        console.log(res, "{res", typeof res.student_block_percentage);
        let districtCount = res.school__student_district_id_percentage
          ? res.school__student_district_id_percentage
          : 0;
        let blockCount = res.school__student_block_id_percentage
          ? res.school__student_block_id_percentage
          : 0;
        let schoolCount = res.school_percentage ? res.school_percentage : 0;
        let kendraCount = res.school__kendra_id_percentage ? res.school__kendra_id_percentage : 0;

        array1.push(districtCount);
        array2.push(blockCount);
        array3.push(schoolCount);
        array4.push(kendraCount);
      });

    obj1 = {
      type: "column",
      name: "District",
      color: "#23208C",
      data: array1,
    };
    obj2 = {
      type: "column",
      name: "Block",
      color: "#A0B745",
      data: array2,
    };
    obj3 = {
      type: "column",
      name: "School",
      color: "#47A0E1",
      data: array3,
    };
    obj4 = {
      type: "column",
      name: "Kendra",
      color: "#D547E1",
      data: array4,
    };
    arrayData.push(obj1, obj2, obj3, obj4);
    console.log(arrayData, "data");
    return arrayData;
  };
  CompareInfo = (res) => {
    this.setState(
      {
        selectedSub: res.subject_id,
      },
      () => [this.getDataForCompare()]
    );

    console.log(res, "res");
  };

  getDataForCompare = () => {


    if(this.state.selectedLoSubjectComparsion != null){

      let payload = {
        "school_id":this.state.school?.school_id,
        "year":this.state?.TulanaSelectedYear,
        "month": this.state?.TulanaSelectedMonth?.id,
        "month_name":this.state?.TulanaSelectedMonth?.months,
        "class":this.state.comparisionClass?.class_id,
        "subject":this.state.selectedLoSubjectComparsion?.id,
        "student_district_id":this.state.school?.student_district_id,
        "student_block_id":this.state.school?.student_block_id,
    }
    if(this.state?.school?.kendra_id){
      payload.kendra_id = this.state?.school?.kendra_id
    }
  
      getHMService()
        .getCompareLOAnalytics(payload)
        .then((res) => {
          console.log(res, "res t elle me ths da");
          if (res) {
            this.setState({
              comapareArray: res.data.region_level_wise_count,
              avaialbleCount: res.data.total_available_entries,
              levelWiseCount: res.data.total_students_region_wise,
  
            });
          }
        });
    }

  };
  render() {
    let { stdWiseGraph } = this.state;

    return (
      <>
        <button
          onClick={() => {
            this.setState(
              {
                tab1: true,
                tab2: false,
                tab3: false,
                levelComparsionArr:[],
                arrayForLine:null,
                tab3table:[],
                viewtab3Table:false,
                comparisionSubjectList:[],
                levelWiseCount: null,
                avaialbleCount: null,
                comapareArray:null,
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                loSubjectList:[],
                selectedLoSubject:null,
                currentPos:0,
                selectedLoSubjectComparsion:null,
                comparisionClass:null,
                comparisionMedium:null,
                selectedMonth: [],
                allCount: null,
                viewLineGraph:false
              },
              () => this.getYears()
            );
          }}
          className={
            this.state.tab1 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          चालू माहिती
        </button>
        <button
          onClick={() => {
            this.setState(
              {
                tab1: false,
                tab2: true,
                tab3: false,
                levelComparsionArr:[],
                arrayForLine:null,
                tab3table:[],
                viewtab3Table:false,
                comparisionSubjectList:[],
                levelWiseCount: null,
                avaialbleCount: null,
                comapareArray:null,
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                loSubjectList:[],
                selectedLoSubject:null,
                currentPos:0,
                selectedLoSubjectComparsion:null,
                comparisionClass:null,
                comparisionMedium:null,
                selectedMonth: [],
                allCount: null,
                viewLineGraph:false
              },
              () => {
                // this.getDataForCompare();
                this.getYears();
              }
            );
          }}
          className={
            this.state.tab2 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          तुलना करा
        </button>
        <button
          onClick={() => {
            this.setState(
              {
                tab1: false,
                tab2: false,
                tab3: true,
                levelComparsionArr:[],
                arrayForLine:null,
                tab3table:[],
                viewtab3Table:false,
                comparisionSubjectList:[],
                levelWiseCount: null,
                avaialbleCount: null,
                comapareArray:null,
                ChaluSelectedMedium:null,
                ChaluSelectedClass:null,
                loSubjectList:[],
                selectedLoSubject:null,
                currentPos:0,
                selectedLoSubjectComparsion:null,
                comparisionClass:null,
                comparisionMedium:null,
                selectedMonth: [],
                allCount: null,
                viewLineGraph:false
              },
              () => {
                // this.getDataForCompare();
                this.getYears();
              }
            );
          }}
          className={
            this.state.tab3 ? classes.currentPageButton : classes.nextPageButton
          }
        >
          अधिक माहिती पहा
        </button>

        {this.state.tab1
          ? this.currentInfo()
          : this.state.tab2
          ? this.comparision()
          : this.moreInfoTab()}
      </>
    );
  }
}