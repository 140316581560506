import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import GifIcon from '@material-ui/icons/Gif';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import SubjectIcon from '@material-ui/icons/Subject';
import CancelIcon from '@material-ui/icons/Cancel';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import { TextsmsOutlined } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: "column"
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'center',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    centerWidget: {
        alignItems: "center",
        display: "flex",
        height: "80%",
        justifyContent: "center",
        cursor: "pointer"
    }
}));

export default function PersistentDrawerRight(props) {
    console.log(props,"props")
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            {/* <CssBaseline /> */}
            {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
                title="Widgets panel"
                style={{
                    position: "fixed",
                    top: "50%",
                    right: "1%",
                    zIndex: 100,
                    background: "#fff"
                }}
            >
                <MenuIcon />
            </IconButton> */}
            {/* <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            > */}
            <div className="row">
                <div className="col-10" style={{ textAlign: "center", alignSelf: "center" }}>
                    {/* <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton> */}
                    Choose an element
                </div>
                <div className="col-2">
                    <IconButton onClick={()=>props.onCancelClick()}>
                        <CancelIcon style={{fill:"red"}}/>
                    </IconButton>
                </div>
            </div>
            <Divider />
            {/* <DragDropContext onDragEnd={(e) => props.onDragEndFunProp(e)}> */}
            {/* <Draggable> */}
            {/* {(provided) => ( */}
            <div className="row">
                <div className="col-6">
                    <Card className={classes.centerWidget} onClick={(e) => props.onAddData(e, "TEXT")}>
                        <SubjectIcon style={{fill:"blue"}}/>
                        <div>Text Editor</div>
                    </Card>
                </div>
                <div className="col-6">
                    <Card className={classes.centerWidget} onClick={(e) => props.onAddData(e, "SPECIAL_TEXT")}>
                        <TextsmsOutlined
                         style={{fill:"blue"}}/>
                        <div>Bloom's Activity </div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "IMG")}>
                    <Card className={classes.centerWidget}>
                        <ImageOutlinedIcon style={{fill:"cadetblue"}}/>
                        <div>Image</div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "GIF")}>
                    <Card className={classes.centerWidget}>
                        <GifIcon style={{fill:"brown"}}/>
                        <div>GIF</div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "AUDIO")}>
                    <Card className={classes.centerWidget}>
                        <AudiotrackIcon style={{fill:"pink"}}/>
                        <div>Audio</div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "VIDEO")}>
                    <Card className={classes.centerWidget}>
                        <OndemandVideoIcon style={{fill:"red"}}/>
                        <div>Video</div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "PDF")}>
                    <Card className={classes.centerWidget}>
                        <PictureAsPdfIcon style={{fill:"darkred"}}/>
                        <div>PDF</div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "PPT")}>
                    <Card className={classes.centerWidget}>
                        <FormatAlignLeftIcon style={{fill:"orange"}}/>
                        <div>PPT</div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "gForm")}>
                    <Card className={classes.centerWidget}>
                        <FormatAlignLeftIcon style={{fill:"purple"}}/>
                        <div>Google Form</div>
                    </Card>
                </div>
                <div className="col-6" onClick={(e) => props.onAddData(e, "json")}>
                    <Card className={classes.centerWidget}>
                        <SlideshowIcon style={{fill:"purple"}}/>
                        <div>Lottie File</div>
                    </Card>
                </div>
            </div>
            {/* )} */}
            {/* </Draggable> */}
            {/* </DragDropContext> */}
            {/* </Drawer> */}
        </div>
    );
}
