import React from "react";
import Snackbar from "./../../components/Snackbar/Snackbar";
import TextEditor from "./../../components/Editor/Editor";
import {
  Button,
  TextField,
  AddIcon,
  IconButton,
  Dialog,
  Switch,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ContentToolbar from "components/ContentToolbar/ContentToolbar";
import CustomInput from "components/CustomInput/CustomInput.js";
import ProgressBar from "components/ProgressBar/ProgressBar";
import AlertDialog from "components/AlertDialog/AlertDialog";
import VoiceRecorder from "components/VoiceRecorder/VoiceRecorder";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Mic from "@material-ui/icons/Mic";
import DragHandle from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import cls from "./Activity.module.css";
import * as constants from "./../../common/constants";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Modal from "@material-ui/core/Modal";
import { getSuperAdminService } from "./../../service/service";
import GadgetPanel from "../../components/GadgetPanel/GadgetPanel";
import ImageSlider from "components/ImageSlider/ImageSlider";
import SimpleImageSlider from "react-simple-image-slider";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";

const styles = {
  activityLbl: {
    fontSize: "30px",
    display: "flex",
    justifyContent: "center",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dropdown: {
    width: "45%",
    margin: "35px",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  cardFooter: {
    justifyContent: "flex-end",
    width: "96%",
  },
  activityBoard: {
    display: "flex",
    justifyContent: "left",
    //border :"3px solid 	grey",
    borderTop: "1px solid",
    flexDirection:'column'
  },
};

export default class Activity extends React.Component {
  state = {
    message: "",
    error: "",
    chapter: {},
    uploadProgress: "#",
    openAudioRecordDialog: false,
    audioContentExtra: {},
    showDeleteFieldDialog: false,
    activityName: "",
    validations: {
      activityName: {
        valid: true,
        touched: false,
      },
    },
    activityData: null,
    AddWidgetModal: false,
    activityArray: [],
    activityId: 0,
    editactivityId: 0,
    openSnack: false,
    deleteActivity: false,
    uploadMedia: {},
    slideActivity: [],
    hideButton: false,
    showActivity: false,
    activityTitle: "",
    AddEditWidgetModal: false,
    editActivity: false,
  };

  componentDidMount() {
    this.getActivity();
    this.setState({
      chapter: {
        color: "#f0f0f0",
        content: [],
        data: [],
        type: "TEXT",
      },
    });
  }

  setMessage(val) {}

  onChapterTextChange = (e, index, type, content_id, tag = "") => {
    console.log(e, "E", type, "type", index, "id");
    let value =
      type == "TEXT" ? e : e.hasOwnProperty("target") ? e.target.value : e;
    if (type === "Link") {
      value = {
        name:
          e.target.name === "linkName"
            ? e.target.value
            : this.state.activityArray[index].tag,
        value:
          e.target.name === "linkValue"
            ? e.target.value
            : this.state.activityArray[index].value,
      };
    }

    if (type === "VIDEO") {
      value = {
        url:
          typeof e == "object"
            ? e.target.value
            : this.state.activityArray[index].value,

        description:
          typeof e == "string" ? e : this.state.activityArray[index].tag,
      };
    }

    if (type === "IMG" || type == "GIF" || type == "json") {
      const id =
        type == "IMG"
          ? `#image-file-${index}`
          : type == "GIF"
          ? `#gif-file-${index}`
          : `#json-file-${index}`;
      const files = document.querySelector(id).files;
      value = {
        filePath: this.state.activityArray[index].value.filePath,
        description: e,
      };
    }

    let chapter = [...this.state.activityArray];
    chapter[index].value = value;

    this.setState({
      activityArray: chapter,
    });
  };
  onChapterEditTextChange = (e, index, type, content_id, tag = "") => {
    console.log(e, "E", type, "type", index, "id");
    let value =
      type == "TEXT" ? e : e.hasOwnProperty("target") ? e.target.value : e;
    if (type === "Link") {
      value = {
        name:
          e.target.name === "linkName"
            ? e.target.value
            : this.state.activityData[index].tag,
        value:
          e.target.name === "linkValue"
            ? e.target.value
            : this.state.activityData[index].value,
      };
    }

    if (type === "VIDEO") {
      value = {
        url:
          typeof e == "object"
            ? e.target.value
            : this.state.activityData[index].value,

        description:
          typeof e == "string" ? e : this.state.activityData[index].tag,
      };
    }

    if (type === "IMG" || type == "GIF" || type == "json") {
      const id =
        type == "IMG"
          ? `#image-file-${index}`
          : type == "GIF"
          ? `#gif-file-${index}`
          : `#json-file-${index}`;
      const files = document.querySelector(id).files;
      value = {
        filePath: this.state.activityData[index].value.filePath,
        description: e,
      };
    }

    let chapter = [...this.state.activityData];
    chapter[index].value = value;

    this.setState({
      activityData: chapter,
    });
  };
  setFieldToDelete = () => {
    let uploadActivity = [...this.state.activityArray];
    uploadActivity.splice(this.state.activityId, 1);
    this.setState({
      activityArray: uploadActivity,
    });
  };

  render() {
    let { validations, openAudioRecordDialog, activityData } = this.state;
    return (
      <GridContainer>
        <AlertDialog
          open={this.state.deleteActivity}
          title="Delete?"
          text="Do you want to delete this field?"
          onCancel={() => this.setState({ deleteActivity: false })}
          onConfirm={() => {
            this.setFieldToDelete();
            this.setState({ deleteActivity: false });
          }}
        />
        <Dialog
          onClose={() => {
            this.setState({
              openAudioRecordDialog: false,
            });
          }}
          aria-labelledby="simple-dialog-title"
          open={openAudioRecordDialog}
        >
          <VoiceRecorder
            onDone={(file) => {
              this.handleRecordedFile(file);
            }}
          />
        </Dialog>
        <Modal
          open={this.state.AddWidgetModal}
          onClose={() => {
            this.setState({
              AddWidgetModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <span
              style={{
                background: "white",
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <GadgetPanel
                onCancelClick={() => {
                  this.setState({
                    AddWidgetModal: false,
                  });
                }}
                onAddData={(e, type) => {
                  this.setState({ AddWidgetModal: false, hideButton: true });
                  this.addData(type, e);
                }}
              />
            </span>
          </div>
        </Modal>

        <Modal
          open={this.state.AddEditWidgetModal}
          onClose={() => {
            this.setState({
              AddEditWidgetModal: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <span
              style={{
                background: "white",
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <GadgetPanel
                onCancelClick={() => {
                  this.setState({
                    AddEditWidgetModal: false,
                  });
                }}
                onAddData={(e, type) => {
                  this.setState({ AddEditWidgetModal: false });
                  this.addEditData(type, e);
                }}
              />
            </span>
          </div>
        </Modal>
        <GridItem xs={12} sm={12} md={12}>
          <Snackbar
            autoHideDuration={2000}
            message="Sucrssfully created activity "
            color={this.state.error ? "danger" : "success"}
            close={false}
            place="br"
            closeNotification={() => this.setState({ openSnack: false })}
            rtlActive={true}
            open={this.state.openSnack}
          ></Snackbar>
          <Snackbar
            autoHideDuration={3000}
            message={this.state.message}
            color={this.state.error ? "danger" : "success"}
            close={false}
            place="br"
            closeNotification={() => this.setMessage("")}
            rtlActive={true}
            open={this.state.message ? true : false}
          ></Snackbar>
          <Card>
            {/* <CardHeader color="primary">
                            <h4 className={''}>Manage Activity</h4>
                            
                        </CardHeader> */}
            <CardBody>
              <div style={styles.activityLbl}> Create an activity </div>
              <hr />
              <TextField
                style={styles.dropdown}
                type="text"
                label="Activity Name"
                id="activityName"
                name="activityName"
                value={this.state.activityName}
                onChange={this.onChangeText.bind(this)}
                error={
                  !validations.activityName.valid &&
                  validations.activityName.touched
                }
                helperText={
                  !validations.activityName.valid &&
                  validations.activityName.touched
                    ? "Activity name is required"
                    : ""
                }
                onClick={(e) => {
                  let updatedValidations = { ...validations };
                  updatedValidations.activityName.touched = true;
                  updatedValidations.activityName.valid = true ? true : false;
                  this.setState({
                    validations: updatedValidations,
                  });
                }}
              />
              <hr style={{ color: "white" }} />
              {this.state.hideButton == false &&
              this.state.editActivity == false &&
              this.state.showActivity == false ? (
                <Button
                  style={{ marginLeft: "1%" }}
                  color="primary"
                  variant="outlined"
                  //className={cls.toolbarWrapper}
                  onClick={() => {
                    this.setState({
                      AddWidgetModal: true,
                      // hideButton : true
                    });
                  }}
                >
                  Add element
                </Button>
              ) : (
                ""
              )}
              <hr style={{ color: "white" }} />
              <GridContainer>{this.renderdataforTab()}</GridContainer>
              <Button
                color="primary"
                style={{ margin: "5px" }}
                variant="contained"
                onClick={this.onSubmitActivity}
                disabled={
                  this.state.activityName.trim() == "" ||
                  this.state.showActivity == true
                }
              >
                Create Activity
              </Button>
              <hr />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.showActivity}
                    color="primary"
                    onChange={() =>
                      this.setState({ showActivity: !this.state.showActivity })
                    }
                    name="Show Activity"
                  />
                }
                label="Show Activity"
              />
              {this.state.showActivity ? (
                <>
                  <legend style={{ padding: "5px", marginLeft: "20em" }}>
                    Activity Board
                  </legend>

                  {this.state.editActivity == false && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton onClick={this.startEditing}>
                        <EditIcon />
                      </IconButton>
                    </div>
                  )}

                  {this.state.editActivity == true && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton onClick={this.closeEditing}>
                        <ClearIcon />
                      </IconButton>

                      <IconButton onClick={this.saveEditing}>
                        <SaveIcon />
                      </IconButton>
                    </div>
                  )}

                  {this.state.editActivity && this.renderEditdataforTab()}
                  {this.state.editActivity !== true && (
                    <h4
                      style={{
                        fontFamily: "sans-serif",
                        padding: "5px 5px",
                        borderTop: "0.5px solid grey",
                      }}
                    >
                      Title : {this.state.activityTitle}
                    </h4>
                  )}
                  {this.state.editActivity !== true && (
                    <fieldset style={styles.activityBoard}>
                    {activityData && activityData.length > 0
                      &&  activityData?.map((res,index)=>{
                        return(
                          <div key={index}>
                         {
                          (res.type === 'TEXT') &&(
                            <div
                            style={{
                              display: "list-item",
                              fontSize: "2em",
                              fontFamily: "fantasy",
                              marginTop: "2em",
                              marginLeft: "2em",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: res.value,
                            }}
                          ></div>
                          )
                         }            
                           {
                            (res.type === "IMG" || res.type == "GIF" )
                            &&(
                              <div style={{ marginRight: "5em" }}>
                              <span>
                                <img
                                  src={
                                    res.value.filePath
                                    //activityData && this.state.uploadMedia ? this.state.uploadMedia.value.filePath : ""
                                    //activityData.type == "TEXT" || "VIDEO" || "AUDIO" || " gForm" || "PDF" ? null : activityData.value.filePath
                                  }
                                  style={{ width: "519px" }}
                                />
                                <br></br>
                                <br></br>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      res.value.description,
                                    //activityData.type == "TEXT" || "VIDEO" || "AUDIO" || " gForm" || "PDF"? null : activityData.value.description
                                  }}
                                ></div>
                              </span>
                            </div>
                            )
                           }

                           {
                            (res.type === "json" )
                            &&(
                              <div style={{ marginRight: "5em" }}>
                                      <span>
                                        <Player
                                          autoplay
                                          loop
                                          src={
                                            res.value &&
                                            res.value.hasOwnProperty(
                                              "filePath"
                                            )
                                              ? res.value.filePath
                                              : res.value
                                          }
                                          style={{
                                            height: "300px",
                                            width: "300px",
                                          }}
                                        >
                                          {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                                        </Player>

                                        <br></br>
                                        <br></br>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                            res?.value?.description,
                                          }}
                                        ></div>
                                      </span>
                                    </div>
                            )
                           }
                           {
                                  (res.type === "PPT" ) && (
                                    <iframe
                                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${res.value}`}
                                      style={{ width: "100%", height: 500 }}
                                      frameBorder="0"
                                    ></iframe>
                                  )
                           }

                           {
                                  (res.type === "VIDEO" ) && (
                                    <div>
                                        <iframe
                                          width="100%"
                                          height="480"
                                          src={`https://www.youtube.com/embed/${this.getYTVideoID(
                                            res.value
                                          )}`}
                                        ></iframe>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: res.description,
                                          }}
                                        ></div>
                                      </div>
                                  )
                           }

                           {
                                  (res.type === "PDF" ) && (
                                    <div>
                                         <iframe
                                      width="100%"
                                      height="480"
                                      src={res.value}
                                    ></iframe>
                                      </div>
                                  )
                           }
                           {
                                  (res.type === "AUDIO" ) && (
                                    <div>
                                         <audio controls style={{ width: "100%" }}>
                                      <source
                                        src={res?.value}
                                        type="audio/mpeg"
                                      />
                                    </audio>
                                      </div>
                                  )
                           }
                           {
                                  (res.type === "gForm" ) && (
                                    <div>
                                     <iframe
                                      src={res.value}
                                      width="100%"
                                      height="1923"
                                      frameBorder="0"
                                      marginHeight="0"
                                      marginWidth="0"
                                    >
                                      Loading…
                                    </iframe>
                                      </div>
                                  )
                           }
                           {
                                  (res.type === "Link" ) && (
                                    <div>
                                      <Button
                                      href={res.value}
                                      target="_blank"
                                      color="primary"
                                    ></Button>
                                      </div>
                                  )
                           }
                          </div>
                        )
                      })
                       }
                    </fieldset>
                  )}
                </>
              ) : null}
              <hr />

              {}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  getActivity = async () => {
    let activityData = await getSuperAdminService().getActivity();
    activityData = activityData.data;
    if (activityData && activityData.status === 200) {
      this.setState({
        activityTitle: activityData.response.activity_name,
      });
      let data = activityData.response.content;
      console.log(Array.isArray(activityData.response.content), "typeof");
      if (!Array.isArray(activityData.response.content)) {
        data = [activityData.response.content];
      }
      this.setState({
        activityData: data,
      });
    }
  };

  onSubmitActivity = async () => {
    let { activityArray, activityName } = this.state;
    let activityBody = {
      activity_name: activityName,
      content: activityArray,
      // [

      //    // type: activityArray[index].type,
      //     //value: activityArray[index].value.filePath ?activityArray[index].value.filePath : activityArray[index].value
      // ]
    };

    console.log(activityBody, "activity body");
    let activity = await getSuperAdminService().createActivity(activityBody);

    activity = activity.data;
    if (activity) {
      this.setState(
        {
          message: "Activity created successfully",
          openSnack: true,
          activityName: "",
          chapter: {
            hideButton: false,
            color: "#f0f0f0",
            content: [],
            data: [],
            type: "TEXT",
          },
          message: "",
        },
        () => {
          window.location.reload()
          this.getActivity();
        }
      );
    }
  };

  onChangeText(e) {
    let updatedValidations = { ...this.state.validations };
    updatedValidations[e.target.name].touched = true;
    updatedValidations[e.target.name].valid = e.target.value ? true : false;
    let activityName = e.target.value;
    this.setState({
      activityName,
      validations: updatedValidations,
    });
  }

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: "0",
    width: `100%`,
    ...draggableStyle,
  });

  startEditing = () => {
    this.setState({
      editActivity: true,
    });
  };
  closeEditing = () => {
    this.setState({
      editActivity: false,
    });
  };
  saveEditing = async () => {
    let activityBody = {
      activity_name: this.state.activityTitle,
      content: this.state.activityData,
    };
    console.log(activityBody, "activityData");
    let activity = await getSuperAdminService().createActivity(activityBody);
    activity = activity.data;
    if (activity) {
      this.setState(
        {
          message: "Activity created successfully",
          openSnack: true,
          activityName: "",
          chapter: {
            hideButton: false,
            color: "#f0f0f0",
            content: [],
            data: [],
            type: "TEXT",
          },
          message: "",
        },
        () => {
          window.location.reload();
        }
      );
    }
  };

  getYTVideoID = (value) => {
    if (value) {
      let url = value.hasOwnProperty("url") ? value.url : value;
      url = url.toString().trim();
      var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#]*).*/;
      var match = url.match(regExp);
      return match && match[1];
    } else {
      return;
    }
  };

  renderdataforTab() {
    // let index = Math.round(Math.random(100) * 100)
    let Array = this.state.activityArray.map((c) => c.type);
    return (
      <div>
        <div>
          {Array.map((type, index) => (
            <div
              key={index}
              style={{
                width: "80vw",
                display: "flex",
              }}
            >
              {type === "TEXT" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    marginTop: 20,
                    border: "3px solid #295939",
                    borderRadius: "4px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <span style={{ color: "gray" }}>Enter text content</span>
                  <TextEditor
                    onChange={(e) =>
                      this.onChapterTextChange(
                        e,
                        index,
                        type,
                        1,
                        this.state.activityArray[index].tag
                      )
                    }
                    onFocus={() => {}}
                    value={this.state.activityArray[index].value}
                  />
                </GridItem>
              ) : type == "IMG" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #184d47",
                    borderRadius: "4px",
                    background: "white",
                    marginTop: "15px",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <TextField
                        id={`image-file-${index}`}
                        accept="image/jpeg"
                        type="file"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        style={{ width: "100%", paddingTop: "15px" }}
                        onChange={(e) => {
                          this.onImageInputChange(
                            e,
                            index,
                            type,
                            this.state.activityArray[index].content_id
                          );
                        }}
                        placeholder="Image Path"
                        helperText="Choose Image file"
                      />
                      {this.state.uploadProgress.toString().split("#")[0] > 0 &&
                        this.state.uploadProgress.toString().split("#")[0] !=
                          100 &&
                        index ==
                          this.state.uploadProgress
                            .toString()
                            .split("#")[1] && (
                          <ProgressBar
                            value={
                              this.state.uploadProgress.toString().split("#")[0]
                            }
                          />
                        )}
                      {this.state.activityArray[index].value.hasOwnProperty(
                        "filePath"
                      ) &&
                        this.state.activityArray[index].value.filePath && (
                          <img
                            className={cls.preview}
                            src={
                              this.state.activityArray[index].value.filePath
                                ? this.state.activityArray[index].value.filePath
                                : this.state.activityArray[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="Image Description"
                      height="120"
                      name="ImgDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      placeholder="Image description"
                      onChange={(e) =>
                        this.onChapterTextChange(
                          e,
                          index,
                          type,
                          this.state.activityArray[index].content_id,
                          null,
                          this.state.activityArray[index].color
                        )
                      }
                      onFocus={() => {}}
                      value={
                        this.state.activityArray[index].value.hasOwnProperty(
                          "description"
                        )
                          ? this.state.activityArray[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : type == "VIDEO" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    border: "3px solid #e40017",
                    borderRadius: "4px",
                    background: "white",
                    marginTop: "15px",
                    marginRight: "12px",
                  }}
                >
                  <TextField
                    name="videoURL"
                    placeholder="URL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    style={{ width: "100%", paddingTop: "15px" }}
                    value={
                      this.state.activityArray[index].value &&
                      this.state.activityArray[index].value.hasOwnProperty(
                        "url"
                      )
                        ? this.state.activityArray[index].value.url
                        : this.state.activityArray[index].value
                    }
                    onChange={(e) =>
                      this.onChapterTextChange(
                        e,
                        index,
                        type,
                        this.state.activityArray[index].content_id
                      )
                    }
                  />
                  <span style={{ fontSize: 12 }}>
                    {`Example:-
                                    https://youtu.be/kHEvyeSKd6w?start=30&end=125`}
                  </span>
                  <br></br>
                  <TextEditor
                    height="120"
                    style={{
                      width: "100%",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      marginTop: "15px",
                    }}
                    inputProps={{
                      style: { fontSize: 15, marginTop: 15 },
                    }}
                    name="videoDesc"
                    placeholder="Description"
                    formControlProps={{
                      fullWidth: true,
                      rows: 4,
                    }}
                    value={
                      this.state.activityArray[index].value.hasOwnProperty(
                        "description"
                      )
                        ? this.state.activityArray[index].description
                        : ""
                    }
                    onChange={(e) =>
                      this.onChapterTextChange(
                        e,
                        index,
                        type,
                        this.state.activityArray[index].content_id,
                        null,
                        this.state.activityArray[index].color
                      )
                    }
                    onFocus={() => {}}
                  ></TextEditor>
                </GridItem>
              ) : type == "PDF" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #822659",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <TextField
                    id="pdf-file"
                    accept=".pdf"
                    label="PDF"
                    type="file"
                    onChange={(e) =>
                      this.onImageInputChange(
                        e,
                        index,
                        type,
                        this.state.activityArray[index].content_id
                      )
                    }
                    placeholder="PDF Path"
                    helperText="Choose PDF file"
                  />
                  {/* {this.state.uploadProgress
                                .toString()
                                .split("#")[0] > 0 &&
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[0] != 100 &&
                                index ==
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[1] && (
                                    <ProgressBar
                                        value={
                                            this.state.uploadProgress
                                                .toString()
                                                .split("#")[0]
                                        }
                                    />
                                )} */}
                  {/* <span>
                                    {this.state.chapter.parts[
                                        this.state.currentTabIndex
                                    ].data.length > 0
                                        ? this.state.chapter.parts[
                                            this.state.currentTabIndex
                                        ].data[index].value
                                        : ""}
                                </span> */}
                </GridItem>
              ) : type == "AUDIO" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #111d5e",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <div className={cls.audioWrapper}>
                    <TextField
                      id="audio-file"
                      accept="audio/mpeg"
                      label="Audio"
                      type="file"
                      onChange={(e) =>
                        this.onImageInputChange(
                          e,
                          index,
                          type,
                          this.state.activityArray[index].content_id
                        )
                      }
                      placeholder="Audio Path"
                      helperText="Choose Audio file"
                    />
                    {this.state.activityArray[index].value && (
                      <span className={cls.audioPlayer}>
                        <audio controls>
                          <source
                            src={this.state.activityArray[index].value}
                          ></source>
                        </audio>
                      </span>
                    )}
                  </div>

                  {this.state.uploadProgress.toString().split("#")[0] > 0 &&
                  this.state.uploadProgress.toString().split("#")[0] != 100 &&
                  index ==
                    this.state.uploadProgress.toString().split("#")[1] ? (
                    <ProgressBar
                      value={this.state.uploadProgress.toString().split("#")[0]}
                    />
                  ) : (
                    <div
                      className={cls.record}
                      onClick={() =>
                        this.setState({
                          openAudioRecordDialog: true,
                          audioContentExtra: {
                            index,
                            type,
                            content_id: this.state.activityArray[index]
                              .content_id,
                          },
                        })
                      }
                    >
                      <Mic />
                    </div>
                  )}
                </GridItem>
              ) : type == "PPT" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #351f39",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <TextField
                    id="ppt-file"
                    accept=".ppt"
                    label="PPT"
                    type="file"
                    onChange={(e) =>
                      this.onImageInputChange(
                        e,
                        index,
                        type,
                        this.state.activityArray[index].content_id
                      )
                    }
                    placeholder="PPT Path"
                    helperText="Choose PPT file"
                  />
                  {/* {this.state.uploadProgress
                                .toString()
                                .split("#")[0] > 0 &&
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[0] != 100 &&
                                index ==
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[1] && (
                                    <ProgressBar
                                        value={
                                            this.state.uploadProgress
                                                .toString()
                                                .split("#")[0]
                                        }
                                    />
                                )} */}
                  <span>{this.state.activityArray[index].value}</span>
                </GridItem>
              ) : type == "Link" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    "margin-top": "26px",
                    margin: "30px 14px",
                    justifyContent: "space-between",
                    marginRight: "12px",
                  }}
                >
                  <TextField
                    label="Name"
                    name="linkName"
                    style={{ width: "50%" }}
                    inputProps={{ style: { fontSize: 15 } }}
                    value={this.state.activityArray[index].value.name}
                    onChange={(e) =>
                      this.onChapterTextChange(
                        e,
                        index,
                        type,
                        this.state.activityArray[index].content_id
                      )
                    }
                  />
                  <TextField
                    label="Enter URL"
                    name="linkValue"
                    style={{
                      width: "45%",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{ style: { fontSize: 15 } }}
                    value={this.state.activityArray[index].value}
                    onChange={(e) =>
                      this.onChapterTextChange(
                        e,
                        index,
                        type,
                        this.state.activityArray[index].content_id
                      )
                    }
                  />
                </GridItem>
              ) : type == "gForm" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    border: "3px solid #09015f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <CustomInput
                    labelText="Enter your Google form link"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    val={this.state.chapter.value}
                    change={(e) =>
                      this.onChapterTextChange(
                        e,
                        index,
                        type,
                        this.state.chapter.content_id
                      )
                    }
                    Focus={() => {}}
                  />
                </GridItem>
              ) : type == "GIF" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #2c061f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <TextField
                        id={`gif-file-${index}`}
                        accept=".gif"
                        label="GIF"
                        type="file"
                        style={{ width: "100%", paddingTop: "15px" }}
                        onChange={(e) =>
                          this.onImageInputChange(
                            e,
                            index,
                            type,
                            this.state.activityArray[index].content_id
                          )
                        }
                        placeholder="GIF Path"
                        helperText="Choose GIF file"
                      />
                      {this.state.uploadProgress.toString().split("#")[0] > 0 &&
                        this.state.uploadProgress.toString().split("#")[0] !=
                          100 &&
                        index ==
                          this.state.uploadProgress
                            .toString()
                            .split("#")[1] && (
                          <ProgressBar
                            value={
                              this.state.uploadProgress.toString().split("#")[0]
                            }
                          />
                        )}
                      {this.state.activityArray[index].value.hasOwnProperty(
                        "filePath"
                      ) &&
                        this.state.activityArray[index].value.filePath && (
                          <img
                            className={cls.preview}
                            src={
                              this.state.activityArray[index].value.filePath
                                ? this.state.activityArray[index].value.filePath
                                : this.state.activityArray[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="GIF Description"
                      height="120"
                      name="gifDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      onChange={(e) =>
                        this.onChapterTextChange(
                          e,
                          index,
                          type,
                          this.state.activityArray[index].content_id,
                          null,
                          this.state.activityArray[index].color
                        )
                      }
                      onFocus={() => {}}
                      value={
                        this.state.activityArray[index].value.hasOwnProperty(
                          "description"
                        )
                          ? this.state.activityArray[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : type == "json" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #2c061f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <TextField
                        id={`json-file-${index}`}
                        accept=".json"
                        label="JSON"
                        type="file"
                        style={{ width: "100%", paddingTop: "15px" }}
                        onChange={(e) =>
                          this.onImageInputChange(
                            e,
                            index,
                            type,
                            this.state.activityArray[index].content_id
                          )
                        }
                        placeholder="Lottie Path"
                        helperText="Choose Lottie file"
                      />
                      {/* {this.state.uploadProgress
                                        .toString()
                                        .split("#")[0] > 0 &&
                                        this.state.uploadProgress
                                            .toString()
                                            .split("#")[0] != 100 &&
                                        index ==
                                        this.state.uploadProgress
                                            .toString()
                                            .split("#")[1] && (
                                            <ProgressBar
                                                value={
                                                    this.state.uploadProgress
                                                        .toString()
                                                        .split("#")[0]
                                                }
                                            />
                                        )} */}
                      {this.state.activityArray[index].value.hasOwnProperty(
                        "filePath"
                      ) &&
                        this.state.activityArray[index].value.filePath && (
                          <img
                            className={cls.preview}
                            src={
                              this.state.activityArray[index].value.filePath
                                ? this.state.activityArray[index].value.filePath
                                : this.state.activityArray[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="Lottie Description"
                      height="120"
                      name="LottieDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      onChange={(e) =>
                        this.onChapterTextChange(
                          e,
                          index,
                          type,
                          this.state.activityArray[index].content_id,
                          null,
                          this.state.activityArray[index].color
                        )
                      }
                      onFocus={() => {}}
                      value={
                        this.state.activityArray[index].value.hasOwnProperty(
                          "description"
                        )
                          ? this.state.activityArray[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : null}

              <div className={cls.item_actions}>
                <IconButton
                  aria-label="delete"
                  style={{ marginTop: 10 }}
                  title="Delete element"
                  onClick={() => {
                    this.setState({
                      deleteActivity: true,
                      activityId: index,
                    });
                  }}
                >
                  <DeleteIcon style={{ fill: "red" }} />
                </IconButton>
                <IconButton
                  title="Help"
                  size="small"
                  style={{ alignSelf: "center" }}
                  onClick={() => {}}
                >
                  <HelpOutlineIcon />
                </IconButton>

                <IconButton
                  title="Add element"
                  style={{ alignSelf: "center" }}
                  onClick={() => {
                    this.setState({
                      AddWidgetModal: true,
                      activityId: index,
                    });
                  }}
                >
                  <AddCircleOutlineIcon style={{ fill: "lightgreen" }} />
                </IconButton>
              </div>
            </div>
          ))}
          {/* {provided.placeholder} */}
        </div>
      </div>
    );
  }

  setEditToDelete = () => {
    let uploadActivity = [...this.state.activityData];
    uploadActivity.splice(this.state.editactivityId, 1);
    this.setState({
      activityData: uploadActivity,
    });
  };

  renderEditdataforTab() {
    // let index = Math.round(Math.random(100) * 100)
    let Array = this.state.activityData.map((c) => c.type);
    return (
      <div>
        <div>
          {Array.map((type, index) => (
            <div
              key={index}
              style={{
                width: "80vw",
                display: "flex",
              }}
            >
              {type === "TEXT" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    marginTop: 20,
                    border: "3px solid #295939",
                    borderRadius: "4px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <span style={{ color: "gray" }}>Enter text content</span>
                  <TextEditor
                    onChange={(e) =>
                      this.onChapterEditTextChange(
                        e,
                        index,
                        type,
                        1,
                        this.state.activityData[index].tag
                      )
                    }
                    onFocus={() => {}}
                    value={this.state.activityData[index].value}
                  />
                </GridItem>
              ) : type == "IMG" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #184d47",
                    borderRadius: "4px",
                    background: "white",
                    marginTop: "15px",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <TextField
                        id={`image-file-${index}`}
                        accept="image/jpeg"
                        type="file"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        style={{ width: "100%", paddingTop: "15px" }}
                        onChange={(e) => {
                          this.onEditImageInputChange(
                            e,
                            index,
                            type,
                            this.state.activityData[index].content_id
                          );
                        }}
                        placeholder="Image Path"
                        helperText="Choose Image file"
                      />
                      {this.state.uploadProgress.toString().split("#")[0] > 0 &&
                        this.state.uploadProgress.toString().split("#")[0] !=
                          100 &&
                        index ==
                          this.state.uploadProgress
                            .toString()
                            .split("#")[1] && (
                          <ProgressBar
                            value={
                              this.state.uploadProgress.toString().split("#")[0]
                            }
                          />
                        )}
                      {this.state.activityData[index].value.hasOwnProperty(
                        "filePath"
                      ) &&
                        this.state.activityData[index].value.filePath && (
                          <img
                            className={cls.preview}
                            src={
                              this.state.activityData[index].value.filePath
                                ? this.state.activityData[index].value.filePath
                                : this.state.activityData[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="Image Description"
                      height="120"
                      name="ImgDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      placeholder="Image description"
                      onChange={(e) =>
                        this.onChapterEditTextChange(
                          e,
                          index,
                          type,
                          this.state.activityData[index].content_id,
                          null,
                          this.state.activityData[index].color
                        )
                      }
                      onFocus={() => {}}
                      value={
                        this.state.activityData[index].value.hasOwnProperty(
                          "description"
                        )
                          ? this.state.activityData[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : type == "VIDEO" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    border: "3px solid #e40017",
                    borderRadius: "4px",
                    background: "white",
                    marginTop: "15px",
                    marginRight: "12px",
                  }}
                >
                  <TextField
                    name="videoURL"
                    placeholder="URL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    style={{ width: "100%", paddingTop: "15px" }}
                    value={
                      this.state.activityData[index].value &&
                      this.state.activityData[index].value.hasOwnProperty("url")
                        ? this.state.activityData[index].value.url
                        : this.state.activityData[index].value
                    }
                    onChange={(e) =>
                      this.onChapterEditTextChange(
                        e,
                        index,
                        type,
                        this.state.activityData[index].content_id
                      )
                    }
                  />
                  <span style={{ fontSize: 12 }}>
                    {`Example:-
                                    https://youtu.be/kHEvyeSKd6w?startTime=30&endTime=125`}
                  </span>
                  <br></br>
                  <TextEditor
                    height="120"
                    style={{
                      width: "100%",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      marginTop: "15px",
                    }}
                    inputProps={{
                      style: { fontSize: 15, marginTop: 15 },
                    }}
                    name="videoDesc"
                    placeholder="Description"
                    formControlProps={{
                      fullWidth: true,
                      rows: 4,
                    }}
                    value={
                      this.state.activityData[index].value.hasOwnProperty(
                        "description"
                      )
                        ? this.state.activityData[index].description
                        : ""
                    }
                    onChange={(e) =>
                      this.onChapterTextChange(
                        e,
                        index,
                        type,
                        this.state.activityData[index].content_id,
                        null,
                        this.state.activityData[index].color
                      )
                    }
                    onFocus={() => {}}
                  ></TextEditor>
                </GridItem>
              ) : type == "PDF" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #822659",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <TextField
                    id="pdf-file"
                    accept=".pdf"
                    label="PDF"
                    type="file"
                    onChange={(e) =>
                      this.onEditImageInputChange(
                        e,
                        index,
                        type,
                        this.state.activityData[index].content_id
                      )
                    }
                    placeholder="PDF Path"
                    helperText="Choose PDF file"
                  />
                  {/* {this.state.uploadProgress
                                .toString()
                                .split("#")[0] > 0 &&
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[0] != 100 &&
                                index ==
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[1] && (
                                    <ProgressBar
                                        value={
                                            this.state.uploadProgress
                                                .toString()
                                                .split("#")[0]
                                        }
                                    />
                                )} */}
                  {/* <span>
                                    {this.state.chapter.parts[
                                        this.state.currentTabIndex
                                    ].data.length > 0
                                        ? this.state.chapter.parts[
                                            this.state.currentTabIndex
                                        ].data[index].value
                                        : ""}
                                </span> */}
                </GridItem>
              ) : type == "AUDIO" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #111d5e",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <div className={cls.audioWrapper}>
                    <TextField
                      id="audio-file"
                      accept="audio/mpeg"
                      label="Audio"
                      type="file"
                      onChange={(e) =>
                        this.onEditImageInputChange(
                          e,
                          index,
                          type,
                          this.state.activityData[index].content_id
                        )
                      }
                      placeholder="Audio Path"
                      helperText="Choose Audio file"
                    />
                    {this.state.activityData[index].value && (
                      <span className={cls.audioPlayer}>
                        <audio controls>
                          <source
                            src={this.state.activityData[index].value}
                          ></source>
                        </audio>
                      </span>
                    )}
                  </div>

                  {this.state.uploadProgress.toString().split("#")[0] > 0 &&
                  this.state.uploadProgress.toString().split("#")[0] != 100 &&
                  index ==
                    this.state.uploadProgress.toString().split("#")[1] ? (
                    <ProgressBar
                      value={this.state.uploadProgress.toString().split("#")[0]}
                    />
                  ) : (
                    <div
                      className={cls.record}
                      onClick={() =>
                        this.setState({
                          openAudioRecordDialog: true,
                          audioContentExtra: {
                            index,
                            type,
                            content_id: this.state.activityData[index]
                              .content_id,
                          },
                        })
                      }
                    >
                      <Mic />
                    </div>
                  )}
                </GridItem>
              ) : type == "PPT" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #351f39",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <TextField
                    id="ppt-file"
                    accept=".ppt"
                    label="PPT"
                    type="file"
                    onChange={(e) =>
                      this.onEditImageInputChange(
                        e,
                        index,
                        type,
                        this.state.activityData[index].content_id
                      )
                    }
                    placeholder="PPT Path"
                    helperText="Choose PPT file"
                  />
                  {/* {this.state.uploadProgress
                                .toString()
                                .split("#")[0] > 0 &&
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[0] != 100 &&
                                index ==
                                this.state.uploadProgress
                                    .toString()
                                    .split("#")[1] && (
                                    <ProgressBar
                                        value={
                                            this.state.uploadProgress
                                                .toString()
                                                .split("#")[0]
                                        }
                                    />
                                )} */}
                  <span>{this.state.activityData[index].value}</span>
                </GridItem>
              ) : type == "gForm" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    border: "3px solid #09015f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <CustomInput
                    labelText="Enter your Google form link"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    val={this.state.chapter.value}
                    change={(e) =>
                      this.onChapterEditTextChange(
                        e,
                        index,
                        type,
                        this.state.chapter.content_id
                      )
                    }
                    Focus={() => {}}
                  />
                </GridItem>
              ) : type == "GIF" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #2c061f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <TextField
                        id={`gif-file-${index}`}
                        accept=".gif"
                        label="GIF"
                        type="file"
                        style={{ width: "100%", paddingTop: "15px" }}
                        onChange={(e) =>
                          this.onEditImageInputChange(
                            e,
                            index,
                            type,
                            this.state.activityData[index].content_id
                          )
                        }
                        placeholder="GIF Path"
                        helperText="Choose GIF file"
                      />
                      {this.state.uploadProgress.toString().split("#")[0] > 0 &&
                        this.state.uploadProgress.toString().split("#")[0] !=
                          100 &&
                        index ==
                          this.state.uploadProgress
                            .toString()
                            .split("#")[1] && (
                          <ProgressBar
                            value={
                              this.state.uploadProgress.toString().split("#")[0]
                            }
                          />
                        )}
                      {this.state.activityData[index].value.hasOwnProperty(
                        "filePath"
                      ) &&
                        this.state.activityData[index].value.filePath && (
                          <img
                            className={cls.preview}
                            src={
                              this.state.activityData[index].value.filePath
                                ? this.state.activityData[index].value.filePath
                                : this.state.activityData[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="GIF Description"
                      height="120"
                      name="gifDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      onChange={(e) =>
                        this.onChapterEditTextChange(
                          e,
                          index,
                          type,
                          this.state.activityData[index].content_id,
                          null,
                          this.state.activityData[index].color
                        )
                      }
                      onFocus={() => {}}
                      value={
                        this.state.activityData[index].value.hasOwnProperty(
                          "description"
                        )
                          ? this.state.activityData[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : type == "json" ? (
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "3px solid #2c061f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <TextField
                        id={`json-file-${index}`}
                        accept=".json"
                        label="JSON"
                        type="file"
                        style={{ width: "100%", paddingTop: "15px" }}
                        onChange={(e) =>
                          this.onEditImageInputChange(
                            e,
                            index,
                            type,
                            this.state.activityData[index].content_id
                          )
                        }
                        placeholder="Lottie Path"
                        helperText="Choose Lottie file"
                      />
                      {/* {this.state.uploadProgress
                                        .toString()
                                        .split("#")[0] > 0 &&
                                        this.state.uploadProgress
                                            .toString()
                                            .split("#")[0] != 100 &&
                                        index ==
                                        this.state.uploadProgress
                                            .toString()
                                            .split("#")[1] && (
                                            <ProgressBar
                                                value={
                                                    this.state.uploadProgress
                                                        .toString()
                                                        .split("#")[0]
                                                }
                                            />
                                        )} */}
                      {this.state.activityData[index].value.hasOwnProperty(
                        "filePath"
                      ) &&
                        this.state.activityData[index].value.filePath && (
                          <img
                            className={cls.preview}
                            src={
                              this.state.activityData[index].value.filePath
                                ? this.state.activityData[index].value.filePath
                                : this.state.activityData[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="Lottie Description"
                      height="120"
                      name="LottieDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      onChange={(e) =>
                        this.onChapterEditTextChange(
                          e,
                          index,
                          type,
                          this.state.activityData[index].content_id,
                          null,
                          this.state.activityData[index].color
                        )
                      }
                      onFocus={() => {}}
                      value={
                        this.state.activityData[index].value.hasOwnProperty(
                          "description"
                        )
                          ? this.state.activityData[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : null}
              <div className={cls.item_actions}>
                <IconButton
                  aria-label="delete"
                  style={{ marginTop: 10 }}
                  title="Delete element"
                  onClick={() => {
                    this.setState(
                      {
                        editactivityId: index,
                      },
                      () => {
                        this.setEditToDelete();
                      }
                    );
                  }}
                >
                  <DeleteIcon style={{ fill: "red" }} />
                </IconButton>
                <IconButton
                  title="Help"
                  size="small"
                  style={{ alignSelf: "center" }}
                  onClick={() => {}}
                >
                  <HelpOutlineIcon />
                </IconButton>

                <IconButton
                  title="Add element"
                  style={{ alignSelf: "center" }}
                  onClick={() => {
                    this.setState({
                      AddEditWidgetModal: true,
                      editactivityId: index,
                    });
                  }}
                >
                  <AddCircleOutlineIcon style={{ fill: "lightgreen" }} />
                </IconButton>
              </div>
            </div>
          ))}
          {/* {provided.placeholder} */}
        </div>
      </div>
    );
  }

  addData(type, e) {
    let uploadActivity = [...this.state.activityArray];

    uploadActivity.splice(this.state.activityId + 1, 0, {
      type,
      value: "",
      tag: e.currentTarget.name,
    });

    this.setState({
      activityId: 0,
      activityArray: uploadActivity,
    });
  }

  addEditData(type, e) {
    let uploadActivity = [...this.state.activityData];

    uploadActivity.splice(this.state.editactivityId + 1, 0, {
      type,
      value: "",
      tag: e.currentTarget.name,
    });
    console.log(uploadActivity, "uploadActivity");
    this.setState({
      activityId: 0,
      activityData: uploadActivity,
    });
  }

  validateFileInput = (file, type) => {
    let isFileValid = false;
    let message = "Please choose valid file type";
    let fileExt = file.name.split(".").pop();

    let fileSize = +(file.size / (1024 * 1024)).toFixed(2);
    if (fileSize < 7) {
      isFileValid = true;
    } else {
      message = "File is too large. Please upload file less than 7MB size";
      isFileValid = false;
      return {
        isFileValid: isFileValid,
        message: message,
      };
    }

    if (type == "IMG") {
      if (
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png"
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "PDF") {
      if (file.type == "application/pdf" && fileExt == "pdf") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "PPT") {
      if (
        (file.type == "application/vnd.ms-powerpoint" ||
          file.type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
        (fileExt == "ppt" || fileExt == "pptx")
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "GIF") {
      if (file.type == "image/gif" && fileExt == "gif") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "AUDIO") {
      if (
        (file.type == "audio/mp3" || file.type == "audio/mpeg") &&
        fileExt == "mp3"
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "json") {
      console.log(fileExt, "type");
      if (file.type == "application/json" && fileExt == "json") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    }

    return {
      isFileValid: isFileValid,
      message: message,
    };
  };

  onImageInputChange = async (e, index, type, content_id) => {
    let file = e.hasOwnProperty("currentTarget") ? e.currentTarget.files[0] : e;

    let fileExt = file.name.split(".").pop().toString().toLowerCase();
    let fileName = file.name.split(".")[0];
    const updatedFile = new File([file], `${fileName}.${fileExt}`, {
      type: file.type,
    });
    let formData = new FormData();
    formData.append("content_id", content_id);
    formData.append("filename", updatedFile);
    if (e.hasOwnProperty("persist")) e.persist();

    const { isFileValid, message } = this.validateFileInput(updatedFile, type);
    if (!isFileValid) {
      if (e.hasOwnProperty("currentTarget")) {
        e.target.value = null;
        e.currentTarget.value = null;
      }

      this.setState({
        message: message,
        isError: true,
      });
      return;
    }

    try {
      let mediaRes = await getSuperAdminService().uploadActivityMedia(
        formData,
        (progress) => {
          let current = (progress.loaded / progress.total) * 100;
          this.setState({
            uploadProgress: `${current}#${index}`,
          });
        }
      );
      mediaRes = mediaRes.data;
      if (mediaRes && mediaRes.status == 200) {
        let uploadData = [...this.state.activityArray];
        let value = mediaRes.response.url;
        if (type == "IMG" || type == "GIF" || type == "json") {
          value = {
            filePath: mediaRes.response.url,
            description: uploadData[index].value.description,
          };
        }

        let data = {
          type,
          content_id: Math.floor(Math.random() * 100 + 1),
          value: value,
          name: "",
        };
        uploadData[index] = data;
        this.setState({
          activityArray: uploadData,
        });
      } else {
        console.log("Something went wrong.......");
      }
    } catch (error) {
      e.currentTarget.value = null;
      this.setState({
        message: "Uploading failed",
        isError: true,
      });
    }
  };

  onEditImageInputChange = async (e, index, type, content_id) => {
    let file = e.hasOwnProperty("currentTarget") ? e.currentTarget.files[0] : e;

    let fileExt = file.name.split(".").pop().toString().toLowerCase();
    let fileName = file.name.split(".")[0];
    const updatedFile = new File([file], `${fileName}.${fileExt}`, {
      type: file.type,
    });
    console.log(updatedFile, "update");
    let formData = new FormData();
    formData.append("content_id", content_id);
    formData.append("filename", updatedFile);
    if (e.hasOwnProperty("persist")) e.persist();

    const { isFileValid, message } = this.validateFileInput(updatedFile, type);
    if (!isFileValid) {
      if (e.hasOwnProperty("currentTarget")) {
        e.target.value = null;
        e.currentTarget.value = null;
      }

      this.setState({
        message: message,
        isError: true,
      });
      return;
    }

    try {
      let mediaRes = await getSuperAdminService().uploadActivityMedia(
        formData,
        (progress) => {
          let current = (progress.loaded / progress.total) * 100;
          this.setState({
            uploadProgress: `${current}#${index}`,
          });
        }
      );
      mediaRes = mediaRes.data;
      if (mediaRes && mediaRes.status == 200) {
        let uploadData = [...this.state.activityData];
        let value = mediaRes.response.url;
        if (type == "IMG" || type == "GIF" || type == "json") {
          value = {
            filePath: mediaRes.response.url,
            description: uploadData[index].value.description,
          };
        }

        let data = {
          type,
          content_id: Math.floor(Math.random() * 100 + 1),
          value: value,
          name: "",
        };
        uploadData[index] = data;
        this.setState({
          activityData: uploadData,
        });
      } else {
        console.log("Something went wrong.......");
      }
    } catch (error) {
      e.currentTarget.value = null;
      this.setState({
        message: "Uploading failed",
        isError: true,
      });
    }
  };

  handleRecordedFile = (file) => {
    if (!file.url) {
      this.setState({
        message: "Please record the audio first",
        isError: true,
        openAudioRecordDialog: false,
      });
      return;
    }

    const { type, content_id, index } = this.state.audioContentExtra;
    var blobFile = new File([file.blob], `${new Date().getTime()}.mp3`, {
      type: "audio/mp3",
    });
    console.log("file", blobFile);
    this.setState(
      {
        openAudioRecordDialog: false,
      },
      () => {
        this.onImageInputChange(blobFile, index, type, content_id);
      }
    );
  };
}