import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { getSuperAdminService,getContentWriterService } from "./../../service/service";
import classes from "./Dashboard.module.css";

import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ContentWriterService from "service/contentWriterService";
import { options } from "colorette";
import InputLabel from "@material-ui/core/InputLabel";
import { Button } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { getNoOfChapters } from "service/WebSeparateService";
export default class Dashboard extends React.Component {
  state = {
    totalChapter: [],
    districtWiseChapterData: [],
    districtGraphData: [],
    districtCurrentIndex: 0,
    teacherWiseChapterData: [],
    teacherGraphData: [],

    districts: [],
    stdDistrict: null,
    stdClasses: [],
    stdClass: null,
    stdWiseGraph: null,

    chapterDetails: {},
    stdMedium:[],
    selectedMed:null,
    displayChapter : false,
    displayPublished : false,
    displayUnpublished : false
  };

  componentDidMount() {
    this.getTotalChapterChart();
    this.standardWiseTotalChapterChart();
    // this.teacherWiseChapterChart();
    this.getMedium();
    const districts = localStorage.getItem("districts");
    if (!districts) {
      this.getDistrictByBoard();
    } else {
      this.setState(
        {
          districts: JSON.parse(districts),
          stdDistrict: JSON.parse(districts)[0],
        },
        () => {
          this.getStdClasses();
        }
      );
    }
  }

  getDistrictByBoard = async () => {
    const param = {
      board_id: 2,
    };
    let districtRes = await getSuperAdminService().getAllDistrictFromBoard(
      param
    );
    districtRes = districtRes.data;
    if (districtRes && districtRes.status == 200) {
      localStorage.setItem("districts", JSON.stringify(districtRes.response));
      this.setState(
        {
          districts: districtRes.response,
          stdDistrict: districtRes.response[0],
        },
        () => {
          this.getStdClasses();
        }
      );
    }
  };

  getMedium= async ()=> {
    const param = {
      state_id: 1
    }
     let mediumdata = await getContentWriterService().getMediumbyState(param)
        mediumdata = mediumdata.data
     this.setState({
       stdMedium : mediumdata.response
     })
  }
  getStdClasses = async () => {
    const param = {
      district_id: this.state.stdDistrict.district_id,
    };
    let stdClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
      param
    );
    stdClassesRes = stdClassesRes.data;
    const param1 = {
      state_id: this.state.state_id
    }
    if (stdClassesRes && stdClassesRes.status == 200) {
      this.setState(
        {
          stdClasses: stdClassesRes.response,
          stdClass: stdClassesRes.response[0].class_id,
        },
        () => {
          this.getClassAnalytics();
        }
      );
    } else {
      this.setState({
        stdClasses: [],
      });
    }
  };

  getTotalChapterChart = async () => {
    //let totalChapterRes = await getSuperAdminService().getTotalNumberOfChapters();
    let totalChapterRes = await getNoOfChapters()
    // console.log(data,"data",totalChapterRes)
    totalChapterRes = totalChapterRes.data;
    console.log(totalChapterRes,"totalChapterRes")
    if (totalChapterRes ) {
      const data = [
        {
          name: "Published Chapter",
          y: totalChapterRes.published_chapter,
          color: "#f7ac32",
        },
        {
          name: "Un-published Chapter",
          y:
            totalChapterRes.total_chapter -
            totalChapterRes.published_chapter,
          color: "#01558b",
        },
      ];

      this.setState({
        totalChapter: {
          title: {
            text: `Total Chapters: ${totalChapterRes.total_chapter}`,
          },
          series: [
            {
              data,
            },
          ],
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            width: (window.innerWidth - 260 - 120) / 2,
          },
        },
        chapterDetails: {
          totalChapter: totalChapterRes.total_chapter,
          published: totalChapterRes.published_chapter,
          unPublished: totalChapterRes.total_chapter -
            totalChapterRes.published_chapter
        }
      });
    }
  };

  standardWiseTotalChapterChart = async () => {
    let totalClassChapterRes = await getSuperAdminService().getTotalNumberOfChapterByClass();
    totalClassChapterRes = totalClassChapterRes.data;

    console.log({
      totalClassChapterRes,
    });

    if (totalClassChapterRes && totalClassChapterRes.status == 200) {
      const filteredDistrict = totalClassChapterRes.response.filter((item) => {
        return (
          !item.district_name.includes("Demo") &&
          item.class_wise_data.length > 0
        );
      });

      console.log({
        filteredDistrict,
      });

      let data = filteredDistrict.map((item) => {
        const district_name = item.district_name;
        const published = [];
        const unpublished = [];
        const labels = [];
        item.class_wise_data.map((i) => {
          published.push(i.published_chapter_by_class);
          unpublished.push(
            i.total_chapter_by_class - i.published_chapter_by_class
          );
          labels.push(i.class_name);
        });

        return {
          name: district_name,
          labels: labels,
          series: [
            {
              type: "column",
              name: "Published",
              data: published,
              color: "#f7ac32",
            },
            {
              type: "column",
              name: "Unpublished",
              data: unpublished,
              color: "#01558b",
            },
          ],
        };
      });

      this.setState(
        {
          districtWiseChapterData: data,
        },
        () => {
          localStorage.setItem("graph", JSON.stringify(data));
          this.setDistrictGraphData(this.state.districtWiseChapterData, 0);
        }
      );
    }
  };

  teacherWiseChapterChart = async () => {
    let teacherWiseChapterRes = await getSuperAdminService().getTeacherWiseChapterData();
    teacherWiseChapterRes = teacherWiseChapterRes.data;
    if (teacherWiseChapterRes && teacherWiseChapterRes.status == 200) {
      const labels = [];
      const published = [];
      const unpublished = [];
      teacherWiseChapterRes.response.map((teacher) => {
        labels.push(teacher.full_name);
        published.push(teacher.published_chapter);
        unpublished.push(teacher.total_chapter - teacher.published_chapter);
      });

      const data = {
        labels: labels,
        series: [
          {
            type: "column",
            name: "Published",
            data: published,
            color: "#f7ac32",
          },
          {
            type: "column",
            name: "Unpublished",
            data: unpublished,
            color: "#01558b",
          },
        ],
      };

      this.setState({
        teacherGraphData: {
          title: {
            text: "Teacher wise data",
          },
          xAxis: {
            categories: data.labels,
          },
          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          series: data.series,
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 2,
          },
        },
      });
    }
  };

  setDistrictGraphData = (d, index) => {
    const data = JSON.parse(localStorage.getItem("graph"));
    const series = [...[...data][index].series];
    const labels = [...[...data][index].labels];

    this.setState({
      districtGraphData: {
        ...this.state.districtGraphData,
        xAxis: {
          ...this.state.districtGraphData.xAxis,
          categories: labels,
        },
        series: series,
      },
    });

    this.setState({
      districtGraphData: {
        title: {
          text: " ",
        },
        xAxis: {
          categories: labels,
        },
        labels: {
          items: [
            {
              html: "",
              style: {
                left: "50px",
                top: "18px",
              },
            },
          ],
        },
        series: series,
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          width: (window.innerWidth - 260 - 120) / 2,
        },
      },
    });
  };

  handleDistrictGraph = (index) => {
    this.setDistrictGraphData(this.state.districtWiseChapterData, index);
    this.setState(
      {
        districtCurrentIndex: index,
      },
      () => {
        this.setDistrictGraphData(this.state.districtWiseChapterData, index);
      }
    );
  };

  handleStdDistrict = (index) => {
    const districts = [...this.state.districts];
    this.setState(
      {
        stdDistrict: districts[index],
      },
      () => {
        this.getStdClasses();
      }
    );
  };

  getClassAnalytics = async () => {
    const stdClasses = [...this.state.stdClasses];
    let currentSelectedClassIndex = stdClasses.findIndex(
      (cls) => cls.class_id == this.state.stdClass
    );
    if (currentSelectedClassIndex > -1) {
      const stdClass = stdClasses[currentSelectedClassIndex];
      const param = {
        class_id: stdClass.class_id,
        class_name: stdClass.class_name,
      };
      let stdWiseRes = await getSuperAdminService().getStdWiseAnalytics(param);
      stdWiseRes = stdWiseRes.data;
      const labels = [];
      const published = [];
      const unpublished = [];
      let series = [];

      if (stdWiseRes && stdWiseRes.status == 200) {
        const dataForFilter = stdWiseRes.response.subject_data;
        let data;
        if(this.state.selectedMed){
           data = dataForFilter.filter(item => item.medium_id == this.state.selectedMed)
        }
        else{
           data = dataForFilter
        }
        
        data.map((d) => {
          const subject_name =
            `${d.subject_name}`.toString().charAt(0).toUpperCase() +
            `${d.subject_name}`.slice(1);
          labels.push(subject_name);
          published.push(d.published_chapter);
          unpublished.push(d.total_chapters - d.published_chapter);
        });

        series = [
          {
            name: "Published",
            data: published,
            color: "#f7ac32",
          },
          {
            name: "Unpublished",
            data: unpublished,
            color: "#01558b",
          },
        ];
      }

      this.setState({
        stdWiseGraph: {
          title: {
            text: "",
          },
          xAxis: {
            categories: labels,
            crosshair: true,
          },
          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          series: series,
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 2,
            type: "column",
          },
        },
      });
    }
  };

  roundedBtnCls = (index) => {
    console.log(index, "index")
    return index == 0 ? classes.leftRoundedBtn : classes.rightRoundedBtn;
  }

  renderTest() {
    const {
      totalChapter,
      districtWiseChapterData,
      districtGraphData,
      districtCurrentIndex,
      teacherGraphData,
      districts,
      stdDistrict,
      stdClasses,
      stdWiseGraph,
      stdMedium
    } = this.state;
    return (
      <div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            className={[classes.totalChapter, classes.graphbox]
              .toString()
              .split(",")
              .join(" ")}
          >
            <HighchartsReact
              className={classes.totalChapter}
              highcharts={Highcharts}
              options={totalChapter}
            />
          </div>
          <div className={classes.graphbox}>
            <div className={classes.district_container}>
              {districtWiseChapterData.map((district, i) => {
                return (
                  <button
                    onClick={() => this.handleDistrictGraph(i)}
                    className={classes.dist_btn}
                    style={{
                      backgroundColor:
                        districtCurrentIndex == i ? "#0051B4" : "",
                      color: districtCurrentIndex == i ? "#FFF" : "#000",
                    }}
                    key={i}
                  >
                    {district.name}
                  </button>
                );
              })}
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={districtGraphData}
            ></HighchartsReact>
          </div>
          <div className={classes.graphbox}>
            <div className={classes.subjectBoxCtrl}>
              <div className={classes.district_container}>
                {districts.map((district, i) => {
                  return (
                    <button
                      onClick={() => this.handleStdDistrict(i)}
                      className={classes.dist_btn}
                      style={{
                        backgroundColor:
                          stdDistrict &&
                            stdDistrict.district_id == district.district_id
                            ? "#0051B4"
                            : "",
                        color:
                          stdDistrict &&
                            stdDistrict.district_id == district.district_id
                            ? "#FFF"
                            : "#000",
                      }}
                      key={i}
                    >
                      {district.district_name}
                    </button>
                  );
                })}
              </div>
              <FormControl
                className={[classes.formControl, classes.dropdown]
                  .toString()
                  .split(",")
                  .join(" ")}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.stdClass}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    this.setState(
                      {
                        stdClass: e.target.value,
                      },
                      () => {
                        this.getClassAnalytics();
                      }
                    );
                  }}
                >
                  {stdClasses.map((stdClass) => {
                    return (
                      <MenuItem value={stdClass.class_id} key={stdClass.class_id}>
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={stdWiseGraph}
            ></HighchartsReact>
          </div>
          {/* <div className={classes.graphbox} style={{ alignSelf: "flex-end" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={teacherGraphData}
            ></HighchartsReact>
          </div> */}
        </div>
      </div>
    );
  }

  render() {
    const {
      totalChapter,
      districtWiseChapterData,
      districtGraphData,
      districtCurrentIndex,
      teacherGraphData,
      districts,
      stdDistrict,
      stdClasses,
      stdWiseGraph,
    } = this.state;
    return (
      <div className="col-12">
        <ReactTooltip id="registerTip" place="bottom" effect="solid" style={{background:"blue"}}>
                Click to see details
                 </ReactTooltip>
        <div className={`row ${classes.headerText}`}>
          Overview
        </div>
        <div className="row p-3 mb-4" style={{ justifyContent: "space-between" }}>
          <div className="col-3"
           data-tip data-for="registerTip"
           style={{cursor:"pointer"}}
           onClick={()=>{
             this.setState({
              displayChapter :true
             })
           }} >
            <div className={`${classes.chpterStatBox} row`}>
              <div className="row">
                <Button 
                data-tip data-for="registerTip"
                onClick={()=>{
                  this.setState({
                    displayChapter :true
                  })
                }}
                style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                  Total Chapters
                </Button>
              </div>
              <div className="row">
                { this.state.displayChapter ? <div className={`col-6 ${classes.statText}`}>
                  {this.state.chapterDetails.totalChapter}
                </div> : ""}
                <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton>
                    <FileCopyIcon className={classes.copySvg} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <div
          data-tip data-for="registerTip"
          style={{cursor:"pointer"}}
          onClick={()=>{
            this.setState({
              displayPublished :true
            })
          }} className="col-3">
            <div className={`${classes.chpterStatBox} row`}>
              <div className="row">
                <Button 
                data-tip data-for="registerTip"
                onClick={()=>{
                  this.setState({
                    displayPublished :true
                  })
                }}
                style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                  Published Chapters
                </Button>
              </div>
              <div className="row">
                {this.state.displayPublished ? <div className={`col-6 ${classes.statText}`}>
                  {this.state.chapterDetails.published}
                </div> : "" }
                <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton>
                    <FileCopyIcon className={classes.copySvg} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <div className="col-3"
           data-tip data-for="registerTip"
           style={{cursor:"pointer"}}
           onClick={()=>{
             this.setState({
              displayUnpublished :true
             })
           }} >
            <div className={`${classes.chpterStatBox} row`}>
              <div className="row">
                <Button
                data-tip data-for="registerTip"
                onClick={()=>{
                  this.setState({
                    displayUnpublished :true
                  })
                }} style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                  Unpublished Chapters
                </Button>
                
              </div>
              <div className="row">
                {this.state.displayUnpublished ? <div className={`col-6 ${classes.statText}`}>
                  {this.state.chapterDetails.unPublished}
                </div> : ""}
                <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton>
                    <FileCopyIcon className={classes.copySvg} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">

          <div className={`col-6 ${classes.chart} ${classes.graphbox}`}>
            <div className={`row ${classes.chartHead}`}>
              <div className="col-6">
                Class wise data
              </div>
              <div className="col-6">
                <div className={classes.district_container}>
                  {districtWiseChapterData.map((district, i) => {
                    return (
                      <button
                        onClick={() => this.handleDistrictGraph(i)}
                        className={`${classes.dist_btn} ${this.roundedBtnCls(i)}`}
                        style={{
                          height:"5em",
                          width:"12em",
                          border:"none",
                          borderRadius:"5px",
                          backgroundColor:
                            districtCurrentIndex == i ? "#0F9FCC" : "",
                          color: districtCurrentIndex == i ? "#FFF" : "#000",
                        }}
                        key={i}
                      >
                        <div className={classes.btnText}>
                          {district.name}
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={districtGraphData}
            ></HighchartsReact>
          </div>

          <div className={`col-6 ${classes.chart} ${classes.graphbox}`}>
            <div className={`row ${classes.chartHead}`}>
              <div className="col-5">
                Subject wise data
              </div>
              <div className="col-4">
                <div className={classes.subjectBoxCtrl}>
                  <div style={{marginRight:"8em"}} className={classes.district_container}>
                    {districts.map((district, i) => {
                      return (
                        <button
                          onClick={() => this.handleStdDistrict(i)}
                          className={`${classes.dist_btn} ${this.roundedBtnCls(i)}`}
                          style={{
                            height:"5em",
                            width:"8em",
                            border:"none",
                            borderRadius:"5px",
                            backgroundColor:
                              stdDistrict &&
                                stdDistrict.district_id == district.district_id
                                ? "#0F9FCC"
                                : "",
                            color:
                              stdDistrict &&
                                stdDistrict.district_id == district.district_id
                                ? "#FFF"
                                : "#000",
                          }}
                          key={i}
                        >
                          <div className={classes.btnText}>
                            {district.district_name}
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-3" style={{marginRight:"0.5em"}}>
                {/* <FormControl
                  className={classes.formControl}
                > */}
                <InputLabel id="demo-simple-select-label">filter</InputLabel>
                <Select 
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                  value={this.state.selectedMed}
                  InputLabelProps={{
                    shrink: true,
                  }}
                   onChange={(e) => {
                      this.setState(
                        {
                          selectedMed : e.target.value
                        },
                        ()=>{
                          this.getClassAnalytics()
                        })
                      }
                    }
                  >
                      {
                         this.state.stdMedium.map((medRes)=>{
                          return (
                            <MenuItem value={medRes.medium_id}  key={medRes.medium_id}>
                              {medRes.medium_name}
                            </MenuItem>
                          );
                        })
                      }
                    </Select>
          
                  <Select
                  style={{marginLeft:"1em"}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.stdClass}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      this.setState(
                        {
                          stdClass: e.target.value,
                        },
                        () => {
                          this.getClassAnalytics();
                        }
                      );
                    }}
                  >
                    {stdClasses.map((stdClass) => {
                      return (
                        <MenuItem value={stdClass.class_id} key={stdClass.class_id}>
                          {stdClass.class_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                {/* </FormControl> */}
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={stdWiseGraph}
            ></HighchartsReact>
          </div>
        </div>
      </div>
    );
  }

}
