import React from "react";
import ReactDOM from "react-dom";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import 'bootstrap/dist/css/bootstrap.css';
import App from "./App";
import store from "views/ChapterPreview/Quiz/store";
import { Provider } from "react-redux";
ReactDOM.render(
    <Provider store={store}>
    <App />
  </Provider>, document.getElementById("root"));
