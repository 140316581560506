import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getSuperAdminService } from "../../service/service";
import * as constants from "./../../common/constants";
import Snackbar from "./../../components/Snackbar/Snackbar";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  cardFooter: {
    justifyContent: "flex-end",
    width: "96%",
  },
};

export default class UserDetail extends React.Component {
  state = {
    userDetail: {
      first_name: "",
      middle_name: "",
      last_name: "",
      mobile: "",
      email: "",
      user_type: "",
      assigned_principal: "",
    },
    adminList: [],
    state: null,
    board: null,
    district: null,
    className: [],
    classList: [],
    selectedClass: [],
    stateList: [],
    boardList: [],
    districtList: [],
    adminList: [],
    stats: {},
    assignedClasses: [],
    validations: {
      first_name: {
        valid: true,
        touched: false,
      },
      middle_name: {
        valid: true,
        touched: false,
      },
      last_name: {
        valid: true,
        touched: false,
      },
      email: {
        valid: true,
        touched: false,
      },
      mobile: {
        valid: true,
        touched: false,
      },
    },
    message: "",
    error: false,
    HMExtraDetails: null,
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const data = {
      user_id: this.props.history.location.state.data.user_id,
    };

    const adminCWDetail = await getSuperAdminService().getAdminCWDetail(data);
    const states = await getSuperAdminService().getAllState();
    const allAdmins = await getSuperAdminService().getAllAdminList();

    Promise.all([adminCWDetail, states, allAdmins]).then((results) => {
      const adminCW = results[0].data.response;
      const states = results[1].data.response;
      const admins = results[2].data.response;
      this.setState(
        {
          userDetail: adminCW.profile,
          HMExtraDetails: adminCW,
          stateList: states,
          adminList: admins,
          stats: {
            chapters_count: adminCW.chapters_count,
            in_progress_count: adminCW.in_progress_count,
            review_count: adminCW.review_count,
            publish_count: adminCW.publish_count,
          },
          assignedClasses: adminCW.assignedClasses,
          state:
            adminCW.assignedClasses.length > 0
              ? adminCW.assignedClasses[0].state_id
              : null,
        },
        async () => {
          let data = { state_id: this.state.state };
          let res = await getSuperAdminService().getAllBoardFromState(data);
          res = res.data;
          if (res && res.status == 200) {
            this.setState(
              {
                boardList: res.response,
                board:
                  adminCW.assignedClasses.length > 0
                    ? adminCW.assignedClasses[0].board_id
                    : null,
              },
              async () => {
                let data = { board_id: this.state.board };
                let res = await getSuperAdminService().getAllDistrictFromBoard(
                  data
                );
                res = res.data;
                if (res && res.status == 200) {
                  this.setState(
                    {
                      districtList: res.response,
                      district:
                        adminCW.assignedClasses.length > 0
                          ? adminCW.assignedClasses[0].district_id
                          : null,
                    },
                    async () => {
                      let data = { district_id: this.state.district };
                      let res = await getSuperAdminService().getAllClassesFromDistrict(
                        data
                      );
                      res = res.data;
                      if (res && res.status == 200) {
                        this.setState({
                          classList: res.response,
                          className: this.state.assignedClasses.map(
                            (c) => c.class_id
                          ),
                        });
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );
    });
  }

  onChangeText(e) {
    let { userDetail } = this.state;
    let updatedValidations = { ...this.state.validations };
    updatedValidations[e.target.name].touched = true;
    updatedValidations[e.target.name].valid = e.target.value ? true : false;
    userDetail = { ...userDetail, [e.target.name]: e.target.value };
    this.setState({
      userDetail,
      validations: updatedValidations,
    });
  }

  async updateProfile() {
    let {
      email,
      mobile,
      user_type,
      first_name,
      middle_name,
      last_name,
      admin_id,
      user_id,
      assigned_principal,
    } = this.state.userDetail;

    const param = {
      user_id: user_id,
      admin_id: admin_id,
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      user_type: user_type,
      email: email,
      mobile: mobile,
      assigned_principal: assigned_principal,
    };
    console.log(param, "param");
    try {
      let res = await getSuperAdminService().UpdateAdminCWDetail(param);
      res = res.data;
      if (res && res.status == 200) {
        const classesAssigned = this.state.className.map((cls) => {
          return {
            state_id: this.state.state,
            board_id: this.state.board,
            district_id: this.state.district,
            class_id: cls,
          };
        });
        const param = {
          user_id: user_id,
          class_details: classesAssigned,
        };
        getSuperAdminService()
          .assignClassesToUser(param)
          .then((res) => {
            console.log(res, "res");
            this.setState({
              message: "User updated successfully",
              error: false,
            });
          });
      } else {
        console.log(res, "res");
        this.setState({
          message: res.message,
          error: true,
        });
      }
    } catch (error) {
      this.setState({
        message: "Something has went wrong",
        error: true,
      });
    }
  }

  renderStateMenu(type) {
    let { stateList, boardList, districtList, classList } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "board"
        ? boardList
        : type == "class"
        ? classList
        : districtList;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "board"
          ? state.board_id
          : type == "class"
          ? state.class_id
          : state.district_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "board"
          ? state.board_name
          : type == "class"
          ? state.class_name
          : state.district_name;
      return (
        <MenuItem key={index + "state"} value={value}>
          {name}
        </MenuItem>
      );
    });
  }

  renderAdminList() {
    let { adminList } = this.state;
    return adminList.map((admin, index) => {
      return (
        <MenuItem key={"admin" + index} value={admin.user_id}>
          {admin.fullname}
        </MenuItem>
      );
    });
  }

  getBoardByState = (e) => {
    this.setState({ state: e.target.value }, async () => {
      let data = { state_id: this.state.state };
      let res = await getSuperAdminService().getAllBoardFromState(data);
      res = res.data;
      if (res && res.status == 200) {
        this.setState({ boardList: res.response });
      }
    });
  };

  getDistrictByBoard = (e) => {
    this.setState({ board: e.target.value }, async () => {
      let data = { board_id: this.state.board };
      let res = await getSuperAdminService().getAllDistrictFromBoard(data);
      res = res.data;
      if (res && res.status == 200) {
        this.setState({ districtList: res.response });
      }
    });
  };

  getClassesByDistrict = (e) => {
    this.setState({ district: e.target.value }, async () => {
      let data = { district_id: this.state.district };
      let res = await getSuperAdminService().getAllClassesFromDistrict(data);
      res = res.data;
      if (res && res.status == 200) {
        this.setState({ classList: res.response });
      }
    });
  };

  handleClassChange = (e) => {
    let updatedSelectedClasses = e.target.value.map((id) => {
      let itemIndex = this.state.classList.findIndex((i) => i.class_id === id);
      if (itemIndex > -1) {
        return this.state.classList[itemIndex];
      }
    });
    this.setState({
      className: e.target.value,
      selectedClass: updatedSelectedClasses,
    });
  };

  isFormValid = () => {
    let isFormValid = true;
    for (let field in this.state.validations) {
      if (!this.state.validations[field].valid) {
        isFormValid = false;
      }
    }
    return !isFormValid;
  };

  render() {
    const { validations, userDetail, message, error } = this.state;
    return (
      <div style={{ justifyContent: "center", alignItems: "center" }}>
        <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() => this.setState({ message: "" })}
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={styles.cardTitleWhite}>Profile</h4>
              </CardHeader>
              {console.log(
                this.props.location.state.data.user_type == "HEADMASTER",
                "props"
              )}
              <CardBody>
                {console.log()}
                <div style={styles.inputWrapper}>
                  {this.props.location.state.data.user_type ==
                  "HEADMASTER" ? null : (
                    <FormControl
                      style={styles.dropdown}
                      disabled={this.state.state}
                    >
                      <span id="demo-simple-select-label">State</span>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.state}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={(e) => this.getBoardByState(e)}
                      >
                        {this.renderStateMenu("state")}
                      </Select>
                    </FormControl>
                  )}
                  {this.props.location.state.data.user_type ==
                  "HEADMASTER" ? null : (
                    <FormControl
                      style={styles.dropdown}
                      disabled={this.state.board}
                    >
                      <span focused id="demo-simple-select-label">
                        Board
                      </span>
                      <Select
                        label="Board"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        labelId="demo-controlled-open-select-label1"
                        id="demo-controlled-open-select1"
                        value={this.state.board}
                        onChange={(e) => this.getDistrictByBoard(e)}
                      >
                        {this.renderStateMenu("board")}
                      </Select>
                    </FormControl>
                  )}

                  {this.props.location.state.data.user_type ==
                  "HEADMASTER" ? null : (
                    <FormControl
                      style={styles.dropdown}
                      disabled={this.state.district}
                    >
                      <span id="demo-simple-select-label">District</span>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.district}
                        onChange={(e) => this.getClassesByDistrict(e)}
                      >
                        {this.renderStateMenu("district")}
                      </Select>
                    </FormControl>
                  )}

                  {this.props.location.state.data.user_type ==
                  "HEADMASTER" ? null : (
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Class
                      </InputLabel>
                      <Select
                        multiple
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.className}
                        onChange={(e) => this.handleClassChange(e)}
                      >
                        {this.renderStateMenu("class")}
                      </Select>
                    </FormControl>
                  )}

                  <TextField
                    style={styles.dropdown}
                    type="text"
                    label="First Name"
                    id="first_name"
                    name="first_name"
                    value={this.state.userDetail.first_name}
                    onChange={this.onChangeText.bind(this)}
                    error={
                      !validations.first_name.valid &&
                      validations.first_name.touched
                    }
                    helperText={
                      !validations.first_name.valid &&
                      validations.first_name.touched
                        ? "First name is required"
                        : ""
                    }
                    onClick={(e) => {
                      let updatedValidations = { ...validations };
                      updatedValidations.first_name.touched = true;
                      updatedValidations.first_name.valid = userDetail.first_name
                        ? true
                        : false;
                      this.setState({
                        validations: updatedValidations,
                      });
                    }}
                  />
                  <TextField
                    style={styles.dropdown}
                    type="text"
                    label="Middle Name"
                    id="middle_name"
                    name="middle_name"
                    value={this.state.userDetail.middle_name}
                    onChange={this.onChangeText.bind(this)}
                    error={
                      !validations.middle_name.valid &&
                      validations.middle_name.touched
                    }
                    helperText={
                      !validations.middle_name.valid &&
                      validations.middle_name.touched
                        ? "Middle name is required"
                        : ""
                    }
                    onClick={(e) => {
                      let updatedValidations = { ...validations };
                      updatedValidations.middle_name.touched = true;
                      updatedValidations.middle_name.valid = userDetail.middle_name
                        ? true
                        : false;
                      this.setState({
                        validations: updatedValidations,
                      });
                    }}
                  />
                  <TextField
                    style={styles.dropdown}
                    type="text"
                    label="Last Name"
                    id="last_name"
                    name="last_name"
                    value={this.state.userDetail.last_name}
                    onChange={this.onChangeText.bind(this)}
                    error={
                      !validations.last_name.valid &&
                      validations.last_name.touched
                    }
                    helperText={
                      !validations.last_name.valid &&
                      validations.last_name.touched
                        ? "Last name is required"
                        : ""
                    }
                    onClick={(e) => {
                      let updatedValidations = { ...validations };
                      updatedValidations.last_name.touched = true;
                      updatedValidations.last_name.valid = userDetail.last_name
                        ? true
                        : false;
                      this.setState({
                        validations: updatedValidations,
                      });
                    }}
                  />
                  <TextField
                    style={styles.dropdown}
                    type="text"
                    label="Email"
                    id="email"
                    name="email"
                    // disabled
                    value={this.state.userDetail.email}
                    onChange={this.onChangeText.bind(this)}
                    // error={
                    //   !validations.email.valid &&
                    //   validations.email.touched
                    // }
                    // helperText={
                    //   !validations.email.valid &&
                    //   validations.email.touched
                    //     ? "email is required"
                    //     : ""
                    // }
                    // onClick={(e) => {
                    //   let updatedValidations = { ...validations };
                    //   updatedValidations.email.touched = true;
                    //   updatedValidations.email.valid = userDetail.first_name
                    //     ? true
                    //     : false;
                    //   this.setState({
                    //     validations: updatedValidations,
                    //   });
                    // }}
                  />
                  <TextField
                    style={styles.dropdown}
                    type="Number"
                    label="Mobile Number"
                    id="mobile"
                    name="mobile"
                    value={this.state.userDetail.mobile}
                    onChange={this.onChangeText.bind(this)}
                  />

                  {this.props.location.state.data.user_type ==
                  "HEADMASTER" ? null : (
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        User Type
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.userDetail.user_type}
                        onChange={(e) => {
                          let { userDetail } = this.state;
                          userDetail = {
                            ...userDetail,
                            user_type: e.target.value,
                          };
                          this.setState({ userDetail });
                        }}
                      >
                        <MenuItem key={1} value={constants.USER_TYPE.ADMIN}>
                          {"ADMIN"}
                        </MenuItem>
                        <MenuItem
                          key={2}
                          value={constants.USER_TYPE.CONTENT_WRITER}
                        >
                          {"CONTENT WRITER"}
                        </MenuItem>
                        <MenuItem
                          key={3}
                          value={constants.USER_TYPE.SUPER_ADMIN}
                        >
                          {"SUPER ADMIN"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}

                  {this.props.location.state.data.user_type == "HEADMASTER" ? (
                    <>
                      {console.log(
                        this.state.HMExtraDetails,
                        "this.state.userDetail"
                      )}
                      <TextField
                        style={styles.dropdown}
                        // label="School Name"
                        id="mobile"
                        // name="mobile"
                        value={this.state.HMExtraDetails?.school_name}
                        disabled
                        // onChange={this.onChangeText.bind(this)}
                      />
                      <TextField
                        style={styles.dropdown}
                        // label="U_Dise Number"

                        value={this.state.HMExtraDetails?.u_dise}
                        disabled
                      />
                    </>
                  ) : (
                    <FormControl style={styles.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Assign Admin
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.userDetail.assigned_principal}
                        onChange={(e) => {
                          let { userDetail } = this.state;
                          userDetail = {
                            ...userDetail,
                            assigned_principal: e.target.value,
                          };
                          this.setState({ userDetail });
                        }}
                      >
                        {this.renderAdminList()}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </CardBody>
              <CardFooter style={styles.cardFooter}>
                <Button
                  color="primary"
                  onClick={this.updateProfile.bind(this)}
                  disabled={this.isFormValid()}
                >
                  Update Profile
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardHeader color="primary">
                <h4 className={styles.cardTitleWhite}>Statistics</h4>
              </CardHeader>
              <CardBody profile>
                <GridItem xs={12} sm={12} md={14} style={{ margin: 15 }}>
                  <div>{`Chapters Count:`}</div>
                  <span>{this.state.stats.chapters_count}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={14} style={{ margin: 15 }}>
                  <div>{`Chapters InProgress Count:`}</div>
                  <span>{this.state.stats.in_progress_count}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={14} style={{ margin: 15 }}>
                  <div>{`Chapters Review Count:`}</div>
                  <span>{this.state.stats.review_count}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={14} style={{ margin: 15 }}>
                  <div>{`Chapters Publish Count:`}</div>
                  <span>{this.state.stats.publish_count}</span>
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
