import React, { useEffect, useState } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { RiTimeLine } from "react-icons/ri";
import { alpha } from "@material-ui/core/styles";

const DateTimeComponent = ({
  selectedStartDate,
  selectedEndDate,
  handleStartDateChangeNew,
  handleEndDateChangeNew,
  dateErrorMsg,
  disabled,
  status,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="row challengeListFilterDiv pt-5 pb-5">
        <div className="">
          <h5 className="color8A">Select validity</h5>
        </div>
        <div className="col-4 inputWrapper">
          <div className="dateTimeRangeInputContainer width28">
            <div className="me-2">
              {status === "PUBLISHED" || status === "ACTIVE" ? (
                <KeyboardDatePicker
                  disabled={disabled}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Start date*"
                  format="dd/MM/yyyy"
                  value={selectedStartDate}
                  onChange={handleStartDateChangeNew}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              ) : (
                <KeyboardDatePicker
                  disabled={disabled}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Start date*"
                  format="dd/MM/yyyy"
                  value={selectedStartDate}
                  onChange={handleStartDateChangeNew}
                  minDate={new Date()}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              )}
            </div>

            <div className="timePicker">
              <KeyboardTimePicker
                disabled={disabled}
                margin="normal"
                id="time-picker"
                label="Start time*"
                value={selectedStartDate}
                onChange={handleStartDateChangeNew}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                keyboardIcon={<RiTimeLine />}
              />
            </div>
          </div>
        </div>
        <div className="col-4 inputWrapper">
          <div className="dateTimeRangeInputContainer width28">
            <div className="me-2">
              <KeyboardDatePicker
                disabled={disabled}
                margin="normal"
                id="date-picker-dialog"
                label="End date*"
                format="dd/MM/yyyy"
                value={selectedEndDate}
                onChange={handleEndDateChangeNew}
                minDate={selectedStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>

            <div className="timePicker">
              <KeyboardTimePicker
                disabled={disabled}
                margin="normal"
                id="time-picker"
                label="End time*"
                value={selectedEndDate}
                onChange={handleEndDateChangeNew}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                keyboardIcon={<RiTimeLine />}
              />
            </div>
          </div>
        </div>
        <div className="mx-1">
          {dateErrorMsg && <p className="errorText">{dateErrorMsg}</p>}
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DateTimeComponent;
