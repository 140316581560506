import React from "react";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getSuperAdminService } from "service/service";
import { FormHelperText, MenuItem } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import http from "common/axios";
import { getContentWriterService } from "service/service";
import MaterialTable from "material-table";
import Modal from "@material-ui/core/Modal";
import CardHeader from "components/Card/CardHeader";
import ReactStars from "react-rating-stars-component";
import Group from "@material-ui/icons/Group";
import {
  chapterPreview,
} from "service/WebSeparateService";
import {
  Avatar,
  Divider,
  FormControlLabel,
  List,
  Switch,
} from "@material-ui/core";
import ChapterComments from "views/ChapterPreview/ChapterComments/chapterComments";

const styles = {
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
    padding: "8px 8px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

export default class ChapterAnyalytics extends React.Component {
  state = {
    baseAnalytics: {},
    studentList: [],
    column: [],
    queryObject: { searchText: "" },
    checked: false,
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    mediumList: [],
    subjectList: [],
    userList: [],
    state: {
      helperText: null,
      value: "",
    },
    board: "",
    district: {
      helperText: null,
      value: "",
    },
    class: "",
    medium: "",
    subject: "",
    teacher: "",
    user: "",
    currentTabIndex: 0,
    stdWiseGraph: null,
    openDetailDialog: false,
    studentDetail: {},
    subjectWiseGraph: null,
    loading: false,
    districts: [],
    blocks: [],
    block: {
      helperText: null,
      value: "",
    },
    schools: [],
    school: "",
    classStandrd: "",
    AllmediumList: [],
    selectedMedium: null,
    selectedStandard: "",
    classList: [],
    selectSubject: "",
    subList: [],
    openDetailDialog: false,
    MediumList: [],
    mediumSelected: "",

    // columns: [
    //   { field: "id", headerName: "ID", width: 70 },
    //   { field: "firstName", headerName: "First name", width: 130 },
    //   { field: "lastName", headerName: "Last name", width: 130 },
    //   {
    //     field: "age",
    //     headerName: "Age",
    //     type: "number",
    //     width: 90,
    //   },
    //   {
    //     field: "fullName",
    //     headerName: "Full name",
    //     description: "This column has a value getter and is not sortable.",
    //     sortable: false,
    //     width: 160,
    //   },
    // ],
    rows: [],
    rowsClick: [],
    tableShow: false,
    comments:{},
    pageNumber:1,
    recordPerPage:5,
    chapterID:null
  };
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    let { chapter } = this.state;
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      chapter = { ...chapter, state_id: stateRes.response[0].state_id };
      const stateList = stateRes.response;
      this.setState(
        {
          stateList: stateList,
          chapter,
          // state: {
          //   value:
          //     stateList.length > 0
          //       ? stateList[0].state_id
          //       : null,
          //   helperText: null,
          // },
          stateForGraph: stateList.length > 0 ? stateList[0].state_id : null,
        },
        () => {
          // this.getBoardByState();
          if (this.state.currentTabIndex == 0) {
            this.getDistrictsByState(this.state.stateForGraph);
          }
        }
      );
    } else {
      this.setState({
        stateList: [],
        state: {
          helperText: null,
          value: null,
        },
      });
    }
  }

  renderStateMenu(type) {
    let { stateList, districts, blocks, schools, classList } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "district"
        ? districts
        : type == "class"
        ? classList
        : type == "block"
        ? blocks
        : schools;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "district"
          ? state.student_district_id
          : type == "class"
          ? classList
          : type == "block"
          ? state.student_block_id
          : state.school_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "district"
          ? state.district_name
          : type == "block"
          ? state.block_name
          : type == "class"
          ? classList
          : type == "school"
          ? state.school_name
          : "";
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }

  getDistrictsByState = (state_id) => {
    getSuperAdminService()
      .getStudentDistrict({
        student_state_id: state_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              districts: response.response,
              // district: {
              //   value:
              //     response.response.length > 0
              //       ? response.response[0].student_district_id
              //       : null,
              //   helperText: null,
              // },
              // districtForGraph: response.response.length > 0
              //   ? response.response[0].student_district_id
              //   : null,
            },
            () => {
              if (this.state.currentTabIndex == 0) {
                if (this.state.districtForGraph) {
                  //   this.getStudentDataForChart();
                }
                //this.getStudentBlock(this.state.districtForGraph)
              }
            }
          );
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
          });
        }
      });
  };

  getStudentBlock = (district_id) => {
    getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id ? district_id : 1,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
              // block: {
              //   value:
              //     response.response.length > 0
              //       ? response.response[0].student_block_id
              //       : null,
              //   helperText: null,
              // },
              schools: [],
              // blockForGraph: response.response.length > 0
              //   ? response.response[0].student_block_id
              //   : null,
            },
            () => {
              if (this.state.currentTabIndex == 0) {
                // this.getStudentDataForChart();
              }
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = (block, searchtext) => {
    const param = {
      student_block_id: block,
      searchText: searchtext ? searchtext : "",
    };
    getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        result = result.data;
        console.log(result, "school");
        if (result && result.status == 200) {
          this.setState({
            schools: result.response,
            filteredSchools: result.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  };

  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };

  onChangeHandle = (val, value) => {
    console.log(val, value, "val from onChangeHandle");
    this.setState({
      school: val,
    });
  };

  getAllstandards = async (value) => {
    let apidata3 = {
      district_id: this.state.selectedMedium,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };

  getAllSubject = () => {
    let param = {
      class_id: this.state.selectedStandard,
      medium_id: this.state.mediumSelected,
    };

    getContentWriterService()
      .getSubjectByClassMed(param)
      .then((res) => {
        this.setState({
          subList: res.data.response,
        });
      });
  };

  getMedium = async () => {
    let apidata = {
      board_id: 2,
    };
    let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
      apidata
    );
    console.log(CWDistrictRes.data.response);
    this.setState({
      AllmediumList: CWDistrictRes.data.response,
    });
  };
  getAnalytics = (id) => {
    console.log(this.state.state, this.state.district, this.state.block);
    http
      .get(`chapter_part_wise_analysis/`, {
        params: {
          student_district_id: this.state.district.value
            ? this.state.district.value
            : null,
          student_block_id: this.state.block.value
            ? this.state.block.value
            : null,
          medium_id: this.state.mediumSelected
            ? this.state.mediumSelected
            : null,
          class_id: this.state.selectedStandard,
          subject_id: this.state.selectSubject
            ? this.state.selectSubject
            : null,
          chapter_id: id ? id : null,
        },
      })
      .then((res) => { 
        console.log(res);
        if (id) {
          this.setState({
            rowsClick: res.data.response,
          });
        } else {
          this.setState({
            rows: res.data.response,
            tableShow: res.data ? true : false,
          });
        }
      });
  };

  handleRowClick = async (event, rowData) => {
    console.log(rowData, "rowData");
    this.getAnalytics(rowData.chapter_id);

    let reqBody = {
      chapter_id: rowData.chapter_id,
      page_number: this.state.pageNumber,
      record_per_page: this.state.recordPerPage,
      order_column: "created_at",
      order_type: 1,
    };


    let comments = await getContentWriterService().getChapterComments(reqBody);
    comments = comments.data;
    if (comments && comments.status === 200) {
      console.log(comments.response, "comments.response");
      this.setState({
        comments:comments.response

      });
    }
    this.setState({
      openDetailDialog: true,
      chapterID:rowData.chapter_id
    });
  };

  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    this.setState({
      MediumList: mediumRes.data.response,
    });
  };

  getFirstLetter = (str) => {
    if (str.trim().length) {
      return str.substr(0, 1).toUpperCase();
    }
    return "";
  };

  setPageDetails = (pageNumber, recorPerPage, totalCount) => {
    let page;
    if (totalCount < recorPerPage) {
      page = 1;
    } else {
      page = pageNumber + 1;
    }
    this.getChapterWiseComments(page, +recorPerPage);
    this.setState({
      pageNumber:page,
      recordPerPage:recorPerPage
    })
  };

  getChapterWiseComments = async (pageNumber = 1, recordPerPage = 5) => {

    let reqBody = {
      chapter_id: this.state.chapterID,
      page_number: recordPerPage == this.state.recordPerPage ?  pageNumber : 1,
      record_per_page: recordPerPage,
      order_column: "created_at",
      order_type: 1,
    };


    let comments = await getContentWriterService().getChapterComments(reqBody);
    comments = comments.data;
    if (comments && comments.status === 200) {
      console.log(comments.response, "comments.response");
      this.setState({
        comments:comments.response
        
      });
    }
  };

  render() {
    return (
      <>
        <Modal
          open={this.state.openDetailDialog}
          onClose={() => this.setState({ openDetailDialog: false,comments:[],pageNumber:1,recordPerPage:5 })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            overflow:'scroll',
          }}
        >
          <Card style={{ width: "80%", marginLeft: "10%" }}>
            <MaterialTable
              onRowClick={this.handleRowClick}
              columns={[
                { title: "part name", field: "part_name" },
                { title: "total time", field: "total_time" },
                { title: "student count", field: "stu_count" },
                {
                  title: "Average time",
                  field: "average_time",
                },
              ]}
              data={this.state.rowsClick}
              title="Part wise Analysis"
            />



{
  this?.state?.comments?.total_count > 0 ? 
  
  (
    <ChapterComments
    removeDelete
            comments={this.state.comments}
            onPageChange={(event, recordPerPage) =>
     
              this.setPageDetails(
                event.selected,
                recordPerPage,
                this.state.comments.total_count
              )
     
            }
          ></ChapterComments>

  ) : null

}


          </Card>
        </Modal>
        <Card>
          <CardHeader color="primary">
            <h4 className={styles.cardTitleWhite}>Chapter Analytics</h4>
          </CardHeader>
          <CardContent>
            <div className="row">
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select State
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={this.state.state.value}
                      onChange={(e) => {
                        this.setState(
                          {
                            state: { value: e.target.value, helperText: null },
                          },
                          () => {
                            this.getMedium();
                            this.getDistrictsByState(this.state.state.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("state")}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Syllabus Set*
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectedMedium}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState(
                          {
                            selectedMedium: e.target.value,
                             mediumSelected:"",
                             selectedStandard:"",
                             selectSubject:""
                          },
                          () => {
                            this.getAllMedium();
                          }
                        );
                      }}
                    >
                      {this.state.AllmediumList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.district_id}
                            key={stdClass.district_id}
                          >
                            {stdClass.district_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Medium*
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.mediumSelected}
                      // disabled={this.state.bulkSend}
                      onChange={(e) => {
                        this.setState(
                          {
                            mediumSelected: e.target.value,
                            selectedStandard:"",
                            selectSubject:"",
                          },
                          () => {
                            this.getAllstandards();
                          }
                        );
                      }}
                    >
                      {this.state.MediumList.map((med) => {
                        return (
                          <MenuItem value={med.medium_id} key={med.medium_id}>
                            {med.medium_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {/* <FormControl >
                    <Autocomplete
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      style={{ width: 300 }}
                      open={this.state.isSetAuto}
                      disableClearable
                      onOpen={() => {
                        this.setOpen(true);
                      }}
                      onClose={() => {
                        this.setOpen(false);
                      }}
                      onChange={(event, value) => {
                        this.setState({
                          schoolName: event.target.value,
                          AutoSchool_id: value.school_id,
                          showButton: true,
                          BlockCsv: false,
                          ShowSchoolSummary: true
                        })
                        console.log("e val", event.target.value);
                        this.onChangeHandle(value.school_id, value);
                      }}
                      getOptionLabel={(option) => option.school_name}
                      options={this.state.filteredSchools}
                    
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search School "
                          style={{ marginTop: "1em", width: "24em" }}
                          onChange={(ev) => {
                            console.log(ev.target.value, "ev");

                            this.getSchools(this.state.block.value, ev.target.value);

                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                
                            {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    {console.log(this.state.filteredSchools)}
                    {console.log(this.state.AutoSchool_id, "id")}
                  </FormControl> */}
            <div className="row">
              
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Standard*
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectedStandard}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState(
                          {
                            selectedStandard: e.target.value,
                            selectSubject:""
                          },
                          () => {
                            this.getAllSubject();
                          }
                        );
                      }}
                    >
                      {this.state.classList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.class_id}
                            key={stdClass.class_id}
                          >
                            {stdClass.class_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Subject*
                    </InputLabel>
                    <Select
                      style={{ width: "9em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.selectSubject}
                      onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState({
                          selectSubject: e.target.value,
                        });
                      }}
                    >
                      {this.state.subList.map((stdClass) => {
                        return (
                          <MenuItem
                            value={stdClass.subject_id}
                            key={stdClass.subject_id}
                          >
                            {stdClass.subject_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select District
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.district.value}
                      onChange={(e) => {
                        this.setState(
                          {
                            district: {
                              value: e.target.value,
                              helperText: null,
                            },
                            block: {
                              helperText: null,
                              value: "",
                            },
                            school: "",
                          },
                          () => {
                            this.getMedium();
                            this.getStudentBlock(this.state.district.value);
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("district")}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div style={styles.inputWrapper}>
                  <FormControl style={styles.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Select block
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={this.state.block.value}
                      onChange={(e) => {
                        this.setState(
                          {
                            BlockCsv: true,
                            showButton: true,
                            block: {
                              value: e.target.value,
                              helperText: null,
                            },
                          },
                          () => {
                            this.getSchools(this.state.block.value);
                            this.getMedium();
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("block")}
                      {console.log(this.state.block)}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <Button
              disabled={
                this.state.selectedStandard && this.state.selectSubject
                  ? false
                  : true
              }
              style={{
                color: "#fff",
                backgroundImage: "linear-gradient(60deg, #3f50b5, #8e24aa)",
              }}
              onClick={() => {
                this.getAnalytics();
              }}
            >
              Get Analytics
            </Button>
            <FormHelperText>* fields mandatory</FormHelperText>
          </CardFooter>
        </Card>
        <div style={{ height: 400, width: "100%" }}>
          {this.state.tableShow ? (
            <MaterialTable
              onRowClick={this.handleRowClick}
              columns={[
                { title: "chapter_name", field: "chapter_name" },
                { title: "total time", field: "total_time" },
                { title: "student count", field: "stu_count" },
                {
                  title: "Average time",
                  field: "average_time",
                },
                {
                  title: " Ratings",
                  field: "avg_ratings",
                },
                {
                  title: "Comments",
                  field: "total_comment",
                }
              ]}
              data={this.state.rows}
              title="Chapter Analysis"
            />
          ) : null}
        </div>
      </>
    );
  }
}
