import { Button, Card, FormHelperText, Input } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { getSuperAdminService } from "./../../service/service";
export default class Background extends React.Component {
  state = {
    imgFile: {},
    showImg : {},
    leaderboardImgFile : {},
    showLeaderBoardImg : {},
    dailyActivityImgFile : {},
    showdailyActivityImg : {},
    dashboardOneImgFile : {},
    showdashboardOneImg : {},
    dashboardTwoImgFile : {},
    showdashboardTwoImg : {},
    dashboardThreeImgFile : {},
    showdashboardThreeImg : {},
    SpecialCourseImgFile:{},
    showSpecialCourseImg:{}
  };
  componentDidMount() {
    this.getcurrentBackground();
    this.getLeaderboardBackground();
    this.getDailyActivityImageBackground()
    this.getDashboardOneImageBackground()
    this.getDashboardTwoImageBackground()
    this.getDashboardThreeImageBackground()
    this.getSpecialCourseBackground()
  }
  onImageInput = async (e) => {
    const fileE = e.target.files[0];
    console.log(fileE,"file");
    this.setState({
        imgFile : fileE
    }) 
  };
  uploadImage = async (e) =>{
     const fd = new FormData();
     fd.append("image" , this.state.imgFile)
    fd.append("img_type" , this.state.imgFile.type)
    fd.append("application_background" ,"application_background")
     console.log(this.state.imgFile,"fd");
  
    let param ={
        image : this.state.imgFile,
        img_type : this.state.imgFile.type
    }
    console.log(param,"img");
   getSuperAdminService().updateBackground(fd).
   then((data)=>{
       if(data)
       {
        this.getcurrentBackground();
       }
   })
    
  }

  onLeaderbaordImageInput = async (e) => {
    const fileE = e.target.files[0];
    console.log(fileE,"file");
    this.setState({
      leaderboardImgFile : fileE
    }) 
  };

  onSpecialCourseImageInput = async (e) => {
    const fileE = e.target.files[0];
    console.log(fileE,"file");
    this.setState({
      SpecialCourseImgFile : fileE
    }) 
  };

  uploadLeaderImage = async (e) =>{
    const fd = new FormData();
    fd.append("image" , this.state.leaderboardImgFile)
   fd.append("img_type" , this.state.leaderboardImgFile.type)
   fd.append("service_name" , "topper_background")

  getSuperAdminService().updateBackground(fd).
  then((data)=>{
      if(data)
      {
       this.getLeaderboardBackground();
      }
  })
   
 }

 uploadSpecialCourseImage = async (e) =>{
  const fd = new FormData();
  fd.append("image" , this.state.SpecialCourseImgFile)
 fd.append("img_type" , this.state.SpecialCourseImgFile.type)
 fd.append("service_name" , "special_course_background")

getSuperAdminService().updateBackground(fd).
then((data)=>{
    if(data)
    {
     this.getSpecialCourseBackground();
    }
})
 
}
  

  getLeaderboardBackground = async () => {
    let data = await getSuperAdminService().getTopperBackground();
    console.log(data.data.file, "data");
    let file = data.data.file;
    this.setState({
      showLeaderBoardImg: file,
    });
  };

  getSpecialCourseBackground = async () => {
    let data = await getSuperAdminService().getSPecialCourseBackground();
    console.log(data.data.file, "data");
    let file = data.data.file;
    this.setState({
      showSpecialCourseImg: file,
    });
  };

  onDailyActivityImageInput = async (e) => {
    const fileE = e.target.files[0];
    console.log(fileE,"file");
    this.setState({
      dailyActivityImgFile : fileE
    }) 
  };

  uploadDailyActivityImage = async (e) =>{
    const fd = new FormData();
    fd.append("image" , this.state.dailyActivityImgFile)
   fd.append("img_type" , this.state.dailyActivityImgFile.type)
   fd.append("service_name" , "daily_activity_background")

  getSuperAdminService().updateBackground(fd).
  then((data)=>{
      if(data)
      {
       this.getDailyActivityImageBackground();
      }
  })
   
 }
  

  getDailyActivityImageBackground = async () => {
    let data = await getSuperAdminService().getDailyActivityBackground();
    console.log(data.data.file, "data");
    let file = data.data.file;
    this.setState({
      showdailyActivityImg: file,
    });
  };


  getDashboardOneImageBackground = async () => {
    let data = await getSuperAdminService().getDashboardSliderOneBackground();
    console.log(data.data.file, "data");
    let file = data.data.file;
    this.setState({
      showdashboardOneImg: file,
    });
  };

  uploadDashboardOneImage = async (e) =>{
    const fd = new FormData();
    fd.append("image" , this.state.dashboardOneImgFile)
   fd.append("img_type" , this.state.dashboardOneImgFile.type)
   fd.append("service_name" , "dashboard_slider_1")

  getSuperAdminService().updateBackground(fd).
  then((data)=>{
      if(data)
      {
       this.getDashboardOneImageBackground();
      }
  })
   
  }


  onDashboardOneImageInput = async (e) => {
    const fileE = e.target.files[0];
    console.log(fileE,"file");
    this.setState({
      dashboardOneImgFile : fileE
    }) 
  };

  getDashboardTwoImageBackground = async () => {
    let data = await getSuperAdminService().getDashboardSliderTwoBackground();
    console.log(data.data.file, "data");
    let file = data.data.file;
    this.setState({
      showdashboardTwoImg: file,
    });
  };

  uploadDashboardTwoImage = async (e) =>{
    const fd = new FormData();
    fd.append("image" , this.state.dashboardTwoImgFile)
   fd.append("img_type" , this.state.dashboardTwoImgFile.type)
   fd.append("service_name" , "dashboard_slider_2")

  getSuperAdminService().updateBackground(fd).
  then((data)=>{
      if(data)
      {
       this.getDashboardTwoImageBackground();
      }
  })
  }

  onDashboardTwoImageInput = async (e) => {
    const fileE = e.target.files[0];
    console.log(fileE,"file");
    this.setState({
      dashboardTwoImgFile : fileE
    }) 
  };

  getDashboardThreeImageBackground = async () => {
    let data = await getSuperAdminService().getDashboardSliderThreeBackground();
    console.log(data.data.file, "data");
    let file = data.data.file;
    this.setState({
      showdashboardThreeImg: file,
    });
  };

  uploadDashboardThreeImage = async (e) =>{
    const fd = new FormData();
    fd.append("image" , this.state.dashboardThreeImgFile)
   fd.append("img_type" , this.state.dashboardThreeImgFile.type)
   fd.append("service_name" , "dashboard_slider_3")

  getSuperAdminService().updateBackground(fd).
  then((data)=>{
      if(data)
      {
       this.getDashboardThreeImageBackground();
      }
  })
  }




  onDashboardThreeImageInput = async (e) => {
    const fileE = e.target.files[0];
    console.log(fileE,"file");
    this.setState({
      dashboardThreeImgFile : fileE
    }) 
  };





  

  render() {
    return (
      <>
        <Card>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Background</h4>
          </CardHeader>
          <CardBody>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              current background
            </div>
            <br />

            <br />
            <img
              src={this.state.showImg ? this.state.showImg : ""}
              style={{ width: "20em", border: "3px solid black", marginLeft:"40%" }}
            />
            <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey"}}></h1>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Choose background
            </div>
            <br />
            <br />
            <div>
            <input
              //accept="image/jpeg"
              label="Image"
              type="file"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                marginLeft: "30%",
              }}
              onChange={ this.onImageInput}
            />
            <FormHelperText style={{ marginLeft:"50%"}}>
              Add image less 2MB and resolution 375 * 713
            </FormHelperText>
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"90%"}}
         
             onClick={()=>{
                 this.uploadImage()
             }}>Upload</Button>
            </div>
          </CardBody>
        </Card>

        <br />
        <br />

        <Card style={{marginBottom:"40px"}} >
          <CardBody>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Leaderboard background</h4>
          </CardHeader>
            <br />

            <br />
            <img
              src={this.state.showLeaderBoardImg ? this.state.showLeaderBoardImg : ""}
              style={{ width: "20em", border: "3px solid black", marginLeft:"40%" }}
            />
            <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey"}}></h1>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Choose Leaderboard background
            </div>
            
            <br />
            <br />
            <div>
            <input
              //accept="image/jpeg"
              label="Image"
              type="file"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                marginLeft: "30%",
              }}
              onChange={ this.onLeaderbaordImageInput}
            />
            <FormHelperText style={{ marginLeft:"50%"}}>
              Add image less 2MB and resolution 375 * 713
            </FormHelperText>
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"90%"}}
         
             onClick={()=>{
                 this.uploadLeaderImage()
             }}>Upload</Button>
            </div>
          </CardBody>
        </Card>

        <Card style={{marginBottom:"40px"}} >
          <CardBody>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Special course background</h4>
          </CardHeader>
            <br />

            <br />
            <img
              src={this.state.showSpecialCourseImg ? this.state.showSpecialCourseImg : ""}
              style={{ width: "20em", border: "3px solid black", marginLeft:"40%" }}
            />
            <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey"}}></h1>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Choose Special Course background
            </div>
            
            <br />
            <br />
            <div>
            <input
              //accept="image/jpeg"
              label="Image"
              type="file"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                marginLeft: "30%",
              }}
              onChange={ this.onSpecialCourseImageInput}
            />
            <FormHelperText style={{ marginLeft:"50%"}}>
              Add image less 2MB and resolution 375 * 713
            </FormHelperText>
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"90%"}}
         
             onClick={()=>{
                 this.uploadSpecialCourseImage()
             }}>Upload</Button>
            </div>
          </CardBody>
        </Card>
        

        <br />
        <br />

        <Card style={{marginBottom:"40px"}} >
          <CardBody>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Daily Activity background</h4>
          </CardHeader>
            <br />

            <br />
            <img
              src={this.state.showdailyActivityImg ? this.state.showdailyActivityImg : ""}
              style={{ width: "20em", border: "3px solid black", marginLeft:"40%" }}
            />
            <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey"}}></h1>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Daily Activity background
            </div>
            
            <br />
            <br />
            <div>
            <input
              //accept="image/jpeg"
              label="Image"
              type="file"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                marginLeft: "30%",
              }}
              onChange={ this.onDailyActivityImageInput}
            />
            <FormHelperText style={{ marginLeft:"50%"}}>
              Add image less 2MB and resolution 375 * 713
            </FormHelperText>
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"90%"}}
         
             onClick={()=>{
                 this.uploadDailyActivityImage()
             }}>Upload</Button>
            </div>
          </CardBody>
        </Card>

        <br />
        <br />
        <Card style={{marginBottom:"40px"}} >
          <CardBody>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Dashboard background</h4>
          </CardHeader>
            <br />
            <br />
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
                marginBottom:"30px"
              }}
            >
              {" "}
               background 1
            </div>
          <div style={{display:"flex",flexDirection:"row",alignItems:"center"}} >
         <div style={{width:"40%"}} >
         <img
              src={this.state.showdashboardOneImg ? this.state.showdashboardOneImg : ""}
              style={{ width: "20em", border: "3px solid black", marginLeft:"10%" }}
            />
        
         </div>
            <div style={{width:"60%"}} >
            <input
              //accept="image/jpeg"
              label="Image"
              type="file"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                marginLeft: "30%",
              }}
              onChange={ this.onDashboardOneImageInput}
            />
            <FormHelperText style={{ marginLeft:"50%"}}>
              Add image less 2MB and resolution 375 * 713
            </FormHelperText>
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"50%"}}
        
             onClick={()=>{
                 this.uploadDashboardOneImage()
             }}>Upload</Button>
            </div>
          </div>

           <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey",marginBottom:"20px"}}></h1>
            

            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
                marginBottom:"30px"
              }}
            >
              {" "}
              background 2
            </div>
            <div style={{display:"flex",flexDirection:"row",alignItems:"center"}} >
         <div style={{width:"40%"}} >
         <img
              src={this.state.showdashboardTwoImg ? this.state.showdashboardTwoImg : ""}
              style={{ width: "20em", border: "3px solid black", marginLeft:"10%" }}
            />
        
         </div>
            <div style={{width:"60%"}} >
            <input
              //accept="image/jpeg"
              label="Image"
              type="file"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                marginLeft: "30%",
              }}
              onChange={ this.onDashboardTwoImageInput}
            />
            <FormHelperText style={{ marginLeft:"50%"}}>
              Add image less 2MB and resolution 375 * 713
            </FormHelperText>
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"50%"}}
        
             onClick={()=>{
                 this.uploadDashboardTwoImage()
             }}>Upload</Button>
            </div>
          </div>


          <br />
            <br />

            <h1 style={{ borderBottom:"1px solid grey",marginBottom:"20px"}}></h1>
            
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
                marginBottom:"30px"
              }}
            >
              {" "}
           background 3
            </div>
            <div style={{display:"flex",flexDirection:"row",alignItems:"center"}} >
         <div style={{width:"40%"}} >
         <img
              src={this.state.showdashboardThreeImg ? this.state.showdashboardThreeImg : ""}
              style={{ width: "20em", border: "3px solid black", marginLeft:"10%" }}
            />
        
         </div>
            <div style={{width:"60%"}} >
            <input
              //accept="image/jpeg"
              label="Image"
              type="file"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                marginLeft: "30%",
              }}
              onChange={ this.onDashboardThreeImageInput}
            />
            <FormHelperText style={{ marginLeft:"50%"}}>
              Add image less 2MB and resolution 375 * 713
            </FormHelperText>
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"50%"}}
        
             onClick={()=>{
                 this.uploadDashboardThreeImage()
             }}>Upload</Button>
            </div>
          </div>


          </CardBody>
        </Card>

      </>
    );
  }
  getcurrentBackground = async () => {
    let data = await getSuperAdminService().getBackground();
    console.log(data.data.file, "data");
    let file = data.data.file;
    this.setState({
        showImg: file,
    });
  };
  
}