import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ value, onChange, height = 200, placeholder = "", onFocus, medium }) => {
  const directionality = medium === "Urdu Medium" ? "rtl" : "ltr";
  return (
    <Editor
      tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@5/tinymce.min.js"
      init={{
        placeholder: placeholder,
        height: height,
        width: "100%",
        menubar: false,
        allow_unsafe_link_target: true,
        autosave_ask_before_unload: false,
        contextmenu:
          "paste | link image inserttable | cell row column deletetable",
        content_style: "body { line-height: 1; }",

        fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt",

        font_formats:
          "Andale Mono=Roboto Mono; sans-serif=sans-serif;BalBharti=BalBhartiDev01; Karma=Karma; Noto Sans=Noto Sans; Eczar=Eczar; Vesper Libre=Vesper Libre; Cambay=Cambay; Mukta=Mukta; Open Sans=Open Sans; Trocchi=Trocchi; Noto Serif=Noto Serif; Lato=Lato; Crimson Text=Crimson Text; Tinos=Tinos; Markazi Text=Markazi Text; Indie Flower=Indie Flower; Caveat=Caveat; Mukta Vaani=Mukta Vaani; Hind Madurai=Hind Madurai; Hind Guntur=Hind Guntur; Chilanka=Chilanka; Baloo Da 2=Baloo Da 2; Lateef=Lateef;Nafees=Nafees;Fajer Noori Nastalique=Fajer Noori Nastalique;Jameel Noori Nastaleeq=Jameel Noori Nastaleeq; Jameel Noori Nastaleeq Kasheeda=Jameel Noori Nastaleeq Kasheeda",
        content_style: ` @import url('https://fonts.googleapis.com/css2?family=Karma:wght@300&display=swap'); 
          @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Eczar&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Vesper+Libre&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Cambay&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Trocchi&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Tinos&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Mukta+Vaani&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Hind+Madurai&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Hind+Guntur&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Chilanka&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Lateef&display=swap');
        `,
        plugins: [
          "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons",
        ],
        content_css: `../../../custom.css`,
        toolbar:
          "undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | fontselect | fontsizeselect | formatselect | forecolor backcolor removeformat | table | fullscreen  preview | tiny_mce_wiris_formulaEditor | link charmap |  numlist bullist | superscript subscript | hr  | pagebreak | emoticons | ltr rtl",
        lists_indent_on_tab: false,
        external_plugins: {
          tiny_mce_wiris:
            "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
        },
        setup: function (ed) {
          ed.on("init", function () {
            this.getDoc().body.style.fontSize = "14pt";
          });
          if (medium === "Urdu Medium") {
            ed.on("init", function () {
              this.getDoc().body.style.fontFamily = "Jameel Noori Nastaleeq";
            });
          }
        },
          directionality: directionality,
          license_key: 'gpl',
      }}
      value={value}
      onEditorChange={(e) => onChange(e)}
      onFocus={(e)=>onFocus(e)}
    />
  );
};

export default TextEditor;
