import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

const MyLottieComponent = ({ vopalottie }) => {
  console.log(vopalottie, 1234);
  return (
    <div>
      <div style={{ marginRight: "5em" }}>
        <span>
          <Player
            autoplay
            loop
            src={vopalottie}
            style={{
              height: "106px",
              width: "136px",
            }}
          ></Player>
        </span>
      </div>
    </div>
  );
};

export default MyLottieComponent;
