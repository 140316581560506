//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./FLNHome.module.css";
import {
  getAdministrativeService,
  getContentWriterService,
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import { Autocomplete } from "@material-ui/lab";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dropdown: {
    width: "9em",
    marginTop: "35px",
  },
  inputWrapper: {
    display: "flex",
    width: "70%",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "5px",
  },
  cardFooter: {
    justifyContent: "flex-end",
    width: "68%",
    paddingBottom: "15px",
  },
  uploadFile: {
    display: "flex",
    width: "50%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  uploadButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
};

export default class HMDashboardPage extends React.Component {
  state = {
    district: {
      helperText: null,
      value: null,
    },
    block: {
      value: null,
      helperText: null,
    },
    school: {
      value: null,
      helperText: null,
    },
    state: {
      helperText: null,
      value: null,
    },
    districtList: [],
    blocks: [],
    mediumSelected: null,
    classList: [],
    selectedMedium: null,
    yearList: null,
    monthList: null,
    ChaluSelectedMonth: null,
    selectedYear: null,
    ChaluSelectedYear: null,
    modalOpen: false,
    // monthDataType: JSON.parse(localStorage.getItem("HMSchool")).fln_data_type,
    modalValues: [],
    isSetAuto: false,
    loading: false,
    csvDownloading:false
  };

  componentDidMount() {
    this.getData();
    this.getAllMedium();
    this.getYears();
  }

  async getData() {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: 1,
      })
      .then((res) => {
        console.log(res, "Res");
        if (res.status == 200) {
          console.log(
            localStorage.getItem("AMdetails"),
            "localStorage.getItem"
          );
          this.setState({
            districtList: res.data.response,
          });
          if (localStorage.getItem("AMdetails")) {
            let Val = JSON.parse(localStorage.getItem("AMdetails"));
            console.log(Val.student_district_id, "VAL");
            this.setState(
              {
                district: {
                  helperText: null,
                  value: Val.student_district_id,
                },
              },
              () => this.getStudentBlock(Val.student_district_id)
            );
          }
        }
      });
  }

  getYears = () => {
    getHMService()
      .getYear()
      .then((res) => {
        console.log(res, "res");
        this.setState({
          yearList: res.data.years,
        });
        if (res.data.years.length > 0) {
          this.setState(
            {
              selectedYear: res.data.years[0],
              ChaluSelectedYear: res.data.years[0],
              TulanaSelectedYear: res.data.years[0],
            },
            this.getMonthsList(res.data.years[0])
          );
        }
      });
  };

  getMonthsList = (data) => {
    let param = data ? data : this.state.selectedYear;
    getHMService()
      .getMonths(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data.months)
          this.setState(
            {
              monthList: res.data.months,
              ChaluSelectedMonth: res.data.months[0],
              TulanaSelectedMonth: res.data.months[0],
              // selectedMonth: res.data.months[0],
            },
            () => {
              if (this.state.tab2) {
                this.getDataForCompare();
              }
            }
          );
      });
  };

  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    this.setState({
      MediumList: mediumRes.data.response,
    });
  };

  getAllstandards = async (value) => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };
  getStudentBlock = async (district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
            },
            () => {
              if (this.state.block.value) {
                this.getSchools(this.state.block.value);
              }
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  };

  getClassName = () => {
    var result = this.state.classList.find((obj) => {
      return obj.class_id === this.state.selectedStandard;
    });
    console.log(result, "classList");
    return result?.class_name ?result?.class_name: 'सर्व' ;
  };

  getMediumName = () => {
    var result = this.state.MediumList?.find((obj) => {
      return obj.medium_id === this.state.mediumSelected;
    });
    console.log(result, "classList");
    return result?.medium_name?result?.medium_name :'सर्व';
  };

  getDataReports = () => {
    let param = {};
    if (this.state.district.value == 0) {
      param = {
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        month_half: this.state.selectedHalf
          ? this.state.selectedHalf
          : "FIRST HALF",
        student_district_id: this.state.district.value,
      };
    } else {
      param = {
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        student_district_id: this.state.district.value,
      };
    }

    if (this.state.mediumSelected) {
      param["medium"] = this.state.mediumSelected;
    }
    if (this.state.selectedStandard) {
      param["student_class"] = this.state.selectedStandard;
    }
    if (this.state.school) {
      param["school"] = this.state.school.school_id;
    }
    if (this.state.block.value) {
      param["student_block_id"] = this.state.block.value;
    }
    getAdministrativeService()
      .getDataEntryReport(param)
      .then((res) => {
        console.log(res, "res");
        this.setState({
          modalValues: res.data,
          modalOpen: true,
        });
      });
  };

  getCSVDataReports = () => {
    this.setState({ modalOpen: false,csvDownloading:true });
    let param = {};
    if (this.state.district.value == 0) {
      param = {
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        month_half: this.state.selectedHalf
          ? this.state.selectedHalf
          : "FIRST HALF",
        student_district_id: this.state.district.value,
      };
    } else {
      param = {
        year: this.state.ChaluSelectedYear,
        month: this.state.ChaluSelectedMonth,
        student_district_id: this.state.district.value,
      };
    }
    if (this.state.mediumSelected) {
      param["medium"] = this.state.mediumSelected;
    }
    if (this.state.school) {
      param["school"] = this.state.school.school_id;
    }
    if (this.state.block.value) {
      param["student_block_id"] = this.state.block.value;
    }
    if (this.state.selectedStandard) {
      param["student_class"] = this.state.selectedStandard;
    }
    getAdministrativeService()
      .getCSVDataEntryReport(param)
      .then((res) => {
        // console.log(res.data, "res", res.headers["content-disposition"]);
        // let contentDisposition = res.headers["content-disposition"];
        // console.log(
        //   "filenmae",
        //   contentDisposition.substr(
        //     contentDisposition.indexOf("=") + 1,
        //     contentDisposition.length
        //   )
        // );
        if (res) {
          this.setState({
            csvDownloading:false
          })
          window.open(res.data.pdf_url);
          console.log(res,"res")
          // this.convertJsonToCsv(
          //   res.data,
          //   contentDisposition.substr(
          //     contentDisposition.indexOf("=") + 1,
          //     contentDisposition.length
          //   )
          // );
        }
      });
  };



  // convertJsonToCsv(items, fileTitle) {
  //   console.log(typeof items, "items");
  //   var jsonObject = JSON.stringify(items);
  //   var exportedFilenmae = fileTitle || "export.csv";

  //   var blob = new Blob([items], {
  //     type:
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   if (navigator.msSaveBlob) {
  //     navigator.msSaveBlob(blob, exportedFilenmae);
  //   } else {
  //     var link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       var url = URL.createObjectURL(blob);
  //       link.setAttribute("href", url);
  //       link.setAttribute("download", exportedFilenmae);
  //       link.style.visibility = "hidden";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // }

  
  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };

  onChangeHandle = (val, value) => {
    console.log(val, value, "val from onChangeHandle");
    this.setState({
      school: val,
    });
  };

  render() {
    let { district, block, school, state } = this.state;

    return (
      <>
        <Dialog
          style={{
            borderRadius: "10px",
            fontWeight: "bold",
            borderRadius: "20px",
          }}
          fullWidth
          maxWidth="sm"
          //fullWidth={true}
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({
              modalOpen: false,
            });
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            style={{
              backgroundColor: "#7965DA",
              color: "#fff",
              textAlign: "center",
            }}
            id="form-dialog-title"
          >
            Data Entry Summary
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#D9D9D9" }}>
            <div
              style={{
                display: "flex",

                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>वर्ष : {this.state.ChaluSelectedYear}</div>
              <div>वर्ग: {this.getClassName()}</div>
            </div>

            <div
              style={{
                marginLeft: "-10px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>महिना: {this.state.ChaluSelectedMonth}</div>
              {this.state.district.value == 0 ? (
                <div>भाग: {this.state.selectedHalf ? this.state.selectedHalf:'सर्व'}</div>
              ) : null}
              <div>माध्यम: {this.getMediumName()}</div>
            </div>

            <div
              style={{ borderBottom: "2px solid #7965DA", marginTop: "5px" }}
            ></div>

            <div
              style={{
                marginTop: "40px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>
                Total Entries found: {this.state.modalValues?.total_entries}
              </div>
              <div>
                Total Schools found: {this.state.modalValues?.total_schools}
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div>
                Total Students found: {this.state.modalValues?.total_students}
              </div>
              <div>
                Schools with Zero entries:{" "}
                {this.state.modalValues?.schools_with_zero_entries}
              </div>
            </div>

            <div
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                color: "#415EB6",
              }}
            >
              <div
                style={{
                  backgroundColor: "#7965DA",
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                  cursor:'pointer'
                }}
                onClick={() => this.getCSVDataReports()}
              >
                Download CSV
              </div>
              <div
                style={{
                  backgroundColor: "#C2B0BB",
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                }}
                onClick={() => this.setState({ modalOpen: false })}
              >
                रद्द करा
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <div
          style={{
            backgroundColor: "#B6B0CF",
            padding: "10px",
            width: "15%",
            borderRadius: "10px",
            color: "#fff",
          }}
        >
          Data Entry Report
        </div>

          {
            this.state.csvDownloading == true ? (
              <div style={{
                marginLeft:"50%",
                marginTop:"50px"
              }} >
                <CircularProgress 
                       color="inherit"
                          size={30}
                       />
                  </div>
            ) : (
      <>
        <div style={{ marginTop: "30px" }} className="row">
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  disabled
                  error={district.helperText}
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                      },
                      () => {
                        this.getStudentBlock(
                          this.state.district.value,
                          this.state.singleSchoolFlag
                        );
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },

                        school: "",
                        searchText: { value: "", helperText: null },
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  disabled={this.state.block.value ? false:true}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                    });
                    this.onChangeHandle(value);
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">
                      Medium*
                    </InputLabel> */}
                <span style={{ color: "#415EB6" }} id="School">
                  Medium
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  // disabled={this.state.bulkSend}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        selectedStandard: "",
                      },
                      () => {
                        this.getAllstandards();
                      }
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                      },
                      () => {
                        // this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass.class_id}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            marginLeft: "20px",
          }}
        >
          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Year
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.ChaluSelectedYear}
                  onChange={(e) => {
                    console.log(e.target.value, "eee");
                    this.setState(
                      {
                        ChaluSelectedYear: e.target.value,
                        firstTime: false,
                      },
                      () => {
                        this.getMonthsList(e.target.value);

                        // this.getStudentBlock(this.state.selectedDistrict);
                      }
                    );
                  }}
                >
                  {console.log(this.state.districts, "district")}
                  {this.state.yearList?.map((res) => {
                    return <MenuItem value={res}>{res}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-2">
            <div style={styles.inputWrapper}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Month
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.ChaluSelectedMonth}
                  onChange={(e) => {
                    console.log(e.target.value, "eee");
                    this.setState(
                      {
                        ChaluSelectedMonth: e.target.value,
                        firstTime: false,
                      },
                      () => {
                        // this.getStudentBlock(this.state.selectedDistrict);
                      }
                    );
                  }}
                >
                  {console.log(this.state.districts, "district")}
                  {this.state.monthList?.map((res) => {
                    return <MenuItem value={res}>{res}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          {this.state.district.value == 0 ? (
            <div className="col-2">
              <div style={styles.inputWrapper}>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="School">
                    Part
                  </span>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.selectedHalf}
                    onChange={(e) => {
                      console.log(e.target.value, "eee");
                      this.setState(
                        {
                          selectedHalf: e.target.value,
                          firstTime: false,
                        },
                        () => {}
                      );
                    }}
                  >
                    {console.log(this.state.districts, "district")}
                    <MenuItem value="FIRST HALF">part-I</MenuItem>
                    <MenuItem value="SECOND HALF">part- II</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => this.getDataReports()}
          >
            View
          </div>
        </div>
            </>
            )
          }
          
      </>
    );
  }
}
