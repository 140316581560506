//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
import classes from "./FLNHome.module.css";
import {
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import { Autocomplete } from "@material-ui/lab";
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    block: {
      value: null,
      helperText: null,
    },
    school: {
      value: null,
      helperText: null,
    },
    blocks: [],
    schools: [],
  };

  componentDidMount() {
    this.getStudentBlock();
  }

  getStudentBlock = async (district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: JSON.parse(localStorage.getItem("HMSchool"))
          ? JSON.parse(localStorage.getItem("HMSchool")).student_district_id
          : JSON.parse(localStorage.getItem("AMdetails"))
          ? JSON.parse(localStorage.getItem("AMdetails")).student_district_id
          : null,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
            },
            () => {
              this.getSchools(this.state.block.value);
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
          });
        } else {
          this.setState({
            schools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
        });
      });
  };

  setSchool = () => {
    let data = JSON.parse(localStorage.getItem("AMdetails"));
    console.log(data, "data");
    data["student_block_id"] = this.state.block.value;
    data["school_id"] = this.state.school.school_id;
    data["school_name"] = this.state.school.school_name;
    localStorage.setItem("AMdetails", JSON.stringify(data));
    window.location.reload();
  };

  render() {
    let { stdWiseGraph, block, school } = this.state;

    return (
      <>
        <div
          style={{
            marginTop: "15px",
            // backgroundColor: "#DFE4E8",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ fontSize: "20px", padding: "10px" }}>सुस्वागतम!</div>
          <div
            style={{
              display: "flex",
              justifyContent: "column",
              //   marginLeft: "18%",
            }}
          >
            <div
              style={{
                // backgroundColor: "#DFE4E8",
                padding: "10px",
                color: "#4B66B9",
                padding: "10px",
                width: "50%",
              }}
            >
              राष्ट्रीय शैक्षणिक धोरण 2020 नुसार आपल्या शाळेतील सर्व
              विद्यार्थ्यांचा अध्ययन स्तर निश्चित करावा यासाठी आपण कटिबद्ध आहोत.
              आपल्या वर्गातील विद्यार्थ्यांचा भाषा, गणित, लेखन इ. विषयाचा स्तर
              निश्चित करून स्तरनिश्चिती चे सर्व पेपर तपासून घ्यावेत त्यांचे
              संकलन करावे. आपल्या विद्यार्थ्यांची प्रगती व त्यानुसार भविष्यात
              आपण काय प्रयत्न करायचे आहेत यासाठी या माहितीची मदत होईल. या
              माहितीचे डीजीटायझेशन खालील टप्प्यात होईल.
              <div style={{ marginTop: "15px" }}>
                १. मुख्याध्यापक विद्यार्थ्यांची नावे इथे अपलोड करतील (एकदाच)
              </div>
              <div style={{ marginTop: "15px" }}>
                २. जे शिक्षक अध्ययन स्तरांची माहिती मोबाईलवरून भरणार आहेत
                त्यांची माहिती मुख्याध्यापक इथे देतील.
              </div>
              <div style={{ marginTop: "15px" }}>
                ३. त्या शिक्षकांना व्हीस्कुल मोबाईल ॲप मध्ये अध्ययन स्तर
                निश्चिती चा पर्याय सुरु होईल, व ते विद्यार्थीनिहाय नोंदी करू
                शकतील.
              </div>
              <div style={{ marginTop: "15px" }}>
                ४. शिक्षक इयत्ता निहाय अध्ययन स्तरांची माहिती अपलोड करतील.
                (प्रत्येक महिन्यातून एकदा किंवा आदेशानुसार)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "40px",
                width: "50%",
              }}
            >
              <img
                width="34%"
                src={logo}
                // style={{ marginLeft: "15%", marginBottom: "15px" }}
              />
              <img
                width="91%"
                src={home}
                // style={{ marginLeft: "15%", marginBottom: "15px" }}
              />
            </div>
          </div>
        </div>

        {JSON.parse(localStorage.getItem("user")).user_type ===
        "ADMINISTRATIVE" ? (
          <div></div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
              marginBottom: "50px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#7965DA",
                color: "#fff",
                padding: "10px",
                borderRadius: "10px",
              }}
              onClick={() => this.props.history.push("/user/FLNStudentList")}
            >
              विद्यार्थी माहिती अपलोड करा.
            </Button>
            <Button
              style={{
                backgroundColor: "#7965DA",
                color: "#fff",
                padding: "10px",
                borderRadius: "10px",
                marginLeft: "20px",
              }}
              onClick={() => this.props.history.push("/user/FLNTeacherList")}
            >
              शिक्षक माहिती अपलोड करा.
            </Button>
          </div>
        )}
      </>
    );
  }
}
