import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdfOutlined";
import ImageIcon from "@material-ui/icons/ImageOutlined";
import VideocamIcon from "@material-ui/icons/VideocamOutlined";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import GifOutlinedIcon from "@material-ui/icons/GifOutlined";
import AudioTrackIcon from "@material-ui/icons/AudiotrackOutlined";

import classes from "./ContentToolbar.module.css";

const ContentToolbar = ({ addData, className }) => {
  return (
    <Card profile className={[classes.no_spacing, className].join(" ")}>
      <CardBody
        profile
        className={[classes.container, classes.no_spacing]
          .toString()
          .split(",")
          .join(" ")}
      >
        <Button
          variant="outlined"
          name="span"
          className={classes.button}
          style={{ backgroundColor: "#295939" }}
          startIcon={<TextFieldsIcon />}
          onClick={(e) => addData(e, "TEXT")}
        >
          TEXT Field
        </Button>
        <p></p>
        {/* <Button
            color="primary"
            variant="outlined"
            name="h2"
            startIcon={<ViewHeadlineIcon />}
            onClick={this.addData.bind(this, "TEXT")}
        >
            Heading Text
        </Button>
        <p></p>
        <Button
            color="primary"
            variant="outlined"
            name="h4"
            startIcon={<ShortTextIcon />}
            onClick={this.addData.bind(this, "TEXT")}
        >
            SubHeading Text
        </Button>
        <p></p>
        <Button
            color="primary"
            variant="outlined"
            name="p"
            startIcon={<SubjectIcon />}
            onClick={this.addData.bind(this, "TEXT")}
        >
            Paragraph Text
        </Button>
        <p></p> */}
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ImageIcon />}
          className={classes.button}
          style={{ backgroundColor: "#184d47" }}
          onClick={(e) => addData(e, "IMG")}
        >
          Image
        </Button>
        <p></p>
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          style={{ backgroundColor: "#2c061f" }}
          startIcon={<GifOutlinedIcon />}
          onClick={(e) => addData(e, "GIF")}
        >
          GIF
        </Button>
        <p></p>
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          style={{ backgroundColor: "#111d5e" }}
          startIcon={<AudioTrackIcon />}
          onClick={(e) => addData(e, "AUDIO")}
        >
          Audio
        </Button>
        <p></p>
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          style={{ backgroundColor: "#e40017" }}
          startIcon={<VideocamIcon />}
          onClick={(e) => addData(e, "VIDEO")}
        >
          Video
        </Button>
        <p></p>
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          style={{ backgroundColor: "#822659" }}
          startIcon={<PictureAsPdfIcon />}
          onClick={(e) => addData(e, "PDF")}
        >
          {"   Pdf    "}
        </Button>
        <p></p>
        <Button
          variant="outlined"
          className={classes.button}
          style={{ backgroundColor: "#351f39" }}
          startIcon={<PictureAsPdfIcon />}
          color="primary"
          onClick={(e) => addData(e, "PPT")}
        >
          {"PPT"}
        </Button>
        <p></p>
        <Button
          variant="outlined"
          className={classes.button}
          style={{ backgroundColor: "#09015f" }}
          startIcon={<ListAltOutlinedIcon />}
          color="primary"
          onClick={(e) => addData(e, "gForm")}
        >
          {"Google form link"}
        </Button>
        <p></p>
        {/* <Button
            variant="outlined"
            startIcon={<LinkOutlinedIcon />}
            color="primary"
            onClick={this.addData.bind(this, "Link")}
        >
            {"External Link"}
        </Button> */}
      </CardBody>
    </Card>
  );
};

export default ContentToolbar;
