"use client";
import React, { useEffect, useState } from "react";
import "../Nudge.css";
import Pencil from "../icons/Pencil.png";
import Sort from "../icons/Sort.png";
import { Link, useHistory } from "react-router-dom";
import { GrUp } from "react-icons/gr";
import { GrDown } from "react-icons/gr";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { getSuperAdminService } from "service/service";
import { getContentWriterService } from "service/service";
import { RxCross2 } from "react-icons/rx";
import { IoCheckmarkSharp } from "react-icons/io5";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

function NudgeList({
  page,
  setMessage,
  setError,
  limit,
  offset,
  setOffset,
  setCount,
}) {
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(true);
  const [nudgeLoader, setNudgeLoader] = useState(false);
  const [filterStringGlobal, setFilterStringGlobal] = useState("");
  const [nudgeList, setNudgeList] = useState([]);
  const [allCreatedNudgeList, setAllCreatedNudgeList] = useState([]);
  // search
  const [searchByNudge, setSearchByNudge] = useState("");
  const [searchByTitle, setSearchByTitle] = useState("");
  const [searchByDate, setSearchByDate] = useState(null);
  const [searchByDateStr, setSearchByDateStr] = useState("");
  // sort
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("-");
  const [showSort, setShowSort] = useState(false);
  // filter
  // const [state_id, setState_id] = useState("");
  // const [stateList, setStateList] = useState([]);
  // const [district_id, setDistrict_id] = useState("");
  // const [districtList, setDistrictList] = useState([]);
  // const [student_block_id, setStudent_block_id] = useState("");
  // const [blockList, setBlockList] = useState([]);
  // const [syllabus_id, setSyllabus_id] = useState(3);
  // const [syllabusList, setSyllabusList] = useState([]);
  // const [medium_id, setMedium_id] = useState("");
  // const [mediumList, setMediumList] = useState([]);
  // const [class_id, setClass_id] = useState("");
  // const [classList, setClassList] = useState([]);
  const [nudgeStatus, setNudgeStatus] = useState("");

  const handleDateChange = (date) => {
    setSearchByDate(date);

    if (date !== null) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      setSearchByDateStr(formattedDate);
    } else if (date === null) {
      setSearchByDateStr("");
    }
  };

  const showSortOptionsToggler = () => {
    setShowSort((prevState) => !prevState);
  };

  const handleSetSortOrder = (value) => {
    setSortOrder(value);
  };

  const handleSetSortBy = (value) => {
    setSortBy(value);
  };

  const handleShowFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  // async function getData() {
  //   let stateRes = await getSuperAdminService().getAllState();
  //   stateRes = stateRes.data;
  //   if (stateRes && stateRes.status == 200) {
  //     setStateList(stateRes.response);
  //   }
  // }

  // async function getDistrictsByState(state_id) {
  //   let response = await getSuperAdminService().getStudentDistrict({
  //     student_state_id: state_id,
  //   });
  //   response = response.data;
  //   if (response && response.status == 200) {
  //     setDistrictList(response.response);
  //   }
  // }

  // async function getStudentBlock(district_id) {
  //   try {
  //     let response = await getSuperAdminService().getStudentBlock({
  //       student_district_id: district_id,
  //     });
  //     response = response.data;
  //     if (response && response.status == 200) {
  //       setBlockList(response.response);
  //     }
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // }

  // async function getSyllabus() {
  //   let param = {
  //     board_id: 2,
  //   };
  //   let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
  //     param
  //   );
  //   CWDistrictRes = CWDistrictRes.data;
  //   if (CWDistrictRes && CWDistrictRes.status == 200) {
  //     setSyllabusList(CWDistrictRes.response);
  //   }
  // }

  // async function getMedium() {
  //   let param = { state_id: 1 };
  //   let mediumRes = await getContentWriterService().getMediumbyState(param);
  //   mediumRes = mediumRes.data;
  //   if (mediumRes && mediumRes.status == 200) {
  //     setMediumList(mediumRes.response);
  //   }
  // }

  // async function getClass(syllabus_id) {
  //   let param = {
  //     district_id: syllabus_id,
  //     board_id: 3,
  //   };
  //   let classRes = await getSuperAdminService().getAllClassesFromDistrict(
  //     param
  //   );
  //   classRes = classRes.data;
  //   if (classRes && classRes.status == 200) {
  //     setClassList(classRes.response);
  //   }
  // }

  const getNudgeList = async () => {
    try {
      const response = await getSuperAdminService().getNudgeList();
      setNudgeList(response.data.nudges_type);
    } catch (error) {
      console.error("Error fetching nudge list", error);
    }
  };

  const getAllCreatedNudge = async (filterString) => {
    try {
      setNudgeLoader(true);
      const response = await getSuperAdminService().getAllCreatedNudgeData(
        filterString
      );
      setAllCreatedNudgeList(response.data.results);
      setCount(response.data.count);
      setNudgeLoader(false);
    } catch (error) {
      setNudgeLoader(false);
      console.error("Error fetching Nudge list", error);
    }
  };

  // const disableNudge = async (Nudge_id) => {
  //   try {
  //     let response = await getSuperAdminService().deleteNudge(Nudge_id);
  //     setError(false);
  //     setMessage("Nudge disabled successfully");
  //     await getAllCreatedNudge(filterStringGlobal);
  //   } catch (error) {
  //     setError(true);
  //     setMessage("Error disable nudge");
  //     console.error("Error disable nudge", error);
  //   }
  // };

  const enableNudge = async (nudge_id, payload) => {
    try {
      let response = await getSuperAdminService().updateNudgeStatus(nudge_id, payload);
      setError(false);
      setMessage("Nudge enabled successfully");
      await getAllCreatedNudge(filterStringGlobal);
    } catch (error) {
      setError(true);
      setMessage("Error enable Nudge");
      console.error("Error enable Nudge", error);
    }
  };

  useEffect(() => {
    // getData();
    // getMedium();
    // getClass(syllabus_id);
    // getAllCreatedNudge(filterString);
    getNudgeList()
  }, []);

  useEffect(() => {
    const data = history.location.state?.coming;
  }, []);


  useEffect(() => {
    let filterString = "";
    if (page !== "") {
      if (page === "ongoing") {
        filterString += `active=true&`;
      }
      if (page === "disabled") {
        filterString += `active=false&`;
      }
      if (searchByNudge) {
        filterString += `nudges_type=${searchByNudge}&`;
      }
      if (searchByTitle) {
        filterString += `search=${searchByTitle}&`;
      }
      if (searchByDateStr !== "") {
        filterString += `created_at=${searchByDateStr}&`;
      }
      let orderingParam = sortOrder + sortBy;
      setFilterStringGlobal(
        `${filterString}${
          "ordering=" + orderingParam + "&"
        }limit=${limit}&offset=${offset}`
      );
      getAllCreatedNudge(
        `${filterString}${
          "ordering=" + orderingParam + "&"
        }limit=${limit}&offset=${offset}`
      );
    }
  }, [
    sortOrder,
    sortBy,
    offset,
    limit,
    page,
    searchByNudge,
    searchByTitle,
    searchByDateStr,
  ]);

  useEffect(() => {
    setOffset(0);
  }, [page, limit, searchByNudge, searchByTitle, searchByDateStr]);

  return (
    <>
      <div
        className="pb-4"
        onClick={() => {
          if (showSort == true) {
            setShowSort(false);
          }
        }}
      >
        <div className="primarLight p-3 milestoneFilter primaryPurple mb-3">
          <div
            className="d-flex justify-content-between"
            onClick={handleShowFilter}
          >
            <span className="title2 colorBlack">Nudge Filter</span>
            <div className="pe-2 pointerArrow">
              {showFilter ? <GrUp /> : <GrDown />}
            </div>
          </div>
          <div
            className={`${
              showFilter ? "" : "d-none"
            } d-flex justify-content-between pt-3`}
          >
            <div className="col-3">
              <input
                className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                type="text"
                placeholder="Search by Title"
                value={searchByTitle}
                onChange={(e) => {
                  setSearchByTitle(e.target.value);
                }}
              />
            </div>
            <div className="col-3">
              <select
                className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                aria-label=".form-select-lg example"
                value={searchByNudge}
                onChange={(e) => {
                  setSearchByNudge(e.target.value);
                }}
              >
                <option value="" selected>
                  Search by nudge Scenario
                </option>
                {nudgeList?.map((nudge, index) => {
                  return (
                    <option key={index + "nudge"} value={nudge}>
                      {`${nudge}`}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-3">
              <div className="whiteBackground inputSelect mb-3">
                <div className="ms-4 selectHeight">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="width100"
                      placeholder="Select Created Date"
                      margin="normal"
                      id="date-picker-dialog"
                      label=""
                      InputProps={{
                        disableUnderline: true,
                      }}
                      format="dd/MM/yyyy"
                      value={searchByDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="col-1">
              <button
                type="button"
                class="btn btn-secondary paddingButton"
                onClick={() => {
                  setSearchByNudge("");
                  setSearchByTitle("");
                  setSearchByDate(null);
                  setSearchByDateStr("");
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div
            className="px-3 py-2 primarLight pointerArrow sortBtn"
            onClick={showSortOptionsToggler}
          >
            <img
              className="mt-1 pointerArrow"
              src={Sort}
              alt="Sort"
              width={30}
              height={30}
            />
          </div>
          <div className={`${showSort ? "" : "d-none"} challengeSort rounded`}>
            <div className="pb-3 bg-light rounded">
              <div className="crossBtn" onClick={showSortOptionsToggler}>
                <RxCross2 />
              </div>
              <div
                className="px-4 py-2 rounded sortElement d-flex"
                onClick={() => {
                  handleSetSortBy("created_at");
                }}
              >
                <div>
                  <span>Created date</span>
                </div>
                <div
                  className={`${sortBy === "created_at" ? "" : "d-none"} ps-2`}
                >
                  <IoCheckmarkSharp />
                </div>
              </div>
              <hr className=""></hr>
              <div
                className="px-4 py-2 rounded sortElement d-flex"
                onClick={() => {
                  handleSetSortOrder("");
                }}
              >
                <div>
                  <span>Ascending</span>
                </div>
                <div className={`${sortOrder === "" ? "" : "d-none"} ps-2`}>
                  <IoCheckmarkSharp />
                </div>
              </div>
              <div
                className="px-4 py-2 rounded sortElement d-flex"
                onClick={() => {
                  handleSetSortOrder("-");
                }}
              >
                <div>
                  <span>Descending</span>
                </div>
                <div className={`${sortOrder === "-" ? "" : "d-none"} ps-2`}>
                  <IoCheckmarkSharp />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4 pb-5 whiteBackground">
          <div className="d-flex">
            <div className="Regular12 colorGray col-1 ps-3 pe-2">
              <span>Sr.No.</span>
            </div>
            <div className="Regular12 colorGray col-3 pe-3">
              <span>Title</span>
            </div>
            <div className="Regular12 colorGray col-2 pe-3">
              <span>Trigger Scenario</span>
            </div>
            <div className="Regular12 colorGray col-1 pe-3">
              <span>Repeat Nudge</span>
            </div>
            <div className="Regular12 colorGray col-2 pe-3">
              <span>Duration</span>
            </div>
            <div className="Regular12 colorGray col-2 pe-3">
              <span>Notification Medium</span>
            </div>
            <div className="Regular12 colorGray col-1 px-2">
              <span>Action</span>
            </div>
          </div>
          <hr className=""></hr>
          {nudgeLoader === true ? (
            <div
              style={{
                marginLeft: "50%",
                marginTop: "10%",
              }}
            >
              <CircularProgress color="primary" size={40} />
            </div>
          ) : (
            <>
              {allCreatedNudgeList.map((createdNudge, index) => {
                return (
                  <div key={index + "nudge"}>
                    <div className="d-flex py-2">
                      <div className="Regular14 colorPrimaryBlack col-1 ps-4 pe-2">
                        <span>{createdNudge.sn_no}</span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-3 pe-3">
                        <span>{createdNudge.nudges_title}</span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-2 pe-3">
                        <span>{createdNudge.nudges_type}</span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-1 ps-4 pe-3">
                        <span>{createdNudge.repeat ? "Yes" : "No"}</span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-2 pe-3">
                        <span>
                          {createdNudge.timeframe_duration} days later
                        </span>
                      </div>
                      <div className="Regular14 colorPrimaryBlack col-2 pe-3">
                        <span>{createdNudge.notification_platform}</span>
                      </div>
                      <div className="d-flex Regular14 colorPrimaryBlack col-1 px-2">
                        <Link
                          to={`/admin/nudges/edit-nudge/${createdNudge._id}`}
                        >
                          <img
                            className="mt-1"
                            src={Pencil}
                            alt="Pencil"
                            width={20}
                            height={20}
                          />
                        </Link>
                        <div className="form-check form-switch ms-3">
                          <input
                            style={{ height: "22px", width: "40px" }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={createdNudge.active}
                            onClick={() => {
                              if (!createdNudge.active) {
                                enableNudge(createdNudge._id, {
                                  active: true,
                                });
                              } else if (createdNudge.active) {
                                enableNudge(createdNudge._id, {
                                  active: false,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="primarLightColor m-0"></hr>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default NudgeList;
