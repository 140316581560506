
//  URLs
// const URL='http://dev.vopa.in'; // For Development env
// const URL='http://test.vopa.in'; // For Development env
// const URL='http://uat.vopa.in'; // For UAT env
const URL='https://lessons.vopa.in'; // For PROD env

//End Points:
export let BASE_URL = `${URL}/vopa/api/`; 
export let Web_separate = `${URL}/content/api/`; 
export let KOITA_URL = `${URL}/student_activity/api/`; //for Koita

// /http://vopa-api-lb-999322704.ap-south-1.elb.amazonaws.com/vopa/api/health/
