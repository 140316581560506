import React, { isValidElement } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";


import cls from "./Chapters.module.css";

import * as constants from "./../../common/constants";
import {
  getContentWriterService,
  getSuperAdminService,
  getSharedService,
} from "../../service/service";
import httpV2 from "common/axiosV2";
import { chapterPreview } from "service/WebSeparateService";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  list: {
    maxHeight: "80vh",
    overflowY: "auto",
  },
};

const classes = styles;
export default class Chapters extends React.Component {
  state = {
    chapterDetail: {},
    parts: [
      {
        part_name: "",
        tags: "",
      },
    ],
    chapter: {
      state_id: "",
      board_id: "",
      district_id: "",
      class_id: "",
      medium_id: "",
      subject_id: "",
      chapter_name: "",
      chapter_number: "",
      no_of_parts: "",
      parts: [
        {
          part_name: "",
          tags: "",
        },
      ],
    },
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    mediumList: [],
    subjectList: [],
    number_of_parts: 1,
    loading: true,
    inputErrors: {
      chapter_name: {
        valid: false,
        touched: false,
      },
      chapter_number: {
        valid: false,
        touched: false,
      },
    },
    user_type: null,
    chapterList: [],
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState(
      {
        user_type: user.user_type,
      },
      () => {
        this.getData();
      }
    );
  }

  async getData() {
    let { chapter } = this.state;
    let stateRes;
    if (this.state.user_type == constants.USER_TYPE.SUPER_ADMIN) {
      stateRes = await getSuperAdminService().getAllState();
    } else {
      stateRes = await getContentWriterService().getAdminCWstates();
    }
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      chapter = { ...chapter, state_id: stateRes.response[0].state_id };
      this.setState({ stateList: stateRes.response, chapter }, async () => {
        let data = { state_id: this.state.chapter.state_id };
        let mediumRes = await getContentWriterService().getMediumbyState(data);
        mediumRes = mediumRes.data;
        if (mediumRes && mediumRes.status == 200) {
          chapter = { ...chapter, medium_id: mediumRes.response[0].medium_id };
          this.setState({ mediumList: mediumRes.response, chapter });
        }
        let boardRes;
        if (this.state.user_type === constants.USER_TYPE.SUPER_ADMIN) {
          boardRes = await getSuperAdminService().getAllBoardFromState(data);
        } else {
          boardRes = await getContentWriterService().getAdminCWBoard(data);
        }
        boardRes = boardRes.data;
        if (boardRes && boardRes.status == 200) {
          chapter = { ...chapter, board_id: boardRes.response[0].board_id };
          this.setState({ boardList: boardRes.response, chapter }, async () => {
            let apidata = { board_id: this.state.chapter.board_id };
            let CWDistrictRes;
            if (this.state.user_type == constants.USER_TYPE.SUPER_ADMIN) {
              CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                apidata
              );
            } else {
              CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                apidata
              );
            }
            CWDistrictRes = CWDistrictRes.data;
            if (CWDistrictRes && CWDistrictRes.status == 200) {
              chapter = {
                ...chapter,
                district_id: CWDistrictRes.response[0].district_id,
              };
              this.setState(
                { districtList: CWDistrictRes.response, chapter },
                async () => {
                  let apidata1 = {
                    board_id: this.state.chapter.board_id,
                    district_id: this.state.chapter.district_id,
                  };
                  let CWClassesRes;
                  if (this.state.user_type == constants.USER_TYPE.SUPER_ADMIN) {
                    CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                      apidata1
                    );
                  } else {
                    CWClassesRes = await getContentWriterService().getAdminCWclasses(
                      apidata1
                    );
                  }
                  CWClassesRes = CWClassesRes.data;
                  if (CWClassesRes && CWClassesRes.status == 200) {
                    chapter = {
                      ...chapter,
                      class_id: CWClassesRes.response[0].class_id,
                    };
                    this.setState(
                      { classList: CWClassesRes.response, chapter },
                      async () => {
                        let apidata2 = {
                          class_id: chapter.class_id,
                          medium_id: chapter.medium_id,
                        };
                        let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                          apidata2
                        );
                        subjectClassRes = subjectClassRes.data;
                        chapter = {
                          ...chapter,
                          subject_id:
                            subjectClassRes.response.length &&
                            subjectClassRes.response[0].subject_id,
                        };
                        this.setState(
                          {
                            subjectList: subjectClassRes.response,
                            chapter,
                            loading: false,
                          },
                          () => {
                            this.getChapterListByChapter(this.state.chapter);
                          }
                        );
                      }
                    );
                  }
                }
              );
            }
          });
        }
      });
    }
  }

  renderParts() {
    let { chapter } = this.state;
    return chapter.parts.map((part, index) => {
      return (
        <>
          <GridItem
            key={index}
            xs={12}
            sm={12}
            md={6}
            style={{ marginTop: 15 }}
          >
            <TextField
              variant="outlined"
              type="text"
              label="name of part"
              id="name of part"
              name="name of part"
              value={part.part_name}
              onChange={(e) => {
                let { chapter } = this.state;
                chapter.parts[index].part_name = e.target.value;
                this.setState({ chapter });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%" }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: 15 }}>
            <TextField
              variant="outlined"
              type="text"
              label="tags"
              id="tags"
              name="tags"
              value={part.tags}
              onChange={(e) => {
                let { chapter } = this.state;
                chapter.parts[index].tags = e.target.value;
                this.setState({ chapter });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%" }}
            />
          </GridItem>
        </>
      );
    });
  }

  getChapterListByChapter = async (chapter) => {
    let reqBody = {
      subject_id: chapter.subject_id,
    };
    let chapterListData = await httpV2.get(`chapters/`, {
      params: {
        limit: 100,
        offset: 0,
        subject_id: chapter.subject_id,
      },
    });
    console.log(chapterListData, "chapterListData");
    chapterListData = chapterListData.data;
    if (chapterListData) {
      console.log(chapterListData, "chapterListData");
      this.setState({
        chapterList: chapterListData.results,
      });
    }
  };
  rowClick = (data) => {
    console.log(data, "data");
    let param = {
      chapter_id: data,
    };
    chapterPreview(data).then((result) => {
      console.log(result.data.response.chapter, "result");
      let rowData = result.data.response.chapter;
      if (rowData) {
        this.props.history.push(`chapter/preview/${data}`, {
          chapter: rowData,
        });
      }
    });
  };
  setChapterStatusColor = (chapter) => {
    if (chapter.status === constants.CHAPTER_STATUS.PUBLISHED) {
      return cls.published;
    } else if (chapter.status === constants.CHAPTER_STATUS.IN_REVIEW) {
      return cls.inReview;
    } else if (chapter.status === constants.CHAPTER_STATUS.CHANGES_REQUIRED) {
      return cls.changesRequired;
    } else if (chapter.status === constants.CHAPTER_STATUS.IN_PROGRESS) {
      return cls.inProgress;
    }
    return {};
  };

  renderStateMenu(type) {
    let {
      stateList,
      boardList,
      districtList,
      classList,
      mediumList,
      subjectList,
    } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "board"
        ? boardList
        : type == "district"
        ? districtList
        : type == "class"
        ? classList
        : type == "medium"
        ? mediumList
        : subjectList;
    console.log(type, "type", array);
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "board"
          ? state.board_id
          : type == "district"
          ? state.district_id
          : type == "class"
          ? state.class_id
          : type == "medium"
          ? state.medium_id
          : state.subject_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "board"
          ? state.board_name
          : type == "district"
          ? state.district_name
          : type == "class"
          ? state.class_name
          : type == "medium"
          ? state.medium_name
          : state.subject_name;
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }

  isFormInvalid = () => {
    let isInvalid = false;
    if (!this.state.chapter.chapter_name) {
      isInvalid = true;
    } else if (!this.state.chapter.chapter_number) {
      isInvalid = true;
    } else if (!this.state.number_of_parts) {
      isInvalid = true;
    } else if (!this.state.chapter.state_id) {
      isInvalid = true;
    } else if (!this.state.chapter.district_id) {
      isInvalid = true;
    } else if (!this.state.chapter.board_id) {
      isInvalid = true;
    } else if (!this.state.chapter.class_id) {
      isInvalid = true;
    } else if (!this.state.chapter.medium_id) {
      isInvalid = true;
    }
    return isInvalid;
  };

  render() {
    let { chapter, inputErrors, number_of_parts, chapterList } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Create new Lesson</h4>
                <span>Fill the details to create new chapter</span>
              </CardHeader>
              <CardBody>
                <div style={styles.inputWrapper}>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={chapter.state_id}
                      onChange={(e) => {
                        const chapter = {
                          ...chapter,
                          state_id: e.target.value,
                        };
                        this.setState(
                          {
                            chapter: chapter,
                          },
                          async () => {
                            let data = {
                              state_id: this.state.chapter.state_id,
                            };
                            let mediumRes = await getContentWriterService().getMediumbyState(
                              data
                            );
                            mediumRes = mediumRes.data;
                            if (mediumRes && mediumRes.status == 200) {
                              let boardRes;
                              if (
                                this.state.user_type ===
                                constants.USER_TYPE.SUPER_ADMIN
                              ) {
                                boardRes = await getSuperAdminService().getAllBoardFromState(
                                  data
                                );
                              } else {
                                boardRes = await getContentWriterService().getAdminCWBoard(
                                  data
                                );
                              }
                              boardRes = boardRes.data;
                              if (boardRes && boardRes.status == 200) {
                                let ch = {
                                  ...chapter,
                                  board_id: boardRes.response[0].board_id,
                                  medium_id: mediumRes.response[0].medium_id,
                                };
                                this.setState(
                                  {
                                    boardList: boardRes.response,
                                    mediumList: mediumRes.response,
                                    chapter: ch,
                                  },
                                  async () => {
                                    let apidata = {
                                      board_id: this.state.chapter.board_id,
                                    };
                                    let CWDistrictRes;
                                    if (
                                      this.state.user_type ==
                                      constants.USER_TYPE.SUPER_ADMIN
                                    ) {
                                      CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                        apidata
                                      );
                                    } else {
                                      CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                        apidata
                                      );
                                    }
                                    CWDistrictRes = CWDistrictRes.data;
                                    if (
                                      CWDistrictRes &&
                                      CWDistrictRes.status == 200
                                    ) {
                                      let ch = {
                                        ...this.state.chapter,
                                        district_id:
                                          CWDistrictRes.response[0].district_id,
                                      };
                                      this.setState(
                                        {
                                          districtList: CWDistrictRes.response,
                                          chapter: ch,
                                        },
                                        async () => {
                                          let apidata1 = {
                                            board_id: this.state.chapter
                                              .board_id,
                                            district_id: this.state.chapter
                                              .district_id,
                                          };
                                          let CWClassesRes;
                                          if (
                                            this.state.user_type ==
                                            constants.USER_TYPE.SUPER_ADMIN
                                          ) {
                                            CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                              apidata1
                                            );
                                          } else {
                                            CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                              apidata1
                                            );
                                          }
                                          CWClassesRes = CWClassesRes.data;
                                          if (
                                            CWClassesRes &&
                                            CWClassesRes.status == 200
                                          ) {
                                            let ch = {
                                              ...this.state.chapter,
                                              class_id:
                                                CWClassesRes.response[0]
                                                  .class_id,
                                            };
                                            this.setState(
                                              {
                                                classList:
                                                  CWClassesRes.response,
                                                chapter: ch,
                                              },
                                              async () => {
                                                let apidata2 = {
                                                  class_id: this.state.chapter
                                                    .class_id,
                                                  medium_id: this.state.chapter
                                                    .medium_id,
                                                };
                                                let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                                  apidata2
                                                );
                                                subjectClassRes =
                                                  subjectClassRes.data;
                                                let ch = {
                                                  ...this.state.chapter,
                                                  subject_id:
                                                    subjectClassRes.response[0]
                                                      .subject_id,
                                                };
                                                this.setState({
                                                  subjectList:
                                                    subjectClassRes.response,
                                                  chapter: ch,
                                                  loading: false,
                                                });
                                              }
                                            );
                                          }
                                        }
                                      );
                                    }
                                  }
                                );
                              }
                            } else {
                              this.setState({
                                loading: false,
                                subjectList: [],
                                classList: [],
                                districtList: [],
                                boardList: [],
                                mediumList: [],
                                chapter: {
                                  ...this.state.chapter,
                                  subject_id: null,
                                  class_id: null,
                                  district_id: null,
                                  board_id: null,
                                  medium_id: null,
                                },
                              });
                            }
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("state")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">Board</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={chapter.board_id}
                      onChange={(e) => {
                        let updatedChapter = {
                          ...chapter,
                          board_id: e.target.value,
                        };
                        this.setState(
                          {
                            chapter: updatedChapter,
                          },
                          async () => {
                            let apidata = {
                              board_id: this.state.chapter.board_id,
                            };
                            let CWDistrictRes;
                            if (
                              this.state.user_type ==
                              constants.USER_TYPE.SUPER_ADMIN
                            ) {
                              CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                apidata
                              );
                            } else {
                              CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                apidata
                              );
                            }
                            CWDistrictRes = CWDistrictRes.data;
                            if (CWDistrictRes && CWDistrictRes.status == 200) {
                              let ch = {
                                ...this.state.chapter,
                                district_id:
                                  CWDistrictRes.response[0].district_id,
                              };
                              this.setState(
                                {
                                  districtList: CWDistrictRes.response,
                                  chapter: ch,
                                },
                                async () => {
                                  let apidata1 = {
                                    board_id: this.state.chapter.board_id,
                                    district_id: this.state.chapter.district_id,
                                  };
                                  let CWClassesRes;
                                  if (
                                    this.state.user_type ==
                                    constants.USER_TYPE.SUPER_ADMIN
                                  ) {
                                    CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                      apidata1
                                    );
                                  } else {
                                    CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                      apidata1
                                    );
                                  }
                                  CWClassesRes = CWClassesRes.data;
                                  if (
                                    CWClassesRes &&
                                    CWClassesRes.status == 200
                                  ) {
                                    let ch = {
                                      ...this.state.chapter,
                                      class_id:
                                        CWClassesRes.response[0].class_id,
                                    };
                                    this.setState(
                                      {
                                        classList: CWClassesRes.response,
                                        chapter: ch,
                                      },
                                      async () => {
                                        let apidata2 = {
                                          class_id: this.state.chapter.class_id,
                                          medium_id: this.state.chapter
                                            .medium_id,
                                        };
                                        let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                          apidata2
                                        );
                                        subjectClassRes = subjectClassRes.data;
                                        let ch = {
                                          ...this.state.chapter,
                                          subject_id:
                                            subjectClassRes.response[0]
                                              .subject_id,
                                        };
                                        this.setState({
                                          subjectList: subjectClassRes.response,
                                          chapter: ch,
                                          loading: false,
                                        });
                                      }
                                    );
                                  }
                                }
                              );
                            } else {
                              this.setState({
                                subjectList: [],
                                classList: [],
                                districtList: [],
                                loading: false,
                                chapter: {
                                  ...this.state.chapter,
                                  subject_id: null,
                                  class_id: null,
                                  district_id: null,
                                },
                              });
                            }
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("board")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Syllabus Set
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={chapter.district_id}
                      onChange={(e) => {
                        let updatedChapter = {
                          ...chapter,
                          district_id: e.target.value,
                        };
                        this.setState(
                          {
                            chapter: updatedChapter,
                          },
                          async () => {
                            let apidata1 = {
                              board_id: this.state.chapter.board_id,
                              district_id: this.state.chapter.district_id,
                            };
                            let CWClassesRes;
                            if (
                              this.state.user_type ==
                              constants.USER_TYPE.SUPER_ADMIN
                            ) {
                              CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                apidata1
                              );
                            } else {
                              CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                apidata1
                              );
                            }
                            CWClassesRes = CWClassesRes.data;
                            if (CWClassesRes && CWClassesRes.status == 200) {
                              let ch = {
                                ...this.state.chapter,
                                class_id: CWClassesRes.response[0].class_id,
                              };
                              this.setState(
                                {
                                  classList: CWClassesRes.response,
                                  chapter: ch,
                                },
                                async () => {
                                  let apidata2 = {
                                    class_id: this.state.chapter.class_id,
                                    medium_id: this.state.chapter.medium_id,
                                  };
                                  let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                    apidata2
                                  );
                                  subjectClassRes = subjectClassRes.data;
                                  let ch = {
                                    ...this.state.chapter,
                                    subject_id:
                                      subjectClassRes.response[0].subject_id,
                                  };
                                  this.setState({
                                    subjectList: subjectClassRes.response,
                                    chapter: ch,
                                    loading: false,
                                  });
                                }
                              );
                            } else {
                              this.setState({
                                subjectList: [],
                                classList: [],
                                loading: false,
                                chapter: {
                                  ...this.state.chapter,
                                  subject_id: null,
                                  class_id: null,
                                },
                              });
                            }
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("district")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">Class</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={chapter.class_id}
                      onChange={(e) => {
                        let { chapter } = this.state;
                        chapter = { ...chapter, class_id: e.target.value };
                        this.setState({ chapter }, () => {
                          let apidata2 = {
                            class_id: chapter.class_id,
                            medium_id: chapter.medium_id,
                          };
                          getContentWriterService()
                            .getSubjectByClassMed(apidata2)
                            .then((res) => {
                              if (res.data && res.data.status == 200) {
                                chapter = {
                                  ...chapter,
                                  subject_id:
                                    res.data.response.length > 0
                                      ? res.data.response[0].subject_id
                                      : "",
                                };
                                this.setState({
                                  subjectList: res.data.response,
                                  // chapter,
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                    // class_id: null,
                                  },
                                });
                              } else {
                                this.setState({
                                  subjectList: [],
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                  },
                                });
                              }
                            });
                        });
                      }}
                    >
                      {this.renderStateMenu("class")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Medium
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={chapter.medium_id}
                      onChange={(e) => {
                        let { chapter } = this.state;
                        chapter = { ...chapter, medium_id: e.target.value };
                        this.setState({ chapter }, () => {
                          let apidata2 = {
                            class_id: chapter.class_id,
                            medium_id: chapter.medium_id,
                          };
                          getContentWriterService()
                            .getSubjectByClassMed(apidata2)
                            .then((res) => {
                              if (res.data && res.data.status == 200) {
                                chapter = {
                                  ...chapter,
                                  subject_id:
                                    res.data.response.length > 0
                                      ? res.data.response[0].subject_id
                                      : "",
                                };
                                this.setState({
                                  subjectList: res.data.response,
                                  // chapter,
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                  },
                                });
                              } else {
                                this.setState({
                                  subjectList: [],
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                  },
                                });
                              }
                            });
                        });
                      }}
                    >
                      {this.renderStateMenu("medium")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Subject
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={chapter.subject_id}
                      onChange={(e) => {
                        let { chapter } = this.state;
                        chapter = { ...chapter, subject_id: e.target.value };
                        this.setState({ chapter }, () => {
                          this.getChapterListByChapter(this.state.chapter);
                        });
                      }}
                    >
                      {this.renderStateMenu("subject")}
                    </Select>
                  </FormControl>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={8} sm={8} md={4}>
            <Card>
              <CardHeader color="primary">
                <h4>Lesson List</h4>
              </CardHeader>
              <CardBody>
                <div style={(styles.inputWrapper, styles.list)}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Chapter</TableCell>
                          <TableCell align="center">Chapter No</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chapterList.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.rowClick(row.chapter_id);
                              }}
                              align="left"
                            >
                              {row.chapter_name}
                            </TableCell>
                            <TableCell align="center">
                              {row.chapter_no}
                            </TableCell>
                            <TableCell align="center">
                              <div className={this.setChapterStatusColor(row)}>
                                {row.status}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
