import { Button, Card, FormHelperText, Input,CircularProgress } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import {
  getSuperAdminService,
  getContentWriterService,
} from "./../../service/service";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change,
} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { getMcqAiGenerated,addAIquestionsToDB } from "service/WebSeparateService";
import Snackbar from "components/Snackbar/Snackbar";


export default class MCQAI extends React.Component {
  state = {
    classList: [],
    selectedStandard: "",
    selectSubject: "",
    subList: [],
    difficulty:"",
    description:"",
    aiquestions:[],
    topic:"",
    checkForm:false,
    showLoader:false,
    message: "",
    error: false,
    duplicateAiQues:[]
  };
  componentDidMount() {
    this.getAllstandards();
  }

  getAllstandards = async (value) => {
    let apidata3 = {
      district_id: 3,
      board_id: 3,
    };
    console.log(apidata3);

    getSuperAdminService()
      .getAllClassesFromDistrict(apidata3)
      .then((res) => {
        this.setState({
          classList: res.data.response,
        });
      });
  };
  getAllSubject = () => {
    let param = {
      class_id: this.state.selectedStandard?.class_id,
      medium_id: 3,
    };

    getContentWriterService()
      .getSubjectByClassMed(param)
      .then((res) => {
        this.setState({
          subList: res.data.response,
        });
      });
  };

  showMCQ =()=>{


    return(
      <>
      {this.state.aiquestions?.map((res,index)=>{
        return(
       <>
        {res?.data_format == true ? (
          <div style={{marginTop:"20px",marginLeft:"40px",marginBottom:"70px"}} >
           <div style={{marginBottom:"20px"}} >

          <div
          style={{
            marginLeft:"85%",
            marginBottom:"-40px"
          }}
          >
          {
            res?.addedMcq == false ? (
              <>
              {
            res?.editQuestion != true ? (
              <IconButton
                onClick={()=>{
            const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  return { ...item, editQuestion: !res?.editQuestion };
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray
              })
           }}
              >
            <EditIcon />
            </IconButton>
            ) : (
           <>
           <IconButton
                 onClick={()=>{
            const updatedArray = this.state.duplicateAiQues.map((item,i) => {
                if (i === index) {
                  return { ...item, editQuestion: !res?.editQuestion };
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray
              })
           }}
              >
            <ClearIcon />
            </IconButton>
           <IconButton
                 onClick={()=>{
            const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  return { ...item, editQuestion: !res?.editQuestion };
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray,
                duplicateAiQues:updatedArray
              })
           }}
              >
            <SaveIcon />
            </IconButton>

           </>
            
            )
           }  
              </>
            ) : null
          }

          </div>
          
         
           <p style={{fontWeight:600}} >Question {index + 1} </p>
            <input
            disabled={!res?.editQuestion}
              style={{
                borderWidth: !res?.editQuestion ? "0" : "1px",
                borderColor:"black",
                borderStyle:"solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
              value={res?.question}
              onChange={(event) => {    
                const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  return { ...item, question: event.target.value };
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray
              })
              }}
              placeholder="Question"
              type="Text"
            />
           </div>

           <div style={{marginBottom:"20px"}} >
           <p style={{fontWeight:600}} >Options</p>
           {    
            res?.answers?.map((i,pos)=>{
              return(
                <input
                disabled={!res?.editQuestion}
              style={{
                borderWidth: !res?.editQuestion ? "0" : "1px",
                borderColor:"black",
                borderStyle:"solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
                marginTop:20
              }}
              value={i}
              onChange={(event) => {
  
                const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  console.log("Event",event.target.value,pos)  
                  let updarr = item?.answers?.map((arr,arrIndex)=>{
                    if(arrIndex == pos){
                      return event.target.value
                    }
                    return arr
                  })
                  return { ...item, answers: updarr }; 
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray
              })
              }}
              placeholder="Options"
              type="Text"
            />
              )
            })
           }
            
           </div>
           <div style={{marginBottom:"20px"}} >
           <p style={{fontWeight:600}} >Correct answer</p>
            <input
              disabled={true}
              style={{
                border:"0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
              value={res?.answers[res?.correctAnswerPos]}
              // onChange={(event) => {    
              //   const updatedArray = this.state.aiquestions.map((item,i) => {
              //   if (i === index) {
              //     return { ...item, correctAnswer: event.target.value };
              //   }
              //   return item;
              // });
              // this.setState({
              //   aiquestions:updatedArray
              // })
              // }}
              placeholder="Correct answer"
              type="Text"
            />
            
           </div>
           <div style={{marginBottom:"20px"}} >
           <p style={{fontWeight:600}} >Feedback for correct answer</p>
            <input
                        disabled={!res?.editQuestion}
              style={{
                borderWidth: !res?.editQuestion ? "0" : "1px",
                borderColor:"black",
                borderStyle:"solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
              value={res?.messageForCorrectAnswer}

              onChange={(event) => {    
                const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  return { ...item, messageForCorrectAnswer: event.target.value };
                }
                return item;
                });
              this.setState({
                aiquestions:updatedArray
              })
              }}
              placeholder="Feedback For Correct Answer"
              type="Text"
            />
            
           </div>
           <div style={{marginBottom:"20px"}} >
           <p style={{fontWeight:600}} >Feedback for Incorrect answer</p>
            <input
                        disabled={!res?.editQuestion}
              style={{
                borderWidth: !res?.editQuestion ? "0" : "1px",
                borderColor:"black",
                borderStyle:"solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
              value={res?.messageForIncorrectAnswer}
              onChange={(event) => {    
                const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  return { ...item, messageForIncorrectAnswer: event.target.value };
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray
              })
              }}
              placeholder="Feedback For Incorrect Answer"
              type="Text"
            />
            
           </div>
           <div style={{marginBottom:"20px"}} >
           <p style={{fontWeight:600}} >Explanation</p>
            <input
                disabled={!res?.editQuestion}
              style={{
                borderWidth: !res?.editQuestion ? "0" : "1px",
                borderColor:"black",
                borderStyle:"solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
              value={res?.explanation}
              onChange={(event) => {    
                const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  return { ...item, explanation: event.target.value };
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray
              })
              }}
              placeholder="explanation"
              type="Text"
            />
            {/* <p 
             style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
                marginTop:20,
                color:"black"
              }}
            >
              {res?.explanation}
            </p> */}
            
           </div>
           <div style={{marginBottom:"20px"}} >
           <p style={{fontWeight:600}} >Explanation Link</p>
            <input
                disabled={!res?.editQuestion}
              style={{
                borderWidth: !res?.editQuestion ? "0" : "1px",
                borderColor:"black",
                borderStyle:"solid",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
              value={res?.explanationLink}
              onChange={(event) => {    
                const updatedArray = this.state.aiquestions.map((item,i) => {
                if (i === index) {
                  return { ...item, explanationLink: event.target.value };
                }
                return item;
              });
              this.setState({
                aiquestions:updatedArray
              })
              }}
              placeholder="explanation Link"
              type="Text"
            />
            {/* <p 
             style={{
                border: "0",
                backgroundColor: "#DFE4E8",
                height: "40px",
                marginRight: "10px",
                paddingLeft: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
                marginTop:20,
                color:"black"
              }}
            >
              {res?.explanation}
            </p> */}
            
           </div>
           {/* <div style={{marginBottom:"20px"}} >
           <p>Upload Photo</p>
           <input
                  type="file"
                  className="form-control"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                  
                  }}
                  style={{ width: 190, height: 38 }}
                />
            
           </div> */}

           {
            res?.addedMcq == false ? (
<>
{
            res?.editQuestion != true ? (
              <div style={{display:"flex",justifyContent:"center"}} >
           <button 
           onClick={()=>{
            this.addToDB(res,index)
           }}
           style={{"backgroundColor":"#855AD8","borderRadius":"10px","color":"white","width":"200px","border":"none","height":"40px","margin":"10px"}} >
           Add to AI database
          </button>
           </div>
            ) : null
           }
</>
            ) : (
            null
            )
           }
          

        </div>
        ) : (
   <div>
    {
      <div style={{
          marginTop:"20px",marginLeft:"20px",marginBottom:"50px"
        }} >
          {
            Object?.keys(res)?.map((key,index)=>{
              return(
              <>
              {Array.isArray(res?.[key]) == true ? <div style={{
                display:"flex",
                flexDirection:"row"
              }} >
               <div>
               {key} : 
               </div>
                <div>
{res?.[key]?.map?.((i,index)=>{
  return(
    <p style={{marginLeft:"10px"}} >
       {i}
    </p>
  )
})}
                </div>
              </div> : 
              (<p>
                  {key} : {res?.[key]}
                </p>)
               }
            
              </>
              )
            })
          }
          <br/>
          <hr/>
        </div>
    }
   </div>
        )}
       </>
        )
      })}
      </>
    )
  }

  getMCQ = async ()=>{


    if(this.state.selectedStandard != "" && this.state.selectSubject != "" && this.state.difficulty != "" &&  this.state.description != "" && this.state.topic != "" ){
        this.setState({
          checkForm:false,
          showLoader:true
        },async ()=>{

          let param={
            class : this.state.selectedStandard?.class_name,
            subject : this.state.selectSubject?.subject_name,
            keys: this.state.description,
            dificulty: this.state.difficulty,
            topic: this.state.topic
          }
          
          let url = `live_mcq_generation/generate_live_mcq/?class=${param?.class}&subject=${param?.subject}&keys=${param?.keys}&dificulty=${param?.dificulty}&topic=${param?.topic}`

           await getMcqAiGenerated(url)
           .then((res)=>{
            if(res){
              let arr = res?.data

              if(arr.status == 200){


                arr =  arr?.data

                let newArr = arr.map((i)=>{
                  if(i?.data_format == true){
                    let correctAnswerIndex = i.answers?.indexOf(i?.correctAnswer)
         
                    return({
                      ...i,
                      editQuestion:false,
                      correctAnswerPos:correctAnswerIndex,
                      addedMcq:false
                    })
                  }
                  else{
                    return({
                      ...i,
                    })
                  }
                })

                this.setState({
                  aiquestions:newArr,
                  duplicateAiQues:newArr,
                  showLoader:false
                })

          
              }
              else{
                this.setState({
                  showLoader:false
                })
              }     
      
            }
            else{
              this.setState({
                showLoader:false
              })
            }
           })
           .catch((err)=>{
            this.setState({
              showLoader:false
            })
           })
      
        })
    }
    else{
      this.setState({
        checkForm:true
      })
    }
  }

  addToDB= async (data,key)=>{


    const mcq = { ...data };
    
    const removedArray = this.state.aiquestions.map((item,i) => {
      if (i === key) {
        return { ...item, addedMcq:true };
      }
      return item;
      });
  

    mcq.correctAnswer = `${mcq?.correctAnswerPos + 1}`
    delete mcq.data_format ;
    delete mcq.editQuestion ;
    delete mcq.correctAnswerPos ;
    delete mcq.addedMcq  ;


    let param = {
      medium_id_id: 3,
      subject_id_id: this.state.selectSubject?.subject_id,
      class_id_id: this.state.selectedStandard?.class_id,
      topic: this.state.topic,
      data:[mcq]
    }


    await addAIquestionsToDB(param)
    .then((res)=>{
      if(res){
        let data=res?.data
        if(data.status == 201){
          this.setState({
            message:"The MCQ has been added",
            error: false,
            aiquestions:removedArray
          })
        }
        else{
          this.setState({
            message:"Something Went Wrong",
            error: true,
          })
        }
      }
      else{
        this.setState({
          message:"Something Went Wrong",
          error: true,
        })
      }
    })
    
  }


  render() {
    let {
      message,
      error
    } = this.state;
    return (
      <>
        <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() => {
            this.setState({
              message: null,
            });
          }}
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        <h3 style={{backgroundColor:"#DFE4E8",color:"#855AD8",padding:"5px"}}  >AI MCQs Generator</h3>
        <hr />
        <br />
        <br />
        <div style={{ flexDirection: "row", display: "flex", }}>
          <div className="col-2">
            <div
              style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Class
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedStandard}
                  onChange={(e) => {
                    console.log(e.target, "E");
                    this.setState(
                      {
                        selectedStandard: e.target.value,
                        selectSubject: "",
                      },
                      () => {
                        this.getAllSubject();
                      }
                    );
                  }}
                >
                  {this.state.classList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass}
                        key={stdClass.class_id}
                      >
                        {stdClass.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <FormControl
                style={{
                  width: "30%",
                  padding: "8px 8px",
                }}
              >
                    <span style={{ color: "#415EB6" }} id="School">
                  Subject
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectSubject}
                  onChange={(e) => {
                        console.log(e.target.value, "E");
                        this.setState({
                          selectSubject: e.target.value,
                        });
                      }}
                >
                  {this.state.subList.map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass}
                        key={stdClass.subject_id}
                      >
                        {stdClass.subject_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div
              style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  Difficulty
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8", width: "9em" }}
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.difficulty}
                  onChange={(e) => {
                    console.log("E",e)
                      this.setState({
                        difficulty:e.target.value
                      })
                  }}
                >
                  {["easy","hard"].map((stdClass) => {
                    return (
                      <MenuItem
                        value={stdClass}
                        key={stdClass}
                      >
                        {stdClass}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div
              style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6",fontSize:"10px",marginLeft:"12px" }} id="School">
                  Add Topic (One or Two Word)
                </span>
                <textarea
                  style={{"border":"0","backgroundColor":"#DFE4E8","height":"80px","marginBottom":"5px","marginRight":"10px","marginLeft":"10px","paddingLeft":"10px","width":"200px","display":"flex","flexDirection":"row","alignItems":"center"}}
                value={this.state.topic}
                onChange={(event) => {
                  this.setState({ topic: event.target.value });
                }}
                placeholder="topic"
              >
                {this.state.topic}
              </textarea>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div
              style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <FormControl fullWidth>
                <span style={{ color: "#415EB6",fontSize:"10px",marginLeft:"12px" }} id="School">
                  Add a Keyword or Description
                </span>
                <textarea
                  style={{"border":"0","backgroundColor":"#DFE4E8","height":"80px","marginBottom":"5px","marginRight":"10px","marginLeft":"10px","paddingLeft":"10px","width":"200px","display":"flex","flexDirection":"row","alignItems":"center"}}
                value={this.state.description}
                onChange={(event) => {
                  this.setState({ description: event.target.value });
                }}
                placeholder="Description"
              >
                {this.state.description}
              </textarea>
              </FormControl>
            </div>
          </div>
          <div className="col-2">
            <div
              style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
                marginTop:"30px"
              }}
            >
{
  this.state.showLoader == true ? (
   <div style={{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginTop:"10px"
   }} >
   <CircularProgress 
                       color="inherit"
                          size={20}
                       />
   </div>
  )  : (
    <button onClick={this.getMCQ} style={{"backgroundColor":"#855AD8","borderRadius":"10px","color":"white","width":"230px","border":"none","height":"40px","margin":"10px",fontSize:"12px"}} >
            Generate mcqs
          </button>
  )
}


            </div>
          </div>
        </div>
        {
          this.state.checkForm == true ? (
            <p  style={{
              color:"red"
            }} > fill the form carefully</p>
          ) : null
                  }
        <br />
        <br />

        <h3 style={{backgroundColor:"#DFE4E8",color:"#855AD8",padding:"5px"}} >AI Response</h3>
        <hr />
        <br />
        <br />

        {
          this.state.showLoader == true ? (

              <div style={{
                marginLeft:"50%"
              }} >
                <CircularProgress 
                       color="inherit"
                          size={20}
                       />
                  </div>
         
          ) : (this.showMCQ())
        }
      </>
    );
  }
}