import http from "./../common/axios";

export default class HMService {
  getMySchool = () => {
    return http.get("/get_my_school/");
  }

  createSchoolDetails = (data) => {
    return http.post("/create_school_details/", data);
  }

  addSMCDetails = (data) => {
    return http.post("/create_smc_detail/", data);
  }

  editSchoolDetail = (data, details_id) => {
    return http.patch(`/update_school_details/${details_id}/`, data);
  }

  getSMCDetails = (SMC_id) => {
    return http.get(`get_smc_detail/${SMC_id}/`);
  }

  getSchoolDetails = (details_id) => {
    return http.get(`get_school_details/${details_id}/`);
  }

  uploadSchoolImages = (data) => {
    return http.post("/upload_school_images/", data);
  }

  updateSMCDetails = (data, SMC_id) => {
    return http.patch(`/update_smc_detail/${SMC_id}/`, data);
  }

  uploadHMUserByCsv = (data) =>{
    return http.post('/update_school_by_csv/',data);
  }
  getSMCDetails = (details_id) => {
    return http.get(`get_school_details/${details_id}/`);
  }

  getManagementDetails = (details_id) => {
    return http.get(`get_smc_detail/${details_id}/`);
  }

  deleteSMCMeeting = (details_id) => {
    return http.delete(`delete_smc_meeting/${details_id}/`);
  }

  deleteSMCMember = (details_id) => {
    return http.delete(`delete_smc_member/${details_id}/`);
  }

  updateSMCMember = (details) => {
    return http.patch(`update_smc_detail/1/`,details);
  }

  getVersionName = (Url) => {
    return http.get(`${Url}`);
  }

  getSMCFormDetails = (id) => {
    return http.get(`/get_school_details/${id}/`);
  }

  createSMCForm = (data) => {
    return http.post("/create_school_details/", data);
  }

  createSMCExtraDetails = (data) => {
    return http.post("/create_other_school_details/", data);
  }

  createSMCMeetingsForm = (data) => {
    return http.post("/create_smc_detail/", data);
  }

  getSMCExtraDetails = (id) => {
    return http.get(`/get_other_school_details/${id}/`);
  }

  getVersionWiseDetails = (id,version) => {
    return http.get(`/get_school_details/${id}/?school_detail_id=${version}`);
  }

  getSMCFormDetailsForDraft = (id) => {
    return http.get(`/get_school_details/${id}/?draft_data=true`);
  }

  createFLNStudnetList = (param) => {
    return http.post(`/fln_student/upload_fln_students/`,param);
  }

  createFLNTeacherList = (param) => {
    return http.post(`/fln_teacher/`,param);
  }

  getFLNStatus = (data) => {
    return http.get(`/fln_req_status/?limit=10&&offset=${data}`);
  }

  getStudentListDetails = (id) => {
    return http.get(id);
  }
  getSchoolWiseStudentListDetails = (id,data) => {
    return http.get(`/fln_student/school_wise_summery/?school=${id}&&limit=10&&offset=${data}`);
  }

  getTeacherListDetails = (schoolId,id) => {
    return http.get(`/fln_teacher/?school=${schoolId}&&limit=10&&offset=${id}`);
  }
  deleteStudent = (id,data) => {
    return http.delete(`/fln_student/${data}?school=${id}`);
  }
  editStudent = (id,data,param) => {
    return http.patch(`/fln_student/${id}/?school=${data}`,param);
  }

  deleteTeacher = (data) => {
    return http.delete(`/fln_teacher/${data}/`);
  }
  editTeacher = (param,data) => {
    return http.patch(`/fln_teacher/${data}/`,param);
  }

  createFLNStudnet = (param) => {
    return http.post(`/fln_student/`,param);
  }

  getPDFUrl = (param) => {
    return http.get(`/get_school_details_pdf/${param}/`);
  }

  getSubjectList = (param) => {
    return http.get(`/fln_subject/?student_district_id=${param.student_district_id}`);
  }

  getAnyltics = (param) => {
    return http.post(`/fln_data/fln_analytics/`,param);
  }

  getYear = () => {
    return http.get(`fln_student/month_year_list?year=${true}`,);
  }

  getMonths = (param) => {
    return http.get(`fln_student/month_year_list?month=true&received_year=${param}`,);
  
  }
  
  getTrendlineAnalytics = (param) => {
    return http.post(`fln_data/fln_analytics_trend_lines/ `,param);
  
  }
  getTrendlineAnalyticsForCSV = (param) => {
    return http.post(`fln_data/fln_analytics_trend_lines/ `,param,{responseType:'arraybuffer'});
  
  }
  getCompareAnalytics = (param) => {
    return http.post(`fln_data/fln_analytics_all_region/`,param);
  
  }
  getYearHm = () => {
    return http.get(`get_quarter_and_years/`);
  
  }
  getHalfValues = (param) => {
    return http.get(`get_quarter_and_years/?fin_year=${param}`);
  
  }
  getSchoolDetailsHm = (param) => {
    return http.get(`get_quarter_details/?fin_year=${param.year}&school=${param.school}&quarter=${param.quarter}`);
  
  }

  flnTransferTeacher = (param) => {
    return http.post(`fln_teacher/fln_teacher_school_transfer/`,param);
  }

  getHeadmsterDetails = (schoolId) => {
    return http.get(`get_headmaster_details/?school_id=${schoolId}`);
  }

  getLoAnalysisChalu = (url) => {
    return http.get(url);
  }

  getLoSubject = (url) => {
    return http.get(url);
  }

  getLoMonths = (param)=>{
    return http.get(`lo_months/?limit=12&selected_year=${param}`);
  } 

  getLoTrendlineAnalytics = (url) => {
    return http.get(url);
  
  }

  getCompareLOAnalytics = (param) => {
    return http.post(`lo_data_analytics/lo_analytics_all_region/`,param);
  
  }
  
}
