import http from "./../common/axios";

export default class SharedService {
  getContentFromUrl = (data) => {
    return fetch(data.content, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json",   "X-Requested-With": "XMLHttpRequest" }),
    });
  };

  getMCQFromUrl = (data) => {
    console.log(data,"getMCQFromUrl")
    return fetch(data.question_url, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json",   "X-Requested-With": "XMLHttpRequest" }),
    });
  };
  getChapterPreview(data) {
    return http.post(`/chapterPreview/`, data);
  }

  getChapterPartContent(data) {
    return http.post(`/getPartContent/`, data);
  }

  getChapterPartContentNew(data) {
    return http.post(`v2/getpartcontent/`, data).then(async (result) => {
      console.log(result, "result ---");
      if (result && result.data) {
        let { response } = result.data;
        const chapterContent = await this.getContentFromUrl(response);
        const contentTrans = await chapterContent.json();
        response.content = contentTrans;
        result.data.response = response;
      }
      return result;
    });
  }

  deleteChapter(data) {
    return http.put("/deleteChapter/", data);
  }

  deleteChapterPart(data) {
    return http.put("/deleteChapterPart/", data);
  }

  addSubject(data) {
    return http.post("/createSubject/", data);
  }

  updateChapterName(data) {
    return http.put("/updateChapter/", data);
  }

  updateChapterPart(data) {
    return http.put("/updateChapterPart/", data);
  }

  getChapterList(data){
    return http.post("/getAllChapters/",data);
  }

  getCWChapterList(data){
    return http.post("/cwchapterlist/",data);
  }
  
  deleteComment(data){
    return http.post("/delete_comment/",data);
  }
}
