import http from "./../common/axios";

export default class AdministrativeService {

    getAdminUserDetails() {
        return http.get(`/get_admin_user_details/`);
      }
    
     getDataEntryReport(param) {
        return http.post(`/fln_data/district_data_entry_summery/`,param);
      } 

     getCSVDataEntryReport(param) {
        return http.post(`/fln_data/district_data_entry_csv/`,param);
      }

    getTrendLineDataReport(param) {
        return http.post(`/fln_data/view_trend_analytics/`,param);
      } 
     
    getSummaryGraphData(param) {
        return http.post(`/fln_data/view_summary_graph/`,param);
      } 
    getSummaryForComapreBlockGraph(param) {
        return http.post(`/fln_data/compare_block_or_kendra_wise/`,param);
      } 
    getSummaryForComapreSchoolGraph(param) {
        return http.post(`/fln_data/compare_block_or_kendra_wise/`,param);
      }   
    getSummaryForComapreKendraGraph(param) {
        return http.post(`/fln_data/compare_block_or_kendra_wise/`,param);
      } 
      
    getSMCDataPoints(param) {
        return http.post(`/get_smc_data_point/`,param);
      }  
    
    getSMCStudentDataPoints(param) {
        return http.post(`/get_smc_data_students/`,param);
      }
    
     getSMCMembersDataPoints(param) {
        return http.post(`/get_smc_data_smc/`,param);
      } 

      getScholershipPoints(param) {
        return http.post(`/get_smc_data_scholarship/`,param);
      }  

      getFaciltiesPoints(param) {
        return http.post(`/get_smc_data_facilities/`,param);
      }  

      getExtraFaciltiesPoints(param) {
        return http.post(`/get_smc_data_meal_water_uniform/`,param);
      }

      compareBlockKendraSchoolAdmin(param) {
        return http.post(`/lo_data_analytics/compare_lo_block_kendra_or_school_wise/`,param);
      }  

      getLODataEntryReport(param) {
        return http.get(param);
      } 

     getLOCSVDataEntryReport(param) {
        return http.post(`/lo_data_analytics/lo_district_or_block_level_report/`,param);
      }
}