"use client";
import React, { useEffect, useState } from "react";
import "../Nudge.css";
import { useHistory } from "react-router-dom";
import Sidebar from "../icons/Sidebar-d.png";

function NudgeNavbar({ page, pageLink, path, setPage }) {
  const history = useHistory();

  return (
    <>
      <div className="d-flex py-3 topBar">
        <div>
          <img
            className="mt-1"
            src={Sidebar}
            alt="Sidebar"
            width={20}
            height={20}
          />
        </div>
        <div className="pt-1 ps-3">
          <span className="Regular14">admin / </span>
          <span className="Regular14 colorPrimaryBlack">{path}</span>
        </div>
      </div>
      <hr className="m-0"></hr>
      <div className="d-flex gap-4 my-4 borderRadious16">
        <div>
          <span
            className={`${
              page === "ongoing" ? "navbarSelected" : "Regular14"
            } pointerArrow`}
            onClick={() => {
              setPage("ongoing");
              history.push("/admin/nudge", { coming: "ongoing" });
            }}
          >
            Active Nudge
          </span>
        </div>
        <div>
          <span
            className={`${
              page === "disabled" ? "navbarSelected" : "Regular14"
            } pointerArrow`}
            onClick={() => {
              setPage("disabled");
              history.push("/admin/nudge", { coming: "disabled" });
            }}
          >
            Inactive Nudge
          </span>
        </div>
        <div>
          <span
            className={`${
              page === "create" || page === "edit"
                ? "navbarSelected"
                : "Regular14"
            } pointerArrow`}
            onClick={() => {
              if (page !== "edit") {
                history.push("/admin/nudges/create-nudge");
              }
            }}
          >
            {pageLink}
          </span>
        </div>
      </div>
    </>
  );
}

export default NudgeNavbar;
