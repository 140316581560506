import React from "react";
import classes from "./Login.module.css";
import Form from "./Form/Form";

export default class Login extends React.Component {
  render() {
    return (
      <div className={classes.container}>
        <div className={classes.image}></div>
        <div className={classes.form_container}>
          <Form {...this.props}/>
        </div>
      </div>
    );
  }
}
