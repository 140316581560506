import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "./styles.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "./../../components/Snackbar/Snackbar";
import { getSuperAdminService } from './../../service/service';
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import  {  useEffect } from 'react';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialog from "components/AlertDialog/AlertDialog";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { ListItemIcon } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Notifications() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [heading, setHeading] = React.useState("");
  const [content, setContent] = React.useState("");
  const [url, setURL] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [validations, setValidations] = React.useState({
    heading: {
      valid: false,
      touched: false,
    },
    content: {
      valid: false,
      touched: false,
    },
  });
  const [notification, setNotification] = React.useState([]);
  const [showDeleteAlert, setshowDeleteAlert] = React.useState(false);
  const [notificationId, setnotificationId] = React.useState("");
  const [expand, setExpand] = React.useState(false);
  const [Allnotification, setAllNotification] = React.useState(false);
  const [expandId, setExpandId] = React.useState("");
   useEffect(() => {
    getNotification()
   }, [])
  const handleInputClick = (input) => {
    let updatedValidations = { ...validations };
    updatedValidations[input].touched = true;
    setValidations(updatedValidations);
  };

  const handleInputChange = (e) => {
    const input = e.target.name;
    const value = e.target.value;
    let updatedValidations = { ...validations };
    if (input == "url") {
      setURL(value);
      return;
    }
    input == "heading" ? setHeading(value) : setContent(value);
    updatedValidations[input].touched = true;
    updatedValidations[input].valid = e.target.value.length > 0 ? true : false;
    setValidations(updatedValidations);
  };

  const handleSendNotification = () => {
    setLoading(true);
    const param = {
      heading,
      content,
      url,
    };
    getSuperAdminService()
      .sendNotificationAll(param)
      .then((result) => {
        result = result.data;
        setLoading(false);
        if (result && result.status == 200) {
          setError(false);
          setMessage("Notification sent successfully")
          setContent("");
          setHeading("");
          setURL("")
        } else {
          setError(true);
          setMessage("Error! Failed to send notification")
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setMessage("Something has went wrong")
      });
  };
 const getNotification = async () =>{
   let param={
    order_column:"created_at",
    order_type: 1,
    page_number: 1,
    record_per_page:10
}
    await getSuperAdminService().getAllNotification(param).then((result) => {
      result = result.data
      //console.log(result.response.notificationDetails,"noti");
      if(result && result.status == 200){
         let data =  result.response.notificationDetails
        console.log(data);
        setNotification(data)
        console.log(notification,"no");
      }
      console.log(notification,"no");
    })
 }
const toggleData = ()=>{
  console.log(expand,!expand,"da");
  setExpand(!expand)
}
 const deleteNotification = (id) =>{
   
  // let param = {
  //   notification_id : notificationId ? notificationId : ""
  // }
  let idArray=[]
  if(Allnotification == true)
  {
    notification.map((data)=>{
idArray.push(data.notification_id)
    })
  }
  else{
    idArray.push(notificationId)
  }
let param={
  notification_ids : idArray
}
  console.log(idArray,"no");
    getSuperAdminService().deleteNotification(param)
    .then((result) => {
      result = result.data;
      setLoading(false);
      if (result && result.status == 200) {
        setError(false);
        setMessage("Notification delete successfully")
       
      } else {
        setError(true);
        setMessage("Error! Failed to delete notification")
      }
    })
    .catch((err) => {
      setLoading(false);
      setError(true);
      setMessage("Something has went wrong")
    });
   console.log(notification,"4444444444")
  }
  return (
    <GridContainer>
       <GridItem xs={12} sm={12} md={12}>
      <Snackbar
        autoHideDuration={3000}
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => setMessage("")}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>
      <AlertDialog
          open={showDeleteAlert}
          title="Delete?"
          text="Do you want to delete this Notification?"
          onCancel={ ()=>
            setshowDeleteAlert(false)
          }
          onConfirm={()=>
            {
              setshowDeleteAlert(false)
              deleteNotification()
            }
          }
        />
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Manage Notifications</h4>
          <p className={classes.cardCategoryWhite}>
            Send push notification to application users.
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div className={classes.formContainer}>
                <div className={classes.spacer}></div>
                <TextField
                  id="standard-basic"
                  label="Title"
                  name="heading"
                  error={
                    validations.heading.touched && !validations.heading.valid
                  }
                  onClick={() => handleInputClick("heading")}
                  onChange={(e) => handleInputChange(e)}
                  helperText={
                    validations.heading.touched && !validations.heading.valid
                      ? "Title is required"
                      : null
                  }
                />
                <div className={classes.spacer}></div>
                <TextField
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={2}
                  name="content"
                  error={
                    validations.content.touched && !validations.content.valid
                  }
                  onClick={() => handleInputClick("content")}
                  onChange={(e) => handleInputChange(e)}
                  helperText={
                    validations.content.touched && !validations.content.valid
                      ? "Message is required"
                      : null
                  }
                />
                <div className={classes.spacer}></div>
                <TextField
                  id="standard-basic"
                  label="URL (optional)"
                  name="url"
                  onChange={(e) => handleInputChange(e)}
                />
                <div className={classes.spacer}></div>
                <div className={classes.btnWrapper}>
                  <div className={classes.spacer}></div>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendNotification()}
                      disabled={!validations.heading.valid || !validations.content.valid }
                    >
                      Send
                    </Button>
                  )}
                </div>
                <div className={classes.spacer}></div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
            <Card>
              <CardBody>
                <div className={classes.demo}>
                <Typography variant="h6" className={classes.title}>
                  Notification List
                  
                </Typography>
                <Button
                color="primary"
                variant="contained"
                   onClick={()=>
                    { setshowDeleteAlert(true)
                     //console.log(notification.notification_id,"id")
                     setAllNotification(true)
                   }}
                   >Clear All</Button>
                {console.log(notification,"no")}
                <div className={classes.demo} style={{ maxHeight:"80vh", overflow:"auto"}} >
                  <List dense={true}>
                    {notification.map((notification) => {
                      console.log(notification,"--==")
                      return (
                        <>
                        <ListItem 
                        // style={{ padding:"2px"}}
                        button
                         key={notification.notification_id}
                         onClick={toggleData} >
                          <ListItemText primary={notification.heading}  style={{borderBottom:"1px solid #4d73fa ", height:"3em",}} />
                            {expand == true && expandId == notification.notification_id? (
                                <ExpandLess />
                                 ) : (
                                <ExpandMore 
                                onClick={()=>{
                                  setExpandId(notification.notification_id)
                                  console.log(notification.notification_id,"notification.notification_id");
                                }}/>
                                )}
                        </ListItem>
                       {expandId == notification.notification_id ? <Collapse
                        in={expand == true}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem  className={classes.nested}>
                            
                           <ListItemText primary="content :"  />
                            <ListItemText primary={notification.content} />
                            {/* <ListItemText primary="URL :"  />
                            <ListItemText primary={notification.url} /> */}
                            <ListItemIcon
                            style={{cursor:"pointer"}}
                                button
                                  edge="end"
                                  aria-label="delete"
                                  onClick={()=>
                                   { setshowDeleteAlert(true)
                                    //console.log(notification.notification_id,"id")
                                    setnotificationId(notification.notification_id)
                                  }}
                                >
                                  <DeleteIcon />
                                </ListItemIcon>
                          </ListItem>
                          <ListItem>
                          <ListItemText primary="URL :"  />
                            <ListItemText primary={notification.url} />
                          </ListItem>
                          
                        </List>
                      </Collapse> : ""}
                      </>
                      );
                    })}
                  </List>
                  {notification.length == 0 && (
                    <span>There are no notification found here.</span>
                  )}
                </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
    </GridContainer>
  );
}
