"use client";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import "./Challenges.css";
import { getSuperAdminService } from 'service/service';
import { getContentWriterService } from 'service/service';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.js";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, CircularProgress, Modal } from '@material-ui/core';
import { EditRounded } from "@material-ui/icons";
import SortIcon from '@material-ui/icons/Sort';
import AlertDialog from 'components/AlertDialog/AlertDialog';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import Snackbar from "components/Snackbar/Snackbar";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { TbArrowsSort } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { BsCheck } from "react-icons/bs";
import { IoCheckmarkSharp } from "react-icons/io5";

function Challenges() {
  //filter{}
  const [state_id, setState_id] = useState('');
  const [stateList, setStateList] = useState([]);
  const [district_id, setDistrict_id] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [student_block_id, setStudent_block_id] = useState('');
  const [blockList, setBlockList] = useState([]);
  const [syllabus_id, setSyllabus_id] = useState(3);
  const [syllabusList, setSyllabusList] = useState([]);
  const [medium_id, setMedium_id] = useState('');
  const [mediumList, setMediumList] = useState([]);
  const [class_id, setClass_id] = useState('');
  const [classList, setClassList] = useState([]);
  const [challengeStatus, setChallengeStatus] = useState('')
  //extras
  const [allCreatedChallengeList, setAllCreatedChallengeList] = useState([])
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [challengeToBeDeleted_id, setChallengeToBeDeleted_id] = useState('')
  const [filterStringGlobal, setFilterStringGlobal] = useState('')
  const [orderByCreatedDate, setOrderByCreatedDate] = useState(true);
  const [orderByCreated, setOrderByCreated] = useState(false);
  const [orderByUpdated, setOrderByUpdated] = useState(false);
  const [totalOrderCount, setTotalOrderCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  //snackbar
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)

  const [challengeLoader, setChallengeLoader] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [showSort, setShowSort] = useState(false)
  const statusList = ['DRAFT', 'PUBLISHED', 'ACTIVE', 'EXPIRED']
  // sort
  const [sortBy, setSortBy] = useState('created_at')
  const [sortOrder, setSortOrder] = useState('-')

  const showFilterToggler = () => {
    setShowFilter(prevState => !prevState)
  }

  const showSortOptionsToggler = () => {
    setShowSort(prevState => !prevState)
  }

  async function getData() {
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      setStateList(stateRes.response)
    }
  }

  async function getDistrictsByState(state_id) {
    let response = await getSuperAdminService().getStudentDistrict({ student_state_id: state_id, })
    response = response.data;
    if (response && response.status == 200) {
      setDistrictList(response.response)
    }
  }

  async function getStudentBlock(district_id) {
    try {
      let response = await getSuperAdminService().getStudentBlock({ student_district_id: district_id, })
      response = response.data;
      if (response && response.status == 200) {
        setBlockList(response.response)
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  async function getSyllabus() {
    let param = {
      board_id: 2,
    };
    let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(param);
    setSyllabusList(CWDistrictRes.data.response)
  };

  async function getMedium() {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    setMediumList(mediumRes.data.response)
  };

  async function getClass() {
    let param = {
      district_id: syllabus_id,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(param)
    setClassList(classRes.data.response)
  };

  const getAllCreatedChallenge = async (filterString) => {
    try {
      setChallengeLoader(true)
      const response = await getSuperAdminService().getAllCreatedChallengeData(filterString)
      setAllCreatedChallengeList(response.data.results)
      setTotalOrderCount(response.data.count)
      setChallengeLoader(false)
    } catch (error) {
      setChallengeLoader(false)
      console.error('Error fetching challenge list', error)
    }
  }

  const handleSetSortOrder = (value) => {
    setSortOrder(value)
  }

  const handleSetSortBy = (value) => {
    setSortBy(value)
  }

  const handleDeleteChallenge = async (challenge_id) => {
    try {
      let response = await getSuperAdminService().deleteChallenge(challenge_id)
      setError(false)
      setMessage("Challenge deleted successfully")
      await getAllCreatedChallenge(filterStringGlobal)
    } catch (error) {
      setError(true)
      setMessage('Error delete challenge')
      console.error('Error delete challenge', error)
    }
  }

  const handlePublishChallenge = async (challenge_id) => {
    try {
      let response = await getSuperAdminService().publishChallenge(challenge_id)
      if (response.data.status === 200) {
        setError(false)
        setMessage("Challenge published successfully")
        await getAllCreatedChallenge(filterStringGlobal)
      }
      if (response.data.status !== 200) {
        setError(true)
        setMessage(response.data.response)
        console.error('Error publish challenge', error)
      }
    } catch (error) {
      setError(true)
      setMessage('Error publish challenge')
      console.error('Error publish challenge', error)
    }
  }

  const toggleOrderByCreated = () => {
    setOrderByCreated(prevState => !prevState)
    setOrderByUpdated(false)
  }

  const toggleOrderByUpdated = () => {
    setOrderByUpdated(prevState => !prevState)
    setOrderByCreated(false)
  }

  useEffect(() => {
    getData()
    getMedium()
    getSyllabus()
  }, [])

  useEffect(() => {
    let filterString = ''
    if (state_id) {
      filterString += `student_state_id=${state_id}&`
    }
    if (district_id) {
      filterString += `student_district_id=${district_id}&`
    }
    if (student_block_id) {
      filterString += `student_block_id=${student_block_id}&`
    }
    if (syllabus_id) {
      filterString += `district_id=${syllabus_id}&`
    }
    if (medium_id) {
      filterString += `medium_id=${medium_id}&`
    }
    if (class_id) {
      filterString += `class_id=${class_id}&`
    }
    if (challengeStatus) {
      filterString += `status=${challengeStatus}&`
    }
    setFilterStringGlobal(filterString)

    let orderingParam = sortOrder + sortBy;

    setFilterStringGlobal(`${filterString}${orderingParam ? 'ordering=' + orderingParam + '&' : ''}limit=${limit}&offset=${offset}`)
    getAllCreatedChallenge(`${filterString}${orderingParam ? 'ordering=' + orderingParam + '&' : ''}limit=${limit}&offset=${offset}`)

  }, [state_id, district_id, student_block_id, syllabus_id, medium_id, class_id, challengeStatus, orderByCreatedDate, orderByCreated, orderByUpdated, offset, limit, sortOrder, sortBy])


  useEffect(() => {
    setDistrict_id('')
    setDistrictList([])
    if (state_id) {
      getDistrictsByState(state_id)
    }
    setStudent_block_id('')
    setBlockList([])
  }, [state_id]);

  useEffect(() => {
    setStudent_block_id('')
    setBlockList([])
    if (district_id) {
      getStudentBlock(district_id)
    }
  }, [district_id]);

  useEffect(() => {
    setClass_id('')
    setClassList([])
    if (syllabus_id) {
      getClass()
    }
  }, [syllabus_id]);

  return (
    <>
      <AlertDialog
        open={showDeleteAlert}
        title="Delete challenge"
        text="Are you sure you want to delete this challenge?"
        onCancel={() => { setShowDeleteAlert(false) }}
        onConfirm={() => {
          handleDeleteChallenge(challengeToBeDeleted_id)
          setShowDeleteAlert(false)
        }}
      />
      <Snackbar
        autoHideDuration={5000} mess
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => {
          setMessage("")
        }}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>

      <div className='challengeListingPage mb-5 pb-4'
       onClick={()=>{
        if(showSort == true ){
          setShowSort(false)
        }
       }}
        >
        <div className='d-flex justify-content-between ps-2 pe-3'>
          <div className='challengePageTitle'>
            Challenges
          </div>
          <div>
            <Link to="/admin/challenge/create-challenge">
              <Button
                className="createChallengeBtn"
                color="primary"
              >
                Create Challenge
              </Button>
            </Link>
          </div>
        </div>
        <div className="row challengeListFilterDivV2">
          <div className='arrowPointer d-flex justify-content-between' onClick={showFilterToggler}><h5 className='color8A'>Filter challenge list</h5><div className='py-2 px-3 rounded bg-light'>{showFilter ? <FaChevronUp /> : <FaChevronDown />}</div></div>
          <div className={`${showFilter ? "" : "d-none"} row`}>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Select State
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={state_id}
                  onChange={(e) => {
                    setState_id(e.target.value)
                  }}
                >
                  {stateList.map((state, index) => {
                    return (
                      <MenuItem key={index + "state"} value={state.state_id}>
                        {`${state.state_name}`.toString().charAt(0).toUpperCase() + `${state.state_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Select District
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={district_id}
                  onChange={(e) => {
                    setDistrict_id(e.target.value)
                  }}
                >
                  {districtList.map((district, index) => {
                    return (
                      <MenuItem key={index + "district"} value={district.student_district_id}>
                        {`${district.district_name}`.toString().charAt(0).toUpperCase() + `${district.district_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Select Block
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={student_block_id}
                  onChange={(e) => {
                    setStudent_block_id(e.target.value)
                  }}
                >
                  {blockList.map((block, index) => {
                    return (
                      <MenuItem key={index + "block"} value={block.student_block_id}>
                        {`${block.block_name}`.toString().charAt(0).toUpperCase() + `${block.block_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Medium
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={medium_id}
                  onChange={(e) => {
                    setMedium_id(e.target.value)
                  }}
                >
                  {mediumList.map((medium, index) => {
                    return (
                      <MenuItem key={index + "medium"} value={medium.medium_id}>
                        {`${medium.medium_name}`.toString().charAt(0).toUpperCase() + `${medium.medium_name}`.slice(1)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Class
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={class_id}
                  onChange={(e) => {
                    setClass_id(e.target.value)
                  }}
                >
                  {classList.map((cls, index) => {
                    return (
                      <MenuItem key={index + "class"} value={cls.class_id}>
                        {cls.class_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-4 inputWrapper">
              <FormControl className="dropdown width28" >
                <InputLabel id="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={challengeStatus}
                  onChange={(e) => {
                    setChallengeStatus(e.target.value)
                  }}
                >
                  {statusList.map((status, index) => {
                    return (
                      <MenuItem key={index + "status"} value={status}>
                        {status}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className='d-flex justify-content-end pe-5 pt-5'>
              <button type="button" className="btn btn-outline-dark px-4" onClick={
                () => {
                  setState_id('')
                  setDistrict_id('')
                  setStudent_block_id('')
                  setSyllabus_id(3)
                  setMedium_id('')
                  setClass_id('')
                  setChallengeStatus('')
                }
              }>RESET</button>
            </div>
          </div>
        </div>


        <div className='challengeListDiv'>
          <div className='d-flex justify-content-between'>
            <div className='pt-2 d-flex justify-content-between col-12 challengeListSortTitle' >
              <span className='challengePageTitle'>
                Challenge list
              </span>
              <div className={`${showSort ? 'd-none' : ""} py-2 px-3 bg-light rounded arrowPointer`} onClick={showSortOptionsToggler} >
                <TbArrowsSort />
              </div>
              <div className={`${showSort ? '' : "d-none"} challengeSort rounded`}>
                <div className='pb-3 bg-light rounded'>
                  <div className='crossBtn' onClick={showSortOptionsToggler} >
                    <RxCross2 />
                  </div>
                  <div className='px-4 py-2 rounded sortElement d-flex' onClick={() => { handleSetSortBy('start_date') }}>
                    <div>
                      <span>Start date</span>
                    </div>
                    <div className={`${sortBy === 'start_date' ? '' : 'd-none'} ps-2`}>
                      <IoCheckmarkSharp />
                    </div>
                  </div>
                  <div className='px-4 py-2 rounded sortElement d-flex' onClick={() => { handleSetSortBy('end_date') }}>
                    <div>
                      <span>End date</span>
                    </div>
                    <div className={`${sortBy === 'end_date' ? '' : 'd-none'} ps-2`}>
                      <IoCheckmarkSharp />
                    </div>
                  </div>
                  <div className='px-4 py-2 rounded sortElement d-flex' onClick={() => { handleSetSortBy('created_at') }}>
                    <div>
                      <span>Created date</span>
                    </div>
                    <div className={`${sortBy === 'created_at' ? '' : 'd-none'} ps-2`}>
                      <IoCheckmarkSharp />
                    </div>
                  </div>
                  <hr className=''>
                  </hr>
                  <div className='px-4 py-2 rounded sortElement d-flex' onClick={() => { handleSetSortOrder('') }}>
                    <div>
                      <span>Ascending</span>
                    </div>
                    <div className={`${sortOrder === '' ? '' : 'd-none'} ps-2`}>
                      <IoCheckmarkSharp />
                    </div>
                  </div>
                  <div className='px-4 py-2 rounded sortElement d-flex' onClick={() => { handleSetSortOrder('-') }}>
                    <div>
                      <span>Descending</span>
                    </div>
                    <div className={`${sortOrder === '-' ? '' : 'd-none'} ps-2`}>
                      <IoCheckmarkSharp />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4">
            <table className="table table-borderless table-hover">
              <thead className='backgroundColorGray'>
                <tr>
                  <th className='' scope="col">#</th>
                  <th className='w-25' scope="col">Title</th>
                  <th className='' scope="col">Status</th>
                  <th className='' scope="col">Class</th>
                  <th className='' scope="col">Medium</th>
                  <th className='' scope="col" >Start date</th>
                  <th className='' scope="col" >End date</th>
                  <th className='' scope="col">Sent</th>
                  <th className='' scope="col">Accepted</th>
                  <th className='' scope="col">Completed</th>
                  <th className='' scope="col">Action</th>
                </tr>
              </thead>
              {
                challengeLoader == true ? (
                  <div style={{
                    marginLeft: "800%",
                    marginTop: "200%",
                  }} >
                    <CircularProgress
                      color="blue"
                      size={50}
                    />
                  </div>

                ) : (
                  <>
                    <tbody>
                      {
                        allCreatedChallengeList.map((createdChallenge, index) => {
                          return (
                            <>
                              <tr key={index + "createdChallenge"}>
                                {/* <th scope="row">{offset + index + 1}</th> */}
                                <th scope="row">{createdChallenge.challenge_id}</th>
                                <td className='' >{createdChallenge.challenge_title}</td>
                                <td>
                                  <span className={
                                    `${createdChallenge.status === "DRAFT" ? "bgYellow" : ""}  
                               ${createdChallenge.status === "PUBLISHED" ? "bgBlue" : ""} 
                               ${createdChallenge.status === "ACTIVE" ? "bgGreen" : ""}
                               ${createdChallenge.status === "EXPIRED" ? "bgRed" : ""} 
                               p-1 rounded`} >
                                    {createdChallenge.status}
                                  </span>
                                </td>
                                <td className=''>
                                  <div>
                                    {createdChallenge.class_id ? classList.map((cls, index) => {
                                      if (cls.class_id === createdChallenge.class_id) {
                                        return (

                                          <div key={index + "class"}>
                                            {cls.class_name}
                                          </div>


                                        );
                                      }
                                    }) :
                                      <div></div>
                                    }

                                  </div>
                                </td>

                                <td className=''>
                                  <div>
                                    {createdChallenge.medium_id ? mediumList.map((medium, index) => {
                                      if (medium.medium_id === createdChallenge.medium_id) {
                                        return (
                                          <div key={index + "medium"}>
                                            {`${medium.medium_name.replace('Medium', '')}`}
                                          </div>
                                        );
                                      }
                                    }) :
                                      <div></div>
                                    }

                                  </div>
                                </td>

                                <td className=''>
                                  <div>
                                    {createdChallenge.start_date.substring(8, 10) +
                                      "-" +
                                      createdChallenge.start_date.substring(5, 7) +
                                      "-" +
                                      createdChallenge.start_date.substring(0, 4) 
                                    }
                                  </div>
                                </td>
                                <td className=''>
                                    {createdChallenge.end_date.substring(8, 10) +
                                      "-" +
                                      createdChallenge.end_date.substring(5, 7) +
                                      "-" +
                                      createdChallenge.end_date.substring(0, 4)
                                    }
                                </td>
                                <td className=''>
                                  <div>{createdChallenge.sent_count}</div>
                                </td>
                                <td className=''>
                                  <div>{createdChallenge.accepted_count}</div>
                                </td>
                                <td className=''>
                                  <div>{createdChallenge.completed_count}</div>
                                </td>
                                <td >
                                  <div className='d-flex'>
                                    <div className='editBtn'>
                                      <Link to={`/admin/challenge/edit-challenge/${createdChallenge._id}`}>
                                        <IconButton
                                          className={`${createdChallenge.status !== "EXPIRED" ? "" : "d-none"}`}
                                          onClick={() => {}}
                                        >
                                          <EditRounded color="primary" />
                                        </IconButton>
                                      </Link>
                                    </div>
                                    <IconButton
                                      className={`${createdChallenge.status === "DRAFT" ? "" : "d-none"}`}
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => {
                                        setChallengeToBeDeleted_id(createdChallenge._id)
                                        setShowDeleteAlert(true)
                                      }}
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                    <Button
                                      className={`${createdChallenge.status === "DRAFT" ? "" : "d-none"} ms-3 p-2`}
                                      color="primary"
                                      onClick={() => { handlePublishChallenge(createdChallenge._id) }}
                                    >
                                      PUBLISH
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        })
                      }
                    </tbody>
                  </>
                )
              }
            </table>
          </div>
        </div >

        <div className='d-flex justify-content-end pe-5 paginationDiv'>
          <select className="form-select form-select-sm rowLimit m-2" value={limit} onChange={(e) => { setLimit(parseInt(e.target.value, 10)) }} aria-label=".form-select-sm example">
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
          </select>

          <div className='bgBlue p-2 m-2 rounded' onClick={() => { setOffset(0) }}>
            <span><FirstPageIcon /></span>
          </div>

          <div className={`${(Math.ceil(offset / limit) + 1) === 1 ? "d-none" : ""} bgBlue p-2 m-2 rounded`} onClick={() => { setOffset(offset - limit) }}>
            <span><NavigateBeforeIcon /></span>
          </div>
          <div className='bgBlue p-2 m-2 rounded'>
            {/* <span>{Math.ceil(offset / limit) + 1}</span> */}
            {(Math.ceil(offset / limit) + 1) === Math.ceil(totalOrderCount / limit) ? <span>{offset + 1} - {totalOrderCount} of {totalOrderCount}</span> : <span>{offset + 1} - {offset + limit} of {totalOrderCount}</span>}
          </div>
          <div className={`${(Math.ceil(offset / limit) + 1) === Math.ceil(totalOrderCount / limit) ? "d-none" : ""} bgBlue p-2 m-2 rounded`} onClick={() => { setOffset(offset + limit) }}>
            <span><NavigateNextIcon /></span>
          </div>

          <div className='bgBlue p-2 m-2 rounded' onClick={() => { setOffset((Math.ceil(totalOrderCount / limit) - 1) * limit) }}>
            <span><LastPageIcon /></span>
          </div>
        </div>
      </div >
    </>
  );
}

export default Challenges;