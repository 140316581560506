import { Button, Card, FormHelperText, Input } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { getSuperAdminService } from "./../../service/service";
import {
    Field,
    FieldArray,
    reduxForm,
    formValueSelector,
    change,
  } from "redux-form";

export default class LiveSessions extends React.Component {
  state = {
    liveSessionOneDesc:"",
    liveSessionOneYoutube:"",
    liveSessionOneData:{},
    liveSessionOneCheck:false,
    liveSessionTwoDesc:"",
    liveSessionTwoYoutube:"",
    liveSessionTwoData:{},
    liveSessionTwoCheck:false,
    liveSessionNotice:"",
    liveSessionNoticeData:{},
  };
  componentDidMount() {
    this.getLiveSessionFirst();
    this.getLiveSessionSecond();
    this.getLiveSessionNotice();
  }

  getLiveSessionFirst = async ()=>{
    let data = await getSuperAdminService().getLivesessionFirst();
  
    let file = data.data;
    this.setState({
      liveSessionOneData:file
    });
  }

  getLiveSessionSecond=async ()=>{
    let data = await getSuperAdminService().getLivesessionSecond();
    let file = data.data;
    console.log(data, "data");
    this.setState({
      liveSessionTwoData:file
    });
  }

  getLiveSessionNotice = async ()=>{
    let data = await getSuperAdminService().getLiveSessionNotice();
    let file = data.data;
    console.log(data, "liveSessionNoticeData");
    this.setState({
      liveSessionNoticeData:file
    });
  }

uploadLiveSessionOne = async (e) =>{
  
    if(this.state.liveSessionOneYoutube != ""){
      const fd = new FormData();
      fd.append("youtube_link" , this.state.liveSessionOneYoutube)
      fd.append("description",this.state.liveSessionOneDesc)
      fd.append("service_name" , "live_session_1")
      getSuperAdminService().updateBackground(fd).
      then((data)=>{
        console.log("Data",data)
          if(data)
          {
            this.getLiveSessionFirst()
          }
      })
    }

    if(this.state.liveSessionOneYoutube == ""){
        this.setState({
            liveSessionOneCheck:true
        })
    }
//   getSuperAdminService().updateBackground(fd).
//   then((data)=>{
//       if(data)
//       {
//        this.getcurrentBackground();
//       }
//   })
   
 }
 uploadLiveSessionTwo = async (e) =>{
  
    if(this.state.liveSessionTwoYoutube != ""){
      const fd = new FormData();
      fd.append("youtube_link" , this.state.liveSessionTwoYoutube)
      fd.append("description" , this.state.liveSessionTwoDesc)
      fd.append("service_name" , "live_session_2")
      getSuperAdminService().updateBackground(fd).
      then((data)=>{
          if(data)
          {
            this.getLiveSessionSecond()
          }
      })
    }

    if(this.state.liveSessionTwoYoutube == ""){
        this.setState({
            liveSessionOneCheck:true
        })
    }
//   getSuperAdminService().updateBackground(fd).
//   then((data)=>{
//       if(data)
//       {
//        this.getcurrentBackground();
//       }
//   })
   
 }

 uploadLiveSessionNotice = async (e)=>{
  const fd = new FormData();
  fd.append("youtube_link" , " ")
  fd.append("description" , this.state.liveSessionNotice)
  fd.append("service_name" , "live_session_notice")
  getSuperAdminService().updateBackground(fd).
  then((data)=>{
      if(data)
      {
        this.getLiveSessionNotice()
      }
  })
 }


   getYTVideoID = (value) => {
    if (value) {
      let url = value.hasOwnProperty("url") ? value.url : value;
      url = url.toString().trim();
      var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      return match && match[1];
    } else {
      return;
    }
  };

  

  render() {
    return (
      <>
        <Card>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Live Session 1</h4>
          </CardHeader>
          <CardBody>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              current Live Session 1    
            </div>
            <br />

            <br />
            <div
            style={{ display: "flex", justifyContent: "center" }}
                  >
                    <iframe
                      width="600"
                      height="600"
                      src={`https://www.youtube.com/embed/${this.getYTVideoID(this.state.liveSessionOneData?.file)}`}
                    ></iframe>
                  </div>
                  <p style={{
                    marginTop:"30px"
                  }} >
                Description : {this.state.liveSessionOneData?.description}

                  </p>
            <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey"}}></h1>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Choose Live Session 1
            </div>
            <br />
            <br />
            <div>
            <div style={{display:"flex",alignItems:"center"}} >
            <FormHelperText style={{
                fontSize:"18px",
                marginRight:"65px"
            }} >
             Youtube Link : {" "}
            </FormHelperText>
            <input
              type="text"
              placeholder="Youtube link"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                height:"40px",
                marginLeft:"10px"
              }}
              value={this.state.liveSessionOneYoutube}
              onChange={(e)=>{
                this.setState({
                    liveSessionOneYoutube:e.target.value
                })
              }}
            />
            </div>
            {
                (this.state.liveSessionOneCheck == true &&  this.state.liveSessionOneYoutube == "") ? (
                    <p style={{color:"red"}} >*Required</p>
                ) :null
            }
            <div style={{display:"flex",alignItems:"center",marginTop:"20px"}} >
            <FormHelperText style={{
                fontSize:"18px",
                marginRight:"5px"
            }} >
             Youtube Description : {" "}
            </FormHelperText>
            <input
              type="text"
              placeholder="Description"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                height:"40px",
              }}
              value={this.state.liveSessionOneDesc}
              onChange={(e)=>{
                this.setState({
                    liveSessionOneDesc:e.target.value
                })
              }}
            />
            </div>
            
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"90%"}}
         
             onClick={()=>{
                 this.uploadLiveSessionOne()
             }}>Upload</Button>
            </div>
          </CardBody>
        </Card>

        <br />
        <br />

     
        <Card>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Live Session 2</h4>
          </CardHeader>
          <CardBody>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              current Live Session 2
            </div>
            <br />

            <br />
            <div
            style={{ display: "flex", justifyContent: "center" }}
                  >
                    <iframe
                      width="600"
                      height="600"
                      src={`https://www.youtube.com/embed/${this.getYTVideoID(this.state.liveSessionTwoData?.file)}`}
                    ></iframe>
                  </div>
                  <p style={{
                    marginTop:"30px"
                  }} >Description : {this.state.liveSessionTwoData?.description} </p>
            <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey"}}></h1>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Choose Live Session 2
            </div>
            <br />
            <br />
            <div>
            <div style={{display:"flex",alignItems:"center"}} >
            <FormHelperText style={{
                fontSize:"18px",
                marginRight:"65px"
            }} >
             Youtube Link : {" "}
            </FormHelperText>
            <input
              type="text"
              placeholder="Youtube link"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                height:"40px",
                marginLeft:"10px"
              }}
              value={this.state.liveSessionTwoYoutube}
              onChange={(e)=>{
                this.setState({
                    liveSessionTwoYoutube:e.target.value
                })
              }}
            />
            </div>
            {
                (this.state.liveSessionTwoCheck == true &&  this.state.liveSessionTwoYoutube == "") ? (
                    <p style={{color:"red"}} >*Required</p>
                ) :null
            }
            <div style={{display:"flex",alignItems:"center",marginTop:"20px"}} >
            <FormHelperText style={{
                fontSize:"18px",
                marginRight:"5px"
            }} >
             Youtube Description : {" "}
            </FormHelperText>
            <input
              type="text"
              placeholder="Description"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                height:"40px",
              }}
              value={this.state.liveSessionTwoDesc}
              onChange={(e)=>{
                this.setState({
                    liveSessionTwoDesc:e.target.value
                })
              }}
            />
            </div>
            
               <Button
               style={{ background:"#3f50b5" , color:"white", marginLeft:"90%"}}
         
             onClick={()=>{
                 this.uploadLiveSessionTwo()
             }}>Upload</Button>
            </div>
          </CardBody>
        </Card>
        
        <br />
        <br />

        <Card>
          <CardHeader style={{ marginTop: "auto" }} color="primary">
            <h4 className={""}> Notice</h4>
          </CardHeader>
          <CardBody>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              current Notice
            </div>
            <br />

            <br />
                  <p style={{
                    marginTop:"30px"
                  }} >Notice : {this.state.liveSessionNoticeData?.description} </p>
            <br />
            <br />
            <h1 style={{ borderBottom:"1px solid grey"}}></h1>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Select Notice
            </div>
            <br />
            <br />
            <div style={{ marginBottom:'70px'}}>
            <div style={{display:"flex",alignItems:"center",marginTop:"20px"}} >
            <FormHelperText style={{
                fontSize:"18px",
                marginRight:"40px"
            }} >
             Youtube Notice : 
            </FormHelperText>
            <input
              type="text"
              placeholder="Notice"
              style={{
                width: "50%",
                display: "flex",
                border: "1px solid black",
                height:"40px",
              }}
              value={this.state.liveSessionNotice}
              onChange={(e)=>{
                this.setState({
                  liveSessionNotice:e.target.value
                })
              }}
            />
            </div>
            
               <Button
              style={{ background:"#3f50b5" , color:"white", marginLeft:"90%",marginBottom:"70px"}}
         
             onClick={()=>{
                 this.uploadLiveSessionNotice()
             }}>Upload</Button>
            </div>
          </CardBody>
        </Card>

      </>
    );
  }

  
}