import axios from "axios";

import { Web_separate } from "common/config"
import  httpV2  from "../common/axiosV2"

export const chapterPreview = (id) =>{
 return  httpV2.get(`chapters/${id}`).
  then((res)=>{
    return res
  })
}
export const getChapterPartContents = (id) =>{
  return  httpV2.get(`contenturl/${id}`).
   then((res)=>{
     return res
   })
 }

 export const uploadNewMedia = (data) =>{
  return  httpV2.post(`media/`, data).
   then((res)=>{
     return res
   })
 }

 export const updateChapterNew = (id , data) =>{
  return  httpV2.patch(`chapters/${id}/`, data).
   then((res)=>{
     return res
   })
 }

 export const CheckChapterStatusNew = (data) =>{
  return  httpV2.post(`chapters/check_request_status/`, data).
   then((res)=>{
     return res
   })
 }

 export const getChapterlistNew = (data) =>{
  return  httpV2.get(`chapters/`,data).
   then((res)=>{
     return res
   })
 }

 export const deleteUpdateChapterPart = (data) =>{
  return  httpV2.patch(`chapters/part_update/`,data).
   then((res)=>{
     return res
   })
 }

 export const chapterDetailsUpdate = (id,data) =>{
  return  httpV2.patch(`chapters/${id}/chapter_update/ `,data).
   then((res)=>{
     return res
   })
 }

 export const getChapterStatus = () =>{
  return  httpV2.get(`create_update_status/ `).
   then((res)=>{
     return res
   })
 }

 export const CWChapterCreate = (data) =>{
  return  httpV2.post(`chapters/ `, data).
   then((res)=>{
     return res
   })
 }

 export const getNoOfChapters = (data) =>{
  return  httpV2.get(`chapters/total_chapter_numbers/ `).
   then((res)=>{
     return res
   })
 }

 export const getCategories = (data) =>{
  return  httpV2.get(`category/`).
   then((res)=>{
     return res
   })
 }

 export const createMCQ = (data) =>{
  return  httpV2.post(`mcq/`,data).
   then((res)=>{
     return res
   })
 }

 export const previewMCQ = (data) =>{
  return  httpV2.get(`mcq/`).
   then((res)=>{
     return res
   })
 }

 export const editQuiz = (data,id) =>{
  return  httpV2.patch(`mcq/${id}/`,data).
   then((res)=>{
     return res
   })
 }

 export const deleteQuiz = (id) =>{
  return  httpV2.delete(`mcq/${id}/`).
   then((res)=>{
     return res
   })
 }

 export const createLearningElement = (data) =>{
  return  httpV2.post(`learning_element/`,data).
   then((res)=>{
     return res
   })
 }

 export const getLearningElement = (data) =>{
  return  httpV2.get(`learning_element/?limit=10&offset=${data.offset}`,data).
   then((res)=>{
     return res
   })
 }

 export const updateLearningElement = (data,id) =>{
  return  httpV2.patch(`learning_element/${id}/`,data).
   then((res)=>{
     return res
   })
 }

 export const getMcqAiGenerated = (url) =>{
  return  httpV2.get(url).
   then((res)=>{
     return res
   })
 }

 export const addAIquestionsToDB = (data) =>{
  return  httpV2.post(`live_mcq_generation/set_live_mcq/`,data).
   then((res)=>{
     return res
   })
 }

 export const getAIQuestion = (data) =>{
  return  httpV2.post("passagebank/get_questions_from_passage/",data).
   then((res)=>{
     return res
   })
 }