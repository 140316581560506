import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import OtpInput from "react-otp-input";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";

import classes from "./OTP.module.css";
import {
  getHMService,
  getSuperAdminService,
  getAdministrativeService,
} from "./../../service/service";
import * as constants from "./../../common/constants";
import Snackbar from "./../../components/Snackbar/Snackbar";
import { withRouter } from "react-router-dom";

import logo from "./../../assets/img/vopa_logo.png";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Countdown from "react-countdown";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
const OTP = ({ history }) => {
  const [OTP, setOTP] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpMail, setOtpMail] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [responseOTP, setResponseOTP] = useState("");
  const [timershow, setTimershow] = useState(false);
  const [buttonshow, setButtonshow] = useState(true);
  if (!history.location.state) {
    history.push("/");
  }

  const verifyOTP = async () => {
    setIsError(false);
    setMessage("");
    setIsLoading(true);
    let result = await getSuperAdminService().loginVerifyToken({
      ...history.location.state,
      otp: OTP,
    });
    const { status, response: user } = result.data;
    const { mobile } = history.location.state;
    setIsLoading(false);
    if (status === 200) {
      localStorage.setItem("user", JSON.stringify({ ...user, mobile }));
      if (user.user_type === constants.USER_TYPE.CONTENT_WRITER) {
        return history.push("/user/dashboard");
      }
      if (user.user_type === constants.USER_TYPE.HEADMASTER) {
        let data = await getHMService().getMySchool();
        console.log(data, "async await");
        localStorage.setItem("HMSchool", JSON.stringify(data.data.response));
        //   getHMService()
        // .getMySchool()
        // .then((res) => {
        //   let data = res.data;
        //   if (data.status == 200) {
        //     console.log(data,"data44")
        //     if(data)
        //     {
        //       localStorage.setItem(
        //         "HMSchool",
        //         JSON.stringify(data.response)
        //       );
        //     }
        //      }
        // });
        return history.push("/user/dashboard");
      } else if (user.user_type === constants.USER_TYPE.ADMINISTRATIVE) {
        let data = await getAdministrativeService().getAdminUserDetails();
        console.log(data, "async await");
        localStorage.setItem("AMdetails", JSON.stringify(data.data));
        return history.push("/user/AMHome");
      } else if (user.user_type === constants.USER_TYPE.ADMIN) {
        return history.push("/admin/dashboard");
      } else {
        return history.push("/admin/dashboard");
      }
    } else {
      setIsError(true);
      setIsLoading(false);
      setOTP("");
      setMessage("Incorrect OTP entered. Try again");
    }
  };

  const handleOTPChange = (otp) => {
    setOTP(otp);
  };

  const handleClear = () => {
    setOTP("");
  };

  const handleResendOTP = async () => {
    setOtpMail(otpMail + 1);
    console.log(otpMail, "oto");
    if (otpMail > 1) {
      setOpenDialog(true);
    }
    setIsError(false);
    setMessage("");
    setIsLoading(true);

    const { mobile, user_type } = history.location.state;
    const payload = {
      mobile: mobile,
      user_type: user_type,
    };

    try {
      let result = await getSuperAdminService().loginUser(payload);
      const { status, response } = result.data;
      setIsLoading(false);
      if (status === 200) {
        setIsError(false);
        let emailShow = response.email;
        console.log(emailShow, ":");
        setResponseOTP(emailShow);
        console.log(responseOTP, "re");
        setMessage("OTP has been sent successfully");
      } else {
        setIsError(true);
        setMessage("Something has went wrong");
      }
    } catch (error) {
      setIsError(true);
      setMessage("Something has went wrong");
      setIsLoading(false);
    }
  };
  const otpOnMail = async () => {
    // setOpenDialog(false);
    setButtonshow(false);
    setTimershow(true);
    const { mobile, user_type } = history.location.state;
    const payload = {
      mobile: mobile,
      user_type: user_type,
      otp_on_email: true,
    };
    console.log(payload, "payload");
    try {
      let result = await getSuperAdminService().loginUser(payload);
      const { status, response } = result.data;
      setIsLoading(false);
      if (status === 200) {
        console.log(response, "re");
        setIsError(false);
        setMessage("OTP has been sent successfully to parents Email");
      } else {
        setIsError(true);
        setMessage("Something has went wrong");
      }
    } catch (error) {
      setIsError(true);
      setMessage("Something has went wrong");
      setIsLoading(false);
    }
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      setButtonshow(false);
      return (
        <>
          <div>
            <Button
              onClick={otpOnMail}
              style={{ marginLeft: "8em" }}
              color="primary"
              variant="contained"
            >
              Click to send
            </Button>
            <Button
              onClick={() => {
                setOpenDialog(false);
              }}
              style={{ marginLeft: "8em" }}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
          </div>
        </>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  const { mobile } = history.location.state;

  return (
    <Card variant="outlined">
      <div className={classes.container}>
        <div className={classes.image}></div>
        <div className={classes.form_container}>
          <div>
            <div className={classes.logoContainer}>
              <img src={logo} className={classes.logo} />
              <span className={classes.logoText}>V-SCHOOL</span>
              <span className={classes.logoTagLine}>
                Ensuring right to education
              </span>
            </div>
            <Dialog
              style={{}}
              onClose={() => {
                setOpenDialog(false);
              }}
              aria-labelledby="simple-dialog-title"
              open={openDialog}
            >
              <DialogTitle id="alert-dialog-title">
                {"We are sharing your OTP on your Email : "}
                {responseOTP}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {timershow == true ? (
                    <>
                      {/* <AccessAlarmsIcon/>  */}
                      <Countdown
                        renderer={renderer}
                        date={Date.now() + 12000}
                      ></Countdown>
                    </>
                  ) : null}
                </div>
              </DialogTitle>
              <DialogContent>
                {/* { timershow == true  ? 
         <Countdown 
         renderer={renderer}
          date={Date.now() + 120000}></Countdown> : null } */}
                {buttonshow == true ? (
                  <>
                    <div>
                      <Button
                        onClick={otpOnMail}
                        style={{ marginLeft: "8em" }}
                        color="primary"
                        variant="contained"
                      >
                        Click to send
                      </Button>
                      <Button
                        onClick={() => {
                          setOpenDialog(false);
                        }}
                        style={{ marginLeft: "8em" }}
                        color="primary"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : null}
              </DialogContent>
            </Dialog>
            <Snackbar
              autoHideDuration={3500}
              message={message}
              color={isError ? "danger" : "success"}
              close={false}
              place="br"
              closeNotification={() => setMessage("")}
              rtlActive={true}
              open={message ? true : false}
            ></Snackbar>
            {/* <Card className={classes.root} variant="outlined"> */}
            <div>
              <h5 className={`${classes.heading} ${classes.alignCenter}`}>
                Enter OTP code sent to your mobile number
              </h5>
              <span
                className={`${classes.alignCenter} ${classes.mobileNumber}`}
              >
                {mobile}
              </span>
              <div className={classes.input}>
                <OtpInput
                  value={OTP}
                  onChange={handleOTPChange}
                  numInputs={5}
                />
              </div>
              <p className={classes.resend}>
                <a onClick={handleResendOTP} style={{ color: "blue" }}>
                  Resend
                </a>
              </p>
              <div className={classes.controls}>
                {/* <Button
              disabled={OTP.length === 0 || isLoading}
              onClick={handleClear}
              className={classes.submitBtn}
              variant="contained"
              color="primary"
            >
              Clear
            </Button> */}
                {isLoading && <CircularProgress color="secondary" />}
                {!isLoading && (
                  <Button
                    disabled={OTP.length != 5 || isNaN(OTP)}
                    onClick={verifyOTP}
                    className={classes.submitBtn}
                    variant="contained"
                  >
                    Confirm
                  </Button>
                )}
              </div>
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default withRouter(OTP);
