import React, { isValidElement } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import * as constants from "./../../common/constants";
import {
  getContentWriterService,
  getSharedService,
  getSuperAdminService,
} from "../../service/service";
import Snackbar from "components/Snackbar/Snackbar";
import { Checkbox, CircularProgress, colors, FormControlLabel, Icon } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import { EditAttributes, EditRounded } from "@material-ui/icons";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
   cardBorder: {
    boxShadow:"none"

  }
};

const classes = styles;
export default class AddSubject extends React.Component {
  state = {
    chapterDetail: {},
    parts: [
      {
        part_name: "",
        tags: "",
      },
    ],
    chapter: {
      state_id: "",
      board_id: "",
      district_id: "",
      class_id: "",
      medium_id: "",
      subject_id: "",
      chapter_name: "",
      chapter_number: "",
      no_of_parts: "",
      parts: [
        {
          part_name: "",
          tags: "",
        },
      ],
    },
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    mediumList: [],
    subjectList: [],
    number_of_parts: 1,
    loading: true,
    inputErrors: {
      subject_name: {
        valid: false,
        touched: false,
      },
    },
    user_type: null,
    message: "",
    error: false,
    showDeleteAlert: false,
    subjectToDelete: null,
    subjectToEdit: null,
    currentTabIndex: 0,
    showPopup: false,
    modalOpen: false,
    editModal: false,
    checkBox1 : false,
    checkBox2 : false,
    checkBox3 : false,
    editSubName:null,
    editSubClass:null,
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState(
      {
        user_type: user.user_type,
      },
      () => {
        this.getData();
      }
    );
  }

  async getData() {
    let { chapter } = this.state;
    let stateRes;
    if (this.state.user_type == constants.USER_TYPE.SUPER_ADMIN) {
      stateRes = await getSuperAdminService().getAllState();
    } else {
      stateRes = await getContentWriterService().getAdminCWstates();
    }
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      chapter = { ...chapter, state_id: stateRes.response[0].state_id };
      this.setState({ stateList: stateRes.response, chapter }, async () => {
        let data = { state_id: this.state.chapter.state_id };
        let mediumRes = await getContentWriterService().getMediumbyState(data);
        mediumRes = mediumRes.data;
        if (mediumRes && mediumRes.status == 200) {
          chapter = { ...chapter, medium_id: mediumRes.response[0].medium_id };
          this.setState({ mediumList: mediumRes.response, chapter });
        }
        let boardRes;
        if (this.state.user_type === constants.USER_TYPE.SUPER_ADMIN) {
          boardRes = await getSuperAdminService().getAllBoardFromState(data);
        } else {
          boardRes = await getContentWriterService().getAdminCWBoard(data);
        }
        boardRes = boardRes.data;
        if (boardRes && boardRes.status == 200) {
          chapter = { ...chapter, board_id: boardRes.response[0].board_id };
          this.setState({ boardList: boardRes.response, chapter }, async () => {
            let apidata = { board_id: this.state.chapter.board_id };
            let CWDistrictRes;
            if (this.state.user_type == constants.USER_TYPE.SUPER_ADMIN) {
              CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                apidata
              );
            } else {
              CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                apidata
              );
            }
            CWDistrictRes = CWDistrictRes.data;
            if (CWDistrictRes && CWDistrictRes.status == 200) {
              chapter = {
                ...chapter,
                district_id: CWDistrictRes.response[0].district_id,
              };
              this.setState(
                { districtList: CWDistrictRes.response, chapter },
                async () => {
                  let apidata1 = {
                    board_id: this.state.chapter.board_id,
                    district_id: this.state.chapter.district_id,
                  };
                  let CWClassesRes;
                  if (this.state.user_type == constants.USER_TYPE.SUPER_ADMIN) {
                    CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                      apidata1
                    );
                  } else {
                    CWClassesRes = await getContentWriterService().getAdminCWclasses(
                      apidata1
                    );
                  }
                  CWClassesRes = CWClassesRes.data;
                  if (CWClassesRes && CWClassesRes.status == 200) {
                    chapter = {
                      ...chapter,
                      class_id: CWClassesRes.response[0].class_id,
                    };
                    this.setState(
                      { classList: CWClassesRes.response, chapter },
                      async () => {
                        let apidata2 = {
                          class_id: chapter.class_id,
                          medium_id: chapter.medium_id,
                        };
                        let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                          apidata2
                        );
                        subjectClassRes = subjectClassRes.data;
                        chapter = {
                          ...chapter,
                          subject_id: subjectClassRes.response[0].subject_id,
                        };
                        this.setState({
                          subjectList: subjectClassRes.response,
                          chapter,
                          loading: false,
                        });
                      }
                    );
                  }
                }
              );
            }
          });
        }
      });
    }
  }

  renderParts() {
    let { chapter } = this.state;
    return chapter.parts.map((part, index) => {
      return (
        <>
          <GridItem
            key={index}
            xs={12}
            sm={12}
            md={6}
            style={{ marginTop: 15 }}
          >
            <TextField
              variant="outlined"
              type="text"
              label="name of part"
              id="name of part"
              name="name of part"
              value={part.part_name}
              onChange={(e) => {
                let { chapter } = this.state;
                chapter.parts[index].part_name = e.target.value;
                this.setState({ chapter });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%" }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: 15 }}>
            <TextField
              variant="outlined"
              type="text"
              label="tags"
              id="tags"
              name="tags"
              value={part.tags}
              onChange={(e) => {
                let { chapter } = this.state;
                chapter.parts[index].tags = e.target.value;
                this.setState({ chapter });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "50%" }}
            />
          </GridItem>
        </>
      );
    });
  }

  renderStateMenu(type) {
    let {
      stateList,
      boardList,
      districtList,
      classList,
      mediumList,
      subjectList,
    } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "board"
        ? boardList
        : type == "district"
        ? districtList
        : type == "class"
        ? classList
        : type == "medium"
        ? mediumList
        : subjectList;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "board"
          ? state.board_id
          : type == "district"
          ? state.district_id
          : type == "class"
          ? state.class_id
          : type == "medium"
          ? state.medium_id
          : state.subject_id;
      let name =
        type == "state"
          ? state.state_name
          : type == "board"
          ? state.board_name
          : type == "district"
          ? state.district_name
          : type == "class"
          ? state.class_name
          : type == "medium"
          ? state.medium_name
          : state.subject_name;
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }

  isFormInvalid = () => {
    let isInvalid = false;
    if (!this.state.chapter.chapter_name) {
      isInvalid = true;
    } else if (!this.state.chapter.state_id) {
      isInvalid = true;
    } else if (!this.state.chapter.district_id) {
      isInvalid = true;
    } else if (!this.state.chapter.board_id) {
      isInvalid = true;
    } else if (!this.state.chapter.class_id) {
      isInvalid = true;
    } else if (!this.state.chapter.medium_id) {
      isInvalid = true;
    }
    return isInvalid;
  };
    renderCreateData = () => {
    let { inputErrors, chapter } = this.state;
    return (
      <GridContainer>
        <GridItem >
          <Card style={styles.cardBorder} >
            <CardHeader>
              {/* <h4 className={classes.cardTitleWhite}>Create new Subject</h4>
                <span>Fill the details to create new subject</span> */}
            </CardHeader>
            <CardBody  >
              <h4 className={classes.cardTitleWhite}>Create new Subject</h4>
              <span>Fill the details to create new subject</span>
              <div className="row">
                <div className="col-4">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        style={{ width: "8em" }}
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={chapter.state_id}
                        onChange={(e) => {
                          const chapter = {
                            ...chapter,
                            state_id: e.target.value,
                          };

                          this.setState(
                            {
                              chapter: chapter,
                              loading: true,
                            },
                            async () => {
                              let data = {
                                state_id: this.state.chapter.state_id,
                              };
                              let mediumRes = await getContentWriterService().getMediumbyState(
                                data
                              );
                              mediumRes = mediumRes.data;
                              if (mediumRes && mediumRes.status == 200) {
                                let boardRes;
                                if (
                                  this.state.user_type ===
                                  constants.USER_TYPE.SUPER_ADMIN
                                ) {
                                  boardRes = await getSuperAdminService().getAllBoardFromState(
                                    data
                                  );
                                } else {
                                  boardRes = await getContentWriterService().getAdminCWBoard(
                                    data
                                  );
                                }
                                boardRes = boardRes.data;
                                if (boardRes && boardRes.status == 200) {
                                  let ch = {
                                    ...chapter,
                                    board_id: boardRes.response[0].board_id,
                                    medium_id: mediumRes.response[0].medium_id,
                                  };
                                  this.setState(
                                    {
                                      boardList: boardRes.response,
                                      mediumList: mediumRes.response,
                                      chapter: ch,
                                    },
                                    async () => {
                                      let apidata = {
                                        board_id: this.state.chapter.board_id,
                                      };
                                      let CWDistrictRes;
                                      if (
                                        this.state.user_type ==
                                        constants.USER_TYPE.SUPER_ADMIN
                                      ) {
                                        CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                          apidata
                                        );
                                      } else {
                                        CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                          apidata
                                        );
                                      }
                                      CWDistrictRes = CWDistrictRes.data;
                                      if (
                                        CWDistrictRes &&
                                        CWDistrictRes.status == 200
                                      ) {
                                        let ch = {
                                          ...this.state.chapter,
                                          district_id:
                                            CWDistrictRes.response[0]
                                              .district_id,
                                        };
                                        this.setState(
                                          {
                                            districtList:
                                              CWDistrictRes.response,
                                            chapter: ch,
                                          },
                                          async () => {
                                            let apidata1 = {
                                              board_id: this.state.chapter
                                                .board_id,
                                              district_id: this.state.chapter
                                                .district_id,
                                            };
                                            let CWClassesRes;
                                            if (
                                              this.state.user_type ==
                                              constants.USER_TYPE.SUPER_ADMIN
                                            ) {
                                              CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                                apidata1
                                              );
                                            } else {
                                              CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                                apidata1
                                              );
                                            }
                                            CWClassesRes = CWClassesRes.data;
                                            if (
                                              CWClassesRes &&
                                              CWClassesRes.status == 200
                                            ) {
                                              let ch = {
                                                ...this.state.chapter,
                                                class_id:
                                                  CWClassesRes.response[0]
                                                    .class_id,
                                              };
                                              this.setState(
                                                {
                                                  classList:
                                                    CWClassesRes.response,
                                                  chapter: ch,
                                                },
                                                async () => {
                                                  let apidata2 = {
                                                    class_id: this.state.chapter
                                                      .class_id,
                                                    medium_id: this.state
                                                      .chapter.medium_id,
                                                  };
                                                  let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                                    apidata2
                                                  );
                                                  subjectClassRes =
                                                    subjectClassRes.data;
                                                  let ch = {
                                                    ...this.state.chapter,
                                                    subject_id:
                                                      subjectClassRes
                                                        .response[0].subject_id,
                                                  };
                                                  this.setState({
                                                    subjectList:
                                                      subjectClassRes.response,
                                                    chapter: ch,
                                                    loading: false,
                                                  });
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }
                                    }
                                  );
                                }
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                    class_id: null,
                                    district_id: null,
                                    board_id: null,
                                    medium_id: null,
                                  },
                                  loading: false,
                                  subjectList: [],
                                  classList: [],
                                  districtList: [],
                                  boardList: [],
                                  mediumList: [],
                                });
                              }
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("state")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-4">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Board
                      </InputLabel>
                      <Select
                        style={{ width: "8em" }}
                        labelId="demo-controlled-open-select-label1"
                        id="demo-controlled-open-select1"
                        value={chapter.board_id}
                        onChange={(e) => {
                          let updatedChapter = {
                            ...chapter,
                            board_id: e.target.value,
                          };
                          this.setState(
                            {
                              chapter: updatedChapter,
                              loading: true,
                            },
                            async () => {
                              let apidata = {
                                board_id: this.state.chapter.board_id,
                              };
                              let CWDistrictRes;
                              if (
                                this.state.user_type ==
                                constants.USER_TYPE.SUPER_ADMIN
                              ) {
                                CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                  apidata
                                );
                              } else {
                                CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                  apidata
                                );
                              }
                              CWDistrictRes = CWDistrictRes.data;
                              if (
                                CWDistrictRes &&
                                CWDistrictRes.status == 200
                              ) {
                                let ch = {
                                  ...this.state.chapter,
                                  district_id:
                                    CWDistrictRes.response[0].district_id,
                                };
                                this.setState(
                                  {
                                    districtList: CWDistrictRes.response,
                                    chapter: ch,
                                  },
                                  async () => {
                                    let apidata1 = {
                                      board_id: this.state.chapter.board_id,
                                      district_id: this.state.chapter
                                        .district_id,
                                    };
                                    let CWClassesRes;
                                    if (
                                      this.state.user_type ==
                                      constants.USER_TYPE.SUPER_ADMIN
                                    ) {
                                      CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                        apidata1
                                      );
                                    } else {
                                      CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                        apidata1
                                      );
                                    }
                                    CWClassesRes = CWClassesRes.data;
                                    if (
                                      CWClassesRes &&
                                      CWClassesRes.status == 200
                                    ) {
                                      let ch = {
                                        ...this.state.chapter,
                                        class_id:
                                          CWClassesRes.response[0].class_id,
                                      };
                                      this.setState(
                                        {
                                          classList: CWClassesRes.response,
                                          chapter: ch,
                                        },
                                        async () => {
                                          let apidata2 = {
                                            class_id: this.state.chapter
                                              .class_id,
                                            medium_id: this.state.chapter
                                              .medium_id,
                                          };
                                          let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                            apidata2
                                          );
                                          subjectClassRes =
                                            subjectClassRes.data;
                                          let ch = {
                                            ...this.state.chapter,
                                            subject_id:
                                              subjectClassRes.response[0]
                                                .subject_id,
                                          };
                                          this.setState({
                                            subjectList:
                                              subjectClassRes.response,
                                            chapter: ch,
                                            loading: false,
                                          });
                                        }
                                      );
                                    }
                                  }
                                );
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                    class_id: null,
                                    district_id: null,
                                  },
                                  subjectList: [],
                                  classList: [],
                                  districtList: [],
                                  loading: false,
                                });
                              }
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("board")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-4">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Syllabus Set
                      </InputLabel>
                      <Select
                        style={{ width: "9em" }}
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={chapter.district_id}
                        onChange={(e) => {
                          let updatedChapter = {
                            ...chapter,
                            district_id: e.target.value,
                          };
                          this.setState(
                            {
                              chapter: updatedChapter,
                              loading: true,
                            },
                            async () => {
                              let apidata1 = {
                                board_id: this.state.chapter.board_id,
                                district_id: this.state.chapter.district_id,
                              };
                              let CWClassesRes;
                              if (
                                this.state.user_type ==
                                constants.USER_TYPE.SUPER_ADMIN
                              ) {
                                CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                  apidata1
                                );
                              } else {
                                CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                  apidata1
                                );
                              }
                              CWClassesRes = CWClassesRes.data;
                              if (CWClassesRes && CWClassesRes.status == 200) {
                                let ch = {
                                  ...this.state.chapter,
                                  class_id: CWClassesRes.response[0].class_id,
                                };
                                this.setState(
                                  {
                                    classList: CWClassesRes.response,
                                    chapter: ch,
                                  },
                                  async () => {
                                    let apidata2 = {
                                      class_id: this.state.chapter.class_id,
                                      medium_id: this.state.chapter.medium_id,
                                    };
                                    let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                      apidata2
                                    );
                                    subjectClassRes = subjectClassRes.data;
                                    let ch = {
                                      ...this.state.chapter,
                                      subject_id:
                                        subjectClassRes.response[0].subject_id,
                                    };
                                    this.setState({
                                      subjectList: subjectClassRes.response,
                                      chapter: ch,
                                      loading: false,
                                    });
                                  }
                                );
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                    class_id: null,
                                  },
                                  subjectList: [],
                                  classList: [],
                                  loading: false,
                                });
                              }
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("district")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-4">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Class
                      </InputLabel>
                      <Select
                        style={{ width: "8em" }}
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={chapter.class_id}
                        onChange={(e) => {
                          let { chapter } = this.state;
                          chapter = { ...chapter, class_id: e.target.value };
                          this.setState({ chapter, loading: true }, () => {
                            let apidata2 = {
                              class_id: chapter.class_id,
                              medium_id: chapter.medium_id,
                            };
                            getContentWriterService()
                              .getSubjectByClassMed(apidata2)
                              .then((res) => {
                                if (res.data && res.data.status == 200) {
                                  chapter = {
                                    ...chapter,
                                    subject_id:
                                      res.data.response.length > 0
                                        ? res.data.response[0].subject_id
                                        : "",
                                  };
                                  this.setState({
                                    subjectList: res.data.response,
                                    chapter,
                                    loading: false,
                                  });
                                } else {
                                  this.setState({
                                    chapter: {
                                      ...this.state.chapter,
                                      subject_id: null,
                                    },
                                    subjectList: [],
                                    loading: false,
                                  });
                                }
                              });
                          });
                        }}
                      >
                        {this.renderStateMenu("class")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-4">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Medium
                      </InputLabel>
                      <Select
                        style={{ width: "8em" }}
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={chapter.medium_id}
                        onChange={(e) => {
                          let { chapter } = this.state;
                          chapter = { ...chapter, medium_id: e.target.value };
                          this.setState({ chapter, loading: true }, () => {
                            let apidata2 = {
                              class_id: chapter.class_id,
                              medium_id: chapter.medium_id,
                            };
                            getContentWriterService()
                              .getSubjectByClassMed(apidata2)
                              .then((res) => {
                                if (res.data && res.data.status == 200) {
                                  chapter = {
                                    ...chapter,
                                    subject_id:
                                      res.data.response.length > 0
                                        ? res.data.response[0].subject_id
                                        : "",
                                  };
                                  this.setState({
                                    subjectList: res.data.response,
                                    chapter,
                                    loading: false,
                                  });
                                } else {
                                  this.setState({
                                    chapter: {
                                      ...this.state.chapter,
                                      subject_id: null,
                                    },
                                    subjectList: [],
                                    loading: false,
                                  });
                                }
                              });
                          });
                        }}
                      >
                        {this.renderStateMenu("medium")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-4">
                  <div style={styles.inputWrapper}>
                    {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }} className="col-sm-4"> */}
                    <TextField
                      style={{ width: "8em", marginTop: "2.5em" }}
                      label="Subject name"
                      error={
                        !inputErrors.subject_name.valid &&
                        inputErrors.subject_name.touched
                      }
                      helperText={
                        !inputErrors.subject_name.valid &&
                        inputErrors.subject_name.touched
                          ? "Subject name is required"
                          : ""
                      }
                      value={chapter.chapter_name}
                      onClick={() => {
                        if (!chapter.chapter_name) {
                          let updateInputErrors = { ...inputErrors };
                          updateInputErrors.subject_name.touched = true;
                          this.setState({
                            inputErrors: updateInputErrors,
                          });
                        }
                      }}
                      onChange={(e) => {
                        chapter = { ...chapter, chapter_name: e.target.value };
                        let updateInputErrors = { ...inputErrors };
                        updateInputErrors.subject_name.valid = e.target.value
                          ? true
                          : false;
                        updateInputErrors.subject_name.touched = true;
                        this.setState({
                          chapter,
                          inputErrors: updateInputErrors,
                        });
                      }}
                    />
                    {/* </GridItem>
                </GridContainer> */}
                  </div>
                </div>
              </div>
            </CardBody>
            <CardFooter style={classes.cardFooter}>
              <Button
                color="primary"
                disabled={this.isFormInvalid()}
                onClick={() => {
                  this.createSubject();
                }}
              >
                Create Subject
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({ modalOpen: false });
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };
  createSubject = async () => {
    const { class_id, medium_id, chapter_name } = this.state.chapter;
    const param = {
      subject_name: chapter_name,
      class_id: class_id,
      medium_id: medium_id,
    };
    try {
      let addSubjectRes = await getSharedService().addSubject(param);
      addSubjectRes = addSubjectRes.data;

      if (addSubjectRes && addSubjectRes.status == 200) {
        this.setState(
          {
            message: "Subject added successfully",
            error: false,
          },
          async () => {
            const chapter = this.state.chapter;
            let apidata2 = {
              class_id: chapter.class_id,
              medium_id: chapter.medium_id,
            };
            let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
              apidata2
            );
            subjectClassRes = subjectClassRes.data;

            this.setState({
              subjectList: subjectClassRes.response,
              loading: false,
              chapter: {
                ...chapter,
                chapter_name: "",
              },
            });
          }
        );
      } else {
        this.setState({
          message: "Something has went wrong",
          error: true,
        });
      }
    } catch (error) {
      this.setState({
        message: "Something has went wrong",
        error: true,
      });
    }
  };

handleTabChange = (event, newValue) => {
    console.log(this.state.currentTabIndex, newValue);
    if (newValue !== undefined && this.state.classList.length) {
      this.setState(
        {
          currentTabIndex: newValue,
        },
        () => {
          console.log(this.state.classList, this.state.currentTabIndex);
          let apidata3 = {
            class_id: this.state.classList[newValue].class_id,
            medium_id: this.state.chapter.medium_id,
          };
          //console.log(this.state.classList[newValue].class_id,this.state.classList);

          getContentWriterService()
            .getSubjectByClassMed(apidata3)
            .then((res) => {
              this.setState({
                subjectList: res.data.response,
              });
            })
            .catch((err) => {
              console.log("err");
            });
        }
      );
    }
  };
  updateSubject = () => {
    const param = {
      subject_id: this.state.subjectToEdit,
      class_id : this.state.editSubClass,
      subject_name : this.state.editSubName,
    };
    getSuperAdminService()
      .updateSubject(param)
      .then((result) => {
        console.log(result.data);
        if(result)
        {
          this.setState({
            editModal: false,
          },
          ()=>{
            this.getData();
            window.location.reload()
          })
        }
      })
      .catch("Error");
  };

  deleteSubject = () => {
    const param = {
      subject_id: this.state.subjectToDelete,
    };
    getSuperAdminService()
      .deleteSubject(param)
      .then((result) => {
        result = result.data;
        if (result && result.status == 200) {
          const filteredSubjects = this.state.subjectList.filter((subject) => {
            return subject.subject_id != this.state.subjectToDelete;
          });
          this.setState({
            message: "Subject deleted successfully",
            error: false,
            subjectList: filteredSubjects,
          });
        } else {
          this.setState({
            message: "Failed to delete subject",
            error: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: "Something has went wrong",
          error: true,
        });
      });
  };

  render() {
    let {
           currentTabIndex,
      chapter,
      inputErrors,
      loading,
      message,
      error,
      showDeleteAlert,
    } = this.state;
    return (
      <div>
        <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() =>
            this.setState({
              message: "",
            })
          }
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        
        <Dialog
        maxWidth={true}
        //fullWidth={true}
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({
              modalOpen: false,
            });
          }}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
          <DialogContent>{this.renderCreateData()}</DialogContent>
        </Dialog>
        <Dialog
          open={showDeleteAlert}
          title="Delete?"
          text="Do you want to delete this subject?"
          onCancel={() => {
            this.setState({
              showDeleteAlert: false,
            });
          }}
          onConfirm={() => {
            this.setState(
              {
                showDeleteAlert: false,
              },
              () => {
                this.deleteSubject();
              }
            );
          }}
        >
          <DialogContent>
              <div>
                 Do you really want to delete this subject?
             </div>
                <FormControlLabel
                                control={
                                < Checkbox
                                    
                                  onChange={(e)=>{
                                    this.setState({
                                      checkBox1 : true
                                    })
                                  }}
                                    name="Phone"
                                    color="primary"
                     />
                                }
                                label="It will delete all the chapters from the subject"
                            />
                            <FormControlLabel
                                control={
                                < Checkbox
                                    
                                onChange={(e)=>{
                                  this.setState({
                                    checkBox2 : true
                                  })
                                }}
                                    name="Phone"
                                    color="primary"
                                />
                                }
                                label="It may create problem "
                            />
                            <FormControlLabel
                                control={
                                < Checkbox
                                    
                                onChange={(e)=>{
                                  this.setState({
                                    checkBox3 : true
                                  })
                                }}
                                    name="Phone"
                                    color="primary"
                                />
                                }
                                label="are you sure that you want to delete it ?"
                            />
                            {console.log(this.state.checkBox1,"ch")}
                            <div>
                            {this.state.checkBox1 == true  && this.state.checkBox2 == true && this.state.checkBox3 == true ? <Button color="primary" variant="filled"
                            onClick={()=>{
                              this.deleteSubject();
                            }}> Delete subject</Button> :null}
                            <Button
                            color="primary" 
                            variant="filled"
                             onClick={()=>{
                               this.setState({
                                showDeleteAlert: false
                               })
                             }}>Cancel</Button>
                            </div>
          </DialogContent>

        </Dialog>
        <Dialog
          open={this.state.editModal}
          onClose={() => {
            this.setState({
              editModal: false,
            });
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit Subject here</DialogTitle>
          <DialogContent>
            <div>
              <input
              value={this.state.editSubName} 
              onChange={(e)=>{
                        this.setState({
                          editSubName : e.target.value
                        })
              }}/>
              <div style={ { display:'flex' , justifyContent:'center', marginTop:'15px'}}>
                <Button
                style={{
                  backgroundColor:'#3f50b5',
                  color:'#fff',
                }} onClick={()=>this.updateSubject()}>
                  Update
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <GridContainer>
          {/* <GridItem xs={8} sm={8} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Create new Subject</h4>
                <span>Fill the details to create new subject</span>
              </CardHeader>
              <CardBody>
                <div style={styles.inputWrapper}>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={chapter.state_id}
                      onChange={(e) => {
                        const chapter = {
                          ...chapter,
                          state_id: e.target.value,
                        };

                        this.setState(
                          {
                            chapter: chapter,
                            loading: true,
                          },
                          async () => {
                            let data = {
                              state_id: this.state.chapter.state_id,
                            };
                            let mediumRes = await getContentWriterService().getMediumbyState(
                              data
                            );
                            mediumRes = mediumRes.data;
                            if (mediumRes && mediumRes.status == 200) {
                              let boardRes;
                              if (
                                this.state.user_type ===
                                constants.USER_TYPE.SUPER_ADMIN
                              ) {
                                boardRes = await getSuperAdminService().getAllBoardFromState(
                                  data
                                );
                              } else {
                                boardRes = await getContentWriterService().getAdminCWBoard(
                                  data
                                );
                              }
                              boardRes = boardRes.data;
                              if (boardRes && boardRes.status == 200) {
                                let ch = {
                                  ...chapter,
                                  board_id: boardRes.response[0].board_id,
                                  medium_id: mediumRes.response[0].medium_id,
                                };
                                this.setState(
                                  {
                                    boardList: boardRes.response,
                                    mediumList: mediumRes.response,
                                    chapter: ch,
                                  },
                                  async () => {
                                    let apidata = {
                                      board_id: this.state.chapter.board_id,
                                    };
                                    let CWDistrictRes;
                                    if (
                                      this.state.user_type ==
                                      constants.USER_TYPE.SUPER_ADMIN
                                    ) {
                                      CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                        apidata
                                      );
                                    } else {
                                      CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                        apidata
                                      );
                                    }
                                    CWDistrictRes = CWDistrictRes.data;
                                    if (
                                      CWDistrictRes &&
                                      CWDistrictRes.status == 200
                                    ) {
                                      let ch = {
                                        ...this.state.chapter,
                                        district_id:
                                          CWDistrictRes.response[0].district_id,
                                      };
                                      this.setState(
                                        {
                                          districtList: CWDistrictRes.response,
                                          chapter: ch,
                                        },
                                        async () => {
                                          let apidata1 = {
                                            board_id: this.state.chapter
                                              .board_id,
                                            district_id: this.state.chapter
                                              .district_id,
                                          };
                                          let CWClassesRes;
                                          if (
                                            this.state.user_type ==
                                            constants.USER_TYPE.SUPER_ADMIN
                                          ) {
                                            CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                              apidata1
                                            );
                                          } else {
                                            CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                              apidata1
                                            );
                                          }
                                          CWClassesRes = CWClassesRes.data;
                                          if (
                                            CWClassesRes &&
                                            CWClassesRes.status == 200
                                          ) {
                                            let ch = {
                                              ...this.state.chapter,
                                              class_id:
                                                CWClassesRes.response[0]
                                                  .class_id,
                                            };
                                            this.setState(
                                              {
                                                classList:
                                                  CWClassesRes.response,
                                                chapter: ch,
                                              },
                                              async () => {
                                                let apidata2 = {
                                                  class_id: this.state.chapter
                                                    .class_id,
                                                  medium_id: this.state.chapter
                                                    .medium_id,
                                                };
                                                let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                                  apidata2
                                                );
                                                subjectClassRes =
                                                  subjectClassRes.data;
                                                let ch = {
                                                  ...this.state.chapter,
                                                  subject_id:
                                                    subjectClassRes.response[0]
                                                      .subject_id,
                                                };
                                                this.setState({
                                                  subjectList:
                                                    subjectClassRes.response,
                                                  chapter: ch,
                                                  loading: false,
                                                });
                                              }
                                            );
                                          }
                                        }
                                      );
                                    }
                                  }
                                );
                              }
                            } else {
                              this.setState({
                                chapter: {
                                  ...this.state.chapter,
                                  subject_id: null,
                                  class_id: null,
                                  district_id: null,
                                  board_id: null,
                                  medium_id: null,
                                },
                                loading: false,
                                subjectList: [],
                                classList: [],
                                districtList: [],
                                boardList: [],
                                mediumList: [],
                              });
                            }
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("state")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">Board</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={chapter.board_id}
                      onChange={(e) => {
                        let updatedChapter = {
                          ...chapter,
                          board_id: e.target.value,
                        };
                        this.setState(
                          {
                            chapter: updatedChapter,
                            loading: true,
                          },
                          async () => {
                            let apidata = {
                              board_id: this.state.chapter.board_id,
                            };
                            let CWDistrictRes;
                            if (
                              this.state.user_type ==
                              constants.USER_TYPE.SUPER_ADMIN
                            ) {
                              CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                apidata
                              );
                            } else {
                              CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                apidata
                              );
                            }
                            CWDistrictRes = CWDistrictRes.data;
                            if (CWDistrictRes && CWDistrictRes.status == 200) {
                              let ch = {
                                ...this.state.chapter,
                                district_id:
                                  CWDistrictRes.response[0].district_id,
                              };
                              this.setState(
                                {
                                  districtList: CWDistrictRes.response,
                                  chapter: ch,
                                },
                                async () => {
                                  let apidata1 = {
                                    board_id: this.state.chapter.board_id,
                                    district_id: this.state.chapter.district_id,
                                  };
                                  let CWClassesRes;
                                  if (
                                    this.state.user_type ==
                                    constants.USER_TYPE.SUPER_ADMIN
                                  ) {
                                    CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                      apidata1
                                    );
                                  } else {
                                    CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                      apidata1
                                    );
                                  }
                                  CWClassesRes = CWClassesRes.data;
                                  if (
                                    CWClassesRes &&
                                    CWClassesRes.status == 200
                                  ) {
                                    let ch = {
                                      ...this.state.chapter,
                                      class_id:
                                        CWClassesRes.response[0].class_id,
                                    };
                                    this.setState(
                                      {
                                        classList: CWClassesRes.response,
                                        chapter: ch,
                                      },
                                      async () => {
                                        let apidata2 = {
                                          class_id: this.state.chapter.class_id,
                                          medium_id: this.state.chapter
                                            .medium_id,
                                        };
                                        let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                          apidata2
                                        );
                                        subjectClassRes = subjectClassRes.data;
                                        let ch = {
                                          ...this.state.chapter,
                                          subject_id:
                                            subjectClassRes.response[0]
                                              .subject_id,
                                        };
                                        this.setState({
                                          subjectList: subjectClassRes.response,
                                          chapter: ch,
                                          loading: false,
                                        });
                                      }
                                    );
                                  }
                                }
                              );
                            } else {
                              this.setState({
                                chapter: {
                                  ...this.state.chapter,
                                  subject_id: null,
                                  class_id: null,
                                  district_id: null,
                                },
                                subjectList: [],
                                classList: [],
                                districtList: [],
                                loading: false,
                              });
                            }
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("board")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Syllabus Set
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={chapter.district_id}
                      onChange={(e) => {
                        let updatedChapter = {
                          ...chapter,
                          district_id: e.target.value,
                        };
                        this.setState(
                          {
                            chapter: updatedChapter,
                            loading: true,
                          },
                          async () => {
                            let apidata1 = {
                              board_id: this.state.chapter.board_id,
                              district_id: this.state.chapter.district_id,
                            };
                            let CWClassesRes;
                            if (
                              this.state.user_type ==
                              constants.USER_TYPE.SUPER_ADMIN
                            ) {
                              CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                apidata1
                              );
                            } else {
                              CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                apidata1
                              );
                            }
                            CWClassesRes = CWClassesRes.data;
                            if (CWClassesRes && CWClassesRes.status == 200) {
                              let ch = {
                                ...this.state.chapter,
                                class_id: CWClassesRes.response[0].class_id,
                              };
                              this.setState(
                                {
                                  classList: CWClassesRes.response,
                                  chapter: ch,
                                },
                                async () => {
                                  let apidata2 = {
                                    class_id: this.state.chapter.class_id,
                                    medium_id: this.state.chapter.medium_id,
                                  };
                                  let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                    apidata2
                                  );
                                  subjectClassRes = subjectClassRes.data;
                                  let ch = {
                                    ...this.state.chapter,
                                    subject_id:
                                      subjectClassRes.response[0].subject_id,
                                  };
                                  this.setState({
                                    subjectList: subjectClassRes.response,
                                    chapter: ch,
                                    loading: false,
                                  });
                                }
                              );
                            } else {
                              this.setState({
                                chapter: {
                                  ...this.state.chapter,
                                  subject_id: null,
                                  class_id: null,
                                },
                                subjectList: [],
                                classList: [],
                                loading: false,
                              });
                            }
                          }
                        );
                      }}
                    >
                      {this.renderStateMenu("district")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">Class</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={chapter.class_id}
                      onChange={(e) => {
                        let { chapter } = this.state;
                        chapter = { ...chapter, class_id: e.target.value };
                        this.setState({ chapter, loading: true }, () => {
                          let apidata2 = {
                            class_id: chapter.class_id,
                            medium_id: chapter.medium_id,
                          };
                          getContentWriterService()
                            .getSubjectByClassMed(apidata2)
                            .then((res) => {

                              if (res.data && res.data.status == 200) {
                                chapter = {
                                  ...chapter,
                                  subject_id:
                                    res.data.response.length > 0
                                      ? res.data.response[0].subject_id
                                      : "",
                                };
                                this.setState({
                                  subjectList: res.data.response,
                                  chapter,
                                  loading: false,
                                });
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                  },
                                  subjectList: [],
                                  loading: false,
                                });
                              }
                            });
                        });
                      }}
                    >
                      {this.renderStateMenu("class")}
                    </Select>
                  </FormControl>
                  <FormControl style={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">
                      Medium
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      value={chapter.medium_id}
                      onChange={(e) => {
                        let { chapter } = this.state;
                        chapter = { ...chapter, medium_id: e.target.value };
                        this.setState({ chapter, loading: true }, () => {
                          let apidata2 = {
                            class_id: chapter.class_id,
                            medium_id: chapter.medium_id,
                          };
                          getContentWriterService()
                            .getSubjectByClassMed(apidata2)
                            .then((res) => {
                              if (res.data && res.data.status == 200) {
                                chapter = {
                                  ...chapter,
                                  subject_id:
                                    res.data.response.length > 0
                                      ? res.data.response[0].subject_id
                                      : "",
                                };
                                this.setState({
                                  subjectList: res.data.response,
                                  chapter,
                                  loading: false,
                                });
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                  },
                                  subjectList: [],
                                  loading: false,
                                });
                              }
                            });
                        });
                      }}
                    >
                      {this.renderStateMenu("medium")}
                    </Select>
                  </FormControl>
                </div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }}>
                    <TextField
                      style={{ width: "45%" }}
                      label="Subject name"
                      error={
                        !inputErrors.subject_name.valid &&
                        inputErrors.subject_name.touched
                      }
                      helperText={
                        !inputErrors.subject_name.valid &&
                        inputErrors.subject_name.touched
                          ? "Subject name is required"
                          : ""
                      }
                      value={chapter.chapter_name}
                      onClick={() => {
                        if (!chapter.chapter_name) {
                          let updateInputErrors = { ...inputErrors };
                          updateInputErrors.subject_name.touched = true;
                          this.setState({
                            inputErrors: updateInputErrors,
                          });
                        }
                      }}
                      onChange={(e) => {
                        chapter = { ...chapter, chapter_name: e.target.value };
                        let updateInputErrors = { ...inputErrors };
                        updateInputErrors.subject_name.valid = e.target.value
                          ? true
                          : false;
                        updateInputErrors.subject_name.touched = true;
                        this.setState({
                          chapter,
                          inputErrors: updateInputErrors,
                        });
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={classes.cardFooter}>
                <Button
                  color="primary"
                  disabled={this.isFormInvalid()}
                  onClick={() => {
                    this.createSubject();
                  }}
                >
                  Create Subject
                </Button>
              </CardFooter>
            </Card>
          </GridItem> */}
          <GridItem>
            <Card>
              <CardBody>
                <Typography variant="h6" className={classes.title}>
                 Subjects List
                 <Button
                  style={{ marginLeft:"70%"}}
                    color="primary"
                    //disabled={this.isFormInvalid()}
                    onClick={() => {
                      this.setState({ modalOpen: true });
                    }}
                  >
                    Create Subject
                  </Button>
                </Typography>
                <div className="row">
                <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        style={{ width: "15em" }}
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={chapter.state_id}
                        onChange={(e) => {
                          const chapter = {
                            ...chapter,
                            state_id: e.target.value,
                          };

                          this.setState(
                            {
                              chapter: chapter,
                              loading: true,
                            },
                            async () => {
                              let data = {
                                state_id: this.state.chapter.state_id,
                              };
                              let mediumRes = await getContentWriterService().getMediumbyState(
                                data
                              );
                              mediumRes = mediumRes.data;
                              if (mediumRes && mediumRes.status == 200) {
                                let boardRes;
                                if (
                                  this.state.user_type ===
                                  constants.USER_TYPE.SUPER_ADMIN
                                ) {
                                  boardRes = await getSuperAdminService().getAllBoardFromState(
                                    data
                                  );
                                } else {
                                  boardRes = await getContentWriterService().getAdminCWBoard(
                                    data
                                  );
                                }
                                boardRes = boardRes.data;
                                if (boardRes && boardRes.status == 200) {
                                  let ch = {
                                    ...chapter,
                                    board_id: boardRes.response[0].board_id,
                                    medium_id: mediumRes.response[0].medium_id,
                                  };
                                  this.setState(
                                    {
                                      boardList: boardRes.response,
                                      mediumList: mediumRes.response,
                                      chapter: ch,
                                    },
                                    async () => {
                                      let apidata = {
                                        board_id: this.state.chapter.board_id,
                                      };
                                      let CWDistrictRes;
                                      if (
                                        this.state.user_type ==
                                        constants.USER_TYPE.SUPER_ADMIN
                                      ) {
                                        CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                          apidata
                                        );
                                      } else {
                                        CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                          apidata
                                        );
                                      }
                                      CWDistrictRes = CWDistrictRes.data;
                                      if (
                                        CWDistrictRes &&
                                        CWDistrictRes.status == 200
                                      ) {
                                        let ch = {
                                          ...this.state.chapter,
                                          district_id:
                                            CWDistrictRes.response[0]
                                              .district_id,
                                        };
                                        this.setState(
                                          {
                                            districtList:
                                              CWDistrictRes.response,
                                            chapter: ch,
                                          },
                                          async () => {
                                            let apidata1 = {
                                              board_id: this.state.chapter
                                                .board_id,
                                              district_id: this.state.chapter
                                                .district_id,
                                            };
                                            let CWClassesRes;
                                            if (
                                              this.state.user_type ==
                                              constants.USER_TYPE.SUPER_ADMIN
                                            ) {
                                              CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                                apidata1
                                              );
                                            } else {
                                              CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                                apidata1
                                              );
                                            }
                                            CWClassesRes = CWClassesRes.data;
                                            if (
                                              CWClassesRes &&
                                              CWClassesRes.status == 200
                                            ) {
                                              let ch = {
                                                ...this.state.chapter,
                                                class_id:
                                                  CWClassesRes.response[0]
                                                    .class_id,
                                              };
                                              this.setState(
                                                {
                                                  classList:
                                                    CWClassesRes.response,
                                                  chapter: ch,
                                                },
                                                async () => {
                                                  let apidata2 = {
                                                    class_id: this.state.chapter
                                                      .class_id,
                                                    medium_id: this.state
                                                      .chapter.medium_id,
                                                  };
                                                  let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                                    apidata2
                                                  );
                                                  subjectClassRes =
                                                    subjectClassRes.data;
                                                  let ch = {
                                                    ...this.state.chapter,
                                                    subject_id:
                                                      subjectClassRes
                                                        .response[0].subject_id,
                                                  };
                                                  this.setState({
                                                    subjectList:
                                                      subjectClassRes.response,
                                                    chapter: ch,
                                                    loading: false,
                                                  });
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }
                                    }
                                  );
                                }
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                    class_id: null,
                                    district_id: null,
                                    board_id: null,
                                    medium_id: null,
                                  },
                                  loading: false,
                                  subjectList: [],
                                  classList: [],
                                  districtList: [],
                                  boardList: [],
                                  mediumList: [],
                                });
                              }
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("state")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Board
                      </InputLabel>
                      <Select
                        style={{ width: "15em" }}
                        labelId="demo-controlled-open-select-label1"
                        id="demo-controlled-open-select1"
                        value={chapter.board_id}
                        onChange={(e) => {
                          let updatedChapter = {
                            ...chapter,
                            board_id: e.target.value,
                          };
                          this.setState(
                            {
                              chapter: updatedChapter,
                              loading: true,
                            },
                            async () => {
                              let apidata = {
                                board_id: this.state.chapter.board_id,
                              };
                              let CWDistrictRes;
                              if (
                                this.state.user_type ==
                                constants.USER_TYPE.SUPER_ADMIN
                              ) {
                                CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
                                  apidata
                                );
                              } else {
                                CWDistrictRes = await getContentWriterService().getAdminCWdistrict(
                                  apidata
                                );
                              }
                              CWDistrictRes = CWDistrictRes.data;
                              if (
                                CWDistrictRes &&
                                CWDistrictRes.status == 200
                              ) {
                                let ch = {
                                  ...this.state.chapter,
                                  district_id:
                                    CWDistrictRes.response[0].district_id,
                                };
                                this.setState(
                                  {
                                    districtList: CWDistrictRes.response,
                                    chapter: ch,
                                  },
                                  async () => {
                                    let apidata1 = {
                                      board_id: this.state.chapter.board_id,
                                      district_id: this.state.chapter
                                        .district_id,
                                    };
                                    let CWClassesRes;
                                    if (
                                      this.state.user_type ==
                                      constants.USER_TYPE.SUPER_ADMIN
                                    ) {
                                      CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                        apidata1
                                      );
                                    } else {
                                      CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                        apidata1
                                      );
                                    }
                                    CWClassesRes = CWClassesRes.data;
                                    if (
                                      CWClassesRes &&
                                      CWClassesRes.status == 200
                                    ) {
                                      let ch = {
                                        ...this.state.chapter,
                                        class_id:
                                          CWClassesRes.response[0].class_id,
                                      };
                                      this.setState(
                                        {
                                          classList: CWClassesRes.response,
                                          chapter: ch,
                                        },
                                        async () => {
                                          let apidata2 = {
                                            class_id: this.state.chapter
                                              .class_id,
                                            medium_id: this.state.chapter
                                              .medium_id,
                                          };
                                          let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                            apidata2
                                          );
                                          subjectClassRes =
                                            subjectClassRes.data;
                                          let ch = {
                                            ...this.state.chapter,
                                            subject_id:
                                              subjectClassRes.response[0]
                                                .subject_id,
                                          };
                                          this.setState({
                                            subjectList:
                                              subjectClassRes.response,
                                            chapter: ch,
                                            loading: false,
                                          });
                                        }
                                      );
                                    }
                                  }
                                );
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                    class_id: null,
                                    district_id: null,
                                  },
                                  subjectList: [],
                                  classList: [],
                                  districtList: [],
                                  loading: false,
                                });
                              }
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("board")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Syllabus_Set
                      </InputLabel>
                      <Select
                        style={{ width: "15em" }}
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={chapter.district_id}
                        onChange={(e) => {
                          let updatedChapter = {
                            ...chapter,
                            district_id: e.target.value,
                          };
                          this.setState(
                            {
                              chapter: updatedChapter,
                              loading: true,
                            },
                            async () => {
                              let apidata1 = {
                                board_id: this.state.chapter.board_id,
                                district_id: this.state.chapter.district_id,
                              };
                              let CWClassesRes;
                              if (
                                this.state.user_type ==
                                constants.USER_TYPE.SUPER_ADMIN
                              ) {
                                CWClassesRes = await getSuperAdminService().getAllClassesFromDistrict(
                                  apidata1
                                );
                              } else {
                                CWClassesRes = await getContentWriterService().getAdminCWclasses(
                                  apidata1
                                );
                              }
                              CWClassesRes = CWClassesRes.data;
                              if (CWClassesRes && CWClassesRes.status == 200) {
                                let ch = {
                                  ...this.state.chapter,
                                  class_id: CWClassesRes.response[0].class_id,
                                };
                                this.setState(
                                  {
                                    classList: CWClassesRes.response,
                                    chapter: ch,
                                  },
                                  async () => {
                                    let apidata2 = {
                                      class_id: this.state.chapter.class_id,
                                      medium_id: this.state.chapter.medium_id,
                                    };
                                    let subjectClassRes = await getContentWriterService().getSubjectByClassMed(
                                      apidata2
                                    );
                                    subjectClassRes = subjectClassRes.data;
                                    let ch = {
                                      ...this.state.chapter,
                                      subject_id:
                                      subjectClassRes.response[0] ?  subjectClassRes.response[0].subject_id : null,
                                    };
                                    this.setState({
                                      subjectList: subjectClassRes.response,
                                      chapter: ch,
                                      loading: false,
                                    });
                                  }
                                );
                              } else {
                                this.setState({
                                  chapter: {
                                    ...this.state.chapter,
                                    subject_id: null,
                                    class_id: null,
                                  },
                                  subjectList: [],
                                  classList: [],
                                  loading: false,
                                });
                              }
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("district")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {/* <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Class
                      </InputLabel>
                      <Select
                        style={{ width: "8em" }}
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={chapter.class_id}
                        onChange={(e) => {
                          let { chapter } = this.state;
                          chapter = { ...chapter, class_id: e.target.value };
                          this.setState({ chapter, loading: true }, () => {
                            let apidata2 = {
                              class_id: chapter.class_id,
                              medium_id: chapter.medium_id,
                            };
                            getContentWriterService()
                              .getSubjectByClassMed(apidata2)
                              .then((res) => {
                                if (res.data && res.data.status == 200) {
                                  chapter = {
                                    ...chapter,
                                    subject_id:
                                      res.data.response.length > 0
                                        ? res.data.response[0].subject_id
                                        : "",
                                  };
                                  this.setState({
                                    subjectList: res.data.response,
                                    chapter,
                                    loading: false,
                                  });
                                } else {
                                  this.setState({
                                    chapter: {
                                      ...this.state.chapter,
                                      subject_id: null,
                                    },
                                    subjectList: [],
                                    loading: false,
                                  });
                                }
                              });
                          });
                        }}
                      >
                        {this.renderStateMenu("class")}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col">
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Medium
                      </InputLabel>
                      <Select
                        style={{ width: "8em" }}
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={chapter.medium_id}
                        onChange={(e) => {
                          let { chapter } = this.state;
                          chapter = { ...chapter, medium_id: e.target.value };
                          this.setState({ chapter, loading: true }, () => {
                            let apidata2 = {
                              class_id: chapter.class_id,
                              medium_id: chapter.medium_id,
                            };
                            getContentWriterService()
                              .getSubjectByClassMed(apidata2)
                              .then((res) => {
                                if (res.data && res.data.status == 200) {
                                  chapter = {
                                    ...chapter,
                                    subject_id:
                                      res.data.response.length > 0
                                        ? res.data.response[0].subject_id
                                        : "",
                                  };
                                  this.setState({
                                    subjectList: res.data.response,
                                    chapter,
                                    loading: false,
                                  });
                                } else {
                                  this.setState({
                                    chapter: {
                                      ...this.state.chapter,
                                      subject_id: null,
                                    },
                                    subjectList: [],
                                    loading: false,
                                  });
                                }
                              });
                          });
                        }}
                      >
                        {this.renderStateMenu("medium")}
                      </Select>
                    </FormControl>
                  </div>
                </div> */}
                {/* <div className="col">
                  <div style={styles.inputWrapper}> */}
                    {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }} className="col-sm-4"> */}
                    {/* <TextField
                      style={{ width: "8em", marginTop: "2.5em" }}
                      label="Subject name"
                      error={
                        !inputErrors.subject_name.valid &&
                        inputErrors.subject_name.touched
                      }
                      helperText={
                        !inputErrors.subject_name.valid &&
                        inputErrors.subject_name.touched
                          ? "Subject name is required"
                          : ""
                      }
                      value={chapter.chapter_name}
                      onClick={() => {
                        if (!chapter.chapter_name) {
                          let updateInputErrors = { ...inputErrors };
                          updateInputErrors.subject_name.touched = true;
                          this.setState({
                            inputErrors: updateInputErrors,
                          });
                        }
                      }}
                      onChange={(e) => {
                        chapter = { ...chapter, chapter_name: e.target.value };
                        let updateInputErrors = { ...inputErrors };
                        updateInputErrors.subject_name.valid = e.target.value
                          ? true
                          : false;
                        updateInputErrors.subject_name.touched = true;
                        this.setState({
                          chapter,
                          inputErrors: updateInputErrors,
                        });
                      }}
                    /> */}
                    {/* </GridItem>
                </GridContainer> */}
                  {/* </div>
                </div> */}
              </div>
              <Typography variant="h6" className={classes.title} style={{marginTop:"2em"}}>
                  Subjects for selected class
                </Typography>
                {chapter.district_id == "3" ? <Tabs
                  value={currentTabIndex}
                  onChange={this.handleTabChange}
                  // variant="static"
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  style={{ width: "100em" }}
                >
                  
                    <Tab label="1st" style={{ minWidth: "8%" }} />
                  <Tab label="2nd" style={{ minWidth: "8%" }} />
                  <Tab label="3th" style={{ minWidth: "8%" }} />
                  <Tab label="4th" style={{ minWidth: "8%" }} />
                  <Tab label="5th" style={{ minWidth: "8%" }} />
                  <Tab label="6th" style={{ minWidth: "8%" }} />
                  <Tab label="7th" style={{ minWidth: "8%" }} />
                  <Tab label="8th" style={{ minWidth: "8%" }} />
                  <Tab label="9th" style={{ minWidth: "8%" }} />
                  <Tab label="10th" style={{ minWidth: "8%" }} />          
                        </Tabs> :
                         chapter.district_id == "7" ?
                        <Tabs
                        value={currentTabIndex}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        
                        indicatorColor="primary"
                        textColor="primary"
                        style={{ width: "100em" }}
                      >
                        {console.log(this.state.classList,"classList")}
                        {
                          this.state.classList.map((res)=>{
                           return <Tab label={res.class_name} style={{ minWidth: "8%" }} />
                          })
                        }
                        {/* <Tab label="५ वी : शिष्यवृत्ती परीक्षा'" style={{ minWidth: "8%" }} />
                        <Tab label="८ वी : शिष्यवृत्ती परीक्षा" style={{ minWidth: "8%" }} />
                        <Tab label="८ वी : एन. एम. एम. एस." style={{ minWidth: "8%" }} />
                        <Tab label="१0 वी : राष्ट्रीय प्रज्ञाशोध परीक्षा" style={{ minWidth: "8%" }} />          */}
                              </Tabs> : "It is under process." }

                <div className={classes.demo}>
                  {!loading && (
                    <List dense={true} style={{ width:"90%"}}>
                      {this.state.subjectList.map((subject) => {
                        return (
                          <>
                            <ListItem key={subject.subject_id}>
                              <ListItemText primary={subject.subject_name} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    this.setState({
                                      showDeleteAlert: true,
                                      subjectToDelete: subject.subject_id,
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    this.setState(
                                      {
                                        editModal: true,
                                        subjectToEdit: subject.subject_id,
                                        editSubClass : subject.class_id,
                                        editSubName: subject.subject_name
                                      });
                                  }}
                                >
                                  <EditRounded />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <div
                              style={{
                                height: "1px",
                                width: "100%",
                                backgroundColor: "gray",
                                margin: "8px 0px",
                              }}
                            ></div>
                          </>
                        );
                      })}
                    </List>
                  )}
                </div>
                <CardFooter style={classes.cardFooter}>
                  {/* <Button
                  style={{ marginRight:"10%"}}
                    color="primary"
                    //disabled={this.isFormInvalid()}
                    onClick={() => {
                      this.setState({ modalOpen: true });
                    }}
                  >
                    Create Subject
                  </Button> */}
                </CardFooter>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
