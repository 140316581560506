import React from "react";
import Button from "@material-ui/core/Button";

import errorImg from "assets/img/something_went_wrong.png";
import classes from "./ErrorBoundary.module.css";
import * as constants from "./../../common/constants";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  handleGoBack = () => {
    this.setState(
      {
        hasError: false,
      },
      () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.user_type === constants.USER_TYPE.CONTENT_WRITER) {
          return this.props.history.push("/user/create-lesson");
        } else if (user.user_type === constants.USER_TYPE.SUPER_ADMIN) {
          return this.props.history.push("/admin/dashboard");
        } else {
          localStorage.clear();
          return this.props.history.push("/");
        }
      }
    );
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={classes.container}>
          <img src={errorImg} />
          <Button
            color="secondary"
            variant="contained"
            onClick={() => this.handleGoBack()}
          >
            Go Back
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
