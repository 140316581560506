//default imports
import React from "react";

//  external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./FLNHome.module.css";
import { getHMService, getStudentService } from "../../service/service";
import { Button } from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
  };

  render() {
    let { stdWiseGraph } = this.state;

    return (
      <>
        <div
          style={{
            marginTop: "15px",
            // backgroundColor: "#DFE4E8",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ fontSize: "20px", padding: "10px" }}>सुस्वागतम!</div>
          <div
            style={{
              display: "flex",
              justifyContent: "column",
              //   marginLeft: "18%",
            }}
          >
            <div
              style={{
                // backgroundColor: "#DFE4E8",
                padding: "10px",
                color: "#4B66B9",
                padding: "10px",
                width: "50%",
              }}
            >
              आदरणीय अधिकारी महोदय, व्हीस्कुल पोर्टल वर आपले स्वागत! व्हीस्कुल
              ॲप शालेय शिक्षणाची गुणवत्ता वाढण्यासाठी कटिबद्ध आहे. याच
              प्रक्रियेचा भाग म्हणून शिक्षकांना व शाळेला मदत करणारे तंत्रज्ञान
              आम्ही मोफत उपलब्ध करून देत आहोत. या पोर्टल वर तुम्हाला विविध
              गोष्टी करता येतील, पूर्ण माहिती वाचून मगच योग्य कृती करावी.
            </div>
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "50px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#7965DA",
              color: "#fff",
              padding: "10px",
              borderRadius:'10px'
            }}
            onClick={() => this.props.history.push("/user/FLNStudentList")}
          >
         विद्यार्थी माहिती अपलोड करा.
          </Button>
          <Button
            style={{
              backgroundColor: "#7965DA",
              color: "#fff",
              padding: "10px",
              borderRadius:'10px',
              marginLeft:'20px'
            }}
            onClick={() => this.props.history.push("/user/FLNTeacherList")}
          >
       शिक्षक माहिती अपलोड करा.
          </Button>
        </div> */}
      </>
    );
  }
}
