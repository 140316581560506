import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Hidden from "@material-ui/core/Hidden";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Input, InputLabel } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar";

import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import TextEditor from "./../../components/Editor/Editor";
import cls from "./createChapterPart.module.css";
import * as constants from "./../../common/constants";
import { getContentWriterService } from "./../../service/service";
import ContentToolbar from "components/ContentToolbar/ContentToolbar";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Mic from "@material-ui/icons/Mic";
import DragHandle from "@material-ui/icons/DragHandle";
import Dialog from "@material-ui/core/Dialog";
import VoiceRecorder from "components/VoiceRecorder/VoiceRecorder";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import GadgetPanel from "../../components/GadgetPanel/GadgetPanel";
import axios from "axios";
import { Web_separate } from "../../common/config";
import { Redirect } from "react-router-dom";
import httpV2 from "../../common/axiosV2";
import { CheckChapterStatusNew } from "service/WebSeparateService";
import { uploadNewMedia } from "service/WebSeparateService";
import { CWChapterCreate } from "service/WebSeparateService";

import MenuItem from "@material-ui/core/MenuItem";
import Iframe from "react-iframe-click";
import Select from "@material-ui/core/Select";
import { getCategories } from "service/WebSeparateService";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { CloudDownload, SkipNext, SkipPrevious } from "@material-ui/icons";

export default class CreateChapterPart extends Component {
  state = {
    value: 0,
    chapter: null,
    currentTabIndex: 0,
    contentPartsCreated: false,
    message: null,
    isError: false,
    uploadProgress: "#",
    showDeleteFieldDialog: false,
    partToBeDelete: null,
    openAudioRecordDialog: false,
    audioContentExtra: {},
    loading: false,
    modalOpen: false,
    contentIndex: 0,
    showExitDialog: false,
    chapterDetail: null,
    requestID: "",
    reqArray: [],
    imageSearchBox: false,
    CommonID: "",
    commonType: "",
    mediaList: [],
    subIndex: "",
    mediaIndex: "",
    tagSearch: "",
    subSearch: "",
    categoryList: [],
    offset: 0,
    uploadingDialog : false,
    tagMedia : "",
    subMedia : "",
    urduFontLesson: false
  };

  componentDidMount() {
    console.log(this.props.location, "this.props.location");
    if (this.props.location.state.chapter) {
      this.setState({
        chapter: {
          ...this.props.location.state.chapter,
          parts: this.props.location.state.chapter.parts.map((p) => ({
            ...p,
            color: "#f0f0f0",
            content: [],
            data: [],
          })),
        },
        chapterDetail: this.props.location.state.chapterDetail,
      });
    }
  }

  handleTabChange = (event, newValue) => {
    console.log(event, newValue, "event, newValue");
    if (newValue === this.state.chapter.parts.length) {
      let parts = [...this.state.chapter.parts];
      parts.push({
        part_name: "",
        tags: "",
        color: "#f0f0f0",
        content: [],
        data: [],
      });
      this.setState({
        chapter: {
          ...this.state.chapter,
          parts: parts,
          no_of_parts: parts.length,
        },
        currentTabIndex: newValue,
      });
    } else {
      this.setState({ currentTabIndex: newValue });
    }
  };

  addData(type, e) {
    let parts = [...this.state.chapter.parts];

    if (
      !parts[this.state.currentTabIndex].part_name &&
      !parts[this.state.currentTabIndex].tags
    ) {
      this.setState({
        isError: true,
        message: "Please choose content to add first from top toolbar panel.",
      });
      return;
    }

    // parts[this.state.currentTabIndex].content = [
    //   ...parts[this.state.currentTabIndex].content,
    //   type,
    // ];
    // parts[this.state.currentTabIndex].data = [
    //   ...parts[this.state.currentTabIndex].data,
    //   { type, value: "", tag: e.currentTarget.name },
    // ];
    parts[this.state.currentTabIndex].content.splice(
      this.state.contentIndex + 1,
      0,
      type
    );
    parts[this.state.currentTabIndex].data.splice(
      this.state.contentIndex + 1,
      0,
      { type, value: "", tag: e.currentTarget.name }
    );
    this.setState(
      {
        chapter: {
          ...this.state.chapter,
          parts,
        },
      },
      () => {
        console.log(this.state.chapter, "chapter --");
      }
    );
  }

  onExitFunction() {}

  onChapterTextChange = (e, index, type, content_id, tag = "") => {
    let value =
      type == "TEXT" ? e : e.hasOwnProperty("target") ? e.target.value : e;
    let parts = [...this.state.chapter.parts];
    if (type === "Link") {
      value = {
        name:
          e.target.name === "linkName"
            ? e.target.value
            : parts[this.state.currentTabIndex].data[index].value.name,
        value:
          e.target.name === "linkValue"
            ? e.target.value
            : parts[this.state.currentTabIndex].data[index].value.value,
      };
    }

    if (type === "VIDEO") {
      value = {
        url:
          typeof e == "object"
            ? e.target.value
            : parts[this.state.currentTabIndex].data[index].value
            ? parts[this.state.currentTabIndex].data[index].value.url
            : "",

        description:
          typeof e == "string"
            ? e
            : parts[this.state.currentTabIndex].data[index].value
            ? parts[this.state.currentTabIndex].data[index].value.description
            : null,
      };
    }

    if (type === "IMG" || type == "GIF" || type == "json") {
      const id =
        type == "IMG"
          ? `#image-file-${index}`
          : type == "GIF"
          ? `#gif-file-${index}`
          : type == "json"
          ? `#json-file-${index}`
          : null;
      const files = document.querySelector(id)
        ? document.querySelector(id).files
        : null;

      value = {
        filePath: parts[this.state.currentTabIndex].data[index].value.filePath
          ? parts[this.state.currentTabIndex].data[index].value.filePath
          : null,
        description: e,
      };
    }

    parts[this.state.currentTabIndex].data[index] = {
      type,
      content_id,
      value: value,
      tag: tag,
      color: parts[this.state.currentTabIndex].color,
    };

    console.log({
      parts,
    });

    const chapter = { ...this.state.chapter, parts: parts };
    this.setState({
      chapter: chapter,
    });
  };

  deleteFieldItem = (index) => {
    this.setState({
      partToBeDelete: null,
      showDeleteFieldDialog: false,
    });
    const parts = [...this.state.chapter.parts];
    parts[this.state.currentTabIndex].data.splice(index, 1);
    parts[this.state.currentTabIndex].content.splice(index, 1);
    this.setState({
      chapter: {
        ...this.state.chapter,
        parts: parts,
      },
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    const chapter = JSON.parse(JSON.stringify(this.state.chapter));
    const allParts = JSON.parse(JSON.stringify(chapter.parts));
    const updatedPart = allParts[this.state.currentTabIndex];

    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      updatedPart.content.slice(),
      result.source.index,
      result.destination.index
    );

    const data = this.reorder(
      updatedPart.data.slice(),
      result.source.index,
      result.destination.index
    );
    allParts[this.state.currentTabIndex].data = data;
    allParts[this.state.currentTabIndex].content = items;

    this.setState(
      {
        chapter: {
          ...chapter,
          parts: allParts,
        },
      },
      () => {
        console.log("%c Updated Chapter", "color: red");
        console.log(this.state.chapter);
      }
    );
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: "0",
    width: `100%`,
    ...draggableStyle,
  });

  getAllMedia = (offsetMedia) => {
    // console.log(commonType, "commonType", offset);
    const { commonType } = this.state;
    httpV2
      .get(`media/`, {
        params: {
          media_type:
            commonType == "IMG"
              ? "IMAGE"
              : commonType == "json"
              ? "LOTTY FILE"
              : commonType,
          //offset: offset,
          tags: this.state.tagSearch ? this.state.tagSearch : null,
          category: this.state.subSearch ? this.state.subSearch : null,
        },
      })
      .then((data) => {
        console.log(data.data.results, "data");
        const list = data.data.results;

        if (list) {
          this.setState({
            mediaList: list,
          });
        }
        console.log(this.state.mediaList, "mediaList");
      });
  };

  getCategory = async () => {
    let data = await getCategories();
    console.log(data);
    data = data.data.results;
    this.setState({
      categoryList: data,
    });
    console.log(this.state.categoryList, "categoryList", data);
  };

  uploadMedia = (mediaUrl) => {
    console.log(mediaUrl, "mediaUrl");
    this.setState({
      imageSearchBox: false,
    });
    if (mediaUrl) {
      let type = this.state.commonType;
      let param = {
        url: mediaUrl ? mediaUrl : "",
      };
      console.log(param, this.state.CommonID, "x");

      let parts = [...this.state.chapter.parts];
      let value = mediaUrl;
      let content_id = null;

      if (type == "IMG" || type == "GIF" || type == "json") {
        value = {
          filePath: mediaUrl,
          description:
          parts[this.state.currentTabIndex].data[this.state.commonID] ?
          parts[this.state.currentTabIndex].data[this.state.commonID].value.description 
          : null,
        };
      }
      console.log(value, "value");

      parts[this.state.currentTabIndex].data[this.state.CommonID] = {
        type,
        content_id,
        value: value,
      };
      console.log(parts[this.state.currentTabIndex].data[this.state.CommonID]);
      console.log(
        parts[this.state.currentTabIndex].data[this.state.CommonID],
        "updatedContent"
      );
      const chapter = { ...this.state.chapter, parts: parts };
      this.setState({
        chapter: chapter,
        loading: false,
      });
    }
  };

  renderdataforTab1() {
    const array = this.state.chapter.parts[this.state.currentTabIndex].content;

    return (
      <DragDropContext onDragEnd={(e) => this.onDragEnd(e)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
            >
              {array.map((type, index) => (
                <Draggable
                  key={index}
                  draggableId={`id ${index}`}
                  index={index}
                  style={{
                    width: "80vw",
                    display: "flex",
                  }}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={this.getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={cls.widgets}
                    >
                      {/* <div className={cls.addWidgetBtn}>
                        <IconButton title="Add widget" onClick={() => { this.setState({ modalOpen: true, contentIndex: index }) }}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </div> */}
                      <div
                        style={{
                          width: "80vw",
                          display: "flex",
                        }}
                      >
                        {type === "TEXT" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              marginTop: 20,
                              border: "1px solid #295939",
                              borderRadius: "4px",
                              background: "white",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <span style={{ color: "gray" }}>
                              Enter text content
                            </span>
                            <TextEditor
                              onChange={(e) =>
                                this.onChapterTextChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].content_id,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].tag
                                )
                              }
                              onFocus={(e) => {}}
                              value={
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0
                                  ? this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].value
                                  : ""
                              }
                              medium={this?.state?.chapterDetail?.medium?.medium_name}
                            />
                          </GridItem>
                        ) : type == "IMG" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: "1px solid #184d47",
                              borderRadius: "4px",
                              background: "white",
                              marginTop: "15px",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {/* <TextField
                                  id={`image-file-${index}`}
                                  accept="image/jpeg"
                                  type="file"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  style={{ width: "100%", paddingTop: "15px" }}
                                  onChange={(e) => {
                                    this.onImageInputChange(
                                      e,
                                      index,
                                      type,
                                      this.state.chapter.parts[
                                        this.state.currentTabIndex
                                      ].content_id
                                    );
                                  }}
                                  placeholder="Image Path"
                                  helperText="Choose Image file"
                                /> */}
                                <Button
                                  style={{
                                    backgroundColor: "#3B86FF",
                                    color: "white",
                                    margin: "3px 3px 3px",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      imageSearchBox: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                  }}
                                >
                                  Import image by search
                                </Button>
                                <Button
                                  style={{
                                    color: "white",
                                    background: "rgb(74 70 211)",
                                    marginLeft: "5%",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      uploadingDialog: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                    //this.getCategory();
                                  }}
                                >
                                  Upload images
                                </Button>
                                {/* {this.state.uploadProgress
                                  .toString()
                                  .split("#")[0] > 0 &&
                                  this.state.uploadProgress
                                    .toString()
                                    .split("#")[0] != 100 &&
                                  index ==
                                    this.state.uploadProgress
                                      .toString()
                                      .split("#")[1] && (
                                    <ProgressBar
                                      value={
                                        this.state.uploadProgress
                                          .toString()
                                          .split("#")[0]
                                      }
                                    />
                                  )} */}
                                {this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0 &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.hasOwnProperty(
                                    "filePath"
                                  ) &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.filePath && (
                                    <img
                                      className={cls.preview}
                                      src={
                                        this.state.chapter.parts[
                                          this.state.currentTabIndex
                                        ].data[index].value.filePath
                                          ? this.state.chapter.parts[
                                              this.state.currentTabIndex
                                            ].data[index].value.filePath
                                          : this.state.chapter.parts[
                                              this.state.currentTabIndex
                                            ].data[index].value
                                      }
                                    />
                                  )}
                              </div>
                              <br></br>
                              <TextEditor
                                placeholder="Image Description"
                                height="120"
                                name="ImgDesc"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                }}
                                // placeholder="Image description"
                                onChange={(e) =>
                                  this.onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].content_id,
                                    null,
                                    this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].color
                                  )
                                }
                                onFocus={(e) => {}}
                                value={
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data.length > 0 &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.hasOwnProperty(
                                    "description"
                                  )
                                    ? this.state.chapter.parts[
                                        this.state.currentTabIndex
                                      ].data[index].value.description
                                    : ""
                                }
                                medium={this?.state?.chapterDetail?.medium?.medium_name}
                              ></TextEditor>
                            </div>
                          </GridItem>
                        ) : type == "VIDEO" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              border: "1px solid #e40017",
                              borderRadius: "4px",
                              background: "white",
                              marginTop: "15px",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <TextField
                              name="videoURL"
                              placeholder="Paste video URL here"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              style={{ width: "100%", paddingTop: "15px" }}
                              value={
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0 &&
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data[index].value.hasOwnProperty("url")
                                  ? this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].value.url
                                  : this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].value
                              }
                              onChange={(e) =>
                                this.onChapterTextChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].content_id
                                )
                              }
                            />
                            <span style={{ fontSize: 12 }}>
                              {`Example:-
                                https://youtu.be/kHEvyeSKd6w?startTime=30&endTime=125`}
                            </span>
                            <br></br>
                            <TextEditor
                              height="120"
                              style={{
                                width: "100%",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                marginTop: "15px",
                              }}
                              inputProps={{
                                style: { fontSize: 15, marginTop: 15 },
                              }}
                              name="videoDesc"
                              placeholder="Provide video related questions,information,instructions here."
                              formControlProps={{
                                fullWidth: true,
                                rows: 4,
                              }}
                              value={
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0 &&
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data[index].value.hasOwnProperty(
                                  "description"
                                )
                                  ? this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].value.description
                                  : ""
                              }
                              onChange={(e) =>
                                this.onChapterTextChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].content_id,
                                  null,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].color
                                )
                              }
                              onFocus={(e) => {}}
                              medium={this?.state?.chapterDetail?.medium?.medium_name}
                            ></TextEditor>
                          </GridItem>
                        ) : type == "PDF" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: "1px solid #822659",
                              borderRadius: "4px",
                              marginTop: "15px",
                              background: "white",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            {/* <TextField
                              id="pdf-file"
                              accept=".pdf"
                              label="PDF"
                              type="file"
                              onChange={(e) =>
                                this.onImageInputChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].content_id
                                )
                              }
                              placeholder="PDF Path"
                              helperText="Choose PDF file (Max size : 7MB)"
                            /> */}
                            <Button
                                  style={{
                                    backgroundColor: "#3B86FF",
                                    color: "white",
                                    margin: "3px 3px 3px",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      imageSearchBox: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                  }}
                                >
                                  Import PDF by search
                                </Button>
                                <Button
                                  style={{
                                    color: "white",
                                    background: "rgb(74 70 211)",
                                    marginLeft: "5%",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      uploadingDialog: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                    //this.getCategory();
                                  }}
                                >
                                  Upload PDF
                                </Button>
                            {/* {this.state.uploadProgress
                              .toString()
                              .split("#")[0] > 0 &&
                              this.state.uploadProgress
                                .toString()
                                .split("#")[0] != 100 &&
                              index ==
                                this.state.uploadProgress
                                  .toString()
                                  .split("#")[1] && (
                                <ProgressBar
                                  value={
                                    this.state.uploadProgress
                                      .toString()
                                      .split("#")[0]
                                  }
                                />
                              )} */}
                            <span>
                              {this.state.chapter.parts[
                                this.state.currentTabIndex
                              ].data.length > 0
                                ? this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value
                                : ""}
                            </span>
                          </GridItem>
                        ) : type == "AUDIO" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: "1px solid #111d5e",
                              borderRadius: "4px",
                              marginTop: "15px",
                              background: "white",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <div className={cls.audioWrapper}>
                              <TextField
                                id="audio-file"
                                accept="audio/mpeg"
                                label="Audio"
                                type="file"
                                onChange={(e) =>
                                  this.onImageInputChange(
                                    e,
                                    index,
                                    type,
                                    this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].content_id
                                  )
                                }
                                placeholder="Audio Path"
                                helperText="Choose Audio file"
                              />
                              {this.state.chapter.parts[
                                this.state.currentTabIndex
                              ].data.length > 0 &&
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data[index].value && (
                                  <span className={cls.audioPlayer}>
                                    <audio controls>
                                      <source
                                        src={
                                          this.state.chapter.parts[
                                            this.state.currentTabIndex
                                          ].data.length > 0
                                            ? this.state.chapter.parts[
                                                this.state.currentTabIndex
                                              ].data[index].value
                                            : ""
                                        }
                                      ></source>
                                    </audio>
                                  </span>
                                )}
                            </div>

                            {this.state.uploadProgress
                              .toString()
                              .split("#")[0] > 0 &&
                            this.state.uploadProgress
                              .toString()
                              .split("#")[0] != 100 &&
                            index ==
                              this.state.uploadProgress
                                .toString()
                                .split("#")[1] ? (
                              <ProgressBar
                                value={
                                  this.state.uploadProgress
                                    .toString()
                                    .split("#")[0]
                                }
                              />
                            ) : (
                              <div
                                className={cls.record}
                                onClick={() =>
                                  this.setState({
                                    openAudioRecordDialog: true,
                                    audioContentExtra: {
                                      index,
                                      type,
                                      content_id: this.state.chapter.parts[
                                        this.state.currentTabIndex
                                      ].content_id,
                                    },
                                  })
                                }
                              >
                                <Mic titleAccess="Record Live" />
                              </div>
                            )}
                          </GridItem>
                        ) : type == "PPT" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: "1px solid #351f39",
                              borderRadius: "4px",
                              marginTop: "15px",
                              background: "white",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <TextField
                              id="ppt-file"
                              accept=".ppt"
                              label="PPT"
                              type="file"
                              onChange={(e) =>
                                this.onImageInputChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].content_id
                                )
                              }
                              placeholder="PPT Path"
                              helperText="Choose PPT file (Max size : 7MB)"
                            />
                            {this.state.uploadProgress
                              .toString()
                              .split("#")[0] > 0 &&
                              this.state.uploadProgress
                                .toString()
                                .split("#")[0] != 100 &&
                              index ==
                                this.state.uploadProgress
                                  .toString()
                                  .split("#")[1] && (
                                <ProgressBar
                                  value={
                                    this.state.uploadProgress
                                      .toString()
                                      .split("#")[0]
                                  }
                                />
                              )}
                            <span>
                              {this.state.chapter.parts[
                                this.state.currentTabIndex
                              ].data.length > 0
                                ? this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value
                                : ""}
                            </span>
                          </GridItem>
                        ) : type == "Link" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "90%",
                              "margin-top": "26px",
                              margin: "30px 14px",
                              justifyContent: "space-between",
                              marginRight: "12px",
                            }}
                          >
                            <TextField
                              label="Name"
                              name="linkName"
                              style={{ width: "50%" }}
                              inputProps={{ style: { fontSize: 15 } }}
                              value={
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0
                                  ? this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].value.name
                                  : ""
                              }
                              onChange={(e) =>
                                this.onChapterTextChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].content_id
                                )
                              }
                            />
                            <TextField
                              label="Enter URL"
                              name="linkValue"
                              style={{
                                width: "45%",
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{ style: { fontSize: 15 } }}
                              value={
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0
                                  ? this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].value.value
                                  : ""
                              }
                              onChange={(e) =>
                                this.onChapterTextChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].content_id
                                )
                              }
                            />
                          </GridItem>
                        ) : type == "gForm" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              border: "1px solid #09015f",
                              borderRadius: "4px",
                              marginTop: "15px",
                              background: "white",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <CustomInput
                              labelText="Enter your Google form link"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              val={
                                this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0
                                  ? this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].value
                                  : ""
                              }
                              change={(e) =>
                                this.onChapterTextChange(
                                  e,
                                  index,
                                  type,
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].content_id
                                )
                              }
                              Focus={(e) => {}}
                            />
                          </GridItem>
                        ) : type == "GIF" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: "1px solid #2c061f",
                              borderRadius: "4px",
                              marginTop: "15px",
                              background: "white",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {/* <TextField
                                  id={`gif-file-${index}`}
                                  accept=".gif"
                                  label="GIF"
                                  type="file"
                                  style={{ width: "100%", paddingTop: "15px" }}
                                  onChange={(e) =>
                                    this.onImageInputChange(
                                      e,
                                      index,
                                      type,
                                      this.state.chapter.parts[
                                        this.state.currentTabIndex
                                      ].content_id
                                    )
                                  }
                                  placeholder="GIF Path"
                                  helperText="Choose GIF file"
                                /> */}
                                <Button
                                  style={{
                                    backgroundColor: "#3B86FF",
                                    color: "white",
                                    margin: "3px 3px 3px",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      imageSearchBox: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                  }}
                                >
                                  Import GIF by search
                                </Button>
                                <Button
                                  style={{
                                    color: "white",
                                    background: "rgb(74 70 211)",
                                    marginLeft: "5%",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      uploadingDialog: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                    //this.getCategory();
                                  }}
                                >
                                  Upload GIF
                                </Button>
                                {/* {this.state.uploadProgress
                                  .toString()
                                  .split("#")[0] > 0 &&
                                  this.state.uploadProgress
                                    .toString()
                                    .split("#")[0] != 100 &&
                                  index ==
                                    this.state.uploadProgress
                                      .toString()
                                      .split("#")[1] && (
                                    <ProgressBar
                                      value={
                                        this.state.uploadProgress
                                          .toString()
                                          .split("#")[0]
                                      }
                                    />
                                  )} */}
                                {this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0 &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.hasOwnProperty(
                                    "filePath"
                                  ) &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.filePath && (
                                    <img
                                      className={cls.preview}
                                      src={
                                        this.state.chapter.parts[
                                          this.state.currentTabIndex
                                        ].data[index].value.filePath
                                          ? this.state.chapter.parts[
                                              this.state.currentTabIndex
                                            ].data[index].value.filePath
                                          : this.state.chapter.parts[
                                              this.state.currentTabIndex
                                            ].data[index].value
                                      }
                                    />
                                  )}
                              </div>
                              <br></br>
                              <TextEditor
                                placeholder="GIF Description"
                                height="120"
                                name="gifDesc"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                }}
                                onChange={(e) =>
                                  this.onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].content_id,
                                    null,
                                    this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].color
                                  )
                                }
                                value={
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data.length > 0 &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.hasOwnProperty(
                                    "description"
                                  )
                                    ? this.state.chapter.parts[
                                        this.state.currentTabIndex
                                      ].data[index].value.description
                                    : ""
                                }
                                onFocus={(e) => {}}
                                medium={this?.state?.chapterDetail?.medium?.medium_name}
                              ></TextEditor>
                            </div>
                          </GridItem>
                        ) : type == "json" ? (
                          <GridItem
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              border: "1px solid #2c061f",
                              borderRadius: "4px",
                              marginTop: "15px",
                              background: "white",
                              marginRight: "12px",
                              borderStyle: "dashed",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {/* <TextField
                                  id={`json-file-${index}`}
                                  accept=".json"
                                  label="JSON"
                                  type="file"
                                  style={{ width: "100%", paddingTop: "15px" }}
                                  onChange={(e) =>
                                    this.onImageInputChange(
                                      e,
                                      index,
                                      type,
                                      this.state.chapter.parts[
                                        this.state.currentTabIndex
                                      ].content_id
                                    )
                                  }
                                  placeholder="Lottie File Path"
                                  helperText="Choose Lottie  file"
                                /> */}
                                <Button
                                  style={{
                                    backgroundColor: "#3B86FF",
                                    color: "white",
                                    margin: "3px 3px 3px",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      imageSearchBox: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                  }}
                                >
                                  Import Lotie by search
                                </Button>
                                <Button
                                  style={{
                                    color: "white",
                                    background: "rgb(74 70 211)",
                                    marginLeft: "5%",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      uploadingDialog: true,
                                      CommonID: index,
                                      commonType: type,
                                      mediaList: [],
                                    });
                                    //this.getCategory();
                                  }}
                                >
                                  Upload Lotie
                                </Button>
                                {/* {this.state.uploadProgress
                                  .toString()
                                  .split("#")[0] > 0 &&
                                  this.state.uploadProgress
                                    .toString()
                                    .split("#")[0] != 100 &&
                                  index ==
                                    this.state.uploadProgress
                                      .toString()
                                      .split("#")[1] && (
                                    <ProgressBar
                                      value={
                                        this.state.uploadProgress
                                          .toString()
                                          .split("#")[0]
                                      }
                                    />
                                  )} */}
                                {this.state.chapter.parts[
                                  this.state.currentTabIndex
                                ].data.length > 0 &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.hasOwnProperty(
                                    "filePath"
                                  ) &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.filePath && (
                                    <img
                                      className={cls.preview}
                                      src={
                                        this.state.chapter.parts[
                                          this.state.currentTabIndex
                                        ].data[index].value.filePath
                                          ? this.state.chapter.parts[
                                              this.state.currentTabIndex
                                            ].data[index].value.filePath
                                          : this.state.chapter.parts[
                                              this.state.currentTabIndex
                                            ].data[index].value
                                      }
                                    />
                                  )}
                              </div>
                              <br></br>
                              <TextEditor
                                placeholder=" Description"
                                height="120"
                                name="Desc"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                style={{
                                  width: "100%",
                                  paddingBottom: "15px",
                                }}
                                onChange={(e) =>
                                  this.onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].content_id,
                                    null,
                                    this.state.chapter.parts[
                                      this.state.currentTabIndex
                                    ].data[index].color
                                  )
                                }
                                onFocus={(e) => {}}
                                value={
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data.length > 0 &&
                                  this.state.chapter.parts[
                                    this.state.currentTabIndex
                                  ].data[index].value.hasOwnProperty(
                                    "description"
                                  )
                                    ? this.state.chapter.parts[
                                        this.state.currentTabIndex
                                      ].data[index].value.description
                                    : ""
                                }
                                medium={this?.state?.chapterDetail?.medium?.medium_name}
                              ></TextEditor>
                            </div>
                          </GridItem>
                        ) : null}
                        <div className={cls.item_actions}>
                          <div
                            {...provided.dragHandleProps}
                            className={cls.drag_handle}
                          >
                            <DragHandle titleAccess="Drag element" />
                          </div>
                          <IconButton
                            aria-label="delete"
                            style={{ marginTop: 20, alignSelf: "center" }}
                            onClick={() => {
                              this.setState({
                                showDeleteFieldDialog: true,
                                partToBeDelete: index,
                              });
                            }}
                            title="Delete element"
                          >
                            <DeleteIcon style={{ fill: "red" }} />
                          </IconButton>

                          <IconButton
                            title="Help"
                            size="small"
                            style={{ marginTop: 10, alignSelf: "center" }}
                            onClick={() => {}}
                          >
                            <HelpOutlineIcon />
                          </IconButton>

                          <IconButton
                            title="Add widget"
                            style={{ marginTop: 10, alignSelf: "center" }}
                            onClick={() => {
                              this.setState({
                                modalOpen: true,
                                contentIndex: index,
                              });
                            }}
                          >
                            <AddCircleOutlineIcon
                              style={{ fill: "lightgreen" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                      {/* <div className={cls.addWidgetBtn}> */}

                      {/* </div> */}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {array.length == 0 ? (
                <div
                  className="row"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <div
                    className={cls.addWidgetDiv}
                    onClick={() => {
                      this.setState({ modalOpen: true });
                    }}
                  >
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <AddCircleOutlineIcon />
                      </div>
                      <span>Add widget here</span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
  newSaveForChapter = async () => {
    this.setState({
      loading: true,
    });
    let param = {
      ...this.state.chapter,
      chapter_no: this.state.chapter.chapter_number,
    };
    CWChapterCreate(param).then((data) => {
      console.log(data.data.request_id, "data");
      let id = data.data.request_id;
      this.setState({
        requestID: id,

        message: "Please wait your Chapter is creating",
      });
      setTimeout(() => {
        this.checkChapterStatus();
      }, 2000);
    });
  };
  checkChapterStatus = async () => {
    this.state.reqArray.push(this.state.requestID);
    console.log(this.state.reqArray, "this.state.reqArray");
    let param = {
      request_id: this.state.requestID,
    };
    // const http = axios.create({
    //   baseURL: Web_separate,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization" : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozODc0MiwiZXhwIjoxNjI2ODc5NjA1LCJpYXQiOjE2MjQyODc2MDV9.rMKcqdexK0m7OsucZdG039I-taPmhbsFxZLvkca5GLw"
    //   },
    // });
    CheckChapterStatusNew(param).then((res) => {
      if (res.data) {
        this.setState({
          loading: false,
        });
        console.log("ok");
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.user_type === constants.USER_TYPE.CONTENT_WRITER) {
          this.props.history.push("/user/lessons");
        } else {
          this.props.history.push("/admin/lessons");
        }
      }
    });
    localStorage.setItem("status", JSON.stringify(this.state.reqArray));
    console.log(localStorage.getItem("status"));
  };
  handleChapterSubmit = async () => {
    if (!this.state.chapter.parts[this.state.currentTabIndex].content.length) {
      this.setState({
        isError: true,
        message: "Please choose content to add first from top toolbar panel.",
      });
      return;
    }

    this.setState({
      loading: true,
    });
    console.log(
      { ...this.state.chapter, chapter_no: this.state.chapter.chapter_number },
      "create"
    );
    // let chapterCreatRes = await getContentWriterService().createChapterAndPartsForCWAdmin(
    //   { ...this.state.chapter, chapter_no: this.state.chapter.chapter_number }
    // );
    // chapterCreatRes = chapterCreatRes.data;
    // if (chapterCreatRes && chapterCreatRes.status === 200) {
    //   this.setState(
    //     {
    //       chapter: {
    //         ...chapterCreatRes.response,
    //         parts: chapterCreatRes.response.parts.map((p, i) => ({
    //           ...p,
    //           content: this.state.chapter.parts[i].content,
    //           data: this.state.chapter.parts[i].data,
    //         })),
    //       },
    //     },
    //     () => {
    //       let totalPart = this.state.chapter.parts.length;
    //       let partsProcessed = 0;
    //       this.state.chapter.parts.forEach(async (part, index) => {
    //         // let contentRes = await getContentWriterService().createChapterContentId(
    //         //   {
    //         //     chapter_id: part.chapter_id,
    //         //     part_id: part.part_id,
    //         //   }
    //         // );
    //         let contentRes = await getContentWriterService().createChapterContentIdNew(
    //           {
    //             chapter_id: part.chapter_id,
    //             part_id: part.part_id,
    //           }
    //         );

    //         contentRes = contentRes.data;
    //         if (contentRes && contentRes.status === 200) {
    //           let parts = [...this.state.chapter.parts];
    //           parts[index].content_id = contentRes.response.content_id;
    //           parts[index].data = parts[index].data.map((d) => ({
    //             ...d,
    //             content_id: contentRes.response.content_id,
    //           }));
    //           this.setState(
    //             {
    //               chapter: {
    //                 ...this.state.chapter,
    //                 parts: parts,
    //                 no_of_parts: this.state.chapter.parts.length,
    //               },
    //             },
    //             () => {
    //               partsProcessed = partsProcessed + 1;
    //               if (partsProcessed === totalPart) {
    //                 this.uploadChapterContent();
    //               }
    //             }
    //           );
    //         }
    //       });
    //     }
    //   );
    // }
  };

  getContentFromUrl = (data) => {
    console.log(data, "data --- getContentFromUrl");
    let url = "";
    fetch(url).then(function (response) {
      return response.json();
    });
  };

  uploadChapterContent = () => {
    let pArray = [];
    let paramsArray = [];

    this.state.chapter.parts.forEach((part, index) => {
      console.log(part, "partdata");
      paramsArray = [];
      part.data.forEach((d, index) => {
        paramsArray.push({
          type: d.type,
          value: d.value,
          sequence: index + 1,
          content_id: d.content_id,
          tag: d.tag,
          color: d.color,
        });
      });
      const param = {
        subject_id: this.state.chapterDetail.subject.subject_id,
        class_id: this.state.chapterDetail.subject.class_id,
        chapter_id: part.chapter_id,
        part_no: index,
        part_id: part.part_id,
        content_id: paramsArray[0].content_id,
        content: { ...paramsArray },
      };
      // let p = getContentWriterService().uploadChapterContent(param);
      let p = getContentWriterService().uploadChapterContentCDN(param);
      this.getContentFromUrl(p);
      pArray.push(p);
    });

    let allRes = Promise.all(pArray).then((allRes) => {
      this.setState(
        {
          loading: false,
          message: "Chapter created successfully",
        },
        () => {
          setTimeout(() => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user.user_type === constants.USER_TYPE.CONTENT_WRITER) {
              this.props.history.push("/user/lessons");
            } else {
              this.props.history.push("/admin/lessons");
            }
          }, 500);
        }
      );
    });
  };

  handlePartNameChanged = (e) => {
    let parts = [...this.state.chapter.parts];
    parts[this.state.currentTabIndex].part_name = e.target.value;
    parts[this.state.currentTabIndex].sequence = this.state.currentTabIndex + 1;
    parts[this.state.currentTabIndex].content = [];
    const chapter = { ...this.state.chapter, parts };
    this.setState({
      chapter,
    });
  };

  handlePartTagChanged = (e) => {
    let parts = [...this.state.chapter.parts];
    parts[this.state.currentTabIndex].tags = e.target.value;
    parts[this.state.currentTabIndex].sequence = this.state.currentTabIndex + 1;
    const chapter = { ...this.state.chapter, parts };
    this.setState({
      chapter,
    });
  };

  validateFileInput = (file, type) => {
    let isFileValid = false;
    let message = "Please choose valid file type";
    let fileExt = file.name.split(".").pop();

    let fileSize = +(file.size / (1024 * 1024)).toFixed(2);
    if (fileSize < 7) {
      isFileValid = true;
    } else {
      message = "File is too large. Please upload file less than 7MB size";
      isFileValid = false;
      return {
        isFileValid: isFileValid,
        message: message,
      };
    }

    if (type == "IMG") {
      if (
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png"
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "PDF") {
      if (file.type == "application/pdf" && fileExt == "pdf") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "PPT") {
      if (
        (file.type == "application/vnd.ms-powerpoint" ||
          file.type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
        (fileExt == "ppt" || fileExt == "pptx")
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "GIF") {
      if (file.type == "image/gif" && fileExt == "gif") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "AUDIO") {
      if (
        (file.type == "audio/mp3" || file.type == "audio/mpeg") &&
        fileExt == "mp3"
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "json") {
      console.log(fileExt, "type");
      if (file.type == "application/json" && fileExt == "json") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    }

    return {
      isFileValid: isFileValid,
      message: message,
    };
  };

  onImageInputChange = async (e, index, type, content_id) => {
    this.setState({
      uploadingDialog : false
    })
    // let file = e.hasOwnProperty("currentTarget") ? e.currentTarget.files[0] : e;

    // let fileExt = file.name.split(".").pop().toString().toLowerCase();
    // let fileName = file.name.split(".")[0];
    // const updatedFile = new File([file], `${fileName}.${fileExt}`, {
    //   type: file.type
    // });

    // let formData = new FormData();
    // formData.append("content_id", content_id);
    // formData.append("filename", updatedFile);
    // if (e.hasOwnProperty('persist'))
    //   e.persist();
    const fd = new FormData();
    fd.append("file", e.currentTarget.files[0]);
    let media = e.currentTarget.files[0];
    fd.append(
      "media_type",
      media.type == "image/jpeg"
        ? "IMAGE"
        : media.type == "image/gif"
        ? "GIF"
        : media.type == "application/pdf"
        ? "PDF"
        : media.type == "application/json"
        ? "LOTTY FILE"
        : media.type == "application/vnd.ms-powerpoint"
        ? "PPT"
        : "AUDIO"
    );
    fd.append("name", e.currentTarget.files[0].name);
    // const { isFileValid, message } = this.validateFileInput(updatedFile, type);
    // if (!isFileValid) {
    //   if (e.hasOwnProperty("currentTarget")) {
    //     e.target.value = null;
    //     e.currentTarget.value = null;
    //   }

    //   this.setState({
    //     message: message,
    //     isError: true,
    //   });
    //   return;
    // }

    try {
      let param = {
        media_type: "IMAGE",
        name: e.currentTarget.files[0].name,
        file: fd,
      };
      console.log(param, "param");
      this.setState({
        loading: true,
      });
      let mediaRes = await uploadNewMedia(fd, (progress) => {
        let current = (progress.loaded / progress.total) * 100;
        this.setState({
          uploadProgress: `${current}#${index}`,
        });
      });

      console.log(mediaRes, "mediaRes");
      if (mediaRes) {
        this.setState({
          loading: false,
        });
        mediaRes = mediaRes.data;
        let parts = [...this.state.chapter.parts];
        console.log(mediaRes.data.file_url, "mediaRes");
        let value = mediaRes.data.file_url;
        if (type == "IMG" || type == "GIF" || type == "json") {
          console.log("ok");
          value = {
            filePath: mediaRes.data.file_url,
            description:
              parts[this.state.currentTabIndex].data[index].value.description,
          };
        }

        parts[this.state.currentTabIndex].data[index] = {
          type,
          content_id,
          value: value,
        };
        const chapter = { ...this.state.chapter, parts: parts };
        this.setState({
          chapter: chapter,
          loading: false,
        });
      } else {
        console.log("Something went wrong.......");
      }
    } catch (error) {
      console.log("Error occurred", error);
      // e.target.value = null;
      this.setState({
        message: "Uploading failed",
        isError: true,
        loading: false,
      });
    }
  };

  changeTab = (dir) => {
    let currentTab = this.state.currentTabIndex;
    if (dir === "next") {
      currentTab =
        this.state.chapter.parts.length - 1 == currentTab
          ? currentTab
          : currentTab + 1;
    } else {
      currentTab = currentTab == 0 ? currentTab : currentTab - 1;
    }
    this.setState({
      currentTabIndex: currentTab,
    });
  };

  checkPartsValidation = () => {
    if (!this.state.chapter) return;
    let isValid = true;
    this.state.chapter.parts.forEach((p) => {
      if (p.part_name == "" || p.tags == "") {
        isValid = false;
      }
    });
    if( this.state.chapter.parts[this.state.currentTabIndex].content.length > 0)
    {
      isValid = true
    }
    else{
      isValid = false
    }
    // console.log("console",this.state.chapter.parts[this.state.currentTabIndex].content)
    return (
      !isValid ||
      (this.state.uploadProgress.split("#")[0] > 0 &&
        this.state.uploadProgress.split("#")[0] < 100)
    );
  };

  onColorChange = (e) => {
    const updatedChapter = { ...this.state.chapter };
    updatedChapter.parts[this.state.currentTabIndex].color = e.target.value;
    if (updatedChapter.parts[this.state.currentTabIndex].data.length > 0) {
      updatedChapter.parts[
        this.state.currentTabIndex
      ].data = updatedChapter.parts[this.state.currentTabIndex].data.map(
        (d) => {
          return { ...d, color: e.target.value };
        }
      );
    }
    this.setState({
      chapter: updatedChapter,
    });
  };

  handleRecordedFile = (file) => {
    console.log(file);

    if (!file.url) {
      this.setState({
        message: "Please record the audio first",
        isError: true,
        openAudioRecordDialog: false,
      });
      return;
    }

    const { type, content_id, index } = this.state.audioContentExtra;
    var blobFile = new File([file.blob], `${new Date().getTime()}.mp3`, {
      type: "audio/mp3",
    });
    console.log("file", blobFile);
    this.setState(
      {
        openAudioRecordDialog: false,
      },
      () => {
        this.onImageInputChange(blobFile, index, type, content_id);
      }
    );

    // let parts = [...this.state.chapter.parts];
    // parts[this.state.currentTabIndex].data[index] = {
    //   type,
    //   content_id,
    //   value: file.url,
    // };
    // const chapter = { ...this.state.chapter, parts: parts };
    // this.setState({
    //   chapter: chapter,
    //   openAudioRecordDialog: false,
    // });
  };

  removeTab(index) {
    let chapterParts = [...this.state.chapter.parts];
    chapterParts.splice(index, 1);
    console.log(chapterParts, "chapterParts");
    const chapter = { ...this.state.chapter, parts: chapterParts };
    this.setState({
      chapter,
      currentTabIndex: index - 1,
    });
  }

  handlefontChange = (e) => {
    this.setState({
      urduFontLesson: e.target.checked
    })
  }

  render() {
    let {
      message,
      chapter,
      currentTabIndex,
      isError,
      showDeleteFieldDialog,
      partToBeDelete,
      openAudioRecordDialog,
      loading,
      showExitDialog,
      chapterDetail,
    } = this.state;
    console.log(chapter, "chapter");
    return (
      <div>
        <GridContainer>
          <Dialog
            onClose={() => {
              this.setState({
                openAudioRecordDialog: false,
              });
            }}
            aria-labelledby="simple-dialog-title"
            open={openAudioRecordDialog}
          >
            <VoiceRecorder
              onDone={(file) => {
                this.handleRecordedFile(file);
              }}
            />
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="md"
            // style={{
            //   height:"10em"
            // }}
            onClose={() => {
              this.setState({
                imageSearchBox: false,
              });
            }}
            aria-labelledby="simple-dialog-title"
            open={this.state.imageSearchBox}
          >
            <div
              style={{
                height: "50em",
              }}
            >
              <h2
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4%",
                  color: "white",
                  backgroundImage: "linear-gradient(#3f50b5,#8e24aa)",
                  fontFamily: "sans-serif",
                }}
              >
                Manage Gallery
              </h2>
              <div
                style={{
                  borderBottom: "3px solid black",
                }}
              ></div>
              <div className="row">
                <div className="col-4">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <FormControl
                  style={{
                    width: "45%",
                    marginTop: "35px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                  <Select
                    style={{ width: "8em" }}
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    onChange={(e) => {
                      console.log(e, e.target.value);
                      setSubIndex(e.target.value);
                    }}
                  >
                    <MenuItem value={10}>History</MenuItem>
                    <MenuItem value={20}>English</MenuItem>
                    <MenuItem value={30}>Marathi</MenuItem>
                  </Select>
                </FormControl> */}
                  </div>
                </div>

                <div className="col-4">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }} className="col-sm-4"> */}
                    <div
                      style={{
                        marginTop: "10%",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="text"
                        className={cls.searchTerm}
                        placeholder="Search by tags"
                        onChange={(e) => {
                          this.setState({
                            tagSearch: e.target.value,
                          });
                        }}
                      />
                      <button
                        style={{ background: "#3f51b5" }}
                        type="submit"
                        className={cls.searchButton}
                        onClick={() => {
                          this.getAllMedia();
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                    <FormControl>
                      <span className={cls.label}>subject</span>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => {
                          this.setState({
                            subSearch: e.target.value,
                          });
                          this.getCategory();
                        }}
                      >
                        {this.state.categoryList
                          ? this.state.categoryList.map((sub, index) => {
                              return (
                                <MenuItem value={sub.category_id} key={index}>
                                  {sub.name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div>
                {/* <Button
              type="contained"
              style={{ background: "#3f51b5" }}
              onClick={() => {
                setOffset(0);
                getAllMedia();
              }}
            >
              search
            </Button> */}

                {/* {mediaUrl ? (
              <Button
                style={{
                  background: "#7700ed",
                  color: "white",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  console.log("ok");
                  uploadMedia();
                  setImageSearchBox(false);
                }}
              >
                Upload
              </Button>
            ) : (
                ""
              )} */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      this.setState({
                        offset: this.state.offset - 10,
                      });
                      //getAllMedia();
                    }}
                  >
                    <SkipPrevious />
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        offset: this.state.offset + 10,
                      });
                    }}
                  >
                    <SkipNext />
                  </Button>
                </div>
              </div>
              <div
                style={{
                  background: "#d6d7ff",
                }}
              >
                {this.state.mediaList
                  ? this.state.mediaList.map((res) => {
                      console.log(res.file_url, "res");
                      if (
                        this.state.commonType == "IMG" ||
                        this.state.commonType == "GIF"
                      ) {
                        return (
                          <img
                            style={{
                              width: "15em",
                              border: "3px solid white",
                              padding: "5px",
                            }}
                            onClick={(e) => {
                              this.uploadMedia(res.file_url);
                              //setMediaUrl(res.file_url);
                            }}
                            src={res.file_url}
                          />
                        );
                      } else if (this.state.commonType == "json") {
                        console.log("jsonFile", res.file_url);
                        return (
                          <div
                            className="col-4"
                            style={{
                              height: "30%",
                              width: "30%",
                              border: "3px solid white",
                            }}
                            onClick={() => {
                              this.uploadMedia(res.file_url);
                            }}
                          >
                            <Player
                              autoplay
                              loop
                              src={res.file_url}
                              onClick={() => {
                                console.log(res.file_url, "url");
                              }}
                            ></Player>
                          </div>
                        );
                      } else if (this.state.commonType === "PDF") {
                        return (
                          <Iframe
                            style={{
                              width: "15em",
                              border: "3px solid white",
                              padding: "5px",
                            }}
                            // onClick={(e)=>{
                            //   console.log("ok");
                            //   setMediaUrl(res.file_url);
                            // }}
                            onInferredClick={() => this.uploadMedia(res.file_url)}
                            src={res.file_url}
                          />
                        );
                      }
                    })
                  : null}
              </div>
            </div>
          </Dialog>

          
      <Dialog
        fullWidth
        maxWidth="sm"
        style={{
          // width:"50%",
          height: "70%",
        }}
        onClose={() => {
          this.setState({
            uploadingDialog : false
          })
        }}
        aria-labelledby="simple-dialog-title"
        open={this.state.uploadingDialog}
      >
        <Card>
          <CardBody>
            <h4
              
              style={{
                color: "white",

                backgroundImage: "linear-gradient(#3f50b5,#8e24aa)",
                fontFamily: "sans-serif",
                fontSize: "20px",
                padding: "2px 2px 2px",
                paddingLeft: "35%",
              }}
            >
              Manage Media upload
            </h4>
            <div
              style={{
                borderBottom: "3px solid black",
              }}
            ></div>
            <div>
              <div>
                <Input
                  style={{
                    paddingTop: "40px",
                  }}
                  placeholder="Add name for media"
                  label="add tag"
                  onChange={(e) => {
                    this.setState({
                      tagMedia : e.target.value
                    })
                  }}
                />
                <FormControl >
                  <span className={cls.label}>subject</span>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      this.setState({
                        subMedia : e.target.value
                      })
                      
                    }}
                  >
                    {this.state.categoryList
                      ? this.state.categoryList.map((sub, index) => {
                        return (
                          <MenuItem value={sub.category_id} key={index}>
                            {sub.name}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </div>
              
              <div>
                {this.state.commonType == "PDF" ? (
                  <input
                    disabled={this.state.tagMedia ? false : true}
                    id="pdf-file"
                    accept=".pdf"
                    label="PDF"
                    type="file"
                    onChange={(e) =>
                      this.onImageInputChange(e, this.state.CommonID, this.state.commonType, null)
                    }
                    placeholder="PDF Path"
                    helperText="Choose PDF file (Max size : 7MB)"
                    onFocus={()=>{}}
                  />
                ) : this.state.commonType == "IMG" ? (
                  <input
                    disabled={this.state.tagMedia ? false : true}
                    id={`image-file-${this.state.CommonID}`}
                    accept="image/jpeg"
                    label="Image"
                    type="file"
                    style={{
                      width: "50%",
                      paddingBottom: "15px",
                      paddingTop: "10px",
                      borderBottom: "1px solid black",
                    }}
                    onChange={(e) =>
                      this.onImageInputChange(e, this.state.CommonID, this.state.commonType, null)
                    }
                    placeholder="Image Path"
                    helperText="Choose Image file"
                  />
                ) : // :
                    //  commonType == "PPT" ? (
                    //   <input
                    //   disabled={tagMedia ? false : true}
                    //     id="ppt-file"
                    //     accept=".ppt"
                    //     label="PPT"
                    //     type="file"
                    //     onChange={(e) => onImageInputChange(e, commonID, commonType, null)}
                    //     placeholder="PPT Path"
                    //     helperText="Choose PPT file (Max size : 7MB)"
                    //   />
                    // )
                    this.state.commonType == "GIF" ? (
                      <input
                        disabled={this.state.tagMedia ? false : true}
                        id={`gif-file-${this.state.CommonID}`}
                        accept=".gif"
                        label="GIF"
                        style={{
                          width: "100%",
                          paddingBottom: "15px",
                          borderBottom: "1px solid black",
                        }}
                        type="file"
                        onChange={(e) =>
                          this.onImageInputChange(e, this.state.CommonID, this.state.commonType, null)
                        }
                        placeholder="GIF Path"
                        helperText="Choose GIF file"
                      />
                    ) : this.state.commonType == "json" ? (
                      <TextField
                        disabled={this.state.tagMedia ? false : true}
                        id={`json-file-${this.state.CommonID}`}
                        accept=".json"
                        label="JSON"
                        style={{
                          width: "100%",
                          paddingBottom: "15px",
                        }}
                        type="file"
                        onChange={(e) =>
                          this.onImageInputChange(e, this.state.CommonID, this.state.commonType, null)
                        }
                        placeholder="Lottie File Path"
                        helperText="Choose Lottie  file"
                      />
                    ) : null}
                <FormHelperText>( enter tags first ) </FormHelperText>
              </div>
            </div>
          </CardBody>
          {/* <CardFooter>
          <Button
          style={{
            background:"#3f50b5",
            marginLeft:"45%"
          }}
          onClick={()=>{
            setUploadDialog(false);
          }}>
            Save
          </Button>
        </CardFooter> */}
        </Card>
      </Dialog>

          <Snackbar
            autoHideDuration={5000}
            message={message}
            color={isError ? "danger" : "success"}
            close={false}
            place="br"
            closeNotification={() => this.setState({ message: "" })}
            rtlActive={true}
            open={message ? true : false}
          ></Snackbar>
          <AlertDialog
            open={showDeleteFieldDialog}
            title="Delete?"
            text="Do you want to delete this field?"
            onCancel={() => {
              this.setState({
                showDeleteFieldDialog: false,
              });
            }}
            onConfirm={() => {
              this.deleteFieldItem(partToBeDelete);
            }}
          />
          <AlertDialog
            open={showExitDialog}
            title="Exit?"
            text="Do you want to exit?"
            onCancel={() => {
              this.setState({
                showExitDialog: false,
              });
            }}
            onConfirm={() => {
              setTimeout(() => {
                const user = JSON.parse(localStorage.getItem("user"));
                if (user.user_type === constants.USER_TYPE.CONTENT_WRITER) {
                  this.props.history.push("/user/create-lesson");
                } else {
                  this.props.history.push("/admin/create-lesson");
                }
              }, 500);
            }}
          />
          <Modal
            open={this.state.modalOpen}
            onClose={() => {
              this.setState({ modalOpen: false });
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <span
                style={{
                  background: "white",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <GadgetPanel
                  onCancelClick={() => {
                    this.setState({ modalOpen: false });
                  }}
                  onAddData={(e, type) => {
                    this.setState({ modalOpen: false });
                    this.addData(type, e);
                  }}
                />
              </span>
            </div>
          </Modal>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              {/* <CardHeader color="primary">
                <h4>Create Lesson Parts</h4>
                <span>
                  Start adding content for each part by choosing the content
                  type from right side panel.
                </span>
              </CardHeader> */}
              <CardHeader>
                <div className="row">
                  <div className="col-10">
                    <div className={cls.chapterTitle}>
                      {chapter?.chapter_name}
                    </div>
                    <div className={cls.subjectDetail}>
                      <span>{chapterDetail?.standard?.class_name}</span>(
                      {chapterDetail?.subject?.subject_name})
                    </div>
                    <div className={cls.chapterDetail}>
                      <span className={cls.detailTitle}>Medium : </span>
                      <span>{chapterDetail?.medium?.medium_name} &nbsp;|</span>
                      <span className={cls.detailTitle}>Board : </span>
                      <span>{chapterDetail?.board?.board_name} &nbsp;|</span>
                      <span className={cls.detailTitle}>Syllabus :</span>
                      <span>
                        {" "}
                        {chapterDetail?.syllabus?.district_name} &nbsp;|
                      </span>
                      <span className={cls.detailTitle}>Created by : </span>
                      <span>
                        {" "}
                        {
                          JSON.parse(localStorage.getItem("user"))?.user_details
                            ?.full_name
                        }
                      </span>
                    </div>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="outlined"
                      className="m-2"
                      size="large"
                      onClick={() => this.setState({ showExitDialog: true })}
                    >
                      Exit
                    </Button>
                    {!loading ? (
                      <Button
                        color="primary"
                        className={cls.publishBtn}
                        variant="contained"
                        className="m-2"
                        size="large"
                        onClick={() => this.newSaveForChapter()}
                        disabled={this.checkPartsValidation()}
                      >
                        Save
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </CardHeader>
              <hr />
              <CardBody>
                {/* <AppBar
                  position="static"
                  style={{
                    position: "sticky",
                    top: 0,
                  }}
                > */}
                <Hidden only="xs">
                  <Paper>
                    <Tabs
                      value={currentTabIndex}
                      onChange={this.handleTabChange}
                      variant="scrollable"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      {chapter &&
                        chapter.parts.map((part, index) => {
                          return (
                            <Tab key={index} label={`Part ${index + 1}`} />
                          );
                        })}
                      <Tab
                        icon={<AddIcon />}
                        aria-label="add"
                        title="Add new tab"
                      />
                      {/* <Tab icon={<DeleteIcon />} aria-label="delete" /> */}
                    </Tabs>
                  </Paper>
                </Hidden>
                <Hidden smUp>
                  <Tabs
                    value={currentTabIndex}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    orientation="horizontal"
                    scrollButtons="on"
                  >
                    {chapter &&
                      chapter.parts.map((part, index) => {
                        return <Tab key={index} label={`Part ${index + 1}`} />;
                      })}
                  </Tabs>
                </Hidden>
                {/* </AppBar> */}
                {/* <ContentToolbar
                  className={cls.contentToolbar}
                  addData={(e, type) => this.addData(type, e)}
                /> */}
                <TabPanel
                  value={currentTabIndex}
                  index={currentTabIndex}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* <div>
                    <span>Set Background Color: &nbsp;</span>
                    <input
                      type="color"
                      value={
                        chapter && chapter.parts[currentTabIndex].color
                          ? chapter.parts[currentTabIndex].color
                          : "#f0f0f0"
                      }
                      onChange={(e) => this.onColorChange(e)}
                    />
                  </div>
                  <br></br> */}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => this.removeTab(currentTabIndex)}
                      hidden={currentTabIndex == 0}
                      title="Delete part"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
          
                       
                        
                  </div>
                  <div className="row">
                    <div className="col-11">
                      <GridItem
                        xs={8}
                        sm={8}
                        md={12}
                        style={{
                          border: "1px solid black",
                          borderRadius: "4px",
                          background: "white",
                          // marginRight: "30px",
                          borderStyle: "dashed",
                        }}
                      >
                     
                        <label
                          htmlFor="Part"
                          style={{ position: "relative", top: "30px" }}
                        >
                          Part name <span style={{ color: "red" }}>*</span>
                        </label>
                       
                    
                        <CustomInput
                          // labelText=""
                          id="Part"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          change={(e) => this.handlePartNameChanged(e)}
                          val={
                            this.state.chapter &&
                            this.state.chapter.parts.length &&
                            this.state.chapter.parts[currentTabIndex].part_name
                          }
                          disabled={this.state.contentPartsCreated}
                          Focus={(e) => {}}
                          urduFontForLesson={this.state.urduFontLesson}
                        />
                        <FormHelperText>
                          ( या भागामध्ये आलेल्या महत्वाच्या संकल्पनेचे नाव अथवा
                          भागाचे समर्पक शीर्षक लिहा. ){" "}
                        </FormHelperText>
                      </GridItem>
                    </div>
                    <div className="col-1" style={{ alignSelf: "center" }}>
                      <IconButton title="">
                        <HelpOutlineIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-11">
                      <GridItem
                        xs={8}
                        sm={8}
                        md={12}
                        style={{
                          border: "1px solid black",
                          borderRadius: "4px",
                          background: "white",
                          marginTop: "15px",
                          // marginRight: "30px",
                          borderStyle: "dashed",
                        }}
                      >
                        <label
                          htmlFor="Tag"
                          style={{ position: "relative", top: "30px" }}
                        >
                          Tag <span style={{ color: "red" }}>*</span>
                        </label>
                        <CustomInput
                          // labelText="Tag"
                          id="Tag"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          change={(e) => this.handlePartTagChanged(e)}
                          disabled={this.state.contentPartsCreated}
                          val={
                            this.state.chapter &&
                            this.state.chapter.parts.length &&
                            this.state.chapter.parts[currentTabIndex].tags
                          }
                          Focus={(e) => {}}
                          urduFontForLesson={this.state.urduFontLesson}
                        />
                        <FormHelperText>
                          ( या भागामध्ये आलेल्या महत्वाच्या संकल्पना, शब्द
                          इत्यादीची नावे लिहा. या Tag मधील शब्दांना अनुसरून
                          विद्यार्थी माहिती शोधतील. )
                        </FormHelperText>
                      </GridItem>
                    </div>
                    <div
                      className="col-1"
                      style={{ alignSelf: "center" }}
                      size="small"
                    >
                      <IconButton title="">
                        <HelpOutlineIcon />
                      </IconButton>
                    </div>
                  </div>

                  <hr />

                  <GridContainer style={{ paddingLeft: "22px" }}>
                    {this.state.chapter && this.renderdataforTab1()}
                  </GridContainer>
                  {currentTabIndex != 0 && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.changeTab("prev")}
                      style={{
                        marginTop: "35px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Prev
                    </Button>
                  )}
                  {chapter && chapter.parts.length != currentTabIndex + 1 && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.changeTab("next")}
                      style={{
                        marginTop: "35px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Next
                    </Button>
                  )}
                  {/* {!loading ? <Button
                    style={{
                      marginTop: "35px",
                      visibility:
                        chapter && chapter.no_of_parts == currentTabIndex + 1
                          ? ""
                          : "hidden",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => this.handleChapterSubmit()}
                    disabled={this.checkPartsValidation()}
                  >
                    Save
                  </Button> : <CircularProgress />} */}
                </TabPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
