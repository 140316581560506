"use client";
import React, { useEffect, useState } from "react";
import "../Milestone.css";
import MilestoneNavbar from "./MilestoneNavbar";
import { getSuperAdminService } from "service/service";
import Snackbar from "components/Snackbar/Snackbar";
import MyLottieComponent from "../components/MyLottieComponent";
import { Link, useHistory, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { getContentWriterService } from "service/service";
import { GrConsole } from "react-icons/gr";
import { Lens } from "@material-ui/icons";

function EditMilestone() {
  const { id } = useParams();
  const history = useHistory();
  const [milestoneImage, setMilestoneImage] = useState("");
  const [milestoneImageURL, setMilestoneImageURL] = useState("");
  const [milestoneImageErrorMsg, setMilestoneImageErrorMsg] = useState(false);
  const [
    milestoneImageUploadDisabled,
    setMilestoneImageUploadDisabled,
  ] = useState(false);
  // user inputs
  const [milestone, setMilestone] = useState("");
  const [value, setValue] = useState("");
  const [syllabus_id, setSyllabus_id] = useState(3);
  const [medium_id, setMedium_id] = useState("");
  const [mediumList, setMediumList] = useState([]);
  const [class_id, setClass_id] = useState("");
  const [classList, setClassList] = useState([]);
  const [subject_id, setSubject_id] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [reward, setReward] = useState("");
  const [diamond, setDiamond] = useState("");
  const [reward_message, setReward_message] = useState("");
  const [title, setTitle] = useState("");
  const [milestoneImageURLS3, setMilestoneImageURLS3] = useState("");
  const [description, setDescription] = useState("");
  const [is_deleted, setIs_deleted] = useState("");
  //snackbar
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  //error handling
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [lottiFetch, setlottiFetch] = useState(null);

  const handleEditMilestonePayload = () => {
    let editMilestonePayload = {};
    editMilestonePayload["milestone"] = milestone;
    editMilestonePayload["value"] = value;
    if (milestone === "Total Chapter") {
      editMilestonePayload["medium_id"] = medium_id;
      editMilestonePayload["class_id"] = class_id;
      editMilestonePayload["subject_id"] = subject_id;
    } else {
      delete editMilestonePayload.medium_id;
      delete editMilestonePayload.class_id;
      delete editMilestonePayload.subject_id;
    }
    if (reward) {
      editMilestonePayload["reward"] = reward;
    } else {
      delete editMilestonePayload.reward;
    }
    if (diamond) {
      editMilestonePayload["diamond"] = diamond;
    } else {
      delete editMilestonePayload.diamond;
    }
    editMilestonePayload["reward_message"] = reward_message;
    editMilestonePayload["title"] = title;
    editMilestonePayload["icon"] = milestoneImageURLS3;
    editMilestonePayload["description"] = description;
    if (
      milestoneImageURLS3?.substring(
        milestoneImageURLS3?.length - 4,
        milestoneImageURLS3?.length
      ) === "json"
    ) {
      editMilestonePayload["lotti_fetch_key"] = lottiFetch;
    } else {
      delete editMilestonePayload.lotti_fetch_key;
    }
    return editMilestonePayload;
  };

  const handlePositiveNumberInput = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    const intValue = inputValue === "" ? "" : parseInt(inputValue, 10);
    return intValue;
  };

  async function getAllSubject() {
    let param = {
      class_id: class_id,
      medium_id: medium_id,
    };
    let subRes = await getContentWriterService().getSubjectByClassMed(param);
    setSubjectList(subRes.data.response);
  }

  async function getMedium() {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    mediumRes = mediumRes.data;
    if (mediumRes && mediumRes.status == 200) {
      setMediumList(mediumRes.response);
    }
  }

  async function getClass() {
    let param = {
      district_id: syllabus_id,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(
      param
    );
    classRes = classRes.data;
    if (classRes && classRes.status == 200) {
      setClassList(classRes.response);
    }
  }

  const uploadMilestoneImageAndGetURL = async () => {
    if (milestoneImage.name.split(".").pop() === "json") {
      const res = await fetch(milestoneImageURL);
      const val = await res.json();
      console.log(val, "val");
      setlottiFetch(val);
    }
    try {
      const formData = new FormData();
      formData.append("image", milestoneImage);
      const uploadResponse = await getSuperAdminService().getImageURLs3(
        formData
      );
      setMilestoneImageURLS3(uploadResponse.data.file);
      setError(false);
      setMessage("Image uploaded successfully");
      setMilestoneImageUploadDisabled(true);
    } catch (error) {
      console.error("Error get image URL from s3 bucket", error);
      setMessage("Error uploading image");
      setError(true);
    }
  };

  const handleEditMilestone = async () => {
    setShowError(true);
    let payload = handleEditMilestonePayload();
    try {
      let response = await getSuperAdminService().updateMilestone(id, payload);
      if (response.status === 200) {
        setError(false);
        setMessage("Saved Changes successfully");
        const timeoutId = setTimeout(() => {
          if (is_deleted) {
            history.push("/admin/milestone", { coming: "disabled" });
          } else {
            history.push("/admin/milestone", { coming: "ongoing" });
          }
        }, 1000);
      } else if (response.data.status === 400) {
        setError(true);
        setMessage("Error updating milestone");
      }
    } catch (error) {
      setError(true);
      setMessage("Error updating milestone");
      console.error("Error updating milestone", error);
    }
  };

  const getSingleMilestone = async () => {
    try {
      setLoader(true);
      const response = await getSuperAdminService().readSingleMilestone(id);
      setLoader(false);
      const milestone = response.data;

      setMilestone(milestone.milestone);
      setValue(milestone.value);
      if (milestone.medium_id) {
        setMedium_id(milestone.medium_id);
      }
      if (milestone.class_id) {
        setClass_id(milestone.class_id);
      }
      if (milestone.reward) {
        setReward(milestone.reward);
      }
      if (milestone.diamond) {
        setDiamond(milestone.diamond);
      }
      setReward_message(milestone.reward_message);
      setTitle(milestone.title);
      setMilestoneImageURLS3(milestone.icon);
      setDescription(milestone.description);
      if (milestone.lotti_fetch_key) {
        setlottiFetch(milestone.lotti_fetch_key);
        console.log(milestone.lotti_fetch_key, "ssssssssssssssssss");
      }
      setIs_deleted(milestone.is_deleted);
      if (milestone.subject_id) {
        setSubject_id(milestone.subject_id);
        console.log(milestone.subject_id, "cccccc")
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching single milestone ", error);
    }
  };

  useEffect(() => {
    getSingleMilestone();

    getMedium();
    getClass();
  }, []);

  useEffect(() => {
    setSubject_id("");
    setSubjectList([]);
    if (class_id && medium_id) {
      getAllSubject();
    }
  }, [class_id, medium_id]);

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        mess
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => {
          setMessage("");
        }}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>
      <div className="whiteBackground p-4 milestones">
        <MilestoneNavbar
          page={"edit"}
          pageLink={"Edit Milestone"}
          path={"milestones / edit-milestone / " + id}
          setPage={() => {}}
        />
        {loader == true ? (
          <div
            style={{
              marginLeft: "50%",
              marginTop: "10%",
            }}
          >
            <CircularProgress color="primary" size={40} />
          </div>
        ) : (
          <>
            <div className="primaryPurple p-4 my-4 borderRadious16">
              <div>
                <span className="title2 colorBlack ">Edit Milestone</span>
              </div>
              <div className="d-flex pt-4">
                <div className="col-4 pe-3 pb-3">
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={milestone}
                    onChange={(e) => {
                      setMilestone(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Select Milestone Type*
                    </option>
                    <option value="Total Chapter">Total Chapter</option>
                    <option value="Total Reels Attempts">
                      Total Reels Attempts
                    </option>
                    <option value="Total Points Earn">Total Points Earn</option>
                    <option value="Daily Open">Daily Open</option>
                  </select>
                </div>
                <div className="col-4 pe-3 pb-3">
                  <input
                    disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Value*"
                    value={value}
                    onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e);
                      if (intValue > 0 && intValue <= 99999) {
                        setValue(intValue);
                      } else if (intValue <= 0) {
                        setValue("");
                      }
                    }}
                  />
                </div>
              </div>
              <div
                className={`${
                  milestone === "Total Chapter" ? "d-flex" : "d-none"
                }`}
              >
                <div className="col-4 pe-3 pb-3">
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={medium_id}
                    onChange={(e) => {
                      setMedium_id(e.target.value);
                    }}
                  >
                    <option key={"medium"} value="" selected>
                      Select Medium*
                    </option>
                    {mediumList.map((medium, index) => {
                      return (
                        <option key={index + "medium"} value={medium.medium_id}>
                          {`${medium.medium_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() + `${medium.medium_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4 pe-3 pb-3">
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={class_id}
                    onChange={(e) => {
                      setClass_id(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Select Class*
                    </option>
                    {classList.map((cls, index) => {
                      return (
                        <option key={index + "class"} value={cls.class_id}>
                          {cls.class_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4 pe-3 pb-3">
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={subject_id}
                    onChange={(e) => {
                      console.log(e.target.value, "ccccccccccc")
                      setSubject_id(e.target.value);
                    }}
                  >
                    <option key={"subject"} value="" selected>
                      Subject*
                    </option>
                    {subjectList.map((subject, index) => {
                      return (
                        <option
                          key={index + "subject"}
                          value={subject.subject_id}
                        >
                          {`${subject.subject_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() + `${subject.subject_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div
                className={`${
                  showError && (milestone === "" || value === "")
                    ? "d-flex"
                    : "d-none"
                } d-flex justify-content-end`}
              >
                <span className="errorMessage Regular16 col-6 ps-3">
                  Values Cannot be empty
                </span>
              </div>
            </div>
            <div className="primaryPurple p-4 my-4 borderRadious16">
              <div>
                <span className="title2 colorBlack">
                  Reward for completing Milestone
                </span>
              </div>
              <div className="d-flex pt-4">
                <div className="col-6 pe-3">
                  <div className=" mb-5">
                    <input
                      disabled={true}
                      className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                      type="text"
                      placeholder="Reward Points"
                      value={reward}
                      onChange={(e) => {
                        const intValue = handlePositiveNumberInput(e);
                        if (intValue > 0 && intValue <= 99999) {
                          setReward(intValue);
                        } else if (intValue <= 0) {
                          setReward("");
                        }
                      }}
                    />
                  </div>
                  <div className="">
                    <input
                      disabled={true}
                      className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                      type="text"
                      placeholder="Reward Diamond"
                      value={diamond}
                      onChange={(e) => {
                        const intValue = handlePositiveNumberInput(e);
                        if (intValue > 0 && intValue <= 99999) {
                          setDiamond(intValue);
                        } else if (intValue <= 0) {
                          setDiamond("");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-6 ps-3 ">
                  <textarea
                    class="form-control colorGray Regular16 p-3 inputSelect"
                    id="exampleFormControlTextarea1"
                    rows="6"
                    placeholder="Reward Message*"
                    maxLength="200"
                    milestoneErrorMessage
                    value={reward_message}
                    onChange={(e) => {
                      setReward_message(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div
                className={`${
                  showError && reward === "" && diamond === ""
                    ? "d-flex"
                    : "d-none"
                } d-flex justify-content-start`}
              >
                <span className="errorMessage Regular16 col-6 ps-3">
                  Reward cannot be empty
                </span>
              </div>
              <div
                className={`${
                  showError && reward_message === "" ? "d-flex" : "d-none"
                } d-flex justify-content-end`}
              >
                <span className="errorMessage Regular16 col-6 ps-3">
                  Reward message cannot be empty
                </span>
              </div>
            </div>
            <div className="primaryPurple p-4 my-4 borderRadious16">
              <div>
                <span className="title2 colorBlack">Milestone displayed</span>
              </div>
              <div className="d-flex pt-4">
                <div className="col-6 pe-3">
                  <div className=" mb-2">
                    <input
                      className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                      type="text"
                      placeholder="Milestone title *"
                      maxLength="200"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <input
                      type="file"
                      accept="image/*,.json"
                      className="form-control colorGray Regular16 p-3 inputSelect"
                      id="customFileUpload"
                      onChange={(e) => {
                        if (!e.target.files[0]) {
                          setMilestoneImage("");
                          setMilestoneImageURL("");
                          setMilestoneImageErrorMsg(false);
                          return;
                        } else if (e.target.files[0].size > 1024 * 1024) {
                          setMilestoneImage("");
                          setMilestoneImageURL("");
                          setMilestoneImageErrorMsg(true);
                        } else {
                          setMilestoneImage(e.target.files[0]);
                          setMilestoneImageURL(
                            URL.createObjectURL(e.target.files[0])
                          );
                          setMilestoneImageErrorMsg(false);
                          setMilestoneImageUploadDisabled(false);
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex">
                    <div>
                      {milestoneImage !== "" ? (
                        milestoneImage.name.split(".").pop() === "json" ? (
                          <div
                            className={`${milestoneImageURL ? "" : "d-none"}`}
                          >
                            <MyLottieComponent vopalottie={milestoneImageURL} />
                          </div>
                        ) : (
                          <div>
                            <img
                              className={`${milestoneImageURL ? "" : "d-none"}`}
                              src={milestoneImageURL}
                              width={136}
                              height={106}
                              alt="Uploaded"
                            />
                          </div>
                        )
                      ) : milestoneImageURLS3.split(".").pop() === "json" ? (
                        <div
                          className={`${milestoneImageURLS3 ? "" : "d-none"}`}
                        >
                          <MyLottieComponent vopalottie={milestoneImageURLS3} />
                        </div>
                      ) : (
                        <div>
                          <img
                            className={`${milestoneImageURLS3 ? "" : "d-none"}`}
                            src={milestoneImageURLS3}
                            width={136}
                            height={106}
                            alt="Uploaded"
                          />
                        </div>
                      )}

                      <div
                        className={`${
                          milestoneImageErrorMsg ? "" : "d-none"
                        } colorRed`}
                        id="my-helper-text"
                      >
                        <span className="Regular16">
                          Image size exceeds 1MB.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 ps-3 ">
                  <textarea
                    class="form-control colorGray Regular16 p-3 inputSelect"
                    id="exampleFormControlTextarea1"
                    rows="10"
                    placeholder="Description*"
                    maxLength="200"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div
                className={`${
                  showError &&
                  (title === "" ||
                    milestoneImageURLS3 === "" ||
                    description === "")
                    ? "d-flex"
                    : "d-none"
                } d-flex justify-content-center`}
              >
                <span className="errorMessage Regular16">
                  Milestone display details cannot be empty
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-end mb-5 ">
              <Link to="/admin/milestone">
                <button
                  type="button"
                  class="btn buttonColorGray px-4 py-3 me-3 borderRadious16 regular18"
                >
                  Cancel
                </button>
              </Link>
              <button
                disabled={milestoneImageUploadDisabled}
                type="button"
                className={`${
                  milestoneImage ? "" : "d-none"
                } btn buttonColorGray px-4 py-3 me-3 borderRadious16 regular18`}
                onClick={uploadMilestoneImageAndGetURL}
              >
                Upload file
              </button>
              <button
                type="button"
                class="btn btn-dark px-4 py-3 borderRadious16 regular18"
                onClick={handleEditMilestone}
              >
                Update milestone
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EditMilestone;
