const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "100%",
    marginTop: "35px",
    position: "relative",
    paddingRight: "50px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  loading: {
    marginRight: "50px",
  },
  addIcon: {
    position: "absolute",
    right: 0,
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  list:{
    maxHeight:"80vh",
    overflowY:"auto"
}
};

export default styles;
