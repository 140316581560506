//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
import classes from "./SMCAM.module.css";
import {
  getHMService,
  getStudentService,
  getSuperAdminService,
} from "../../service/service";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import home from "assets/img/SMCHome.jpg";
import FLNStudentList from "../FLNStudent/FLNStudentList";
import { Autocomplete } from "@material-ui/lab";
import SchoolDetail from "../SchoolDetail/SchoolDetail";
import SMCCreateForm from "../CreateSMCForm/SMCCreateForm";

export default class HMDashboardPage extends React.Component {
  state = {
    schoolTab: true,
    studentTab: false,
    Teachertab: false,
    ViewTab: false,
    block: {
      value: null,
      helperText: null,
    },
    school: {
      value: null,
      helperText: null,
    },
    blocks: [],
    schools: [],
  };
  componentDidMount() {
    this.getStudentBlock();
  }

  getStudentBlock = async (district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: JSON.parse(localStorage.getItem("HMSchool"))
          ? JSON.parse(localStorage.getItem("HMSchool")).student_district_id
          : JSON.parse(localStorage.getItem("AMdetails"))
          ? JSON.parse(localStorage.getItem("AMdetails")).student_district_id
          : null,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
            },
            () => {
              this.getSchools(this.state.block.value);
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
          });
        } else {
          this.setState({
            schools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
        });
      });
  };

  setSchool = () => {
    let data = JSON.parse(localStorage.getItem("AMdetails"));
    console.log(data, "data");
    data["student_block_id"] = this.state.block.value;
    data["school_id"] = this.state.school.school_id;
    data["school_name"] = this.state.school.school_name;
    localStorage.setItem("AMdetails", JSON.stringify(data));
    window.location.reload();
  };

  getSchoolPage = () => {
    const { block, school } = this.state;
    return (
      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "9%" }}>
              <div>
                <FormControl fullWidth>
                  <span style={{ color: "#415EB6" }} id="Block">
                    Block*
                  </span>
                  <Select
                    style={{ backgroundColor: "#DFE4E8" }}
                    labelId="Block"
                    id="Block_dropdown"
                    value={block.value}
                    error={block.helperText}
                    helperText={!block.value && block.helperText}
                    onChange={(e) => {
                      this.setState(
                        {
                          block: {
                            value: e.target.value,
                            helperText: null,
                          },
                          showKendraGraph: false,
                          BlockValidation: false,
                          school: "",
                          searchText: { value: "", helperText: null },
                        },
                        () => {
                          this.getSchools(this.state.block.value);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {this.state.BlockValidation ? (
                <div style={{ color: "red" }}>Required</div>
              ) : null}
            </div>

            <div style={{ width: "15%", marginLeft: "20px" }}>
              <FormControl>
                <span style={{ color: "#415EB6" }} id="School">
                  School
                </span>
                {console.log(this.state.schools, "schoolws")}
                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{}}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setState({ isSetAuto: true });
                  }}
                  onClose={() => {
                    this.setState({ isSetAuto: false });
                  }}
                  onChange={(event, value) => {
                    this.setState(
                      {
                        school: value,
                      },
                      () => {}
                    );
                    // this.onChangeHandle(value);

                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  // options={this.state.schools}
                  loading={this.state.loading}
                  options={
                    this.state.schools == undefined ? [] : this.state.schools
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div>
              <Button
                style={{
                  backgroundColor: "#0d6efd",
                  padding: "10px",
                  color: "#fff",
                  marginTop: "20px",
                }}
                onClick={() => {
                  console.log(this.state.block, "block", this.state.school);
                  this.setSchool();
                }}
              >
                Set School
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  getStudentPage = () => {
    return <FLNStudentList />;

    // if(false)
    // {
    //     this.setState({
    //         schoolTab:true,
    //         studentTab:false,
    //             Teachertab:false,
    //             ViewTab:false
    //     })
    // }
    // else{
    //     return(
    //         <FLNStudentList/>
    //     )
    // }
  };
  getTeacherPage = () => {};
  getViewStudentPage = () => {};

  getSchoolName = () => {
    let flag = true;
    let data = JSON.parse(localStorage.getItem("AMdetails"));
    data.school_id ? (flag = false) : (flag = true);
    return flag;
  };
  render() {
    return (
      <>
        <button
          onClick={() => {
            this.setState({
              schoolTab: true,
              studentTab: false,
              Teachertab: false,
              ViewTab: false,
            });
          }}
          className={
            this.state.schoolTab
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          School Selection
        </button>
        <button
          disabled={this.getSchoolName()}
          onClick={() => {
            this.setState(
              {
                schoolTab: false,
                studentTab: true,
                Teachertab: false,
                ViewTab: false,
              },
              () => {
                // this.getStudentPage()
              }
            );
          }}
          className={
            this.state.studentTab
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          Create SMC
        </button>
        <button
          disabled={this.getSchoolName()}
          onClick={() => {
            this.setState(
              {
                schoolTab: false,
                studentTab: false,
                Teachertab: true,
                ViewTab: false,
              },
              () => {}
            );
          }}
          className={
            this.state.Teachertab
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          Preview SMC
        </button>

        {this.state.schoolTab ? (
          this.getSchoolPage()
        ) : this.state.studentTab ? (
          <SMCCreateForm />
        ) : (
          <SchoolDetail />
        )}
      </>
    );
  }
}
