import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import classes from "./ProgressBar.module.css";

const ProgressBar = ({ value = 0, variant = "determinate"}) => {
  return (
    <div className={classes.container}>
      <LinearProgress
        value={value}
        variant={variant}
        style={{
          width: "90%",
          margin: "0px 24px",
        }}
      />
      <span>{Math.ceil(value)}%</span>
    </div>
  );
};

export default ProgressBar;
