import React from "react";
import classes from "./SpecialCourse.module.css";
import { Button } from "react-bootstrap";
import { getSuperAdminService } from "service/service";
import { Icon, Modal } from "@material-ui/core";
import { CancelRounded, Edit } from "@material-ui/icons";
import { getHMService } from "service/service";
import Snackbar from "components/Snackbar/Snackbar";

export default class SpecialCourse extends React.Component {
  constructor() {
    super();
    this.state = {
      allCourses: null,
      editCourse: false,
      courseName: null,
      courseDescription: null,
      CourseImg: null,
      editcourseName: null,
      editcourseDescription: null,
      editCourseImg: null,
      editCourseId: null,
      NewimageUrl: null,
      message: "",
      error: false,
    };
  }

  componentDidMount() {
    this.getAllCourses();
  }

  getAllCourses = () => {
    let param = {
      board_id: 2,
      district_id: 7,
    };
    getSuperAdminService()
      .getAllClassesFromDistrict(param)
      .then((res) => {
        console.log(res, "res");
        if (res.data) {
          this.setState({
            allCourses: res.data.response,
          });
        }
      });
  };

  getURLForImage = async (event) => {
    this.setState({
      CourseImg: URL.createObjectURL(event.currentTarget?.files[0]),
    });
    const fd = new FormData();
    fd.append(
      "image",
      event.currentTarget ? event.currentTarget.files[0] : event
    );
    fd.append("school_id", null);
    console.log(fd, "fd");
    let mediaRes = await getHMService().uploadSchoolImages(fd);
    console.log(mediaRes.data.file, "media");
    this.setState({
      CourseImg: mediaRes.data.file,
    });
  };
  editClass = () => {
    let param = {
      class_id: parseInt(this.state.editCourseId),
      class_name: this.state.editcourseName,
      class_code: "",
      district_id: 7,
      teacher_reels_class: false,
      ai_reels_class: false,
      image: this.state.CourseImg,
      description: this.state.editcourseDescription,
    };

    console.log(param, "param");
    getSuperAdminService()
      .updateSpecialCourseClass(param)
      .then((res) => {
        console.log(res, ":res");
        if (res) {
          this.setState(
            {
              CourseImg: null,
              editcourseName: null,
              editcourseDescription: null,
              editCourseImg: null,
              editCourseId: null,
              NewimageUrl: null,
              editCourse: false,
              message: "The Special Course has been Edited",
              error: false,
            },
            () => {
              this.getAllCourses();
            }
          );
        }
      });
  };
  createClassCourse = () => {
    let param = {
      class_name: this.state.courseName,
      class_code: "",
      district_id: 7,
      teacher_reels_class: false,
      ai_reels_class: false,
      image: this.state.CourseImg,
      description: this.state.courseDescription,
    };

    getSuperAdminService()
      .creatSpecialCourse(param)
      .then((res) => {
        console.log(res, "res");
        if (res) {
          this.setState(
            {
              editCourse: false,
              courseName: null,
              courseDescription: null,
              CourseImg: null,
              editcourseName: null,
              editcourseDescription: null,
              editCourseImg: null,
              editCourseId: null,
              NewimageUrl: null,
              message: "The Special Course has been created",
              error: false,
            },
            () => {
              window.location.reload();
            }
          );
        }
      });
  };
  render() {

    let { message, error } = this.state;

    return (
      <>
           <Snackbar
          autoHideDuration={5000}
          message={message}
          color={error ? "danger" : "success"}
          close={false}
          place="br"
          closeNotification={() => {
            this.setState({
              message: null,
            });
          }}
          rtlActive={true}
          open={message ? true : false}
        ></Snackbar>
        <Modal
          open={this.state.editCourse}
          onClose={() => {
            this.setState({
              editCourse: false,
            });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                background: "white",
                //   padding: 20,
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#58B9FF",
                  textAlign: "center",
                  padding: "10px",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div></div>
                Edit Course
                <div>
                  <CancelRounded
                    style={{ color: "red" }}
                    onClick={() => {
                      this.setState({
                        CourseImg: null,
                        editcourseName: null,
                        editcourseDescription: null,
                        editCourseImg: null,
                        editCourseId: null,
                        NewimageUrl: null,
                        editCourse: false,
                      });
                    }}
                  />
                </div>
              </div>

              <div style={{ margin: "30px" }}>
                <div>Name of Course:</div>
                <div>
                  <input
                    type="text"
                    value={this.state.editcourseName}
                    placeholder="Name"
                    onChange={(e) =>
                      this.setState({ editcourseName: e.target.value })
                    }
                    className={classes.inputField}
                  />
                </div>
              </div>
              <div style={{ margin: "30px" }}>
                <div>Description:</div>
                <div>
                  <textarea
                    style={{ color: "#415EB6" }}
                    className={classes.addressTextContainer}
                    value={this.state.editcourseDescription}
                    onChange={(event) => {
                      this.setState({
                        editcourseDescription: event.target.value,
                      });
                    }}
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
              <div style={{ margin: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Image
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.CourseImg ? (
                    <img
                      src={this.state.CourseImg}
                      width="100px"
                      height="100px"
                    />
                  ) : null}
                </div>
                <div>
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    style={{ marginTop: "5px", paddingTop: "7px" }}
                    className={classes.committeeDetailsInput}
                    onChange={(e) => {
                      this.getURLForImage(e);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "10px",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    console.log(this.state, "state");
                    this.editClass();
                  }}
                  style={{ backgroundColor: "#7965DA" }}
                >
                  Edit course
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <div>
          <h2>Special Courses</h2>
          <div style={{ fontWeight: "bold", margin: "10px", color: "#958989" }}>
            The below section is used for setting new Special Courses
          </div>

          <div
            style={{
              display: "flex",
              // justifyContent: "space-around",
              backgroundColor: "#ECFBFF",
              height: "35em",
            }}
          >
            <div style={{ width: "50%" }}>
              <div
                style={{
                  display: "flex",
                  fontWeight: "bold",
                  justifyContent: "center",
                }}
              >
                Create new Course
              </div>
              <div style={{ margin: "30px" }}>
                <div>Name of Course:</div>
                <div>
                  <input
                    style={{ color: "#415EB6" }}
                    type="text"
                    value={this.state.courseName}
                    placeholder="Name"
                    onChange={(e) =>
                      this.setState({ courseName: e.target.value })
                    }
                    className={classes.inputField}
                  />
                </div>
              </div>
              <div style={{ margin: "30px" }}>
                <div>Description:</div>
                <div>
                  <textarea
                    style={{ color: "#415EB6" }}
                    className={classes.addressTextContainer}
                    value={this.state.courseDescription}
                    onChange={(event) => {
                      this.setState({ courseDescription: event.target.value });
                    }}
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
              <div style={{ margin: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Image
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.CourseImg ? (
                    <img
                      src={this.state.CourseImg}
                      width="100px"
                      height="100px"
                    />
                  ) : null}
                </div>
                <div>
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    style={{ marginTop: "5px", paddingTop: "7px" }}
                    className={classes.committeeDetailsInput}
                    onChange={(e) => {
                      this.getURLForImage(e);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    this.createClassCourse();
                  }}
                  style={{ backgroundColor: "#7965DA" }}
                >
                  Initialize course
                </Button>
              </div>
            </div>
            <div style={{ borderLeft: "1px solid black", margin: "5px" }}></div>
            <div style={{ width: "50%",overflowY:"scroll", height: "35em", }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                View Course
              </div>
              <div style={{ width: "50%", margin: "40px" }}>
                {this.state.allCourses?.length > 0
                  ? this.state.allCourses.map((res, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between  ",
                          }}
                        >
                          <div style={{ margin: "10px", fontWeight: "bold" }}>
                            {" "}
                            {index + 1} :{res.class_name}
                          </div>
                          <div>
                            <Edit
                              onClick={() => {
                                this.setState({
                                  editCourse: true,
                                  editcourseName: res.class_name,
                                  editcourseDescription: res.description,
                                  editCourseImg: res.image,
                                  editCourseId: res.class_id,
                                  CourseImg: res.image,
                                });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
