import {
  Button,
  Card,
  Dialog,
  FormHelperText,
  Input,
  TextField,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { getSuperAdminService } from "./../../service/service";
import { useHistory, useLocation } from "react-router-dom";
// import ChipInput from "material-ui-chip-input";
import { Mic, BackupOutlined } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VoiceRecorder from "components/VoiceRecorder/VoiceRecorder";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const RenderField = ({
  name,
  handleChange,
  selected,
  options,
  error,
  // disabled,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid black",
          padding: "10px",
          borderRadius: "10px",
          gap: 10,
          // backgroundColor: disabled ? "#e0e0e0" : "#f2f4f5",
          backgroundColor: "#f2f4f5",
        }}
      >
        <div
          style={{
            alignSelf: "flex-start",
            color: "#000000",
            fontWeight: "bold",
          }}
        >
          {name} *
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            // paddingBottom: "10px",
          }}
        >
          {options.length > 0 ? (
            options.map((option) => (
              <div key={option.id}>
                <input
                  checked={selected === option.id}
                  type="radio"
                  name={name}
                  value={option.id}
                  onChange={handleChange}
                  selected
                  // disabled={disabled}
                />
                <label
                  style={{ marginLeft: 8, color: "#000000", fontWeight: "200" }}
                >
                  {option.name}
                </label>
              </div>
            ))
          ) : name === "Learning Item" ? (
            <div
              style={{
                paddingBottom: "6px",
                paddingTop: "5px",
                textWrap: "wrap",
              }}
            >
              Please select a subject first to see the learning items
            </div>
          ) : name === "Levels" ? (
            <div
              style={{
                paddingBottom: "6px",
                paddingTop: "5px",
                textWrap: "wrap",
              }}
            >
              Please select both subject as well as learning item first
            </div>
          ) : null}
        </div>
      </div>
      {error && <div style={{ color: "red" }}>This field is required</div>}
    </div>
  );
};

function Languagetool() {
  const [chip, setChip] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [tags, setTags] = useState([]);
  const [allLearningItems, setAllLearningItems] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [showAudioDialog, setShowAudioDialog] = useState(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [learningItem, setLearningItem] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [text, setText] = useState("");
  const [meaningParent, setMeaningParent] = useState("");
  const [meaningEnglish, setMeaningEnglish] = useState("");
  const [textUsage, setTextUsage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);

  const fileInputRef = useRef(null);

  const history = useHistory();
  const location = useLocation();
  const routeData = location.state;

  console.log(
    "routeData from route: ",
    routeData,
    routeData?.id,
    routeData?.text
  );

  useEffect(() => {
    const getSubjects = async () => {
      const result = await getSuperAdminService().getFLNLearningSubjects();
      result && setSubjects(result?.data?.results);
      console.log("result:", result?.data?.results);
    };

    const getTags = async () => {
      const result = await getSuperAdminService().getFLNTags();
      result && setTags(result?.data?.results);
      console.log("tags:", result?.data?.results);
    };

    getSubjects();
    getTags();

    if (routeData) {
      getLearningItems(routeData?.subject);
      getLevels(routeData?.reading_level);
      setSelectedSubject(routeData?.subject);
      setLearningItem(routeData?.reading_level);
      setSelectedLevel(routeData?.difficulty_level);
      setChip(routeData?.tags);
      setText(routeData?.text);
      setMeaningParent(routeData?.meaning);
      setMeaningEnglish(routeData?.english_meaning);
      setTextUsage(routeData?.text_usage);
      setImageFile(routeData?.image_url);
      setAudioFile(routeData?.audio_url);
    }
  }, []);

  useEffect(() => {
    let timeout;
    if (message?.type === "success") {
      timeout = setTimeout(() => {
        setMessage(null);
      }, 5000);
    } else if (message?.type === "error") {
      timeout = setTimeout(() => {
        setMessage(null);
      }, 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [message]);

  const getLearningItems = async (id) => {
    const result = await getSuperAdminService().getFLNLearningItems(id);
    result && setAllLearningItems(result?.data?.results);
    console.log("setAllLearningItems result:", result?.data?.results);
  };

  const getLevels = async (id) => {
    const result = await getSuperAdminService().getFLNLevels(id);
    result && setAllLevels(result?.data?.results);
    console.log("setAllLevels result:", result?.data?.results);
  };

  const handleSubjectChange = (event) => {
    if (selectedLevel) {
      setLearningItem(null);
    }
    if (learningItem) {
      setSelectedLevel(null);
    }
    setSelectedSubject(Number(event.target.value));
    getLearningItems(Number(event.target.value));
  };

  const handleLearningItemChange = (event) => {
    if (selectedLevel) {
      setSelectedLevel(null);
    }
    setLearningItem(Number(event.target.value));
    getLevels(Number(event.target.value));
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(Number(event.target.value));
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const name = "fln_learning_tool";
        const response = await getSuperAdminService().addFilesOnS3(file, name);

        if (response && response?.data?.file) {
          console.log("image Result", response);
          setImageFile(response?.data?.file);
          // setImageFile(URL.createObjectURL(file));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleAudioChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // setAudioFile(URL.createObjectURL(file));
      try {
        const name = "fln_learning_tool";
        const response = await getSuperAdminService().addFilesOnS3(file, name);

        if (response && response?.data?.file) {
          console.log("audio Result", response);
          setAudioFile(response?.data?.file);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleRemoveAudio = () => {
    setAudioFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!selectedSubject) newErrors.selectedSubject = true;
    if (!learningItem) newErrors.learningItem = true;
    if (!selectedLevel) newErrors.selectedLevel = true;
    if (!text) newErrors.text = true;
    if (!imageFile) newErrors.imageFile = true;
    if (!chip.length) newErrors.chip = true;
    // if (!meaningParent) newErrors.meaningParent = true;
    // if (!meaningEnglish) newErrors.meaningEnglish = true;
    if (!audioFile) newErrors.audioFile = true;

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitFLNform = async (data) => {
    try {
      if (Object.keys(data)?.length === 0) {
        setMessage({
          type: "error",
          text: `You have not made any changes in the form. Please update any value.`,
        });
        return;
      }
      const result = routeData
        ? await getSuperAdminService().submitFLNUpdateform(routeData?.id, data)
        : await getSuperAdminService().submitFLNform(data);
      console.log("form result:", result);
      if (result?.status === 201) {
        console.log("successfully submitted");
        setShowSubmitDialog(true);
        // handleResetForm();
        setMessage({ type: "success", text: "Form successfully submitted" });
      } else if (result?.status === 200) {
        console.log("FLN Record successfully Updated");
        setShowSubmitDialog(true);
        // handleResetForm();
        setMessage({
          type: "success",
          text: "FLN Record successfully Updated",
        });
      } else {
        console.log("error:", result?.statusText);
        setMessage({
          type: "error",
          text: `${result?.statusText}. Please try again later.`,
        });
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(
          "error response",
          error.response,
          error.response?.data?.details,
          error.response?.data?.details?.type?.[0]
        );
        if (error.response.status === 400) {
          try {
            const detailsString = error.response?.data?.details.replace(
              /'/g,
              '"'
            );
            const details = JSON.parse(detailsString);
            console.log("error response details", details);
            if (details?.type?.[0] === "duplicate") {
              setShowDuplicateDialog(true);
            } else {
              console.error("An error occurred: " + error.response.status);
              setMessage({
                type: "error",
                text: `An error occurred. Please try again later.`,
              });
            }
          } catch (e) {
            console.error("Error parsing details: " + e);
            setMessage({
              type: "error",
              text: `Error parsing details. Please try again later.`,
            });
          }
          console.error("Bad Request (400)");
        } else {
          console.error("An error occurred: " + error.response.status);
          setMessage({
            type: "error",
            text: `${error.response?.text}. Please try again later.`,
          });
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
        setMessage({
          type: "error",
          text: `No response received. Please try again later.`,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(
          "error while submitting the form. Please try again later.",
          error?.message
        );
        setMessage({
          type: "error",
          text: `error while submitting the form. Please try again later.`,
        });
      }
      console.log("error in submitting the form", error);
    }
  };

  const handleFormSubmit = () => {
    if (!validateForm()) {
      console.log("Validation failed");
      return;
    }

    const ids = chip.map((tag) => tag?.id);

    let payload = {};
    if (routeData) {
      if (routeData?.subject !== selectedSubject)
        payload.subject = selectedSubject;

      if (routeData?.reading_level !== learningItem)
        payload.reading_level = learningItem;

      if (routeData?.difficulty_level !== selectedLevel)
        payload.difficulty_level = selectedLevel;

      if (routeData?.text !== text) payload.text = text;

      if (routeData?.image_url !== imageFile) payload.image_url = imageFile;

      if (routeData?.tags?.length > 0) {
        const tagIds = new Set(routeData?.tags?.map((tag) => tag.id));
        const allPresent = ids.every((id) => tagIds.has(id));
        if (!allPresent) {
          payload.tags = ids;
        }
      } else {
        payload.tags = ids;
      }

      if (routeData?.meaning !== meaningParent) payload.meaning = meaningParent;

      if (routeData?.english_meaning !== meaningEnglish)
        payload.english_meaning = meaningEnglish;

      if (routeData?.audio_url !== audioFile) payload.audio_url = audioFile;

      if (routeData?.text_usage !== textUsage) payload.text_usage = textUsage;
    } else {
      payload = {
        subject: selectedSubject,
        reading_level: learningItem,
        difficulty_level: selectedLevel,
        text: text,
        image_url: imageFile,
        tags: ids,
        // meaning: meaningParent,
        // english_meaning: meaningEnglish,
        audio_url: audioFile,
      };
      if (textUsage) {
        payload.text_usage = textUsage;
      }
      if (meaningParent) {
        payload.meaning = meaningParent;
      }
      if (meaningEnglish) {
        payload.english_meaning = meaningEnglish;
      }
    }
    console.log("payload", payload);

    try {
      submitFLNform(payload);
    } catch (err) {
      console.log("form error", err);
      setMessage({ type: "error", text: `${err}. Please try again later.` });
    }
  };

  const handleResetForm = (value = true) => {
    setChip([]);
    if (value) {
      allLearningItems?.length > 0 && setAllLearningItems([]);
      allLevels?.length > 0 && setAllLevels([]);
      selectedSubject !== null && setSelectedSubject(null);
      learningItem !== null && setLearningItem(null);
      selectedLevel !== null && setSelectedLevel(null);
    }
    text !== "" && setText("");
    meaningParent !== "" && setMeaningParent("");
    meaningEnglish !== "" && setMeaningEnglish("");
    textUsage !== "" && setTextUsage("");
    imageFile !== null && setImageFile(null);
    audioFile !== null && setAudioFile(null);
    Object.keys(errors).length !== 0 && setErrors({});
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    if (routeData) {
      history.push("/admin/languagetool", null);
    }
  };

  // const handleAddChip = (newChip) => {
  //   const newChips = [...chip];
  //   newChips.push(newChip);
  //   setChip(newChips);
  // };

  // const handleDeleteChip = (delchip, indexToRemove) => {
  //   const newArray = chip.filter((_, index) => index !== indexToRemove);
  //   setChip(newArray);
  // };

  // render tags
  const renderTags = () => {
    return (
      <>
        <div
          style={{ fontWeight: "bold", marginBottom: "10px", color: "#000000" }}
        >
          Enter Tags *
        </div>
        <div
          style={{
            width: "90%",
            border: "1px solid black",
            // padding: "10px 20px",
            borderRadius: "10px",
          }}
        >
          {/* <ChipInput
            fullWidth
            disableUnderline={true}
            value={chip}
            onAdd={(chip) => handleAddChip(chip)}
            onDelete={(chip, index) => handleDeleteChip(chip, index)}
          /> */}
          {/* <Autocomplete
            multiple
            freeSolo
            options={suggestions}
            value={chip}
            onChange={(event, newValue) => {
              setChip(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Enter tags"
                placeholder="Type and press enter"
                onKeyDown={(event) => {
                  if (event.key === "Enter" && event.target.value) {
                    handleAddChip(event.target.value);
                    event.preventDefault();
                  }
                }}
              />
            )}
          /> */}
          <Autocomplete
            multiple
            id="checkboxes-tags"
            options={tags}
            disableCloseOnSelect
            getOptionLabel={(option) => option.tag_name}
            getOptionSelected={(option, value) =>
              option.tag_name === value.tag_name
            }
            value={chip}
            onChange={(event, newValue) => {
              console.log("newValue: " + newValue.tag_name);
              setChip(newValue);
            }}
            renderOption={(option, { selected }) => {
              console.log("selected option", selected);
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.tag_name}
                </React.Fragment>
              );
            }}
            style={{ border: 0 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label=""
                placeholder=""
              />
            )}
          />
        </div>
        {errors.chip && (
          <div style={{ color: "red" }}>This field is required</div>
        )}
      </>
    );
  };

  const renderReadAloud = () => {
    return (
      <div style={{ display: "flex", margin: "20px 0px" }}>
        <div style={{ width: "70%" }}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              color: "#000000",
            }}
          >
            Read Aloud Text *
          </div>
          <input
            type="text"
            value={text}
            maxLength={100}
            onChange={(e) => setText(e.target.value)}
            style={{
              backgroundColor: "transparent",
              border: "1px solid black",
              width: "85%",
              height: "87px",
              borderRadius: "10px",
              margin: "0px",
            }}
          />
          {errors.text && (
            <div style={{ color: "red" }}>This field is required</div>
          )}
        </div>
        <div style={{ width: "20%" }}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              color: "#000000",
            }}
          >
            Read Aloud Image *
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <>
              <input
                // style={{
                //   backgroundColor: "transparent",
                //   border: "1px dotted black",
                //   width: "46%",
                //   height: "87px",
                //   borderRadius: "10px",
                // }}
                id="image-btn"
                accept="image/jpeg"
                label="Image"
                type="file"
                helperText="Choose Image file"
                onChange={handleImageChange}
                hidden
              />
              <label
                htmlFor="image-btn"
                for="image-btn"
                style={{
                  backgroundColor: "transparent",
                  border: "1px dotted black",
                  width: "50%",
                  height: "87px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  cursor: "pointer",
                  margin: "0px",
                }}
              >
                <BackupOutlined style={{ color: "#000000" }} />
                <div style={{ color: "#000000", fontWeight: "400" }}>
                  Upload Media
                </div>
              </label>
            </>
            {imageFile && (
              <img
                src={imageFile}
                alt="Uploaded"
                style={{
                  // marginTop: "20px",
                  // marginLeft: "10px",
                  maxWidth: "100%",
                  maxHeight: "85px",
                }}
              />
            )}
          </div>
          {errors.imageFile && (
            <div style={{ color: "red" }}>This field is required</div>
          )}
        </div>
      </div>
    );
  };

  const renderMeaning = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "20%" }}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              color: "#000000",
            }}
          >
            Meaning in Parent Language
          </div>
          <input
            type="text"
            style={{
              backgroundColor: "transparent",
              border: "1px solid black",
              width: "85%",
              height: "50px",
              borderRadius: "10px",
              margin: "0px",
            }}
            value={meaningParent}
            maxLength={100}
            onChange={(e) => setMeaningParent(e.target.value)}
          />
          {errors.meaningParent && (
            <div style={{ color: "red" }}>This field is required</div>
          )}
        </div>
        <div style={{ width: "20%" }}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              color: "#000000",
            }}
          >
            Meaning in English Language
          </div>
          <input
            type="text"
            style={{
              backgroundColor: "transparent",
              border: "1px solid black",
              width: "85%",
              height: "50px",
              borderRadius: "10px",
              margin: "0px",
            }}
            value={meaningEnglish}
            maxLength={100}
            onChange={(e) => setMeaningEnglish(e.target.value)}
          />
          {errors.meaningEnglish && (
            <div style={{ color: "red" }}>This field is required</div>
          )}
        </div>
        <div style={{ width: "60%" }}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              color: "#000000",
            }}
          >
            Use in word/sentence
          </div>
          <input
            type="text"
            style={{
              backgroundColor: "transparent",
              border: "1px solid black",
              width: "85%",
              height: "50px",
              borderRadius: "10px",
              margin: "0px",
            }}
            value={textUsage}
            maxLength={500}
            onChange={(e) => setTextUsage(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const renderAudio = () => {
    return (
      <>
        <div
          style={{
            marginLeft: "17%",
            width: "60%",
            backgroundColor: "#f2f4f5",
            border: "1px solid black",
            padding: "0px 20px",
            height: "8em",
            borderRadius: "10px",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: "#000000",
              marginTop: "10px",
            }}
          >
            Upload Natural Audio *
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              margin: "10px 10px",
            }}
          >
            <>
              <input
                ref={fileInputRef}
                // style={{
                //   backgroundColor: "transparent",
                //   border: "1px dotted black",
                //   // height: "50px",
                //   width: "30%",
                //   borderRadius: "10px",
                // }}
                id="audio-btn"
                accept="audio/*"
                type="file"
                label="Image"
                helperText="Choose Image file"
                onChange={handleAudioChange}
                hidden
              />
              <label
                htmlFor="audio-btn"
                for="audio-btn"
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px dotted black",
                  width: "30%",
                  height: "50px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  cursor: "pointer",
                }}
              >
                <BackupOutlined style={{ color: "#000000" }} />
                <div style={{ color: "#000000", fontWeight: "400" }}>
                  Upload Audio File
                </div>
              </label>
            </>
            {audioFile && (
              <div
                style={{ display: "flex", alignItems: "center", width: "40%" }}
              >
                <audio controls>
                  <source src={audioFile} type="audio/webm" />
                  Your browser does not support the audio element.
                </audio>
                <button
                  onClick={handleRemoveAudio}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <div
                // className={cls.record}
                style={{
                  width: "50px",
                  height: "50px",
                  border: "1px solid gray",
                  borderRadius: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#FFFFFF",
                }}
                onClick={() => {
                  setShowAudioDialog(true);
                  setAudioFile(null);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                }}
              >
                <Mic titleAccess="Record Live" />
              </div>
              <div>Record audio</div>
            </div>
          </div>
          {errors.audioFile && (
            <div style={{ color: "red", marginTop: "20px" }}>
              This field is required
            </div>
          )}
        </div>
      </>
    );
  };

  const renderButton = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px",
            marginBottom: "0px",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            form="fln-form"
            style={{
              backgroundColor: "#7965DA",
              borderRadius: "10px",
              width: "8%",
              color: "#fff",
            }}
          >
            Submit
          </Button>
          <Button
            style={{
              backgroundColor: "#FFF",
              borderRadius: "10px",
              borderWidth: "1px",
              width: "8%",
              color: "#000",
              borderColor: "#000",
              marginLeft: "15px",
            }}
            onClick={handleResetForm}
          >
            Reset
          </Button>
        </div>
        {message ? (
          <div
            style={{
              color: message?.type === "success" ? "green" : "red",
              marginTop: "10px",
              textAlign: "center",
              marginRight: "25px",
            }}
          >
            {message?.text}
          </div>
        ) : null}
      </div>
    );
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Dialog
        onClose={() => {
          setShowAudioDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showAudioDialog}
      >
        <VoiceRecorder
          onDone={async (file) => {
            // handleRecordedFile(file);
            let blobFile = new File(
              [file.blob],
              `${new Date().getTime()}.mp3`,
              {
                type: "audio/mp3",
              }
            );
            console.log("file", blobFile);
            try {
              const name = "fln_learning_tool";
              const response = await getSuperAdminService().addFilesOnS3(
                blobFile,
                name
              );

              if (response && response?.data?.file) {
                console.log("voice Result", response);
                setAudioFile(response.data.file);
                setShowAudioDialog(false);
              }
            } catch (err) {
              console.log(err);
            }
            // setAudioFile(file.url);
            // setShowAudioDialog(false);
          }}
        />
      </Dialog>

      <Dialog
        onClose={() => {
          setShowSubmitDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showSubmitDialog}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "344px",
              height: "231px",
            }}
          >
            <div
              style={{
                // height: "20%",
                width: "100%",
                display: "flex",
                flex: 0.2,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#CF45FF",
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Success
              </div>
            </div>
            <div
              style={{
                // height: "60%",
                width: "100%",
                display: "flex",
                flex: 0.6,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                fontSize: 20,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Your record is submitted successfully.
            </div>
            <div
              style={{
                // height: "60%",
                width: "100%",
                display: "flex",
                flex: 0.2,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                fontSize: 20,
                fontWeight: "600",
                textAlign: "center",
                color: "#567DF4",
                padding: "0px 10px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowSubmitDialog(false);
                }}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowSubmitDialog(false);
                  const value = false;
                  handleResetForm(value);
                }}
              >
                Upload next record
              </div>
            </div>
          </div>
        </>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowDuplicateDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showDuplicateDialog}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "344px",
              height: "231px",
            }}
          >
            <div
              style={{
                // height: "20%",
                width: "100%",
                display: "flex",
                flex: 0.2,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#CF45FF",
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Duplicate Record
              </div>
            </div>
            <div
              style={{
                // height: "60%",
                width: "100%",
                display: "flex",
                flex: 0.6,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                fontSize: 20,
                fontWeight: "600",
                textAlign: "center",
                padding: "0px 30px",
              }}
            >
              This record is already present. Please upload different record.
            </div>
            <div
              style={{
                // height: "60%",
                width: "100%",
                display: "flex",
                flex: 0.2,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                fontSize: 20,
                fontWeight: "600",
                textAlign: "center",
                color: "#567DF4",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowDuplicateDialog(false);
                }}
              >
                OK
              </div>
            </div>
          </div>
        </>
      </Dialog>

      <div className="whiteBackground p-4 milestones vh100">
        <h1 style={{ fontSize: "40px", fontWeight: "bold", color: "#000000" }}>
          FLN learning Tool Configuration
        </h1>

        <div style={{ fontSize: "20px", fontWeight: "bold", color: "#958989" }}>
          The section will be used for configuring the elements of FLN Learning
          Tool.
        </div>

        <form
          id="fln-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <div
            style={{
              display: "flex",
              width: "90%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0px",
            }}
          >
            <RenderField
              name="Select Subject"
              handleChange={handleSubjectChange}
              selected={selectedSubject}
              options={subjects}
              error={errors.selectedSubject}
              // disabled={routeData ? true : false}
            />
            <RenderField
              name="Learning Item"
              handleChange={handleLearningItemChange}
              selected={learningItem}
              options={allLearningItems}
              error={errors.learningItem}
              // disabled={routeData ? true : false}
            />
            <RenderField
              name="Levels"
              handleChange={handleLevelChange}
              selected={selectedLevel}
              options={allLevels}
              error={errors.selectedLevel}
              // disabled={routeData ? true : false}
            />
          </div>
          <div>{renderTags()}</div>
          <div>{renderReadAloud()}</div>
          <div>{renderMeaning()}</div>
          <div>{renderAudio()}</div>
          <div>{renderButton()}</div>
        </form>
      </div>
    </>
  );
}

export default Languagetool;