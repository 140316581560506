import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  Switch,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactStars from "react-rating-stars-component";
import { FormHelperText, Input } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import RateReviewIcon from "@material-ui/icons/RateReview";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
// import AudioRecorder from 'react-audio-recorder';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Modal from "@material-ui/core/Modal";
import { Helmet } from "react-helmet";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import DragHandle from "@material-ui/icons/DragHandle";
import Mic from "@material-ui/icons/Mic";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GetAppIcon from "@material-ui/icons/GetApp";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Snackbar from "components/Snackbar/Snackbar";
import TextEditor from "components/Editor/Editor";
import cls from "./ChapterPreview.module.css";
import html2pdf from "html2pdf.js";
import Iframe from "react-iframe-click";

//import Chips, { Chip } from 'react-chips'
import {
  getSuperAdminService,
  getContentWriterService,
  getSharedService,
} from "service/service";
import * as constants from "./../../common/constants";
import CreditCourtesy from "./CreditCourtesy/CreditCourtesy";
import ContentToolbar from "components/ContentToolbar/ContentToolbar";
import AlertDialog from "components/AlertDialog/AlertDialog";
import VoiceRecorder from "components/VoiceRecorder/VoiceRecorder";
import ChapterComments from "./ChapterComments/chapterComments";
import Paper from "@material-ui/core/Paper";
import GadgetPanel from "../../components/GadgetPanel/GadgetPanel";
import GridContainer from "components/Grid/GridContainer.js";
import { CloudDownload, SkipNext, SkipPrevious } from "@material-ui/icons";
import jsPDF from "jspdf";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ChipInput from "material-ui-chip-input";
import ReactTooltip from "react-tooltip";
import { cardTitle } from "assets/jss/material-dashboard-react";
import Group from "@material-ui/icons/Group";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { array } from "prop-types";
import {
  chapterPreview,
  uploadNewMedia,
  updateChapterNew,
  deleteChapterPart,
} from "service/WebSeparateService";
import httpV2 from "../../common/axiosV2";
import {
  chapterDetailsUpdate,
  CWChapterCreate,
} from "service/WebSeparateService";
import { deleteUpdateChapterPart } from "service/WebSeparateService";
import SharedService from "service/sharedService";
import CardFooter from "components/Card/CardFooter";
import QuizForm from "./Quiz/QuizForm";
import { getCategories } from "service/WebSeparateService";
import Quiz from "react-quiz-component";
import { createMCQ } from "service/WebSeparateService";
import { previewMCQ } from "service/WebSeparateService";
import { EditRounded } from "@material-ui/icons";
import { editQuiz } from "service/WebSeparateService";
import { deleteQuiz } from "service/WebSeparateService";
import {
  blackColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";

import specialImg from "../../assets/img/bloomsbox.svg";
import { getLearningElement } from "service/WebSeparateService";
import { Editor } from "@tinymce/tinymce-react";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const quiz1 = {
  quizTitle: "quiz 1",
  quizSynopsis: "rest",
  questions: [
    {
      question: "ques 1",
      questionType: "text",
      questionPic: "https://dummyimage.com/600x400/000/fff&text=X",
      answers: ["trets"],
      correctAnswer: "1",
      messageForIncorrectAnswer: "uysejgfdhkzxjcls",
      explanation: "asdhbxc,m ",
      point: "2",
    },
  ],
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span>{children}</span>}
    </div>
  );
}

const ChapterPreview = (props) => {
  const classes = useStyles();

  if (!props.location.state) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user.user_type === constants.USER_TYPE.SUPER_ADMIN ||
      user.user_type === constants.USER_TYPE.ADMIN
    ) {
      document.location.href = "/admin/chapters";
    } else {
      document.location.href = "/user/lessons";
    }
  }

  const { chapter } = props.location.state;
  const [userChapter, setUserChapter] = useState({});
  const [partName, setPartName] = useState(null);
  const [tagName, setTagName] = useState(null);
  const [partColor, setPartColor] = useState("#F0F0F0");
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const [content, setContent] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [changeChapterStatusOn, setChangeChapterStatusOn] = useState(false);
  const [chapterStatus, setChapterStatus] = useState("");
  const [propsState, setPropsState] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("#");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDeletePartDialog, setShowDeletePartDialog] = useState(false);
  const [partToBeDelete, setPartToBeDelete] = useState(null);

  const [showDeleteFieldDialog, setShowDeleteFieldDialog] = useState(false);
  const [fieldToBeDelete, setFieldToBeDelete] = useState(null);
  const [isNewPart, setIsNewPart] = useState(false);

  const [DupClasses, setDupClasses] = useState([]);
  const [DupMediums, setDupMediums] = useState([]);
  const [DupSubjects, setDupSubjects] = useState([]);
  const [DupStates, setDupStates] = useState([]);

  const [DupClass, setDupClass] = useState(null);
  const [DupMedium, setDupMedium] = useState(null);
  const [DupSubject, setDupSubject] = useState(null);
  const [DupChapterName, setDupChapterName] = useState(null);
  const [DupChapterNumber, setDupChapterNumber] = useState(null);
  const [DupState, setDupState] = useState(null);
  const [editChapterName, setEditChapterName] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadingUpdatePart, setLoadingUpdatePart] = useState(false);
  const [showAudioDialog, setShowAudioDialog] = useState(false);
  const [showPDFDialog, setShowPDFDialog] = useState(false);
  const [audioContentExtras, setAudioContentExtras] = useState({});
  const [progressVariant, setProgressVariant] = useState("determinate");
  const [chapterPdf, setchapterPdf] = useState([]);
  const [chapterNewId, setchapterNewId] = useState(null);
  const [chapterComments, setChapterComments] = useState({
    chapterDetails: [],
    total_count: null,
  });
  const [commentAdded, onChangeComment] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [recorPerPage, setRecordPerPage] = useState(5);
  const [adminData, setAdminData] = useState({});
  const [editing, setEditing] = useState(false);
  const [uploadSearch, setUploadSearch] = useState(false);
  const [partAndTagData, setPartAndTagData] = useState({
    part_id: null,
    partName: "",
    tagName: "",
  });
  const [isEditChapterName, setChapterName] = useState(false);

  const [numbers, setNumbers] = useState([
    {
      id: "id " + 1,
      content: "content " + 1,
    },
    {
      id: "id " + 2,
      content: "content " + 2,
    },
    {
      id: "id " + 3,
      content: "content " + 3,
    },
  ]);

  const [showPublishConfirm, setShowPublishConfirm] = useState(false);

  const [editSubject, setEditSubject] = useState(null);

  const [gadgetModalOpen, setAddWidgetModal] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);
  const [userList, setUserList] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const [showSaveChapterDialog, setOnSaveChapter] = React.useState(false);
  const [borderId, setBorderId] = useState(0);
  const [chip, setChip] = useState([]);
  const [uploadID, setuploadID] = useState([]);
  const [updateID, setupdateID] = useState([]);
  const [openUser, setOpenUser] = useState(false);
  const [profileInfo, setProfileInfo] = useState([]);
  const [createrprofileInfo, setCreaterProfileInfo] = useState([]);
  const [pdfDialog, setPdfDialog] = useState(false);
  const [CreaterInfo, setCreaterInfo] = useState(false);
  const [tagchip, setTagChip] = useState([]);
  const [discussion, setDiscussion] = useState(false);
  const [reviewChapter, setReview] = useState(false);
  const [autoAdminID, setAutoadminID] = useState(0);
  const [updateAdminID, setAdminID] = useState([]);
  const [ratingGraph, setRatingGraph] = useState({
    chart: {
      type: "pie",
      width: (window.innerWidth - 460) / 4,
    },
    title: {
      text: "rating",
    },
    series: [
      {
        depth: 45,
        data: [],
        keys: ["y", "name"],
        type: "pie",
      },
    ],
  });
  const [toggle, settoggle] = useState(false);
  const [showPublisher, setShowPublisher] = useState(false);
  const [toggle1, settoggle1] = useState(false);
  const [chipArray, setChipArray] = useState([]);
  const [chipArrayLength, setChipArrayLength] = useState("");
  const [imageBox, setImageBox] = useState(false);
  const [imageSearchBox, setImageSearchBox] = useState(false);
  const [imgIndex, setImgIndex] = useState("");
  const [subIndex, setSubIndex] = useState("");
  const [mediaIndex, setMediaIndex] = useState("");
  const [mediaList, setMediaList] = useState([]);
  const [mediaUrl, setMediaUrl] = useState("");
  const [commonID, setCommonID] = useState("");
  const [commonType, setCommonType] = useState("");
  const [isDelete, setisDelete] = useState(false);
  const [isChapterDelete, setIsChapterDelete] = useState(false);
  const [offset, setOffset] = useState(0);
  const [tagMedia, setTagMedia] = useState("");
  const [tagSearch, setTagSearch] = useState("");
  const [subSearch, setSubSearch] = useState("");
  const [uploadDialog, setUploadDialog] = useState(false);
  const [QuizDialog, setQuizDialog] = useState(false);
  const [subjectMedia, setSubjectMedia] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [quizShow, setQuizShow] = useState(false);
  const [quizStore, setQuizStore] = useState({});
  const [MediaName, setMediaNanme] = useState("");
  const [selectClass, setSelectClass] = useState("");
  const [editMCQ, setEDitMCQ] = useState(false);
  const [mcqID, setMCQID] = useState("");
  const [mcqQues, setMCQques] = useState("");
  const [editExplain, setEditExplain] = useState("");
  const [editCorrectAns, setEditCorrectAns] = useState("");
  const [editOpt, setEditOpt] = useState("");
  const [editOptID, setEditOptID] = useState("");
  const [editCorrectExplain, setEditCorrectExplain] = useState("");
  const [editInCorrectExplain, setEditInCorrectExplain] = useState("");
  const [editExplainLink, setEditExplainLink] = useState("");
  const [quesDialog, setQuesDialog] = useState(false);
  const [deleDialog, setDeleDialog] = useState(false);
  const [point, setPoint] = useState("");
  const [totalPoint, setTotalPoint] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [showCWPublishConfirm, setShowCWPublishConfirm] = useState(false);
  const [user, setUser] = useState(false);
  const [outcomeList, setOutcomeList] = useState([]);
  const [outcomeID, setOutcomeID] = useState("");
  const [eachMcq, setEachMcq] = useState(false);
  const [deleteMCQID, setDeleteMCQID] = useState("");
  const [visitedMCQ , setVisitedMCQ] = useState(false)
  const [newUrl,setNewUrl] = useState([])
  const [allMCQ ,setAllMCQ ] = useState([])
  const [editAnswer,setEditAnswer] = useState([])
  const [editTitle,seteditTitle] = useState([])
  const [urdufont, seturduFont] = useState(false);
  const [mcqTitleFont, setMcqTitleFont] = useState(false);
  const [isUrduCursor, setUrduCursor] = useState(false);
    const [editsynopsis,seteditsynopsis] = useState([])
    const editorRef = useRef(null);
        const [indexQuestion, setindexQuestion] = useState(0)
        const [editVideoUrl, seteditVideoUrl] = useState(null)
        const [editMediafile, seteditMediafile] = useState(null)
        const [mediaType, setmediaType] = useState(null)
        const [userDuplicateArr, setUserDuplicateArr] = useState([])
  // useEffect(() => {
  //     addWirisScript();
  //     let param = {
  //       chapter_id: chapter.chapter_id,
  //     };
  //     setPropsState(chapter);
  //     getSharedService()
  //       .getChapterPreview(param)
  //       .then((result) => {
  //         result = result.data;
  //         console.log(result,"result");
  //         if (result && result.status === 200) {
  //           setUserChapter({
  //             meta: {
  //               ...result.response.chapter,
  //               createdBy: chapter.chapter_created_by,
  //               rating : chapter.rating,
  //               reviews : chapter.reviews
  //             },
  //             parts: result.response.chapter_parts,
  //             credits: result.response.credits,
  //           });
  //         }
  //       });
  //   }, []);

  useEffect(() => {
    addWirisScript();
    setPropsState(chapter);

    chapterPreview(chapter.chapter_id).then((result) => {
      console.log(result, "resp");
      result = result.data;
      console.log(result.response.chapter.reviews, "result");
      setUserChapter({
        meta: {
          ...result.response.chapter,
          createdBy: chapter.chapter_created_by,
          rating: result.response.chapter.rating,
          reviews: result.response.chapter.reviews,
        },
        parts: result.response.chapter_parts,
        credits: result.response.credits,
      });
      console.log(userChapter, "meta");
    });
  }, []);

  useEffect(() => {
    let { chapter } = props.location.state;
    console.log(chapter, "chapter from props");
    setEditChapterName(chapter);
  }, []);

  const mcqRender = async () => {
    console.log(userChapter.parts[currentTabIndex].mcq[0], "mcq");
    let data = userChapter.parts[currentTabIndex].mcq[0];
    let marks = 0;
    let time = 0;
    let qParam = {}
    const val = newUrl.findIndex(x => x.id === currentTabIndex)
    if(val == -1){
          qParam =data
    }
    else{
      qParam = newUrl[val]
    }
    if (qParam) {
      getSharedService()
        .getMCQFromUrl(qParam)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          console.log(JSON.parse(res), "data");
          let data = JSON.parse(res);
          setQuizStore(data);
          console.log(data,quizStore,"454544")
          // if(visitedMCQ === true){
          //   setQuizStore(quizStore);
          // }
          // else{
          //   setQuizStore(data);
          // }
          data.questions.map((resp) => {
            marks = marks + parseInt(resp.point);
          });
          data.questions.map((resp) => {
            time = time + parseInt(resp.time);
          });
          console.log(marks, "marks");
          setTotalPoint(marks);
          setTotalTime(time);
          setVisitedMCQ(true)
        });
    } else {
      setMessage("No mcq for this part");
    }

    // previewMCQ().then(async (res)=>{
    //   console.log(res.data.results[0])
    //    getSharedService().getMCQFromUrl(res.data.results[0]).
    //   then((res)=>{
    //     return res.json()
    //   }).then((res)=>{
    //     console.log(JSON.parse(res),"data")
    //     let data =JSON.parse(res)
    //      setQuizStore(data)
    //   })

    //   console.log(quizStore,"data")
    // })
  };
  const getAllMedia = (chapterName) => {
    console.log(commonType, "commonType", offset);
    httpV2
      .get(`media/`, {
        params: {
          media_type:
            commonType == "IMG"
              ? "IMAGE"
              : commonType == "json"
              ? "LOTTY FILE"
              : commonType,
          offset: offset,
          tags: tagSearch ? tagSearch : null,
          name: chapter.chapter_name,
          category: subSearch ? subSearch : null,
          user: user,
        },
      })
      .then((data) => {
        console.log(data.data.results, "data");
        const list = data.data.results;

        if (list) {
          setMediaList(list);
        }
        console.log(mediaList, "mediaList");
      });
  };

  const uploadMedia = (mediaUrl) => {
    setImageSearchBox(false);
    if (mediaUrl) {
      setMessage("uploaded successfully");

      let type = commonType;
      let param = {
        url: mediaUrl ? mediaUrl : "",
      };
      console.log(param, commonID, "x");

      let updatedContent = [...content];
      let value = mediaUrl;
      let content_id = null;

      if (type == "IMG" || type == "GIF" || type == "json") {
        value = {
          filePath: mediaUrl,
          description:
            updatedContent[commonID] && updatedContent[commonID].value
              ? updatedContent[commonID].value.description
              : "",
        };
      }
      console.log(value, "value");

      updatedContent[commonID] = {
        type,
        content_id,
        value: value,
      };
      console.log(updatedContent, "updatedContent");
      if (currentTabIndex == userChapter.parts.length - 1)
        setContent(updatedContent);

      let contentParts = [...userChapter.parts];
      if (type == "IMG" || type == "GIF" || type == "json") {
        value = {
          filePath: mediaUrl,
          description:
            contentParts[currentTabIndex].content[commonID].value.description,
        };
      }
      console.log(value, "updatedContent 1");

      contentParts[currentTabIndex].content[commonID] = {
        type,
        content_id,
        value: value,
      };
      //   contentParts[currentTabIndex].isUpdated = true
      // console.log("isUpdated from onImageInputChange",currentTabIndex)
      setUserChapter({
        ...userChapter,
        parts: contentParts,
      });
      console.log(userChapter.parts, "updatedContent 1");
    }
  };
  const removeAllChildNodes = (parent) => {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = (e) => {
    const toolbarNodes = document.querySelectorAll(".tox-silver-sink");

    toolbarNodes.forEach((node) => {
      //removeAllChildNodes(node);
    });
  };

  useEffect(() => {
    getChapterPartContent(userChapter.meta, userChapter.parts);
    // partsForDuplicate(userChapter.parts)
  }, [currentTabIndex, userChapter]);
  useEffect(() => {
    getAllMedia(offset);
  }, [offset]);
  useEffect(() => {
    getAllMedia(user);
  }, [user]);
  useEffect(() => {
    getClassesFromDistrict();
  }, [propsState]);

  const getClassesFromDistrict = async () => {
    if (propsState) {
      const param = {
        district_id: propsState.district_id,
      };

      let classesRes = [];

      if (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).user_type ===
          constants.USER_TYPE.SUPER_ADMIN
      ) {
        classesRes = await getSuperAdminService().getAllClassesFromDistrict(
          param
        );
      } else {
        classesRes = await getContentWriterService().getAdminCWclasses(param);
      }
      classesRes = classesRes.data;

      if (classesRes && classesRes.status == 200) {
        setDupClasses(classesRes.response);
        setDupClass(classesRes.response[0].class_id);
        getStates();
      } else {
        getStates();
      }
    }
  };

  useEffect(() => {
    getTeacherList();
    setLoading();
  }, []);

  const getTeacherList = async () => {
    if (userList.length > 0) return;
    let data = {
      page_number: 1,
      record_per_page: 50,
      order_column: "created_at",
      order_type: 1,
      user_type: "",
      searchText: "",
    };
    let allUserRes = await getSuperAdminService().getAllAdminContentWriterList(
      data
    );
    allUserRes = allUserRes.data;
    if (allUserRes && allUserRes.status == 200) {
      let users = allUserRes.response.adminTeacherList;
      users.sort((a, b) => (a.first_name < b.first_name ? -1 : 1));
      console.log(users, "users sort");
      setUserList(users);
    } else {
      setUserList([]);
    }
  };

  const getChapterPartContent = async (chapter, parts) => {
    if (!chapter && !parts) return;

    const part = parts[currentTabIndex];
    setTagChip(part.tags);
    //tagchip.push(part.tags)
    console.log(part.tags, "part.tags");
    console.log(tagchip, "tagchip");
    if (part.tags && part.tags.length > 0) {
      const split_string = part.tags.split(",");
      console.log(split_string, "tagchip");
      setChipArray(split_string);
    } else {
      setChipArray([]);
    }

    // const objData = Object.assign({}, split_string);
    // console.log(objData,"obj");
    // var res = Object.keys(objData).map(function(name){
    //   var obj = {};
    //   obj[name] = objData[name];
    //   return obj;
    // });

    // console.log(res,"objData");

    console.log(part, parts, "parts", currentTabIndex);
    if (part.content && part.content.length) {
      return;
    }
    if (!part.part_id) return;
    let param = {
      part_id: part.part_id,
    };
    // const http = axios.create({
    //   baseURL: Web_separate,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization" : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozODc0MiwiZXhwIjoxNjI2ODc5NjA1LCJpYXQiOjE2MjQyODc2MDV9.rMKcqdexK0m7OsucZdG039I-taPmhbsFxZLvkca5GLw"
    //   },
    // });

    let updatedStatusRes = await httpV2.get(`contenturl/${part.part_id}/`);
    console.log(updatedStatusRes.data, "part.part_id");
    const chapterContent = await getSharedService().getContentFromUrl(
      updatedStatusRes.data
    );
    const contentTrans = await chapterContent.json();
    console.log(userChapter, "contentTrans");

    // let partRes = await getSharedService().getChapterPartContentNew(param);
    // console.log(partRes, "partRes from chapterpreview");
    // partRes = partRes.data;
    if (contentTrans) {
      let p = [];
      // partRes.response.content = await getContentFromUrl(partRes.response);
      //console.log(partRes.response.content, "partRes.response.content");
      for (let key in contentTrans) {
        p.push(contentTrans[key]);
      }
      // const updatedParts = parts.map((p) => ({
      //   ...p,
      //   // content_id:
      //   //   p.content && p.content.length
      //   //     ? p.content[0].content_id
      //   //     : partRes.response.content_id,
      //   content_id: partRes.response.content_id,
      // }));
      const updatedParts = [...parts];
      console.log(
        updatedParts[currentTabIndex],
        "updatedParts[currentTabIndex]"
      );
      console.log(updatedStatusRes.data.content_id, "ontentTrans.content_id");
      updatedParts[currentTabIndex].content_id = contentTrans[0].content_id;
      console.log(updatedParts, "updatedParts", p);

      updatedParts[currentTabIndex].content = p;

      // if (updatedParts.findIndex((p) => !p.hasOwnProperty("part_id")) == -1) {
      //   updatedParts.push({
      //     part_name: "",
      //     tags: "",
      //     content: [],
      //   });
      // }
      setUserChapter({
        ...userChapter,
        meta: chapter,
        parts: updatedParts,
      });
    } else {
      return;
      // let partRes = await getSharedService().getChapterPartContent(param);
      // partRes = partRes.data;
      // if (partRes && partRes.status === 200) {
      //   let p = [];
      //   for (let key in partRes.response.content) {
      //     p.push(partRes.response.content[key]);
      //   }
      //   const updatedParts = parts.map((p) => ({
      //     ...p,
      //     content_id: partRes.response.content_id,
      //   }));

      //   updatedParts[currentTabIndex].content = p;
      //   setUserChapter({
      //     ...userChapter,
      //     meta: chapter,
      //     parts: updatedParts,
      //   });
      // }
    }
  };

  const addData = (e, type) => {
    let updatedContent = [...content];
    console.log(
      contentIndex,
      "contentIndex",
      updatedContent,
      currentTabIndex,
      "length",
      userChapter.parts.length
    );
    updatedContent.splice(contentIndex + 1, 0, {
      type: type,
      data: {
        type,
        value: "",
        tag: e.currentTarget.name,
      },
    });
    if (currentTabIndex == userChapter.parts.length - 1) {
      setContent(updatedContent);
    }
    const parts = [...userChapter.parts];
    const newContent = {
      ...updatedContent[updatedContent.length - 1].data,
      sequence: parts[currentTabIndex].content.length || 0,
      content_id: parts[currentTabIndex].content[0]
        ? parts[currentTabIndex].content[0].content_id
        : "",
    };

    // parts[currentTabIndex].content = [
    //   ...parts[currentTabIndex].content,
    //   newContent,
    // ];

    parts[currentTabIndex].content.splice(contentIndex + 1, 0, newContent);
    console.log(parts[currentTabIndex].content, "content");

    // // parts[currentTabIndex].content.splice(contentIndex + 1, 0, type)
    // // parts[currentTabIndex].data.splice(contentIndex + 1, 0,
    // //   { type, value: "", tag: e.currentTarget.name })

    setUserChapter({
      ...userChapter,
      parts: parts,
    });
    setContentIndex(0);
  };

  const handleChange = (event, newValue) => {
    setVisitedMCQ(false)
    console.log(newValue, "newValue");
    if (document.querySelector("#mainPanel")) {
      document.querySelector("#mainPanel").scrollTo(0, 0);
    }
    addWirisScript();
    // if(newValue === userChapter.parts.length){
    //   let parts = [...userChapter.parts];
    //   parts.push({
    //     part_name: "",
    //     tags: "",
    //     color: "#f0f0f0",
    //     content: [],
    //     data: [],
    //   });
    //   setUserChapter({
    //     ...userChapter,
    //     parts: parts,
    //   });
    // }
    setCurrentTabIndex(newValue);
    // console.log(currentTabIndex,"currentTabIndex --",newValue)
    setQuizShow(false);
  };

  const addWirisScript = () => {
    const scriptTag = document.getElementById("wiris");
    if (scriptTag) scriptTag.remove();
    const script = document.createElement("script");
    script.src =
      "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
    script.async = true;
    script.id = "wiris";
    document.body.appendChild(script);
  };

  const checkForGFormInput = (val) => {
    console.log(val, "e from onblur from checkForGFormInput---");
    let flag = false;
    if (val) {
      flag = val.includes("https://docs.google.com/forms/");
      if (!flag) {
        setError(true);
        setMessage("Invalid Url. Please check URL");
      } else {
        setError(false);
        setMessage("");
      }
    }
  };

  const validateFileInput = (file, type) => {
    let isFileValid = false;
    let message = "Please choose valid file type";
    let fileExt = file.name.split(".").pop().toString().toLowerCase();

    let fileSize = +(file.size / (1024 * 1024)).toFixed(2);
    if (fileSize < 7) {
      isFileValid = true;
    } else {
      message = "File is too large. Please upload file less than 7MB size";
      isFileValid = false;
      return {
        isFileValid: isFileValid,
        message: message,
      };
    }
    if (type == "IMG") {
      if (
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png"
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "PDF") {
      if (file.type == "application/pdf" && fileExt == "pdf") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "PPT") {
      if (
        (file.type == "application/vnd.ms-powerpoint" ||
          file.type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
        (fileExt == "ppt" || fileExt == "pptx")
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "GIF") {
      if (file.type == "image/gif" && fileExt == "gif") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "json") {
      console.log(fileExt, "type");
      if (file.type == "application/json" && fileExt == "json") {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    } else if (type == "AUDIO") {
      if (
        (file.type == "audio/mp3" || file.type == "audio/mpeg") &&
        fileExt == "mp3"
      ) {
        isFileValid = true;
      } else {
        isFileValid = false;
      }
    }

    return {
      isFileValid: isFileValid,
      message: message,
    };
  };

  const onImageInputChange = async (e, index, type, content_id) => {
    // if(type == "PDF" || type == "IMG" || type == "GIF" || type === "AUDIO" || type === "PPT" || type == "json")
    //     {
    //         let listID = document.querySelectorAll('[id^=grid]');
    //         let i;
    //         for (i = 0; i < listID.length; i++) {
    //             listID[i].style.border = "1px solid #295939";
    //             listID[i].style.boxShadow = "none";
    //         }

    //         document.getElementById(`grid${index}`).style.boxShadow = "none";
    //         document.getElementById(`grid${index}`).style.border =  "1px solid #f51000";
    //     }

    // let file = e.hasOwnProperty("currentTarget") ? e.currentTarget.files[0] : e;
    // console.log(file.name,"e");
    // let fileExt = file.name.split(".").pop().toString().toLowerCase();
    // let fileName = file.name.split(".")[0];
    // const updatedFile = new File([file], `${fileName}.${fileExt}`);
    // let formData = new FormData();
    // formData.append("content_id", content_id);
    // formData.append("filename", updatedFile);
    // console.log(formData,"formdara");

    // if (e.hasOwnProperty("currentTarget")) {
    //   const { isFileValid, message } = validateFileInput(file, type);
    //   if (!isFileValid) {
    //     e.target.value = null;
    //     e.currentTarget.value = null;
    //     setMessage(message);
    //     setError(true);
    //     return;
    //   }
    // }
    uploadID.push(content_id);
    console.log(uploadID, "form id");

    const fd = new FormData();
    fd.append("file", e.currentTarget ? e.currentTarget.files[0] : e);
    let media = e.currentTarget ? e.currentTarget.files[0] : e;
    console.log(media, "media");
    fd.append(
      "media_type",
      media.type == "image/jpeg"
        ? "IMAGE"
        : media.type == "image/gif"
        ? "GIF"
        : media.type == "application/pdf"
        ? "PDF"
        : media.type == "application/json"
        ? "LOTTY FILE"
        : media.type == "audio/mp3" || "audio/mpeg"
        ? "AUDIO"
        : "PPT"
    );
    fd.append("name", `${userChapter.meta.chapter_name}_${MediaName}`);
    console.log(MediaName, "MediaName", userChapter);
    if (
      media.type == "image/jpeg" ||
      media.type == "image/gif" ||
      media.type == "application/json"
    ) {
      console.log("in");
      fd.append("tags", tagMedia);
      fd.append("classes", selectClass);
      console.log(subjectMedia, "subSearch");
      fd.append("category", [subjectMedia]);
    }
    // let sub = subjectMedia == 1 ? "Marathi"
    // : subjectMedia == 2 ? "English"
    // : subjectMedia == 3 ? "Hindi"
    // : subje3ctMedia == 2 ? "English"

    try {
      // let mediaRes = await getContentWriterService().uploadChapterMedia(
      //   formData,
      //   (progress) => {
      //     let current = (progress.loaded / progress.total) * 100;
      //     setUploadProgress(`${current}#${index}`);
      //   }
      // );

      console.log("audio");

      let mediaRes = await uploadNewMedia(fd);

      //let mediaRes = await http.post(`media/`,fd)
      console.log(mediaRes, "mediaRes");
      console.log(mediaRes.status, "mediaRes");
      if (mediaRes.status == 201) {
        let updatedContent = [...content];
        console.log(mediaRes.data, "mediaRes");
        mediaRes = mediaRes.data;
        console.log(mediaRes, "updatedContent");
        let value = mediaRes.data.file_url;
        if (type == "IMG" || type == "GIF") {
          value = {
            filePath: mediaRes.data.file_url,
            description:
              updatedContent[index] && updatedContent[index].value
                ? updatedContent[index].value.description
                : "",
          };
        }
        let content_id = mediaRes.data.media_id;
        updatedContent[index] = {
          type,
          content_id,
          value: value,
        };
        console.log(updatedContent[index], "updatedContent[index]");
        if (currentTabIndex == userChapter.parts.length - 1)
          setContent(updatedContent);

        let contentParts = [...userChapter.parts];
        if (type == "IMG" || type == "GIF" || type == "json") {
          value = {
            filePath: mediaRes.data.file_url,
            description:
              contentParts[currentTabIndex].content[index].value.description,
          };
        }
        contentParts[currentTabIndex].content[index] = {
          type,
          content_id,
          value: value,
        };
        //   contentParts[currentTabIndex].isUpdated = true
        // console.log("isUpdated from onImageInputChange",currentTabIndex)
        console.log(contentParts[currentTabIndex].content[index], "update");
        setUserChapter({
          ...userChapter,
          parts: contentParts,
        });
        setUploadDialog(false);
      } else {
        setMessage("Uploading failed");
        setError(true);
        setUploadDialog(false);
      }
    } catch (error) {
      setMessage("Uploading failed");
      setError(true);
      setUploadDialog(false);
    }
  };
  const partBorder = () => {
    let id = document.getElementById("tag");
    console.log(id, "id");
  };
  const borderFocused = (e, content_id, index, type) => {
    console.log(content, "usestae");
    uploadID.push(content_id);
    setBorderId(index);
    if (
      type == "TEXT" ||
      type == "IMG" ||
      type == "GIF" ||
      type === "VIDEO" ||
      type === "Link" ||
      type === "gForm" ||
      type === "json"
    ) {
      console.log("border");
      let listID = document.querySelectorAll("[id^=grid]");
      let i;
      for (i = 0; i < listID.length; i++) {
        listID[i].style.border = "1px solid #295939";
        listID[i].style.boxShadow = "none";
      }
      console.log(`grid${index}`, "index of border");
      console.log(borderId, "bID");
      document.getElementById(`grid${index}`).style.boxShadow =
        "5px 10px #e4ebf2";
      document.getElementById(`grid${index}`).style.border =
        "3px solid #10179c";
    }
  };
  const onChapterTextChange = (
    e,
    index,
    type,
    content_id,
    tag = "",
    color = "#f0f0f0"
  ) => {
    uploadID.push(content_id);
    console.log(content_id, "content_id");
    let value =
      type == "TEXT" ? e : e.hasOwnProperty("target") ? e.target.value : e;
    let updatedContent = [...content];
    // if(type == "TEXT" || type == "IMG" || type == "GIF" || type === "VIDEO" || type === "Link"  || type === "gForm")
    //     {
    //       console.log("border");
    //         let listID = document.querySelectorAll('[id^=grid]');
    //         let i;
    //         for (i = 0; i < listID.length; i++) {
    //             listID[i].style.border = "1px solid #295939";
    //             listID[i].style.boxShadow = "none";
    //         }
    //           console.log(`grid${index}`,"index of border");
    //           console.log(editing,"");
    //         document.getElementById(`grid${index}`).style.boxShadow = "none";
    //         document.getElementById(`grid${index}`).style.border = "1px solid #f51000";
    //       }
    if (type === "Link") {
      value = {
        name:
          e.target.name === "linkName"
            ? e.target.value
            : userChapter.parts[currentTabIndex].content[index].value.name,
        value:
          e.target.name === "linkValue"
            ? e.target.value
            : userChapter.parts[currentTabIndex].content[index].value.value,
        tag,
      };
    }

    if (type === "VIDEO") {
      const video = userChapter.parts[currentTabIndex].content[index];
      const url = video.value.hasOwnProperty("url")
        ? video.value.url
        : video.value;
      const desc = video.value.hasOwnProperty("description")
        ? video.value.description
        : "";

      value = {
        url: typeof e === "object" ? e.target.value : url,
        description: typeof e === "string" ? e : desc,
        tag,
      };
    }

    if (type === "IMG" || type == "GIF" || type == "json") {
      const id =
        type == "IMG"
          ? `#image-file-${index}`
          : type == "GIF"
          ? `#gif-file-${index}`
          : type == "json"
          ? `#json-file-${index}`
          : null;
      const files = document.querySelector(id)
        ? document.querySelector(id).files
        : null;
      value = {
        filePath: userChapter.parts[currentTabIndex].content[index].value
          ? userChapter.parts[currentTabIndex].content[index].value.filePath
          : typeof userChapter.parts[currentTabIndex].content[index].value ==
            "object"
          ? ""
          : userChapter.parts[currentTabIndex].content[index].value,
        description: e,
      };
    }

    updatedContent[index] = {
      type,
      content_id,
      value: value,
      tag,
      color,
    };

    if (currentTabIndex === userChapter.parts.length - 1)
      setContent(updatedContent);

    const chapterParts = [...userChapter.parts];
    chapterParts[currentTabIndex].content[index] = updatedContent[index];
    // chapterParts[currentTabIndex].isUpdated = true
    // console.log("isUpdated from onChapterTextChange",currentTabIndex)
    setUserChapter({
      ...userChapter,
      parts: chapterParts,
    });
  };

  const onDeleteContentField = (index) => {
    setShowDeleteFieldDialog(false);
    setIsNewPart(false);
    let updatedContent = [...content];
    updatedContent.splice(index, 1);
    setContent(updatedContent);
  };
  const ratingStar = {
    size: 30,
    value: userChapter.meta ? userChapter.meta.rating : "",
    edit: false,
  };
  const noOfRatings = () => {
    let FiveCounter = 0;
    let FourCounter = 0;
    let threeCounter = 0;
    let TwoCounter = 0;
    let OneCounter = 0;
    userChapter.meta.reviews.map((data) => {
      console.log(data, "data");
      let ratingCount = data.rating;
      if (ratingCount == "5") {
        FiveCounter++;
      }
      if (ratingCount == "4") {
        FourCounter++;
      }
      if (ratingCount == "3") {
        threeCounter++;
      }
      if (ratingCount == "2") {
        TwoCounter++;
      }
      if (ratingCount == "1") {
        OneCounter++;
      }
    });
    console.log(FiveCounter, "FiveCounter", OneCounter);
    let option = {
      chart: {
        type: "pie",
        width: (window.innerWidth - 460) / 4,
      },
      title: {
        text: "rating",
      },
      series: [
        {
          depth: 45,
          data: [
            [FiveCounter, "5"],
            [FourCounter, "4"],
            [threeCounter, "3"],
            [TwoCounter, "2"],
            [OneCounter, "1"],
          ],
          keys: ["y", "name"],
          type: "pie",
        },
      ],
    };
    let series = [
      {
        depth: 45,
        data: [
          [FiveCounter, "5"],
          [FourCounter, "4"],
          [threeCounter, "3"],
          [TwoCounter, "2"],
          [OneCounter, "1"],
        ],
        keys: ["y", "name"],
        type: "pie",
      },
    ];

    setRatingGraph({
      ...ratingGraph,
      series: series,
    });
    console.log(ratingGraph, "ratingGraph");
  };

  const getCategory = async () => {
    let data = await getCategories();
    console.log(data);
    data = data.data.results;
    setCategoryList(data);
    console.log(categoryList, "categoryList", data);
  };
  const renderContentFields = () => {
    const array = content.map((c) => c.type);
    if (array.length > 0) {
      return array.map((type, index) => {
        return (
          <React.Fragment key={index}>
            <div
              style={{
                width: "100%",
                display: "flex",
                paddingLeft: "30px",
              }}
            >
              {type == "TEXT" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    margin: "20px 0px",
                    border: "1px dashed #295939",
                    borderRadius: "4px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  <span style={{ color: "gray" }}>Enter text content</span>
                  <TextEditor
                    id={index}
                    value={content.length > 0 ? content[index].value : ""}
                    medium ={chapter?.medium_name}
                    onChange={(e) =>
                      onChapterTextChange(
                        e,
                        index,
                        type,
                        null,
                        content[index].data
                          ? content[index].data.tag
                          : content[index].tag
                      )
                    }
                    onFocus={(e) => {
                      borderFocused(e, index, type);
                    }}
                  />
                </GridItem>
              ) : type == "SPECIAL_TEXT" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    margin: "20px 0px",
                    border: "1px dashed #295939",
                    borderRadius: "4px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  <span style={{ color: "gray" }}>Enter Special text</span>
                  <TextEditor
                    id={index}
                    value={content.length > 0 ? content[index].value : ""}
                    medium ={chapter?.medium_name}
                    onChange={(e) =>
                      onChapterTextChange(
                        e,
                        index,
                        type,
                        null,
                        content[index].data
                          ? content[index].data.tag
                          : content[index].tag
                      )
                    }
                    onFocus={(e) => {
                      borderFocused(e, index, type);
                    }}
                  />
                </GridItem>
              ) : type == "IMG" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px dashed #184d47",
                    borderRadius: "4px",
                    background: "white",
                    marginTop: "15px",
                    marginRight: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {/* <Button 
                      onClick={()=>{
                        setShowImgDialog(true)
                        setImgIndex(index)
                      }}>
                        Upload Image
                      </Button> */}
                      <Button
                        style={{
                          backgroundColor: "#3B86FF",
                          color: "white",
                          margin: "3px 3px 3px",
                        }}
                        onClick={() => {
                          setImageSearchBox(true);
                          setCommonID(index);
                          setCommonType(type);
                          setMediaList([]);
                        }}
                      >
                        Import image by search
                      </Button>
                      <Button
                        style={{
                          color: "white",
                          background: "rgb(74 70 211)",
                          marginLeft: "5%",
                        }}
                        onClick={() => {
                          setUploadDialog(true);
                          setCommonID(index);
                          setCommonType(type);
                          getCategory();
                        }}
                      >
                        Upload images
                      </Button>
                      {/* <Input
                       placeholder="Add tag first"
                       label="add tag"
                       onChange={(e)=>{
                        setTagMedia(e.target.value)
                       }}/>
                      
                      <input
                        id={`image-file-${index}`}
                        accept="image/jpeg"
                        label="Image"
                        type="file"
                        style={{
                          width: "30%",
                          paddingBottom: "15px",
                          paddingTop:"10px"
                          //border: "1px solid black",
                        }}
                        onChange={(e) =>
                          onImageInputChange(e, index, type, null)
                        }
                        placeholder="Image Path"
                        helperText="Choose Image file"
                      /> */}

                      {uploadProgress.toString().split("#")[0] > 0 &&
                        uploadProgress.toString().split("#")[0] != 100 &&
                        index == uploadProgress.toString().split("#")[1] && (
                          <ProgressBar
                            variant={progressVariant}
                            value={uploadProgress.toString().split("#")[0]}
                          />
                        )}

                      {content.length > 0 &&
                        content[index].value &&
                        content[index].value.hasOwnProperty("filePath") &&
                        content[index].value.filePath != "" && (
                          <img
                            className={cls.preview}
                            src={
                              content[index].value.filePath
                                ? content[index].value.filePath
                                : content[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="Image Description"
                      medium ={chapter?.medium_name}
                      height="120"
                      name="ImgDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                        borderStyle: "dashed",
                      }}
                      onChange={(e) =>
                        onChapterTextChange(
                          e,
                          index,
                          type,
                          content[index].content_id,
                          null,
                          content[index].color
                        )
                      }
                      onFocus={(e) => {
                        borderFocused(e, index, type);
                      }}
                      value={
                        content.length > 0 &&
                        content[index].value &&
                        content[index].value.hasOwnProperty("description")
                          ? content[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : type == "VIDEO" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    border: "1px dashed",
                    borderRadius: "4px",
                    background: "white",
                    marginTop: "15px",
                    marginRight: "30px",
                  }}
                >
                  <div>
                    <div>
                      <TextField
                        name="videoURL"
                        style={{
                          width: "100%",
                          paddingTop: "15px",
                        }}
                        placeholder="Enter video URL"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={
                          content.length > 0 &&
                          content[index].value &&
                          content[index].value.hasOwnProperty("url")
                            ? content[index].value.url
                            : content[index].value
                        }
                        onChange={(e) =>
                          onChapterTextChange(e, index, type, null)
                        }
                        onFocus={(e) => {
                          borderFocused(e, index, type);
                        }}
                      />
                    </div>
                    <span style={{ fontSize: 12 }}>
                      {`Example:-
                                https://youtu.be/kHEvyeSKd6w?startTime=30&endTime=125`}
                    </span>
                    <br></br>
                    <TextEditor
                      style={{
                        width: "100%",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                      inputProps={{ style: { fontSize: 15 } }}
                      name="videoDesc"
                      placeholder="Description"
                      medium ={chapter?.medium_name}
                      formControlProps={{
                        fullWidth: true,
                        rows: 4,
                      }}
                      value={
                        content.length > 0 &&
                        content[index].value &&
                        content[index].value.hasOwnProperty("description")
                          ? content[index].value.description
                          : ""
                      }
                      onChange={(e) =>
                        onChapterTextChange(
                          e,
                          index,
                          type,
                          content[index].content_id,
                          null,
                          content[index].color
                        )
                      }
                      onFocus={(e) => {
                        borderFocused(e, index, type);
                      }}
                    />
                  </div>
                </GridItem>
              ) : type == "PDF" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px dashed #822659",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#3B86FF",
                      color: "white",
                      margin: "3px 3px 3px",
                    }}
                    onClick={() => {
                      //setShowPDFDialog(true);
                      setImageSearchBox(true);
                      setCommonID(index);
                      setCommonType(type);
                      setMediaList([]);
                    }}
                  >
                    Import PDF by search
                  </Button>
                  <Button
                    style={{
                      color: "white",
                      background: "rgb(74 70 211)",
                      marginLeft: "5%",
                    }}
                    onClick={() => {
                      setUploadDialog(true);
                      setCommonID(index);
                      setCommonType("PDF");
                    }}
                  >
                    Upload PDF
                  </Button>
                  {/* <input
                    id="pdf-file"
                    accept=".pdf"
                    label="PDF"
                    type="file"
                    onChange={(e) => onImageInputChange(e, index, type, null)}
                    placeholder="PDF Path"
                    helperText="Choose PDF file (Max size : 7MB)"
                    onFocus={(e) => borderFocused(e, index, type)}
                  /> */}
                  {uploadProgress.toString().split("#")[0] > 0 &&
                    uploadProgress.toString().split("#")[0] != 100 &&
                    index == uploadProgress.toString().split("#")[1] && (
                      <ProgressBar
                        variant={progressVariant}
                        value={uploadProgress.toString().split("#")[0]}
                      />
                    )}
                  <span>{content.length > 0 ? content[index].value : ""}</span>
                </GridItem>
              ) : type == "AUDIO" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px dashed #111d5e",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  <div className={cls.audioWrapper}>
                    <input
                      id="audio-file"
                      accept="audio/mpeg"
                      label="Audio"
                      type="file"
                      onChange={(e) => onImageInputChange(e, index, type, null)}
                      placeholder="Audio Path"
                      helperText="Choose Audio file"
                    />
                    {content.length > 0 && content[index].value && (
                      <span className={cls.audioPlayer}>
                        <audio controls>
                          <source
                            src={content.length > 0 ? content[index].value : ""}
                          ></source>
                        </audio>
                      </span>
                    )}
                  </div>

                  {uploadProgress.toString().split("#")[0] > 0 &&
                  uploadProgress.toString().split("#")[0] != 100 &&
                  index == uploadProgress.toString().split("#")[1] ? (
                    <ProgressBar
                      variant={progressVariant}
                      value={uploadProgress.toString().split("#")[0]}
                    />
                  ) : (
                    <div
                      className={cls.record}
                      onClick={() => {
                        setShowAudioDialog(true);
                        setAudioContentExtras({
                          index,
                          type,
                          content_id: "",
                        });
                      }}
                    >
                      <Mic titleAccess="Record Live" />
                    </div>
                  )}
                </GridItem>
              ) : type == "PPT" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px dashed #351f39",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  {/* <Button
                   style={{
                    color:"white",
                    background:"rgb(74 70 211)",
                    marginLeft:"5%"
                  }}
                    onClick={() => {
                      setUploadDialog(true);
                      setCommonID(index);
                      setCommonType("PPT");
                    }}
                  >
                    Upload PPT
                  </Button> */}
                  <input
                    id="ppt-file"
                    accept=".ppt/pptx"
                    label="PPT"
                    type="file"
                    onChange={(e) => onImageInputChange(e, index, type, null)}
                    placeholder="PPT Path"
                    helperText="Choose PPT file (Max size : 7MB)"
                  />
                  {uploadProgress.toString().split("#")[0] > 0 &&
                    uploadProgress.toString().split("#")[0] != 100 &&
                    index == uploadProgress.toString().split("#")[1] && (
                      <ProgressBar
                        variant={progressVariant}
                        value={uploadProgress.toString().split("#")[0]}
                      />
                    )}
                  <span>{content.length > 0 ? content[index].value : ""}</span>
                </GridItem>
              ) : type == "Link" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    "margin-top": "26px",
                    // margin: "30px 14px",
                    justifyContent: "space-between",
                    marginRight: "30px",
                  }}
                >
                  <TextField
                    label="Name"
                    name="linkName"
                    style={{ width: "50%" }}
                    inputProps={{ style: { fontSize: 15 } }}
                    value={
                      content.length > 0 && content[index].value
                        ? content[index].value.name
                        : ""
                    }
                    onChange={(e) => onChapterTextChange(e, index, type, null)}
                  />
                  <TextField
                    label="Enter URL"
                    name="linkValue"
                    style={{
                      width: "45%",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{ style: { fontSize: 15 } }}
                    value={
                      content.length > 0 && content[index].value
                        ? content[index].value.value
                        : ""
                    }
                    onChange={(e) => onChapterTextChange(e, index, type, null)}
                  />
                </GridItem>
              ) : type == "gForm" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    border: "1px dashed #09015f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  <CustomInput
                    labelText="Enter your Google form link"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={content.length > 0 ? content[index].value : ""}
                    change={(e) => onChapterTextChange(e, index, type, null)}
                    Focus={(e) => {
                      borderFocused(e, index, type);
                    }}
                    Blur={(e) => {
                      checkForGFormInput(e.target.value);
                    }}
                  />
                </GridItem>
              ) : type == "GIF" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px dashed #2c061f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#3B86FF",
                          color: "white",
                          margin: "3px 3px 3px",
                        }}
                        onClick={() => {
                          setImageSearchBox(true);
                          setCommonID(index);
                          setCommonType(type);
                          setMediaList([]);
                          console.log(commonType, "commonType");
                        }}
                      >
                        Import GIF by search
                      </Button>
                      <Button
                        style={{
                          color: "white",
                          background: "rgb(74 70 211)",
                          marginLeft: "5%",
                        }}
                        onClick={() => {
                          setUploadDialog(true);
                          setCommonID(index);
                          setCommonType("GIF");
                        }}
                      >
                        Upload GIF
                      </Button>
                      {/* <input
                        id={`gif-file-${index}`}
                        accept=".gif"
                        label="GIF"
                        style={{
                          width: "100%",
                          paddingBottom: "15px",
                          borderBottom: "1px solid black",
                        }}
                        type="file"
                        onChange={(e) =>
                          onImageInputChange(e, index, type, null)
                        }
                        placeholder="GIF Path"
                        helperText="Choose GIF file"
                      /> */}
                      {uploadProgress.toString().split("#")[0] > 0 &&
                        uploadProgress.toString().split("#")[0] != 100 &&
                        index == uploadProgress.toString().split("#")[1] && (
                          <ProgressBar
                            variant={progressVariant}
                            value={uploadProgress.toString().split("#")[0]}
                          />
                        )}
                      {content.length > 0 &&
                        content[index].value &&
                        content[index].value.hasOwnProperty("filePath") &&
                        content[index].value.filePath != "" && (
                          <img
                            className={cls.preview}
                            src={
                              content[index].value.filePath
                                ? content[index].value.filePath
                                : content[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder="GIF Description"
                      medium ={chapter?.medium_name}
                      height="120"
                      name="gifDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      onChange={(e) =>
                        onChapterTextChange(
                          e,
                          index,
                          type,
                          content[index].content_id,
                          null,
                          content[index].color
                        )
                      }
                      onFocus={(e) => {
                        borderFocused(e, index, type);
                      }}
                      value={
                        content.length > 0 &&
                        content[index].value &&
                        content[index].value.hasOwnProperty("description")
                          ? content[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : type == "json" ? (
                <GridItem
                  id={`grid${index}`}
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px dashed #2c061f",
                    borderRadius: "4px",
                    marginTop: "15px",
                    background: "white",
                    marginRight: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#3B86FF",
                          color: "white",
                          margin: "3px 3px 3px",
                        }}
                        onClick={() => {
                          setImageSearchBox(true);
                          setCommonID(index);
                          setCommonType(type);
                          setMediaList([]);
                          console.log(commonType, "commonType");
                        }}
                      >
                        Import Lottie by search
                      </Button>
                      <Button
                        style={{
                          color: "white",
                          background: "rgb(74 70 211)",
                          marginLeft: "5%",
                        }}
                        onClick={() => {
                          setUploadDialog(true);
                          setCommonID(index);
                          setCommonType("json");
                        }}
                      >
                        Upload Lottie
                      </Button>
                      {/* <TextField
                        id={`json-file-${index}`}
                        accept=".json"
                        label="JSON"
                        style={{
                          width: "100%",
                          paddingBottom: "15px",
                        }}
                        type="file"
                        onChange={(e) =>
                          onImageInputChange(e, index, type, null)
                        }
                        placeholder="Lottie File Path"
                        helperText="Choose Lottie  file"
                      /> */}
                      {uploadProgress.toString().split("#")[0] > 0 &&
                        uploadProgress.toString().split("#")[0] != 100 &&
                        index == uploadProgress.toString().split("#")[1] && (
                          <ProgressBar
                            variant={progressVariant}
                            value={uploadProgress.toString().split("#")[0]}
                          />
                        )}
                      {content.length > 0 &&
                        content[index].value &&
                        content[index].value.hasOwnProperty("filePath") &&
                        content[index].value.filePath != "" && (
                          <Player
                            autoplay
                            loop
                            style={{
                              height: "100px",
                              width: "100px",
                              border: "1px solid black",
                            }}
                            src={
                              content[index].value.filePath
                                ? content[index].value.filePath
                                : content[index].value
                            }
                          />
                        )}
                    </div>
                    <br></br>
                    <TextEditor
                      placeholder=" Description"
                      medium ={chapter?.medium_name}
                      height="120"
                      name="gifDesc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        width: "100%",
                        paddingBottom: "15px",
                      }}
                      onChange={(e) =>
                        onChapterTextChange(
                          e,
                          index,
                          type,
                          content[index].content_id,
                          null,
                          content[index].color
                        )
                      }
                      onFocus={(e) => {
                        borderFocused(e, index, type);
                      }}
                      value={
                        content.length > 0 &&
                        content[index].value &&
                        content[index].value.hasOwnProperty("description")
                          ? content[index].value.description
                          : ""
                      }
                    ></TextEditor>
                  </div>
                </GridItem>
              ) : null}
              <div className={cls.item_actions}>
                <IconButton
                  aria-label="delete"
                  style={{ marginTop: 20, alignSelf: "center" }}
                  onClick={() => {
                    setFieldToBeDelete(index);
                    setIsNewPart(true);
                    setShowDeleteFieldDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                <IconButton
                  data-tip
                  data-for="registerTip"
                  title="Help"
                  size="small"
                  style={{ marginTop: 10, alignSelf: "center" }}
                  onClick={() => {}}
                >
                  <HelpOutlineIcon />
                </IconButton>
                <ReactTooltip
                  id="registerTip"
                  place="right"
                  effect="solid"
                  style={{ background: "blue" }}
                >
                  Information for adding element
                </ReactTooltip>

                <IconButton
                  title="Add element"
                  style={{ marginTop: 10, alignSelf: "center" }}
                  onClick={() => {
                    setAddWidgetModal(true);
                    setContentIndex(index);
                    setMediaUrl("");
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </div>
            </div>
          </React.Fragment>
        );
      });
    } else {
      return (
        <div className="row" style={{ paddingLeft: "30px" }}>
          <div
            className={cls.addWidgetDiv}
            onClick={() => {
              setAddWidgetModal(true);
            }}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <AddCircleOutlineIcon />
              </div>
              <span>Add first element here</span>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleChangeStatus = async (status) => {
    if (status == constants.CHAPTER_STATUS.PUBLISHED) {
      setShowPublishConfirm(true);
      return;
    }

    changeStatus(status);
  };

  const changeStatus = async (status) => {
    let param = {
      chapter_id: userChapter.meta.chapter_id,
      status: status,
    };
    // const http = axios.create({
    //   baseURL: Web_separate,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization" : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozODc0MiwiZXhwIjoxNjI2ODc5NjA1LCJpYXQiOjE2MjQyODc2MDV9.rMKcqdexK0m7OsucZdG039I-taPmhbsFxZLvkca5GLw"
    //   },
    // });

    let updatedStatusRes = await httpV2.post(
      `chapters/${userChapter.meta.chapter_id}/change_chapter_status/`,
      (param = {
        status,
      })
    );
    console.log(updatedStatusRes, "updatedStatusRes");
    // let updatedStatusRes = await getSuperAdminService().updateChapterStatus(
    //   param
    // );
    //updatedStatusRes = updatedStatusRes.data;
    if (updatedStatusRes && updatedStatusRes.status == 200) {
      const user = JSON.parse(localStorage.getItem("user"));
      setError(false);
      setMessage("Status changed successfully");
      let param = {
        chapter_id: chapter.chapter_id,
      };
      getSharedService().getChapterPreview(param);
      setChangeChapterStatusOn(!changeChapterStatusOn);
      setTimeout(() => {
        if (
          user.user_type === constants.USER_TYPE.SUPER_ADMIN ||
          user.user_type === constants.USER_TYPE.ADMIN
        ) {
          props.history.push("/admin/chapters");
        } else {
          props.history.push("/user/lessons");
        }
      }, 1500);
    }
  };

  const openDialogToEdit = (part) => {
    let partData = {
      part_id: part.part_id,
      partName: part.part_name,
      tagName: part.tags,
    };
    var arr = part.tags.split(", "); // split string on comma space
    console.log(arr, "split");
    setChip(arr);
    setPartAndTagData(partData);
    setEditing(!editing);
  };
  //old add parts

  // const handleChapterSubmit = async () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   let param = {
  //     chapter_id: userChapter.meta.chapter_id,
  //     sequence: userChapter.parts.length + 1,
  //     part_name: partName,
  //     tags: tagName,
  //     user_id: user.user_id,
  //   };
  //   setLoading(true);
  //   if (!content.length) {
  //     setError(true);
  //     setMessage(
  //       "Please choose content to add first from top panel inside newly added part"
  //     );
  //     return;
  //   }

  //   let addChapterPartRes = await getSuperAdminService().addChapterPart(param);
  //   addChapterPartRes = addChapterPartRes.data;
  //   if (addChapterPartRes && addChapterPartRes.status === 200) {
  //     let part_id = addChapterPartRes.response.part_id;
  //     let param = {
  //       part_id: part_id,
  //       chapter_id: addChapterPartRes.response.chapter_id,
  //     };
  //     // let contentIdRes = await getContentWriterService().createChapterContentId(
  //     //   param
  //     // );
  //     let contentIdRes = await getContentWriterService().createChapterContentIdNew(
  //       param
  //     );
  //     contentIdRes = contentIdRes.data;
  //     if (contentIdRes && contentIdRes.status === 200) {
  //       const content_id = contentIdRes.response.content_id;
  //       console.log(userChapter, "userChapter");

  //       console.log(content, "upload");
  //       let updatedContent = content.map((c) => {
  //         return {
  //           ...c,
  //           sequence_id: userChapter.parts.length + 1,
  //           content_id: content_id,
  //           color: partColor,
  //         };
  //       });

  //       let param = {
  //         subject_id: chapter.subject_id ? chapter.subject_id : DupSubject,
  //         class_id: chapter.class_id ? chapter.class_id : DupClass,
  //         chapter_id: addChapterPartRes.response.chapter_id,
  //         part_no: currentTabIndex,
  //         part_id: part_id,
  //         content_id: content_id,
  //         content: { ...updatedContent },
  //       };
  //       // let uploadContentRes = await getContentWriterService().uploadChapterContent(
  //       //   param
  //       // );
  //       console.log(param, "param");
  //       let uploadContentRes = await getContentWriterService().uploadChapterContentCDN(
  //         param
  //       );

  //       // uploadContentRes = uploadContentRes.data;
  //       // if (uploadContentRes && uploadContentRes.status === 200) {
  //       //   setError(false);
  //       //   setMessage("Chapter upda successfully");
  //       //   props.history.go(0);
  //       // }
  //       // setTimeout(() => {
  //       //   setLoading(false);
  //       // }, 500);
  //     }
  //   }
  // };

  const changeEditMode = () => {
    setEditMode(!editMode);
    getCategory();
    if (!editMode) {
      let newParts = [...userChapter.parts];
      if (newParts.findIndex((p) => !p.hasOwnProperty("part_id")) == -1) {
        newParts.push({
          part_name: "",
          tags: "",
          content: [],
        });
      }
      setUserChapter({
        ...userChapter,
        parts: newParts,
      });
      setCurrentTabIndex(0);
    }
  };

  const onDeleteContentInput = (index) => {
    setShowDeleteFieldDialog(false);
    const parts = [...userChapter.parts];
    parts[currentTabIndex].content.splice(index, 1);

    setUserChapter({
      ...userChapter,
      parts: parts,
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const updatedPart = { ...userChapter.parts[currentTabIndex] };
    const allParts = [...userChapter.parts];
    //dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      updatedPart.content,
      result.source.index,
      result.destination.index
    );

    updatedPart.content = items;
    allParts[currentTabIndex] = updatedPart;

    setUserChapter({
      ...userChapter,
      parts: allParts,
    });
  };

  const OptionsForExplaination = (data, qid) => {
    let options = [];
    if (data) {
      data.map((d, i) => {
        options.push(
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                fontFamily: "PoppinsRegular",
                padding: "5px",
              }}
            >
              <div>{i + 1})</div>
              <div>
                {editMCQ && mcqID == qid ? (
                
                  <>
                    {
                      isHTML(d) ? (
                    <div style={{ marginTop: "10px" }} >
                    <Editor
                     initialValue={d}
                     tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@5/tinymce.min.js"
                    init={{
                      placeholder: "Answers",
                      height: "120",
                      width: "100%",
                      menubar: false,
                      autosave_ask_before_unload: false,
                      contextmenu:
                        "paste | link image inserttable | cell row column deletetable",
                      content_style: "body { line-height: 1; }",
                      fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt",
                      font_formats:
                        "Andale Mono=Roboto Mono; BalBharti=BalBhartiDev01; Karma=Karma; Noto Sans=Noto Sans; Eczar=Eczar; Vesper Libre=Vesper Libre; Cambay=Cambay; Mukta=Mukta; Open Sans=Open Sans; Trocchi=Trocchi; Noto Serif=Noto Serif; Lato=Lato; Crimson Text=Crimson Text; Tinos=Tinos; Markazi Text=Markazi Text; Indie Flower=Indie Flower; Caveat=Caveat; Mukta Vaani=Mukta Vaani; Hind Madurai=Hind Madurai; Hind Guntur=Hind Guntur; Chilanka=Chilanka; Baloo Da 2=Baloo Da 2; Lateef=Lateef;Nafees=Nafees;Fajer Noori Nastalique=Fajer Noori Nastalique;Jameel Noori Nastaleeq=Jameel Noori Nastaleeq",
                      content_style: ` @import url('https://fonts.googleapis.com/css2?family=Karma:wght@300&display=swap'); 
                        @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Eczar&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Vesper+Libre&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Cambay&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Trocchi&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Tinos&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Mukta+Vaani&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Hind+Madurai&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Hind+Guntur&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Chilanka&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2&display=swap');
                        @import url('https://fonts.googleapis.com/css2?family=Lateef&display=swap');
                      `,  
                      license_key: 'gpl',     
                    }}

                    onEditorChange={(e) => {

                      updateMQOption(i, e);
                    }}
                    ref={editorRef}
                    onFocus={(e)=>{

                    }}
    />
                    </div>

                      ) : (
                        <TextField
                    //id="standard-basic"
                    id="outlined-basic"
                    variant="outlined"
                    //label={res.email}
                    defaultValue={d}
                    type="text"
                    onChange={(e) => {
                      updateMQOption(i, e.target.value);
                      // setEditOptID(i);
                      // console.log(i, "i", e.target.value);
                      //setEditOpt(e.target.value);
                    }}
                  />
                      ) 
                    }
                  </>
                ) : (
                  <>
                  <div 
                     dangerouslySetInnerHTML={{
                                            __html:d
                                          }}
                     ></div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      });
    }
    return options;
  };

  const renderMCQ = (data) => {
    let array = [];
    let value;
    {console.log(data.questions,"question")}
   
    if (data && Object.keys(data).length) {
      data.questions.map((res, key) => {
        
        if( indexQuestion===key)
          {array.push(
          <Card
            style={{
              width: "90%",
              justifyContent: "flex-start",
              marginLeft: "5%",
              marginTop: "1%",
              flexDirection:'row'
            }}
          >
            <React.Fragment>
              <div style={{ alignItems: "flex-start" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {!editMCQ ? (
                    <>
                      <IconButton
                       onClick={() => {
                        setMCQID(key);
                        setEDitMCQ(true);
                        console.log(key, "key");
                        setMCQques(res.question)
                        setEditExplain(res.explanation)
                        setEditExplainLink(res.explanationLink)
                        setPoint(res.point)
                        setEditCorrectExplain(res.messageForCorrectAnswer)
                        setEditInCorrectExplain(res.messageForIncorrectAnswer)
                        setEditCorrectAns(res.correctAnswer)
                        setEditAnswer(res.answers)
                        getLearningElement()
                      }}
                      >
                        <EditRounded />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEachMcq(true);
                          setDeleteMCQID(key);
                          // setMCQID(key);
                          // setEDitMCQ(true);
                          // console.log(key, "key");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : null}
                  {editMCQ && mcqID == key ? (
                    <>
                      <IconButton
                        onClick={() => {
                          setEDitMCQ(false);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          updateMCQ();
                        }}
                      >
                        <SaveIcon />
                      </IconButton>
                    </>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "4px",
                    fontWeight: "bold",
                  }}
                >
                  Q{key + 1} :
                  {editMCQ && mcqID == key ? (

                  <>
                  {

                      isHTML(res.question) ? (
                        <TextEditor
                            placeholder="Question"
                            medium ={chapter?.medium_name}
                            height="120"
                            onChange={(e)=>{
                              setMCQques(e);
                            }}
                            onFocus={()=>{
                              
                            }}
                            value={mcqQues}
                            
                              />  
                      ) :(
                        <TextField
                      //id="standard-basic"
                      variant="outlined"
                      id="outlined-basic"
                      //label={res.email}
                      defaultValue={res.question}
                      type="text"
                      onChange={(e) => {
                        setMCQques(e.target.value);
                      }}
                    />
                      )
                    }
                  </>
                   
                  ) : (
                    <>

                    <div 
                     dangerouslySetInnerHTML={{
                                            __html: res?.question,
                                          }}
                     ></div>
                 
                    </>
                  )}
                </div>
                {editMCQ && mcqID == key && res.questionPic ?
                 <Input
                 type="file"
                 accept="image/png, image/jpeg , image/gif"
                 placeholder='media'
                 onChange={(e) => onChangeFile(e, 'IMAGE')}
                 className="form-control"
               />:res.questionPic ? (
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <img width="600" height="600" src={res.questionPic} />
                  </div>
                ) : null}
                {editMCQ && mcqID == key && res.questionAUDIO?
                
                 <Input
                 type="file"
                 accept="audio/mp3, audio/mpeg"
                 placeholder='media'
                 onChange={(e) => onChangeFile(e, 'AUDIO')}
                 className="form-control"
               />:res.questionAUDIO ? (
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                  <audio controls style={{ width: "100%" }} src={res?.questionAUDIO} type="audio/mpeg"/>
                      {/* <source src={res?.questionAUDIO} type="audio/mpeg" /> */}
                    {/* </audio> */}
                  </div>
                ) : null}
                   {editMCQ && mcqID == key && res.questionVideo ? 
                   <TextField
                   style={{ padding: "5px" }}
                   variant="outlined"
                   id="outlined-basic"
                   defaultValue={res.questionVideo}
                   type="text"
                   onChange={(e) => {
                    seteditVideoUrl(e.target.value);
                   }}
                 />
                   :res.questionVideo ? (
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <iframe
                      width="600"
                      height="600"
                      src={`https://www.youtube.com/embed/${getYTVideoID(
                        res.questionVideo
                      )}`}
                    ></iframe>
                  </div>
                ) : null}
              </div>
              <div>{OptionsForExplaination(res.answers, key)}</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "14px",
                  flexDirection: "column",
                }}
              >
                <div style={{ fontWeight: "bold", padding: "10px" ,display:"flex",flexDirection:"row"}}>
                  Correct Answer :
                  {editMCQ && mcqID == key ? (
                    <Select
                      variant="outlined"
                      style={{ width: "8em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      defaultValue={res.answers[res.correctAnswer - 1]}
                      onChange={(e) => {
                        console.log(e, e.target.value,"default value");
                        e.preventDefault()
                        setEditCorrectAns(e.target.value);
                      }}
                    >
                      {res.answers.map((data, id) => {
                        return (
                          <MenuItem value={id + 1}>#{id + 1} option</MenuItem>
                        );
                      })}
                    </Select>
                  ) : res.answers ? (
                    <div 
                     dangerouslySetInnerHTML={{
                                            __html:res.answers[res.correctAnswer - 1]
                                          }}
                     ></div>
                  ) : null}
                </div>
                <div>
                  Marks :
                  {editMCQ && mcqID == key ? (
                    <TextField
                      style={{ padding: "5px" }}
                      variant="outlined"
                      id="outlined-basic"
                      defaultValue={res.point}
                      type="text"
                      onChange={(e) => {
                        setPoint(e.target.value);
                      }}
                    />
                  ) : (
                    res.point
                  )}{" "}
                </div>
                {console.log(res.answers ? res.answers[0] : null, "res")}
                <div style={{display:"flex"}}>
                  Message for Correct answer :
                  {editMCQ && mcqID == key ? (
                    <TextField
                      style={{ padding: "5px" }}
                      variant="outlined"
                      //id="standard-basic"
                      id="outlined-basic"
                      //label={res.email}
                      defaultValue={res.messageForCorrectAnswer}
                      type="text"
                      onChange={(e) => {
                        setEditCorrectExplain(e.target.value);
                      }}
                    />
                  ) : (
                    <p
                    style={{height:"0rem"}} 
                     dangerouslySetInnerHTML={{
                      __html: res.messageForCorrectAnswer
                    }}></p>
                    
                  )}{" "}
                </div>
                <div style={{display:"flex"}}>
                  Message for Incorrect answer :
                  {editMCQ && mcqID == key ? (
                    <TextField
                      style={{ padding: "5px" }}
                      variant="outlined"
                      //id="standard-basic"
                      id="outlined-basic"
                      //label={res.email}
                      defaultValue={res.messageForIncorrectAnswer}
                      type="text"
                      onChange={(e) => {
                        setEditInCorrectExplain(e.target.value);
                      }}
                    />
                  ) : (
                    <p style={{height:"0rem"}}
                     dangerouslySetInnerHTML={{
                      __html: res.messageForIncorrectAnswer
                    }}></p>
                    
                  )}{" "}
                </div>
                <div style={{display:"flex"}}>
                  Explaination :
                  {editMCQ && mcqID == key ? (
                    <TextField
                      style={{ padding: "5px" }}
                      variant="outlined"
                      //id="standard-basic"
                      id="outlined-basic"
                      //label={res.email}
                      defaultValue={res.explanation}
                      type="text"
                      onChange={(e) => {
                        setEditExplain(e.target.value);
                      }}
                    />
                  ) : (
                    <p  
                    style={{height:"0rem"}}
                    dangerouslySetInnerHTML={{
                      __html: res.explanation
                    }}></p>
                  
                  )}{" "}
                  <br />
                </div>
                <div style={{display:"flex"}}>
                  Explaination Link :
                  {editMCQ && mcqID == key ? (
                    <TextField
                      style={{ padding: "5px" }}
                      variant="outlined"
                      //id="standard-basic"
                      id="outlined-basic"
                      //label={res.email}
                      defaultValue={res.explanationLink}
                      type="text"
                      onChange={(e) => {
                        setEditExplainLink(e.target.value);
                      }}
                    />
                  ) : (
                    <a href={res.explanationLink}>
                      <p  
                      style={{height:"0rem"}}
                      dangerouslySetInnerHTML={{
                      __html: res.explanationLink
                    }}></p>
                      </a>
                  )}{" "}
                </div>
              </div>
            </React.Fragment>
          </Card>
        )}
      });
    }
    return array;
  };

  const updateMCQ = () => {
    console.log(quizStore,"quizStore",editCorrectAns)
    setEDitMCQ(false);

    if (editTitle.length > 0) {
      quizStore.quizTitle = editTitle;
    } 
    if (editsynopsis.length > 0) {

      quizStore.quizSynopsis = editsynopsis;
    }  
    
    if (mcqQues) {
      quizStore.questions[mcqID].question = mcqQues;
    }
    if (editExplain) {
      quizStore.questions[mcqID].explanation = editExplain;
    }
    if (editCorrectAns) {
      quizStore.questions[mcqID].correctAnswer = editCorrectAns;
    }
    if (editOpt) {
      quizStore.questions[mcqID].answers[editOptID] = editOpt;
    }
    if (editCorrectExplain) {
      quizStore.questions[mcqID].messageForCorrectAnswer = editCorrectExplain;
    }
    if (editInCorrectExplain) {
      quizStore.questions[
        mcqID
      ].messageForIncorrectAnswer = editInCorrectExplain;
    }
    if (editExplainLink) {
      quizStore.questions[mcqID].explanationLink = editExplainLink;
    }
    if (point) {
      quizStore.questions[mcqID].point = point;
    }

    if (editVideoUrl) {
      quizStore.questions[mcqID].questionVideo = editVideoUrl;
    }
    if (editMediafile) {
      if(mediaType === 'IMAGE')
      {
       
        quizStore.questions[mcqID].questionPic = editMediafile;  
      }
      else{
        quizStore.questions[mcqID].questionAUDIO = editMediafile;
      }
      
    }
    
    if(editAnswer.length > 0 ){
      quizStore.questions[mcqID].answers.forEach((i,id)=>{
        quizStore.questions[mcqID].answers[id] = editAnswer[id];
      })
          }

          console.log(editTitle,"editTitle")
              
           
    console.log(quizStore, "log", userChapter.parts[currentTabIndex].mcq[0].id);
    let mcqid = userChapter.parts[currentTabIndex].mcq[0].id;
    let param = {
      questions: JSON.stringify(quizStore, null, 2),
      
      max_marks: 20,
      chapter: userChapter.meta.chapter_id,
      part: userChapter.parts[currentTabIndex].part_id,
    };
    if(outcomeID)
    {
      param['learning_element'] = outcomeID
    }
    console.log(param,"paramparam");
    editQuiz(param, mcqid).then((res) => {
      console.log(res);
      const mcqObject={
        id:currentTabIndex,
        question_url:res?.data?.message?.question_url
      }
      let allMCQUrl = newUrl
      const val = allMCQUrl.findIndex(x => x.id === currentTabIndex)
      if(val == -1){
        allMCQUrl.push(mcqObject)
      }
      else{
        allMCQUrl.splice(val,1,mcqObject)
     }
     setNewUrl(allMCQUrl)
     
    });
    // window.location.reload();
  };

  const updateMQOption = (ID, OptID) => {
    let customArr=[...editAnswer]
    customArr.splice(ID,1,OptID)
    setEditAnswer(customArr)
  };

  const deleteEachMCQ = () => {
    console.log(userChapter.parts[currentTabIndex].mcq[0].id, "ok");
    let id = userChapter.parts[currentTabIndex].mcq[0].id;
    console.log(mcqID, deleteMCQID, "okk");
    console.log(quizStore.questions);

    quizStore.questions.splice(deleteMCQID, 1);
    console.log(quizStore.questions);

    let param = {
      questions: JSON.stringify(quizStore),
      chapter: userChapter.meta.chapter_id,
      part: userChapter.parts[currentTabIndex].part_id,
    };
    console.log();
    editQuiz(param, id).then((res) => {
      console.log(res);
      const mcqObject={
        id:currentTabIndex,
        question_url:res?.data?.message?.question_url
      }
      let allMCQUrl = newUrl
      const val = allMCQUrl.findIndex(x => x.id === currentTabIndex)
      if(val == -1){
        allMCQUrl.push(mcqObject)
      }
      else{
        allMCQUrl.splice(val,1,mcqObject)
     }
     setNewUrl(allMCQUrl)
    });
    // setDeleMCQDialog(false);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0`,
    width: "100%",
    ...draggableStyle,
  });

  const renderContentInputs = () => {
    const part = userChapter.parts[currentTabIndex];

    if (part && part.content) {
      const array = part.content.map((c) => c.type);
      const content = part.content;

      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {array.map((type, index) => (
                  <Draggable
                    key={index}
                    draggableId={`id ${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div
                          key={index}
                          style={{
                            width: "100%",
                            display: "flex",
                            paddingLeft: "10px",
                          }}
                        >
                          {type == "TEXT" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                margin: "20px 0px",
                                border: "1px dashed #295939",
                                borderRadius: "4px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              <span style={{ color: "gray" }}>
                                Enter text content
                              </span>
                              <TextEditor
                                value={
                                  content.length > 0 ? content[index].value : ""
                                }
                                medium ={chapter?.medium_name}
                                onChange={(e) =>
                                  onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id,
                                    content[index].tag,
                                    content[index].color
                                  )
                                }
                                onFocus={(e) => {
                                  borderFocused(
                                    e,
                                    content[index].content_id,
                                    index,
                                    type
                                  );
                                }}
                              />
                            </GridItem>
                          ) : type == "SPECIAL_TEXT" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                margin: "20px 0px",
                                border: "1px dashed #295939",
                                borderRadius: "4px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              <span style={{ color: "gray" }}>
                                Enter Special text
                              </span>
                              <TextEditor
                                value={
                                  content.length > 0 ? content[index].value : ""
                                }
                                medium ={chapter?.medium_name}
                                onChange={(e) =>
                                  onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id,
                                    content[index].tag,
                                    content[index].color
                                  )
                                }
                                onFocus={(e) => {
                                  borderFocused(
                                    e,
                                    content[index].content_id,
                                    index,
                                    type
                                  );
                                }}
                              />
                            </GridItem>
                          ) : type == "IMG" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px dashed #184d47",
                                borderRadius: "4px",
                                background: "white",
                                marginTop: "15px",
                                marginRight: "12px",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {/* <input
                                    id={`image-file-${index}`}
                                    accept="image/jpeg"
                                    label="Image"
                                    type="file"
                                    style={{
                                      width: "100%",
                                      paddingBottom: "15px",
                                      borderBottom: "1px solid black",
                                    }}
                                    onChange={(e) =>
                                      onImageInputChange(
                                        e,
                                        index,
                                        type,
                                        content[index].content_id
                                      )
                                    }
                                    placeholder="Image Path"
                                    helperText="Choose Image file"
                                  /> */}
                                  <Button
                                    style={{
                                      backgroundColor: "#3B86FF",
                                      color: "white",
                                      margin: "3px 3px 3px",
                                    }}
                                    onClick={() => {
                                      setImageSearchBox(true);
                                      setCommonID(index);
                                      setCommonType(type);
                                      setMediaList([]);
                                      getAllMedia(chapter.chapter_name);
                                      console.log(
                                        commonType,
                                        "commonType",
                                        chapter.chapter_name
                                      );
                                    }}
                                  >
                                    Import image by search
                                  </Button>
                                  <Button
                                    style={{
                                      color: "white",
                                      background: "rgb(74 70 211)",
                                      marginLeft: "5%",
                                    }}
                                    onClick={() => {
                                      setUploadDialog(true);
                                      setCommonID(index);
                                      setCommonType(type);
                                      getCategory();
                                    }}
                                  >
                                    Upload images
                                  </Button>
                                  {/* {uploadProgress.toString().split("#")[0] >
                                    0 &&
                                    uploadProgress.toString().split("#")[0] !=
                                      100 &&
                                    index ==
                                      uploadProgress
                                        .toString()
                                        .split("#")[1] && (
                                      <ProgressBar
                                        variant={progressVariant}
                                        value={
                                          uploadProgress
                                            .toString()
                                            .split("#")[0]
                                        }
                                      />
                                    )} */}
                                  <FormHelperText>
                                    ( Choose file less than 2MB ){" "}
                                  </FormHelperText>
                                  {content.length > 0 &&
                                    content[index].value &&
                                    content[index].value.hasOwnProperty(
                                      "filePath"
                                    ) &&
                                    content[index].value.filePath != "" && (
                                      <img
                                        className={cls.preview}
                                        src={
                                          content[index].value.filePath
                                            ? content[index].value.filePath
                                            : content[index].value
                                        }
                                      />
                                    )}
                                </div>
                                <br></br>
                                <TextEditor
                                  placeholder="Image Description"
                                  medium ={chapter?.medium_name}
                                  height="120"
                                  name="ImgDesc"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  style={{
                                    width: "100%",
                                    paddingBottom: "15px",
                                  }}
                                  onChange={(e) =>
                                    onChapterTextChange(
                                      e,
                                      index,
                                      type,
                                      content[index].content_id,
                                      null,
                                      content[index].color
                                    )
                                  }
                                  onFocus={(e) => {
                                    borderFocused(e, index, type);
                                  }}
                                  value={
                                    content.length > 0 &&
                                    content[index].value &&
                                    content[index].value.hasOwnProperty(
                                      "description"
                                    )
                                      ? content[index].value.description
                                      : ""
                                  }
                                ></TextEditor>
                              </div>
                            </GridItem>
                          ) : type == "VIDEO" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                border: "1px dashed #e40017",
                                borderRadius: "4px",
                                background: "white",
                                marginTop: "15px",
                                marginRight: "12px",
                                display: "flex",
                                flexDirection: "column",
                                padding: "35px 25px !important",
                              }}
                            >
                              <TextField
                                name="videoURL"
                                placeholder="Paste video URL here"
                                style={{ width: "100%", paddingTop: "15px" }}
                                inputProps={{ style: { fontSize: 15 } }}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={
                                  content.length > 0 &&
                                  content[index].value &&
                                  content[index].value.hasOwnProperty("url")
                                    ? content[index].value.url
                                    : content[index].value
                                }
                                onChange={(e) =>
                                  onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id,
                                    null,
                                    content[index].color
                                  )
                                }
                                onFocus={(e) => {
                                  borderFocused(e, index, type);
                                }}
                              />
                              <span style={{ fontSize: 12 }}>
                                {`Example:-
                                https://youtu.be/kHEvyeSKd6w?startTime=30&endTime=125`}
                              </span>
                              <br></br>
                              <TextEditor
                                style={{
                                  width: "100%",
                                  paddingTop: "15px",
                                  paddingBottom: "15px",
                                }}
                                inputProps={{ style: { fontSize: 15 } }}
                                name="videoDesc"
                                medium ={chapter?.medium_name}
                                placeholder="Provide video related questions,information,instructions here."
                                formControlProps={{
                                  fullWidth: true,
                                  rows: 4,
                                }}
                                value={
                                  content.length > 0 &&
                                  content[index].value &&
                                  content[index].value.hasOwnProperty(
                                    "description"
                                  )
                                    ? content[index].value.description
                                    : ""
                                }
                                onChange={(e) =>
                                  onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id,
                                    null,
                                    content[index].color
                                  )
                                }
                                onFocus={(e) => {
                                  borderFocused(e, index, type);
                                }}
                              />
                            </GridItem>
                          ) : type == "PDF" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px dashed #822659",
                                borderRadius: "4px",
                                marginTop: "15px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              {/* <input
                                id="pdf-file"
                                accept=".pdf"
                                label="PDF"
                                type="file"
                                onChange={(e) =>
                                  onImageInputChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id
                                  )
                                }
                                onFocus={(e) => {
                                  borderFocused(e, index, type);
                                }}
                                placeholder="PDF Path"
                                helperText="Choose PDF file (Max size : 7MB)"
                              /> */}
                              <Button
                                style={{
                                  backgroundColor: "#3B86FF",
                                  color: "white",
                                  margin: "3px 3px 3px",
                                }}
                                onClick={() => {
                                  setImageSearchBox(true);
                                  setCommonID(index);
                                  setCommonType(type);
                                  setMediaList([]);
                                  console.log(commonType, "commonType");
                                }}
                              >
                                Import PDF by search
                              </Button>
                              <Button
                                style={{
                                  color: "white",
                                  background: "rgb(74 70 211)",
                                  marginLeft: "5%",
                                }}
                                onClick={() => {
                                  setUploadDialog(true);
                                  setCommonID(index);
                                  setCommonType(type);
                                  getCategory();
                                }}
                              >
                                Upload PDF
                              </Button>
                              {/* {uploadProgress.toString().split("#")[0] > 0 &&
                                uploadProgress.toString().split("#")[0] !=
                                  100 &&
                                index ==
                                  uploadProgress.toString().split("#")[1] && (
                                  <ProgressBar
                                    variant={progressVariant}
                                    value={
                                      uploadProgress.toString().split("#")[0]
                                    }
                                  />
                                )} */}
                              <FormHelperText>
                                ( Choose file less than 7MB ){" "}
                              </FormHelperText>
                              <span>
                                {content.length > 0 ? content[index].value : ""}
                              </span>
                            </GridItem>
                          ) : type == "AUDIO" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px dashed #111d5e",
                                borderRadius: "4px",
                                marginTop: "15px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              <div className={cls.audioWrapper}>
                                <input
                                  id="audio-file"
                                  accept="audio/mpeg"
                                  label="Audio"
                                  type="file"
                                  onChange={(e) =>
                                    onImageInputChange(
                                      e,
                                      index,
                                      type,
                                      content[index].content_id
                                    )
                                  }
                                  placeholder="Audio Path"
                                  helperText="Choose Audio file"
                                />
                                {content.length > 0 && content[index].value && (
                                  <span>
                                    <audio controls>
                                      <source
                                        src={
                                          content.length > 0
                                            ? content[index].value
                                            : ""
                                        }
                                      ></source>
                                    </audio>
                                  </span>
                                )}
                              </div>
                              {uploadProgress.toString().split("#")[0] > 0 &&
                              uploadProgress.toString().split("#")[0] != 100 &&
                              index ==
                                uploadProgress.toString().split("#")[1] ? (
                                <ProgressBar
                                  variant={progressVariant}
                                  value={
                                    uploadProgress.toString().split("#")[0]
                                  }
                                />
                              ) : (
                                <div
                                  className={cls.record}
                                  onClick={() => {
                                    setShowAudioDialog(true);
                                    setAudioContentExtras({
                                      index,
                                      type,
                                      content_id: content[index].content_id,
                                    });
                                  }}
                                >
                                  <Mic titleAccess="Record Live" />
                                </div>
                              )}
                            </GridItem>
                          ) : type == "PPT" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px dashed #351f39",
                                borderRadius: "4px",
                                marginTop: "15px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              <input
                                id="ppt-file"
                                accept=".pptx"
                                label="PPT"
                                type="file"
                                onChange={(e) =>
                                  onImageInputChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id
                                  )
                                }
                                placeholder="PPT Path"
                                helperText="Choose PPT file (Max size : 7MB)"
                              />
                              {uploadProgress.toString().split("#")[0] > 0 &&
                                uploadProgress.toString().split("#")[0] !=
                                  100 &&
                                index ==
                                  uploadProgress.toString().split("#")[1] && (
                                  <ProgressBar
                                    variant={progressVariant}
                                    value={
                                      uploadProgress.toString().split("#")[0]
                                    }
                                  />
                                )}
                              <FormHelperText>
                                ( Choose file less than 4MB ){" "}
                              </FormHelperText>
                              <span>
                                {content.length > 0 ? content[index].value : ""}
                              </span>
                            </GridItem>
                          ) : type == "Link" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "90%",
                                "margin-top": "26px",
                                margin: "30px 14px",
                                justifyContent: "space-between",
                                marginRight: "12px",
                              }}
                            >
                              <TextField
                                label="Name"
                                name="linkName"
                                style={{ width: "50%" }}
                                inputProps={{ style: { fontSize: 15 } }}
                                value={
                                  content.length > 0
                                    ? content[index].value.name
                                    : ""
                                }
                                onChange={(e) =>
                                  onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id,
                                    null,
                                    content[index].color
                                  )
                                }
                              />
                              <TextField
                                label="Enter URL"
                                name="linkValue"
                                style={{
                                  width: "45%",
                                }}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{ style: { fontSize: 15 } }}
                                value={
                                  content.length > 0
                                    ? content[index].value.value
                                    : ""
                                }
                                onChange={(e) =>
                                  onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id,
                                    null,
                                    content[index].color
                                  )
                                }
                              />
                            </GridItem>
                          ) : type == "gForm" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                border: "1px dashed #09015f",
                                borderRadius: "4px",
                                marginTop: "15px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              <CustomInput
                                labelText="Enter your Google form link"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                val={
                                  content.length > 0 ? content[index].value : ""
                                }
                                change={(e) =>
                                  onChapterTextChange(
                                    e,
                                    index,
                                    type,
                                    content[index].content_id,
                                    null,
                                    content[index].color
                                  )
                                }
                                Focus={(e) => {
                                  borderFocused(e, index, type);
                                }}
                                Blur={(e) => {
                                  checkForGFormInput(e.target.value);
                                }}
                              />
                            </GridItem>
                          ) : type == "GIF" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px dashed #2c061f",
                                borderRadius: "4px",
                                marginTop: "15px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {/* <input
                                    id={`gif-file-${index}`}
                                    accept=".gif"
                                    label="GIF"
                                    type="file"
                                    style={{
                                      width: "100%",
                                      paddingBottom: "15px",
                                      borderBottom: "1px solid black",
                                    }}
                                    onChange={(e) =>
                                      onImageInputChange(
                                        e,
                                        index,
                                        type,
                                        content[index].content_id
                                      )
                                    }
                                    placeholder="GIF Path"
                                    helperText="Choose GIF file"
                                  /> */}
                                  <Button
                                    style={{
                                      backgroundColor: "#3B86FF",
                                      color: "white",
                                      margin: "3px 3px 3px",
                                    }}
                                    onClick={() => {
                                      setImageSearchBox(true);
                                      setCommonID(index);
                                      setCommonType(type);
                                      setMediaList([]);
                                      console.log(commonType, "commonType");
                                    }}
                                  >
                                    Import GIF by search
                                  </Button>
                                  <Button
                                    style={{
                                      color: "white",
                                      background: "rgb(74 70 211)",
                                      marginLeft: "5%",
                                    }}
                                    onClick={() => {
                                      setUploadDialog(true);
                                      setCommonID(index);
                                      setCommonType(type);
                                      getCategory();
                                    }}
                                  >
                                    Upload GIF
                                  </Button>
                                  {/* {uploadProgress.toString().split("#")[0] >
                                    0 &&
                                    uploadProgress.toString().split("#")[0] !=
                                      100 &&
                                    index ==
                                      uploadProgress
                                        .toString()
                                        .split("#")[1] && (
                                      <ProgressBar
                                        variant={progressVariant}
                                        value={
                                          uploadProgress
                                            .toString()
                                            .split("#")[0]
                                        }
                                      />
                                    )} */}
                                  <FormHelperText>
                                    ( Choose file less than 2MB ){" "}
                                  </FormHelperText>
                                  {content.length > 0 &&
                                    content[index].value &&
                                    content[index].value.hasOwnProperty(
                                      "filePath"
                                    ) &&
                                    content[index].value.filePath != "" && (
                                      <img
                                        className={cls.preview}
                                        src={
                                          content[index].value.filePath
                                            ? content[index].value.filePath
                                            : content[index].value
                                        }
                                      />
                                    )}
                                </div>
                                <br></br>
                                <TextEditor
                                  placeholder="GIF Description"
                                  medium ={chapter?.medium_name}
                                  height="120"
                                  name="gifDesc"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  style={{
                                    width: "100%",
                                    paddingBottom: "15px",
                                  }}
                                  onChange={(e) =>
                                    onChapterTextChange(
                                      e,
                                      index,
                                      type,
                                      content[index].content_id,
                                      null,
                                      content[index].color
                                    )
                                  }
                                  onFocus={(e) => {
                                    borderFocused(e, index, type);
                                  }}
                                  value={
                                    content.length > 0 &&
                                    content[index].value &&
                                    content[index].value.hasOwnProperty(
                                      "description"
                                    )
                                      ? content[index].value.description
                                      : ""
                                  }
                                ></TextEditor>
                              </div>
                            </GridItem>
                          ) : type == "json" ? (
                            <GridItem
                              id={`grid${index}`}
                              xs={8}
                              sm={8}
                              md={8}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px dashed #2c061f",
                                borderRadius: "4px",
                                marginTop: "15px",
                                background: "white",
                                marginRight: "12px",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {/* <TextField
                                    id={`json-file-${index}`}
                                    accept=".json"
                                    label="lottie"
                                    type="file"
                                    style={{
                                      width: "100%",
                                      paddingBottom: "15px",
                                    }}
                                    onChange={(e) =>
                                      onImageInputChange(
                                        e,
                                        index,
                                        type,
                                        content[index].content_id
                                      )
                                    }
                                    placeholder="lotiie Path"
                                    helperText="Choose lotiie file"
                                  /> */}
                                  <Button
                                    style={{
                                      backgroundColor: "#3B86FF",
                                      color: "white",
                                      margin: "3px 3px 3px",
                                    }}
                                    onClick={() => {
                                      setImageSearchBox(true);
                                      setCommonID(index);
                                      setCommonType(type);
                                      setMediaList([]);
                                      console.log(commonType, "commonType");
                                    }}
                                  >
                                    Import Lotti by search
                                  </Button>
                                  <Button
                                    style={{
                                      color: "white",
                                      background: "rgb(74 70 211)",
                                      marginLeft: "5%",
                                    }}
                                    onClick={() => {
                                      setUploadDialog(true);
                                      setCommonID(index);
                                      setCommonType(type);
                                      getCategory();
                                    }}
                                  >
                                    Upload Lottie
                                  </Button>
                                  {/* {uploadProgress.toString().split("#")[0] >
                                    0 &&
                                    uploadProgress.toString().split("#")[0] !=
                                      100 &&
                                    index ==
                                      uploadProgress
                                        .toString()
                                        .split("#")[1] && (
                                      <ProgressBar
                                        variant={progressVariant}
                                        value={
                                          uploadProgress
                                            .toString()
                                            .split("#")[0]
                                        }
                                      />
                                    )} */}
                                  <FormHelperText>
                                    ( Choose file less than 2MB ){" "}
                                  </FormHelperText>
                                  {content.length > 0 &&
                                    content[index].value &&
                                    content[index].value.hasOwnProperty(
                                      "filePath"
                                    ) &&
                                    content[index].value.filePath != "" && (
                                      <img
                                        className={cls.preview}
                                        src={
                                          content[index].value.filePath
                                            ? content[index].value.filePath
                                            : content[index].value
                                        }
                                      />
                                    )}
                                </div>
                                <br></br>
                                <TextEditor
                                  placeholder="lottie Description"
                                  medium ={chapter?.medium_name}
                                  height="120"
                                  name="jsonDesc"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  style={{
                                    width: "100%",
                                    paddingBottom: "15px",
                                  }}
                                  onChange={(e) =>
                                    onChapterTextChange(
                                      e,
                                      index,
                                      type,
                                      content[index].content_id,
                                      null,
                                      content[index].color
                                    )
                                  }
                                  onFocus={(e) => {
                                    borderFocused(e, index, type);
                                  }}
                                  value={
                                    content.length > 0 &&
                                    content[index].value &&
                                    content[index].value.hasOwnProperty(
                                      "description"
                                    )
                                      ? content[index].value.description
                                      : ""
                                  }
                                ></TextEditor>
                              </div>
                            </GridItem>
                          ) : null}

                          <div className={cls.item_actions}>
                            <div
                              {...provided.dragHandleProps}
                              className={cls.drag_handle}
                            >
                              <DragHandle titleAccess="Drag element" />
                            </div>
                            <IconButton
                              aria-label="delete"
                              style={{ marginTop: 10 }}
                              onClick={() => {
                                setFieldToBeDelete(index);
                                setShowDeleteFieldDialog(true);
                              }}
                              title="Delete element"
                            >
                              <DeleteIcon style={{ fill: "red" }} />
                            </IconButton>
                            <IconButton
                              data-tip
                              data-for="registerTip"
                              //title="Help"
                              size="small"
                              style={{ alignSelf: "center" }}
                              onClick={() => {}}
                            >
                              <HelpOutlineIcon />
                            </IconButton>
                            <ReactTooltip
                              id="registerTip"
                              place="right"
                              effect="solid"
                              backgroundColor="#2c9ae8"
                            >
                              Information for adding element
                            </ReactTooltip>
                            <IconButton
                              title="Add element"
                              style={{ alignSelf: "center" }}
                              onClick={() => {
                                setAddWidgetModal(true);
                                setContentIndex(index);
                              }}
                            >
                              <AddCircleOutlineIcon
                                style={{ fill: "lightgreen" }}
                              />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  };

  const saveChanges = async () => {
    if (!content.length && (partName || tagName)) {
      setError(true);
      setMessage(
        "Please choose content to add first from top panel inside newly added part"
      );
      return;
    }

    setLoadingUpdatePart(true);
    console.log(...userChapter.parts, "...userChapter.parts");
    const parts = [...userChapter.parts];
    console.log(parts, "parts -- before updating");
    parts.pop();

    console.log(
      editChapterName,
      "PARTS",
      { parts },
      "userChapter",
      userChapter
    );
    console.log(content, "prt ");

    const updatedParts = [];
    parts.map((p, index) => {
      if (p.content && p.content.length > 0) {
        console.log(p, "p in part ");
        console.log(content, "p in part ");
        let isEditedArr = []
        for (let content of p.content) {
          content.content_id = p.content_id;


          if(content.unique_id){
            if(content.type != "IMG" && content.type != "GIF" && content.type != "json" &&content.type != "PDF"&&content.type != "PPT"&&content.type != "AUDIO"&&content.type != "gForm"){
              userDuplicateArr.forEach((i,index)=>{
                if(content.content_id == i.content_id){
                  if(i.unique_id == content.unique_id  ){
                    if(content.type != "IMG" && content.type != "GIF" && content.type != "json"){
                      if(content.type == "TEXT"){
                        if(i.value != content.value){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      else if(content.type == "AUDIO"){

                        if(i.value != content.value){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      else if(content.type == "VIDEO"){
                        if(i.value.url != content.value.url || i.value.description != content.value.description  ){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      else if(content.type == "PDF"){
                        if(i.value != content.value){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      else if(content.type == "PPT"){
                        if(i.value != content.value){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      else if(content.type == "gForm"){
                        if(i.value != content.value){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      else if(content.type == "Link"){
                        if(i.value?.value != content.value?.value){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      else if(content.type == "SPECIAL_TEXT"){
                        if(i.value != content.value){
                          let arr= {...content,updated:true}
                          isEditedArr.push(arr)
                        }
                        else{
                          let arr= { ...content }
                          isEditedArr.push(arr)
                        }
                      }
                      
                    }
                    else{
                      let arr= { ...content }
                      isEditedArr.push(arr)
                    }
                  }
                }
               })
            } 
            else{
              let arr= {...content}
              isEditedArr.push(arr)
            }
          }
          else{
                let arr= {...content,updated:true}
                console.log(arr,"normal content blank10")
                  isEditedArr.push(arr)
          }

          console.log(content.value.hasOwnProperty("filePath"), "content val");
          if (content.value.hasOwnProperty("filePath")) {
            if (!content.value.filePath) {
              setError(true);
              setOnSaveChapter(false);
              setLoadingUpdatePart(false)
              setMessage(
                "Please add content in  element "
              );
              return;
            }
          }
          else if (content.value.hasOwnProperty("url")) {
            if (!content.value.url) {
              setError(true);
              setOnSaveChapter(false);
              setLoadingUpdatePart(false)
              setMessage(
                "Please add content in  element "
              );
              return;
            }
          }
        }
        // let { content_id, ...partcontent } = p.content;
        console.log([...p.content], "partcontent --");
        console.log([...isEditedArr], "partcontent --");
        
        updatedParts.push({
          // content_id:
          //   parts[index].content && parts[index].content.length > 0
          //     ? parts[index].content[0].content_id
          //     : null,
          subject_id: chapter.subject_id ? chapter.subject_id : DupSubject,
          class_id: chapter.class_id ? chapter.class_id : DupClass,
          chapter_id: p.chapter_id,
          part_no: currentTabIndex,
          part_id: p.part_id,
          content_id: p.content_id ? p.content_id : null,
          data: [...isEditedArr],
          // isUpdated : p.isUpdated ? p.isUpdated : null
        });
      }
    });

    console.log(updatedParts, "updatedParts -- after updating");
    console.log(uploadID, "uploadID");
    let filterParts = updatedParts.filter((d) => {
      // if(d.content_id == uploadID){
      //   return d
      // }
      return uploadID.some((o2) => {
        return d.content_id == o2; // return the ones with equal id
      });
    });
    console.log(filterParts, "filterParts");
    console.log(
      (parseInt(userChapter.meta.no_of_parts) + 1).toString(),
      "content1"
    );
    let param = {
      board_id: userChapter.meta.board_id,
      chapter_name: userChapter.meta.chapter_name,
      chapter_no: userChapter.meta.chapter_no,
      chapter_number: userChapter.meta.chapter_no,
      class_id: userChapter.meta.class_id,
      district_id: userChapter.meta.district_id,
      medium_id: userChapter.meta.medium_id,
      no_of_parts: content
        ? (parseInt(userChapter.meta.no_of_parts) + 1).toString()
        : userChapter.meta.no_of_parts,
      state_id: 1,
      subject_id: userChapter.meta.subject_id,
      parts: filterParts,
      is_deleted: isDelete,
    };

    console.log(param, "param");
    console.log(userChapter, "content");
    // let updatedContent = content.map((c) => {
    //   return {
    //     ...c,
    //     sequence_id: userChapter.parts.length + 1,
    //     content_id: content_id,
    //     color: partColor,
    //   };
    // });
    console.log(partName, "partName", tagName);
    let param1 = {
      chapter_id: userChapter.meta.chapter_id,
      class_id: userChapter.meta.class_id,
      //subject_id: userChapter.meta.subject_id,
      part_no: currentTabIndex + 1,
      data: content,
      sequence: currentTabIndex + 1,
      part_name: partName,
      tags: tagName,
    };
    console.log(content, "content1");
    if (content.length > 0) {
      filterParts.push(param1);
    }

    console.log(filterParts, "filterParts");
    console.log(param1, "param1");
    if (filterParts.length > 0 || isDelete == true) {
      updateChapterNew(userChapter.meta.chapter_id, param).then((res) => {
        console.log(res, "res");
        setMessage("Your request is in progress, check notification box");
        setTimeout(() => {
          const user = JSON.parse(localStorage.getItem("user"));
          if (
            user.user_type === constants.USER_TYPE.SUPER_ADMIN ||
            user.user_type === constants.USER_TYPE.ADMIN
          ) {
            document.location.href = "/admin/chapters";
          } else {
            document.location.href = "/user/lessons";
          }
        }, 4000);
      });
    }
    // if (content.length > 0) {
    //   http.patch(`chapters/${userChapter.meta.chapter_id}/`,param1
    // ).then((res)=>{
    //   console.log(res,"res");

    // })
    // }
    // const pArray = [];
    // filterParts.forEach((param, index) => {
    //   // let p = getContentWriterService().uploadChapterContent(param);
    //   let p = getContentWriterService().uploadChapterContentCDN(param);
    //   pArray.push(p);
    // });

    // let allRes = Promise.all(pArray)
    //   .then((allRes) => {
    //     if (content.length > 0) {
    //       handleChapterSubmit();
    //     } else {
    //       setError(false);
    //       setMessage("Chapter updated successfully");

    //       // setTimeout(() => {
    //       //   props.history.go(0);
    //       // }, 1000);
    //       cancelEdit();
    //       setOnSaveChapter(false);
    //       setLoadingUpdatePart(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("%c Something has gone wrong", "color: red");
    //     console.log(err.message);
    //     setLoadingUpdatePart(false);
    //   });
  };

  // const saveChanges = async () => {
  //   if (!content.length && (partName || tagName)) {
  //     setError(true);
  //     setMessage(
  //       "Please choose content to add first from top panel inside newly added part"
  //     );
  //     return;
  //   }

  //   setLoadingUpdatePart(true);
  //   const parts = [...userChapter.parts];
  //   console.log(parts, "parts -- before updating");
  //   parts.pop();

  //   console.log(
  //     editChapterName,
  //     "PARTS",
  //     { parts },
  //     "userChapter",
  //     userChapter
  //   );

  //   const updatedParts = [];
  //   parts.map((p, index) => {
  //     if (p.content && p.content.length > 0) {
  //       console.log(p, "p in part ");
  //       for (let content of p.content) {
  //         content.content_id = p.content_id;
  //       }
  //       // let { content_id, ...partcontent } = p.content;
  //       console.log(p.content, "partcontent --");
  //       updatedParts.push({
  //         // content_id:
  //         //   parts[index].content && parts[index].content.length > 0
  //         //     ? parts[index].content[0].content_id
  //         //     : null,
  //         subject_id: chapter.subject_id ? chapter.subject_id : DupSubject,
  //         class_id: chapter.class_id ? chapter.class_id : DupClass,
  //         chapter_id: p.chapter_id,
  //         part_no: currentTabIndex,
  //         part_id: p.part_id,
  //         content_id: p.content_id ? p.content_id : null,
  //         content: { ...p.content },
  //         // isUpdated : p.isUpdated ? p.isUpdated : null
  //       });

  //     }
  //   });

  //   console.log(updatedParts, "updatedParts -- after updating");
  //   const pArray = [];
  //   updatedParts.forEach((param, index) => {
  //     // let p = getContentWriterService().uploadChapterContent(param);
  //     let p = getContentWriterService().uploadChapterContentCDN(param);
  //     pArray.push(p);
  //   });

  //   let allRes = Promise.all(pArray)
  //     .then((allRes) => {
  //       if (content.length > 0) {
  //         handleChapterSubmit();
  //       } else {
  //         setError(false);
  //         setMessage("Chapter updated successfully");

  //         // setTimeout(() => {
  //         //   props.history.go(0);
  //         // }, 1000);
  //         cancelEdit();
  //         setOnSaveChapter(false);
  //         setLoadingUpdatePart(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("%c Something has gone wrong", "color: red");
  //       console.log(err.message);
  //       setLoadingUpdatePart(false);
  //     });
  // };

  const cancelEdit = () => {
    setEditMode(false);
    let updatedParts = [...userChapter.parts];
    updatedParts = updatedParts.filter((part) => {
      return part.hasOwnProperty("part_id");
    });
    // setCurrentTabIndex(updatedParts.length - 1);

    setCurrentTabIndex(currentTabIndex);
    //console.log(currentTabIndex, "currentTabIndex................");

    setUserChapter({
      ...userChapter,
      parts: updatedParts,
    });
  };

  const getYTVideoID = (value) => {
    if (value) {
      let url = value.hasOwnProperty("url") ? value.url : value;
      url = url.toString().trim();
      var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      return match && match[1];
    } else {
      return;
    }
  };

  const handleChangeChapterStatusOn = () => {
    setChangeChapterStatusOn(!changeChapterStatusOn);
  };

  const handleChapterStatusChange = (e) => {
    setChapterStatus(e.target.value);
  };

  const cancelChangeStatus = () => {
    setChangeChapterStatusOn(false);
  };
  const partsForDuplicate = () => {
    if (userChapter.parts) {
      let length = userChapter.parts.length;
      console.log(length, "L");
      let contentTrans;
      userChapter.parts.map(async (res, index) => {
        if (index == length - 1) {
          newDuplicateChapter();
        }
        let updatedStatusRes = await httpV2.get(`contenturl/${res.part_id}/`);
        console.log(updatedStatusRes.data, "part.part_id");
        const chapterContent = await getSharedService().getContentFromUrl(
          updatedStatusRes.data
        );
        contentTrans = await chapterContent.json();
        console.log(contentTrans, "contentTrans");
        if (contentTrans) {
          let p = [];
          // partRes.response.content = await getContentFromUrl(partRes.response);
          //console.log(partRes.response.content, "partRes.response.content");
          for (let key in contentTrans) {
            p.push(contentTrans[key]);
          }
          const updatedParts = [...userChapter.parts];
          console.log(updatedParts[index], "updatedParts", p);
          console.log(p, "P");
          updatedParts[index].content = p;
          setUserChapter({
            ...userChapter,
            meta: chapter,
            parts: updatedParts,
          });
        }
      });
    }
  };
  const newDuplicateChapter = async () => {
    console.log(userChapter.parts, "test");
    const board_id = propsState.board_id;
    const district_id = propsState.district_id;
    const class_id = DupClass;
    const medium_id = DupMedium;
    const subject_id = DupSubject;
    const chapter_name = DupChapterName;
    const chapter_number = DupChapterNumber;
    const no_of_parts = userChapter.parts.length;

    let parts = userChapter.parts.map((part) => {
      return {
        part_name: part.part_name,
        tags: part.tags,
        sequence: part.sequence,
        data: part.content,
        // old_part_id: part.part_id,
      };
    });
    console.log(parts, "data");
    let filter = userChapter.parts.map((part, index) => {
      console.log(part.content, index);
      if (part && part.content) {
        part.content.map((res) => {
          console.log(res);
          if (res) {
            let key = delete res.content_id;
          }

          //  console.log(key);
        });
      }
    });
    const param = {
      board_id: board_id,
      district_id: district_id,
      class_id: class_id,
      medium_id: medium_id,
      subject_id: subject_id,
      chapter_name: chapter_name,
      chapter_no: chapter_number,
      chapter_number: chapter_number,
      no_of_parts: no_of_parts,
      parts: parts,
      state_id: 1,
      status: "IN-REVIEW",
    };

    console.log(param, "param");

    CWChapterCreate(param).then((data) => {
      console.log(data, "data");
      if (data.status == 201) {
        setMessage(
          "Your request is processing in background you can check in chapter list"
        );
      } else {
        setMessage("Failed request");
      }

      handleClose();
    });
  };
  //  const duplicateChapter = async () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if (
  //     DupClass &&
  //     DupMedium &&
  //     DupSubject &&
  //     DupChapterName &&
  //     DupChapterNumber
  //   ) {
  //     setLoading(true);
  //     const board_id = propsState.board_id;
  //     const district_id = propsState.district_id;
  //     const class_id = DupClass;
  //     const medium_id = DupMedium;
  //     const subject_id = DupSubject;
  //     const chapter_name = DupChapterName;
  //     const chapter_number = DupChapterNumber;
  //     const no_of_parts = userChapter.parts.length;

  //     let parts = userChapter.parts.map((part) => {
  //       return {
  //         part_name: part.part_name,
  //         tags: part.tags,
  //         sequence: part.sequence,
  //         old_part_id: part.part_id,
  //       };
  //     });

  //     const param = {
  //       board_id: board_id,
  //       district_id: district_id,
  //       class_id: class_id,
  //       medium_id: medium_id,
  //       subject_id: subject_id,
  //       chapter_name: chapter_name,
  //       chapter_no: chapter_number,
  //       no_of_parts: no_of_parts,
  //       parts: parts,
  //     };

  //     if (
  //       user.user_type === constants.USER_TYPE.ADMIN ||
  //       user.user_type === constants.USER_TYPE.SUPER_ADMIN
  //     ) {
  //       param.status = "IN-REVIEW";
  //     }

  //     let createChapterRes = await getContentWriterService().createChapterAndPartsForCWAdmin(
  //       param
  //     );
  //     //console.log(createChapterRes.data.response.chapter_id,"focus");
  //     setchapterNewId(createChapterRes.data.response.chapter_id);
  //     let iDCredit = createChapterRes.data.response.chapter_id;
  //     console.log(chapterNewId, "focus");
  //     createChapterRes = createChapterRes.data;
  //     if (createChapterRes && createChapterRes.status === 200) {
  //       parts = createChapterRes.response.parts.map((p, index) => {
  //         return { ...p, old_part_id: parts[index].old_part_id };
  //       });

  //       const contentIdParts = [];
  //       parts.forEach((part) => {
  //         const param = {
  //           part_id: part.part_id,
  //           chapter_id: part.chapter_id,
  //         };
  //         // const contentIDCreateRes = getContentWriterService().createChapterContentId(
  //         //   param
  //         // );
  //         const contentIDCreateRes = getContentWriterService().createChapterContentIdNew(
  //           param
  //         );
  //         contentIdParts.push(contentIDCreateRes);
  //       });

  //       Promise.all(contentIdParts)
  //         .then((result) => {
  //           result.forEach((res, index) => {
  //             parts[index].content_id = res.data.response.content_id;
  //           });

  //           const partContent = [];
  //           parts.forEach((part) => {
  //             const param = {
  //               part_id: part.old_part_id,
  //             };
  //             // const getPartContentRes = getSharedService().getChapterPartContent(
  //             //   param
  //             // );
  //             const getPartContentRes = getSharedService().getChapterPartContentNew(
  //               param
  //             );

  //             partContent.push(getPartContentRes);
  //           });

  //           Promise.all(partContent).then((result) => {
  //             result.forEach(async (res, index) => {
  //               let contentArray = [];
  //               // res.data.response.content = await getContentFromUrl(res.data.response);
  //               for (let c in res.data.response.content) {
  //                 contentArray.push({
  //                   type: res.data.response.content[c].type,
  //                   value: res.data.response.content[c].value,
  //                   sequence: res.data.response.content[c].sequence,
  //                 });
  //               }
  //               parts[index].content = contentArray;
  //             });

  //             const uploadContent = [];
  //             setTimeout(() => {
  //               parts.forEach((part) => {
  //                 let contentArray = part.content.map((c) => {
  //                   return { ...c, content_id: part.content_id };
  //                   // return { ...c };
  //                 });
  //                 const param = {
  //                   subject_id: DupSubject,
  //                   class_id: DupClass,
  //                   chapter_id: part.chapter_id,
  //                   part_no: currentTabIndex,
  //                   part_id: part.part_id,
  //                   content_id: part.content_id,
  //                   content: { ...contentArray },
  //                 };

  //                 // const uploadContentRes = getContentWriterService().uploadChapterContent(
  //                 //   param
  //                 // );
  //                 const uploadContentRes = getContentWriterService().uploadChapterContentCDN(
  //                   param
  //                 );

  //                 uploadContent.push(uploadContentRes);
  //               });
  //             }, 1000);

  //             Promise.all(uploadContent).then(async (result) => {
  //               setLoading(false);
  //               setMessage("Chapter duplicated successfully");
  //               setError(false);
  //               console.log(iDCredit);
  //               let param = {
  //                 chapter_id: iDCredit,
  //                 credits: userChapter.credits,
  //               };
  //               await getSuperAdminService().updateChapterCredit(param);

  //               setTimeout(() => {
  //                 const user = JSON.parse(localStorage.getItem("user"));
  //                 if (user.user_type == constants.USER_TYPE.CONTENT_WRITER) {
  //                   props.history.push("/user/lessons");
  //                 } else if (user.user_type == constants.USER_TYPE.ADMIN) {
  //                   props.history.push("/admin/lessons");
  //                 } else {
  //                   props.history.push("/admin/chapters");
  //                 }
  //               }, 1500);
  //             });
  //           });
  //         })
  //         .catch((err) => {
  //           setMessage("Something has went wrong");
  //           setError("danger");
  //           setLoading(false);
  //         });
  //     } else {
  //       setMessage("Something has went wrong");
  //       setError("danger");
  //       setLoading(false);
  //     }
  //   } else {
  //     setLoading(false);
  //     setMessage("Please fill all details");
  //     setError("danger");
  //   }
  // }; old function

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCloseEdit = () => {
    setEditing(false);
  };
  const setResult = (values) => {
    let marks = 0;
    let questionsMCQ = JSON.stringify(values, null, 2);
    console.log(
      values,
      "val",
      userChapter.parts[currentTabIndex].part_id,
      questionsMCQ,
      currentTabIndex
    );

    values.questions.map((res) => {
      marks = marks + parseInt(res.point);
    });
    console.log(marks, "marks");
    let param = {
      questions: questionsMCQ,
      created_by: userChapter.meta.createdBy,
      max_marks: marks,
      chapter: userChapter.meta.chapter_id,
      part: userChapter.parts[currentTabIndex].part_id,
      learning_element: outcomeID,
    };
    createMCQ(param).then((res) => {
      console.log(res);

      setMessage("Mcq created successfully");
      setError("success");
      setQuizDialog(false);
      if (res) {
        window.location.reload();
      }
    });
  };
  const setQuestion = (values) => {
    setQuesDialog(false);
    let questionsMCQ = JSON.stringify(values, null, 2);
    console.log(
      values,
      "val",
      userChapter.parts[currentTabIndex].part_id,
      questionsMCQ,
      currentTabIndex
    );

    let data = JSON.parse(questionsMCQ);
    console.log(data.questions, "questionsMCQ");
    data.questions.map((res) => {
      quizStore.questions.push(res);
    });
    console.log(quizStore, "quizStore");
    updateMCQ();
    // let param = {
    //   questions: questionsMCQ,
    //   created_by: userChapter.meta.createdBy,
    //   max_marks: 20,
    //   chapter: userChapter.meta.chapter_id,
    //   part: userChapter.parts[currentTabIndex].part_id,
    // };
    // createMCQ(param).then((res) => {
    //   console.log(res);
    //   setMessage("Mcq created successfully");
    //   setError("success");
    //   setQuizDialog(false);
    // });
  };
  useEffect(() => {
    getMediumByClassId();
  }, [DupState]);

  const getMediumByClassId = async () => {
    const param = {
      state_id: DupState,
    };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    mediumRes = mediumRes.data;

    if (mediumRes && mediumRes.status == 200) {
      setDupMediums(mediumRes.response);
      setDupMedium(mediumRes.response[0].medium_id);
      let { chapter } = props.location.state;
      let medium_id = chapter.medium_id
        ? chapter.medium_id
        : mediumRes.response[0].medium_id;
      let class_id = chapter.class_id ? chapter.class_id : DupClass;
      console.log(chapter, "chapterprops", mediumRes.response[0].medium_id);
      getSubjectFromClass(class_id, medium_id);
    } else {
      setDupMediums([]);
      setDupMedium(null);
    }
  };

  const getSubjectFromClass = async (class_id, medium_id) => {
    const param = {
      class_id: class_id,
      medium_id: medium_id,
    };

    let subRes = await getContentWriterService().getSubjectByClassMed(param);
    subRes = subRes.data;
    if (subRes && subRes.status == 200) {
      setDupSubjects(subRes.response);
      if (subRes.response.length > 0)
        setDupSubject(subRes.response[0]?.subject_id);
      setEditSubject(subRes.response[0]?.subject_id);
    }
  };

  const handleDuplicateChapter = () => {
    setModalOpen(true);
  };

  const downloadChapter = () => {
    setLoading(true);
    let p = [];
    let partArray = [];

    partArray = userChapter.parts.map(async (x) => {
      let param = {
        part_id: x.part_id,
      };

      return await getSharedService().getChapterPartContentNew(param);
    });

    Promise.all(partArray).then((result) => {
      console.log(result, "result");
      result.map((data) => {
        p.push(...Object.values(data.data.response.content));
      });
      generatePdfData(p);
    });
  };

  const generatePdfData = (chapterPart) => {
    console.log(chapterPdf, "chapterPdf ----------");
    let pdfContent = "";
    pdfContent += `<h1 style={{marginLeft:"4em"}}>${userChapter.meta.chapter_name}</h1>`;
    chapterPart.map((data) => {
      if (data.type == "TEXT") {
        pdfContent += `<div>${data.value}</div>`;
      } else if (data.type == "IMG") {
        if (data.value.filePath) {
          pdfContent += `</br> <img src="${data.value.filePath}" height="200" /></br>`;
        }
      } else if (data.type == "VIDEO") {
        pdfContent += `<p ><b>[${data.type}]</b> ${data.value.url}</p>${data.value.description}`;
      } else {
        const value =
          data && data.value && data.value.hasOwnProperty("filePath")
            ? data.value.filePath
            : data.value;
        pdfContent += `<p><b>[${data.type}]</b> ${value}</p>`;
      }
    });
    const pd = `<div id="pdf"  >
             ${pdfContent}
             </div>`;
    let date = new Date().toLocaleString();
    const opt = {
      margin: 1,
      filename: `${userChapter.meta.chapter_name}_${userChapter.meta.class_name}_${userChapter.meta.medium_name}_${date}_VOPA`,
      pagebreak: {
        mode: "",
        before: ".break-before",
        after: ".break-after",
        avoid: ".break-avoid",
        avoid: "img",
      },
      enableLinks: true,
      image: { type: "jpeg/png", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        unit: "in",
        format: "a4",
        compress: true,
        orientation: "portrait",
      },
    };

    html2pdf()
      .from(pd)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(7);
          pdf.setTextColor(100);
          pdf.text(
            "Page " + i + " of " + totalPages,
            pdf.internal.pageSize.getWidth() / 2.3,
            pdf.internal.pageSize.getHeight() - 0.8
          );
        }
      })
      .save();
    setTimeout(function () {
      setLoading(false);
    }, 7000);
  };

  const getStates = async () => {
    let stateReq = await getSuperAdminService().getAllState();
    stateReq = stateReq.data;
    if (stateReq && stateReq.status === 200) {
      setDupStates(stateReq.response);
      setDupState(stateReq.response[0].state_id);
    }
  };

  const onColorChange = (e, index) => {
    const parts = [...userChapter.parts];
    const updatedParts = parts[index];
    // updatedParts.isUpdated = true
    // console.log("isUpdated from onColorChange",index)
    updatedParts.content = updatedParts.content.map((c) => {
      return { ...c, color: e.target.value };
    });
    parts[index] = updatedParts;
    setUserChapter({
      ...userChapter,
      parts: parts,
    });
  };

  const onDeleteChapter = async () => {
    setShowDeleteDialog(false);
    // const { chapter } = props.location.state;
    try {
      let param = {
        is_deleted: true,
      };
      let chapterDelRes = await chapterDetailsUpdate(chapter.chapter_id, param);
      chapterDelRes = chapterDelRes.data;
      console.log(chapterDelRes, "chapterDelRes");
      if (chapterDelRes) {
        setMessage("Chapter deleted successfully");
        setError("success");
        setTimeout(() => {
          const user = JSON.parse(localStorage.getItem("user"));
          if (
            user.user_type == constants.USER_TYPE.SUPER_ADMIN ||
            user.user_type == constants.USER_TYPE.ADMIN
          ) {
            props.history.push("/admin/chapters");
          } else {
            props.history.push("/user/lessons");
          }
        }, 1500);
      } else {
        setMessage("Something has went wrong");
        setError("danger");
      }
    } catch (error) {
      setMessage("Something has went wrong");
      setError("danger");
    }
  };

  const handleDeleteChapterPart = async (part) => {
    setShowDeletePartDialog(false);
    if (userChapter.parts.length == 2) {
      setMessage("You should have atleast 2 parts to delete one");
      setError(true);
      return;
    }
    const param = {
      part_id: part.part_id,
      is_deleted: true,
    };
    console.log(param);
    try {
      let chapterPartDelRes;
      deleteUpdateChapterPart(param).then((res) => {
        console.log(res, "res");

        // chapterPartDelRes = chapterPartDelRes.data;
        console.log(chapterPartDelRes, "chapterPartDelRes");
        if (res.status === 200) {
          setMessage("Part delete successfully");
          setError(false);
          setTimeout(() => {
            props.history.go(0);
          }, 1500);
          // const filteredParts = [...userChapter.parts].filter((p) => {
          //   return p.part_id != part.part_id;
          // });
          // setUserChapter({
          //   ...userChapter,
          //   parts: filteredParts,
          // });
          setCurrentTabIndex(0);
        } else {
          setMessage("Something has went wrong");
          setError(true);
        }
      });
    } catch (error) {
      setMessage("Something has went wrong");
      setError(true);
    }
  };

  const handleRecordedFile = (file) => {
    console.log("file", file);

    if (!file.url) {
      setMessage("Please record the audio first");
      setError(true);
      setShowAudioDialog(false);
      return;
    }

    const { index, type, content_id } = audioContentExtras;
    var blobFile = new File([file.blob], `${new Date().getTime()}.mp3`);
    console.log("file", blobFile);

    setShowAudioDialog(false);
    onImageInputChange(blobFile, index, type, content_id);
  };

  useEffect(() => {
    getChapterWiseComments();
  }, []);

  useEffect(() => {
    getAdminId();
  }, []);

  const getChapterWiseComments = async (pageNumber = 1, recordPerPage = 5) => {
    console.log(pageNumber, recordPerPage, "pageNumber=1,recordPerPage");
    let reqBody = {
      chapter_id: chapter.chapter_id,
      page_number: pageNumber,
      record_per_page: recordPerPage,
      order_column: "created_at",
      order_type: 1,
    };
    let comments = await getContentWriterService().getChapterComments(reqBody);
    comments = comments.data;
    if (comments && comments.status === 200) {
      console.log(comments.response, "comments.response");
      setChapterComments(comments.response);
    }
  };

  const onPostComment = async () => {
    let { admin_id } = adminData;
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    let reqBody = {
      chapter_id: props.match.params.id,
      comment: {
        text: commentAdded.trim(),
        attachment: {},
      },
      user_type: loggedInUser.user_type,
      administrator_id: admin_id,
    };
    let comment = await getContentWriterService().postChapterComment(reqBody);
    comment = comment.data;
    if (comment && comment.status === 200) {
      onChangeComment("");
      getChapterWiseComments(1, recorPerPage);
    }
  };

  const setPageDetails = (pageNumber, recorPerPage, totalCount) => {
    let page;
    if (totalCount < recorPerPage) {
      page = 1;
    } else {
      page = pageNumber + 1;
    }
    getChapterWiseComments(page, +recorPerPage);
    setPageNumber(page);
    setRecordPerPage(recorPerPage);
  };

  const getAdminId = async () => {
    let adminData = await getSuperAdminService().getAdminId();
    adminData = adminData.data;
    if (adminData && adminData.status === 200) {
      setAdminData(adminData.response);
    }
  };
  //   const getAdminList = async () => {
  //   let adminData = await getSuperAdminService().getAllAdminList();
  //     adminData = adminData.data.response;
  //     adminData.map((d)=>{
  //       updateAdminID.push(d)
  //     })
  //     // setAdminID(adminData)
  //   console.log(updateAdminID,"admin ");
  //   console.log(userChapter &&
  //     userChapter.meta &&
  //     userChapter.meta.createdBy," created");
  //     let indexArray = adminData.map(i => i.fullname).indexOf(userChapter &&
  //       userChapter.meta &&
  //       userChapter.meta.createdBy);
  //   console.log(indexArray,"admin ");
  //   console.log(adminData[indexArray],"admin name ");
  //   setAutoadminID(indexArray)
  //   // autoAdminID.push(indexArray)
  //   // console.log(autoAdminID,"autoAdmin");
  // };
  const onChangeChapterDetail = (value, key) => {
    console.log(value, key);
    console.log(value.length, key);

    if (key == "tagName") {
      setChipArrayLength(value.length);
      console.log(chip, "chip");
      if (value.length > 4) {
        setError("danger");
        setMessage("maximum 4 chips are allowed");
      } else {
        setChip(value);
        let stringArray = value.toString();
        console.log(stringArray, "strin");
        let partData = { ...partAndTagData };
        partData[key] = stringArray;
        setPartAndTagData(partData);
      }
    } else {
      let partData = { ...partAndTagData };
      partData[key] = value;
      setPartAndTagData(partData);
    }
  };

  const onChapterCancel = () => {
    let { chapter } = props.location.state;
    // let chapterName = { ...editChapterName }
    // chapterName.chapter_name = chapter.chapter_name
    // chapterName.class_id = chapter.class_id
    setEditChapterName(chapter);
    setChapterName(false);
  };

  const onUpdateChapterName = async () => {
    console.log(userChapter.parts[0].part_id, "user", editChapterName.user_id);
    let data = {
      // chapter_id: editChapterName.chapter_id,
      chapter_name: editChapterName.chapter_name,
      // class_id: editChapterName.class_id,
      // subject_id: editSubject,
      chapter_no: editChapterName.chapter_no,
      // medium_id: editChapterName.medium_id,
      user_id: editChapterName.user_id,
    };
    console.log(data, "data from onUpdateChapterName");
    let chapterData = await chapterDetailsUpdate(
      editChapterName.chapter_id,
      data
    );
    chapterData = chapterData.data;
    if (chapterData && chapterData.status === 200) {
      setMessage("Chapter details updated successfully");
      setChapterName(false);
      setUserChapter({
        ...userChapter,
        meta: {
          ...userChapter.meta,
          createdBy: editChapterName.userFullName,
        },
      });
    }
    setTimeout(() => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (
        user.user_type === constants.USER_TYPE.SUPER_ADMIN ||
        user.user_type === constants.USER_TYPE.ADMIN
      ) {
        props.history.push("/admin/chapters");
      } else {
        props.history.push("/user/lessons");
      }
    }, 1000);
  };

  const checkPartsValidation = () => {
    if (currentTabIndex == userChapter.parts.length - 1) {
      if (!partName || !tagName) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setChapterStatusColor = (chapter) => {
    if (chapter.status === constants.CHAPTER_STATUS.PUBLISHED) {
      return cls.published;
    } else if (chapter.status === constants.CHAPTER_STATUS.IN_REVIEW) {
      return cls.inReview;
    } else if (chapter.status === constants.CHAPTER_STATUS.CHANGES_REQUIRED) {
      return cls.changesRequired;
    } else if (chapter.status === constants.CHAPTER_STATUS.IN_PROGRESS) {
      return cls.inProgress;
    }
    return {};
  };

  const onChangeHandle = async (value) => {
    const response = await getSuperAdminService().getCWBySearch({
      search_text: value,
    });

    setOptions(response.data.response);
  };

  const onUpdatePartAndTagData = async () => {
    let data = {
      part_id: partAndTagData.part_id,
      tags: partAndTagData.tagName,
      part_name: partAndTagData.partName,
    };

    let partData = await deleteUpdateChapterPart(data);
    console.log(partData, "partData");
    if (partData && partData.status === 200) {
      setMessage("Part updated successfully");
      setEditing(false);
      let chapterData = { ...userChapter };
      if (chapterData && chapterData.parts && chapterData.parts.length) {
        chapterData.parts.map((part) => {
          if (part.part_id == partAndTagData.part_id) {
            part.part_name = partAndTagData.partName;
            part.tags = partAndTagData.tagName;
          }
        });
      }
      setUserChapter(chapterData);
    }
  };
  const userDetails = () => {
    console.log(chapter.updated_by, "id");
    let param = {
      user_id: chapter.updated_by,
    };
    getSuperAdminService()
      .getAdminCWDetail(param)
      .then((data) => {
        console.log(data, "user");
        let profileData = data.data.response.profile;
        setCreaterProfileInfo(profileData);
        if (profileData) {
          setOpenUser(true);
          settoggle1(true);
        }
      });
  };
  const createrDetails = () => {
    console.log(chapter.updated_by, "id");
    let param = {
      user_id: userChapter?.meta.user_id,
    };
    getSuperAdminService()
      .getAdminCWDetail(param)
      .then((data) => {
        console.log(data, "user");
        let profileData = data.data.response.profile;
        setProfileInfo(profileData);
        if (profileData) {
          // setOpenUser(true)
          settoggle(true);
        }
      });
  };
  const getFirstLetter = (str) => {
    if (str.trim().length) {
      return str.substr(0, 1).toUpperCase();
    }
    return "";
  };

  const deleteMCQ = () => {
    console.log(userChapter.parts[currentTabIndex].mcq[0].id, "ok");
    let id = userChapter.parts[currentTabIndex].mcq[0].id;
    deleteQuiz(id).then((Res) => {
      console.log(Res, "res");
      if (Res) {
        window.location.reload();
      }
    });
  };

  const getLearningElement = () => {
    console.log(userChapter, "chapter");
    let class_id = userChapter.meta.class_id;
    let sub_id = userChapter.meta.subject_id;
    console.log(class_id, sub_id, "sub_id");
    httpV2
      .get(`learning_element/`, {
        params: {
          classes: class_id,
          subject: sub_id,
          limit: 200,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setOutcomeList(res.data.results);
      });
  };

  const getLEValue =() =>{
   let result = outcomeList.filter(obj => {
      return obj.learning_element === userChapter.parts[currentTabIndex].mcq[0]
      .learning_element__learning_element
    })

    console.log(result,"res")
    return result.element_id

  }

  const isHTML= (text) => {
    const htmlRegex = /<[^>]+>/; 
    return htmlRegex.test(text);
  }
  const onChangeFile = (e,type) => {
    setmediaType(type)
    console.log("button clicked");
    let file = e.target.files[0]
    const fd = new FormData();
    fd.append("file",file);
    if (type == "IMAGE") {
      fd.append("media_type", "IMAGE");
    } else {
      fd.append("media_type", "AUDIO");
    }
    fd.append("name", file.name);
    // fd.append("tags", null);
    try {
      uploadNewMedia(fd).then(
        (res) => {
          let {
            data: { data },
          } = res;
          console.log(
            "File uploaded",
            data.file_url,
            "position ----",
          
          );
          seteditMediafile(data.file_url)
          // this.props.dispatch(change());
       
        },
        (err) => {
          console.log("File uploaded failed", err);
        }
      );
    } catch (e) {
      console.log(e, "e someting has went wrong");
    }
  };

    const handleFontChange = (e) => {
       seturduFont(e.target.checked);
     }

   const handleMcqFont = (e) => {
    setMcqTitleFont(e.target.checked)
  }   
   const handleUrduCursorDirection = (e) => {
  setUrduCursor(e.target.checked);
   }  
  return (
    <div className="content_panel">
      <Dialog
        onClose={() => {
          setShowAudioDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showAudioDialog}
      >
        <VoiceRecorder
          onDone={(file) => {
            handleRecordedFile(file);
          }}
        />
      </Dialog>
      <Dialog
        onClose={() => {
          setShowPDFDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showPDFDialog}
      >
        <GridItem
          id={`grid${imgIndex}`}
          xs={8}
          sm={8}
          md={8}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            border: "1px dashed #184d47",
            borderRadius: "4px",
            background: "white",
            marginTop: "15px",
            marginRight: "30px",
          }}
        >
          <div
            style={{
              height: "50em",
            }}
          >
            <div className="row">
              <div className="col-4">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    style={{
                      width: "45%",
                      marginTop: "35px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Subject
                    </InputLabel>
                    <Select
                      style={{ width: "8em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      onChange={(e) => {
                        console.log(e, e.target.value);
                        setSubIndex(e.target.value);
                      }}
                    >
                      <MenuItem value={10}>History</MenuItem>
                      <MenuItem value={20}>English</MenuItem>
                      <MenuItem value={30}>Marathi</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    style={{
                      width: "45%",
                      marginTop: "35px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Media type
                    </InputLabel>
                    <Select
                      style={{ width: "8em" }}
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      onChange={(e) => {
                        console.log(e, e.target.value);
                        setMediaIndex(e.target.value);
                      }}
                    >
                      <MenuItem value={1}>Image</MenuItem>
                      <MenuItem value={2}>GIF</MenuItem>
                      <MenuItem value={3}>Lottie</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-4">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }} className="col-sm-4"> */}
                </div>
              </div>
            </div>
            <div>
              <Button
                style={{ background: "#3f51b5" }}
                onClick={() => {
                  getAllMedia();
                }}
              >
                search
              </Button>

              {mediaUrl ? (
                <Button
                  onClick={() => {
                    setUploadSearch(true);
                    uploadMedia();
                    setShowPDFDialog(false);
                  }}
                >
                  Upload
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              {mediaList
                ? mediaList.map((res) => {
                    console.log(res, "res", commonType);
                    if (commonType == "IMG") {
                      return (
                        <img
                          onClick={(e) => {
                            setMediaUrl(res.file_url);
                          }}
                          src={res.file_url}
                          style={{ width: "15em" }}
                        />
                      );
                    }
                    if (commonType === "PDF") {
                      return (
                        <div
                          onClick={(e) => {
                            console.log("ok");
                            setMediaUrl(res.file_url);
                          }}
                        >
                          <div />
                          <Iframe
                            // onClick={(e)=>{
                            //   console.log("ok");
                            //   setMediaUrl(res.file_url);
                            // }}
                            onInferredClick={() => setMediaUrl(res.file_url)}
                            src={res.file_url}
                          />
                        </div>
                      );
                    }
                  })
                : null}
            </div>
          </div>
        </GridItem>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        // style={{
        //   height:"10em"
        // }}
        onClose={() => {
          setImageSearchBox(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={imageSearchBox}
      >
        <div
          style={{
            height: "50em",
          }}
        >
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "4%",
              color: "white",
              backgroundImage: "linear-gradient(#3f50b5,#8e24aa)",
              fontFamily: "sans-serif",
            }}
          >
            Manage Gallery
          </h2>
          <div
            style={{
              borderBottom: "3px solid black",
            }}
          ></div>
          <div className="row">
            <div className="col-4">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {/* <FormControl
                  style={{
                    width: "45%",
                    marginTop: "35px",
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                  <Select
                    style={{ width: "8em" }}
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    onChange={(e) => {
                      console.log(e, e.target.value);
                      setSubIndex(e.target.value);
                    }}
                  >
                    <MenuItem value={10}>History</MenuItem>
                    <MenuItem value={20}>English</MenuItem>
                    <MenuItem value={30}>Marathi</MenuItem>
                  </Select>
                </FormControl> */}
              </div>
            </div>

            <div className="col-4">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 35 }} className="col-sm-4"> */}
                <div
                  style={{
                    marginTop: "10%",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    className={cls.searchTerm}
                    placeholder="Search by tags"
                    onChange={(e) => {
                      setTagSearch(e.target.value);
                    }}
                  />
                  <button
                    style={{ background: "#3f51b5" }}
                    type="submit"
                    className={cls.searchButton}
                    onClick={() => {
                      setOffset(0);
                      getAllMedia();
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <FormControl className={classes.formControl}>
                  <span className={cls.label}>subject</span>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setSubSearch(e.target.value);
                      setOffset(0);
                      getCategory();
                    }}
                  >
                    {categoryList
                      ? categoryList.map((sub, index) => {
                          return (
                            <MenuItem value={sub.category_id} key={index}>
                              {sub.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div>
            {/* <Button
              type="contained"
              style={{ background: "#3f51b5" }}
              onClick={() => {
                setOffset(0);
                getAllMedia();
              }}
            >
              search
            </Button> */}

            {mediaUrl ? (
              <Button
                style={{
                  background: "#7700ed",
                  color: "white",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  console.log("ok");
                  uploadMedia();
                  setImageSearchBox(false);
                }}
              >
                Upload
              </Button>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  setOffset(offset - 10);
                  //getAllMedia();
                }}
              >
                <SkipPrevious />
              </Button>
              <Button
                onClick={() => {
                  setOffset(offset + 10);
                  //getAllMedia();
                }}
              >
                <SkipNext />
              </Button>
              <FormControlLabel
                control={
                  <Switch
                    checked={user}
                    color="primary"
                    onChange={() => {
                      setUser(!user);
                      getAllMedia();
                    }}
                    name="Self Media"
                  />
                }
                label="Self Media"
              />
            </div>
          </div>
          <div
            style={{
              background: "#d6d7ff",
            }}
          >
            {mediaList
              ? mediaList.map((res) => {
                  console.log(res.file_url, "res");
                  if (commonType == "IMG" || commonType == "GIF") {
                    return (
                      <img
                        style={{
                          width: "15em",
                          border: "3px solid white",
                          padding: "5px",
                        }}
                        onClick={(e) => {
                          uploadMedia(res.file_url);
                          //setMediaUrl(res.file_url);
                        }}
                        src={res.file_url}
                      />
                    );
                  } else if (commonType == "json") {
                    console.log("jsonFile", res.file_url);
                    return (
                      <div
                        className="col-4"
                        style={{
                          height: "30%",
                          width: "30%",
                          border: "3px solid white",
                        }}
                        onClick={() => {
                          uploadMedia(res.file_url);
                        }}
                      >
                        <Player
                          autoplay
                          loop
                          src={res.file_url}
                          onClick={() => {
                            console.log(res.file_url, "url");
                          }}
                        ></Player>
                      </div>
                    );
                  } else if (commonType === "PDF") {
                    return (
                      <Iframe
                        style={{
                          width: "15em",
                          border: "3px solid white",
                          padding: "5px",
                        }}
                        // onClick={(e)=>{
                        //   console.log("ok");
                        //   setMediaUrl(res.file_url);
                        // }}
                        onInferredClick={() => uploadMedia(res.file_url)}
                        src={res.file_url}
                      />
                    );
                  }
                })
              : null}
          </div>
        </div>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        style={{
          // width:"50%",
          height: "100%",
        }}
        onClose={() => {
          setUploadDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={uploadDialog}
      >
        <Card>
          <CardBody>
            <h4
              className={classes.cardTitleWhite}
              style={{
                color: "white",

                backgroundImage: "linear-gradient(#3f50b5,#8e24aa)",
                fontFamily: "sans-serif",
                fontSize: "20px",
                padding: "2px 2px 2px",
                paddingLeft: "35%",
              }}
            >
              Manage Media upload
            </h4>
            <div
              style={{
                borderBottom: "3px solid black",
              }}
            ></div>
            <div>
              <div style={{}}>
                <Input
                  style={{
                    paddingTop: "40px",
                  }}
                  placeholder="Add Tag for media"
                  label="add tag"
                  onChange={(e) => {
                    setTagMedia(e.target.value);
                  }}
                />
                <Input
                  style={{
                    paddingTop: "40px",
                    marginLeft: "20%",
                  }}
                  placeholder="Add name for media"
                  label="add name"
                  onChange={(e) => {
                    setMediaNanme(e.target.value);
                  }}
                />
              </div>
              <div style={{ padding: "5px 5px" }}>
                <div>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Class</InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label2"
                      id="demo-controlled-open-select2"
                      style={{ width: "350%" }}
                      label="Class"
                      value={selectClass}
                      onChange={(e) => {
                        setSelectClass(e.target.value);
                      }}
                    >
                      <MenuItem value={1}>1St</MenuItem>
                      <MenuItem value={2}>2nd</MenuItem>
                      <MenuItem value={3}>3rd</MenuItem>
                      <MenuItem value={4}>4th</MenuItem>
                      <MenuItem value={5}>5th</MenuItem>
                      <MenuItem value={6}>6th</MenuItem>
                      <MenuItem value={7}> 7th</MenuItem>
                      <MenuItem value={8}>8th</MenuItem>
                      <MenuItem value={9}>9th</MenuItem>
                      <MenuItem value={10}> 10th</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    style={{ marginLeft: "50%", paddingTop: "2px" }}
                    className={classes.formControl}
                  >
                    <span className={cls.label}>subject</span>
                    <Select
                      style={{ width: "12em" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => {
                        setSubjectMedia(e.target.value);
                        console.log(e.target.value);
                      }}
                    >
                      {categoryList
                        ? categoryList.map((sub, index) => {
                            return (
                              <MenuItem value={sub.category_id} key={index}>
                                {sub.name}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {console.log(commonType, "commonType")}
              <div style={{ paddingTop: "5%" }}>
                {commonType == "PDF" ? (
                  <input
                    disabled={tagMedia && subjectMedia ? false : true}
                    id="pdf-file"
                    accept=".pdf"
                    label="PDF"
                    type="file"
                    onChange={(e) =>
                      onImageInputChange(e, commonID, commonType, null)
                    }
                    placeholder="PDF Path"
                    helperText="Choose PDF file (Max size : 7MB)"
                    onFocus={(e) => borderFocused(e, commonID, commonType)}
                  />
                ) : commonType == "IMG" ? (
                  <input
                    disabled={tagMedia && subjectMedia ? false : true}
                    id={`image-file-${commonID}`}
                    accept="image/jpeg"
                    label="Image"
                    type="file"
                    style={{
                      width: "50%",
                      paddingBottom: "15px",
                      paddingTop: "10px",
                      borderBottom: "1px solid black",
                    }}
                    onChange={(e) =>
                      onImageInputChange(e, commonID, commonType, null)
                    }
                    placeholder="Image Path"
                    helperText="Choose Image file"
                  />
                ) : // :
                //  commonType == "PPT" ? (
                //   <input
                //   disabled={tagMedia ? false : true}
                //     id="ppt-file"
                //     accept=".ppt"
                //     label="PPT"
                //     type="file"
                //     onChange={(e) => onImageInputChange(e, commonID, commonType, null)}
                //     placeholder="PPT Path"
                //     helperText="Choose PPT file (Max size : 7MB)"
                //   />
                // )
                commonType == "GIF" ? (
                  <input
                    disabled={tagMedia && subjectMedia ? false : true}
                    id={`gif-file-${commonID}`}
                    accept=".gif"
                    label="GIF"
                    style={{
                      width: "100%",
                      paddingBottom: "15px",
                      borderBottom: "1px solid black",
                    }}
                    type="file"
                    onChange={(e) =>
                      onImageInputChange(e, commonID, commonType, null)
                    }
                    placeholder="GIF Path"
                    helperText="Choose GIF file"
                  />
                ) : commonType == "json" ? (
                  <TextField
                    disabled={tagMedia && subjectMedia ? false : true}
                    id={`json-file-${commonID}`}
                    accept=".json"
                    label="JSON"
                    style={{
                      width: "100%",
                      paddingBottom: "15px",
                    }}
                    type="file"
                    onChange={(e) =>
                      onImageInputChange(e, commonID, commonType, null)
                    }
                    placeholder="Lottie File Path"
                    helperText="Choose Lottie  file"
                  />
                ) : null}
                <FormHelperText>( enter tags & subject first ) </FormHelperText>
              </div>
            </div>
          </CardBody>
          {/* <CardFooter>
          <Button
          style={{
            background:"#3f50b5",
            marginLeft:"45%"
          }}
          onClick={()=>{
            setUploadDialog(false);
          }}>
            Save
          </Button>
        </CardFooter> */}
        </Card>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        disableBackdropClick
        // style={{ height: "70%" }}
        onClose={() => {
          setQuizDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={QuizDialog}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#3f50b5",
          }}
        >
          <IconButton onClick={() => setQuizDialog(false)}>
            <ClearIcon />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#cccfcd",
            // height: "100vh",
          }}
        >
          <span
            style={{
              background: "#dbdbdb",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div>
           <div style={{display:"flex",justifyContent:"space-between"}}>
              <InputLabel id="demo-simple-select-label">
                Select Learning Outcome
              </InputLabel>
                  </div>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                style={{ width: "100%", background: "#fff" }}
                label="filter"
                onChange={(e) => {
                  console.log(e.target.value, "val");
                  setOutcomeID(e.target.value);
                }}
              >
                {console.log(outcomeList, "outcomeList")}
                {outcomeList && outcomeList.length > 0
                  ? outcomeList.map((res) => {
                      return (
                        <MenuItem value={res.element_id}>
                          {res.learning_element}
                        </MenuItem>
                      );
                    })
                  : // <MenuItem value={0}>NO element</MenuItem>
                    null}
              </Select>
            </div>
            <QuizForm onSubmit={setResult} isFontChange={mcqTitleFont} medium={chapter.medium_name} isCursor={isUrduCursor} />
          </span>
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        disableBackdropClick
        // style={{ height: "70%" }}
        onClose={() => {
          setQuesDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={quesDialog}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#3f50b5",
          }}
        >
          <IconButton onClick={() => setQuesDialog(false)}>
            <ClearIcon />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#cccfcd",
            // height: "100vh",
          }}
        >
          <span
            style={{
              background: "#dbdbdb",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <QuizForm onSubmit={setQuestion} editquestion={quesDialog} />
          </span>
        </div>
      </Dialog>
      <AlertDialog
        open={pdfDialog}
        title="Download?"
        text="Do you want to download this chapter?"
        onCancel={() => setPdfDialog(false)}
        onConfirm={() => {
          downloadChapter();
          setPdfDialog(false);
        }}
      />
      <AlertDialog
        open={deleDialog}
        title="Delete?"
        text="Do you want to delete this Quiz?"
        onCancel={() => setDeleDialog(false)}
        onConfirm={() => {
          deleteMCQ();
          setDeleDialog(false);
        }}
      />
      <AlertDialog
        open={eachMcq}
        title="Delete?"
        text="Do you want to delete this Question?"
        onCancel={() => setEachMcq(false)}
        onConfirm={() => {
          deleteEachMCQ();
          setEachMcq(false);
        }}
      />
      <Snackbar
        autoHideDuration={5000}
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => setMessage("")}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>
      <AlertDialog
        open={showDeleteDialog}
        title="Delete?"
        text="Do you want to delete this chapter?"
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={() => {
          onDeleteChapter();
          // setIsChapterDelete(true)
        }}
      />
      <AlertDialog
        open={showDeletePartDialog}
        title="Delete?"
        text="Do you want to delete this part?"
        onCancel={() => setShowDeletePartDialog(false)}
        onConfirm={() => {
          setisDelete(true);
          setShowDeletePartDialog(false);
          handleDeleteChapterPart(partToBeDelete);
        }}
      />
      <AlertDialog
        open={showDeleteFieldDialog}
        title="Delete?"
        text="Do you want to delete this field?"
        onCancel={() => setShowDeleteFieldDialog(false)}
        onConfirm={() => {
          if (isNewPart) {
            onDeleteContentField(fieldToBeDelete);
          } else {
            onDeleteContentInput(fieldToBeDelete);
          }
        }}
      />
      <Dialog
        open={showPublishConfirm}
        onClose={() => {
          setShowPublishConfirm(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you really want to publish this chapter?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            I agree that content created is truly original and not copied from
            anywhere & given credit to people wherever third party content being
            used.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowPublishConfirm(false);
            }}
            color="primary"
          >
            Disagree
          </Button>
          <Button
            onClick={() => {
              changeStatus(constants.CHAPTER_STATUS.PUBLISHED);
            }}
            color="primary"
            autoFocus
          >
            Agree & Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showCWPublishConfirm}
        onClose={() => {
          setShowCWPublishConfirm(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you really want to publish this chapter?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            I agree that content created is truly original and not copied from
            anywhere & given credit to people wherever third party content being
            used.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowCWPublishConfirm(false);
            }}
            color="primary"
          >
            Disagree
          </Button>
          <Button
            onClick={() => {
              handleChangeStatus(constants.CHAPTER_STATUS.IN_REVIEW);
            }}
            color="primary"
            autoFocus
          >
            Agree & Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <span
            style={{
              background: "white",
              padding: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h4>Duplicate Chapter</h4>
            </div>
            <FormControl className={classes.formControl}>
              <span className={cls.label}>State</span>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={DupState}
                onChange={(e) => {
                  setDupState(e.target.value);
                }}
              >
                {DupStates.length > 0 &&
                  DupStates.map((state) => {
                    return (
                      <MenuItem key={state.state_id} value={state.state_id}>
                        {state.state_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br></br>
            <FormControl className={classes.formControl}>
              <span className={cls.label}>Class</span>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={DupClass}
                onChange={(e) => {
                  setDupClass(e.target.value);
                  getSubjectFromClass(e.target.value, DupMedium);
                }}
              >
                {DupClasses.length > 0 &&
                  DupClasses.map((cls) => {
                    return (
                      <MenuItem key={cls.class_id} value={cls.class_id}>
                        {cls.class_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br></br>
            <FormControl className={classes.formControl}>
              <span className={cls.label}>Medium</span>
              <Select
                placeholder="Medium"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                focused={true}
                value={DupMedium}
                onChange={(e) => {
                  setDupMedium(e.target.value);
                  getSubjectFromClass(DupClass, e.target.value);
                }}
              >
                {DupMediums.map((medium) => {
                  return (
                    <MenuItem value={medium.medium_id} key={medium.medium_id}>
                      {medium.medium_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <br></br>
            <FormControl className={classes.formControl}>
              <span className={cls.label}>Subject</span>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={DupSubject}
                onChange={(e) => {
                  setDupSubject(e.target.value);
                }}
              >
                {DupSubjects.map((sub, index) => {
                  return (
                    <MenuItem value={sub.subject_id} key={index}>
                      {sub.subject_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <br></br>
              <TextField
                id="standard-basic"
                label="Chapter number"
                value={DupChapterNumber}
                type="number"
                onChange={(e) => {
                  setDupChapterNumber(e.target.value);
                }}
              />
              <br></br>
              <TextField
                id="standard-basic"
                label="Chapter name"
                value={DupChapterName}
                onChange={(e) => {
                  setDupChapterName(e.target.value);
                }}
              />
            </FormControl>
            <br></br>
            <div
              style={{
                marginTop: 15,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button onClick={() => handleClose()}>close </Button>
              {!loading ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log("ok");
                    //newDuplicateChapter();
                    partsForDuplicate();
                  }}
                >
                  Confirms{" "}
                </Button>
              ) : (
                <CircularProgress />
              )}
            </div>
          </span>
        </div>
      </Modal>
      {
        <div className={cls.actionHeader}>
          <div className={cls.headerTitle}>Chapter List</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "5px" }}>
              {userChapter && userChapter.meta && (
                <Button
                  className={[
                    cls.reviewCls,
                    setChapterStatusColor(userChapter.meta),
                  ].join(" ")}
                >
                  {userChapter.meta.status}
                </Button>
              )}
            </span>
            <span>
              {!editMode &&
                userChapter.meta &&
                userChapter.meta.status !==
                  constants.CHAPTER_STATUS.PUBLISHED &&
                userChapter.meta &&
                userChapter.meta.status !==
                  constants.CHAPTER_STATUS.IN_REVIEW && (
                  <Button
                    variant="contained"
                    color="primary"
                    // startIcon={<RateReviewIcon />}
                    onClick={() => setShowCWPublishConfirm(true)}
                    style={{
                      marginRight: 10,
                      height: "50px",
                    }}
                  >
                    Submit for Review
                  </Button>
                )}
            </span>
            <div
              style={{
                display: "flex",
                // width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {!changeChapterStatusOn && !editMode ? (
                <>
                  {JSON.parse(localStorage.getItem("user")).user_type ==
                    constants.USER_TYPE.SUPER_ADMIN ||
                  JSON.parse(localStorage.getItem("user")).user_type ==
                    constants.USER_TYPE.ADMIN ? (
                    <Button
                      className={cls.changeStatusBtn}
                      onClick={() => handleChangeChapterStatusOn()}
                    >
                      {`Change Status`}
                    </Button>
                  ) : null}
                </>
              ) : !editMode ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <FormControl
                    className={classes.formControl}
                    style={{
                      width: "100%",
                      marginTop: "-18px",
                      marginRight: "2em",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Set Status
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={chapterStatus}
                      onChange={handleChapterStatusChange}
                    >
                      {/* <MenuItem value={"IN-PROGRESS"}>INPROGRESS</MenuItem> */}
                      <MenuItem value={"IN-REVIEW"}>IN REVIEW</MenuItem>
                      <MenuItem value={"PUBLISHED"}>PUBLISHED</MenuItem>
                      <MenuItem value={"CHANGES-REQUIRED"}>
                        CHANGES REQUIRED
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<CheckIcon />}
                    onClick={() => handleChangeStatus(chapterStatus)}
                    style={{
                      marginRight: 10,
                    }}
                  >
                    Set
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ClearIcon />}
                    onClick={() => cancelChangeStatus()}
                    style={{
                      marginRight: 10,
                      width: "70%",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      }
      {/* <div className={cls.actionHeader}> */}
      {/* <div
          style={{
            display: "flex",
            width:
              !changeChapterStatusOn && !editMode
                ? "371px"
                : !editMode
                ? "674px"
                : "",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        > */}
      {/* {userChapter && userChapter.meta && (
            <Chip
              label={userChapter.meta.status}
              color="primary"
              variant="outlined"
            />
          )} */}

      {/* </div> */}

      {/* ) : userChapter &&
          userChapter.meta &&
          userChapter.meta.status === constants.CHAPTER_STATUS.IN_REVIEW ? (
          <p>Chapter is "IN-REVIEW" status</p>
        ) : null} */}
      {/* </div> */}
      <Card className={[classes.root, cls.courtesyContainer].join(" ")}>
        {/* <CardHeader title="Comments" /> */}
        <CardContent>
          <div className="row">
            <div className="col-6">
              <div className={cls.chapterTitle}>
                {!isEditChapterName ? (
                  <Typography variant="h5" component="h2">
                    {editChapterName.chapter_name
                      ? editChapterName.chapter_name
                          .toString()
                          .charAt(0)
                          .toUpperCase() + editChapterName.chapter_name.slice(1)
                      : ""}
                    {userChapter?.meta?.status === "PUBLISHED" ? (
                      ""
                    ) : (
                      <IconButton
                        aria-label="Edit"
                        onClick={() => {
                          // getAdminList()
                          setChapterName(true);
                        }}
                        title="Edit Chapter name"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <div>
                      {userChapter.meta ? <ReactStars {...ratingStar} /> : ""}
                      {console.log(
                        userChapter.meta ? userChapter.meta.rating : "",
                        "rating"
                      )}
                    </div>
                  </Typography>
                ) : (
                  <>
                    <TextField
                      id="standard-basic"
                      label="Chapter name"
                      value={editChapterName.chapter_name}
                      onChange={(e) => {
                        let chapterName = { ...editChapterName };
                        chapterName.chapter_name = e.target.value;
                        setEditChapterName(chapterName);
                      }}
                    />

                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        onChapterCancel();
                      }}
                      title="Cancel changes"
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      aria-label="save"
                      onClick={() => onUpdateChapterName()}
                      title="Save changes"
                    >
                      <SaveIcon />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            <div className="col-6">
              {/* {userChapter.parts && userChapter.parts[currentTabIndex].mcq.length > 0
              ?null: */}
              {/* <button
                style={{ float: "right" }}
                className="btn btn-secondary"
                onClick={() => 
                  {if(userChapter.parts[currentTabIndex].mcq.length > 0)
                  {
                    setMessage(" Part already have MCQ");
                  }
                  else{
                    setQuizDialog(true)
                  }}
                  
                }
              >
                Add Quiz
              </button>  */}
              {(JSON.parse(localStorage.getItem("user")) &&
                JSON.parse(localStorage.getItem("user")).user_type ===
                  constants.USER_TYPE.SUPER_ADMIN) ||
              JSON.parse(localStorage.getItem("user")).user_type ===
                constants.USER_TYPE.ADMIN ? (
                <div style={{ float: "right" }}>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => setPdfDialog(true)}
                    title="Download chapter"
                  >
                    {loading ? <CircularProgress /> : <CloudDownload />}
                  </IconButton>
                  <IconButton
                    aria-label="Duplicate"
                    onClick={() => handleDuplicateChapter()}
                    title="Duplicate lesson"
                  >
                    <FileCopyIcon />
                  </IconButton>

                  {userChapter && userChapter.meta && !changeChapterStatusOn ? (
                    !editMode ? (
                      <>
                        {console.log(
                          userChapter.meta.status === "PUBLISHED",
                          "user"
                        )}
                        {userChapter.meta.status === "PUBLISHED" ? (
                          ""
                        ) : (
                          <IconButton
                            aria-label="Edit"
                            onClick={() => changeEditMode()}
                            title="Edit lesson"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </>
                    ) : (
                      <>
                        <IconButton
                          aria-label="cancel"
                          onClick={() => cancelEdit()}
                          title="Cancel changes"
                        >
                          <ClearIcon />
                        </IconButton>

                        <IconButton
                          aria-label="save"
                          disabled={
                            uploadProgress.toString().split("#")[0] > 0 &&
                            uploadProgress.toString().split("#")[0] < 100
                          }
                          onClick={() => setOnSaveChapter(true)}
                          title="Save"
                        >
                          <SaveIcon />
                        </IconButton>
                      </>
                    )
                  ) : null}
                  {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).user_type ===
                      constants.USER_TYPE.SUPER_ADMIN && (
                      <IconButton
                        aria-label="cancel"
                        onClick={() => setShowDeleteDialog(true)}
                        title="Delete lesson"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                </div>
              ) : userChapter.meta &&
                userChapter.meta.status !=
                  constants.CHAPTER_STATUS.PUBLISHED ? (
                <div style={{ float: "right" }}>
                  <IconButton
                    aria-label="Duplicate"
                    onClick={() => handleDuplicateChapter()}
                    title="Duplicate lesson"
                  >
                    <FileCopyIcon />
                  </IconButton>

                  {!editMode ? (
                    <IconButton
                      aria-label="Edit"
                      onClick={() => changeEditMode()}
                      title="Edit lesson"
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        aria-label="cancel"
                        onClick={() => cancelEdit()}
                        title="Cancel changes"
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        aria-label="save"
                        disabled={
                          (uploadProgress.toString().split("#")[0] > 0 &&
                            uploadProgress.toString().split("#")[0] < 100) ||
                          checkPartsValidation()
                        }
                        onClick={() => setOnSaveChapter(true)}
                        title="Save"
                      >
                        <SaveIcon />
                      </IconButton>
                    </>
                  )}
                  {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).user_type ===
                      constants.USER_TYPE.SUPER_ADMIN && (
                      <IconButton
                        aria-label="cancel"
                        onClick={() => setShowDeleteDialog(true)}
                        title="Delete lesson"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className={cls.subjectDetail}>
                <Typography
                  className={classes.pos}
                  color="textSecondary"
                  component={"span"}
                >
                  {/* {isEditChapterName && JSON.parse(localStorage.getItem("user")).user_type === constants.USER_TYPE.SUPER_ADMIN ? (
                    <FormControl className={classes.formControl}>
                      <span className={cls.label}>Medium</span>
                      <Select
                        placeholder="Medium"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={editChapterName.medium_id}
                        onChange={(e) => {
                          let chapterName = { ...editChapterName }
                          chapterName.medium_id = e.target.value
                          setEditChapterName(chapterName);
                          getSubjectFromClass(DupClass, e.target.value);
                        }}
                      >
                        {DupMediums.map((medium) => {
                          return (
                            <MenuItem value={medium.medium_id} key={medium.medium_id}>
                              {medium.medium_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : ("")} */}
                  {/* {isEditChapterName && JSON.parse(localStorage.getItem("user")).user_type === constants.USER_TYPE.SUPER_ADMIN ? (
                    <FormControl className={classes.formControl} style={{ marginLeft: "10px" }}>
                      <span className={cls.label}>Class</span>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={editChapterName.class_id}
                        onChange={(e) => {
                          let chapterName = { ...editChapterName }
                          chapterName.class_id = e.target.value
                          DupClasses.map(cls => {
                            if (cls.class_id == e.target.value) {
                              chapterName.class_name = cls.class_name
                            }
                          })
                          setEditChapterName(chapterName);
                          getSubjectFromClass(e.target.value, DupMedium);
                        }}
                      >
                        {DupClasses.length > 0 &&
                          DupClasses.map((cls) => {
                            return (
                              <MenuItem key={cls.class_id} value={cls.class_id}>
                                {cls.class_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  ) : (<>
                    {editChapterName.class_name} </>)} */}
                  {editChapterName.class_name}
                  {/* {isEditChapterName && JSON.parse(localStorage.getItem("user")).user_type === constants.USER_TYPE.SUPER_ADMIN ? (
                    <FormControl className={classes.formControl} style={{ paddingLeft: "20px" }}>
                      <span className={cls.label}>Subject</span>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={editSubject}
                        onChange={(e) => {
                          setEditSubject(e.target.value);
                        }}
                      >
                        {DupSubjects.map((sub, index) => {
                          return (
                            <MenuItem value={sub.subject_id} key={index}>
                              {sub.subject_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (<> ({`${chapter.subject_name}`.toString().charAt(0).toUpperCase() +
                    `${chapter.subject_name}`.slice(1)}) </>)
                  } */}
                  ({" "}
                  {`${chapter.subject_name}`
                    .toString()
                    .charAt(0)
                    .toUpperCase() + `${chapter.subject_name}`.slice(1)}{" "}
                  )
                  {!isEditChapterName ? (
                    <> &nbsp;Chapter No: {editChapterName.chapter_no}</>
                  ) : (
                    <FormControl
                      className={classes.formControl}
                      style={{ paddingLeft: "20px" }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Chapter no."
                        type="Number"
                        value={editChapterName.chapter_no}
                        onChange={(e) => {
                          let chapterName = { ...editChapterName };
                          chapterName.chapter_no = +e.target.value;
                          setEditChapterName(chapterName);
                        }}
                      />
                    </FormControl>
                  )}
                </Typography>
              </div>
              <div className={cls.chapterDetail}>
                <span className={cls.detailTitle}>Medium : </span>
                <span>{chapter.medium_name} &nbsp;|</span>
                <span className={cls.detailTitle}>Board : </span>
                <span>{chapter.board_name} &nbsp;|</span>
                <span className={cls.detailTitle}>Syllabus :</span>
                <span> {chapter.district_name} &nbsp;|</span>
                <span className={cls.detailTitle}>Created by : </span>
                <span>
                  {isEditChapterName &&
                  JSON.parse(localStorage.getItem("user")).user_type ===
                    constants.USER_TYPE.SUPER_ADMIN ? (
                    <FormControl style={classes.formControl}>
                      <Autocomplete
                        id="asynchronous-demo"
                        style={{ width: 300 }}
                        open={open}
                        size="small"
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        onChange={(option, user) => {
                          setEditChapterName({
                            ...editChapterName,
                            user_id: user.user_id,
                            userFullName: user.full_name,
                          });
                        }}
                        getOptionLabel={(option) => option.full_name}
                        options={options}
                        loading={loading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={userChapter.meta.createdBy}
                            style={{ marginTop: -12 }}
                            onChange={(ev) => {
                              if (
                                ev.target.value !== "" ||
                                ev.target.value !== null
                              ) {
                                onChangeHandle(ev.target.value);
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  ) : (
                    <>
                      {/* {userChapter &&
                          userChapter.meta &&
                          userChapter.meta.createdBy} */}
                      <Button
                        data-tip
                        data-for="registerTip"
                        style={{ background: "none", border: "none" }}
                        onMouseDown={() => {
                          setShowPublisher(false);
                        }}
                        onMouseOver={() => {
                          setOpenUser(false);
                          if (toggle) {
                            setShowPublisher(true);
                          } else {
                            createrDetails();
                          }
                        }}
                      >
                        {" "}
                        {userChapter &&
                          userChapter.meta &&
                          userChapter.meta.createdBy}{" "}
                      </Button>
                      {showPublisher == true ? (
                        <ReactTooltip
                          id="registerTip"
                          place="right"
                          effect="solid"
                          backgroundColor="#2c9ae8"
                        >
                          <div
                            style={{
                              fontSize: "20px",
                              fontFamily: "sans-serif",
                              marginLeft: "7px",
                            }}
                          >
                            Name : {profileInfo.full_name}
                          </div>
                          <div
                            style={{
                              fontSize: "20px",
                              fontFamily: "sans-serif",
                              marginLeft: "7px",
                            }}
                          >
                            Email :{profileInfo.email}
                          </div>
                          <div
                            style={{
                              fontSize: "20px",
                              fontFamily: "sans-serif",
                              marginLeft: "7px",
                            }}
                          >
                            Contact No : {profileInfo.mobile}
                          </div>
                        </ReactTooltip>
                      ) : null}
                    </>
                  )}
                </span>
                {chapter.status_change_by !== "" ? (
                  <>
                    <span className={cls.detailTitle}> Published by : </span>

                    <Button
                      data-tip
                      data-for="registerTip"
                      style={{ background: "none", border: "none" }}
                      onMouseDown={() => {
                        setOpenUser(false);
                      }}
                      onMouseOver={() => {
                        setShowPublisher(false);
                        //userDetails()
                        if (toggle1) {
                          setOpenUser(true);
                        } else {
                          userDetails();
                        }
                      }}
                    >
                      {" "}
                      {chapter.status_change_by} &nbsp;|{" "}
                    </Button>
                    {openUser == true ? (
                      <ReactTooltip
                        id="registerTip"
                        place="right"
                        effect="solid"
                        backgroundColor="#2c9ae8"
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Name : {createrprofileInfo.full_name}
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Email :{createrprofileInfo.email}
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontFamily: "sans-serif",
                            marginLeft: "7px",
                          }}
                        >
                          Contact No : {createrprofileInfo.mobile}
                        </div>
                      </ReactTooltip>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </CardContent>
        {/* </CardHeader> */}
        {/* </Card> */}
        <hr></hr>

        {/* <AppBar position="static" className={cls.tabBar}> */}
        <Paper>
          {userChapter.parts && userChapter.parts.length ? (
            <Tabs
              value={currentTabIndex}
              onChange={handleChange}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
            >
              {userChapter.parts &&
                userChapter.parts.length &&
                userChapter.parts.map((part, index) => {
                  if (part.hasOwnProperty("part_id")) {
                    return (
                      <Tab
                        key={index}
                        label={`Part ${index + 1}`}
                        {...a11yProps(index)}
                      />
                    );
                  } else {
                    return (
                      <Tab
                        key={index}
                        icon={<AddIcon />}
                        aria-label="AddIcon"
                      />
                    );
                  }
                })}
            </Tabs>
          ) : (
            <CircularProgress style={{ marginLeft: "40em" }} />
          )}
        </Paper>
        {/* </AppBar> */}

        <Modal
          open={editing}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <span
              style={{
                background: "white",
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
              className={cls.modalGridOuter}
            >
              <div style={{ textAlign: "center" }}>
                <h4>Update Part and Tag</h4>
              </div>
              <div>
                
                <GridItem
                  xs={8}
                  sm={8}
                  md={12}
                  style={{
                    background: "white",
                    border: "1px dashed black",
                    borderRadius: "4px",
                    margin: "auto",
                  }}
                >
                  {/* <TextField
                    style={{
                      width: "100%",
                      marginTop: "20px",
                    }}
                    formControlProps={{
                      fullWidth: true,
                      multiline: true
                    }}
                    id="Part name"
                    label="Part name"
                    multiline
                    value={partAndTagData.partName}
                    onChange={(e) =>onChangeChapterDetail(e.target.value, "partName")}
                   
                  /> */}
                  {/* {chapter.medium_name === "Urdu Medium" &&
                  <div style={{display:"flex", justifyContent:"end"}}>
                  <FormControlLabel control={<Checkbox color="default"  name='Jameel Noori Nastaleeq' onChange={handleFontChange}
            />} label="Jameel Noori Nastaleeq" />
                  </div>
                  } */}
                 
                  <CustomInput
                    labelText="Part name"
                    id="Part name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    val={partAndTagData.partName}
                    change={(e) => {
                      onChangeChapterDetail(e.target.value, "partName");
                    }}
                    Focus={(e) => {
                      partBorder();
                    }}
                    Blur={(e) => {
                      console.log(e, "e from onblur ---");
                    }}
                    urdufont={urdufont}
                  />
                  <FormHelperText
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    ( या भागामध्ये आलेल्या महत्वाच्या संकल्पनेचे नाव अथवा भागाचे
                    समर्पक शीर्षक लिहा. ){" "}
                  </FormHelperText>
                </GridItem>

                <GridItem
                  xs={8}
                  sm={8}
                  md={12}
                  style={{
                    background: "white",
                    border: "1px dashed black",
                    borderRadius: "4px",
                    margin: "auto",
                    marginTop: "15px",
                  }}
                >
                  {/* <CustomInput
                    labelText="Tags"
                    id="Tag"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    val={partAndTagData.tagName}
                    change={(e) => {
                      onChangeChapterDetail(e.target.value, "tagName");
                    }}
                    Focus={(e)=>{
                      partBorder()
                      }}
                  /> */}
                  <ChipInput
                    // InputProps={{
                    //   maxLength: 2
                    // }}
                    defaultValue={chip}
                    onChange={(chips) =>
                      onChangeChapterDetail(chips, "tagName")
                    }
                  />
                  <FormHelperText
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    ( या भागामध्ये आलेल्या महत्वाच्या संकल्पना, शब्द इत्यादीची
                    नावे लिहा. या Tag मधील शब्दांना अनुसरून विद्यार्थी माहिती
                    शोधतील. )
                  </FormHelperText>
                </GridItem>
              </div>
              <hr />
              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button onClick={() => handleCloseEdit()}>close </Button>
                {!loading ? (
                  <Button
                    disabled={chipArrayLength < 5 ? false : true}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      onUpdatePartAndTagData();
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </span>
          </div>
        </Modal>

        {!editMode ? (
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              {userChapter.parts &&
                userChapter.parts.length &&
                userChapter.parts.map((p, index) => {
                  return (
                    <TabPanel key={index} value={currentTabIndex} index={index}>
                      {/* <Card className={classes.root}> */}
                      <IconButton
                        aria-label="Edit"
                        onClick={() => openDialogToEdit(p)}
                        title="Edit Part & Tag name"
                        className={cls.editPartBtn}
                      >
                        <EditIcon />
                      </IconButton>
                      {JSON.parse(localStorage.getItem("user")) && (
                        //  &&
                        //   JSON.parse(localStorage.getItem("user")).user_type ===
                        //     constants.USER_TYPE.SUPER_ADMIN
                        <Button
                          title="quiz"
                          variant="contained"
                          color="primary"
                          style={{ float: "right", marginTop: "2%" }}
                          className="btn btn-secondary"
                          onClick={() => {
                            if (
                              userChapter.parts[currentTabIndex].mcq.length > 0
                            ) {
                              setQuizShow(true);
                              mcqRender();
                              setindexQuestion(0)
                              setQuizStore({});
                            } else {
                              getLearningElement();
                              setQuizDialog(true);
                            }
                          }}
                        >
                          {userChapter.parts[currentTabIndex].mcq.length > 0
                            ? "Show Mcq"
                            : "Add MCQ"}
                        </Button>
                      )}

                      <CardContent>
                        <div
                          style={{
                            width: "519px",
                            background: "#fff",
                            // p.content && p.content.length > 0
                            //   ? p.content[0].color
                            //   : "#f9f7f7",
                            border: "0.5px dashed #eeeeee",
                            padding: 15,
                          }}
                          className={cls.cardContent}
                        >
                          {p.hasOwnProperty("part_id") && (
                            <>
                              <Card>
                                <div
                                  style={{
                                    borderBottom: " 1px solid black ",
                                    width: "300%",
                                    padding: "15px",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    component="h4"
                                    style={{ fontFamily: chapter.medium_name === "Urdu Medium" ?"Jameel Noori Nastaleeq" :"" }}
                                  >
                                    Part Title : {p.part_name}
                                  </Typography>
                                  <div style={{ fontFamily: chapter.medium_name === "Urdu Medium" ?"Jameel Noori Nastaleeq" :"" }}>
                                    Tags :
                                    {chipArray.map((chip, i) => {
                                      return <Chip label={chip} key={i} />;
                                    })}
                                  </div>
                                </div>
                              </Card>
                            </>
                          )}

                          {p.content &&
                            p.content.length > 0 &&
                            p.content.map((c, index) => {
                              return (
                                <div key={index} className={cls.tableStyle}>
                                  <Helmet>
                                    <script
                                      src="https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image"
                                      defer="false"
                                    ></script>
                                  </Helmet>
                                  {(c.type === "IMG" || c.type == "GIF") && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <span>
                                        <img
                                          src={
                                            c.value &&
                                            c.value.hasOwnProperty("filePath")
                                              ? c.value.filePath
                                              : c.value
                                          }
                                          style={{ width: "100%" }}
                                        />
                                        <br></br>
                                        <br></br>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: c?.value?.description,
                                          }}
                                        ></div>
                                      </span>
                                    </Card>
                                  )}
                                  {c.type == "json" && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <span>
                                        {console.log(c.value.filePath, "lotti")}
                                        <Player
                                          autoplay
                                          loop
                                          src={
                                            c.value &&
                                            c.value.hasOwnProperty("filePath")
                                              ? c.value.filePath
                                              : c.value
                                          }
                                          style={{
                                            height: "300px",
                                            width: "300px",
                                          }}
                                        >
                                          <Controls
                                            visible={true}
                                            buttons={[
                                              "play",
                                              "repeat",
                                              "frame",
                                              "debug",
                                            ]}
                                          />
                                        </Player>

                                        <br></br>
                                        <br></br>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: c?.value?.description,
                                          }}
                                        ></div>
                                      </span>
                                    </Card>
                                  )}
                                  {c.type === "TEXT" && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: c.value,
                                        }}
                                      ></div>
                                    </Card>
                                  )}
                                  {c.type == "SPECIAL_TEXT" && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={specialImg}
                                          style={{
                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{ marginLeft: "10%" }}
                                        dangerouslySetInnerHTML={{
                                          __html: c.value,
                                        }}
                                      ></div>
                                    </Card>
                                  )}
                                  {c.type === "VIDEO" && (
                                    <>
                                      <Card
                                        style={{
                                          boxShadow: " 0 5px 5px 0 #8a8a8a",
                                        }}
                                      >
                                        <iframe
                                          width="100%"
                                          height="480"
                                          src={`https://www.youtube.com/embed/${getYTVideoID(
                                            c.value
                                          )}`}
                                        ></iframe>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: c?.value?.description,
                                          }}
                                        ></div>
                                      </Card>
                                    </>
                                  )}
                                  {c.type === "PDF" && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <iframe
                                        width="100%"
                                        height="480"
                                        src={c.value}
                                      ></iframe>
                                    </Card>
                                  )}
                                  {c.type === "PPT" && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <iframe
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${c.value}`}
                                        style={{ width: "100%", height: 500 }}
                                        frameBorder="0"
                                      ></iframe>
                                    </Card>
                                  )}
                                  {c.type === "AUDIO" && (
                                    <>
                                      <Card
                                        style={{
                                          boxShadow: " 0 5px 5px 0 #8a8a8a",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <audio
                                            controls
                                            style={{ width: "100%" }}
                                          >
                                            <source
                                              src={c.value}
                                              type="audio/mpeg"
                                            />
                                          </audio>
                                          <a
                                            href={c.value}
                                            download="audiofile"
                                          >
                                            <GetAppIcon
                                              style={{
                                                marginTop: "15px",
                                                color: "black",
                                              }}
                                            />
                                          </a>
                                        </div>
                                      </Card>
                                    </>
                                  )}
                                  {c.type === "gForm" && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <iframe
                                        src={c.value}
                                        width="100%"
                                        height="1923"
                                        frameBorder="0"
                                        marginHeight="0"
                                        marginWidth="0"
                                      >
                                        Loading…
                                      </iframe>
                                    </Card>
                                  )}
                                  {c.type === "Link" && (
                                    <Card
                                      style={{
                                        boxShadow: " 0 5px 5px 0 #8a8a8a",
                                      }}
                                    >
                                      <Button
                                        href={c.value.value}
                                        target="_blank"
                                        color="primary"
                                      >
                                        {c.value.name}
                                      </Button>
                                    </Card>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </CardContent>
                      {/* </Card> */}
                    </TabPanel>
                  );
                })}
            </div>
          </div>
        ) : (
          <>
            {/* <ContentToolbar
                addData={(e, type) => addData(e, type)}
                className={cls.toolbarWrapper}
              /> */}
            {userChapter.parts &&
              userChapter.parts.length &&
              userChapter.parts.map((p, index) => {
                return (
                  <TabPanel
                    key={index}
                    value={currentTabIndex}
                    index={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {/* <Card
                    className={classes.root}
                    style={{
                      background: "aliceblue",
                      padding: "16px",
                    }}
                  > */}
                    {p.hasOwnProperty("part_id") ? (
                      <div className={cls.saveContainer}>
                        <CardBody>
                          {JSON.parse(localStorage.getItem("user")) &&
                            JSON.parse(localStorage.getItem("user"))
                              .user_type ===
                              constants.USER_TYPE.SUPER_ADMIN && (
                              <div className={cls.deletePartContainer}>
                                <div>
                                  <span>Set Background Color: &nbsp;</span>
                                  <input
                                    type="color"
                                    value={
                                      p.content &&
                                      p.content.length > 0 &&
                                      p.content[0].color
                                        ? p.content[0].color
                                        : "#f0f0f0"
                                    }
                                    onChange={(e) => onColorChange(e, index)}
                                  />
                                </div>
                              </div>
                            )}
                          {renderContentInputs(p)}
                        </CardBody>
                        <div style={{ marginTop: "10px", paddingLeft: "30px" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setShowDeletePartDialog(true);
                              setPartToBeDelete(p);
                            }}
                            className={[classes.button, cls.deletePartBtn].join(
                              " "
                            )}
                            startIcon={<DeleteIcon />}
                          >
                            Delete Part
                          </Button>
                          <span></span>
                          <Button
                            variant="contained"
                            color="primary"
                            className={cls.saveBtn}
                            disabled={
                              uploadProgress.toString().split("#")[0] > 0 &&
                              uploadProgress.toString().split("#")[0] < 100
                            }
                            onClick={() => setOnSaveChapter(true)}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {/* <div>
                              <span>Set Background Color: &nbsp;</span>
                              <input
                                type="color"
                                value={partColor}
                                onChange={(e) => setPartColor(e.target.value)}
                              />
                            </div>
                            <br></br> */}
                        <div className="row" style={{ paddingLeft: "30px" }}>
                          <div className="col-11">
                            <GridItem
                              xs={8}
                              sm={8}
                              md={12}
                              style={{
                                background: "white",
                                border: "1px dashed black",
                                borderRadius: "4px",
                                marginRight: "30px",
                              }}
                            >
                              <CustomInput
                                labelText="Part name"
                                id="Part name"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                val={partName}
                                change={(e) => {
                                  setPartName(e.target.value);
                                }}
                                Focus={(e) => {
                                  partBorder();
                                }}
                                Blur={(e) => {
                                  console.log(e, "e from onblur ---");
                                }}
                              />
                              <FormHelperText>
                                ( या भागामध्ये आलेल्या महत्वाच्या संकल्पनेचे नाव
                                अथवा भागाचे समर्पक शीर्षक लिहा. ){" "}
                              </FormHelperText>
                            </GridItem>
                          </div>
                          <div
                            className="col-1"
                            style={{ alignSelf: "center" }}
                            size="small"
                          >
                            <IconButton title="">
                              <HelpOutlineIcon />
                            </IconButton>
                          </div>
                        </div>

                        <div className="row" style={{ paddingLeft: "30px" }}>
                          <div className="col-11">
                            <GridItem
                              xs={8}
                              sm={8}
                              md={12}
                              style={{
                                background: "white",
                                border: "1px dashed black",
                                borderRadius: "4px",
                                marginTop: "15px",
                                marginRight: "30px",
                              }}
                            >
                              <CustomInput
                                labelText="Tags"
                                id="Tag"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                val={tagName}
                                change={(e) => {
                                  setTagName(e.target.value);
                                }}
                                Focus={(e) => {
                                  partBorder();
                                }}
                                Blur={(e) => {
                                  console.log(e, "e from onblur ---");
                                }}
                              />
                              {/* <ChipInput
                                 fullWidthInput
                                 value={chip}
                                      onChange={(chips) => onChangeChapterDetail(chips,"tagName")}
                                        /> */}
                              <FormHelperText>
                                ( या भागामध्ये आलेल्या महत्वाच्या संकल्पना, शब्द
                                इत्यादीची नावे लिहा. या Tag मधील शब्दांना
                                अनुसरून विद्यार्थी माहिती शोधतील. )
                              </FormHelperText>
                            </GridItem>
                          </div>
                          <div
                            className="col-1"
                            style={{ alignSelf: "center" }}
                            size="small"
                          >
                            <IconButton title="">
                              <HelpOutlineIcon />
                            </IconButton>
                          </div>
                        </div>
                        <br />
                        <hr />
                        <GridContainer style={{ paddingLeft: "20px" }}>
                          {renderContentFields()}
                        </GridContainer>
                        {!loading ? (
                          <Button
                            style={{
                              margin: "10px",
                            }}
                            color="primary"
                            variant="contained"
                            onClick={() => setOnSaveChapter(true)}
                            disabled={checkPartsValidation()}
                          >
                            Save
                          </Button>
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    )}
                    {/* </Card> */}
                  </TabPanel>
                );
              })}
            {/* TOOLBAR WAS HERE */}
          </>
        )}
        <Modal
          open={gadgetModalOpen}
          onClose={() => {
            setAddWidgetModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <span
              style={{
                background: "white",
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <GadgetPanel
                onCancelClick={() => {
                  setAddWidgetModal(false);
                }}
                onAddData={(e, type) => {
                  setAddWidgetModal(false);
                  addData(e, type);
                }}
              />
            </span>
          </div>
        </Modal>

        <Dialog
          open={showSaveChapterDialog}
          onClose={() => {
            setOnSaveChapter(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Save chapter?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to save this chapter.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loadingUpdatePart ? true : false}
              onClick={() => {
                setOnSaveChapter(false);
                setLoadingUpdatePart(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            {!loadingUpdatePart ? (
              <Button
                onClick={() => {
                  saveChanges();
                }}
                color="primary"
                autoFocus
              >
                Save
              </Button>
            ) : (
              <CircularProgress />
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="md"
          open={quizShow}
          onClose={() => {
            setQuizShow(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              borderTop: "1px solid black",
              backgroundColor: "#e3e6e4",
            }}
          >
            {quizStore && Object.keys(quizStore).length ? (
              <Card
                style={{
                  marginTop: "3%",
                  width: "90%",
                  justifyContent: "center",
                  marginLeft: "5%",
                  height: "25%",
                  color: "#fff",
                  backgroundColor: "#3f50b5",
                }}
              >
                <h1 style={{ display: "flex", justifyContent: "center" }}>
                  {editMCQ ? <TextField
                      //id="standard-basic"
                      variant="outlined"
                      id="outlined-basic"
                      //label={res.email}
                      defaultValue={quizStore.quizTitle}
                      type="text"
                      onChange={(e) => {
                        seteditTitle(e.target.value);
                      }}
                    /> : quizStore.quizTitle }
                </h1>
                <div style={{ padding: "5px" }}>
                  Total question :{quizStore.questions.length} <br />

                  Synopsis :  {editMCQ ? <TextField
                      //id="standard-basic"
                      variant="outlined"
                      id="outlined-basic"
                      //label={res.email}
                      defaultValue={quizStore.quizSynopsis}
                      type="text"
                      onChange={(e) => {
                        seteditsynopsis(e.target.value);
                      }}
                    /> : quizStore.quizSynopsis }
                    
                     <br />
                  Total Marks : {totalPoint} <br />
                  Total Time : {(totalTime / 60).toFixed(2)} min <br />
                  learning element :{" "}
                  { editMCQ? 
                  (
                    <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                style={{ width: "40%", background: "#fff" }}
                label="filter"
                // defaultValue={getLEValue()}
                onChange={(e) => {
                  console.log(e.target.value, "val");
                  setOutcomeID(e.target.value);
                }}
              >
                {console.log(outcomeList, "outcomeList", userChapter.parts[currentTabIndex].mcq[0])}
                {outcomeList && outcomeList.length > 0
                  ? outcomeList.map((res) => {
                      return (
                        <MenuItem value={res.element_id}>
                          {res.learning_element}
                        </MenuItem>
                      );
                    })
                  : // <MenuItem value={0}>NO element</MenuItem>
                    null}
              </Select>
                  ): (userChapter.parts[currentTabIndex].mcq[0]
                    ? userChapter.parts[currentTabIndex].mcq[0]
                        .learning_element__learning_element
                    : null) }
                  <IconButton
                    style={{ marginLeft: "80%", color: "white" }}
                    aria-label="Edit"
                    //  variant="contained"
                    //  color="primary"

                    onClick={() => setDeleDialog(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                {console.log(quizStore, "quizStore")}
              </Card>
            ) : null}
            <div>
  <Card  style={{
              width: "90%",
              justifyContent: "flex-start",
              marginLeft: "5%",
              marginTop: "1%",
              display:'flex',
              padding:'10px'
            }}>
     {quizStore?.questions?.map((red,index)=>{
      return(
        <div 
        style={{ width:'30px',
        height:'30px',
        marginLeft:'15px',
        borderRadius:'15px',
        backgroundColor:indexQuestion===index?'#3F50B5':'grey',
        color:'#fff',
      display:'flex',
    justifyContent:'center',
    cursor:'pointer',
  alignItems:'center'}}
        onClick={()=>{
          setindexQuestion(index)
        }}>{index+1}</div>
      )
     })} 
     </Card>
    </div>
            <div>{renderMCQ(quizStore)}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {quizStore && Object.keys(quizStore).length ? (
                <>
                  {" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setQuesDialog(true)}
                  >
                    Add question
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </Dialog>
        <hr />
        {userChapter && userChapter.credits && (
          <CreditCourtesy credits={userChapter.credits}></CreditCourtesy>
        )}
        {/* </Card> */}
        <hr />
        {/* {quizShow ? (
          <div
            style={{
              borderTop: "1px solid black",
              backgroundColor: "#e3e6e4",
            }}
          >
            {quizStore && Object.keys(quizStore).length ? (
              <Card
                style={{
                  marginTop: "3%",
                  width: "90%",
                  justifyContent: "center",
                  marginLeft: "5%",
                  height: "25%",
                  color: "#fff",
                  backgroundColor: "#3f50b5",
                }}
              >
                <h1 style={{ display: "flex", justifyContent: "center" }}>
                  {quizStore.quizTitle}
                </h1>
                <div style={{ padding: "5px" }}>
                  Total question :{quizStore.questions.length}{" "}
                  <IconButton
            style={{ marginLeft:"80%",
          color:"white"}}
            aria-label="Edit"
          //  variant="contained"
          //  color="primary"
           
            onClick={() => setDeleDialog(true)}
            >
              <DeleteIcon/>
            </IconButton>
                </div>
                {console.log(quizStore, "quizStore")}
              </Card>
            ) : null}
            <div>{renderMCQ(quizStore)}</div>
            <div
            style={{
              display:"flex",
              justifyContent:"center",
              marginTop:"10px",
              marginBottom:"10px"
            }}>
            {quizStore && Object.keys(quizStore).length ?
            <> <Button
           variant="contained"
           color="primary"
           
            onClick={() => setQuesDialog(true)}
            >Add question</Button>
            </> : null}
            </div>
          </div>
        ) : (
          ""
        )} */}

        <hr></hr>
        {
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "10px",
              marginBottom: "35px",
            }}
          >
            {/* <Button
            style={{
              color:"white",
              background:"#3f50b5",
              marginRight:"5px"
            }}
            onClick={()=>{
              setDiscussion(!discussion)
            }}>Discussion Room</Button> */}

            {/* <FormControlLabel
              control={
                <Switch
                  checked={quizShow}
                  color="primary"
                  onChange={() => {
                    setQuizShow(!quizShow);
                    mcqRender();
                    setQuizStore({});
                  }}
                  name="Quiz"
                />
              }
              label="Quiz"
            /> */}
            <FormControlLabel
              control={
                <Switch
                  checked={discussion}
                  color="primary"
                  onChange={() => setDiscussion(!discussion)}
                  name="Discussion Room"
                />
              }
              label="Discussion Room"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={reviewChapter}
                  color="primary"
                  onChange={() => {
                    setReview(!reviewChapter);
                    noOfRatings();
                  }}
                  name="Reviews of chapter"
                />
              }
              label="Reviews of chapter"
            />
            {/* <Button 
            style={{
              color:"white",
              background:"#3f50b5",
              
            }}
            onClick={()=>{
              setReview(!reviewChapter)
            }}>Reviews of chapter</Button> */}
          </div>
        }
        {/* <div style={{ overflow: "auto", position: "relative", top: "330px" }}>
        <Card className={classes.root} variant="outlined"> */}
        {discussion ? (
          <div>
            <CardHeader title="Comments" />
            <CardContent>
              <div className="row">
                <div className="col-12">
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Add a comment"
                    multiline
                    rows={8}
                    variant="outlined"
                    value={commentAdded}
                    onChange={(event) => onChangeComment(event.target.value)}
                  />
                </div>
                <div className="col-12">
                  <Button
                    onClick={() => onPostComment()}
                    color="primary"
                    variant="contained"
                    style={{ marginTop: 5 }}
                    disabled={commentAdded.trim() == ""}
                  >
                    Post Comment
                  </Button>
                </div>
              </div>
            </CardContent>
          </div>
        ) : null}
        {chapterComments.chapterDetails &&
        chapterComments.total_count &&
        chapterComments.chapterDetails.length &&
        discussion ? (
          <ChapterComments
            comments={chapterComments}
            onPageChange={(event, recordPerPage) =>
              setPageDetails(
                event.selected,
                recordPerPage,
                chapterComments.total_count
              )
            }
          ></ChapterComments>
        ) : (
          ""
        )}
        {userChapter.meta && reviewChapter ? (
          <div>
            <div
              style={{
                borderTop: "1px solid grey",
                display: "flex",
                padding: "16px",
                alignItems: "center",
              }}
            >
              Review and Rating
            </div>
            {console.log(userChapter.meta, "rating reiew")}
            <div style={{ marginTop: "2%", marginLeft: "3%" }}>
              <div style={{ fontSize: "64px" }}>
                {userChapter.meta ? userChapter.meta.rating : null}
              </div>
              <br />
              <div>
                {userChapter.meta ? <ReactStars {...ratingStar} /> : ""}
              </div>
              <span>
                <Group />
                {userChapter.meta
                  ? userChapter.meta.rating_total_user
                  : null}{" "}
              </span>
              <br />
            </div>
            <br />
            <span>
              <HighchartsReact highcharts={Highcharts} options={ratingGraph} />
            </span>
            <br />
            {userChapter.meta.reviews.map((data, index) => {
              return (
                <div key={index}>
                  <Card
                    className={classes.root}
                    variant="outlined"
                    style={{ marginLeft: "3px", width: "95%" }}
                  >
                    <CardHeader
                      title={`${data.first_name}`}
                      subheader={
                        <ReactStars
                          {...{
                            size: 20,
                            value: data ? data.rating : "",
                            edit: false,
                          }}
                        />
                      }
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          {getFirstLetter(data.first_name)}
                        </Avatar>
                      }
                    />
                    <CardContent>
                      {data.review ? data.review : "no review"}
                    </CardContent>
                  </Card>
                  <br />
                </div>
                // <div>
                // <h3>
                //   {data.first_name}
                // </h3>
                // <h3>
                //   {data.review}
                // </h3>
                // </div>)
              );
            })}
          </div>
        ) : (
          ""
        )}

        {/* </Card>
      </div> */}
      </Card>
    </div>
  );
};

export default ChapterPreview;