import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
// import Navbar from "components/Navbars/Navbar.js";
import Navbar from "components/Navbar/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { dashboardRoutes } from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/Background.png";
import logo from "assets/img/vopa_logo_white.png";
import ChapterParts from "views/CreateChapter/createChapterPart";
import UserDetail from "views/UserProfile/UserDetail";
import ChapterPreview from "views/ChapterPreview/ChapterPreview";
let ps;

const switchRoutes = ({ ...rest }) => (
  <Switch>
    {dashboardRoutes().map((prop, key) => {
      if (prop.layout === "/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            {...rest}
          />
        );
      }
      return null;
    })}
    <Route
      path={"/user/partofchapter"}
      component={ChapterParts}
      // {...rest}
    />
    <Route
      path={"/user/userdetail"}
      component={UserDetail}
      // {...rest}
    />
    <Route path={"/user/chapter/preview/:id"} component={ChapterPreview} />
    {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
  </Switch>
);

const useStyles = makeStyles(styles);

export default function ContentWriter({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/user/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      localStorage.clear();
      window.location.href = window.location.origin;
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardRoutes()}
        logoText={"VOPA"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      {/* <Navbar /> */}
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* <Navbar
      <div className={classes.mainPanel} ref={mainPanel} id="mainPanel">
        <Navbar
          routes={dashboardRoutes()}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        /> */}
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes(rest)}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes(rest)}</div>
        )}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
