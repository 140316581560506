"use client";
import React, { useEffect, useState } from 'react';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js"
import Button from "components/CustomButtons/Button.js";
import { getSuperAdminService } from 'service/service';
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox, FormHelperText, Input, ListItemText, TextField } from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import { getContentWriterService } from 'service/service';
import httpV2 from 'common/axiosV2';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Snackbar from "components/Snackbar/Snackbar";
import { CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MyLottieComponent from "../components/lottieShow"
import DateTimeComponent from "../components/DateTimeComponent";
import http from 'common/axios';


function EditChallenge() {
  const [challengeTitle, setChallengeTitle] = useState('')
  //filter{}
  const [state_id, setState_id] = useState('');
  const [stateList, setStateList] = useState([]);
  const [district_id, setDistrict_id] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [student_block_id, setStudent_block_id] = useState('');
  const [blockList, setBlockList] = useState([]);
  const [syllabus_id, setSyllabus_id] = useState(3);
  // const [syllabus_id, setSyllabus_id] = useState('');
  const [syllabusList, setSyllabusList] = useState([]);
  const [medium_id, setMedium_id] = useState('');
  const [mediumList, setMediumList] = useState([]);
  const [class_id, setClass_id] = useState('');
  const [classList, setClassList] = useState([]);
  const [specialClass_id, setSpecialClass_id] = useState('');
  const [specialClassList, setSpecialClassList] = useState([]);
  //Advance filter
  const [user_type, setUser_type] = useState('');
  const [advanceFilterList, setAdvanceFilterList] = useState([]);
  const [timeframe, setTimeframe] = useState('');
  const [advanceFilterTimeframeList, setAdvanceFilterTimeframeList] = useState([]);
  const [timeframeValue, setTimeframeValue] = useState('');
  const [timeframeValueList, setTimeframeValueList] = useState([]);
  const [advanceFilterPoints, setAdvanceFilterPoints] = useState('');
  const [advanceFilterComparison, setAdvanceFilterComparison] = useState();
  const [advanceFilterComparisonList, setAdvanceFilterComparisonList] = useState([]);
  // school
  const [schoolSearchTxt, setSchoolSearchTxt] = useState('');
  const [schoolPageNo, setSchoolPageNo] = useState(1);
  const [selectedSchools, setSelectedSchools] = useState([]);//school id list
  const [selectedSchoolsObject, setSelectedSchoolsObject] = useState([]);//school id and name object
  const [schoolList, setSchoolList] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //reward
  const [rewardPoints, setRewardPoints] = useState('');
  const [rewardDiamonds, setRewardDiamonds] = useState('');
  const [rewardDescription, setRewardDescription] = useState('');
  //challenge info{} 
  // fix
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const [challengeList, setChallengeList] = useState([]);
  const [selectedSubChallenge, setSelectedSubChallenge] = useState('');
  const [subChallengeList, setSubChallengeList] = useState([]);

  const [subject_id, setSubject_id] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [chapter_id, setChapter_id] = useState('');
  const [chapterList, setChapterList] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState('')
  const [selectedTimeframe, setSelectedTimeframe] = useState('')
  const [components, setComponents] = useState([])
  const [AIGenerated, setAIGenerated] = useState('')
  const [comparisonForChallenge, setComparisonForChallenge] = useState('');
  const [comparisonForChallengeList, setComparisonForChallengeList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [unitList, setUnitList] = useState([]);
  const [selectedUnitValue, setSelectedUnitValue] = useState('');
  // challenge_display{}
  const [challengeDisplayImage, setChallengeDisplayImage] = useState('');
  const [challengeDisplayImageURL, setChallengeDisplayImageURL] = useState('');
  const [challengeDisplayImageURLS3, setChallengeDisplayImageURLS3] = useState('');
  const [challengeDisplayImageErrorMsg, setChallengeDisplayImageErrorMsg] = useState(false);
  const [challengeDisplaySubtitle, setChallengeDisplaySubtitle] = useState('');
  const [challengeDisplayDescription, setChallengeDisplayDescription] = useState('');
  const [displayImageUploadDisabled, setDisplayImageUploadDisabled] = useState(false);
  // after_success{}
  const [afterSuccessImage, setAfterSuccessImage] = useState('');
  const [afterSuccessImageURL, setAfterSuccessImageURL] = useState('');
  const [afterSuccessImageURLS3, setAfterSuccessImageURLS3] = useState('');
  const [afterSuccessImageErrorMsg, setAfterSuccessImageErrorMsg] = useState(false);
  const [afterSuccessSubtitle, setAfterSuccessSubtitle] = useState('');
  const [afterSuccessDescription, setAfterSuccessDescription] = useState('');
  const [successImageUploadDisabled, setSuccessImageUploadDisabled] = useState(false);
  //extras
  const [challengeConfig, setChallengeConfig] = useState([]);
  const [challengeToBeUpdated, setChallengeToBeUpdated] = useState({})
  const [challengeStatus, setChallengeStatus] = useState('')
  const [editDisabled, setEditDisabled] = useState(false)
  const { id } = useParams()
  const [saved, setSaved] = useState(false)
  const history = useHistory();
  //error
  const [showSchoolList, setShowSchoolList] = useState(false)
  const [showMCQChallengeError, setShowMCQChallengeError] = useState(false)
  //snackbar
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  //disable save 
  const [specialClassExists, setSpecialClassExists] = useState(false)
  const [subjectExists, setSubjectExists] = useState(false)
  const [chapterExists, setChapterExists] = useState(false)
  const [selectedComponentExists, setSelectedComponentExists] = useState(false)
  const [selectedTimeframeExists, setSelectedTimeframeExists] = useState(false)
  const [challengeLoader, setChallengeLoader] = useState(false)

  const [createChallengePayload, setCreateChallengePayload] = useState({})
  const [createChallengePayloadForPublished, setCreateChallengePayloadForPublished] = useState({})
  //validity
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [UTCStartDate, setUTCStartDate] = useState('');
  const [UTCEndDate, setUTCEndDate] = useState('');
  const [dateErrorMsg, setDateErrorMsg] = useState('')

  const handleStartDateChangeNew = (date) => {
    const newStartDate = date;
    const currentDate = new Date();
    const delayedDate = new Date(currentDate.getTime() - 60000); // 60,000 milliseconds = 1 minute
    const isValidStartDate = newStartDate > delayedDate

    if (isValidStartDate) {
      setSelectedStartDate(newStartDate);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      const outputDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
      setUTCStartDate(outputDateString)
      if (selectedEndDate !== null && selectedEndDate <= newStartDate) {
        setSelectedEndDate(null);
        setDateErrorMsg('End date must be after start date.')
      } else {
        setDateErrorMsg('')
      }
    } else {
      const date = new Date()
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      const outputDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
      setUTCStartDate(outputDateString)
      setSelectedStartDate(date);
      if (selectedEndDate !== null && selectedEndDate <= newStartDate) {
        setSelectedEndDate(null);
        setDateErrorMsg('End date must be after start date.')
      } else {
        setDateErrorMsg('')
      }
    }

  };

  const handleEndDateChangeNew = (date) => {
    const newEndDate = date;

    const isValidEndDate = newEndDate > selectedStartDate;

    if (isValidEndDate) {
      setSelectedEndDate(newEndDate);
      //convert 'Fri Jan 05 2024 11:34:02 GMT+0530 (India Standard Time)' into  "2024-01-07T06:30:00Z" format
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      const outputDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
      setUTCEndDate(outputDateString)

      setDateErrorMsg('')
    } else {
      const date = new Date(selectedStartDate.getTime() + 60000)
      setSelectedEndDate(date); //60,000 milliseconds = 1 minute
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      const outputDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
      setUTCEndDate(outputDateString)
      setDateErrorMsg('')
    }
  };

  const handleCreateChallengePayload = () => {
    createChallengePayload["challenge_title"] = challengeTitle;

    if (state_id) {
      createChallengePayload['student_state_id'] = state_id;
    } else {
      delete createChallengePayload.student_state_id
    }
    if (district_id) {
      createChallengePayload['student_district_id'] = district_id;
    } else {
      delete createChallengePayload.student_district_id
    }
    if (student_block_id) {
      createChallengePayload['student_block_id'] = student_block_id;
    } else {
      delete createChallengePayload.student_block_id
    }
    if (syllabus_id) {
      createChallengePayload['district_id'] = syllabus_id;
    } else {
      delete createChallengePayload.district_id 
    }
    if (medium_id) {
      createChallengePayload['medium_id'] = medium_id;
    } else {
      delete createChallengePayload.medium_id
    }
    if (class_id) {
      createChallengePayload['class_id'] = class_id;
    } else {
      delete createChallengePayload.class_id
    }
    //advance filter
    if (user_type) {
      createChallengePayload['advance_filter'] = {};
    } else {
      delete createChallengePayload.advance_filter
    }
    if (user_type) {
      createChallengePayload.advance_filter['user_type'] = user_type;

      if (timeframe) {
        createChallengePayload.advance_filter['time_frame'] = timeframe;
      } else {
        delete createChallengePayload.advance_filter?.time_frame
      }
      if (timeframeValue !== '') {
        createChallengePayload.advance_filter['unit_value'] = timeframeValue;
      } 
      if (user_type === 'Point') {
        createChallengePayload.advance_filter['unit_key'] = "point";
      } else {
        createChallengePayload.advance_filter['unit_key'] = "time";
      }
      if (advanceFilterPoints !== '') {
        createChallengePayload.advance_filter['unit_value'] = advanceFilterPoints;
      } 
    }
    // school
    if (selectedSchools) {
      createChallengePayload['school_id'] = selectedSchools;
    } else {
      delete createChallengePayload.school_id
    }

    createChallengePayload["start_date"] = UTCStartDate;
    createChallengePayload["end_date"] = UTCEndDate;

    if (rewardPoints) {
      createChallengePayload["reward_points"] = rewardPoints;
    } else {
      delete createChallengePayload.reward_points;
    }
    if (rewardDiamonds) {
      createChallengePayload["diamond"] = rewardDiamonds;
    } else {
      delete createChallengePayload.diamond;
    }
    createChallengePayload["reward_message"] = rewardDescription;

    createChallengePayload["challenge_info"] = {};
    // Challenge info
    if (selectedChallenge) {
      createChallengePayload.challenge_info['challenge'] = selectedChallenge;
    } else {
      delete createChallengePayload.challenge_info?.challenge
    }
    if (selectedSubChallenge) {
      createChallengePayload.challenge_info['sub_challenge'] = selectedSubChallenge;
    }else {
      delete createChallengePayload.challenge_info?.sub_challenge
    }
    if (specialClass_id) {
      createChallengePayload.challenge_info['class_id'] = specialClass_id;
    }else {
      delete createChallengePayload.challenge_info?.class_id
    }
    if (subject_id) {
      createChallengePayload.challenge_info['subject_id'] = subject_id;
    } else {
      delete createChallengePayload.challenge_info?.subject_id
    }
    if (chapter_id) {
      createChallengePayload.challenge_info['chapter_id'] = chapter_id;
    } else {
      delete createChallengePayload.challenge_info?.chapter_id
    }
    if (selectedComponent) {
      createChallengePayload.challenge_info['component_type'] = selectedComponent;
    }else {
      delete createChallengePayload.challenge_info?.component_type
    }
    if (selectedTimeframe) {
      createChallengePayload.challenge_info['timeframe'] = selectedTimeframe;
    } else {
      delete createChallengePayload.challenge_info?.timeframe
    }
    if (AIGenerated) {
      createChallengePayload.challenge_info['ai_generated'] = AIGenerated;
    } else {
      delete createChallengePayload.challenge_info?.ai_generated
    }
    if (comparisonForChallenge) {
      createChallengePayload.challenge_info['comparison_operator'] = comparisonForChallenge;
    } else {
      delete createChallengePayload.challenge_info?.comparison_operator
    }
    if (selectedUnit) {
      createChallengePayload.challenge_info['unit_key'] = selectedUnit;
    } else {
      delete createChallengePayload.challenge_info?.unit_key
    }
    if (selectedUnitValue) {
      createChallengePayload.challenge_info['unit_value'] = selectedUnitValue;
    } else {
      delete createChallengePayload.challenge_info?.unit_value
    }

    createChallengePayload["challenge_display"] = {};
    createChallengePayload.challenge_display['display_image'] = challengeDisplayImageURLS3;
    createChallengePayload.challenge_display['display_sub_title'] = challengeDisplaySubtitle;
    createChallengePayload.challenge_display['display_description'] = challengeDisplayDescription;

    createChallengePayload["after_success"] = {};
    createChallengePayload.after_success['success_image'] = afterSuccessImageURLS3;
    createChallengePayload.after_success['success_sub_title'] = afterSuccessSubtitle;
    createChallengePayload.after_success['success_description'] = afterSuccessDescription;

    if (challengeStatus === 'PUBLISHED' || challengeStatus === 'ACTIVE') {
      createChallengePayloadForPublished["reward_message"] = rewardDescription;

      createChallengePayloadForPublished["challenge_display"] = {};
      createChallengePayloadForPublished.challenge_display['display_image'] = challengeDisplayImageURLS3;
      createChallengePayloadForPublished.challenge_display['display_sub_title'] = challengeDisplaySubtitle;
      createChallengePayloadForPublished.challenge_display['display_description'] = challengeDisplayDescription;

      createChallengePayloadForPublished["after_success"] = {};
      createChallengePayloadForPublished.after_success['success_image'] = afterSuccessImageURLS3;
      createChallengePayloadForPublished.after_success['success_sub_title'] = afterSuccessSubtitle;
      createChallengePayloadForPublished.after_success['success_description'] = afterSuccessDescription;

    }
  }

  const handlePositiveNumberInput = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    const intValue = inputValue === '' ? '' : parseInt(inputValue, 10)
    return (intValue)
  }

  const disablePublishChallenge = () => {
    if (challengeTitle &&
      state_id &&
      selectedStartDate &&
      selectedEndDate &&
      (rewardPoints||rewardDiamonds) &&
      rewardDescription &&
      selectedChallenge &&
      selectedSubChallenge &&
      comparisonForChallenge &&
      selectedUnit &&
      selectedUnitValue &&
      challengeDisplayImageURLS3 &&
      challengeDisplaySubtitle &&
      challengeDisplayDescription &&
      afterSuccessImageURLS3 &&
      afterSuccessSubtitle &&
      afterSuccessDescription
    ) {
      if (specialClassExists && specialClass_id) {
        return (false)
      } else if (subjectExists && subject_id && chapterExists && chapter_id) {
        return (false)
      } else if (subjectExists && subject_id && selectedSubChallenge !== "Chapter Progress" && selectedSubChallenge !== "MCQ Set") {
        return (false)
      } else if (selectedComponentExists && selectedComponent) {
        return (false)
      } else if (selectedTimeframeExists && selectedTimeframe) {
        return (false)
      } else if (selectedSubChallenge === "AI Reels Total" || selectedSubChallenge === "Teacher Generated Total" || selectedSubChallenge === "Earn Points") {
        return (false)
      } else {
        return (true)
      }
    } else {
      return (true)
    }
  }

  async function getData() {
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      setStateList(stateRes.response)
    }
  }

  async function getDistrictsByState(state_id) {
    let response = await getSuperAdminService().getStudentDistrict({ student_state_id: state_id, })
    response = response.data;
    if (response && response.status == 200) {
      setDistrictList(response.response)
    }
  }

  async function getStudentBlock(district_id) {
    try {
      let response = await getSuperAdminService().getStudentBlock({ student_district_id: district_id, })
      response = response.data;
      if (response && response.status == 200) {
        setBlockList(response.response)
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  async function getSyllabus() {
    let param = {
      board_id: 2,
    };
    let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(param);
    CWDistrictRes = CWDistrictRes.data
    if (CWDistrictRes && CWDistrictRes.status == 200) {
      setSyllabusList(CWDistrictRes.response)
    }
  };

  async function getMedium() {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    mediumRes = mediumRes.data
    if (mediumRes && mediumRes.status == 200) {
      setMediumList(mediumRes.response)
    }
  };

  async function getClass() {
    let param = {
      district_id: syllabus_id,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(param)
    classRes = classRes.data
    if (classRes && classRes.status == 200) {
      setClassList(classRes.response)
    }
  };

  async function getSpecialClass() {
    let param = {
      district_id: 7,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(param)
    classRes = classRes.data
    if (classRes && classRes.status == 200) {
      setSpecialClassList(classRes.response)
    }
  };

  async function getAllSubject() {
    if (selectedSubChallenge === "AI Reels In One Session") {
      let URL = `get_all_subjects_v2/?is_common_reels=True&class_id=${class_id}&medium_id=${medium_id}`
  
      let subRes = await http.get(URL)
      subRes = subRes.data;
      if (subRes) {
        let newSubRes = subRes.response.filter(function(sub) {
          return sub.is_subject_reels === true;
        });

        setSubjectList(newSubRes)
        // setSubjectList(subRes.response)
      } else {
        setSubjectList([])
    }
  } else {
    let param = {
      class_id: class_id,
      medium_id: medium_id,
    };
    let subRes = await getContentWriterService().getSubjectByClassMed(param)
    setSubjectList(subRes.data.response)
  }
  };

  async function getChapterListByChapter() {
    let URL = 'chapters/'
    if (selectedChallenge === "MCQ Set") {
      URL = 'chapters/?mcq_exits=true'
    }
    let chapterListData = await httpV2.get(URL, {
      params: {
        limit: 100,
        offset: 0,
        status: "PUBLISHED",
        subject_id: subject_id,
      },
    });
    chapterListData = chapterListData.data;
    if (chapterListData) {
      setChapterList(chapterListData.results)
      if (chapterListData.results?.length < 1) {
        setShowMCQChallengeError(true)
      } else {
        setShowMCQChallengeError(false)
      }
    } else {
      setChapterList([])
    }
  };

  const getSchoolsForBlockData = async () => {
    const param = {
      student_block_id: student_block_id,
      searchText: schoolSearchTxt,
    };

    try {
      const response = await getSuperAdminService().getSchoolsForBlock(param)
      setSchoolList(response.data.response)
    } catch (error) {
      console.error('Error fetching school list', error)
    }
  }

  const getAdvanceFilter = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterData()
      if(response.data.status==200)
        setAdvanceFilterList(response?.data?.response)
      else
        throw new Error(response.data.response);
    } catch (error) {
      console.error('Error fetching advance filter list', error)
    }
  }

  const getAdvanceFilterTimeframe = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterTimeframeData(user_type)
      setAdvanceFilterTimeframeList(response.data.response)
    } catch (error) {
      console.error('Error fetching advance filter timeframe list', error)
    }
  }

  const getAdvanceFilterTimeframeValue = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterTimeframeValueData(user_type, timeframe)
      setTimeframeValueList(response.data.response)
    } catch (error) {
      console.error('Error fetching advance filter timeframe value list', error)
    }
  }

  const getAdvanceFilterComparison = async () => {
    try {
      const response = await getSuperAdminService().getAdvanceFilterComparisonData(user_type, timeframe, timeframeValue)
      setAdvanceFilterComparisonList(response.data.response)
    } catch (error) {
      console.error('Error fetching advance filter comparison list', error)
    }
  }

  const getSingleChallenge = async () => {
    try {
      setChallengeLoader(true)
      const response = await getSuperAdminService().getSingleChallengeData(id)
      setChallengeLoader(false)
      setChallengeToBeUpdated(response.data)
      const challenge = response.data

      setChallengeStatus(challenge.status)
      setChallengeTitle(challenge.challenge_title)
      //filter{}
      setState_id(challenge.student_state_id)
      if (challenge.student_district_id) {
        setDistrict_id(challenge.student_district_id)
      }
      if (challenge.student_block_id) {
        setStudent_block_id(challenge.student_block_id)
      }
      if (challenge.district_id) {
        setSyllabus_id(challenge.district_id)
      }
      if (challenge.medium_id) {
        setMedium_id(challenge.medium_id)
      }
      if (challenge.class_id) {
        setClass_id(challenge.class_id)
      }
      //advance filter
      if (challenge.advance_filter.user_type) {
        setUser_type(challenge.advance_filter.user_type)
      }
      if (challenge.advance_filter.time_frame) {
        setTimeframe(challenge.advance_filter.time_frame)
      }
      if (challenge.advance_filter.user_type === "Point" && challenge.advance_filter.unit_value) {
        setAdvanceFilterPoints(challenge.advance_filter.unit_value)
      } else if (challenge.advance_filter.user_type !== "Point" && challenge.advance_filter.unit_value) {
        setTimeframeValue(challenge.advance_filter.unit_value)
      } else if (challenge.advance_filter.user_type === "Inactive User") {
        setTimeframeValue(challenge.advance_filter.unit_value)
      }
      //school
      if (challenge.school_id) {
        setSelectedSchools(challenge.school_id)
      }
      if (challenge.school_list) {
        setSelectedSchoolsObject(challenge.school_list)
      }
      //validity
      const utcDatetimeString = challenge.start_date;
      // Create a Date object from the UTC string
      const utcDatetime = new Date(utcDatetimeString);
      setSelectedStartDate(utcDatetime)
      setUTCStartDate(utcDatetime)

      const utcDatetimeStringEnd = challenge.end_date;
      // Create a Date object from the UTC string
      const utcDatetimeEnd = new Date(utcDatetimeStringEnd);
      const localDatetimeStringEnd = `${utcDatetimeEnd.getFullYear()}-${(utcDatetimeEnd.getMonth() + 1).toString().padStart(2, '0')}-${utcDatetimeEnd.getDate().toString().padStart(2, '0')}T${utcDatetimeEnd.getHours().toString().padStart(2, '0')}:${utcDatetimeEnd.getMinutes().toString().padStart(2, '0')}`;
      setSelectedEndDate(utcDatetimeEnd)
      setUTCEndDate(utcDatetimeEnd)

      // //reward
      if (challenge.reward_points) {
        setRewardPoints(challenge.reward_points)
      }
      if (challenge.diamond) {
        setRewardDiamonds(challenge.diamond)
      }
      setRewardDescription(challenge.reward_message)
      // //challenge info{} 
      setSelectedChallenge(challenge.challenge_info.challenge)
      setSelectedSubChallenge(challenge.challenge_info.sub_challenge)
      if (challenge.challenge_info.subject_id) {
        setSubject_id(challenge.challenge_info.subject_id)
      }
      if (challenge.challenge_info.chapter_id) {
        setChapter_id(challenge.challenge_info.chapter_id)
      }
      if (challenge.challenge_info.class_id) {
        setSpecialClass_id(challenge.challenge_info.class_id)
      }
      if (challenge.challenge_info.component_type) {
        setSelectedComponent(challenge.challenge_info.component_type)
      }
      if (challenge.challenge_info.timeframe) {
        setSelectedTimeframe(challenge.challenge_info.timeframe)
      }
      if (challenge.challenge_info.ai_generated) {
        setAIGenerated(challenge.challenge_info.ai_generated)
      }
      if (challenge.challenge_info.comparison_operator) {
        setComparisonForChallenge(challenge.challenge_info.comparison_operator)
      }
      if (challenge.challenge_info.unit_key) {
        setSelectedUnit(challenge.challenge_info.unit_key)
      }
      if (challenge.challenge_info.unit_value) {
        setSelectedUnitValue(challenge.challenge_info.unit_value)
      }
      // challenge_display{}
      setChallengeDisplayImageURL(challenge.challenge_display.display_image)
      setChallengeDisplayImageURLS3(challenge.challenge_display.display_image)
      setChallengeDisplaySubtitle(challenge.challenge_display.display_sub_title)
      setChallengeDisplayDescription(challenge.challenge_display.display_description)
      // after_success{}
      setAfterSuccessImageURL(challenge.after_success.success_image)
      setAfterSuccessImageURLS3(challenge.after_success.success_image)
      setAfterSuccessSubtitle(challenge.after_success.success_sub_title)
      setAfterSuccessDescription(challenge.after_success.success_description)
    } catch (error) {
      setChallengeLoader(false)
      console.error('Error fetching single challenge ', error)
    }
  }

  const getChallengeList = async () => {
    try {
      const response = await getSuperAdminService().getChallengeList()
      setChallengeList(response.data.response)
    } catch (error) {
      console.error('Error fetching challenge list', error)
    }
  }

  const getSubChallengeList = async () => {
    try {
      const response = await getSuperAdminService().getSubChallengeListByChallenge(selectedChallenge)
      setSubChallengeList(response.data.response)
    } catch (error) {
      console.error('Error fetching sub challenge list', error)
    }
  }

  const getChallengeConfiguration = async () => {
    try {
      const response = await getSuperAdminService().getChallengeConfig(selectedChallenge, selectedSubChallenge)
      setChallengeConfig(response.data.response)
    } catch (error) {
      console.error('Error fetching challenge configuration', error)
    }
  }

  const uploadChallengeDisplayImageAndGetURL = async () => {
    try {
      const formData = new FormData()
      formData.append('image', challengeDisplayImage)
      const uploadResponse = await getSuperAdminService().getImageURLs3(formData)
      setChallengeDisplayImageURLS3(uploadResponse.data.file)
      setDisplayImageUploadDisabled(true)
      setError(false)
      setMessage("Image uploaded successfully")
    } catch (error) {
      console.error('Error get image URL from s3 bucket', error)
      setMessage("Error uploading image")
      setError(true)
    }
  }

  const uploadAfterSuccessImageAndGetURL = async () => {
    try {
      const formData = new FormData()
      formData.append('image', afterSuccessImage)
      const uploadResponse = await getSuperAdminService().getImageURLs3(formData)
      setAfterSuccessImageURLS3(uploadResponse.data.file)
      setSuccessImageUploadDisabled(true)
      setError(false)
      setMessage("Image uploaded successfully")
    } catch (error) {
      console.error('Error get image URL from s3 bucket', error)
      setMessage("Error uploading image")
      setError(true)
    }
  }

  const handleUpdateChallenge = async () => {
    try {
      const handleEditChallenge = async () => {
        let payload = ''
        if (challengeStatus === 'PUBLISHED' || challengeStatus === 'ACTIVE') {
          payload = createChallengePayloadForPublished
        }
        if (challengeStatus === 'DRAFT') {
          payload = createChallengePayload
        }

        let response = await getSuperAdminService().updateSavedChallenge(id, payload)
        if (response.data.status === 200) {
          setError(false)
          setMessage("Saved Changes successfully")
          const timeoutId = setTimeout(() => {
            history.push("/admin/challenges")
          }, 1000);
        } else {
          setMessage(response.data.response)
          setError(true)
        }
      }
      handleEditChallenge()
    } catch (error) {
      console.error('Error updating challenge', error)
    }
  }

  useEffect(() => {
    getData()
    getMedium()
    getAdvanceFilter()
    getSyllabus()

    getChallengeList()
    getSingleChallenge()
  }, []);

  useEffect(() => {
    for (let i = 0; i < challengeConfig[0]?.parameters?.length; i++) {
      if (challengeConfig[0]?.parameters[i] === "Special class") {
        setSpecialClassExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "Subject") {
        setSubjectExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "Chapter") {
        setChapterExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "Select component") {
        setSelectedComponentExists(true)
      }
      if (challengeConfig[0]?.parameters[i] === "timeframe") {
        setSelectedTimeframeExists(true)
      }

    }
  }, [challengeConfig]);

  useEffect(() => {
    setDistrict_id('')
    setDistrictList([])
    if (state_id) {
      getDistrictsByState(state_id)
    }
    setStudent_block_id('')
    setBlockList([])
  }, [state_id]);

  useEffect(() => {
    setStudent_block_id('')
    setBlockList([])
    if (district_id) {
      getStudentBlock(district_id)
    }
  }, [district_id]);

  useEffect(() => {
    if (student_block_id) {
      getSchoolsForBlockData()
    }
  }, [schoolPageNo, schoolSearchTxt, student_block_id]);

  useEffect(() => {
    setClass_id('')
    setClassList([])
    if (syllabus_id) {
      getClass()
    }
    setSubject_id('')
    setSubjectList([])
  }, [syllabus_id]);

  useEffect(() => {
    setSubject_id('')
    setSubjectList([])
    if (class_id && medium_id && selectedSubChallenge) {
      getAllSubject()
    }
    setChapter_id('')
    setChapterList([])
  }, [class_id, medium_id, selectedSubChallenge]);

  useEffect(() => {
    setChapterList([])
    if (subject_id) {
      getChapterListByChapter()
    }
  }, [subject_id]);

  useEffect(() => {
    setTimeframe('')
    setTimeframeValue('')
    setAdvanceFilterPoints('')
    if (user_type) {
      getAdvanceFilterTimeframe();
    }
  }, [user_type]);

  useEffect(() => {
    if (user_type === "Champion User") {
      setTimeframeValue('')
    }
    if (timeframe) {
      getAdvanceFilterTimeframeValue();
    }
  }, [timeframe]);

  useEffect(() => {
    setSelectedSubChallenge('')
    setSubChallengeList([])
    if (selectedChallenge) {
      getSubChallengeList()
    }
  }, [selectedChallenge]);

  useEffect(() => {
    if (selectedChallenge && selectedSubChallenge) {
      getChallengeConfiguration()
    }
    if (selectedSubChallenge === 'Special Course') {
      getSpecialClass()
    }
    if (selectedSubChallenge !== 'Special Course') {
      setSpecialClass_id('')
      setSpecialClassList([])
    }
    setSubject_id('')
    setChapter_id('')
    setSelectedComponent('')
    setSelectedTimeframe('')
    setAIGenerated('')
    setComparisonForChallenge('')
    setSelectedUnit('')
    setSelectedUnitValue('')
  }, [selectedChallenge, selectedSubChallenge]);

  useEffect(() => {
    if (challengeConfig[0]?.components) {
      setComponents(challengeConfig[0]?.components)
    } else {
      setComponents([])
    }

    if (challengeConfig[0]?.comparison_operator) {
      setComparisonForChallengeList(challengeConfig[0]?.comparison_operator)
    } else {
      setComparisonForChallengeList([])
    }

    if (challengeConfig[0]?.unit) {
      setUnitList(challengeConfig[0]?.unit)
    } else {
      setUnitList([])
    }
  }, [challengeConfig]);

  useEffect(() => {
    if (challengeStatus === 'PUBLISHED' || challengeStatus === 'ACTIVE') {
      setEditDisabled(true)
    }
  }, [challengeStatus]);

  useEffect(() => {
    handleCreateChallengePayload();
  }, [challengeTitle, state_id, district_id, student_block_id, syllabus_id, medium_id, class_id, user_type, timeframe, timeframeValue, advanceFilterPoints, selectedSchools, UTCStartDate, UTCEndDate, rewardPoints, rewardDiamonds, rewardDescription, selectedChallenge, selectedSubChallenge, specialClass_id, subject_id, chapter_id, selectedComponent, selectedTimeframe, AIGenerated, comparisonForChallenge, selectedUnit, selectedUnitValue, challengeDisplayImageURLS3, challengeDisplaySubtitle, challengeDisplayDescription, afterSuccessImageURLS3, afterSuccessSubtitle, afterSuccessDescription]);

  return (
    <>
      <Snackbar
        autoHideDuration={5000} mess
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => {
          setMessage("")
        }}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>
      <Card className="mb-5 ps-4 pb-4 pe-4" >
        <CardHeader color="primary">
          <h4 className="cardTitleWhite" >Edit Challenge</h4>
        </CardHeader>


        {challengeLoader == true ? (
          <div style={{
            marginLeft: "50%",
            marginTop: "20%",
            marginBottom: '30%',
          }} >
            <CardContent>
              <CircularProgress
                color="blue"
                size={50}
              />
            </CardContent>
          </div>

        ) : (
          <>
            <CardContent>
              <div className="row pt-5">
                <div className="col-12">
                  <div className='pb-2'><h5 className='color8A'>Challenge Title<span className='colorRed' >*</span></h5></div>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" maxlength="200" disabled={editDisabled}
                    value={challengeTitle}
                    onChange={(e) => {
                      setChallengeTitle(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>

              <div className="row challengeListFilterDiv pt-5">
                <div className=''><h5 className='color8A'>Target user</h5></div>
                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      Select State<span className='colorRed' >*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={state_id}
                      onChange={(e) => {
                        setState_id(e.target.value)
                        console.log(e.target.value, 'state')
                      }}
                    >
                      {stateList.map((state, index) => {
                        return (
                          <MenuItem key={index + "state"} value={state.state_id}>
                            {`${state.state_name}`.toString().charAt(0).toUpperCase() + `${state.state_name}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled} >
                    <InputLabel id="demo-simple-select-label">
                      Select District
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={district_id}
                      onChange={(e) => {
                        setDistrict_id(e.target.value)
                      }}
                    >
                      {districtList.map((district, index) => {
                        return (
                          <MenuItem key={index + "district"} value={district.student_district_id}>
                            {`${district.district_name}`.toString().charAt(0).toUpperCase() + `${district.district_name}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      Select Block
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={student_block_id}
                      onChange={(e) => {
                        setStudent_block_id(e.target.value)
                      }}
                    >
                      {blockList.map((block, index) => {
                        return (
                          <MenuItem key={index + "block"} value={block.student_block_id}>
                            {`${block.block_name}`.toString().charAt(0).toUpperCase() + `${block.block_name}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      Medium
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={medium_id}
                      onChange={(e) => {
                        setMedium_id(e.target.value)
                        setChapter_id('')
                      }}
                    >
                      {mediumList.map((medium, index) => {
                        return (
                          <MenuItem key={index + "medium"} value={medium.medium_id}>
                            {`${medium.medium_name}`.toString().charAt(0).toUpperCase() + `${medium.medium_name}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={class_id}
                      onChange={(e) => {
                        setClass_id(e.target.value)
                        setChapter_id('')
                      }}
                    >
                      {classList.map((cls, index) => {
                        return (
                          <MenuItem key={index + "class"} value={cls.class_id}>
                            {cls.class_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled} >
                    <InputLabel id="demo-simple-select-label">
                      Advance Filter
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={user_type}
                      onChange={(e) => {
                        setUser_type(e.target.value)
                      }}
                    >
                      {advanceFilterList?.map((item, index) => {
                        return (
                          <MenuItem key={index + "advanceFilter"} value={item}>
                            {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div className={`${user_type === "Point" ? '' : 'd-none'} col-4 inputWrapper`} >
                  <FormControl className='width28' disabled={editDisabled}>
                    <InputLabel htmlFor="my-input">Points<span className='colorRed' >*</span></InputLabel>
                    <Input id="my-input" aria-describedby="my-helper-text" type='text' value={advanceFilterPoints} onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e)
                      if (intValue > 0 && intValue <= 99999) {
                        setAdvanceFilterPoints(intValue)
                      } else if (intValue <= 0) {
                        setAdvanceFilterPoints('')
                      }
                    }}
                    />
                    <FormHelperText id="my-helper-text">Point should be more than 0.</FormHelperText>
                  </FormControl>
                </div>

                <div className={`${user_type && advanceFilterTimeframeList[0] ? '' : 'd-none'} col-4 inputWrapper`} >
                  <FormControl className="dropdown width28" disabled={editDisabled} >
                    <InputLabel id="demo-simple-select-label">
                      Timeframe<span className='colorRed' >*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={timeframe}
                      onChange={(e) => {

                        setTimeframe(e.target.value)
                        console.log(user_type)
                        if (user_type === "Active User") {
                          setTimeframeValue(1000)
                        }
                        if (user_type === "Inactive User") {
                          setTimeframeValue(0)
                        }
                      }}
                    >
                      {advanceFilterTimeframeList.map((item, index) => {
                        return (
                          <MenuItem key={index + "advanceFilter"} value={item}>
                            {`${item}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className={`${timeframe && timeframeValueList[0]?.unit_value ? '' : 'd-none'} col-4 inputWrapper`} >
                  <FormControl className="dropdown width28" disabled={editDisabled} >
                    <InputLabel id="demo-simple-select-label">
                      Timeframe Value<span className='colorRed' >*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={timeframeValue}
                      onChange={(e) => {
                        setTimeframeValue(e.target.value)
                      }}
                    >
                      {timeframeValueList.map((item, index) => {
                        return (
                          <MenuItem key={index + "advanceFilterTimeframeValue"} value={item.actual_value}>
                            {`${item.unit_value}`.toString().charAt(0).toUpperCase() + `${item.unit_value}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className={`${advanceFilterComparisonList[0] ? '' : 'd-none'} col-4 inputWrapper`} >
                  <FormControl className="dropdown width28" disabled={editDisabled} >
                    <InputLabel id="demo-simple-select-label">
                      Comparison<span className='colorRed' >*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={advanceFilterComparison}
                      onChange={(e) => {
                        setAdvanceFilterComparison(e.target.value)
                      }}
                    >
                      {advanceFilterComparisonList.map((item, index) => {
                        return (
                          <MenuItem key={index + "advanceFilterComparisonValue"} value={item}>
                            {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <div className={`col-8 inputWrapper mt-4`} >
                    <InputLabel className='pb-1' id="demo-simple-select-label">
                      School
                    </InputLabel>
                    <Autocomplete
                      disabled={editDisabled}
                      value={selectedSchoolsObject}
                      onChange={(event, value, reason) => {
                        if (reason === 'clear') {
                          setSelectedSchoolsObject([]);
                          setSelectedSchools([])
                          return;
                        }
                        if (reason === 'remove-option') {
                          setSelectedSchoolsObject(value);
                          let schoolId = []
                          for (let i = 0; i < value?.length; i++) {
                            schoolId.push(value[i]?.school_id)
                          }
                          setSelectedSchools(schoolId)
                          return;
                        }
                        if (reason === 'select-option') {
                          const updatedSchools = [...selectedSchoolsObject];
                          const selectedSchool = value.slice(-1)[0];
                          const schoolIndex = updatedSchools.findIndex(school => school.school_id === selectedSchool.school_id);

                          if (schoolIndex === -1) {
                            updatedSchools.push(selectedSchool);
                          } else {
                            updatedSchools.splice(schoolIndex, 1);
                          }
                          setSelectedSchoolsObject(updatedSchools);
                          let schoolId = []
                          for (let i = 0; i < updatedSchools?.length; i++) {
                            schoolId.push(updatedSchools[i]?.school_id)
                          }
                          setSelectedSchools(schoolId)

                        }
                      }}

                      inputValue={schoolSearchTxt}
                      onInputChange={(event, newInputValue) => {
                        setSchoolSearchTxt(newInputValue);
                      }}
                      filterOptions={options => options}
                      multiple
                      id="checkboxes-tags-demo"
                      options={schoolList}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.school_name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selectedSchoolsObject.findIndex(sch => sch.school_id === option.school_id) > -1}
                          />
                          {option.school_name}
                        </React.Fragment>
                      )}
                      style={{ width: "90%" }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="" placeholder="Search..." />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row challengeListFilterDiv">
                <div className='d-flex justify-content-end pe-5 pt-4'>
                  <button type="button" className="btn btn-outline-dark px-4" disabled={editDisabled} onClick={
                    () => {
                      setState_id('')
                      setDistrict_id('')
                      setStudent_block_id('')
                      setSyllabus_id(3)
                      setMedium_id('')
                      setClass_id('')
                      setSpecialClass_id('')
                      setUser_type('')
                      setTimeframe('')
                      setTimeframeValue('')
                      setAdvanceFilterPoints('')
                      setAdvanceFilterComparison('')
                      setSelectedSchools([])
                      setSelectedSchoolsObject([])
                      setSchoolList([])
                      delete createChallengePayload.student_state_id
                      delete createChallengePayload.student_district_id
                      delete createChallengePayload.student_block_id
                      delete createChallengePayload.district_id //syllabus
                      delete createChallengePayload.medium_id
                      delete createChallengePayload.class_id
                      delete createChallengePayload.advance_filter
                      delete createChallengePayload.school_id
                    }
                  }>RESET</button>
                </div>
              </div>

              <DateTimeComponent selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleStartDateChangeNew={handleStartDateChangeNew} handleEndDateChangeNew={handleEndDateChangeNew} dateErrorMsg={dateErrorMsg} disabled={editDisabled} status={challengeStatus} />

              <div className="row challengeListFilterDiv pt-5">
                <div className=''><h5 className='color8A'>Reward for completing challenge</h5></div>
                <div className='col-4'>
              <div className="col-12 inputWrapper">
                <FormControl className='width28'>
                  <InputLabel htmlFor="my-input">Reward Points</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" type='text' value={rewardPoints} onChange={(e) => {
                    const intValue = handlePositiveNumberInput(e)
                    if (intValue > 0 && intValue <= 99999) {
                      setRewardPoints(intValue)
                    } else if (intValue <= 0) {
                      setRewardPoints('')
                    }
                  }}
                  />
                  {/* <FormHelperText id="my-helper-text">Point should be more than 0.</FormHelperText> */}
                </FormControl>
              </div>
              <div className="col-12 inputWrapper">
                <FormControl className='width28'>
                  <InputLabel htmlFor="my-input">Reward Diamonds</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" type='text' value={rewardDiamonds} onChange={(e) => {
                    const intValue = handlePositiveNumberInput(e)
                    if (intValue > 0 && intValue <= 99999) {
                      setRewardDiamonds(intValue)
                    } else if (intValue <= 0) {
                      setRewardDiamonds('')
                    }
                  }}
                  />
                  {/* <FormHelperText id="my-helper-text">Point should be more than 0.</FormHelperText> */}
                </FormControl>
              </div>
            </div>
                <div className="col-8 inputWrapper">
                  <FormControl style={{ width: "90%" }}>
                    <label className='color8A'>Reward message<span className='colorRed' >*</span></label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      maxLength="200"
                      value={rewardDescription}
                      onChange={(e) => {
                        setRewardDescription(e.target.value);
                      }}
                    ></textarea>
                    <div className='d-flex justify-content-end color8A pt-1'>{rewardDescription.length}/200</div>
                  </FormControl>
                </div>
              </div>

              <div className="row challengeListFilterDiv pt-5">
                <div className=''><h5 className='color8A'>Challenge type</h5></div>
                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      Challenge<span className='colorRed' >*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={selectedChallenge}
                      onChange={(e) => {
                        setSelectedChallenge(e.target.value)
                        setChapter_id('')
                      }}
                    >
                      {challengeList.map((item, index) => {
                        return (
                          <MenuItem key={index + "challengeList"} value={item}>
                            {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-4 inputWrapper">
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      Sub Challenge<span className='colorRed' >*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={selectedSubChallenge}
                      onChange={(e) => {
                        setSelectedSubChallenge(e.target.value)
                        setChapter_id('')
                      }}
                    >
                      {subChallengeList.map((item, index) => {
                        return (
                          <MenuItem key={index + "subChallengeList"} value={item}>
                            {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                {
                  challengeConfig[0]?.parameters?.map((param, index) => (
                    <>
                      <div className={`${param === "Special class" ? "" : "d-none"} col-4 inputWrapper`}>
                        <FormControl className="dropdown width28" disabled={editDisabled} >
                          <InputLabel id="demo-simple-select-label">
                            Special class
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={specialClass_id}
                            onChange={(e) => {
                              setSpecialClass_id(e.target.value)
                            }}
                          >
                            {specialClassList?.map((cls, index) => {
                              return (
                                <MenuItem key={index + "class"} value={cls.class_id}>
                                  {cls.class_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={`${param === "Subject" ? "" : "d-none"} col-4 inputWrapper`}>
                        <FormControl className="dropdown width28" disabled={editDisabled}>
                          <InputLabel id="demo-simple-select-label">
                            Subject<span className='colorRed' >*</span>
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={subject_id}
                            onChange={(e) => {
                              setSubject_id(e.target.value)
                              setChapter_id('')
                            }}
                          >
                            {subjectList.map((subject, index) => {
                              return (
                                <MenuItem key={index + "subject"} value={subject.subject_id}>
                                  {`${subject.subject_name}`.toString().charAt(0).toUpperCase() + `${subject.subject_name}`.slice(1)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText id="my-helper-text"><span className="colorRed" >{class_id && medium_id ? "" : "To select Subject, select class and medium first"}</span></FormHelperText>
                        </FormControl>
                      </div>

                      <div className={`${param === "Chapter" ? "" : "d-none"} col-4 inputWrapper`}>
                        <FormControl className="dropdown width28" disabled={editDisabled}>
                          <InputLabel id="demo-simple-select-label">
                            Chapter<span className='colorRed' >*</span>
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={chapter_id}
                            onChange={(e) => {
                              setChapter_id(e.target.value)
                            }}
                          >
                            {chapterList.map((chapter, index) => {
                              return (
                                <MenuItem key={index + "chapter"} value={chapter.chapter_id}>
                                  {`${chapter.chapter_name}`.toString().charAt(0).toUpperCase() + `${chapter.chapter_name}`.slice(1)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText className="colorRed" id="my-helper-text"><span className="colorRed" >{subject_id ? "" : "To select Chapter, select subject first"}</span></FormHelperText>
                          <FormHelperText className={`${showMCQChallengeError && subject_id ? "" : "d-none"} colorRed`} id="my-helper-text"><span className="colorRed" >MCQ set not present please select another subject</span></FormHelperText>
                        </FormControl>
                      </div>
                      <div className={`${param === "Select component" ? "" : "d-none"} col-4 inputWrapper`}>
                        <FormControl className="dropdown width28" disabled={editDisabled}>
                          <InputLabel id="demo-simple-select-label">
                            Select component<span className='colorRed' >*</span>
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={selectedComponent}
                            onChange={(e) => {
                              setSelectedComponent(e.target.value)
                            }}
                          >
                            {components.map((item, index) => {
                              return (
                                <MenuItem key={index + "components"} value={item}>
                                  {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={`${param === "timeframe" ? "" : "d-none"} col-4 inputWrapper`}>
                        <FormControl className="dropdown width28" disabled={editDisabled}>
                          <InputLabel id="demo-simple-select-label">
                            Timeframe
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={selectedTimeframe}
                            onChange={(e) => {
                              setSelectedTimeframe(e.target.value)
                            }}
                          >
                            {components.map((item, index) => {
                              return (
                                <MenuItem key={index + "components"} value={item}>
                                  {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </>
                  ))
                }

                <div className={`${challengeConfig[0]?.comparison_operator ? "" : "d-none"} col-4 inputWrapper`}>
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      <div className='d-flex'>Comparison<span className='colorRed' >*</span></div>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={comparisonForChallenge}
                      onChange={(e) => {
                        setComparisonForChallenge(e.target.value)
                      }}
                    >
                      {comparisonForChallengeList.map((item, index) => {
                        return (
                          <MenuItem key={index + "components"} value={item}>
                            {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className={`${challengeConfig[0]?.unit ? "" : "d-none"} col-4 inputWrapper`}>
                  <FormControl className="dropdown width28" disabled={editDisabled}>
                    <InputLabel id="demo-simple-select-label">
                      Unit<span className='colorRed' >*</span>
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={selectedUnit}
                      onChange={(e) => {
                        setSelectedUnit(e.target.value)
                        setSelectedUnitValue('')
                      }}
                    >
                      {unitList.map((item, index) => {
                        return (
                          <MenuItem key={index + "components"} value={item}>
                            {`${item}`.toString().charAt(0).toUpperCase() + `${item}`.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className={`${selectedUnit === "Percentage" ? "" : "d-none"} col-4 inputWrapper`}>
                  <FormControl className='width28' disabled={editDisabled}>
                    <InputLabel htmlFor="my-input">{selectedUnit}<span className='colorRed' >*</span></InputLabel>
                    <Input id="my-input" aria-describedby="my-helper-text" type='text' value={selectedUnitValue} onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e)
                      if (intValue > 0 && intValue <= 100) {
                        setSelectedUnitValue(intValue)
                      } else if (intValue <= 0) {
                        setSelectedUnitValue('')
                      }
                    }} />
                  </FormControl>
                </div>
                <div className={`${selectedUnit !== "Percentage" && selectedUnit !== "" ? "" : "d-none"} col-4 inputWrapper`}>
                  {/* <div className={`${selectedUnit === "Minute" || selectedUnit === "Points" ? "" : "d-none"} col-4 inputWrapper`}> */}
                  <FormControl className='width28' disabled={editDisabled}>
                    <InputLabel htmlFor="my-input">{selectedUnit}<span className='colorRed' >*</span></InputLabel>
                    <Input id="my-input" aria-describedby="my-helper-text" type='text' value={selectedUnitValue} onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e)
                      if (intValue > 0 && intValue <= 99999) {
                        setSelectedUnitValue(intValue)
                      } else if (intValue <= 0) {
                        setSelectedUnitValue('')
                      }
                    }} />
                  </FormControl>
                </div>
              </div>

              <div className='challengeImgTitleDesc'>
                <div className="mt-5 py-5 bg-light px-4 d-flex justify-content-around">
                  <div>
                    <div>
                      <h3>Challenge displayed</h3>
                    </div>
                    <div className="d-flex">
                      <div className="px-4 w-100">
                        <div className="upload-image" style={{ marginBottom: '20px' }}>
                          <label className="form-label" htmlFor="customFile">
                            Upload icon/image<span className='colorRed' >*</span>
                          </label>
                          <input
                            type="file"
                            accept="image/*,.json"
                            className="form-control"
                            id="customFile"
                            onChange={(e) => {
                              if (!e.target.files[0]) {
                                setChallengeDisplayImage('')
                                setChallengeDisplayImageURL('');
                                setChallengeDisplayImageErrorMsg(false)
                                return;
                              } else if (e.target.files[0].size > 1024 * 1024) {
                                setChallengeDisplayImage('')
                                setChallengeDisplayImageURL('');
                                setChallengeDisplayImageErrorMsg(true)
                              } else {
                                setChallengeDisplayImage(e.target.files[0])
                                setChallengeDisplayImageURL(URL.createObjectURL(e.target.files[0]));
                                setChallengeDisplayImageErrorMsg(false)
                                setDisplayImageUploadDisabled(false)
                              }
                            }}
                          />

                          {
                            challengeDisplayImage !== "" ?
                              (challengeDisplayImage.name.split('.').pop() === 'json' ?
                                <div className={`${challengeDisplayImageURL ? "" : "d-none"}`}><MyLottieComponent vopalottie={challengeDisplayImageURL} /></div> :
                                <div>
                                  <img className={`${challengeDisplayImageURL ? "" : "d-none"}`} src={challengeDisplayImageURL} width={370} height={370} alt="Uploaded" />
                                </div>) :
                              challengeDisplayImageURLS3.split('.').pop() === 'json' ?
                                <div className={`${challengeDisplayImageURLS3 ? "" : "d-none"}`}><MyLottieComponent vopalottie={challengeDisplayImageURLS3} /></div> :
                                <div>
                                  <img className={`${challengeDisplayImageURLS3 ? "" : "d-none"}`} src={challengeDisplayImageURLS3} width={370} height={370} alt="Uploaded" />
                                </div>
                          }

                          <div className={`pt-3`}>
                            <div className={`${challengeDisplayImageErrorMsg ? "" : "d-none"} text-danger`} id="my-helper-text">
                              Image size exceeds 1MB.
                            </div>
                          </div>

                        </div>

                        <div className={`${challengeDisplayImage ? "" : "d-none"} d-flex justify-content-center`}>
                          <Button
                            disabled={displayImageUploadDisabled}
                            className='ms-2'
                            color="primary"
                            onClick={uploadChallengeDisplayImageAndGetURL}
                          >
                            UPLOAD
                          </Button>
                        </div>

                        <div className="py-3">
                          <label htmlFor="exampleFormControlTextarea1">Challenge subtitle<span className='colorRed' >*</span></label>
                          <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" maxLength="200"
                            value={challengeDisplaySubtitle}
                            onChange={(e) => {
                              setChallengeDisplaySubtitle(e.target.value);
                            }}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">Challenge description<span className='colorRed' >*</span></label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            maxLength="200"
                            value={challengeDisplayDescription}
                            onChange={(e) => {
                              setChallengeDisplayDescription(e.target.value);
                            }}
                          ></textarea>
                          <div className='d-flex justify-content-end color8A pt-1'>{challengeDisplayDescription?.length}/200</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h3>After success</h3>
                    </div>
                    <div className="d-flex">
                      <div className="px-4 w-100">
                        <div className="upload-image" style={{ marginBottom: '20px' }}>
                          <label className="form-label" htmlFor="customFile">
                            Upload icon/image<span className='colorRed' >*</span>
                          </label>
                          <input
                            type="file"
                            accept="image/*,.json"
                            className="form-control"
                            id="customFile"
                            onChange={(e) => {
                              if (!e.target.files[0]) {
                                setAfterSuccessImage('')
                                setAfterSuccessImageURL('');
                                setAfterSuccessImageErrorMsg(false)
                                return;
                              } else if (e.target.files[0].size > 1024 * 1024) {
                                setAfterSuccessImage('')
                                setAfterSuccessImageURL('');
                                setAfterSuccessImageErrorMsg(true)
                              } else {
                                setAfterSuccessImage(e.target.files[0])
                                setAfterSuccessImageURL(URL.createObjectURL(e.target.files[0]));
                                setAfterSuccessImageErrorMsg(false)
                                setSuccessImageUploadDisabled(false)
                              }
                            }}
                          />
                          {
                            afterSuccessImage !== "" ?
                              (afterSuccessImage.name.split('.').pop() === 'json' ?
                                <div className={`${afterSuccessImageURL ? "" : "d-none"}`}><MyLottieComponent vopalottie={afterSuccessImageURL} /></div> :
                                <div>
                                  <img className={`${afterSuccessImageURL ? "" : "d-none"}`} src={afterSuccessImageURL} width={370} height={370} alt="Uploaded" />
                                </div>)
                              :
                              afterSuccessImageURLS3.split('.').pop() === 'json' ?
                                <div className={`${afterSuccessImageURLS3 ? "" : "d-none"}`}><MyLottieComponent vopalottie={afterSuccessImageURLS3} /></div> :
                                <div>
                                  <img className={`${afterSuccessImageURLS3 ? "" : "d-none"}`} src={afterSuccessImageURLS3} width={370} height={370} alt="Uploaded" />
                                </div>
                          }

                          <div className={`pt-3`}>
                            <div className={`${afterSuccessImageErrorMsg ? "" : "d-none"} text-danger`} id="my-helper-text">
                              Image size exceeds 1MB.
                            </div>
                          </div>
                        </div>

                        <div className={`${afterSuccessImage ? "" : "d-none"} d-flex justify-content-center`}>
                          <Button
                            disabled={successImageUploadDisabled}
                            className='ms-2'
                            color="primary"
                            onClick={uploadAfterSuccessImageAndGetURL}
                          >
                            UPLOAD
                          </Button>
                        </div>

                        <div className="py-3">
                          <label htmlFor="exampleFormControlTextarea1">Challenge title<span className='colorRed' >*</span></label>
                          <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" maxLength="200"
                            value={afterSuccessSubtitle}
                            onChange={(e) => {
                              setAfterSuccessSubtitle(e.target.value);
                            }}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">Challenge description<span className='colorRed' >*</span></label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            maxLength="200"
                            value={afterSuccessDescription}
                            onChange={(e) => {
                              setAfterSuccessDescription(e.target.value);
                            }}
                          ></textarea>
                          <div className='d-flex justify-content-end color8A pt-1'>{afterSuccessDescription?.length}/200</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-end pe-5'>
                <label><span className='colorRed' >*</span> fields mandatory</label>
              </div>
            </CardContent >
            <CardFooter className="cardFooter d-flex justify-content-end" >
              <Button
                className=""
                color="primary"
                disabled={disablePublishChallenge()}
                onClick={handleUpdateChallenge}
              >
                SAVE CHANGES
              </Button>
              <Link to="/admin/challenges">
                <Button
                  color="secondary"
                >
                  Cancel
                </Button>
              </Link>
            </CardFooter>
          </>
        )}
      </Card >
    </>
  );
}

export default EditChallenge;