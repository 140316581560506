//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./FLNHome.module.css";
import { getHMService, getStudentService,getSuperAdminService } from "../../service/service";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  CircularProgress
} from "@material-ui/core";
import home from "assets/img/FLNSCren.png";
import GridItem from "components/Grid/GridItem";
import {
  ArrowBack,
  ArrowForward,
  DeleteOutline,
  HelpOutline,
} from "@material-ui/icons";
import Popup from "reactjs-popup";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Snackbar from "components/Snackbar/Snackbar";
import { Autocomplete } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "45%",
    marginTop: "35px",
  },
  cardFooter: {
    justifyContent: "flex-end",
  },
  list: {
    maxHeight: "80vh",
    overflowY: "auto",
  },
};

const classes = styles;
export default class FlnTransferTeacher extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    // trueContact: "",
    meetingsForm: [
      {
        teacher_name: "",
        phone: "",
        school: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
        JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
        trueContact: "",
      },
    ],
    emptyMeetings: {
      teacher_name: "",
      phone: "",
      school: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
      JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
      trueContact: "",
    },
    confirmModal: false,
    trucontactArrya: [],
    teacherList: null,
    offset: 0,
    statusLength: null,
    deleteId: null,
    alert: false,
    validateFlag:false,
    messageSnack:null,
    alertEdit:false,
    transferTeacherId:null,
    transferTeacherModal:false,
    transferTeacherAlert:false,
    district: {
        helperText: null,
        value: null,
      },
    block: {
        value: null,
        helperText: null,
    },
    school: {
        value: null,
        helperText: null,
    },
    isSetAuto:false,
    districtList: [],
    blocks: [],
    loading: false,
    districtValidation:false,
    blockValidation:false,
    schoolValidation:false,
    schoolId: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
    JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
    teacherPos:null,
    headMasterDetails:{},
};


  componentDidMount() {
    this.getTeacher();
    this.getData();
  }

  async getData() {
    await getSuperAdminService()
      .getStudentDistrict({
        student_state_id: 1,
      })
      .then((res) => {
        console.log(res, "Res");
        if (res.status == 200) {
          this.setState({
            districtList: res.data.response,
          });
        }
      });
  }

  getStudentBlock = async (district_id, singleSchoolFlag) => {
    await getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState(
            {
              blocks: response.response,
            },
            () => {
              // this.getSchools(this.state.block.value);
            }
          );
        } else {
          this.setState({
            blocks: [],
            schools: [],
            addBlocks: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = async (block, search) => {
    this.setState({
      searchText: { value: "", helperText: null },
    });

    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    await getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            schools: response.response,
            filteredSchools: response.response,
          });
        } else {
          this.setState({
            schools: [],
            filteredSchools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
          filteredSchools: [],
        });
      });
  };

  renderMemberTiming = () => {
    let { meetingsForm, smcDetails, isSMCDetailsPresent } = this.state;
    let form = [];

    meetingsForm.map((data, index) => {
      form.push(
        <div>
          <div
            style={{ display: "flex", padding: "5px" }}
            className={classes.committeeContainer}
            key={index}
          >
            <div
              style={{
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                minWidth: "100px",
                // marginTop: "10px",
                color: "#958989",
                textAlign: "center",
              }}
            >
              {" "}
              {index + 1}
            </div>

            <input
              style={{
                color: "#415EB6",
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                marginRight: "20px",
                marginLeft: "30px",
              }}
              placeholder="Name"
              value={data.teacher_name}
              className={classes.committeeAttendanceInput}
              onChange={(e) =>
                this.onValChangeMeetings("teacher_name", e, index)
              }
            />

            <input
              style={{
                color: "#415EB6",
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                marginRight: "20px",
                marginLeft: "30px",
              }}
              type="tel"
              min="0"
              maxLength="10"
              placeholder="Number"
              value={data.phone}
              className={classes.committeeAttendanceInput}
              onChange={(e) => this.onValChangeMeetings("phone", e, index)}
            />

            <input
              style={{
                color: "#415EB6",
                border: "0px",
                backgroundColor: "#DFE4E8",
                minWidth: "150px",
                height: "46px",
                marginRight: "20px",
                marginLeft: "30px",
              }}
              type="tel"
              min="0"
              maxLength="10"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              placeholder="Number"
              value={data.trueContact}
              className={classes.committeeAttendanceInput}
              onChange={(e) =>
                this.onValChangeMeetings("trueContact", e, index)
              }
            />

            <button
              style={{ border: "0px", backgroundColor: "transparent" }}
              onClick={() => this.removeMeetings(index)}
            >
              <DeleteOutline style={{ color: "red" }} />
            </button>
          </div>
          {console.log(data.trueContact === data.phone, "val")}
          {data.trueContact === data.phone ? null : (
            <div style={{ color: "red", fontSize: "10px" }}>
             मोबाईल नंबर दोन्ही रिकाम्या जागी अचूक लिहावे 
            </div>
          )}
        </div>
      );
    });
    let formWrap = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "20px",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "160px",
              color: "#958989",
            }}
          >
            शिक्षकांचे पूर्ण नाव
          </p>
          <p
            style={{
              textAlign: "center",
              width: "300px",
              color: "#958989",
            }}
          >
            शिक्षकांचा फोन क्रमांक
          </p>
          <p
            style={{
              textAlign: "center",
              width: "200px",
              color: "#958989",
            }}
          >
            फोन क्रमांक परत लिहा
          </p>
        </div>

        {form}

        <div className="d-flex flex-row align-items-center justify-content-center">
          <button
            style={{
              fontSize: "35px",
              border: "0px",
              backgroundColor: "transparent",
              marginRight: "20px",
              backgroundColor: "#c8ed91",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              height: "40px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
            onClick={() => this.addMeetings()}
          >
            +
            <span style={{ fontSize: "15px", marginLeft: "10px" }}>
              अजून एक क्रमांक नोंदवा
            </span>
          </button>
        </div>
      </div>
    );
    return formWrap;
  };

  addMeetings = () => {
    let emptyMeetings = JSON.stringify(this.state.emptyMeetings);
    let meetingsForm = [...this.state.meetingsForm];
    meetingsForm.push(JSON.parse(emptyMeetings));
    this.setState(
      {
        meetingsForm,
      },
      () => {
        console.log(this.state.meetingsForm, "meetingsform");
      }
    );
  };

  removeMeetings = (index) => {
    let meetingsForm = [...this.state.meetingsForm];
    if (meetingsForm.length > 1) {
      meetingsForm.splice(index, 1);
      this.setState({
        meetingsForm,
      });
    }
  };

  onValChangeMeetings = async (flag, event, index) => {
    let meetingsForm = [...this.state.meetingsForm];

    if (flag === "teacher_name") {
      meetingsForm[index].teacher_name = event.target.value;
    } else if (flag === "phone") {
      meetingsForm[index].phone = event.target.value;
    } else if (flag === "trueContact") {
      meetingsForm[index].trueContact = event.target.value;
    }

    this.setState(
      {
        meetingsForm,
      }
    );
  };
  validateNumber = () => {
   let array=[]
   this.state.meetingsForm.map((res)=>{
    console.log(res.phone,"res",res.trueContact)
    if(res.phone!==res.trueContact)
    {
      array.push(res)
    }
   })
   array.length > 0 ? this.setState({ confirmModal: false,}) : this.setState({ confirmModal: true,})
   console.log(array,"array")
  };

  submiTeacherDetails = () => {
    getHMService()
      .createFLNTeacherList(this.state.meetingsForm)
      .then((res) => {
        console.log(res, "res");
        if (res) {
          this.setState(
            {
              confirmModal: false,
              meetingsForm: [
                {
                  teacher_name: "",
                  phone: "",
                  school: JSON.parse(localStorage.getItem("HMSchool")) ?JSON.parse(localStorage.getItem("HMSchool")).school_id:
                  JSON.parse(localStorage.getItem('AMdetails'))?JSON.parse(localStorage.getItem('AMdetails')).school_id:null,
                  trueContact: "",
                },
              ],
            },
            () => {
              this.getTeacher();
            }
          );
        }
      })
      .catch((e)=>{
        console.log(e.response.data[0].phone,"response")
        this.setState({
          messageSnack : e.response?.data[0].phone
        })
        
      })
  };

  getTeacher = async () => {
    let data = await getHMService().getTeacherListDetails(this.state.schoolId,this.state.offset);
    console.log(data, "data");
    if (data.status == 200) {
      this.setState({
        teacherList: data.data.results,
        statusLength: data.data.count,
      });
    }
  };

  nextData = () => {
    let data = this.state.offset + 10;
    console.log(this.state.statusLength, "this.state.statusLength");
    if (data > this.state.statusLength) {
      return;
    } else {
      this.setState(
        {
          offset: this.state.offset + 10,
        },
        () => {
          this.getTeacher();
        }
      );
    }
  };

  deleteTeacher = (index) => {
    console.log(index, "index");
    let TeacherId = this.state.teacherList[this.state.deleteId].teacher_id;
    console.log(this.state.teacherList[this.state.deleteId].teacher_id, "list");
    getHMService()
      .deleteTeacher(TeacherId)
      .then((res) => {
        window.location.reload();
        console.log(res, "res");
      });
  };

  editTeacher =(index) =>{
    let TeacherId = this.state.teacherList[this.state.deleteId].teacher_id;
    console.log(this.state.teacherList[this.state.deleteId], "list");
    this.state.teacherList[this.state.deleteId].is_deleted = false
    console.log(this.state.teacherList[this.state.deleteId], "list12");
    getHMService()
    .editTeacher(this.state.teacherList[this.state.deleteId],TeacherId)
    .then((res) => {
      window.location.reload();
      console.log(res, "res");
    });
  }


  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };
  onChangeHandle = (val, value) => {
    console.log(val, value, "val from onChangeHandle");
    this.setState({
      school: val,
    });
  };

  getHMdetails = async (schoolID) =>{
    let data = await getHMService().getHeadmsterDetails(schoolID);
    let result=data?.data?.data
    this.setState({
      headMasterDetails:result
    })
  } 


  render() {
    let { stdWiseGraph ,district, block, school,} = this.state;

    let schoolDetails = JSON.parse(localStorage.getItem("HMSchool")) ? JSON.parse(localStorage.getItem("HMSchool")):
    JSON.parse(localStorage.getItem('AMdetails'))? JSON.parse(localStorage.getItem('AMdetails')):null 
    


    return (
      <>
        <AlertDialog
          open={this.state.alert}
          title="Delete?"
          text="Do you want to Delete this Teacher?"
          onCancel={() => this.setState({ alert: false })}
          onConfirm={() => {
            this.setState({ alert: false }, () => {
              this.deleteTeacher();
            });
          }}
        />
    
        <Dialog
          open={this.state.transferTeacherAlert}
        onClose={() => this.setState({ transferTeacherAlert: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Transfer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to Transfer the Teacher ?
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Teacher Name : {this.state?.teacherList?.[this.state?.teacherPos]?.teacher_name}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Teacher Number : {this.state?.teacherList?.[this.state?.teacherPos]?.phone}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Headmaster Name : {this.state.headMasterDetails?.head_master_name ? this.state.headMasterDetails?.head_master_name : "N/A"}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Headmaster Number : {this.state.headMasterDetails?.mobile ? this.state.headMasterDetails?.mobile : "N/A"}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           District : {this.state.districtList?.find((i) =>  i.student_district_id == this.state.district?.value )?.district_name }
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Block : {this.state.blocks?.find((i) =>  i.student_block_id == this.state.block?.value )?.block_name }
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           School Name : {this.state?.school?.school_name}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           School Udise : {this.state?.school?.u_dise}
          </DialogContentText>
        </DialogContent>
      
        <DialogActions>
          <Button 
          color="primary"
          onClick={() => {
          this.setState({ transferTeacherAlert: false })
          }} >
            No
          </Button>
          <Button 
          style={{ background:"#3f50b5", color:"white"}}
          onClick={() => {
            let param={
                teacher_id: this.state.transferTeacherId,
                school_id: this.state.school?.school_id,
                block_id: this.state.block?.value,
                district_id: this.state.district?.value
            }
            getHMService()
            .flnTransferTeacher(param)
            .then((res) => {
                console.log(res, "res");
                if (res) {
                    window.location.reload();
                }
            })
            .catch((e)=>{   
            })
          }}  autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
        <AlertDialog
          open={this.state.alertEdit}
          title="Activate?"
          text="Do you want to Activate this Teacher?"
          onCancel={() => this.setState({ alertEdit: false })}
          onConfirm={() => {
            this.setState({ alertEdit: false }, () => {
              this.editTeacher();
            });
          }}
        />
        <Snackbar
          autoHideDuration={4000}
          color={"success"}
          close={false}
          open={this.state.messageSnack}
          message={this.state.messageSnack}
          place="br"
          rtlActive={true}
          closeNotification={() => {
            this.setState({
              messageSnack: null,
            });
          }}
        />
             
        <Modal
        open={this.state.transferTeacherModal}
          onClose={() => {
            this.setState({
                transferTeacherModal: false,
                district: {
                    helperText: null,
                    value: null,
                },
                block: {
                    value: null,
                    helperText: null,
                },
                school: {
                    value: null,
                    helperText: null,
                },
          });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 900,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                marginBottom: "50px",
                textAlign: "center",
                color: "#415EB6",
              }}
            >
              Transfer your teacher from here
            </div>
            <div style={{ marginTop: "30px",display:"flex",flexDirection:"row",justifyContent:"center" }} className="row">
            <div className="col-4">
            <div style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
            }}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="District">
                  District*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="District"
                  id="District_dropdown"
                  value={district.value}
                  error={district.helperText}
                  helperText={!district.value && district.helperText}
                  onChange={(e) => {
                    console.log(e.target.value, "ee");
                    this.setState(
                      {
                        district: {
                          value: e.target.value,
                          helperText: null,
                        },
                        block: {
                          helperText: null,
                          value: "",
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        schools: [],
                        districtValidation:false,
                      },
                      () => {
                        this.getStudentBlock(this.state.district.value);
                      }
                    );
                  }}
                >
                  {this.state.districtList?.map((res) => {
                    return (
                      <MenuItem value={res.student_district_id}>
                        {res.district_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {this.state.districtValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
            </div>
          </div>

          <div className="col-4">
          <div style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
            }}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="Block">
                  Block*
                </span>
                <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="Block"
                  id="Block_dropdown"
                  value={block.value}
                  error={block.helperText}
                  helperText={!block.value && block.helperText}
                  onChange={(e) => {
                    this.setState(
                      {
                        block: {
                          value: e.target.value,
                          helperText: null,
                        },
                        school: {
                          helperText: null,
                          value: "",
                        },
                        searchText: { value: "", helperText: null },
                        blockValidation:false,
                      },
                      () => {
                        this.getSchools(this.state.block.value);
                      }
                    );
                  }}
                >
                  {this.state.blocks?.map((res) => {
                    return (
                      <MenuItem value={res.student_block_id}>
                        {res.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {this.state.blockValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
            </div>
          </div>

          <div className="col-4">
            <div style={{
                display: "flex",
                width: "70%",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "5px",
            }}>
              <FormControl fullWidth>
                <span style={{ color: "#415EB6" }} id="School">
                  School*
                </span>
                {/* <Select
                  style={{ backgroundColor: "#DFE4E8" }}
                  labelId="School"
                  id="School_dropdown"
                  value={school.value}
                  error={school.helperText}
                  helperText={!school.value && school.helperText}
                  onChange={(e) => {
                    this.setState({
                      school: {
                        value: e.target.value,
                        helperText: null,
                      },
                      updateschool: {
                        value: e.target.value,
                        helperText: null,
                      },
                    });
                  }}
                >
                  {this.state.schools?.map((res) => {
                    return (
                      <MenuItem value={res.school_id}>
                        {res.school_name}
                      </MenuItem>
                    );
                  })}
                </Select> */}

                <Autocomplete
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  style={{ width: "7em" }}
                  disabled={this.state.block.value ? false:true}
                  open={this.state.isSetAuto}
                  disableClearable
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, value) => {
                    this.setState({
                      school: {
                        value: value.school_id,
                        helperText: null,
                      },
                      updateschool: {
                        value: value.school_id,
                        helperText: null,
                      },
                      schoolValidation:false
                    },()=>{
                      this.getHMdetails(value.school_id)
                      this.onChangeHandle(value);
                    });
                    console.log("admin ID", value.school_id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.school_name} ${option.u_dise}`
                  }
                  options={this.state.schools}
                  loading={this.state.loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      labelId="School"
                      id="School_dropdown"
                      value={school.value}
                      error={school.helperText}
                      helperText={!school.value && school.helperText}
                      style={{ width: "10em", backgroundColor: "#DFE4E8" }}
                      onChange={(ev) => {
                        this.getSchools(
                          this.state.block.value,
                          ev.target.value
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              {this.state.schoolValidation ? (
              <div style={{ color: "red" }}>Required</div>
            ) : null}
            </div>
          </div>  
            </div>
            <div
              style={{  
                color: "#415EB6",
                marginTop:"6px"
              }}
            >
            Teacher Name : {this.state?.teacherList?.[this.state?.teacherPos]?.teacher_name}
            </div>
            <div
              style={{
                color: "#415EB6",
                marginTop:"6px"
              }}
            >
            District Name : {schoolDetails?.district}
            </div>
            <div
              style={{
                color: "#415EB6",
                marginTop:"6px"
              }}
            >
            Block Name : {schoolDetails?.block}
            </div>
            <div
              style={{
                color: "#415EB6",
                marginTop:"6px"
              }}
            >
            School Name : {schoolDetails?.school_name}
            </div>
            <Button
            style={{
              textAlign: "center",
              backgroundColor: "#7965DA",
              padding: "10px",
              width: "7%",
              borderRadius: "10px",
              color: "#fff",
              marginLeft: "15px",
              marginTop:"30px"
            }}
            onClick={() => {
                console.log("this.state.",this.state.school)

                if (
                this.state.district.value &&
                this.state.block.value &&
                this.state.school?.school_id
              ) {
                this.setState(
                  {
                    transferTeacherAlert: true,
                  }
                );
              } else {
                if (!this.state.block.value) {
                  this.setState({ blockValidation: true });
                }
                if (!this.state.district.value) {
                  this.setState({ districtValidation: true });
                }
                if (!this.state.school?.school_id) {
                  this.setState({ schoolValidation: true });
                }
              }
            }}
          >
            Submit
          </Button>
          </Box>
        </Modal>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginTop: "15px",
              // backgroundColor: "#DFE4E8",
              fontWeight: "bold",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "column",
                //   marginLeft: "18%",
              }}
            ></div>
            <div style={{ display: "flex", flexDirection: "row",width:"100%" }}>
              <div
                style={{
                  //   backgroundColor: "#DFE4E8",
                  padding: "10px",
                  color: "#4B66B9",
                  //   padding: "10px",
                }}
              >
                महत्वाच्या सूचना:
                <div style={{ padding: "20px" }}>
                  १. आपल्या शाळेतील सर्व विद्यार्थ्यांच्या अध्ययनस्तर निश्चितीची
                  माहिती व्हीस्कुल ॲप वरून अपलोड करण्यासाठी ज्या शिक्षकांची निवड
                  तुम्ही केली आहे त्यांची माहिती येथे भरायची आहे.
                </div>
              
              </div>
              <div>
                <img width="42%" src={home} style={{ marginBottom: "15px" }} />
              </div>
            </div>

       

         
            <div>
              <Card>
                <CardHeader color="primary">
                  <h4>यशस्वी नोंद झालेली अंतिम माहिती</h4>
                </CardHeader>
                <CardBody>
                  <div style={(styles.inputWrapper, styles.list)}>
                    <TableContainer>
                      <Table
                        //   className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">पूर्ण नाव</TableCell>
                            <TableCell align="center">मोबाईल क्रमांक</TableCell>
                            <TableCell align="center">Transfer Teacher</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {console.log(this.state.StudentList, "sta")}
                          {this.state.teacherList?.map((res, index) => {
                            return (
                              <TableRow>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  align="left"
                                >
                                  {res.teacher_name}
                                </TableCell>
                                <TableCell align="center">
                                  {res.phone}
                                </TableCell>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  align="center"
                                  onClick={() => {
                                    this.setState({
                                      transferTeacherId: res?.teacher_id,
                                      transferTeacherModal:true,
                                      teacherPos: index,
                                    });
                                  }}
                                >
                                  Transfer
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter style={{ display: "flex" }}>
                          <IconButton
                            onClick={() => {
                              this.setState(
                                {
                                  offset:
                                    this.state.offset > 0
                                      ? this.state.offset - 10
                                      : this.state.offset,
                                },
                                () => {
                                  this.getTeacher();
                                }
                              );
                            }}
                          >
                            <ArrowBack />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              this.nextData();
                            }}
                          >
                            <ArrowForward />
                          </IconButton>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}
