import axios from "axios";

import { BASE_URL } from "./config";

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    config.headers.Authorization = `${user.token}`;
  }
  return config;
});

http.interceptors.response.use((response) => {
  const { status, message } = response.data;

  if (status === 500 && message === "Authentication token invalid") {
    localStorage.clear();
    sessionStorage.setItem(
      "message",
      "Your session has been expired. Please login"
    );
    window.location.href = window.location.origin;
  }
  return response;
});

export default http;
