import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import MaterialTable from "material-table";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import FilterListIcon from "@material-ui/icons/FilterList";
import CardContent from "@material-ui/core/CardContent";

import { getSuperAdminService, getContentWriterService, getAdminService } from "service/service";
import * as constants from "./../../common/constants";

import cls from "./CheckingSection.module.css";

import filterIcon from "../../assets/img/filter_icon.svg";
import { Avatar, Badge, Dialog, DialogContent, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import axios from "axios"
import { Web_separate } from "common/config";
import Snackbar from "components/Snackbar/Snackbar";
import { CheckChapterStatusNew } from "service/WebSeparateService";
import { getChapterlistNew } from "service/WebSeparateService";
import httpV2 from "../../common/axiosV2"
import { getChapterStatus } from "service/WebSeparateService";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  filterContainer: {
    // width: "96%",
    margin: " 0 auto",
  },
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
  },
  filterActions: {
    justifyContent: "flex-end",
  }
};
export default class TableList extends React.Component {

  tableRef = React.createRef();
  state = {
    column: [],
    chapters: [],
    page: 1,
    stateList: [],
    boardList: [],
    districtList: [],
    classList: [],
    mediumList: [],
    subjectList: [],
    userList: [],
    checked: false,
    state: "",
    board: "",
    district: "",
    class: "",
    medium: "",
    subject: "",
    teacher: "",
    user: "",
    searchText: "",
    subjectStatus: "",
    statusList: [],
    queryObject: { searchText: "" },
    modalOpen : false,
    message : "",
    filterValues: {
      state: null,
      board: null,
      district: null,
      class: null,
      medium: null,
      subject: null,
      teacher: null,
      subjectStatus: "",
      modalOpen: false,
      message: false,
      Statuslist: []
    }
  };

  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  componentDidMount() {
    this.checkStatus()
    this.getStatusOfChapter()
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user", user);
    this.setState({
      teacher:
        user.user_type == constants.USER_TYPE.CONTENT_WRITER
          ? user.user_id
          : user.user_type == constants.USER_TYPE.ADMIN
            ? user.user_id : "",
      user: user,
      column: [
        {
          field: "chapter_id",
          title: "ID",
          editable: "never",
          filtering: false,
          sorting: true,
        },
        {
          field: "board_name",
          title: "Board",
          editable: "never",
          sorting: false,
        },
        {
          field: "district_name",
          title: "Syllabus set",
          editable: "never",
          sorting: false,
        },
        {
          field: "class_name",
          title: "Class",
          editable: "never",
          sorting: false,
        },
        {
          field: "medium_name",
          title: "Medium",
          editable: "never",
          sorting: false,
        },
        {
          field: "subject_name",
          title: "Subject",
          editable: "never",
          sorting: false,
        },
        {
          field: "chapter_name",
          title: "Lesson Name",
          editable: "never",
          sorting: false,
          filterComponent: (metaData) => {
            return (
              <input
                type="text"
                onBlur={(e) => {
                  console.log("E", e.target.value);
                  this.setState(
                    {
                      searchText: e.target.value,
                    },
                    () => {
                      this.tableRef.current.onQueryChange();
                    }
                  );
                }}
              />
            );
          },
        },
        {
          field: "chapter_no",
          title: "Chapter No.",
          editable: "never",
          sorting: true,
        },
        {
          field: "no_of_parts",
          title: "Total Parts",
          editable: "never",
          sorting: true,
        },
        ...user.user_type == constants.USER_TYPE.SUPER_ADMIN ? [{
          field: "chapter_created_by",
          title: "Teacher Name",
          editable: "never",
          sorting: false,
        }] : [],
        {
          field: "status",
          title: "Status",
          sorting: false,
          render: (rowData) => {
            return <div className={this.setChapterStatusColor(rowData)}><Typography className={cls.statusText}>{rowData.status}</Typography></div>;
          },
          editComponent: (props) => {
            return (
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={props.value}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
              >
                <MenuItem value={"IN-PROGRESS"}>INPROGRESS</MenuItem>
                <MenuItem value={"IN-REVIEW"}>IN REVIEW</MenuItem>
                <MenuItem value={"PUBLISHED"}>PUBLISHED</MenuItem>
                <MenuItem value={"CHANGES-REQUIRED"}>CHANGES REQUIRED</MenuItem>
              </Select>
            );
          },
        },
        {
          field: "status_change_by",
          title: "status changed by",
          editable: "never",
        },
      ],
      statusList: [{
        name: "In Progress",
        value: constants.CHAPTER_STATUS.IN_PROGRESS
      }, {
        name: "In Review",
        value: constants.CHAPTER_STATUS.IN_REVIEW
      }, {
        name: "Changes Required",
        value: constants.CHAPTER_STATUS.CHANGES_REQUIRED
      }, {
        name: "Published",
        value: constants.CHAPTER_STATUS.PUBLISHED
      }]
    });
    if (localStorage.getItem("filterPath") !== "" && localStorage.getItem("filterPath") === this.props.location.pathname) {
      if (localStorage.getItem("isFilterOpen") == "true") {
        this.setState({
          checked: true
        }, () => {
          this.getData();
          this.getTeacherList();
        })
      }
      let filterFromLocalStorage = localStorage.getItem("filterValues")
      if (filterFromLocalStorage) {
        this.setState({
          ...JSON.parse(filterFromLocalStorage),
          filterValues: { ...JSON.parse(filterFromLocalStorage) }
        }, () => {
          console.log(this.state.class, this.state.medium, "medium")
          this.getChapterChunks({
            pageSize: 10,
            page: 1
          })
        })
      }
    } else {
      localStorage.removeItem("filterValues")
      localStorage.removeItem("isFilterOpen")
      localStorage.removeItem("filterPath")
    }
  }

  checkStatus = () => {

    let reqIDarray = JSON.parse(localStorage.getItem("status"))
    console.log(reqIDarray);
    //   let param ={
    //   request_id : reqIDarray
    // }
    if (reqIDarray) {
      reqIDarray.map((response, index) => {
        console.log(index, "inde");
        let param = {
          request_id: response
        }

        // const http = axios.create({
        //   baseURL: Web_separate,
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Authorization" : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozODc0MiwiZXhwIjoxNjI2ODc5NjA1LCJpYXQiOjE2MjQyODc2MDV9.rMKcqdexK0m7OsucZdG039I-taPmhbsFxZLvkca5GLw"
        //   },
        // });
        CheckChapterStatusNew(param)
          .then((res) => {
            console.log(res.data, "data");
            this.setState({
              message: res?.data?.message?.message || ""
            })
            if (res?.data?.message?.status == "Success") {
              console.log(index, "inde");
              let arr = reqIDarray.filter(e => e !== response);
              console.log(arr, "arr");

              localStorage.setItem("status", JSON.stringify(arr))
            }
          })

      })
    }
  }
  searchByLessonName = (data) => {

    var searchText = data.target.value;
    console.log(searchText, "searchText")
    this.setState({
      searchText: searchText
    });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.tableRef.current.onQueryChange();
    }, 1000);
  }

  updateFilterVal(value) {
    if (localStorage.getItem("filterValues")) {
      let filterVal = JSON.parse(localStorage.getItem("filterValues"));
      filterVal[value] = this.state[value];
      localStorage.setItem("filterValues", JSON.stringify(filterVal))
    }
  }

  async getData() {
    let { chapter } = this.state;
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      chapter = { ...chapter, state_id: stateRes.response[0].state_id };
      const stateList = stateRes.response;
      this.setState(
        {
          stateList: stateList,
          chapter,
          state: stateList.length > 0 ? stateList[0].state_id : "",
        },
        () => {
          this.getBoardByState();
        }
      );
    } else {
      this.setState({
        stateList: [],
        state: null,
      });
    }
  }

  getBoardByState = async () => {
    let data = { state_id: this.state.state };
    let res = await getSuperAdminService().getAllBoardFromState(data);
    res = res.data;
    if (res && res.status == 200) {
      const boardList = res.response;
      this.setState(
        {
          boardList: boardList,
          board: this.state.filterValues.board || (boardList.length > 0 ? boardList[0].board_id : ""),
        },
        () => {
          this.getDistrictByBoard();
        }
      );
    } else {
      this.setState({
        boardList: [],
        board: "",
      });
    }

    let param = { state_id: this.state.state };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    mediumRes = mediumRes.data;
    if (mediumRes && mediumRes.status == 200) {
      const mediumList = mediumRes.response;
      this.setState({
        mediumList: mediumList,
        medium: this.state.filterValues.medium || (mediumList.length > 0 ? mediumList[0].medium_id : ""),
      });
    } else {
      this.setState({
        mediumList: [],
        medium: "",
      });
    }
  };

  getDistrictByBoard = async () => {
    let data = { board_id: this.state.board };
    let res = await getSuperAdminService().getAllDistrictFromBoard(data);
    res = res.data;
    if (res && res.status == 200) {
      const districtList = res.response;
      this.setState(
        {
          districtList: districtList,
          district: this.state.filterValues.district || (districtList.length > 0 ? districtList[0].district_id : ""),
        },
        () => {
          this.getClassesByDistrict();
        }
      );
    } else {
      this.setState({
        districtList: [],
        district: "",
      });
    }
  };

  getClassesByDistrict = async () => {
    let data = { district_id: this.state.district };
    let res = await getSuperAdminService().getAllClassesFromDistrict(data);
    res = res.data;
    if (res && res.status == 200) {
      const classList = res.response;
      this.setState(
        {
          classList: classList,
          class: this.state.filterValues.class || (classList.length > 0 ? classList[0].class_id : ""),
        },
        () => {
          this.getSubjectByClass();
        }
      );
    } else {
      this.setState({
        classList: [],
        class: "",
      });
    }
  };

  getSubjectByClass = () => {
    let apidata2 = {
      class_id: this.state.class,
      medium_id: this.state.medium,
    };
    getContentWriterService()
      .getSubjectByClassMed(apidata2)
      .then((res) => {
        res = res.data;
        if (res && res.status == 200) {
          const subjectList = res.response;
          this.setState({
            subjectList,
            subject: this.state.filterValues.subject || (subjectList.length > 0 ? subjectList[0].subject_id : ""),
          });
        } else {
          this.setState({
            subjectList: [],
          });
        }
      });
  };

  getTeacherList = async () => {
    if (this.state.userList.length > 0) return;
    let data = {
      page_number: 1,
      record_per_page: 50,
      order_column: "created_at",
      order_type: 1,
      user_type: "",
      searchText: "",
    };
    let allUserRes = await getSuperAdminService().getAllAdminContentWriterList(
      data
    );
    allUserRes = allUserRes.data;
    if (allUserRes && allUserRes.status == 200) {
      this.setState({
        userList: allUserRes.response.adminTeacherList,
      });
    } else {
      this.setState({
        userList: [],
      });
    }
  };

  getChapterChunks = (query) => {
    let isAdminChapter = false;
    if (this.props.location.pathname !== "/admin/chapters") {
      isAdminChapter = true
    } else {
      isAdminChapter = false
    }
    return new Promise(async (resolve, reject) => {
      let columnOrder;
      if (query.orderBy) {
        columnOrder = query.orderBy.field;
      }
      else {
        columnOrder = "updated_at";
      }

      let columnOrderDirection;
      if (query.orderDirection == "asc") {
        columnOrderDirection = 0;
      }
      else {
        columnOrderDirection = 1;
      }

      // let param = {
      //   board_id: this.state.board,
      //   district_id: this.state.district,
      //   class_id: this.state.class,
      //   medium_id: this.state.medium,
      //   subject_id: this.state.subject,
      //   user_id: isAdminChapter ? this.state.teacher : "",
      //   search:  this.state.searchText,
      //   // searchText: query.searchText || this.state.searchText,
      //   order_type: columnOrderDirection,
      //   order_column: columnOrder,
      //   record_per_page: query.pageSize,
      //   page_number: query.page + 1,
      //   status: this.state.subjectStatus || ""
      // };
      // console.log(param,"param")
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        console.log(user.user_type, "user");

        let allChapterRes;
        let res;
        let resStatus;


        console.log(query.pageSize, query.page);
        let isAdminChapter = false;
        if (this.props.location.pathname !== "/admin/chapters") {
          isAdminChapter = true
        } else {
          isAdminChapter = null
        }
        await httpV2.get(`chapters/`, {
          params: {
            limit: query.pageSize,
            offset: query.page * query.pageSize,
            state_id: this.state.state ? this.state.state : null,
            board_id: this.state.board ? this.state.board : null,
            district_id: this.state.district ? this.state.district : null,
            class_id: this.state.class ? this.state.class : null,
            medium_id: this.state.medium ? this.state.medium : null,
            subject_id: this.state.subject ? this.state.subject : null,
            teacher: this.state.teacher ? this.state.teacher : null,
            status: this.state.subjectStatus ? this.state.subjectStatus : null,
            own_chapters: isAdminChapter
          }
        }).then((resp) => {
          console.log(resp.data, "res");
          res = resp.data.results
          resStatus = resp
        })
        if (this.state.searchText) {
          let Count = await httpV2.get(`chapters/`, {
            params: {
              search: this.state.searchText,
            }
          });
          console.log(Count.data.results, "Res")
          res = Count.data.results
          resStatus = Count

        }
        if (true) {
          let data;

          if (query.filters.length > 0) {
            data = res.filter((c) => {
              const filter = query.filters[0];
              if (
                c[filter.column.field]
                  .toString()
                  .toLowerCase()
                  .includes(filter.value.toString().toLowerCase())
              ) {
                return true;
              } else {
                return false;
              }
            });
          } else {
            console.log(resStatus.data.count, "resStatus")
            data = res
            //data = allChapterRes.response.chapterDetails;
          }

          /*
          data.forEach((item, index) => {
            if (
              item.status == constants.CHAPTER_STATUS.IN_REVIEW ||
              item.status == constants.CHAPTER_STATUS.IN_PROGRESS
            ) {
              this.arraymove(data, index, 0);
            }
          });
          */

          resolve({
            data: data,
            page: query.page,
            totalCount: resStatus.data.count,
          });
        } else {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
        }
      } catch (error) {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
      }
    });
  };

  /*
  arraymove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };
  */

  handleChange = () => {
    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        this.getData();
        this.getTeacherList();
      }
    );
  };

  getStatusOfChapter = async () => {
    // this.setState({
    //   modalOpen : true
    //  })
    await getChapterStatus()
      .then((res) => {
        console.log(res.data.results);
        this.setState({
          Statuslist: res.data.results
        })
      })
  }
  renderStateMenu(type) {
    let {
      stateList,
      boardList,
      districtList,
      classList,
      mediumList,
      subjectList,
      userList,
      statusList
    } = this.state;
    let array =
      type == "state"
        ? stateList
        : type == "board"
          ? boardList
          : type == "district"
            ? districtList
            : type == "class"
              ? classList
              : type == "medium"
                ? mediumList
                : type == "user"
                  ? userList
                  : type == "subject"
                    ? subjectList
                    : statusList;
    return array.map((state, index) => {
      let value =
        type == "state"
          ? state.state_id
          : type == "board"
            ? state.board_id
            : type == "district"
              ? state.district_id
              : type == "class"
                ? state.class_id
                : type == "medium"
                  ? state.medium_id
                  : type == "user"
                    ? state.user_id
                    : type == "subject"
                      ? state.subject_id
                      : state.value;
      let name =
        type == "state"
          ? state.state_name
          : type == "board"
            ? state.board_name
            : type == "district"
              ? state.district_name
              : type == "class"
                ? state.class_name
                : type == "medium"
                  ? state.medium_name
                  : type == "user"
                    ? `${state.first_name} ${state.last_name}`
                    : type == "subject"
                      ? state.subject_name
                      : state.name;
      return (
        <MenuItem key={index + "state"} value={value}>
          {`${name}`.toString().charAt(0).toUpperCase() + `${name}`.slice(1)}
        </MenuItem>
      );
    });
  }


  clearFilter = () => {
    this.setState(
      {
        state: "",
        board: "",
        district: "",
        class: "",
        medium: "",
        subject: "",
        teacher: "",
        subjectStatus: "",
        filterValues: {
          state: null,
          board: null,
          district: null,
          class: null,
          medium: null,
          subject: null,
          teacher: null,
          subjectStatus: ""
        }
      },
      () => {
        this.tableRef.current.onQueryChange();
        localStorage.removeItem("filterValues")
        localStorage.removeItem("isFilterOpen")
        localStorage.removeItem("filterPath")
      }
    );
  };

  updateChapterStatus = (chapter) => {
    // let param = {
    //   chapter_id: chapter.chapter_id,
    //   status: chapter.status,
    // };
    return new Promise(async (res, rej) => {
      // let updateStatusRes = await getSuperAdminService().updateChapterStatus(
      //   param
      // );
      let status = chapter.status
      let updateStatusRes = await httpV2.post(`chapters/${chapter.chapter_id}/change_chapter_status/`,
        ({
          status,
        })
      );
      updateStatusRes = updateStatusRes.data;
      if (updateStatusRes && updateStatusRes.status === 200) {
        res(true);
      } else {
        res(false);
      }
    });
  };

  setChapterStatusColor = (chapter) => {
    if (chapter.status === constants.CHAPTER_STATUS.PUBLISHED) {
      return cls.published;
    } else if (chapter.status === constants.CHAPTER_STATUS.IN_REVIEW) {
      return cls.inReview;
    } else if (chapter.status === constants.CHAPTER_STATUS.CHANGES_REQUIRED) {
      return cls.changesRequired;
    } else if (chapter.status === constants.CHAPTER_STATUS.IN_PROGRESS) {
      return cls.inProgress;
    }
    return {};
  };

  handleRowClick = (event, rowData) => {
    this.props.history.push(`chapter/preview/${rowData.chapter_id}`, {
      chapter: rowData,
    });
  };

  render() {

    const classes = styles;
    const { chapter, user } = this.state;
    return (
      <>
        <Snackbar
          autoHideDuration={5000}
          message={this.state.message}
          color={"success"}
          close={false}
          place="br"
          closeNotification={() =>
            this.setState({
              message: "",
            })
          }
          rtlActive={true}
          open={this.state.message ? true : false}
        ></Snackbar>
        <Dialog
          maxWidth={'md'}
          //fullWidth={true}
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({
              modalOpen: false,
            });
          }}
          aria-labelledby="form-dialog-title"
        >

          <DialogContent>
            {this.state.Statuslist && this.state.Statuslist.length > 0 ?
              this.state.Statuslist.map((res, index) => {
                return (
                  <Card key={index}
                  >
                    <List
                      style={{
                        borderBottom: "1px solid black"
                      }} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}>
                      <ListItem>
                        <ListItemText primary="Chapter Name :" secondary="status :" />
                        <ListItemText primary={res.name} secondary={res.status} />
                      </ListItem>
                    </List>
                  </Card>
                )

              }) : "No requests....."}


          </DialogContent>
          <Button
            onClick={() => {
              this.setState({
                modalOpen: false
              })
            }}>Close</Button>
        </Dialog>
        <GridItem xs={12} sm={12} md={12}>
          <div className="row">
            <div className="col-8">
              <h4 className={classes.cardTitleWhite}>Chapter List</h4>
              <span className={classes.cardCategoryWhite}>
                {JSON.parse(localStorage.getItem("user")).user_type ==
                  constants.USER_TYPE.SUPER_ADMIN
                  ? "Manage all chapters created by Admin or Content Writer"
                  : "Manage all your chapters"}
              </span>
            </div>
            <div className="col-4">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-10">
                  <div className={cls.wrap}>
                    <div className={cls.search}>
                      <input type="text" className={cls.searchTerm} placeholder="Search by lesson name"
                        value={this.state.searchText} onChange={(e) => this.searchByLessonName(e)} />
                      <button type="submit" className={cls.searchButton}>
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-2">
                  <IconButton
                    variant={this.state.checked ? "contained" : "outlined"}
                    onClick={() => this.handleChange()}
                    aria-label="filter"
                    title="filter"
                    color={this.state.checked ? "primary" : "secondary"}
                    style={{ background: this.state.checked ? "#3B86FF 0% 0% no-repeat padding-box" : "" }}
                  >
                    {/* <FilterListIcon /> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" className="bi bi-funnel" viewBox="0 0 16 16" stroke="black" strokeWidth="0">
                      <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" className={cls.filterIcon} />
                    </svg> */}
                    {!this.state.checked ? (<div className={[cls.filterIcon, cls.filterOriginal].join(" ")}></div>) : (<div className={[cls.filterIcon, cls.filterOpened].join(" ")}></div>)}
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginLeft: "1%"
            }}>
            <Badge badgeContent={this.state.Statuslist ? this.state.Statuslist.length : 4} color="primary">
              <Mail color="action" onClick={() => {
                //this.getStatusOfChapter()
                this.setState({
                  modalOpen: true
                })
              }} />
            </Badge>
          </div>
          <div xs={12} sm={12} md={12} style={classes.filterContainer}>
            <Collapse in={this.state.checked}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <div style={styles.inputWrapper}>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.state}
                        onChange={(e) => {
                          this.setState(
                            {
                              state: e.target.value,
                            },
                            () => {
                              this.updateFilterVal("state");
                              this.getBoardByState(e);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("state")}
                      </Select>
                    </FormControl>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">Select Board</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label1"
                        id="demo-controlled-open-select1"
                        value={this.state.board}
                        onChange={(e) => {
                          this.setState(
                            {
                              board: e.target.value,
                            },
                            () => {
                              this.updateFilterVal("board");
                              this.getDistrictByBoard();
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("board")}
                      </Select>
                    </FormControl>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select Syllabus
                    </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.district}
                        onChange={(e) => {
                          this.setState(
                            {
                              district: e.target.value,
                            },
                            () => {
                              this.updateFilterVal("district");
                              this.getClassesByDistrict(e);
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("district")}
                      </Select>
                    </FormControl>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select Medium
                    </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.medium}
                        onChange={(e) => {
                          this.setState(
                            {
                              medium: e.target.value,
                            },
                            () => {
                              this.updateFilterVal("medium");
                              this.getSubjectByClass();
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("medium")}
                      </Select>
                    </FormControl>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.class}
                        onChange={(e) => {
                          this.setState(
                            {
                              class: e.target.value,
                            },
                            () => {
                              this.updateFilterVal("class");
                              this.getSubjectByClass();
                            }
                          );
                        }}
                      >
                        {this.renderStateMenu("class")}
                      </Select>
                    </FormControl>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select Subject
                    </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.subject}
                        onChange={(e) => {
                          this.setState({
                            subject: e.target.value,
                          }, () => {
                            this.updateFilterVal("subject");
                          });
                        }}
                      >
                        {this.renderStateMenu("subject")}
                      </Select>
                    </FormControl>
                    <FormControl style={classes.dropdown}>
                      <InputLabel id="demo-simple-select-label">
                        Select Status
                    </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label2"
                        id="demo-controlled-open-select2"
                        value={this.state.subjectStatus}
                        onChange={(e) => {
                          this.setState({
                            subjectStatus: e.target.value,
                          }, () => {
                            this.updateFilterVal("subjectStatus");
                          });
                        }}
                      >
                        {this.renderStateMenu("subjectStatus")}
                      </Select>
                    </FormControl>
                    {user.user_type == constants.USER_TYPE.SUPER_ADMIN && (
                      <FormControl style={classes.dropdown}>
                        <InputLabel id="demo-simple-select-label">
                          Teacher
                      </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label2"
                          id="demo-controlled-open-select2"
                          value={this.state.teacher}
                          onChange={(e) => {
                            this.setState({
                              teacher: e.target.value,
                            }, () => {
                              this.updateFilterVal("teacher");
                            });
                          }}
                        >
                          {this.renderStateMenu("user")}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                </CardContent>
                <CardActions style={classes.filterActions}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      this.setState({ checked: false }, () => {
                        this.clearFilter();
                      })
                    }
                  >
                    Cancel
                </Button>
                  {/* <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => this.clearFilter()}
                  >
                    Clear Filter
                </Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState(
                        {
                          page: 1,
                        },
                        () => {
                          this.tableRef.current.onQueryChange();
                          let filterObj = {
                            state: this.state.state,
                            board: this.state.board,
                            district: this.state.district,
                            class: this.state.class,
                            medium: this.state.medium,
                            subject: this.state.subject,
                            teacher: this.state.teacher,
                            status: this.state.subjectStatus
                          }
                          this.getChapterChunks({
                            pageSize: 10,
                            page: 1
                          })
                          localStorage.setItem("filterValues", JSON.stringify(filterObj))
                          localStorage.setItem("isFilterOpen", "true")
                          localStorage.setItem("filterPath", this.props.location.pathname)
                        }
                      );
                    }}
                  >
                    Apply
                </Button>
                </CardActions>
              </Card>
            </Collapse>
          </div>

          <Card>
            {/* <CardHeader color="primary"> */}

            {/* </CardHeader> */}
            <div>
              <MaterialTable
                onRowClick={this.handleRowClick}
                title={""}
                tableRef={this.tableRef}
                columns={this.state.column}
                data={(query) => {
                  console.log("QUERY---", query, this.tableRef);
                  return this.getChapterChunks(query);
                }}
                actions={[
                  {
                    icon: "remove_red_eye",
                    tooltip: "Preview Chapter",
                    onClick: (event, rowData) => {
                      this.props.history.push(
                        `chapter/preview/${rowData.chapter_id}`,
                        { chapter: rowData }
                      );
                    },
                  },
                ]}
                editable={{
                  onRowUpdate: (newData) => {
                    return this.updateChapterStatus(newData);
                  },
                  isEditHidden: (rowData) => {
                    let user = JSON.parse(localStorage.getItem("user"));
                    return user.user_type == constants.USER_TYPE.SUPER_ADMIN
                      ? false
                      : true;
                  },
                }}
                options={{
                  sorting: true,
                  search: false,
                  actionsColumnIndex: -1,
                  filtering: false,
                  pageSize: 10,

                  // rowStyle: (rowData) => {
                  //   return this.setChapterStatusColor(rowData);
                  // },
                }}
                localization={{
                  toolbar: { searchPlaceholder: "Search by lesson name" },
                }}
              />
            </div>
          </Card>
        </GridItem>
      </>
    );
  }
}
