import http from "./../common/axios";

export default class ContentWriterService {
  getAdminCWstates(data) {
    return http.get(`/getCWstates/`);
  }

  getAdminCWBoard(data) {
    return http.post(`/getCWboards/`, data);
  }

  getAdminCWdistrict(data) {
    return http.post(`/getCWdistricts/`, data);
  }

  getAdminCWclasses(data) {
    return http.post(`/getCWclasses/`, data);
  }

  getMediumbyState(data) {
    return http.post(`/getMediumByState/`, data);
  }

  getSubjectByClassMed(data) {
    return http.post(`/getAllSubjectByClassMed/`, data);
  }

  createChapterAndPartsForCWAdmin(data) {
    return http.post(`/createChapterAndParts/`, data);
  }

  createChapterContentId(data) {
    return http.post(`/createContentId/`, data);
  }

  createChapterContentIdNew(data) {
    return http.post(`vr/createcontentid/`, data);
  }

  uploadChapterContent(data) {
    return http.post(`/uploadContent/`, data);
  }

  uploadChapterContentCDN(data) {
    return http.post(`/v2/uploadcontent/`, data);
  }

  uploadChapterMedia(data, cb) {
    return http.post(`/uploadMedia/`, data, {
      onUploadProgress: (progressEvent) => {
        cb(progressEvent);
      },
    });
  }

  getChapters(data) {
    return http.post(`/getMychaptersCW/`, data);
  }

  getChapterComments(data){
    return http.post(`/getchaptercomments/`,data);
  }

  postChapterComment(data){
    return http.post(`/addcomment/`,data);
  }
}
