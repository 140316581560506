import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

const ImageSlider = ({ height, width, images }) => {
  return (
    <div>
      <SimpleImageSlider
        width={width}
        height={height}
        images={images}
        showNavs={true}
        showBullets={true}
        useGPURender={true}
        navStyle={2}
      />
    </div>
  );
};

export default ImageSlider;
