//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
import classes from "./VSAnlytics.module.css";
import {
  getHMService,
  getStudentService,
  getSuperAdminService,
  getContentWriterService
} from "../../service/service";

import { Button, TextField, CircularProgress, Input } from "@material-ui/core";
import home from "assets/img/FLN1.png";
import logo from "assets/img/FLN2.png";
import MaterialTable from "material-table";
import DatePicker from "react-date-picker";
import * as moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const data = [
  "मराठी वाचन",
  "इंग्रजी वाचन",
  "गणित",
  "मराठी श्रुतलेखन",
  "इंग्रजी श्रुतलेखन",
];
const data1 = ["मराठी वाचन", "इंग्रजी वाचन", "गणित", "मराठी श्रुतलेखन"];

const styles = {
  inputWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  dropdown: {
    width: "30%",
    marginTop: "35px",
    padding: "8px 8px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
    districtTab: true,
    userTab: false,
    blockTab: false,
    schoolTab: false,
    blocks: [],
    schools: [],
    districts: [],
    selectedDistrict: null,
    selectedBlock: null,
    selectedSchool: null,
    selectedUser: null,
    selectedTimeSpan: null,
    selectedUsageType: null,
    selectedDate: null,
    startDate: "",
    page: 0,
    showDistrictLevelData: false,
    showBlockLevelData: false,
    showSchoolLevelData: false,
    showUserLevelData: false,
    data: [],
    query: null,
    isSetAuto: false,
    offsetPage: 0,
    userLevelData: [],
    userLevelStudents: null,
    searchText: null,
    searchPhone: null,
    showSearchBar: false,
    endDate:"",
    MediumList:[],
    mediumSelected: 10
  };

  componentDidMount() {
    this.getDistrictsByState();
    this.getAllMedium();
  }

  getAllMedium = async () => {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    console.log(mediumRes.data.response, "mediumRes");
    let mediumArr = mediumRes.data.response
    mediumArr.unshift({
      medium_id: 10, medium_name: 'ALL', state_id: 1
    })
    this.setState({
      MediumList: mediumArr,
    });
  };

  getDistrictsByState = (state_id) => {
    getSuperAdminService()
      .getStudentDistrict({
        student_state_id: 1,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            districts: response.response,
          });
        } else {
          this.setState({
            districts: [],
            blocks: [],
            schools: [],
          });
        }
      });
  };

  getStudentBlock = (district_id) => {
    getSuperAdminService()
      .getStudentBlock({
        student_district_id: district_id ? district_id : 1,
      })
      .then((result) => {
        const response = result.data;
        if (response && response.status == 200) {
          this.setState({
            blocks: response.response,
            schools: [],
          });
        } else {
          this.setState({
            blocks: [],
            schools: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  getSchools = (block, search) => {
    const param = {
      student_block_id: block,
      searchText: search ? search : "",
    };
    getSuperAdminService()
      .getSchoolsForBlock(param)
      .then((result) => {
        result = result.data;
        console.log(result, "school");
        if (result && result.status == 200) {
          this.setState({
            schools: result.response,
          });
        } else {
          this.setState({
            schools: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          schools: [],
        });
      });
  };

  renderSelectionInfo = () => {
    console.log("name", this.state);

    return (
      <>
        <div style={{ display: "flex" }}>
          <div className="col-4">
            {this.state.districtTab != true ? (
              <div style={styles.inputWrapper}>
                <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Select District
                    {this.state.userTab == true ? null : "*"}
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.selectedDistrict}
                    onChange={(e) => {
                      console.log(e.target.value, "eee");
                      this.setState(
                        {
                          selectedDistrict: e.target.value,
                          showDistrictLevelData: false,
                          showBlockLevelData: false,
                          showSchoolLevelData: false,
                          showUserLevelData: false,
                          page: 0,
                          selectedBlock: null,
                          selectedSchool: null,
                          query: null,
                          offsetPage: 0,
                        },
                        () => {
                          this.getStudentBlock(this.state.selectedDistrict);
                        }
                      );
                    }}
                  >
                    {console.log(this.state.districts, "district")}
                    {this.state.districts?.map((res) => {
                      return (
                        <MenuItem value={res.student_district_id}>
                          {res.district_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          </div>

          <div className="col-4">
            {this.state.districtTab != true && this.state.blockTab != true ? (
              <div style={styles.inputWrapper}>
                <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Select block
                    {this.state.schoolTab == true || this.state.blockTab == true
                      ? "*"
                      : null}
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.selectedBlock}
                    onChange={(e) => {
                      this.setState(
                        {
                          selectedBlock: e.target.value,
                          showDistrictLevelData: false,
                          showBlockLevelData: false,
                          showSchoolLevelData: false,
                          showUserLevelData: false,
                          page: 0,
                          selectedSchool: null,
                          query: null,
                          offsetPage: 0,
                        },
                        () => {
                          this.getSchools(this.state.selectedBlock);
                        }
                      );
                    }}
                  >
                    {this.state.blocks?.map((res) => {
                      return (
                        <MenuItem value={res.student_block_id}>
                          {res.block_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          </div>

          <div className="col-4">
            {this.state.districtTab != true &&
            this.state.blockTab != true &&
            this.state.schoolTab != true ? (
              <div style={styles.inputWrapper}>
                {/* <FormControl style={styles.dropdown}>
                  <InputLabel id="demo-simple-select-label">
                    Select School
                    {this.state.schoolTab == true ? "*" : null}
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    value={this.state.selectedSchool}
                    onChange={(e) => {
                      console.log(e.target.value, "eee");
                      this.setState(
                        {
                          selectedSchool: e.target.value,
                          showDistrictLevelData: false,
                          showBlockLevelData: false,
                          showSchoolLevelData: false,
                          showUserLevelData: false,
                          page: 0,
                          query: null,
                          offsetPage: 0,
                        },
                        () => {
                          // this.getStudentBlock(this.state.selectedDistrict);
                        }
                      );
                    }}
                  >
                    {console.log(this.state.districts, "district")}
                    {this.state.schools?.map((res) => {
                      return (
                        <MenuItem value={res.school_id}>
                          {res.school_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <FormControl fullWidth style={{ marginTop: "40px" }}>
                  <span style={{ color: "grey", fontSize: "13px" }} id="School">
                    Select School*
                  </span>
                  <Autocomplete
                    labelId="demo-controlled-open-select-label2"
                    id="demo-controlled-open-select2"
                    style={{ width: "7em" }}
                    open={this.state.isSetAuto}
                    disableClearable
                    disabled={this.state.selectedBlock ? false : true}
                    onOpen={() => {
                      this.setOpen(true);
                    }}
                    onClose={() => {
                      this.setOpen(false);
                    }}
                    onChange={(e, value) => {
                      console.log("Fasdfaf", value);
                      this.setState({
                        selectedSchool: value.school_id,
                        showDistrictLevelData: false,
                        showBlockLevelData: false,
                        showSchoolLevelData: false,
                        showUserLevelData: false,
                        page: 0,
                        query: null,
                        offsetPage: 0,
                      });
                      // this.onChangeHandle(value);
                      console.log("admin ID", value.school_id);
                    }}
                    getOptionLabel={(option) =>
                      `${option.school_name} ${option.u_dise}`
                    }
                    options={this.state.schools}
                    loading={this.state.loading}
                    renderInput={(params) => (
                      <TextField
                        type="text"
                        {...params}
                        labelId="School"
                        id="School_dropdown"
                        style={{ width: "10em", backgroundColor: "#fff" }}
                        onChange={(ev) => {
                          this.getSchools(
                            this.state.selectedBlock,
                            ev.target.value
                          );
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="col-4">
            <div style={styles.inputWrapper}>
              <FormControl style={styles.dropdown}>
                <InputLabel id="demo-simple-select-label">
                  Select user type*
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedUser}
                  onChange={(e) => {
                    console.log(e.target.value, "eee");
                    this.setState({
                      selectedUser: e.target.value,
                      showDistrictLevelData: false,
                      showBlockLevelData: false,
                      showSchoolLevelData: false,
                      showUserLevelData: false,
                      page: 0,
                      query: null,
                      offsetPage: 0,
                    });
                  }}
                >
                  <MenuItem value={"Student"}>Student</MenuItem>
                  <MenuItem value={"Teacher"}>Teacher</MenuItem>
                  <MenuItem value={"All"}>All</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-4">
            <div style={styles.inputWrapper}>
              <FormControl style={styles.dropdown}>
                <InputLabel id="demo-simple-select-label">
                  Time Span*
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label2"
                  id="demo-controlled-open-select2"
                  value={this.state.selectedTimeSpan}
                  onChange={(e) => {
                    console.log(e.target.value, "eee");
                    this.setState({
                      selectedTimeSpan: e.target.value,
                      selectedDate: null,
                      startDate: "",
                      page: 0,
                      showDistrictLevelData: false,
                      showBlockLevelData: false,
                      showSchoolLevelData: false,
                      showUserLevelData: false,
                      query: null,
                      offsetPage: 0,
                    });
                  }}
                >
                  {/* <MenuItem value={"Life"}>Life time</MenuItem> */}
                  <MenuItem value={"Week"}>Weekly</MenuItem>
                  <MenuItem value={"Month"}>Monthly</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="col-4">
            {this.state.selectedTimeSpan != null &&
            this.state.selectedTimeSpan != "Life" ? (
              <>
              <div style={{ marginTop: "40px" }}>
                <DatePicker
                  format="y-MM-dd"
                  value={this.state.selectedDate}
                  maxDate={new Date()}
                  onChange={(e) => {
                    if (this.state.selectedTimeSpan == "Week") {
                      let stringdate = new Date(e);

                      var myIsoWeekDayMonday = 1;
                      var startOfPeriod = moment(stringdate);
                      var daysToSubtract =
                        moment(startOfPeriod).isoWeekday() >= myIsoWeekDayMonday
                          ? moment(startOfPeriod).isoWeekday() -
                            myIsoWeekDayMonday
                          : 7 +
                            moment(startOfPeriod).isoWeekday() -
                            myIsoWeekDayMonday;
                      var mondayDate = moment(startOfPeriod).subtract(
                        "d",
                        daysToSubtract
                      );

                      let sundayDate = moment(mondayDate.format("YYYY-MM-DD")).add(6,"days")
                      let Stringdate = new Date(e).toISOString();
                      let filterDate = Stringdate.replace("T", " ");
                      let data = filterDate.replace("Z", "0");
                      console.log(data, "Date is ");
                      this.setState({
                        selectedDate: e,
                        startDate: mondayDate.format("YYYY-MM-DD"),
                        showDistrictLevelData: false,
                        showBlockLevelData: false,
                        showSchoolLevelData: false,
                        showUserLevelData: false,
                        page: 0,
                        query: null,
                        offsetPage: 0,
                        endDate:sundayDate.format("YYYY-MM-DD")
                      });
                    } else if (this.state.selectedTimeSpan == "Month") {
                      let stringdate = new Date(e);

                      const startOfMonth = moment(e).startOf("month");
                      const endOfMonth =  moment(e).endOf('month');

                      let Stringdate = new Date(e).toISOString();
                      let filterDate = Stringdate.replace("T", " ");
                      let data = filterDate.replace("Z", "0");
                      console.log(data, "Date is ");
                      this.setState({
                        selectedDate: e,
                        startDate: startOfMonth.format("YYYY-MM-DD"),
                        showDistrictLevelData: false,
                        showBlockLevelData: false,
                        showSchoolLevelData: false,
                        showUserLevelData: false,
                        page: 0,
                        query: null,
                        offsetPage: 0,
                        endDate:endOfMonth.format("YYYY-MM-DD")
                      });
                    }
                  }}
                />
              </div>
             <div style={{
              marginTop:"10px"
             }} >
              {
                (this.state.startDate != "Invalid date" && this.state.startDate != "" && this.state.startDate != "1969-12-29" )  ?  <>{ this.state.startDate} to {this.state.endDate}</>: null
              }
  
             </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="col-4">
          <div style={styles.inputWrapper}>
            <FormControl style={styles.dropdown}>
              <InputLabel id="demo-simple-select-label">
                Type of Usage*
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                value={this.state.selectedUsageType}
                onChange={(e) => {
                  console.log(e.target.value, "eee");
                  this.setState({
                    selectedUsageType: e.target.value,
                    showDistrictLevelData: false,
                    showBlockLevelData: false,
                    showSchoolLevelData: false,
                    showUserLevelData: false,
                    page: 0,
                    query: null,
                    offsetPage: 0,
                  });
                }}
              >
                <MenuItem value={"Mobile"}>Mobile App Usage</MenuItem>
                <MenuItem value={"Web"}>Web App usage</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        {
          this.state.districtTab == true || this.state.blockTab == true ? (
            <div className="col-4">
            <div style={styles.inputWrapper}>
              <FormControl style={styles.dropdown}>
                <InputLabel id="demo-simple-select-label">
                        Medium*
                      </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label2"
                id="demo-controlled-open-select2"
                  value={this.state.mediumSelected}
                  onChange={(e) => {
                    this.setState(
                      {
                        mediumSelected: e.target.value,
                        showDistrictLevelData: false,
                        showBlockLevelData: false,
                        showSchoolLevelData: false,
                        showUserLevelData: false,
                        page: 0,
                        query: null,
                        offsetPage: 0,
                      },
                    );
                  }}
                >
                  {this.state.MediumList?.map((med) => {
                    return (
                      <MenuItem value={med.medium_id} key={med.medium_id}>
                        {med.medium_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          ) : null
        }
      </>
    );
  };

  setOpen = (val) => {
    this.setState({
      isSetAuto: val,
    });
  };

  getBlockMaterialData = (query) => {
    return new Promise(async (resolve, reject) => {
      if (
        this.state.selectedDistrict != null &&
        this.state.selectedUser != null &&
        this.state.selectedUsageType != null &&
        this.state.selectedTimeSpan != null
      ) {
        if (
          this.state.selectedTimeSpan != "Life" &&
          this.state.selectedDate != null
        ) {
          if (this.state.selectedUsageType == "Mobile") {
            if (this.state.selectedTimeSpan == "Week") {
              let param = {
                student_district_id: this.state.selectedDistrict,
                week_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
              };

              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }

              if(this.state.mediumSelected != 10){
                param["medium_id"] = this.state.mediumSelected
               }


              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseAppTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            } else {
              let param = {
                student_district_id: this.state.selectedDistrict,
                month_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
              };

              if(this.state.mediumSelected != 10){
                param["medium_id"] = this.state.mediumSelected
               }


              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseAppTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            }
          } else {
            if (this.state.selectedTimeSpan == "Week") {
              let param = {
                student_district_id: this.state.selectedDistrict,
                week_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
              };

              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }

              if(this.state.mediumSelected != 10){
                param["medium_id"] = this.state.mediumSelected
               }


              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseWebTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            } else {
              let param = {
                student_district_id: this.state.selectedDistrict,
                month_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
              };

              if(this.state.mediumSelected != 10){
                param["medium_id"] = this.state.mediumSelected
               }

              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseWebTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            }
          }
        } else if (this.state.selectedTimeSpan == "Life") {
          if (this.state.selectedUsageType == "Mobile") {
            let param = {
              student_district_id: this.state.selectedDistrict,
              excel_download: false,
              page_number: query ? query.page + 1 : 1,
              record_per_page: query ? query.pageSize : 1,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            console.log(param, "params");

            getSuperAdminService()
              .vsidataBlockwiseAppLifeTime(param)
              .then((result) => {
                if (result) {
                  console.log("result ", result);

                  resolve({
                    data: result.data.data,
                    page: query ? query.page : 1,
                    totalCount: result.data.count,
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          } else {
            let param = {
              student_district_id: this.state.selectedDistrict,
              excel_download: false,
              page_number: query ? query.page + 1 : 1,
              record_per_page: query ? query.pageSize : 1,
            };

            if(this.state.mediumSelected != 10){
              param["medium_id"] = this.state.mediumSelected
             }


            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }
            console.log(param, "params");

            getSuperAdminService()
              .vsidataBlockwiseWebLifeTime(param)
              .then((result) => {
                if (result) {
                  resolve({
                    data: result.data.data,
                    page: query ? query.page : 1,
                    totalCount: result.data.count,
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          }
        }
        this.setState({
          page: query ? query.page : 1,
          query: query,
        });
      }
    });
  };

  districtInfo = () => {
    return (
      <>
        <Card
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          {this.renderSelectionInfo()}
          <div>
            <Button
              onClick={this.getDataDistrictWise}
              style={{
                padding: "10px",
                backgroundColor: "#7965DA",
                color: "#fff",
                marginTop: "30px",
              }}
            >
              Search
            </Button>
          </div>
        </Card>
        {this.state.showDistrictLevelData == true ? (
          <div style={{ width: "100%" }}>
            <MaterialTable
              title={""}
              columns={this.getDistrictWiseTableData()}
              data={(query) => {
                return this.getDistrictMaterialData(query);
              }}
              options={{
                search: true,
                actionsColumnIndex: -1,
                pageSize: this.state.query ? this.state.query.pageSize : 10,
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={this.downloadCSVDistrictLevel}
                style={{
                  padding: "10px",
                  backgroundColor: "#7965DA",
                  color: "#fff",
                  marginTop: "30px",
                  marginBottom: "100px",
                  borderRadius: "10px",
                }}
              >
                Download List
              </Button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  getBlockWiseTableData = () => {
    let arr = [];

    console.log(this.state, "tell me the state");

    arr.push({
      title: "District Name",
      field: "district_name",
      editable: "never",
    });
    arr.push({ title: "Block Name", field: "block_name", editable: "never" });

    arr.push({
      title: "User",
      render: (rowData) => {
        return <p>{this.state.selectedUser}</p>;
      },
      editable: "never",
    });
    arr.push({
      title: "Total Usage",
      render: (rowData) => {
        return <p>{rowData.total_time} Min</p>;
      },
      editable: "never",
    });
    arr.push({
      title: "Average Usage",
      render: (rowData) => {
        return <p>{rowData.avg_time} Min</p>;
      },
      editable: "never",
    });
    arr.push({ title: "Active Users", field: "student", editable: "never" });

    return arr;
  };

  getSchoolWiseTableData = () => {
    let arr = [];

    arr.push({
      title: "District Name",
      field: "district_name",
      editable: "never",
    });
    arr.push({ title: "Block Name", field: "block_name", editable: "never" });
    arr.push({ title: "UDISE", field: "u_dise", editable: "never" });
    arr.push({
      title: "User",
      render: (rowData) => {
        return <p>{this.state.selectedUser}</p>;
      },
      editable: "never",
    });
    arr.push({
      title: "Total Usage",
      render: (rowData) => {
        return <p>{rowData.total_time} Min</p>;
      },
      editable: "never",
    });
    arr.push({
      title: "Average Usage",
      render: (rowData) => {
        return <p>{rowData.avg_time} Min</p>;
      },
      editable: "never",
    });
    arr.push({ title: "Active Users", field: "student", editable: "never" });

    return arr;
  };

  getDataBlockWise = () => {
    if (
      this.state.selectedDistrict != null &&
      this.state.selectedUser != null &&
      this.state.selectedUsageType != null &&
      this.state.selectedTimeSpan != null
    ) {
      if (
        this.state.selectedTimeSpan != "Life" &&
        this.state.selectedDate != null
      ) {
        this.setState({
          showBlockLevelData: true,
        });
      } else if (this.state.selectedTimeSpan == "Life") {
        this.setState({
          showBlockLevelData: true,
        });
      }
    }
  };

  getSchoolMaterialData = (query) => {
    this.setState({
      showSchoolLevelData: true,
    });
    return new Promise(async (resolve, reject) => {
      if (
        this.state.selectedDistrict != null &&
        this.state.selectedUser != null &&
        this.state.selectedUsageType != null &&
        this.state.selectedTimeSpan != null &&
        this.state.selectedBlock != null
      ) {
        if (
          this.state.selectedTimeSpan != "Life" &&
          this.state.selectedDate != null
        ) {
          if (this.state.selectedUsageType == "Mobile") {
            if (this.state.selectedTimeSpan == "Week") {
              let param = {
                student_district_id: this.state.selectedDistrict,
                week_start: this.state.startDate,
                excel_download: false,
                student_block_id: this.state.selectedBlock,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                // search:this.state.searchText
              };
              if (this.state.searchText) {
                param["search"] = this.state.searchText;
              }
              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }

              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseAppTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            } else {
              let param = {
                student_district_id: this.state.selectedDistrict,
                month_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                student_block_id: this.state.selectedBlock,
                // search:this.state.searchText
              };
              if (this.state.searchText) {
                param["search"] = this.state.searchText;
              }
              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseAppTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            }
          } else {
            if (this.state.selectedTimeSpan == "Week") {
              let param = {
                student_district_id: this.state.selectedDistrict,
                week_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                student_block_id: this.state.selectedBlock,
                // search:this.state.searchText
              };
              if (this.state.searchText) {
                param["search"] = this.state.searchText;
              }
              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseWebTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            } else {
              let param = {
                student_district_id: this.state.selectedDistrict,
                month_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                student_block_id: this.state.selectedBlock,
                // search:this.state.searchText
              };

              if (this.state.searchText) {
                param["search"] = this.state.searchText;
              }
              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseWebTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            }
          }
        } else if (this.state.selectedTimeSpan == "Life") {
          if (this.state.selectedUsageType == "Mobile") {
            let param = {
              student_district_id: this.state.selectedDistrict,
              excel_download: false,
              page_number: query ? query.page + 1 : 1,
              record_per_page: query ? query.pageSize : 1,
              student_block_id: this.state.selectedBlock,
              // search:this.state.searchText
            };
            if (this.state.searchText) {
              param["search"] = this.state.searchText;
            }
            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            console.log(param, "params");

            getSuperAdminService()
              .vsidataBlockwiseAppLifeTime(param)
              .then((result) => {
                if (result) {
                  console.log("result ", result);

                  resolve({
                    data: result.data.data,
                    page: query ? query.page : 1,
                    totalCount: result.data.count,
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          } else {
            let param = {
              student_district_id: this.state.selectedDistrict,
              excel_download: false,
              page_number: query ? query.page + 1 : 1,
              record_per_page: query ? query.pageSize : 1,
              student_block_id: this.state.selectedBlock,
              // search:this.state.searchText
            };
            if (this.state.searchText) {
              param["search"] = this.state.searchText;
            }
            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }
            console.log(param, "params");

            getSuperAdminService()
              .vsidataBlockwiseWebLifeTime(param)
              .then((result) => {
                if (result) {
                  resolve({
                    data: result.data.data,
                    page: query ? query.page : 1,
                    totalCount: result.data.count,
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          }
        }
        this.setState({
          page: query ? query.page : 1,
          query: query,
        });
      }
    });
  };

  getDataSchoolWise = () => {
    if (
      this.state.selectedDistrict != null &&
      this.state.selectedUser != null &&
      this.state.selectedUsageType != null &&
      this.state.selectedTimeSpan != null &&
      this.state.selectedBlock != null
    ) {
      if (
        this.state.selectedTimeSpan != "Life" &&
        this.state.selectedDate != null
      ) {
        this.setState({
          showSchoolLevelData: true,
        });
      } else if (this.state.selectedTimeSpan == "Life") {
        this.setState({
          showSchoolLevelData: true,
        });
      }
    }
  };

  getDataUserWise = () => {
    this.setState({
      showSearchBar: true,
    });
    if (
      this.state.selectedUser != null &&
      this.state.selectedUsageType != null &&
      this.state.selectedTimeSpan != null
    ) {
      if (
        this.state.selectedTimeSpan != "Life" &&
        this.state.selectedDate != null
      ) {
        if (this.state.selectedUsageType == "Mobile") {
          if (this.state.selectedTimeSpan == "Week") {
            let url = `/user_wise_analytics?week_start=${this.state.startDate}&page_number=${this.state.offsetPage/10 + 1}`;
            if (this.state.selectedDistrict != null) {
              url = url + `&student_district_id=${this.state.selectedDistrict}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null
            ) {
              url = url + `&student_block_id=${this.state.selectedBlock}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null &&
              this.state.selectedSchool != null
            ) {
              url = url + `&school_id=${this.state.selectedSchool}`;
            }

            if (this.state.selectedUser == "Student") {
              url = url + `&is_teacher=${false}`;
            } else if (this.state.selectedUser == "Teacher") {
              url = url + `&is_teacher=${true}`;
            }
            if (this.state.searchPhone) {
              url = url + `&mobile_num=${this.state.searchPhone}`;
            }
            console.log(url, "params");

            getSuperAdminService()
              .getVSIdataUserwise(url)
              .then((res) => {
                this.setState({
                  userLevelData: res.data.results,
                  userLevelStudents: res.data.count,
                  showUserLevelData: true,
                });
              });
          } else {
            let url = `/user_wise_analytics?month_start=${this.state.startDate}&page_number=${this.state.offsetPage/10 + 1}`;
            if (this.state.selectedDistrict != null) {
              url = url + `&student_district_id=${this.state.selectedDistrict}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null
            ) {
              url = url + `&student_block_id=${this.state.selectedBlock}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null &&
              this.state.selectedSchool != null
            ) {
              url = url + `&school_id=${this.state.selectedSchool}`;
            }

            if (this.state.selectedUser == "Student") {
              url = url + `&is_teacher=${false}`;
            } else if (this.state.selectedUser == "Teacher") {
              url = url + `&is_teacher=${true}`;
            }
            if (this.state.searchPhone) {
              url = url + `&mobile_num=${this.state.searchPhone}`;
            }
            console.log(url, "params");

            getSuperAdminService()
              .getVSIdataUserwise(url)
              .then((res) => {
                this.setState({
                  userLevelData: res.data.results,
                  userLevelStudents: res.data.count,
                  showUserLevelData: true,
                });
              });
          }
        } else {
          if (this.state.selectedTimeSpan == "Week") {
            let url = `/get_user_web_time_analytics?week_start=${this.state.startDate}&record_per_page=10&page_number=${this.state.offsetPage/10+1}`;
            if (this.state.selectedDistrict != null) {
              url = url + `&student_district_id=${this.state.selectedDistrict}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null
            ) {
              url = url + `&student_block_id=${this.state.selectedBlock}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null &&
              this.state.selectedSchool != null
            ) {
              url = url + `&school_id=${this.state.selectedSchool}`;
            }

            if (this.state.selectedUser == "Student") {
              url = url + `&is_teacher=False`;
            } else if (this.state.selectedUser == "Teacher") {
              url = url + `&is_teacher=True`;
            }
            if (this.state.searchPhone) {
              url = url + `&mobile_num=${this.state.searchPhone}`;
            }
            console.log(url, "params");

            getSuperAdminService()
              .getVSIdataUserwise(url)
              .then((res) => {
                this.setState({
                  userLevelData: res.data.data,
                  userLevelStudents: res.data.count,
                  showUserLevelData: true,
                });
              });
          } else {
            let url = `/get_user_web_time_analytics?month_start=${this.state.startDate}&record_per_page=10&page_number=${this.state.offsetPage/10+1}`;
            if (this.state.selectedDistrict != null) {
              url = url + `&student_district_id=${this.state.selectedDistrict}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null
            ) {
              url = url + `&student_block_id=${this.state.selectedBlock}`;
            }
            if (
              this.state.selectedDistrict != null &&
              this.state.selectedBlock != null &&
              this.state.selectedSchool != null
            ) {
              url = url + `&school_id=${this.state.selectedSchool}`;
            }

            if (this.state.selectedUser == "Student") {
              url = url + `&is_teacher=False`;
            } else if (this.state.selectedUser == "Teacher") {
              url = url + `&is_teacher=True`;
            }
            if (this.state.searchPhone) {
              url = url + `&mobile_num=${this.state.searchPhone}`;
            }
            console.log(url, "params");
            getSuperAdminService()
              .getVSIdataUserwise(url)
              .then((res) => {
                this.setState({
                  userLevelData: res.data.data,
                  userLevelStudents: res.data.count,
                  showUserLevelData: true,
                });
              });
          }
        }
      } else if (this.state.selectedTimeSpan == "Life") {
        if (this.state.selectedUsageType == "Mobile") {
          let url = `/user_app_lifetime_analytics?&offset=${this.state.offsetPage}`;
          if (this.state.selectedDistrict != null) {
            url = url + `&student_district_id=${this.state.selectedDistrict}`;
          }
          if (
            this.state.selectedDistrict != null &&
            this.state.selectedBlock != null
          ) {
            url = url + `&student_block_id=${this.state.selectedBlock}`;
          }
          if (
            this.state.selectedDistrict != null &&
            this.state.selectedBlock != null &&
            this.state.selectedSchool != null
          ) {
            url = url + `&school_id=${this.state.selectedSchool}`;
          }

          if (this.state.selectedUser == "Student") {
            url = url + `&is_teacher=${false}`;
          } else if (this.state.selectedUser == "Teacher") {
            url = url + `&is_teacher=${true}`;
          }
          if (this.state.searchPhone) {
            url = url + `&mobile_num=${this.state.searchPhone}`;
          }
          console.log(url, "params");
          getSuperAdminService()
            .getVSIdataUserwise(url)
            .then((res) => {
              this.setState({
                userLevelData: res.data.data,
                userLevelStudents: res.data.count,
                showUserLevelData: true,
              });
            });
        } else {
          let url = `/get_user_web_time_analytics?&record_per_page=10&page_number=${this.state.offsetPage/10+1}`;
          if (this.state.selectedDistrict != null) {
            url = url + `&student_district_id=${this.state.selectedDistrict}`;
          }
          if (
            this.state.selectedDistrict != null &&
            this.state.selectedBlock != null
          ) {
            url = url + `&student_block_id=${this.state.selectedBlock}`;
          }
          if (
            this.state.selectedDistrict != null &&
            this.state.selectedBlock != null &&
            this.state.selectedSchool != null
          ) {
            url = url + `&school_id=${this.state.selectedSchool}`;
          }

          if (this.state.selectedUser == "Student") {
            url = url + `&is_teacher=False`;
          } else if (this.state.selectedUser == "Teacher") {
            url = url + `&is_teacher=True`;
          }
          if (this.state.searchPhone) {
            url = url + `&mobile_num=${this.state.searchPhone}`;
          }
          console.log(url, "params");
          getSuperAdminService()
            .getVSIdataUserwise(url)
            .then((res) => {
              if (res) {
                this.setState({
                  userLevelData: res.data.results,
                  userLevelStudents: res.data.count,
                  showUserLevelData: true,
                });
              }
            });
        }
      }
    }
  };

  userInfo = () => {
    return (
      <>
        <Card
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          {this.renderSelectionInfo()}

          <div>
            <Button
              style={{
                padding: "10px",
                backgroundColor: "#7965DA",
                color: "#fff",
                marginTop: "30px",
              }}
              onClick={this.getDataUserWise}
            >
              Search
            </Button>
          </div>
        </Card>
        {this.state.showUserLevelData ? (
          <>
            <div>
              {this.state.userLevelData?.length > 0 ? (
                <div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="center">User</TableCell>
                          <TableCell align="right">TotalTime</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.userLevelData.map((row) => (
                          <TableRow
                            key={row.user_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row?.full_name}
                            </TableCell>
                            {console.log(row)}
                            <TableCell align="center">
                              {row?.is_teacher == true ? "Teacher" : "Student"}
                            </TableCell>
                            <TableCell align="right">
                              {row?.time_spend ?(row?.time_spend)
                              :(row?.time / 60000).toFixed(2)} Min
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "100px",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.BackPageUserWise();
                      }}
                    >
                      <ArrowBack />
                    </Button>
                    <Button
                      onClick={() => {
                        this.nextPageUserWise();
                      }}
                    >
                      <ArrowForward />
                    </Button>
                  </div>

                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "30px",
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <h1 style={{ textAlign: "center" }}>No data</h1>
              )}
            </div>
                
                <div>
            {/* <div style={{ display:'flex',justifyContent:'space-between'}}>
              {this.state.showSearchBar ? (
                <div>
                  <TextField
                    value={this.state.searchPhone}
                    type="number"
                    onChange={(e) => {
                      this.setState({
                        searchPhone: e.target.value,
                      });
                    }}
                  />
                  <Button
                    //  disabled={this.state.searchText && this.state.searchText?.length == 11 ? false : true }
                    style={{
                      padding: "10px",
                      backgroundColor: "#7965DA",
                      color: "#fff",
                      marginLeft: "15px",
                      // marginTop: "30px",
                      marginBottom: "100px",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      this.setState(
                        {
                          showUserLevelData: false,
                        },
                        () => {
                          this.getDataUserWise();
                        }
                      );

                      console.log(this.state.searchPhone, "serach");
                    }}
                  >
                    Search User
                  </Button>

                  <Button
                    //  disabled={this.state.searchText.length<11}
                    style={{
                      padding: "10px",
                      backgroundColor: "red",
                      color: "#fff",
                      // marginTop: "30px",
                      marginLeft: "15px",
                      marginBottom: "100px",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      this.setState(
                        {
                          searchPhone: null,
                          showUserLevelData: false,
                        },
                        () => {
                          this.getSchoolMaterialData();
                        }
                      );
                    }}
                  >
                    Reset
                  </Button>
                </div>
              ) : null}

           <div> 
           <Button
                    
                    style={{
                      padding: "10px",
                      backgroundColor: "#7965DA",
                      color: "#fff",
                      marginLeft: "15px",
                      // marginTop: "30px",
                      marginBottom: "100px",
                      borderRadius: "10px",
                    }}
                   onClick={()=>{
                    this.getExcelForuser()
                   }}
                  >
                    Excel
                  </Button>
           </div>
            </div> */}

            
            </div>

          </>
        ) : null}
      </>
    );
  };

  BackPageUserWise = () => {
    let data =
      this.state.offsetPage > 0
        ? this.state.offsetPage - 10
        : this.state.offsetPage;

    if (this.state.offsetPage != 0) {
      this.setState(
        {
          offsetPage: data,
        },
        () => {
          this.getDataUserWise();
        }
      );
    } else {
      return;
    }
  };
  nextPageUserWise = () => {
    let data = this.state.offsetPage + 10;
    if (data > this.state.userLevelStudents) {
      return;
    } else {
      this.setState(
        {
          offsetPage: data,
        },
        () => {
          this.getDataUserWise();
        }
      );
    }
  };

  downloadCSVDistrictLevel = () => {
    if (
      this.state.selectedUser != null &&
      this.state.selectedUsageType != null &&
      this.state.selectedTimeSpan != null
    ) {
      if (
        this.state.selectedTimeSpan != "Life" &&
        this.state.selectedDate != null
      ) {
        if (this.state.selectedUsageType == "Mobile") {
          if (this.state.selectedTimeSpan == "Week") {
            let param = {
              week_start: this.state.startDate,
              excel_download: true,
              student_state_id: 1,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseAppTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          } else {
            let param = {
              month_start: this.state.startDate,
              excel_download: true,
              student_state_id: 1,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseAppTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          }
        } else {
          if (this.state.selectedTimeSpan == "Week") {
            let param = {
              week_start: this.state.startDate,
              excel_download: true,
              student_state_id: 1,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseWebTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          } else {
            let param = {
              month_start: this.state.startDate,
              excel_download: true,
              student_state_id: 1,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseWebTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          }
        }
      } else if (this.state.selectedTimeSpan == "Life") {
        if (this.state.selectedUsageType == "Mobile") {
          let param = {
            excel_download: true,
            student_state_id: 1,
          };

          if (this.state.selectedUser == "Student") {
            param["is_teacher"] = false;
          } else if (this.state.selectedUser == "Teacher") {
            param["is_teacher"] = true;
          }

          getSuperAdminService()
            .vsidataBlockwiseAppLifeTime(param)
            .then((result) => {
              if (result.data) {
                let contentDisposition = result.headers["content-disposition"];
                let fileName = `${contentDisposition.substr(
                  contentDisposition.indexOf("=") + 1,
                  contentDisposition.length
                )}`;
                this.convertJsonToCsv(result.data, fileName);
              }
            });
        } else {
          let param = {
            excel_download: true,
            student_state_id: 1,
          };

          if (this.state.selectedUser == "Student") {
            param["is_teacher"] = false;
          } else if (this.state.selectedUser == "Teacher") {
            param["is_teacher"] = true;
          }

          getSuperAdminService()
            .vsidataBlockwiseWebLifeTime(param)
            .then((result) => {
              if (result.data) {
                let contentDisposition = result.headers["content-disposition"];
                let fileName = `${contentDisposition.substr(
                  contentDisposition.indexOf("=") + 1,
                  contentDisposition.length
                )}`;
                this.convertJsonToCsv(result.data, fileName);
              }
            });
        }
      }
    }
  };

  downloadCSVBlockLevel = () => {
    if (
      this.state.selectedUser != null &&
      this.state.selectedUsageType != null &&
      this.state.selectedTimeSpan != null &&
      this.state.selectedDistrict != null
    ) {
      if (
        this.state.selectedTimeSpan != "Life" &&
        this.state.selectedDate != null
      ) {
        if (this.state.selectedUsageType == "Mobile") {
          if (this.state.selectedTimeSpan == "Week") {
            let param = {
              week_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseAppTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          } else {
            let param = {
              month_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseAppTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          }
        } else {
          if (this.state.selectedTimeSpan == "Week") {
            let param = {
              week_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseWebTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          } else {
            let param = {
              month_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseWebTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          }
        }
      } else if (this.state.selectedTimeSpan == "Life") {
        if (this.state.selectedUsageType == "Mobile") {
          let param = {
            excel_download: true,
            student_district_id: this.state.selectedDistrict,
          };

          if (this.state.selectedUser == "Student") {
            param["is_teacher"] = false;
          } else if (this.state.selectedUser == "Teacher") {
            param["is_teacher"] = true;
          }

          getSuperAdminService()
            .vsidataBlockwiseAppLifeTime(param)
            .then((result) => {
              if (result.data) {
                let contentDisposition = result.headers["content-disposition"];
                let fileName = `${contentDisposition.substr(
                  contentDisposition.indexOf("=") + 1,
                  contentDisposition.length
                )}`;
                this.convertJsonToCsv(result.data, fileName);
              }
            });
        } else {
          let param = {
            excel_download: true,
            student_district_id: this.state.selectedDistrict,
          };

          if (this.state.selectedUser == "Student") {
            param["is_teacher"] = false;
          } else if (this.state.selectedUser == "Teacher") {
            param["is_teacher"] = true;
          }

          getSuperAdminService()
            .vsidataBlockwiseWebLifeTime(param)
            .then((result) => {
              if (result.data) {
                let contentDisposition = result.headers["content-disposition"];
                let fileName = `${contentDisposition.substr(
                  contentDisposition.indexOf("=") + 1,
                  contentDisposition.length
                )}`;
                this.convertJsonToCsv(result.data, fileName);
              }
            });
        }
      }
    }
  };

  downloadCSVSchoolLevel = () => {
    if (
      this.state.selectedDistrict != null &&
      this.state.selectedUser != null &&
      this.state.selectedUsageType != null &&
      this.state.selectedTimeSpan != null &&
      this.state.selectedBlock != null
    ) {
      if (
        this.state.selectedTimeSpan != "Life" &&
        this.state.selectedDate != null
      ) {
        if (this.state.selectedUsageType == "Mobile") {
          if (this.state.selectedTimeSpan == "Week") {
            let param = {
              week_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
              student_block_id: this.state.selectedBlock,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseAppTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          } else {
            let param = {
              month_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
              student_block_id: this.state.selectedBlock,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseAppTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          }
        } else {
          if (this.state.selectedTimeSpan == "Week") {
            let param = {
              week_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
              student_block_id: this.state.selectedBlock,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseWebTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          } else {
            let param = {
              month_start: this.state.startDate,
              excel_download: true,
              student_district_id: this.state.selectedDistrict,
              student_block_id: this.state.selectedBlock,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            getSuperAdminService()
              .vsidataBlockwiseWebTime(param)
              .then((result) => {
                if (result.data) {
                  let contentDisposition =
                    result.headers["content-disposition"];
                  let fileName = `${contentDisposition.substr(
                    contentDisposition.indexOf("=") + 1,
                    contentDisposition.length
                  )}`;
                  this.convertJsonToCsv(result.data, fileName);
                }
              });
          }
        }
      } else if (this.state.selectedTimeSpan == "Life") {
        if (this.state.selectedUsageType == "Mobile") {
          let param = {
            excel_download: true,
            student_district_id: this.state.selectedDistrict,
            student_block_id: this.state.selectedBlock,
          };

          if (this.state.selectedUser == "Student") {
            param["is_teacher"] = false;
          } else if (this.state.selectedUser == "Teacher") {
            param["is_teacher"] = true;
          }

          getSuperAdminService()
            .vsidataBlockwiseAppLifeTime(param)
            .then((result) => {
              if (result.data) {
                let contentDisposition = result.headers["content-disposition"];
                let fileName = `${contentDisposition.substr(
                  contentDisposition.indexOf("=") + 1,
                  contentDisposition.length
                )}`;
                this.convertJsonToCsv(result.data, fileName);
              }
            });
        } else {
          let param = {
            excel_download: true,
            student_district_id: this.state.selectedDistrict,
            student_block_id: this.state.selectedBlock,
          };

          if (this.state.selectedUser == "Student") {
            param["is_teacher"] = false;
          } else if (this.state.selectedUser == "Teacher") {
            param["is_teacher"] = true;
          }

          getSuperAdminService()
            .vsidataBlockwiseWebLifeTime(param)
            .then((result) => {
              if (result.data) {
                let contentDisposition = result.headers["content-disposition"];
                let fileName = `${contentDisposition.substr(
                  contentDisposition.indexOf("=") + 1,
                  contentDisposition.length
                )}`;
                this.convertJsonToCsv(result.data, fileName);
              }
            });
        }
      }
    }
  };

  // convertJsonToCsv(items, fileTitle) {
  //   // Convert Object to JSON
  //   // var jsonObject = JSON.stringify(items);

  //   // var csv = this.convertToCSV(jsonObject);

  //   var exportedFilenmae = fileTitle || "export.csv";

  //   var blob = new Blob([items], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
  //   if (navigator.msSaveBlob) {
  //     // IE 10+
  //     navigator.msSaveBlob(blob, exportedFilenmae);
  //   } else {
  //     var link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       // feature detection
  //       // Browsers that support HTML5 download attribute
  //       var url = URL.createObjectURL(blob);
  //       link.setAttribute("href", url);
  //       link.setAttribute("download", exportedFilenmae);
  //       link.style.visibility = "hidden";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // }
  convertJsonToCsv(items, fileTitle) {
    console.log(typeof items, "items");
    var jsonObject = JSON.stringify(items);
    var exportedFilenmae = "export.csv";

    var blob = new Blob([items], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  getDataDistrictWise = () => {
    if (
      this.state.selectedUser != null &&
      this.state.selectedUsageType != null &&
      this.state.selectedTimeSpan != null
    ) {
      if (
        this.state.selectedTimeSpan != "Life" &&
        this.state.selectedDate != null
      ) {
        this.setState({
          showDistrictLevelData: true,
        });
      } else if (this.state.selectedTimeSpan == "Life") {
        this.setState({
          showDistrictLevelData: true,
        });
      }
    }
  };

  getDistrictWiseTableData = () => {
    let arr = [];
    arr.push({
      title: "District Name",
      field: "district_name",
      editable: "never",
    });

    arr.push({
      title: "User",
      render: (rowData) => {
        return <p>{this.state.selectedUser}</p>;
      },
      editable: "never",
    });
    arr.push({
      title: "Total Usage",
      render: (rowData) => {
        return <p>{rowData.total_time} Min</p>;
      },
      editable: "never",
    });
    arr.push({
      title: "Average Usage",
      render: (rowData) => {
        return <p>{rowData.avg_time} Min</p>;
      },
      editable: "never",
    });
    arr.push({ title: "Active Users", field: "student", editable: "never" });

    return arr;
  };

  getDistrictMaterialData = (query) => {
    return new Promise(async (resolve, reject) => {
      if (
        this.state.selectedUser != null &&
        this.state.selectedUsageType != null &&
        this.state.selectedTimeSpan != null
      ) {
        if (
          this.state.selectedTimeSpan != "Life" &&
          this.state.selectedDate != null
        ) {
          if (this.state.selectedUsageType == "Mobile") {
            if (this.state.selectedTimeSpan == "Week") {
              let param = {
                week_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                student_state_id: 1,
              };

              if(this.state.mediumSelected != 10){
                 param["medium_id"] = this.state.mediumSelected
                }

              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }

              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseAppTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            } else {
              let param = {
                month_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                student_state_id: 1,
              };

              if(this.state.mediumSelected != 10){
                param["medium_id"] = this.state.mediumSelected
               }
              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseAppTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            }
          } else {
            if (this.state.selectedTimeSpan == "Week") {
              let param = {
                week_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                student_state_id: 1,
              };


              if(this.state.mediumSelected != 10){
                param["medium_id"] = this.state.mediumSelected
               }

              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseWebTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            } else {
              let param = {
                month_start: this.state.startDate,
                excel_download: false,
                page_number: query ? query.page + 1 : 1,
                record_per_page: query ? query.pageSize : 1,
                student_state_id: 1,
              };


              if(this.state.mediumSelected != 10){
                param["medium_id"] = this.state.mediumSelected
               }
              if (this.state.selectedUser == "Student") {
                param["is_teacher"] = false;
              } else if (this.state.selectedUser == "Teacher") {
                param["is_teacher"] = true;
              }
              console.log(param, "params");

              getSuperAdminService()
                .vsidataBlockwiseWebTime(param)
                .then((result) => {
                  if (result) {
                    resolve({
                      data: result.data.data,
                      page: query ? query.page : 1,
                      totalCount: result.data.count,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                });
            }
          }
        } else if (this.state.selectedTimeSpan == "Life") {
          if (this.state.selectedUsageType == "Mobile") {
            let param = {
              excel_download: false,
              page_number: query ? query.page + 1 : 1,
              record_per_page: query ? query.pageSize : 1,
              student_state_id: 1,
            };

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }

            console.log(param, "params");

            getSuperAdminService()
              .vsidataBlockwiseAppLifeTime(param)
              .then((result) => {
                if (result) {
                  console.log("result ", result);

                  resolve({
                    data: result.data.data,
                    page: query ? query.page : 1,
                    totalCount: result.data.count,
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          } else {
            let param = {
              excel_download: false,
              page_number: query ? query.page + 1 : 1,
              record_per_page: query ? query.pageSize : 1,
              student_state_id: 1,
            };


            if(this.state.mediumSelected != 10){
              param["medium_id"] = this.state.mediumSelected
             }

            if (this.state.selectedUser == "Student") {
              param["is_teacher"] = false;
            } else if (this.state.selectedUser == "Teacher") {
              param["is_teacher"] = true;
            }


            console.log(param, "params");

            getSuperAdminService()
              .vsidataBlockwiseWebLifeTime(param)
              .then((result) => {
                if (result) {
                  resolve({
                    data: result.data.data,
                    page: query ? query.page : 1,
                    totalCount: result.data.count,
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          }
        }
        this.setState({
          page: query ? query.page : 1,
          query: query,
        });
      }
    });
  };

  schoolInfo = () => {
    return (
      <>
        <Card
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          {this.renderSelectionInfo()}
          <div>
            <Button
              onClick={this.getDataSchoolWise}
              style={{
                padding: "10px",
                backgroundColor: "#7965DA",
                color: "#fff",
                marginTop: "30px",
              }}
            >
              Search
            </Button>
          </div>
        </Card>
        {this.state.showSchoolLevelData == true ? (
          <div style={{ width: "100%" }}>
            <MaterialTable
              title={""}
              columns={this.getSchoolWiseTableData()}
              data={(query) => {
                return this.getSchoolMaterialData(query);
              }}
              options={{
                search: true,
                actionsColumnIndex: -1,
                pageSize: this.state.query ? this.state.query.pageSize : 10,
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <div>
                <TextField
                  value={this.state.searchText}
                  type="number"
                  onChange={(e) => {
                    this.setState({
                      searchText: e.target.value,
                    });
                  }}
                />
                <Button
                  disabled={
                    this.state.searchText && this.state.searchText?.length == 11
                      ? false
                      : true
                  }
                  style={{
                    padding: "10px",
                    backgroundColor:
                      this.state.searchText &&
                      this.state.searchText?.length == 11
                        ? "#7965DA"
                        : "grey",
                    color: "#fff",
                    marginLeft: "15px",
                    // marginTop: "30px",
                    marginBottom: "100px",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        showSchoolLevelData: false,
                      },
                      () => {
                        this.getSchoolMaterialData();
                      }
                    );

                    console.log(this.state.searchText, "serach");
                  }}
                >
                  Search school
                </Button>

                <Button
                  //  disabled={this.state.searchText.length<11}
                  style={{
                    padding: "10px",
                    backgroundColor: "red",
                    color: "#fff",
                    // marginTop: "30px",
                    marginLeft: "15px",
                    marginBottom: "100px",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        searchText: null,
                        showSchoolLevelData: false,
                      },
                      () => {
                        this.getSchoolMaterialData();
                      }
                    );
                  }}
                >
                  Reset
                </Button>
              </div>

              <div>
                <Button
                  onClick={this.downloadCSVSchoolLevel}
                  style={{
                    padding: "10px",
                    backgroundColor: "#7965DA",
                    color: "#fff",
                    marginTop: "30px",
                    marginBottom: "100px",
                    borderRadius: "10px",
                  }}
                >
                  Download List
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  blockInfo = () => {
    return (
      <>
        <Card
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          {this.renderSelectionInfo()}
          <div>
            <Button
              onClick={this.getDataBlockWise}
              style={{
                padding: "10px",
                backgroundColor: "#7965DA",
                color: "#fff",
                marginTop: "30px",
              }}
            >
              Search
            </Button>
          </div>
        </Card>
        {this.state.showBlockLevelData == true ? (
          <div style={{ width: "100%" }}>
            <MaterialTable
              title={""}
              columns={this.getBlockWiseTableData()}
              data={(query) => {
                return this.getBlockMaterialData(query);
              }}
              options={{
                search: false,
                actionsColumnIndex: -1,
                pageSize: this.state.query ? this.state.query.pageSize : 10,
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={this.downloadCSVBlockLevel}
                style={{
                  padding: "10px",
                  backgroundColor: "#7965DA",
                  color: "#fff",
                  marginTop: "30px",
                  marginBottom: "100px",
                  borderRadius: "10px",
                }}
              >
                Download List
              </Button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  moreInfoTab = () => {
    return (
      <>
        <div
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#415EB6",
            fontFamily: "Mukta",
          }}
        >
          <div>शाळेचे नाव: न्यू इंग्लिश स्कूल, वर्धा</div>
          <div>
            नुकत्याच भरलेल्या माहितीनुसार विद्यार्थ्यांचा अध्ययनस्तर
            खालीलप्रमाणे आहे.
          </div>
        </div>
        <div style={{ borderBottom: "4px solid #8F50D6" }}></div>
        <div
          style={{
            borderBottomWidth: "4px",
            borderBottomColor: "black",
            marginTop: "35px",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <div>वर्ष</div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button style={{ backgroundColor: "#C2B0BB" }}>2022</Button>
                <Button style={{ backgroundColor: "#C2B0BB" }}>2023</Button>
              </div>
            </div>

            <div style={{ marginTop: "35px" }}>
              <div>महिना</div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button style={{ backgroundColor: "#C2B0BB" }}>Aug</Button>
                <Button
                  style={{ backgroundColor: "#C2B0BB", marginLeft: "10px" }}
                >
                  Sept
                </Button>
                {/* <Button style={{ backgroundColor: "#C2B0BB" }}>Oct</Button> */}
              </div>
            </div>
          </div>
          <div style={{ marginRight: "20em" }}>
            <div>
              <div>
                <div>अध्ययनस्तर विषय निवडा.</div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button style={{ backgroundColor: "#C2B0BB" }}>गणित</Button>
                  <Button style={{ backgroundColor: "#C2B0BB" }}>मराठी</Button>
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>माध्यम निवडा</div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button style={{ backgroundColor: "#C2B0BB" }}>सर्व</Button>
                  <Button style={{ backgroundColor: "#C2B0BB" }}>मराठी</Button>
                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>सेमी</Button> */}
                </div>
              </div>

              <div style={{ marginTop: "35px" }}>
                <div>आपली इयत्ता निवडा</div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button style={{ backgroundColor: "#C2B0BB" }}>सर्व</Button>
                  <Button style={{ backgroundColor: "#C2B0BB" }}>५ वी</Button>
                  {/* <Button style={{ backgroundColor: "#C2B0BB" }}>10 वी</Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "25px",
            display: "flex",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <Button style={{ backgroundColor: "#7965DA", color: "#fff" }}>
            आलेख पहा
          </Button>
          <Button
            style={{
              backgroundColor: "#C2B0BB",
              color: "#fff",
              marginLeft: "10px",
            }}
          >
            सारणी पहा
          </Button>
        </div>

        <div class="row">
          {data1.map((res) => {
            return (
              <div class="col" style={{ padding: "5px", marginTop: "25px" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    title: {
                      text: "",
                    },
                    xAxis: {
                      //   categories: labels,
                      crosshair: true,
                    },
                    yAxis: {
                      stackLabels: {
                        enabled: true,
                        align: "center",
                      },
                      visible: true,
                      title: {
                        enabled: true,
                        text:
                          this.state.filterDropdownId == "1"
                            ? "Active student count "
                            : this.state.filterDropdownId == "2"
                            ? "Unique Contacts"
                            : this.state.filterDropdownId == "4"
                            ? "avg time(in min)"
                            : "Student count",
                      },
                    },

                    labels: {
                      items: [
                        {
                          html: "",
                          style: {
                            left: "50px",
                            top: "18px",
                          },
                        },
                      ],
                    },
                    series: [
                      {
                        name: "Population",
                        data: [
                          ["Shanghai", 3.7],
                          ["Lagos", 16.1],
                          ["Istanbul", 14.2],
                          ["Karachi", 14.0],
                          ["Karachi", 14.0],
                        ],
                      },
                    ],
                    chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      width: window.innerWidth / 2.8,
                      type: "spline",
                    },
                  }}
                ></HighchartsReact>
                <div>
                  विद्यार्थी संख्या: १२५ उपलब्ध नोंदी: १२५ अनुपलब्ध नोंदी: १२
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };


  getExcelForuser =() =>{

    
    
    const { startDate,selectedUsageType,selectedDistrict,selectedBlock,selectedSchool,selectedUser,selectedTimeSpan} = this.state

    if(selectedUsageType == "Mobile"){
      let param={
        student_district_id: selectedDistrict,
        uses_span : selectedUsageType =='Mobile'?'app':'web',
        is_teacher : selectedUser ==='Student'?false:true
      }

      let url = `/mobile_app_user_level_screen_excel?student_district_id=${selectedDistrict}`
  
      if(selectedBlock)
      {
        url =  url + `&student_block_id=${selectedBlock}`
      }
  
      if(selectedUser)
      {
        if(selectedUser == "Student"){
          url = url + `&is_teacher=false`
        }
        if(selectedUser == "Teacher"){
          url = url + `&is_teacher=true`
        }
       
    
      }
      if(selectedSchool)
      {
        url =  url + `&school_id=${selectedSchool}`
      }
      if(this.state.selectedTimeSpan == "Week")
      {
        url = url +  `&week_start=${startDate}`
      }
      if(this.state.selectedTimeSpan == "Month")
      {
        url =  url + `&month_start=${startDate}`
      }     
         
      getSuperAdminService().CreateUserLevelExcel(url).then((res)=>{
        console.log(res,"res")
        if(res)
        {
          this.convertJsonToCsv(res.data, 'File')
        }
      })
  
    }
    else{
      let url = `/get_user_web_time_analytics?student_district_id=${selectedDistrict}&excel_download=true`;


      if(selectedBlock)
      {
        url = url + `&student_block_id=${this.state.selectedBlock}`;
      }
      if(selectedUser)
      {
        if(selectedUser ==='Student')
        {
          url = url + `&is_teacher=False`;
        }else  if(selectedUser ==='Teacher')
        {
          url = url + `&is_teacher=True`;
        }
      }
  
      if(selectedSchool)
      {
        url = url + `&school_id=${this.state.selectedSchool}`;
      }
      if(this.state.selectedTimeSpan == "Week")
      {
        url = url + `&week_start=${startDate}`;
      }
      if(this.state.selectedTimeSpan == "Month")
      {
        url = url + `&month_start=${startDate}`;
      }

      getSuperAdminService()
      .getVSIdataUserwise(url)
      .then((res) => {
        this.convertJsonToCsv(res.data, 'File')
      });      

    }


  }


  render() {
    let { stdWiseGraph } = this.state;

    console.log("this is the state", this.state);

    return (
      <>
        <button
          onClick={() => {
            this.setState({
              districtTab: true,
              userTab: false,
              blockTab: false,
              schoolTab: false,
              selectedDistrict: null,
              selectedBlock: null,
              selectedSchool: null,
              selectedUser: null,
              selectedTimeSpan: null,
              selectedUsageType: null,
              selectedDate: null,
              startDate: "",
              page: 0,
              showDistrictLevelData: false,
              showBlockLevelData: false,
              showSchoolLevelData: false,
              showUserLevelData: false,
              query: null,
              offsetPage: 0,
              endDate:"",
              mediumSelected: 10
            });
          }}
          className={
            this.state.districtTab
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          District Level
        </button>
        <button
          onClick={() => {
            this.setState({
              districtTab: false,
              userTab: false,
              blockTab: true,
              schoolTab: false,
              selectedDistrict: null,
              selectedBlock: null,
              selectedSchool: null,
              selectedUser: null,
              selectedTimeSpan: null,
              selectedUsageType: null,
              selectedDate: null,
              startDate: "",
              page: 0,
              showDistrictLevelData: false,
              showBlockLevelData: false,
              showSchoolLevelData: false,
              showUserLevelData: false,
              query: null,
              offsetPage: 0,
              endDate:"",
              mediumSelected: 10
            });
          }}
          className={
            this.state.blockTab
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          Block Level
        </button>
        <button
          onClick={() => {
            this.setState({
              districtTab: false,
              userTab: false,
              blockTab: false,
              schoolTab: true,
              selectedDistrict: null,
              selectedBlock: null,
              selectedSchool: null,
              selectedUser: null,
              selectedTimeSpan: null,
              selectedUsageType: null,
              selectedDate: null,
              startDate: "",
              page: 0,
              showDistrictLevelData: false,
              showBlockLevelData: false,
              showSchoolLevelData: false,
              showUserLevelData: false,
              query: null,
              offsetPage: 0,
              endDate:"",
              mediumSelected: 10
            });
          }}
          className={
            this.state.schoolTab
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          School Level
        </button>
        <button
          onClick={() => {
            this.setState({
              districtTab: false,
              userTab: true,
              blockTab: false,
              schoolTab: false,
              selectedDistrict: null,
              selectedBlock: null,
              selectedSchool: null,
              selectedUser: null,
              selectedTimeSpan: null,
              selectedUsageType: null,
              selectedDate: null,
              startDate: "",
              page: 0,
              showDistrictLevelData: false,
              showBlockLevelData: false,
              showSchoolLevelData: false,
              showUserLevelData: false,
              query: null,
              offsetPage: 0,
              endDate:"",
              mediumSelected: 10
            });
          }}
          className={
            this.state.userTab
              ? classes.nextPageButton
              : classes.currentPageButton
          }
        >
          User Level
        </button>
        <div
          style={{ borderBottom: "2px solid black", marginTop: "5px" }}
        ></div>
        ..
        {this.state.districtTab
          ? this.districtInfo()
          : this.state.userTab
          ? this.userInfo()
          : this.state.blockTab
          ? this.blockInfo()
          : this.schoolInfo()}
      </>
    );
  }
}