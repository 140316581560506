import SuperAdminService from "./superAdminService.js";
import ContentWriterService from "./contentWriterService"
import SharedService from "./sharedService.js";
import AdminService from "./adminService";
import StudentService from "./studentService.js"
import HMService from "./HMService";
import AdministrativeService from './AdministrativeService'

let superAdmin;
let contentWriter;
let shared;
let admin;
let student;
let headMaster;
let administrative;

export function getSuperAdminService() {
    if (!superAdmin) {
        superAdmin = new SuperAdminService();
    }
    return superAdmin
}

export function getContentWriterService() {
    if (!contentWriter) {
        contentWriter = new ContentWriterService();
    }
    return contentWriter
}

export function getSharedService() {
    if (!shared) {
        shared = new SharedService();
    }
    return shared
}

export function getAdminService() {
    if (!admin) {
        admin = new AdminService();
    }
    return admin
}

export function getStudentService() {
    if (!student) {
        student = new StudentService();
    }
    return student
}

export function getHMService() {
    if (!headMaster) {
        headMaster = new HMService();
    }
    return headMaster
}

export function getAdministrativeService() {
    if (!administrative) {
        administrative = new AdministrativeService();
    }
    return administrative
}
