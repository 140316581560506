//default imports
import React from "react";

// external package imports
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//custom imports
// import classes from "./HMDashboard.module.css";
import { getHMService, getStudentService } from "../../service/service";
import { Button } from "@material-ui/core";
import home from "assets/img/SMCHome.jpg";
export default class HMDashboardPage extends React.Component {
  state = {
    schoolDetail: {},
    stdWiseGraph: null,
    filterDropdownId: "",
    ActiveStudentKey: false,
    uniqueContactKey: false,
    avgTimeGraph: false,
    incomeFilter: false,
    moreFilter: false,
    filterName: "",
    showLoader: false,
    versionArray: false,
  };

  componentDidMount() {
    getHMService()
      .getVersionName()
      .then((res) => {
        if (res.data.response.length > 0) {
          this.setState({
            versionArray: true,
          });
        }
      });
    getHMService()
      .getMySchool()
      .then((res) => {
        let data = res.data;
        if (data.status == 200) {
          this.setState(
            {
              schoolDetail: data.response,
            },
            () => {
              localStorage.setItem(
                "HMSchool",
                JSON.stringify(this.state.schoolDetail)
              );
            }
          );
          let { school_id } = data.response;
          this.getGraphData({ school_id });
        }
      });
  }

  getStudentDataForChart = async () => {
    let { districtForGraph, blockForGraph } = this.state;
    if (this.state.filterDropdownId === "1") {
      this.setState({ ActiveStudentKey: true });
    } else if (this.state.filterDropdownId === "2") {
      this.setState({ uniqueContactKey: true });
    } else if (this.state.filterDropdownId === "4") {
      this.setState({ avgTimeGraph: true });
    }
    let key;
    let param = {
      district_id: districtForGraph,
      block_id: blockForGraph,
      medium_id: "",
      active_student: this.state.ActiveStudentKey,
      unique_user: this.state.uniqueContactKey,
      average_time: this.state.avgTimeGraph,
    };
    let studentCount;
    if (this.state.filterName == "income") {
      key = "income";
    } else if (this.state.filterName == "Caste") {
      key = "caste";
    } else {
      key = "gender";
    }

    let { school_id } = JSON.parse(localStorage.getItem("HMSchool"));

    if (this.state.moreFilter == false) {
      studentCount = await getStudentService().getStudentCountGraph(param);
      studentCount = studentCount.data;
      console.log(studentCount, "studentCount");
    } else {
      console.log(this.state.filterName, "data");
      let params = {
        school_id,
        [key]: true,
      };
      studentCount = await getStudentService().getCastIncomeGendergraph({
        params,
      });
      studentCount = studentCount.data.response;
    }

    console.log(studentCount, "count");
    let labels = [];
    let series = [];
    let count = [];
    let Extracount = [];
    let otherCount = [];
    let income1 = [];
    let income2 = [];
    let income3 = [];
    let income4 = [];
    let income0 = [];
    let OpenNum = [];
    let OBCNum = [];
    let NTNum = [];
    let OtherNum = [];
    let SBCNum = [];
    let STNum = [];
    let SCNum = [];
    let VJNTNum = [];
    let isClass = false;
    let sum = 0;

    if (studentCount) {
      console.log("ok");
      let filteredData;
      if (this.state.moreFilter == true) {
        console.log("ok");
        this.setState({ showLoader: false });
        if (studentCount[0].block_name) {
          filteredData = studentCount.filter((x) =>
            x.hasOwnProperty("block_name")
          );
          console.log(filteredData, "filter");
        } else {
          filteredData = studentCount.filter((x) =>
            x.hasOwnProperty("class_name")
          );
          isClass = true;
          console.log(filteredData);
        }
      } else {
        console.log("enter");
        this.setState({ showLoader: false });

        console.log(studentCount.response[0], "0");
        if (studentCount.response[0].district_name) {
          filteredData = studentCount.response.filter((x) =>
            x.hasOwnProperty("district_name")
          );
          console.log(filteredData, "filter");
        } else if (
          studentCount.response[0].student_district_id ||
          studentCount.response[0].block_name
        ) {
          console.log("ok");
          if (this.state.filterDropdownId === "1") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("active_students")
            );
            console.log(filteredData, "activ");
          } else if (this.state.filterDropdownId === "2") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("unique_user")
            );
            console.log(filteredData, "activ");
          } else if (this.state.filterDropdownId === "4") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("avg_time")
            );
            console.log(filteredData, "activ");
          } else if (this.state.filterDropdownId === "0") {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("block_name")
            );
            console.log(filteredData, "filter");
          } else {
            filteredData = studentCount.response.filter((x) =>
              x.hasOwnProperty("block_name")
            );
            console.log(filteredData, "filter");
          }
        } else {
          filteredData = studentCount.response.filter((x) =>
            x.hasOwnProperty("class_name")
          );
          isClass = true;
        }
      }
      filteredData.sort((a, b) => a.class_id - b.class_id);
      console.log(filteredData, "data");
      filteredData.map((x) => {
        if (x.hasOwnProperty("district_name")) {
          labels.push(x.district_name);
        } else if (x.hasOwnProperty("class_name")) {
          labels.push(x.class_name);
        } else {
          labels.push(x.block_name);
          console.log(labels, "labels");
        }

        if (
          this.state.filterDropdownId == "1" ||
          ("2" && this.state.moreFilter == false)
        ) {
          console.log(this.state.filterDropdownId, "drop");
          if (this.state.filterDropdownId == "4") {
            console.log(x.avg_time, "x");
            var ms = x.avg_time,
              min = Math.floor((ms / 1000 / 60) << 0);
            //sec = Math.floor((ms/1000) % 60);
            console.log(min);
            count.push(min);
            sum = sum + min;
          } else {
            count.push(x.count);
            sum = sum + x.count;
          }
        } else if (this.state.moreFilter == true) {
          console.log(x, "x");
          if (x.male_count) {
            console.log(x.male_count, "male");
            count.push(x.male_count);
            Extracount.push(x.female_count);
            otherCount.push(x.other_count);
            sum = sum + x.female_count + x.male_count + x.other_count;
            console.log(sum, "sum");
          }
          if (x.open_caste_count) {
            OpenNum.push(x.open_caste_count);
            OBCNum.push(x.OBC_caste_count);
            NTNum.push(x.NT_caste_count);
            OtherNum.push(x.OTHER_caste_count);
            SBCNum.push(x.SBC_caste_count);
            STNum.push(x.ST_caste_count);
            SCNum.push(x.SC_caste_count);
            VJNTNum.push(x.VJNT_caste_count);
            sum =
              sum +
              x.open_caste_count +
              x.OBC_caste_count +
              x.NT_caste_count +
              x.OTHER_caste_count +
              x.SBC_caste_count +
              x.ST_caste_count +
              x.SC_caste_count +
              x.VJNT_caste_count;
            console.log(sum, "sum");
          } else {
            console.log(x, "male");
            income1.push(x.fifty_to_one_lakh_income_count);
            income2.push(x.one_to_five_lakh_income_count);
            income3.push(x.five_to_ten_lakh_income_count);
            income4.push(x.above_10lakh_income_count);
            income0.push(x.belowfifty_thousand_income_count);
            sum =
              sum +
              x.fifty_to_one_lakh_income_count +
              x.one_to_five_lakh_income_count +
              x.five_to_ten_lakh_income_count +
              x.above_10lakh_income_count +
              x.belowfifty_thousand_income_count;
          }
        } else {
          count.push(x.count);
          sum = sum + x.count;
        }
      });
      this.setState({
        sumOfStudents: sum,
      });

      series =
        this.state.moreFilter == false
          ? [
              {
                name: isClass ? "class" : "Count",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ]
          : this.state.incomeFilter == true
          ? [
              {
                name: "",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " below 50k",
                data: income0,
                color: "orange",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " 50k-1L",
                data: income1,
                color: "blue",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " 1-5L",
                data: income2,
                color: "voilet",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "5-10L",
                data: income3,
                color: "green",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " above 10L",
                data: income4,
                color: "yellow",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ]
          : this.state.filterName == "Caste"
          ? [
              {
                name: "",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "Open",
                data: OpenNum,
                color: "orange",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " OBC",
                data: OBCNum,
                color: "blue",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " NT",
                data: NTNum,
                color: "voilet",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "Other",
                data: OtherNum,
                color: "green",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " SBC",
                data: SBCNum,
                color: "yellow",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "ST",
                data: STNum,
                color: "#8f32a8",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "SC",
                data: SCNum,
                color: "#32a897",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: "VJNT",
                data: VJNTNum,
                color: "#32a852",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ]
          : [
              {
                name: " Male Count",
                data: count,
                color: "#f7ac32",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " Female Count",
                data: Extracount,
                color: "red",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
              {
                name: " other Count",
                data: otherCount,
                color: "blue",
                dataLabels: {
                  enabled: true,
                  align: "center",
                  style: {
                    fontSize: "10px",
                    fontFamily: "helvetica, arial, sans-serif",
                  },
                },
              },
            ];

      this.setState({
        stdWiseGraph: {
          title: {
            text: "",
          },
          xAxis: {
            categories: labels,
            crosshair: true,
          },
          yAxis: {
            stackLabels: {
              enabled: true,
              align: "center",
            },
            visible: true,
            title: {
              enabled: true,
              text:
                this.state.filterDropdownId == "1"
                  ? "Active student count "
                  : this.state.filterDropdownId == "2"
                  ? "Unique Contacts"
                  : this.state.filterDropdownId == "4"
                  ? "avg time(in min)"
                  : "Student count",
            },
          },

          labels: {
            items: [
              {
                html: "",
                style: {
                  left: "50px",
                  top: "18px",
                },
              },
            ],
          },
          series: series,
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            width: (window.innerWidth - 260 - 120) / 1.05,
            type: "column",
          },
        },
      });
      this.setState({
        ActiveStudentKey: false,
        uniqueContactKey: false,
        avgTimeGraph: false,
        filterName: "",
      });
    }
  };

  getGraphData = (req) => {
    getStudentService()
      .getStudentCountGraph(req)
      .then((res) => {
        console.log(res, " res from getGraphData");
        let labels = [];

        let count = [];

        res.data.response.map((data) => {
          labels.push(data.class_name);
          count.push(data.count);
        });

        let series = [
          {
            name: "Class",
            data: count,
            color: "#f7ac32",
          },
        ];

        this.setState({
          stdWiseGraph: {
            title: {
              text: "Count",
            },
            xAxis: {
              categories: labels,
              crosshair: true,
            },
            labels: {
              items: [
                {
                  html: "",
                  style: {
                    left: "50px",
                    top: "18px",
                  },
                },
              ],
            },
            series: series,
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              width: (window.innerWidth - 260 - 120) / 2,
              type: "column",
            },
          },
        });
      });
  };

  render() {
    let { stdWiseGraph } = this.state;

    return (
      <>
        <div
          style={{
            marginTop: "15px",
            backgroundColor: "#ECFBFF",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ fontSize: "20px", padding: "10px" }}>सुस्वागतम!</div>
          <div
            style={{
              display: "flex",
              justifyContent: "row",
              marginLeft: "18%",
            }}
          >
            <img
              width="42%"
              src={home}
              style={{ marginLeft: "15%", marginBottom: "15px" }}
            />
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              padding: "10px",
              color: "#4B66B9",
              padding: "10px",
            }}
          >
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets cont */}
            समता, सामाजिक न्याय, लोकशाही आणि मानवी समाजामध्ये न्यायाची
            प्रस्थापना या बाबी, सर्व मुलांच्या प्राथमिक शिक्षणाच्या माध्यमातून
            साधली जाऊ शकतात. यादृष्टीने शाळा व्यवस्थापन समितीची निर्मिती झाली.
            त्यामुळे सहा ते चौदा वयोगटातील सर्व बालकांना मोफत व सक्तीचे प्राथमिक
            शिक्षण पुरविण्याची, त्यांना शाळांमध्ये प्रवेश देण्याची उपस्थितीची
            आणि प्राथमिक शिक्षण पूर्ण करण्याची जबाबदारी शासनाने स्वीकारली आहे.
            शिक्षणाच्या विकासात लोकसहभाग रहावा यादृष्टीने राज्यातील प्रत्येक
            प्राथमिक शाळेकरिता व्यवस्थापनपरत्वे ग्राम शिक्षण समिती, वार्ड शिक्षण
            समिती अथवा खाजगी शाळांच्या बाबतीत शाळा समिती गठीत करण्यात येते. या
            समित्या शाळांच्या भौतिक,शैक्षणिक विकासाच्या बाबतीत महत्वपूर्ण भूमिका
            बजावतात. त्यासाठी संबंधित शाळा समित्यांना शासनाने काही आर्थिक
            अधिकार, कर्तव्ये तसेच काही उत्तरदायित्वे प्रदान केलेली आहेत.
            समित्यांची कार्ये बऱ्याच प्रमाणात शाळांच्या विकासासाठी उपयुक्त आहेत.
            बालकांचा मोफत व सक्तीच्या शिक्षणाचा अधिकार अधिनियम २००९ अनुसार
            शाळांसाठी शाळा व्यवस्थापन समिती महत्वाचे आहे.
          </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            backgroundColor: "#F2BDBD",
            fontWeight: "bold",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "20px",
            color: "#000000",
          }}
        >
          <p>महत्वाची सूचना :</p>
          <p>१. सर्व मुद्दे भरणे अनिवार्य आहे</p>
          <p>२. सर्व फोटोस २०० kb पेक्षा कमी आकाराचे हवे</p>
          <p>३. अंकांना फक्त इंग्रजीत लिहावे (Ex. 1,2,3,…)</p>
          <p>४. लागू नसल्यासं NA/0 असे लिहावे</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "50px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#1ACA2B",
              color: "#fff",
              padding: "10px",
            }}
            onClick={() => this.props.history.push("/user/createform")}
          >
            माहिती अद्यावत करा
          </Button>
          {console.log(this.state.versionArray, "version")}
          {this.state.versionArray ? (
            <Button
              style={{
                backgroundColor: "#1ACA2B",
                color: "#fff",
                padding: "10px",
                marginLeft: "10px",
              }}
              onClick={() => this.props.history.push("/user/schoolDetail")}
            >
              आधी भरलेली माहिती पहा{" "}
            </Button>
          ) : null}
        </div>
      </>
      //     <Card>
      //     <CardHeader color="primary">
      //         <h4 className={''}> Head Master Dashboard</h4>

      //     </CardHeader>
      //     <CardBody>

      //     <div >
      //         <HighchartsReact
      //         // style={{marginLeft:'30%'}}
      //             highcharts={Highcharts}
      //             options={stdWiseGraph}
      //         ></HighchartsReact>

      //             <FormControl style={{marginLeft:'20%'}} >
      //                 <InputLabel id="demo-simple-select-label">filter</InputLabel>
      //                 <Select
      //                     labelId="demo-controlled-open-select-label2"
      //                     id="demo-controlled-open-select2"
      //                     style={{ width: "7em" }}
      //                     label="filter"
      //                     value={this.state.filterDropdownId}
      //                     onChange={(e) => {
      //                         console.log(e.target.value, "E");
      //                         this.setState({
      //                             filterDropdownId: e.target.value,
      //                             showLoader: true,
      //                             filterName: e.target.value == 3 ? "gender" : e.target.value == 6 ? "Caste" : "income",
      //                             ActiveStudentKey: e.target.value == 1 ? true : false,
      //                             uniqueContactKey: e.target.value == 2 ? true : false,
      //                             moreFilter: e.target.value == 3 || e.target.value == 5 || e.target.value == 6 ? true : false,
      //                             avgTimeGraph: e.target.value == 4 ? true : false,
      //                             incomeFilter: e.target.value == 5 ? true : false,
      //                         },
      //                             () => {
      //                                 this.getStudentDataForChart()
      //                             })
      //                     }
      //                     }
      //                 >
      //                     <MenuItem value={1}>Active student</MenuItem>
      //                     <MenuItem value={2}>Unique Contacts</MenuItem>
      //                     <MenuItem value={3}>Gender</MenuItem>
      //                     <MenuItem value={4}>Avg Time</MenuItem>
      //                     <MenuItem value={5}>Income</MenuItem>
      //                     <MenuItem value={6}>Caste</MenuItem>
      //                     <MenuItem value={0}>student count</MenuItem>

      //                 </Select>

      //             </FormControl>
      //         </div>
      //     </CardBody>
      // </Card>
    );
  }
}
