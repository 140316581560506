import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSuperAdminService } from "service/service";

const ShowPremiumResources = (props) => {
  const { setIsEdit, setShowTable } = props;
  const history = useHistory();
  const [resourcesList, setResourcesList] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [checkedMap, setCheckedMap] = useState({});
  const [open, setOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [resourceName, setResourceName] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [resourceCost, setResourceCost] = useState(null);
  const [resourceTitle, setResourceTitle] = useState(null);
  const [resourceValidity, setResourceValidity] = useState(null);
  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    { title: "Validity Date", field: "validity" },
    { title: "Cost Of Resource", field: "cost" },
  ]);

  const [data, setData] = useState([]);

  const fetchResources = async () => {
    let limit = 10;
    let url = `premium/resource-details/?limit=${limit}&offset=${offset}`;
    getSuperAdminService()
      .getPremiumResource(url)
      .then((res) => {
        if (res?.status === 200) {
          console.log("data", res);
          setResourcesList(res?.data?.results);
          setTotalCount(res?.data?.count);
        }
      });
  };

  const fetchPreviousData = async () => {
    setPage((prevCount) => prevCount - 1);
    let data = offset > 0 ? offset - 10 : offset;
    setOffset(data);
  };

  useEffect(() => {
    fetchResources();
  }, [offset, page]);

  const fetchNextData = async () => {
    setPage((prevCount) => prevCount + 1);
    let data = offset + 10;
    if (data > totalCount) {
      return;
    } else {
      setOffset(data);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  useEffect(() => {
    if (resourcesList) {
      console.log("List", resourcesList);

      setData(resourcesList);
      // Initialize checkedMap with default checked value true for each row
      const initialCheckedMap = {};
      resourcesList.forEach((row) => {
        initialCheckedMap[row.id] = !row?.is_deactivated;
      });
      setCheckedMap(initialCheckedMap);
    }
  }, [resourcesList]);

  const handleChange = async (event, id, data) => {
    const payload = {
      name: data?.name,
      validity: data?.validity,
      title: data?.title,
      cost: data?.cost,
      is_deactivated: !event.target.checked,
    };
    // Update the checked state for the row with the given ID
    setCheckedMap((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
    await getSuperAdminService()
      .deactivateResource(id, payload)
      .then((res) => {
        console.log("res", res);
      });
  };

  useEffect(() => {
    if (deleteStatus === "Delete") {
      const payload = {
        name: resourceName,
        validity: resourceValidity,
        title: resourceTitle,
        cost: resourceCost,
        is_deleted: true,
      };
      getSuperAdminService()
        .deleteResource(resourceId, payload)
        .then((res) => {
          console.log(res);
          fetchResources();
        });

      setOpen(false);
    }
    if (deleteStatus === "Cancel") {
      setOpen(false);
    }
    setDeleteStatus(null);
  }, [deleteStatus]);

  const deleteResource = (data) => {
    setResourceId(data?.id);
    setResourceName(data?.name);
    setResourceTitle(data?.title);
    setResourceValidity(data?.validity);
    setResourceCost(data?.cost);
    setOpen(true);
  };

  const handleConfirmDelete = () => {
    setDeleteStatus("Delete");
  };

  const handleCloseConfirmation = () => {
    setDeleteStatus("Cancel");
  };

  console.log("DATA", data, resourcesList);
  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Resource delete confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Resource?
        </DialogContent>
        <DialogActions>
          <Button
            style={{ textTransform: "none" }}
            onClick={() => {
              handleCloseConfirmation();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ color: "red", textTransform: "none" }}
            onClick={() => {
              handleConfirmDelete();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ width: "90%", marginLeft: "4rem", marginTop: "3rem" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field}>{column.title}</TableCell>
                ))}
                <TableCell style={{ marginLeft: "1rem" }}>Update</TableCell>
                <TableCell style={{ marginLeft: "1rem" }}>Status</TableCell>
              </TableRow>
            </TableHead>

            {data?.map((row) => (
              <TableRow key={row.id} className="table-row-hover">
                {columns.map((column) => (
                  <TableCell key={column.field}>{row[column.field]}</TableCell>
                ))}
                <TableCell>
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setShowTable(false);
                        setIsEdit(true);
                        history.push({
                          pathname: "/admin/PremiumResources", // Replace with your edit resource route
                          state: { rowData: row }, // Pass rowData as part of the route state
                        });
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete" arrow>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        deleteResource(row);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Switch
                    color="primary"
                    checked={checkedMap[row.id]}
                    onChange={(event) => handleChange(event, row.id, row)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Button
            disabled={offset === 0}
            onClick={() => {
              fetchPreviousData();
            }}
          >
            <ArrowBack style={{ color: "black", fontSize: "30px" }} />
          </Button>
          <div style={{ marginTop: "0.5rem" }}>
            Showing Page {offset / 10 + 1} out of{" "}
            {totalCount === 10
              ? Math.floor(totalCount / 10)
              : Math.floor(totalCount / 10) + 1}
          </div>
          <Button
            onClick={() => {
              fetchNextData();
            }}
            disabled={
              totalCount <= 10 ||
              offset / 10 + 1 === Math.floor(totalCount / 10) + 1
            }
          >
            <ArrowForward style={{ color: "black", fontSize: "30px" }} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default ShowPremiumResources;
