"use client";
import React, { useEffect, useState } from "react";
import "../Nudge.css";
import NudgeNavbar from "./NudgeNavbar";
import { getSuperAdminService } from "service/service";
import { getContentWriterService } from "service/service";
import httpV2 from "common/axiosV2";
import Snackbar from "components/Snackbar/Snackbar";
import MyLottieComponent from "./MyLottieComponent";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Checkbox,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { CircularProgress } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import http from "common/axios";
import Button from "components/CustomButtons/Button.js";

function EditNudge() {
  const { id } = useParams();
  const history = useHistory();
  const [nudgeConfig, setNudgeConfig] = useState([]);
  // user inputs
  const [sn, setSn] = useState("");
  const [title, setTitle] = useState("");
  const [nudge, setNudge] = useState("");
  const [nudgeList, setNudgeList] = useState([]);
  const [triggerParameter, setTriggerParameter] = useState("");
  const [triggerKey, setTriggerKey] = useState("");
  const [initialTriggerValue, setInitialTriggerValue] = useState("");
  const [finalTriggerValue, setFinalTriggerValue] = useState("");
  const [waitDuration, setWaitDuration] = useState("");
  const [repeatCount, setRepeatCount] = useState("");
  const [repeatDuration, setRepeatDuration] = useState("");

  const [subject_id, setSubject_id] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [chapter_id, setChapter_id] = useState("");
  const [chapterList, setChapterList] = useState([]);
  const [specialClass_id, setSpecialClass_id] = useState("");
  const [specialClassList, setSpecialClassList] = useState([]);
  const [challenge_id, setChallenge_id] = useState("");
  const [challengeList, setChallengeList] = useState([]);
  const [milestone_id, setMilestone_id] = useState("");
  const [milestoneList, setMilestoneList] = useState([]);

  const [checkCondition, setCheckCondition] = useState("");
  const [checkParameter, setCheckParameter] = useState("");
  const [notificationPlatform, setNotificationPlatform] = useState("");

  const [wpTemplate, setWpTemplate] = useState("");
  const [wpTemplateList, setWpTemplateList] = useState([]);
  const [wpMessage, setWpMessage] = useState("");
  const [notification_title, setNotification_title] = useState("");
  const [nudgeMessage, setNudgeMessage] = useState("");
  const [isValidMessage, setIsValidMessage] = useState("");
  // image
  const [nudgeImage, setNudgeImage] = useState("");
  const [nudgeImageURL, setNudgeImageURL] = useState("");
  const [nudgeImageURLS3, setNudgeImageURLS3] = useState("");
  const [nudgeImageErrorMsg, setNudgeImageErrorMsg] = useState(false);
  const [nudgeImageUploadDisabled, setNudgeImageUploadDisabled] = useState(
    false
  );
  const [lottiFetch, setlottiFetch] = useState(null);
  //snackbar
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  //error handling
  const [showError, setShowError] = useState(false);
  const [showMCQChallengeError, setShowMCQChallengeError] = useState(false);

  // filter
  const [state_id, setState_id] = useState("");
  const [stateList, setStateList] = useState([]);
  const [district_id, setDistrict_id] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [student_block_id, setStudent_block_id] = useState("");
  const [blockList, setBlockList] = useState([]);
  const [syllabus_id, setSyllabus_id] = useState(3);
  const [syllabusList, setSyllabusList] = useState([]);
  const [medium_id, setMedium_id] = useState("");
  const [mediumList, setMediumList] = useState([]);
  const [class_id, setClass_id] = useState("");
  const [classList, setClassList] = useState([]);
  //Advance filter
  // const [user_type, setUser_type] = useState("");
  // const [advanceFilterList, setAdvanceFilterList] = useState([]);
  // const [timeframe, setTimeframe] = useState("");
  // const [advanceFilterTimeframeList, setAdvanceFilterTimeframeList] = useState(
  //   []
  // );
  // const [timeframeValue, setTimeframeValue] = useState("");
  // const [timeframeValueList, setTimeframeValueList] = useState([]);
  // const [advanceFilterPoints, setAdvanceFilterPoints] = useState("");
  // const [advanceFilterComparison, setAdvanceFilterComparison] = useState();
  // const [
  //   advanceFilterComparisonList,
  //   setAdvanceFilterComparisonList,
  // ] = useState([]);

  // school
  const [schoolSearchTxt, setSchoolSearchTxt] = useState("");
  const [schoolPageNo, setSchoolPageNo] = useState(1);
  const [selectedSchools, setSelectedSchools] = useState([]); //school id list
  const [selectedSchoolsObject, setSelectedSchoolsObject] = useState([]); //school id and name object
  const [schoolList, setSchoolList] = useState([]); //school list from Api
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  //immediate
  const [immediateNudge, setImmediateNudge] = useState(false);
  // Repeat Nudge
  const [repeatNudge, setRepeatNudge] = useState(false);
  const [is_deleted, setIs_deleted] = useState("");
  const [createNudgePayload, setCreateNudgePayload] = useState({});
  const [loader, setLoader] = useState(false);

  const [validateMessageDisabled, setValidateMessageDisabled] = useState(true);

  const handleCreateNudgePayload = () => {
    let createNudgePayload = {};

    if (id) {
      createNudgePayload["_id"] = id;
    } else {
      delete createNudgePayload._id;
    }
    if (sn) {
      createNudgePayload["sn_no"] = sn;
    } else {
      delete createNudgePayload.sn_no;
    }

    // user group
    if (state_id) {
      createNudgePayload["state_id"] = state_id;
    } else {
      delete createNudgePayload.state_id;
    }
    if (district_id) {
      createNudgePayload["district_id"] = district_id;
    } else {
      delete createNudgePayload.district_id;
    }
    if (student_block_id) {
      createNudgePayload["block_id"] = student_block_id;
    } else {
      delete createNudgePayload.block_id;
    }
    if (medium_id) {
      createNudgePayload["medium_id"] = medium_id;
    } else {
      delete createNudgePayload.medium_id;
    }
    if (class_id) {
      createNudgePayload["class_id"] = class_id;
    } else {
      delete createNudgePayload.class_id;
    }
    // school
    if (selectedSchools) {
      createNudgePayload["school_id"] = selectedSchools;
    } else {
      delete createNudgePayload.school_id;
    }

    // Challenge info
    createNudgePayload["nudges_info"] = {};
    if (subject_id) {
      createNudgePayload.nudges_info["subject_id"] = parseInt(subject_id);
    } else {
      delete createNudgePayload.nudges_info?.subject_id;
    }
    if (chapter_id) {
      createNudgePayload.nudges_info["chapter_id"] = parseInt(chapter_id);
    } else {
      delete createNudgePayload.nudges_info?.chapter_id;
    }
    if (specialClass_id) {
      createNudgePayload.nudges_info["specialClass_id"] = parseInt(
        specialClass_id
      );
    } else {
      delete createNudgePayload.nudges_info?.specialClass_id;
    }
    if (challenge_id) {
      createNudgePayload.nudges_info["challenge_id"] = challenge_id;
    } else {
      delete createNudgePayload.nudges_info?.challenge_id;
    }
    if (milestone_id) {
      createNudgePayload.nudges_info["milestone_id"] = milestone_id;
    } else {
      delete createNudgePayload.nudges_info?.milestone_id;
    }

    //User Input
    if (title) {
      createNudgePayload["nudges_title"] = title;
    } else {
      delete createNudgePayload.nudges_title;
    }
    if (nudge) {
      createNudgePayload["nudges_type"] = nudge;
    } else {
      delete createNudgePayload.nudges_type;
    }

    if (triggerParameter) {
      createNudgePayload["trigger_parameter"] = triggerParameter;
    } else {
      delete createNudgePayload.trigger_parameter;
    }
    if (triggerKey) {
      createNudgePayload["trigger_key"] = triggerKey;
    } else {
      delete createNudgePayload.trigger_key;
    }
    if (initialTriggerValue) {
      createNudgePayload["initial_trigger_value"] = initialTriggerValue;
    } else {
      delete createNudgePayload.initial_trigger_value;
    }
    if (finalTriggerValue) {
      createNudgePayload["final_trigger_value"] = finalTriggerValue;
    } else {
      delete createNudgePayload.final_trigger_value;
    }
    if (checkCondition) {
      createNudgePayload["check_condition"] = checkCondition;
    } else {
      delete createNudgePayload.check_condition;
    }
    if (checkParameter) {
      createNudgePayload["check_parameter"] = checkParameter;
    } else {
      delete createNudgePayload.check_parameter;
    }
    if (waitDuration) {
      createNudgePayload["timeframe_duration"] = waitDuration;
    } else if (immediateNudge) {
      createNudgePayload["timeframe_duration"] = 0;
    } else {
      delete createNudgePayload.timeframe_duration;
    }
    if (immediateNudge) {
      createNudgePayload["immediate"] = immediateNudge;
    } else {
      delete createNudgePayload.immediate;
    }
    if (repeatNudge) {
      createNudgePayload["repeat"] = repeatNudge;
    } else {
      delete createNudgePayload.repeatNudge;
    }
    if (repeatCount) {
      createNudgePayload["repeat_count"] = repeatCount;
    } else {
      delete createNudgePayload.repeat_count;
    }
    if (repeatDuration) {
      createNudgePayload["repeat_time_duration"] = repeatDuration;
    } else {
      delete createNudgePayload.repeat_time_duration;
    }
    if (notificationPlatform) {
      createNudgePayload["notification_platform"] = notificationPlatform;
    } else {
      delete createNudgePayload.notification_platform;
    }
    if (wpTemplate) {
      createNudgePayload["templates"] = wpTemplate;
    } else {
      delete createNudgePayload.templates;
    }
    if (nudgeMessage) {
      createNudgePayload["messages"] = nudgeMessage;
    } else {
      delete createNudgePayload.messages;
    }
    if (notification_title) {
      createNudgePayload["notification_title"] = notification_title;
    } else {
      delete createNudgePayload.notification_title;
    }

    // Image
    if (nudgeImageURLS3) {
      createNudgePayload["image"] = nudgeImageURLS3;
    } else {
      delete createNudgePayload.image;
    }
    if (
      nudgeImageURLS3?.substring(
        nudgeImageURLS3?.length - 4,
        nudgeImageURLS3?.length
      ) === "json"
    ) {
      createNudgePayload["lotti_fetch_key"] = lottiFetch;
    } else {
      delete createNudgePayload.lotti_fetch_key;
    }

    return createNudgePayload;
  };

  const immediateNudgeToggler = () => {
    setImmediateNudge((prevState) => !prevState);
  };

  const repeatNudgeToggler = () => {
    setRepeatNudge((prevState) => !prevState);
  };

  const validateNudgeMessage = () => {
    const inputString = nudgeMessage;
    const containsEmptyCurlyBraces = /{}+/g.test(inputString);
    if (inputString) {
      if (!containsEmptyCurlyBraces) {
        const matches = inputString.match(/{([^{}]+)}/g);
        const resultArray = matches
          ? matches.map((match) => match.slice(1, -1))
          : [];

        console.log(resultArray, "++++");

        const isValid = resultArray.every((value) =>
          nudgeConfig[0]?.variables?.includes(value)
        );
        setIsValidMessage(isValid);

        if (isValid) {
          setError(false);
          setMessage("Your message is valid");
          setValidateMessageDisabled(true);
        }
        if (!isValid) {
          setMessage("Only use the available variables");
          setError(true);
        }
      } else if (containsEmptyCurlyBraces) {
        setMessage("Do not use empty {}");
        setError(true);
      }
    } else if (inputString === "") {
      setMessage("Message cannot be empty");
      setError(true);
    }
  };

  const handlePositiveNumberInput = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    const intValue = inputValue === "" ? "" : parseInt(inputValue, 10);
    return intValue;
  };

  const uploadNudgeImageAndGetURL = async () => {
    if (nudgeImage.name.split(".").pop() === "json") {
      const res = await fetch(nudgeImageURL);
      const val = await res.json();
      console.log(val, "val");
      setlottiFetch(val);
    }

    try {
      const formData = new FormData();
      formData.append("image", nudgeImage);
      const uploadResponse = await getSuperAdminService().getImageURLs3(
        formData
      );

      setNudgeImageURLS3(uploadResponse.data.file);
      setError(false);
      setMessage("Image uploaded successfully");
      setNudgeImageUploadDisabled(true);
    } catch (error) {
      console.error("Error get image URL from s3 bucket", error);
      setMessage("Error uploading image");
      setError(true);
    }
  };
  // const uploadNudgeImageAndGetURL = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("image", nudgeImage);
  //     const uploadResponse = await getSuperAdminService().getImageURLs3(
  //       formData
  //     );
  //     setNudgeImageURLS3(uploadResponse.data.file);
  //     setError(false);
  //     setMessage("Image uploaded successfully");
  //     setNudgeImageUploadDisabled(true);
  //   } catch (error) {
  //     console.error("Error get image URL from s3 bucket", error);
  //     setMessage("Error uploading image");
  //     setError(true);
  //   }
  // };

  async function getData() {
    let stateRes = await getSuperAdminService().getAllState();
    stateRes = stateRes.data;
    if (stateRes && stateRes.status == 200) {
      setStateList(stateRes.response);
    }
  }

  async function getDistrictsByState(state_id) {
    let response = await getSuperAdminService().getStudentDistrict({
      student_state_id: state_id,
    });
    response = response.data;
    if (response && response.status == 200) {
      setDistrictList(response.response);
    }
  }

  async function getStudentBlock(district_id) {
    try {
      let response = await getSuperAdminService().getStudentBlock({
        student_district_id: district_id,
      });
      response = response.data;
      if (response && response.status == 200) {
        setBlockList(response.response);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  async function getSyllabus() {
    let param = {
      board_id: 2,
    };
    let CWDistrictRes = await getSuperAdminService().getAllDistrictFromBoard(
      param
    );
    CWDistrictRes = CWDistrictRes.data;
    if (CWDistrictRes && CWDistrictRes.status == 200) {
      setSyllabusList(CWDistrictRes.response);
    }
  }

  async function getMedium() {
    let param = { state_id: 1 };
    let mediumRes = await getContentWriterService().getMediumbyState(param);
    mediumRes = mediumRes.data;
    if (mediumRes && mediumRes.status == 200) {
      setMediumList(mediumRes.response);
    }
  }

  async function getClass(syllabus_id) {
    let param = {
      district_id: syllabus_id,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(
      param
    );
    classRes = classRes.data;
    if (classRes && classRes.status == 200) {
      setClassList(classRes.response);
    }
  }

  async function getSpecialClass() {
    let param = {
      district_id: 7,
      board_id: 3,
    };
    let classRes = await getSuperAdminService().getAllClassesFromDistrict(
      param
    );
    classRes = classRes.data;
    if (classRes && classRes.status == 200) {
      setSpecialClassList(classRes.response);
    }
  }

  async function getAllSubject() {
    if (nudge === "AI Generated Reels On Topic") {
      let URL = `get_all_subjects_v2/?is_common_reels=True&class_id=${class_id}&medium_id=${medium_id}`;

      let subRes = await http.get(URL);
      subRes = subRes.data;
      if (subRes) {
        let newSubRes = subRes.response.filter(function (sub) {
          return sub.is_subject_reels === true;
        });

        setSubjectList(newSubRes);
        // setSubjectList(subRes.response)
      } else {
        setSubjectList([]);
      }
    } else {
      let param = {
        class_id: class_id,
        medium_id: medium_id,
      };
      let subRes = await getContentWriterService().getSubjectByClassMed(param);
      setSubjectList(subRes.data.response);
    }
  }

  async function getChapterListByChapter(subject_id) {
    let URL = "chapters/";
    // if (selectedChallenge === "MCQ Set") {
    //   URL = 'chapters/?mcq_exits=true'
    // }
    let chapterListData = await httpV2.get(URL, {
      params: {
        limit: 100,
        offset: 0,
        status: "PUBLISHED",
        subject_id: subject_id,
        // medium_id: medium_id,
      },
    });
    chapterListData = chapterListData.data;
    if (chapterListData) {
      setChapterList(chapterListData.results);
      if (chapterListData.results?.length < 1) {
        setShowMCQChallengeError(true);
      } else {
        setShowMCQChallengeError(false);
      }
    } else {
      setChapterList([]);
    }
  }

  const getSchoolsForBlockData = async () => {
    const param = {
      student_block_id: student_block_id,
      searchText: schoolSearchTxt,
    };

    try {
      const response = await getSuperAdminService().getSchoolsForBlock(param);
      setSchoolList(response.data.response);
    } catch (error) {
      console.error("Error fetching school list", error);
    }
  };

  // const getAdvanceFilter = async () => {
  //   try {
  //     const response = await getSuperAdminService().getAdvanceFilterData();
  //     setAdvanceFilterList(response.data.response);
  //   } catch (error) {
  //     console.error("Error fetching advance filter list", error);
  //   }
  // };

  // const getAdvanceFilterTimeframe = async (value) => {
  //   try {
  //     const response = await getSuperAdminService().getAdvanceFilterTimeframeData(
  //       value
  //     );
  //     setAdvanceFilterTimeframeList(response.data.response);
  //   } catch (error) {
  //     console.error("Error fetching advance filter timeframe list", error);
  //   }
  // };

  // const getAdvanceFilterTimeframeValue = async (value) => {
  //   try {
  //     const response = await getSuperAdminService().getAdvanceFilterTimeframeValueData(
  //       user_type,
  //       value
  //     );
  //     setTimeframeValueList(response.data.response);
  //   } catch (error) {
  //     console.error(
  //       "Error fetching advance filter timeframe value list",
  //       error
  //     );
  //   }
  // };

  // const getAdvanceFilterComparison = async () => {
  //   try {
  //     const response = await getSuperAdminService().getAdvanceFilterComparisonData(
  //       user_type,
  //       timeframe,
  //       timeframeValue
  //     );
  //     setAdvanceFilterComparisonList(response.data.response);
  //   } catch (error) {
  //     console.error("Error fetching advance filter comparison list", error);
  //   }
  // };

  const getAllCreatedChallenge = async (filterString) => {
    try {
      const response = await getSuperAdminService().getAllCreatedChallengeData(
        filterString
      );
      setChallengeList(
        response.data.results.filter(function (challenge) {
          return Object.keys(challenge.advance_filter).length < 1;
        })
      );
    } catch (error) {
      console.error("Error fetching challenge list", error);
    }
  };

  const getAllCreatedMilestone = async () => {
    try {
      // setMilestoneLoader(true);
      const response = await getSuperAdminService().getAllCreatedMilestoneData(
        `is_deleted=false&limit=${10000}&offset=${0}`
      );
      setMilestoneList(response.data.results);
      // setMilestoneLoader(false);
    } catch (error) {
      // setMilestoneLoader(false);
      console.error("Error fetching Milestone list", error);
    }
  };

  const getNudgeList = async () => {
    try {
      const response = await getSuperAdminService().getNudgeList();
      setNudgeList(response.data.nudges_type);
    } catch (error) {
      console.error("Error fetching nudge list", error);
    }
  };

  const getTemplate = async (nudge, platform) => {
    try {
      const response = await getSuperAdminService().getWhatsappTemplates(
        nudge,
        platform
      );
      if (platform === "WhatsApp") {
        setWpTemplateList(response.data.results);
      } else {
        setWpTemplateList([]);
      }
    } catch (error) {
      console.error("Error fetching template list", error);
    }
  };

  const getNudgeConfig = async (selected_nudge) => {
    try {
      const response = await getSuperAdminService().getNudgeConfig(
        selected_nudge
      );
      setNudgeConfig(response.data.results);
      if (response.data.results[0]?.nudges_info?.includes("Special Class")) {
        getSpecialClass();
      }
    } catch (error) {
      console.error("Error fetching nudge configuration", error);
    }
  };

  const handleEditNudge = async () => {
    setShowError(true);
    if (
      ((notificationPlatform === "WhatsApp" && wpTemplate !== "") ||
        notificationPlatform !== "WhatsApp") &&
      ((notificationPlatform === "WhatsApp" && wpMessage !== "") ||
        notificationPlatform !== "WhatsApp") &&
      ((notificationPlatform === "Drawer" && nudgeMessage !== "") ||
        notificationPlatform !== "Drawer") &&
      ((notificationPlatform === "Drawer" && notification_title !== "") ||
        notificationPlatform !== "Drawer")
    ) {
      if (validateMessageDisabled || notificationPlatform !== "Drawer") {
        let payload = handleCreateNudgePayload();
        try {
          let response = await getSuperAdminService().updateNudge(id, payload);
          if (response.status === 200) {
            setError(false);
            setMessage("Saved Changes successfully");
            const timeoutId = setTimeout(() => {
              if (is_deleted) {
                history.push("/admin/nudge", { coming: "disabled" });
              } else {
                history.push("/admin/nudge", { coming: "ongoing" });
              }
            }, 1000);
          } else if (response.data.status === 400) {
            setError(true);
            setMessage("Error updating nudge");
          }
        } catch (error) {
          setError(true);
          setMessage("Error updating nudge");
          console.error("Error updating nudge", error);
        }
      } else {
        setError(true);
        setMessage("Validate message");
      }
    } else {
      setError(true);
      setMessage("Fill all the required fields");
    }
  };

  const getSingleNudge = async () => {
    try {
      setLoader(true);
      const response = await getSuperAdminService().readSingleNudge(id);
      setLoader(false);
      const nudge = response.data;

      if (nudge.sn_no) {
        setSn(nudge.sn_no);
      }
      if (nudge.state_id) {
        setState_id(nudge.state_id);
        getDistrictsByState(nudge.state_id);
      }
      if (nudge.district_id) {
        setDistrict_id(nudge.district_id);
        getStudentBlock(nudge.district_id);
      }
      if (nudge.block_id) {
        setStudent_block_id(nudge.block_id);
      }
      if (nudge.medium_id) {
        setMedium_id(nudge.medium_id);
      }
      if (nudge.class_id) {
        setClass_id(nudge.class_id);
      }
      //school
      if (nudge.school_id) {
        setSelectedSchools(nudge.school_id);
      }
      if (nudge.school_list) {
        setSelectedSchoolsObject(nudge.school_list);
      }

      // nudge info
      if (nudge.nudges_info.subject_id) {
        setSubject_id(nudge.nudges_info.subject_id);
        console.log(nudge.nudges_info.subject_id, "subject_id");
        // getAllSubject()
        getChapterListByChapter(nudge.nudges_info.subject_id);
      }
      if (nudge.nudges_info.chapter_id) {
        setChapter_id(nudge.nudges_info.chapter_id);
      }
      if (nudge.nudges_info.specialClass_id) {
        setSpecialClass_id(nudge.nudges_info.specialClass_id);
      }
      if (nudge.nudges_info.challenge_id) {
        setChallenge_id(nudge.nudges_info.challenge_id);
      }
      if (nudge.nudges_info.milestone_id) {
        setMilestone_id(nudge.nudges_info.milestone_id);
      }

      //User Input
      if (nudge.nudges_title) {
        setTitle(nudge.nudges_title);
      }
      if (nudge.nudges_type) {
        setNudge(nudge.nudges_type);
        getNudgeConfig(nudge.nudges_type);
      }

      if (nudge.trigger_parameter) {
        setTriggerParameter(nudge.trigger_parameter);
      }
      if (nudge.trigger_key) {
        setTriggerKey(nudge.trigger_key);
      }
      if (nudge.initial_trigger_value) {
        setInitialTriggerValue(nudge.initial_trigger_value);
      }
      if (nudge.final_trigger_value) {
        setFinalTriggerValue(nudge.final_trigger_value);
      }
      if (nudge.check_condition) {
        setCheckCondition(nudge.check_condition);
      }
      if (nudge.check_parameter) {
        setCheckParameter(nudge.check_parameter);
      }
      if (nudge.timeframe_duration) {
        setWaitDuration(nudge.timeframe_duration);
      }
      if (nudge.repeat) {
        setRepeatNudge(nudge.repeat);
      }
      if (nudge.repeat_count) {
        setRepeatCount(nudge.repeat_count);
      }
      if (nudge.repeat_time_duration) {
        setRepeatDuration(nudge.repeat_time_duration);
      }
      if (nudge.notification_platform) {
        setNotificationPlatform(nudge.notification_platform);

        if (nudge.nudges_type && nudge.notification_platform === "WhatsApp") {
          getTemplate(nudge.nudges_type, nudge.notification_platform);
        }
      }
      if (nudge.templates) {
        setWpTemplate(nudge.templates);
      }
      if (nudge.notification_title) {
        setNotification_title(nudge.notification_title);
      }
      if (nudge.messages) {
        setNudgeMessage(nudge.messages);
      }

      // Image
      if (nudge.image) {
        setNudgeImageURLS3(nudge.image);
      }
      if (nudge.lotti_fetch_key) {
        setlottiFetch(nudge.lotti_fetch_key);
      }

      //status
      if (nudge.is_deleted) {
        setIs_deleted(nudge.is_deleted);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching single nudge ", error);
    }
  };

  useEffect(() => {
    getSingleNudge();
    getData();
    getMedium();
    getNudgeList();
    getAllCreatedMilestone();
    // getAdvanceFilter();
    getClass(syllabus_id);
  }, []);

  useEffect(() => {
    if (wpTemplate && wpTemplateList.length > 0) {
      let selectedTemplate = wpTemplateList?.filter(function (template) {
        return template._id === wpTemplate;
      });
      setWpMessage(selectedTemplate[0].message);
    }
  }, [wpTemplateList]);

  useEffect(() => {
    setChallenge_id("");
    // setChallengeList("");
    let filterString = "";
    if (state_id) {
      filterString += `student_state_id=${state_id}&`;
    }
    if (district_id) {
      filterString += `student_district_id=${district_id}&`;
    }
    if (student_block_id) {
      filterString += `student_block_id=${student_block_id}&`;
    }
    if (syllabus_id) {
      filterString += `district_id=${syllabus_id}&`;
    }
    if (medium_id) {
      filterString += `medium_id=${medium_id}&`;
    }
    if (class_id) {
      filterString += `class_id=${class_id}&`;
    }
    filterString += `status=ACTIVE&`;

    getAllCreatedChallenge(
      `${filterString}
      ordering=-created_at&limit=1000&offset=0`
    );
  }, [
    state_id,
    district_id,
    student_block_id,
    syllabus_id,
    medium_id,
    class_id,
  ]);

  // useEffect(() => {
  //   setDistrict_id("");
  //   setDistrictList([]);
  //   if (state_id) {
  //     getDistrictsByState(state_id);
  //   }
  //   setStudent_block_id("");
  //   setBlockList([]);
  // }, [state_id]);

  // useEffect(() => {
  //  if(nudgeConfig[0]?.nudges_info?.includes("Special Class")) {
  //   getSpecialClass()
  //  }
  // }, [nudgeConfig])

  useEffect(() => {
    if (student_block_id) {
      getSchoolsForBlockData();
    }
  }, [schoolPageNo, schoolSearchTxt, student_block_id]);

  useEffect(() => {
    // setSubject_id("");
    // setSubjectList([]);
    if (class_id && medium_id && nudge) {
      getAllSubject();
    }
    // setChapter_id("");
    // setChapterList([]);
  }, [class_id, medium_id, nudge]);

  // useEffect(() => {
  //   setChapter_id('')
  //   setChapterList([])
  //   if (subject_id) {
  //     getChapterListByChapter()
  //   }
  // }, [subject_id]);

  // useEffect(() => {
  //   handleCreateNudgePayload();
  // }, [
  //   nudge,
  //   value,
  //   reward,
  //   diamond,
  //   reward_message,
  //   title,
  //   nudgeImageURLS3,
  //   description,
  // ]);

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        mess
        message={message}
        color={error ? "danger" : "success"}
        close={false}
        place="br"
        closeNotification={() => {
          setMessage("");
        }}
        rtlActive={true}
        open={message ? true : false}
      ></Snackbar>
      <div className="whiteBackground p-4 milestones">
        <NudgeNavbar
          page={"edit"}
          pageLink={"Edit Nudge"}
          path={"nudges / edit-nudge / " + id}
          setPage={() => {}}
        />
        {loader == true ? (
          <div
            style={{
              marginLeft: "50%",
              marginTop: "10%",
            }}
          >
            <CircularProgress color="primary" size={40} />
          </div>
        ) : (
          <>
            <div className="primaryPurple p-4 my-4 borderRadious16">
              <div>
                <span className="title2 colorBlack ">Select User Group</span>
              </div>
              <div className="d-flex pt-4 row">
                <div className="col-4 pe-3 pb-3">
                  <div>
                    <span className="Regular16 ps-3 mb-2">Select State*</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={state_id}
                    onChange={(e) => {
                      setState_id(e.target.value);

                      setDistrict_id("");
                      setDistrictList([]);
                      if (e.target.value) {
                        getDistrictsByState(e.target.value);
                      }
                      setStudent_block_id("");
                      setBlockList([]);
                    }}
                  >
                    <option key={"state"} value="" selected>
                      Select State*
                    </option>
                    {stateList.map((state, index) => {
                      return (
                        <option key={index + "state"} value={state.state_id}>
                          {`${state.state_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() + `${state.state_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4 pe-3 pb-3">
                  <div>
                    <span className="Regular16 ps-3 mb-2">Select District</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={district_id}
                    onChange={(e) => {
                      setDistrict_id(e.target.value);

                      setStudent_block_id("");
                      setBlockList([]);
                      if (e.target.value) {
                        getStudentBlock(e.target.value);
                      }
                    }}
                  >
                    <option key={"district"} value="" selected>
                      Select District
                    </option>
                    {districtList.map((district, index) => {
                      return (
                        <option
                          key={index + "district"}
                          value={district.student_district_id}
                        >
                          {`${district.district_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() +
                            `${district.district_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4 pe-3 pb-3">
                  <div>
                    <span className="Regular16 ps-3 mb-2">Select Block</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={student_block_id}
                    onChange={(e) => {
                      setStudent_block_id(e.target.value);
                    }}
                  >
                    <option key={"block"} value="" selected>
                      Select Block
                    </option>
                    {blockList.map((block, index) => {
                      return (
                        <option
                          key={index + "block"}
                          value={block.student_block_id}
                        >
                          {`${block.block_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() + `${block.block_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4 pe-3 pb-3">
                  <div>
                    <span className="Regular16 ps-3 mb-2">Select Medium</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={medium_id}
                    onChange={(e) => {
                      setMedium_id(e.target.value);
                    }}
                  >
                    <option key={"medium"} value="" selected>
                      Select Medium
                    </option>
                    {mediumList.map((medium, index) => {
                      return (
                        <option key={index + "medium"} value={medium.medium_id}>
                          {`${medium.medium_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() + `${medium.medium_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4 pe-3 pb-3">
                  <div>
                    <span className="Regular16 ps-3 mb-2">Select Class</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={class_id}
                    onChange={(e) => {
                      setClass_id(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Select Class
                    </option>
                    {classList.map((cls, index) => {
                      return (
                        <option key={index + "class"} value={cls.class_id}>
                          {cls.class_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* <div className="col-4 pe-3 pb-3">
              <select
                className="form-select form-select-lg colorGray Regular16 p-3 inputSelect"
                aria-label=".form-select-lg example"
                value={user_type}
                onChange={(e) => {
                  setUser_type(e.target.value);

                  setTimeframe("");
                  setTimeframeValue("");
                  setAdvanceFilterPoints("");
                  if (e.target.value !== "" && e.target.value !== "Point") {
                    getAdvanceFilterTimeframe(e.target.value);
                  } else {
                    setAdvanceFilterTimeframeList([]);
                  }
                }}
              >
                <option value="" selected>
                  Advance Filter
                </option>
                {advanceFilterList?.map((item, index) => {
                  return (
                    <option key={index + "advanceFilter"} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              className={`${
                user_type === "Point" ? "" : "d-none"
              } col-4 pe-3 pb-2`}
            >
              <input
                className="form-control form-control-lg colorGray Regular16 p-3 inputSelect"
                type="text"
                placeholder="Points*"
                value={advanceFilterPoints}
                onChange={(e) => {
                  const intValue = handlePositiveNumberInput(e);
                  if (intValue > 0 && intValue <= 99999) {
                    setAdvanceFilterPoints(intValue);
                  } else if (intValue <= 0) {
                    setAdvanceFilterPoints("");
                  }
                }}
              />
            </div>
            <div
              className={`${
                user_type && user_type !== "Point" ? "" : "d-none"
              } col-4 pe-3 pb-3`}
            >
              <select
                className="form-select form-select-lg colorGray Regular16 p-3 inputSelect"
                aria-label=".form-select-lg example"
                value={timeframe}
                onChange={(e) => {
                  setTimeframe(e.target.value);

                  setTimeframeValue("");
                  if (user_type === "Champion User") {
                    getAdvanceFilterTimeframeValue(e.target.value);
                  } else {
                    setTimeframeValueList([]);
                  }
                }}
              >
                <option value="" selected>
                  Timeframe*
                </option>
                {advanceFilterTimeframeList?.map((item, index) => {
                  return (
                    <option key={index + "advanceFilterTimeframe"} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              className={`${
                user_type === "Champion User" && timeframe !== ""
                  ? ""
                  : "d-none"
              } col-4 pe-3 pb-3`}
            >
              <select
                className="form-select form-select-lg colorGray Regular16 p-3 inputSelect"
                aria-label=".form-select-lg example"
                value={timeframeValue}
                onChange={(e) => {
                  setTimeframeValue(e.target.value);
                }}
              >
                <option value="" selected>
                  Timeframe Value*
                </option>
                {timeframeValueList.map((item, index) => {
                  return (
                    <option
                      key={index + "advanceFilterTimeframeValue"}
                      value={item.actual_value}
                    >
                      {item.unit_value}
                    </option>
                  );
                })}
              </select>
            </div> */}
              </div>
              <div className="d-flex row">
                <div>
                  <span className="Regular16 ps-3 mb-2">Select School</span>
                </div>
                <div className={`col-8 pe-3`}>
                  <Autocomplete
                    disabled={true}
                    value={selectedSchoolsObject}
                    onChange={(event, value, reason) => {
                      if (reason === "clear") {
                        setSelectedSchoolsObject([]);
                        setSelectedSchools([]);
                        return;
                      }
                      if (reason === "remove-option") {
                        setSelectedSchoolsObject(value);
                        let schoolId = [];
                        for (let i = 0; i < value?.length; i++) {
                          schoolId.push(value[i]?.school_id);
                        }
                        setSelectedSchools(schoolId);
                        return;
                      }
                      if (reason === "select-option") {
                        const updatedSchools = [...selectedSchoolsObject];
                        const selectedSchool = value.slice(-1)[0];
                        const schoolIndex = updatedSchools.findIndex(
                          (school) =>
                            school.school_id === selectedSchool.school_id
                        );

                        if (schoolIndex === -1) {
                          updatedSchools.push(selectedSchool);
                        } else {
                          updatedSchools.splice(schoolIndex, 1);
                        }
                        setSelectedSchoolsObject(updatedSchools);
                        let schoolId = [];
                        for (let i = 0; i < updatedSchools?.length; i++) {
                          schoolId.push(updatedSchools[i]?.school_id);
                        }
                        setSelectedSchools(schoolId);
                      }
                    }}
                    inputValue={schoolSearchTxt}
                    onInputChange={(event, newInputValue) => {
                      setSchoolSearchTxt(newInputValue);
                    }}
                    filterOptions={(options) => options}
                    multiple
                    id="checkboxes-tags-demo"
                    options={schoolList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.school_name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={
                            selectedSchoolsObject.findIndex(
                              (sch) => sch.school_id === option.school_id
                            ) > -1
                          }
                        />
                        {option.school_name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Search School"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={true}
                  type="button"
                  className={`btn buttonColorGray px-4 py-3 me-3 borderRadious16 regular18`}
                  onClick={() => {
                    setState_id("");
                    setDistrict_id("");
                    setStudent_block_id("");
                    setSyllabus_id(3);
                    setMedium_id("");
                    setClass_id("");
                    setSpecialClass_id("");
                    // setUser_type('')
                    // setTimeframe('')
                    // setTimeframeValue('')
                    // setAdvanceFilterPoints('')
                    // setAdvanceFilterComparison('')
                    setSelectedSchools([]);
                    setSelectedSchoolsObject([]);
                    setSchoolList([]);
                    delete createNudgePayload.student_state_id;
                    delete createNudgePayload.student_district_id;
                    delete createNudgePayload.student_block_id;
                    delete createNudgePayload.district_id; //syllabus
                    delete createNudgePayload.medium_id;
                    delete createNudgePayload.class_id;
                    delete createNudgePayload.school_id;
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="primaryPurple p-4 my-4 borderRadious16">
              <div>
                <span className="title2 colorBlack">Nudges Details</span>
              </div>

              <div className="d-flex pt-4 row">
                <div className="col-12 pe-3 pb-2">
                  <div>
                    <span className="Regular16 ps-3 mb-2">Nudge Title*</span>
                  </div>
                  <input
                    disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Nudge Title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="col-4 pe-3 pb-3">
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Choose trigger scenarios*
                    </span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={nudge}
                    onChange={(e) => {
                      setNudge(e.target.value);
                      if (e.target.value) {
                        getNudgeConfig(e.target.value);
                      } else {
                        setNudgeConfig([]);
                      }

                      setTriggerParameter("");
                      setTriggerKey("");
                      setInitialTriggerValue("");
                      setFinalTriggerValue("");
                      setWaitDuration("");
                      setRepeatCount("");
                      setRepeatDuration("");
                      setSubject_id("");
                      setChapter_id("");
                      setSpecialClass_id("");
                      setChallenge_id("");
                      setMilestone_id("");
                      setCheckCondition("");
                      setCheckParameter("");
                      setNotificationPlatform("");
                      setWpTemplate("");
                      setWpTemplateList([]);
                      setWpMessage("");
                      setNotification_title("");
                      setNudgeMessage("");
                      setValidateMessageDisabled(false);
                    }}
                  >
                    <option value="" selected>
                      Choose trigger scenarios
                    </option>
                    {nudgeList?.map((nudge, index) => {
                      return (
                        <option key={index + "nudge"} value={nudge}>
                          {`${nudge}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.parameters?.trigger_parameter
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Choose parameters for trigger*
                    </span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={triggerParameter}
                    onChange={(e) => {
                      setTriggerParameter(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Choose parameters for trigger
                    </option>
                    {nudgeConfig[0]?.parameters?.trigger_parameter?.map(
                      (trigger_parameter, index) => {
                        return (
                          <option
                            key={index + "trigger_parameter"}
                            value={trigger_parameter}
                          >
                            {`${trigger_parameter}`}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.parameters?.trigger_key ? "" : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Choose Units*</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={triggerKey}
                    onChange={(e) => {
                      setTriggerKey(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Choose Units
                    </option>
                    {nudgeConfig[0]?.parameters?.trigger_key?.map(
                      (trigger_key, index) => {
                        return (
                          <option
                            key={index + "trigger_key"}
                            value={trigger_key}
                          >
                            {`${trigger_key}`}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.input_fields?.includes(
                      "Initial Trigger value"
                    )
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-2`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Initial Trigger value*
                    </span>
                  </div>
                  <input
                    disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Initial Trigger value"
                    value={initialTriggerValue}
                    onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e);
                      if (intValue > 0 && intValue <= 99999) {
                        setInitialTriggerValue(intValue);
                      } else if (intValue <= 0) {
                        setInitialTriggerValue("");
                      }
                    }}
                  />
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.input_fields?.includes(
                      "Final Trigger value"
                    )
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-2`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Final Trigger Value*
                    </span>
                  </div>
                  <input
                    disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Final Trigger Value"
                    value={finalTriggerValue}
                    onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e);
                      if (intValue > 0 && intValue <= 99999) {
                        setFinalTriggerValue(intValue);
                      } else if (intValue <= 0) {
                        setFinalTriggerValue("");
                      }
                    }}
                  />
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.nudges_info?.includes("subject")
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Subject*</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={subject_id}
                    onChange={(e) => {
                      setSubject_id(e.target.value);

                      setChapter_id("");
                      setChapterList([]);
                      if (e.target.value) {
                        getChapterListByChapter(e.target.value);
                      }
                    }}
                  >
                    <option key={"subject"} value="" selected>
                      Subject*
                    </option>
                    {subjectList.map((subject, index) => {
                      return (
                        <option
                          key={index + "subject"}
                          value={subject.subject_id}
                        >
                          {`${subject.subject_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() + `${subject.subject_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                  <FormHelperText id="my-helper-text">
                    <span className="errorMessage Regular16">
                      {class_id && medium_id
                        ? ""
                        : "To select subject, select class and medium first"}
                    </span>
                  </FormHelperText>
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.nudges_info?.includes("Choose Chapter")
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Chapter*</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={chapter_id}
                    onChange={(e) => {
                      setChapter_id(e.target.value);
                    }}
                  >
                    <option key={"chapter"} value="" selected>
                      Chapter*
                    </option>
                    <option key={"any chapter"} value="any">
                      Any Chapter
                    </option>
                    {chapterList.map((chapter, index) => {
                      return (
                        <option
                          key={index + "chapter"}
                          value={chapter.chapter_id}
                        >
                          {`${chapter.chapter_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() + `${chapter.chapter_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                  <FormHelperText id="my-helper-text">
                    <span className="errorMessage Regular16">
                      {subject_id
                        ? ""
                        : "To select chapter, select subject first"}
                    </span>
                  </FormHelperText>
                  {/* <FormHelperText
                    className={`${
                      showMCQChallengeError && subject_id ? "" : "d-none"
                    }`}
                    id="my-helper-text"
                  >
                    <span className="errorMessage Regular16">
                      MCQ set not present please select another subject
                    </span>
                  </FormHelperText> */}
                </div>

                <div
                  className={`${
                    nudgeConfig[0]?.nudges_info?.includes("Special Class")
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Special Course*</span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={specialClass_id}
                    onChange={(e) => {
                      setSpecialClass_id(e.target.value);
                    }}
                  >
                    <option key={"SpecialClass"} value="" selected>
                      Special Course*
                    </option>
                    {specialClassList.map((SpecialClass, index) => {
                      return (
                        <option
                          key={index + "SpecialClass"}
                          value={SpecialClass.class_id}
                        >
                          {`${SpecialClass.class_name}`
                            .toString()
                            .charAt(0)
                            .toUpperCase() +
                            `${SpecialClass.class_name}`.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                  {/* <FormHelperText id="my-helper-text">
                    <span className="errorMessage Regular16">
                      {class_id && medium_id
                        ? ""
                        : "Please select medium first"}
                    </span>
                  </FormHelperText> */}
                </div>

                <div
                  className={`${
                    nudgeConfig[0]?.nudges_info?.includes("Choose Challenges")
                      ? ""
                      : "d-none"
                  } col-4 `}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Challenge*</span>
                  </div>
                  <FormControl
                    variant="outlined"
                    className="challengesSelectBox"
                  >
                    <InputLabel
                      className={challenge_id ? "d-none" : ""}
                      id="demo-simple-select-outlined-label"
                    >
                      Challenge*
                    </InputLabel>
                    <Select
                      disabled={true}
                      className="challengesOption "
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="challenge"
                      value={challenge_id}
                      onChange={(e) => {
                        setChallenge_id(e.target.value);
                      }}
                    >
                      <MenuItem key={"any challenge"} value="any">
                        Any Challenge
                      </MenuItem>
                      {challengeList?.map((challenge, index) => {
                        return (
                          <MenuItem
                            key={index + "challenge"}
                            value={challenge._id}
                            className="col-4"
                          >
                            {challenge.challenge_title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.nudges_info?.includes("Choose Milestone")
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Milestone*</span>
                  </div>
                  <FormControl
                    variant="outlined"
                    className="challengesSelectBox"
                  >
                    <InputLabel
                      className={milestone_id ? "d-none" : ""}
                      id="demo-simple-select-outlined-label"
                    >
                      Milestone*
                    </InputLabel>
                    <Select
                      disabled={true}
                      className="challengesOption "
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="milestone"
                      value={milestone_id}
                      onChange={(e) => {
                        setMilestone_id(e.target.value);
                      }}
                    >
                      <MenuItem key={"any milestone"} value="any">
                        Any Milestone
                      </MenuItem>
                      {milestoneList?.map((milestone, index) => {
                        return (
                          <MenuItem
                            key={index + "milestone"}
                            value={milestone._id}
                            className="col-4"
                          >
                            {milestone.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className={`${
                    immediateNudge ? "d-none" : ""
                  } col-4 pe-3 pb-2 `}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Wait duration in days*
                    </span>
                  </div>
                  <input
                    disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Wait duration in days*"
                    value={waitDuration}
                    onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e);
                      if (intValue > 0 && intValue <= 99999) {
                        setWaitDuration(intValue);
                      } else if (intValue <= 0) {
                        setWaitDuration("");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="d-flex row">
                <div className="d-flex col-4 pe-3 pb-2 pt-1 ">
                  <div className="form-check form-switch pe-3">
                    <input
                      disabled={true}
                      className="form-check-input"
                      style={{ height: "22px", width: "40px" }}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={immediateNudge}
                      onClick={() => {
                        immediateNudgeToggler();
                        setRepeatNudge(false);
                        setWaitDuration("");
                        setRepeatCount("");
                        setRepeatDuration("");
                      }}
                    />
                  </div>
                  <div className="pt-1">
                    <span>Immediate</span>
                  </div>
                </div>
              </div>
              <div className="d-flex row">
                <div className="d-flex col-4 pe-3 pb-2 pt-1 ">
                  <div className="form-check form-switch pe-3">
                    <input
                      disabled={true}
                      className="form-check-input"
                      style={{ height: "22px", width: "40px" }}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={repeatNudge}
                      onClick={() => {
                        repeatNudgeToggler();
                        setImmediateNudge(false);
                        // setWaitDuration("");
                        setRepeatCount("");
                        setRepeatDuration("");
                      }}
                    />
                  </div>
                  <div className="pt-1">
                    <span>Repeat Nudge</span>
                  </div>
                </div>
                <div
                  className={`${repeatNudge ? "" : "d-none"} col-4 pe-3 pb-2`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Repeat Count*</span>
                  </div>
                  <input
                    disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Repeat Count*"
                    value={repeatCount}
                    onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e);
                      if (intValue > 0 && intValue <= 99999) {
                        setRepeatCount(intValue);
                      } else if (intValue <= 0) {
                        setRepeatCount("");
                      }
                    }}
                  />
                </div>
                <div
                  className={`${repeatNudge ? "" : "d-none"} col-4 pe-3 pb-2`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Repeat duration in days*
                    </span>
                  </div>
                  <input
                    disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Repeat duration in days*"
                    value={repeatDuration}
                    onChange={(e) => {
                      const intValue = handlePositiveNumberInput(e);
                      if (intValue > 0 && intValue <= 99999) {
                        setRepeatDuration(intValue);
                      } else if (intValue <= 0) {
                        setRepeatDuration("");
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="primaryPurple p-4 my-4 borderRadious16">
              <div className="d-flex pt-4 row">
                <div
                  className={`${
                    nudgeConfig[0]?.parameters?.check_condition ? "" : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      {" "}
                      Choose Condition Parameter*
                    </span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={checkCondition}
                    onChange={(e) => {
                      setCheckCondition(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Choose Condition Parameter
                    </option>
                    {nudgeConfig[0]?.parameters?.check_condition?.map(
                      (check_condition, index) => {
                        return (
                          <option
                            key={index + "check_condition"}
                            value={check_condition}
                          >
                            {`${check_condition}`}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.parameters?.check_parameter ? "" : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Condition Variable*
                    </span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={checkParameter}
                    onChange={(e) => {
                      setCheckParameter(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Condition Variable
                    </option>
                    {nudgeConfig[0]?.parameters?.check_parameter?.map(
                      (check_parameter, index) => {
                        return (
                          <option
                            key={index + "check_parameter"}
                            value={check_parameter}
                          >
                            {`${check_parameter}`}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div
                  className={`${
                    nudgeConfig[0]?.parameters?.notification_platform
                      ? ""
                      : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Medium of Nudges*
                    </span>
                  </div>
                  <select
                    disabled={true}
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={notificationPlatform}
                    onChange={(e) => {
                      setNotificationPlatform(e.target.value);
                      setNotification_title("");
                      setNudgeMessage("");
                      setValidateMessageDisabled(false);
                      setWpTemplate("");
                      setWpTemplateList([]);
                      setWpMessage("");

                      if (e.target.value === "WhatsApp") {
                        getTemplate(nudge, e.target.value);
                      }
                    }}
                  >
                    <option value="" selected>
                      Medium of Nudges
                    </option>
                    {nudgeConfig[0]?.parameters?.notification_platform?.map(
                      (notification_platform, index) => {
                        return (
                          <option
                            key={index + "notification_platform"}
                            value={notification_platform}
                          >
                            {`${notification_platform}`}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div
                  className={`${
                    notificationPlatform === "WhatsApp" ? "" : "d-none"
                  } col-4 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Select template*
                    </span>
                  </div>
                  <select
                    className="form-select form-select-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    aria-label=".form-select-lg example"
                    value={wpTemplate}
                    onChange={(e) => {
                      setWpTemplate(e.target.value);

                      if (e.target.value) {
                        let selectedTemplate = wpTemplateList?.filter(function (
                          template
                        ) {
                          return template._id === e.target.value;
                        });
                        setWpMessage(selectedTemplate[0].message);
                      } else {
                        setWpMessage("");
                      }
                    }}
                  >
                    <option value="" selected>
                      Select template
                    </option>
                    {wpTemplateList?.map((template, index) => {
                      return (
                        <option key={index + "template"} value={template._id}>
                          {`${template.template_name}`}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    className={`${
                      showError && wpTemplate === "" ? "" : "d-none"
                    }`}
                  >
                    <span className="errorMessage Regular16 ps-3">
                      *Required
                    </span>
                  </div>
                </div>
                <div
                  className={`${
                    notificationPlatform === "WhatsApp" ? "" : "d-none"
                  } col-12 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Message*</span>
                  </div>
                  <div className="col-12">
                    <textarea
                      class="form-control colorGray Regular16 p-3 inputSelect"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Message"
                      maxLength="200"
                      value={wpMessage}
                      disabled={true}
                    ></textarea>
                  </div>
                  <div
                    className={`${
                      showError && wpMessage === "" ? "" : "d-none"
                    }`}
                  >
                    <span className="errorMessage Regular16 ps-3">
                      *Required
                    </span>
                  </div>
                </div>
                <div
                  className={`${
                    notificationPlatform === "Drawer" ? "" : "d-none"
                  } col-12 pe-3 pb-2`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">
                      Notification Title*
                    </span>
                  </div>
                  <input
                   disabled={true}
                    className="form-control form-control-lg colorGray Regular16 px-3 py-2 selectHeight inputSelect"
                    type="text"
                    placeholder="Notification Title*"
                    value={notification_title}
                    onChange={(e) => {
                      setNotification_title(e.target.value);
                    }}
                  />
                  <div
                    className={`${
                      showError && notification_title === "" ? "" : "d-none"
                    }`}
                  >
                    <span className="errorMessage Regular16 ps-3">
                      *Required
                    </span>
                  </div>
                </div>
                <div
                  className={`${
                    notificationPlatform === "Drawer" ? "" : "d-none"
                  } col-12 pe-3 pb-3`}
                >
                  <div>
                    <span className="Regular16 ps-3 mb-2">Message*</span>
                  </div>
                  <div className="d-flex">
                    <div className="col-11">
                      <textarea
                        class="form-control colorGray Regular16 p-3 inputSelect"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Message"
                        maxLength="200"
                        value={nudgeMessage}
                        onChange={(e) => {
                          setNudgeMessage(e.target.value);
                          setValidateMessageDisabled(false);
                        }}
                      ></textarea>
                      <FormHelperText id="my-helper-text">
                        Note:- Available variables are{" "}
                        {nudgeConfig[0]?.variables?.join(", ")}
                      </FormHelperText>
                      <div
                        className={`${
                          showError && nudgeMessage === "" ? "" : "d-none"
                        }`}
                      >
                        <span className="errorMessage Regular16 ps-3">
                          *Required
                        </span>
                      </div>
                    </div>
                    <Button
                      className="ms-3 height58 mt-4"
                      color="primary"
                      disabled={validateMessageDisabled}
                      onClick={() => {
                        validateNudgeMessage();
                      }}
                    >
                      Validate
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-4 pe-3">
                <div className="mb-2">
                  <input
                    disabled={true}
                    type="file"
                    accept="image/*,.json"
                    className="form-control colorGray Regular16 p-3 inputSelect"
                    id="customFileUpload"
                    onChange={(e) => {
                      if (!e.target.files[0]) {
                        setNudgeImage("");
                        setNudgeImageURL("");
                        setNudgeImageErrorMsg(false);
                        return;
                      } else if (e.target.files[0].size > 1024 * 1024) {
                        setNudgeImage("");
                        setNudgeImageURL("");
                        setNudgeImageErrorMsg(true);
                      } else {
                        setNudgeImage(e.target.files[0]);
                        setNudgeImageURL(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setNudgeImageErrorMsg(false);
                        setNudgeImageUploadDisabled(false);
                      }
                    }}
                  />
                </div>
                <div className="d-flex col-4 pe-3 pb-3">
                  <div>
                    <div
                      className={`${
                        nudgeImageURLS3 ? "d-none" : ""
                      } alternateImage`}
                    >
                      <span>136 x 106</span>
                    </div>
                    {nudgeImage !== "" ? (
                      nudgeImage.name.split(".").pop() === "json" ? (
                        <div className={`${nudgeImageURL ? "" : "d-none"}`}>
                          <MyLottieComponent vopalottie={nudgeImageURL} />
                        </div>
                      ) : (
                        <div>
                          <img
                            className={`${nudgeImageURL ? "" : "d-none"}`}
                            src={nudgeImageURL}
                            width={136}
                            height={106}
                            alt="Uploaded"
                          />
                        </div>
                      )
                    ) : nudgeImageURLS3.split(".").pop() === "json" ? (
                      <div className={`${nudgeImageURLS3 ? "" : "d-none"}`}>
                        <MyLottieComponent vopalottie={nudgeImageURLS3} />
                      </div>
                    ) : (
                      <div>
                        <img
                          className={`${nudgeImageURLS3 ? "" : "d-none"}`}
                          src={nudgeImageURLS3}
                          width={136}
                          height={106}
                          alt="Uploaded"
                        />
                      </div>
                    )}

                    <div
                      className={`${
                        nudgeImageErrorMsg ? "" : "d-none"
                      } colorRed`}
                      id="my-helper-text"
                    >
                      <span className="Regular16">Image size exceeds 1MB.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mb-5 ">
              <Link to="/admin/nudge">
                <button
                  type="button"
                  class="btn buttonColorGray px-4 py-3 me-3 borderRadious16 regular18"
                >
                  Cancel
                </button>
              </Link>
              {/* <Link to="/admin/nudge">
            <button
              type="button"
              class="btn buttonColorGray px-4 py-3 me-3 borderRadious16 regular18"
            >
              Save
            </button>
          </Link> */}
              <button
                disabled={nudgeImageUploadDisabled}
                type="button"
                className={`${
                  nudgeImage ? "" : "d-none"
                } btn buttonColorGray px-4 py-3 me-3 borderRadious16 regular18`}
                onClick={uploadNudgeImageAndGetURL}
              >
                Upload file
              </button>
              <button
                type="button"
                class="btn btn-dark px-4 py-3 borderRadious16 regular18"
                onClick={handleEditNudge}
              >
                Update nudge
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EditNudge;
